import * as moment from 'moment'
import paddleSubscriptionStatusTypes from '@/store/paddle-subscription-status-types'

export default {
  methods: {
    localeDateTime (date) {
      return moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local().format('MMMM Do YYYY, h:mm a')
    },

    localeTime (date) {
      return moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local().format('h:mm a')
    },

    localeDate (date) {
      return moment.utc(date, 'YYYY-MM-DD HH:mm:ss').local().format('MMM Do YYYY')
    }
  },

  computed: {
    paddleSubscriptionStatusTypes: function () {
      return paddleSubscriptionStatusTypes
    }
  }
}
