<template>
  <div id="form-create-steps">
    <div class="form-steps" id="form-steps-wrapper">
      <draggable v-model="steps" :options="dragOptions" :disabled="true">
        <template #item="{ element, index }">
          <form-step v-bind:id="element.id" v-bind:index="index" :key="element.id"></form-step>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import * as actionTypes from '../store/action-types'
import FormStep from './FormStep'
import draggable from 'vuedraggable'
import { mapGetters } from 'vuex'

export default {
  components: {
    'form-step': FormStep,
    'draggable': draggable
  },

  methods: {
  },

  computed: {
    ...mapGetters([
      'formStepElementTypes'
    ]),
    steps: {
      get() {
        return this.$store.state.formBuildState.steps || []
      },
      set(steps) {
        if (steps.length > 0) {
          this.$store.dispatch(
            actionTypes.FORMBUILDER_UPDATE_STEPS,
            { steps: steps }
          )
        }
      }
    },
    dragOptions: function () {
      return {
        handle: '.step-handle',
        scrollSensitivity: 100,
        scrollSpeed: 5
      }
    }
  }
}
</script>

<style lang="scss"></style>
