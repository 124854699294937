export const planFeatures = {
  featuresStarter: [
    '50 Leads/month',
    '1 Form',
    '5000 Views /month',
    'Contact State',
    'UTM Paramaters',
    [
      '0 Partial Leads/month',
      'Trustedform',
      'Multiple Form Endings',
      'Account Manager'
    ]
  ],
  featuresPro: [
    '500 Leads/month',
    '20 Forms',
    '20 Partial Leads/month',
    '12,500 Views /month',
    'Contact State',
    'UTM Paramaters',
    [
      'Trustedform',
      'Multiple Form Endings',
      'Account Manager'
    ]
  ],
  featuresScale: [
    '2,000 Leads/month',
    '100 Forms',
    '1,000 Partial Leads/month',
    '25,000 Views/month',
    'Contact State',
    'UTM Paramaters',
    'Trustedform',
    'Multiple Form Endings',
    ['Account Manager']
  ],
  featuresEnterprise: [
    '10,000 Leads/month',
    '300 Forms',
    '10,000 Partial Leads/month',
    '125,000 Views/month',
    'Contact State',
    'UTM Paramaters',
    'Trustedform',
    'Multiple Form Endings',
    'Account Manager'
  ],
  featuresFreeTrial: [
    '20 Forms',
    'Unlimited responses',
    'Thank you page',
    'E-mail notifications',
    'Integrations',
    'A/B testing',
    'GDPR form fields',
    'Remove LeadGen branding'
  ],
  featuresSingle: [
    '1 Form',
    '30 Leads /month',
    '1,000 Views /month',
    'Thank you page',
    'Form Builder ',
    'Lead Management ',
    'Lead Verification',
    'Remove LeadGen branding'
  ]
}

export const paddleMonthlyPlans = [
  {
    title: 'Starter',
    text: 'Includes:',
    price: 19,
    publicId: 'paddle_starter',
    forms: 1,
    features: planFeatures.featuresStarter,
    leads: '50 leads'
  },
  {
    title: 'Pro',
    text: 'Includes:',
    price: 49,
    publicId: 'paddle_pro',
    forms: 20,
    features: planFeatures.featuresPro,
    leads: '500 leads'
  },
  {
    title: 'Scale',
    text: 'Includes:',
    price: 99,
    publicId: 'paddle_scale',
    forms: 100,
    features: planFeatures.featuresScale,
    leads: '2,000 leads'
  },
  {
    title: 'Enterprise',
    text: 'Includes:',
    price: 250,
    publicId: 'paddle_enterprise',
    forms: 300,
    features: planFeatures.featuresEnterprise,
    leads: '10,000 leads'
  }
]

export const paddleYearlyPlans = [
  {
    title: 'Pro',
    text: 'Includes:',
    price: 39,
    publicId: 'paddle_pro_annual',
    forms: 20,
    features: planFeatures.featuresPro,
    leads: '500 leads',
    originalPrice: 49
  },
  {
    title: 'Scale',
    text: 'Includes:',
    price: 79,
    publicId: 'paddle_scale_annual',
    forms: 100,
    features: planFeatures.featuresScale,
    leads: '2,000 leads',
    originalPrice: 99
  },
  {
    title: 'Enterprise',
    text: 'Includes:',
    price: 200,
    publicId: 'paddle_enterprise_annual',
    forms: 300,
    features: planFeatures.featuresEnterprise,
    leads: '10,000 leads',
    originalPrice: 250
  },
  {
    title: 'Single Plan',
    text: 'Includes:',
    price: 50,
    publicId: 'paddle_single_annual',
    forms: 1,
    features: planFeatures.featuresSingle
  }
]

export const paddleMonthlyTrialPlans = [
  {
    title: 'Pro Trial',
    text: 'Includes:',
    price: 49,
    publicId: 'paddle_pro_trial',
    forms: 20,
    features: planFeatures.featuresPro
  },
  {
    title: 'Scale Trial',
    text: 'Includes:',
    price: 99,
    publicId: 'paddle_scale_trial',
    forms: 100,
    features: planFeatures.featuresScale
  },
  {
    title: 'Enterprise Trial',
    text: 'Includes:',
    price: 250,
    publicId: 'paddle_enterprise_trial',
    forms: 300,
    features: planFeatures.featuresEnterprise
  }
]

export const paddleYearlyTrialPlans = [
  {
    title: 'Pro Trial',
    text: 'Includes:',
    price: 39,
    publicId: 'paddle_pro_annual_trial',
    forms: 20,
    features: planFeatures.featuresPro
  },
  {
    title: 'Scale Trial',
    text: 'Includes:',
    price: 79,
    publicId: 'paddle_scale_annual_trial',
    forms: 100,
    features: planFeatures.featuresScale
  },
  {
    title: 'Enterprise Trial',
    text: 'Includes:',
    price: 200,
    publicId: 'paddle_enterprise_annual_trial',
    forms: 300,
    features: planFeatures.featuresEnterprise
  }
]

export const freeTrialPlans = [
  {
    title: 'Free Trial',
    text: 'Includes:',
    price: 0,
    publicId: 'free_trial',
    forms: 20,
    features: planFeatures.featuresFreeTrial
  }
]

export const allPlanFeatures = [
  {
    Plan: 'Lead submissions/month',
    Starter: '50',
    Pro: '500',
    Scale: '2,000',
    Enterprise: '10,000'
  },
  {
    Plan: 'Number of forms',
    Starter: '1',
    Pro: '20',
    Scale: '100',
    Enterprise: '300'
  },
  {
    Plan: 'Partial leads/month',
    Starter: '0',
    Pro: '20',
    Scale: '1,000',
    Enterprise: '10,000'
  },
  {
    Plan: 'Views/month',
    Starter: '5000',
    Pro: '12,500',
    Scale: '25,000',
    Enterprise: '125,000'
  },
  {
    Plan: 'Questions per form',
    Starter: 'Unlimited',
    Pro: 'Unlimited',
    Scale: 'Unlimited',
    Enterprise: 'Unlimited'
  },
  {
    Plan: 'Self email notifications',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Contact State integration',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'UTM Parameters',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Trustedform integration',
    Starter: '✗',
    Pro: '✗',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Redirect to URL',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Thank you message editor',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Multiple form endings',
    Starter: '✗',
    Pro: '✗',
    Scale: 'Answer-Based',
    Enterprise: 'Answer-Based, Visitor-Based'
  },
  {
    Plan: 'Account manager',
    Starter: '✗',
    Pro: '✗',
    Scale: '✗',
    Enterprise: '✓'
  },
  {
    Plan: 'Conditional logic flows',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Google cloud address autocomplete',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Add tracking scripts',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Post/append form data to URL',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Hidden fields',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Premium themes',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Add images/GIFs',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Form visibility geo-fencing',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Custom email validation',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Leadproof popups',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Calculator forms/scoring',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Zapier integration',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Webhooks integration',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Form metrics',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  },
  {
    Plan: 'Google analytics integration',
    Starter: '✓',
    Pro: '✓',
    Scale: '✓',
    Enterprise: '✓'
  }
]
