import Vue from 'vue'
import * as types from '@/store/mutation-types'
import questionTypes from '@/store/question-types'
import questionTypesMap from './question-types-map'
import { otherChoice } from '../shared/constants/formQuestionSkins'
import _ from 'lodash'

export default {

  [types.INIT_APP](state, data) {
    state.token = data.token
    state.decodedToken = data.decodedToken
    state.user = data.user
    state.plans = data.plans
  },

  [types.INSERT_ENTITY](state, data) {
    for (let entity of data.entities) {
      if (!state[data.type]) {
        return
      }

      if (_.find(state[data.type], { id: entity?.id })) {
        return
      }

      state[data.type][state[data.type].length] = entity
    }
  },

  [types.UPDATE_ENTITY](state, data) {
    if (!state[data.type]) {
      return
    }

    let entity = _.find(state[data.type], { id: data.id })

    if (!entity) {
      return
    }

    for (let prop in data.entity) {
      entity.prop = data.entity[prop]
    }
  },

  [types.FETCH_FORMS_BY_USER](state) { },

  [types.FETCH_FORM_BY_USER](state, data) {
    state.formBuildState = data
  },

  [types.FORMBUILDER_RESET](state, data) {
    if (data && data.state) {
      for (let step of data.state.steps) {
        for (let q of step.questions) {
          if (q.type === questionTypesMap.GDPR) {
            continue
          }
          q.description = q.description || questionTypes[0].description

          if (q.choices) {
            q.choicesValues = q.choicesValues || q.choices.map(() => 0)
          }
        }
      }
      state.formBuildState = data.state
      return
    }
    state.formBuildState = {
      id: null,
      formTitle: 'My Form',
      validate: false,
      lastStepId: 1,
      lastQuestionId: 1,
      lastElementId: 1,
      choiceFormula: '',
      calculator: {
        fieldName: ''
      },
      steps: [
        {
          'id': 1,
          'autoNavigation': false,
          'questions': [
            {
              'id': 1,
              'stepId': 1,
              'number': 1,
              'type': 'SHORT_TEXT',
              'required': true,
              'showHideOrientationScale': true,
              'placeholder': '',
              'title': '',
              'description': '',
              'choices': [
                { id: 1, label: 'Choice 1', order: 1, description: '', icon: '', image: '', selected: false },
                { id: 2, label: 'Choice 2', order: 2, description: '', icon: '', image: '', selected: false },
                { id: 3, label: 'Choice 3', order: 3, description: '', icon: '', image: '', selected: false }
              ],
              restrictEmailFields: [
                { id: 1, order: 1, allow: 1 }
              ],
              rangeFields: {
                unitAlignment: 'left',
                unit: '',
                veryUnsatisfied: 'Very Unsatisfied',
                unsatisfied: 'Unsatisfied',
                neutral: 'Neutral',
                satisfied: 'Satisfied',
                verySatisfied: 'Very Satisfied',
                lowerEndScaleText: 'Very Bad',
                higherEndScaleText: 'Excellent',
                maxScaleLimit: 5,
                minScaleValue: 0,
                maxScaleValue: 100,
                valueMin: 0,
                valueMax: 100,
                likertRadios: [
                  { id: 0, label: '1', lowerEndScaleText: 'Very Bad' },
                  { id: 1, label: '2' },
                  { id: 2, label: '3' },
                  { id: 3, label: '4' },
                  { id: 4, label: '5', higherEndScaleText: 'Excellent' }
                ]
              },
              'choicesValues': [0, 0, 0],
              'valid': true,
              'enabled': true,
              'hide_title': false
            }
          ],
          'elements': []
        }
      ]
    }
  },

  [types.FORMBUILDER_CREATE_FORM](state) { },

  [types.FORMBUILDER_CREATE_FORM_VARIANT_SUCCESS](state) { },

  [types.FORMBUILDER_CREATE_FORM_VARIANT_ERROR](state) { },

  [types.FORMBUILDER_UPDATE_ID](state, data) {
    state.formBuildState.id = data.id
  },

  [types.FORMBUILDER_ADD_STEP](state, data) {
    let steps = state.formBuildState.steps.slice()
    steps.splice(data.position, 0, data.step)
    state.formBuildState.steps = steps
    // state.formBuildState.steps.push(data.step)
  },

  [types.FORMBUILDER_DELETE_STEP](state, data) {
    let stepIndex = 0
    let steps = state.formBuildState.steps

    for (let step of steps) {
      if (step.id === data.stepId) {
        break
      }
      stepIndex++
    }

    // Reset step jumps.
    for (let step of steps) {
      if (step?.jump && step?.jump?.step === stepIndex + 1 && step.id !== data.stepId) {
        step.splice(jump, 1)
      }
    }

    steps.splice(stepIndex, 1)

    // Handle over jumps.
    for (let step of steps) {
      if (step?.jump?.step && step?.jump?.step > steps.length) {
        step.jump[step] = -1
      }

      for (let question of step.questions) {
        if (!question?.jumps || question?.jumps.length === 0) {
          continue
        }

        for (let jump of question?.jumps) {
          if (jump.step && jump.step > steps.length) {
            jump[step] = -1
          }
        }
      }
    }
  },

  [types.FORMBUILDER_ADD_QUESTION](state, data) {
    let step = getStepById(state, data.question.stepId)
    if (step.questions) {
      //Vue.set(step.questions, step.questions.length, data.question)
      step.questions[step.questions.length] = data.question
    }
  },
  [types.FORMBUILDER_DUPLICATE_QUESTION](state, data) {
    let step = getStepById(state, data.duplicate.stepId)
    if (step.questions) {
      step.questions[step.questions.length] = data.duplicate
    }
  },
  [types.FORMBUILDER_UPDATE_FORMTITLE](state, data) {
    state.formBuildState.formTitle = data.title
  },
  [types.FORMBUILDER_UPDATE_CHOICE_FORMULA](state, data) {
    state.formBuildState.choiceFormula = data.choiceFormula
  },
  [types.FORMBUILDER_UPDATE_QENABLE_OTHER_CHOICE](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.otherOption = data.otherOption
  },
  [types.FORMBUILDER_UPDATE_STEPQ](state, data) {
    // var stepId = data.questions[0].stepId
    var stepId = data.stepId
    var index = 0
    for (let step of state.formBuildState.steps) {
      if (step.id === stepId) {
        let questions = []
        for (let q of data.questions) {
          q.stepId = stepId
          questions.push(q)
        }
        step.questions = questions
        state.formBuildState.steps.index = step
        break
      }
      index++
    }
  },
  [types.FORMBUILDER_UPDATE_STEPS](state, data) {
    state.formBuildState.steps = data.steps
  },
  [types.FORMBUILDER_UPDATE_STEP_JUMP](state, data) {
    let step = getStepById(state, data.stepId)
    if (step.jump) {
      step.jump.step = data.step
      step.jump.step = data.step
    } else {
      step.jump = { step: data.step }
    }
  },
  [types.FORMBUILDER_UPDATE_STEP_AN](state, data) {
    let step = getStepById(state, data.stepId)

    step.autoNavigation = data.autoNavigation
  },
  [types.FORMBUILDER_ADD_QT](state, data) {
    state.formBuildState.qt = data.qt
  },
  [types.FORMBUILDER_UPDATE_QTYPE](state, data) { },
  [types.FORMBUILDER_UPDATE_QTITLE](state, data) { },
  [types.FORMBUILDER_UPDATE_QTITLE_HIDE](state, data) {
    let question = getQuestionById(state, data.questionId)
    question.hide_title = data.hide_title
  },
  [types.FORMBUILDER_UPDATE_QDESCRIPTION](state, data) { },
  [types.FORMBUILDER_UPDATE_QFIELDNAME](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.field_name = data.fieldName
  },
  [types.FORMBUILDER_UPDATE_QPLACEHOLDER](state, data) { },
  [types.FORMBUILDER_UPDATE_QDATE_PLACEHOLDERDAY](state, data) { },
  [types.FORMBUILDER_UPDATE_QDATE_PLACEHOLDERMONTH](state, data) { },
  [types.FORMBUILDER_UPDATE_QDATE_PLACEHOLDERYEAR](state, data) { },
  [types.FORMBUILDER_UPDATE_QREQUIRED](state, data) { },
  [types.FORMBUILDER_UPDATE_QMINDATE](state, data) { },
  [types.FORMBUILDER_UPDATE_QMAXDATE](state, data) { },
  [types.FORMBUILDER_UPDATE_QMINMAX](state, data) { },
  [types.FORMBUILDER_UPDATE_QMINMAX_AUTOINCEREMENT](state, data) { },
  [types.FORMBUILDER_UPDATE_QMINMAXCHOICES](state, data) { },
  [types.FORMBUILDER_UPDATE_QENABLE_CHOICES_VALUES](state, data) { },
  [types.FORMBUILDER_UPDATE_QENABLE_PRE_SELECT_CHOICES](state, data) { },
  [types.FORMBUILDER_UPDATE_QMINCHOICES](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question['minChoices'] = data.minChoices
  },
  [types.FORMBUILDER_UPDATE_QMAXCHOICES](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question['maxChoices'] = data.maxChoices
  },
  [types.FORMBUILDER_UPDATE_QENABLE_RANDOM_CHOICE_ORDER](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question['randomChoiceOrder'] = data.randomChoiceOrder
  },
  [types.FORMBUILDER_UPDATE_QNUMBER](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.number = data.number
  },
  [types.FORMBUILDER_UPDATE_QSKIN](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.skin = data.skin
  },
  [types.FORMBUILDER_UPDATE_QCHOICES](state, data) { },
  [types.FORMBUILDER_UPDATE_QCHOICES_VALUES](state, data) { },
  [types.FORMBUILDER_UPDATE_QCHOICE](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }

    for (let [index, choice] of question.choices.entries()) {
      if (choice.id === data.choice.id) {
        question.choices[index] = data.choice
        return
      }
    }

    question.choices.question.choices.length = data.choice
  },
  [types.FORMBUILDER_UPDATE_QCHOICE_VALUE](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.choicesValues[data.index] = data.value
  },
  [types.FORMBUILDER_DELETE_QCHOICE](state, data) { },
  [types.FORMBUILDER_DELETE_QCHOICE_VALUE](state, data) { },
  [types.FORMBUILDER_ADD_QCHOICE](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    let maxId = -1
    let maxOrder = -1
    let otherChoiceIndex = -1
    question.choices.forEach((choice, index) => {
      if (maxId < choice.id) {
        maxId = choice.id
      }
      if (maxOrder < choice.order) {
        maxOrder = choice.order
      }
      if (choice.type === otherChoice.OTHER) {
        otherChoiceIndex = index
      }
    })
    if (otherChoiceIndex !== -1) {
      question.choices[otherChoiceIndex].id = maxId + 1
      question.choices[otherChoiceIndex].order = maxOrder + 1
    }
    let newChoiceId = maxId + (otherChoiceIndex === -1 ? 1 : 2)
    let newChoiceOrder = maxOrder + (otherChoiceIndex === -1 ? 1 : 0)
    let newChoice = {
      id: newChoiceId,
      label: data.choiceLabel || '',
      order: newChoiceOrder
    }
    if (otherChoiceIndex !== -1) {
      question.choices.splice(otherChoiceIndex, 0, newChoice)
    } else {
      question.choices.push(newChoice)
    }
  },
  [types.FORMBUILDER_ADD_QCHOICE_VALUE](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.choicesValues[question.choicesValues.length] = 0
  },
  [types.FORMBUILDER_SORT_QCHOICE](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    const otherChoices = []
    const otherChoicesValues = []
    const standardChoices = []
    const standardChoicesValues = []
    question.choices.forEach((choice, index) => {
      if (choice.type === 'other') {
        otherChoices.push(choice)
        otherChoicesValues.push(question.choicesValues[index])
      } else {
        standardChoices.push(choice)
        standardChoicesValues.push(question.choicesValues[index])
      }
    })
    let newChoices = []
    let newChoicesValues = []
    standardChoices.forEach((choice, index) => {
      let newIndex = data.state.indexOf(choice.id.toString())
      if (newIndex >= 0) {
        newChoices[newIndex] = choice
        newChoicesValues[newIndex] = standardChoicesValues[index]
      }
    })
    newChoices = newChoices.filter(choice => choice != null)
    newChoicesValues = newChoicesValues.filter(value => value != null)
    newChoices.forEach((choice, index) => {
      choice.order = index + 1
    })
    otherChoices.forEach((choice, index) => {
      newChoices.push(choice)
      newChoicesValues.push(otherChoicesValues[index])
      choice.order = newChoices.length
    })
    question.choices = newChoices
    question.choicesValues = newChoicesValues
  },
  [types.FORMBUILDER_UPDATE_GDPR_CHOICES](state, data) {
    let question = getQuestionById(state, data.questionId)

    if (!question.options) {
      question.options = {}
    }

    let isPresent = data.choices.some(function (el) { return el.required === true })
    if (isPresent) {
      question.required = true
    } else {
      question.required = false
    }

    question.options.choices = data.choices
  },
  [types.FORMBUILDER_DELETE_Q](state, data) {
    let step = getStepById(state, data.stepId)
    let qIndex = 0
    for (let question of step.questions) {
      if (question.id === data.questionId) {
        step.questions.splice(qIndex, 1)
        break
      }
      qIndex++
    }
  },
  [types.FORMBUILDER_VALIDATE](state, data) {
    state.formBuildState.validate = data.validate
  },

  [types.FORMBUILDER_UPDATE_GDPR](state, data) {
    let lastStep = state.formBuildState.steps[state.formBuildState.steps.length - 1]
    data.id = state.formBuildState.lastQuestionId + 1
    data.stepId = state.formBuildState.lastStepId
    if (!data.dbId) {
      data.dbId = null
    }
    data.number = lastStep.elements.length + lastStep.questions.length + 1
    lastStep.questions.lastStep.questions.length = data
  },

  [types.FORMBUILDER_REMOVE_GDPR](state) {
    for (let step of state.formBuildState.steps) {
      step.questions = step.questions.filter(q => q.type !== questionTypesMap.GDPR)
    }
  },

  [types.FORMBUILDER_UPDATE_CALCULATOR](state, data) {
    // Use optional chaining and nullish coalescing to initialize the objects
    state.formBuildState.calculator = state.formBuildState.calculator || {}
    state.formBuildState.calculator.data = state.formBuildState.calculator.data || {}

    // Safely update the name property
    state.formBuildState.calculator.data.name = data.value
  },

  [types.FORMBUILDER_ADD_QJUMP](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    if (question.jumps) {
      question.jumps[question.jumps.length] = data.jump
    } else {
      question.jumps = []
      question.jumps[question.jumps.length] = data.jump
    }
  },

  [types.FORMBUILDER_ADD_QJUMP_CONDITION](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    if (question.jumps && question.jumps[data.jumpIndex]) {
      let conditions = question.jumps[data.jumpIndex].conditions
      conditions[conditions.length] = data.condition
    }
  },

  [types.FORMBUILDER_UPDATE_QJUMP_CONDITION](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    if (question.jumps && question.jumps[data.jumpIndex]) {
      let conditions = question.jumps[data.jumpIndex].conditions
      conditions[data.conditionIndex] = data.condition
    }
  },

  [types.FORMBUILDER_UPDATE_QJUMP](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    if (question.jumps && question.jumps[data.jumpIndex]) {
      question.jumps[data.jumpIndex] = data.jump
    }
  },

  [types.FORMBUILDER_DELETE_QJUMP](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    if (question.jumps && question.jumps[data.jumpIndex]) {
      question.jumps.splice(data.jumpIndex, 1)
    }
  },

  [types.FORMBUILDER_DELETE_QJUMP_CONDITION](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    if (question.jumps && question.jumps[data.jumpIndex]) {
      let conditions = question.jumps[data.jumpIndex].conditions
      conditions.splice(data.conditionIndex, 1)
    }
  },

  [types.FORMBUILDER_DELETE_QJUMPS](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    if (question.jumps) {
      question.jumps.splice(0)
    }
  },

  [types.FORMBUILDER_UPDATE_QADDRESS_FIELDS](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }

    // Update order when fields are sorted.
    if (data.sorted) {
      for (let field of Object.values(data.fields)) {
        let index = data.sorted.indexOf(field.id)

        field.order = index + 1
      }

      question.fields = data.fields

      return
    }

    question.fields = data.fields
  },

  [types.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.autocompleteApiKey = data.apikey
  },

  [types.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY_SOURCE](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.autocompleteApiKeySource = data.apikeySource
  },

  [types.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_FIELDS_EDIT](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.autocompleteFieldsEdit = data.autocompleteFieldsEdit
  },

  [types.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_MODE](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.autocompleteMode = data.autocompleteMode.id
  },

  [types.FORMBUILDER_UPDATE_QEMAIL_REPLYTO](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.replyTo = data.replyTo
  },
  // ENABLE NUMBER MIN MAX LIMIT
  [types.FORMBUILDER_UPDATE_QNUMBER_QMINMAXLIMIT](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question['enableMinMaxLimit'] = data.enableMinMaxLimit
  },
  // ENABLE Range MIN MAX LIMIT
  [types.FORMBUILDER_UPDATE_QRANGE_UNITVALUES](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.enableUnitValues = data.enableUnitValues
  },
  // STep COUNT
  [types.FORMBUILDER_UPDATE_QRANGE_STEPCOUNT](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.enableStepCount = data.enableStepCount
  },
  // ENABLE Range Custom text
  [types.FORMBUILDER_UPDATE_QRANGE_CUSTOMTEXT](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.enableCustomText = data.enableCustomText
  },
  [types.FORMBUILDER_UPDATE_QRANGE_SHORIENTATIONSCALE](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.showHideOrientationScale = data.showHideOrientationScale
  },
  [types.FORMBUILDER_UPDATE_QMINNUMBER](state, data) { },
  [types.FORMBUILDER_UPDATE_QMAXNUMBER](state, data) { },
  [types.FORMBUILDER_UPDATE_QRANGE_RANGEFIELDS](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }

    question.rangeFields = data.rangeFields
  },
  [types.FORMBUILDER_UPDATE_QRANGE_RANGEFIELD](state, data) { },
  [types.FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAIL](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.restrictEmail = data.restrictEmail
  },
  [types.FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELDS](state, data) { },
  [types.FORMBUILDER_DELETE_QEMAIL_RESTRICTEMAILFIELD](state, data) { },
  [types.FORMBUILDER_ADD_QEMAIL_RESTRICTEMAILFIELD](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    let maxId = -1
    let maxOrder = -1
    for (let feild of question.restrictEmailFields) {
      if (maxId < feild.id) {
        maxId = feild.id
      }
      if (maxOrder < feild.order) {
        maxOrder = feild.order
      }
    }

    if (_.find(question.restrictEmailFields, { id: maxId + 1 })) {
      return
    }

    if (_.find(question.restrictEmailFields, { order: maxOrder + 1 })) {
      return
    }

    question.restrictEmailFields[question.restrictEmailFields.length] = { id: maxId + 1, email: '', order: maxOrder + 1, allow: 1 }
  },
  [types.FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELD](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }

    for (let [index, field] of question.restrictEmailFields.entries()) {
      if (field.id === data.restrictEmailField.id) {
        question.restrictEmailFields[index] = data.restrictEmailField
        return
      }
    }
    question.restrictEmailFields[question.restrictEmailFields.length] = data.restrictEmailField
  },
  /* phone number default country code */
  [types.FORMBUILDER_UPDATE_QPHONE_DEFAULT_COUNTRY_CODE](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.enableDefaultCode = data.enableDefaultCode
  },

  [types.FORMBUILDER_UPDATE_QPHONE_COUNTRY_CODE_VALUE](state, data) {
    let question = getQuestionById(state, data.questionId)
    if (!question) {
      return
    }
    question.defaultCountryCode = data.defaultCountryCode
  },

  // step element
  [types.FORMBUILDER_ADD_ELEMENT]: (state, data) => {
    let step = getStepById(state, data.element.stepId)
    if (step.elements) {
      step.elements[step.elements.length] = data.element
    }
  },

  [types.FORMBUILDER_UPDATE_ENUMBER](state, data) {
    let element = getStepElementById(state, data.elementId)
    if (!element) {
      return
    }
    element.number = data.number
  },

  [types.FORMBUILDER_DELETE_E]: (state, data) => {
    let step = getStepById(state, data.stepId)
    let eIndex = 0
    for (let element of step.elements) {
      if (element.id === data.elementId) {
        step.elements.splice(eIndex, 1)
        break
      }
      eIndex++
    }
  },

  [types.FORMBUILDER_UPDATE_TB_ELEMENT_CONTENT]: (state, data) => {
    let element = getStepElementById(state, data.elementId)
    if (!element) {
      return
    }
    element.content = data.content
  },

  /* register and login */
  [types.REGISTER_USER](state, data) { },
  [types.REGISTER_SUCCESS](state) { },
  [types.REGISTER_FAILED](state) { },

  [types.LOGIN_USER](state) { },
  [types.LOGIN_SUCCESS](state, data) {
    state.user = data.user
    state.token = data.token
    state.decodedToken = data.decodedToken
  },
  [types.LOGIN_FAILED](state) { },
  [types.LOGOUT_USER](state) {
    state.user = null
    state.token = null
    state.decodedToken = null
  },

  // theme variants
  [types.FORMBUILDER_SET_THEME_SETTING_FIRST_TIME](state, data) {
    state.formVariantThemes[0] = Object.assign({}, { id: -1 }, data)
  },
  [types.FORMBUILDER_SET_DEFAULT_THEME](state, data) {
    state.formVariantThemes[0] = Object.assign({}, data, JSON.parse(JSON.stringify(state.defaultFormVariantTheme)))
  },
  [types.UPDATE_FORM_THEME_TYPOGRAPHY](state, data) {
    state.formVariantThemes[0].typography = data
  },
  [types.UPDATE_FORM_THEME_GENERAL](state, data) {
    state.formVariantThemes[0].general = data
  },
  [types.UPDATE_FORM_THEME_UI_ELEMENTS](state, data) {
    state.formVariantThemes[0].ui_elements = data
  },
  [types.FORMBUILDER_GET_THEME_SETTING_SUCCESS](state, data) {
    state.formVariantThemes[0] = data
  },

  // theme templates
  [types.FORMBUILDER_GET_FORM_THEME_TEMPLATES_SUCCESS](state, data) {
    state.formThemeTemplates[0] = data
  },
  [types.FORMBUILDER_SET_TEMPLATE_THEME](state, data) {
    state.formVariantThemes[0].general = data.theme.config.general
    state.formVariantThemes[0].typography = data.theme.config.typography
    state.formVariantThemes[0].ui_elements = data.theme.config.ui_elements
  },

  /* forms */
  [types.FETCH_FORM_SUCCESS](state, data) {
    let index = objectExists(state.forms, 'id', data.id)
    if (index === false) {
      index = state.forms.length
    }
    state.forms[index] = data
  },
  [types.FETCH_FORM_ERROR](state, data) {

  },

  [types.FETCH_FORMS_SUCCESS](state, data) {
    if (!data.page) {
      state.forms = data || []
      return
    }
    if (state.forms.length === 0 || data.page === 1) {
      state.forms = data.forms || []
    } else {
      state.forms = state.forms.concat(data.forms)
    }
  },
  [types.FETCH_FORMS_ERROR](state, data) {

  },

  [types.FORM_UPDATE_IFRAME_HEIGHT](state, data) {
    state.formPreview.height = data.height
  },

  [types.FORM_UPDATE_IFRAME_WIDTH](state, data) {
    state.formPreview.width = data.width
  },

  [types.FORM_UPDATE_IFRAME_FORM_SHADOW]: (state, data) => {
    state.formPreview.formShadow = data.formShadow
  },

  [types.FORM_FULL_WIDTH_FORM](state, data) {
    state.fullwidthForm = data
  },

  [types.UPDATE_IFRAME_DIMENSIONS](state, data) {
    state.formPreview.width = data.width
    state.formPreview.height = data.height
    const compositeKey = `form_${data.formId}_variant_${data.variantId}_dimensions`

    const dimensions = {
      width: data.width,
      height: data.height,
      formShadow: data.formShadow,
      fullwidthForm: data.fullwidthForm
    }
    localStorage.setItem(compositeKey, JSON.stringify(dimensions))
  },

  [types.FORM_FETCH_LEADS_SUCCESS](state, data) {
    state.formLeads[data.formId] = data.leads
  },

  [types.FORM_FETCH_LEADS_ERROR](state) { },

  [types.FETCH_LEADS_COUNT](state, data) {
    state.leadsCount = data.count
  },

  /* form hidden fields */
  [types.RESET_FORM_HIDDEN_FIELDS]: (state, data) => {
    state.formHiddenFields = data
  },

  // [types.FETCH_FORM_HIDDEN_FIELDS_SUCCESS](state, data) {
  //   if (!data) {
  //     return
  //   }
  //   if (!state.formHiddenFields) {
  //     state.formHiddenFields = []
  //   }
  //   for (let hf1 of data) {
  //     let found = false
  //     let hf2Index = 0
  //     for (let hf2 of state.formHiddenFields) {
  //       if (hf1.id === hf2.id) {
  //         Vue.set(state.formHiddenFields, hf2Index, hf1)
  //         found = true
  //         break
  //       }
  //       hf2Index++
  //     }
  //     if (!found) {
  //       state.formHiddenFields.push(hf1)
  //     }
  //   }
  // },

  [types.FETCH_FORM_HIDDEN_FIELDS_SUCCESS](state, data) {
    if (!data) {
      return
    }
    if (!state.formHiddenFields) {
      state.formHiddenFields = []
    }
    for (let hf1 of data) {
      let found = false
      let hf2Index = state.formHiddenFields.findIndex(hf2 => hf1.id === hf2.id)

      if (hf2Index !== -1) {
        state.formHiddenFields[hf2Index] = hf1
        found = true
      }

      if (!found) {
        state.formHiddenFields.push(hf1)
      }
    }
  },

  [types.UPDATE_FORM_HIDDEN_FIELDS_SUCCESS]: (state, data) => {
    if (data.deleteExisting) {
      let hf1Index = 0
      for (let hf1 of state.formHiddenFields) {
        for (let hf2 of data.hiddenFields) {
          if (hf1.form_variant_id === hf2.form_variant_id && hf1.form_id === hf2.form_id) {
            state.formHiddenFields.splice(hf1Index, 1)
          }
        }
        hf1Index++
      }
    }

    if (!data.hiddenFields) {
      return
    }

    for (let hiddenField of data.hiddenFields) {
      let index = objectExists(state.formHiddenFields, 'id', hiddenField.id)
      if (index === false) {
        index = state.formHiddenFields.length
      }
      state.formHiddenFields[index] = hiddenField
    }
  },

  [types.REMOVE_FORM_HIDDEN_FIELD]: (state, data) => {
    let index = objectExists(state.formHiddenFields, 'id', data.id)
    if (index === false) {
      return
    }
    state.formHiddenFields.splice(index, 1)
  },

  [types.UPDATE_FORM_HIDDEN_FIELD_ATTRIBUTE]: (state, data) => {
    let index = objectExists(state.formHiddenFields, 'id', data.id)
    if (index === false) {
      return
    }
    let hiddenField = Object.assign({}, state.formHiddenFields[index])
    hiddenField[data.attribute] = data.attributeValue
    state.formHiddenFields[index] = hiddenField
  },

  [types.FETCH_FORMS_COUNT](state, data) {
    state.formsCount = data.count
  },

  [types.FETCH_AVG_CONV_RATE_COUNT](state, data) {
    state.averageConversionCount = data.rate
  },

  [types.FORM_FETCH_SETTING_SUCCESS](state, data) {
    state.formSetting[data.formId] = data.setting
  },

  [types.FORM_FETCH_SETTING_ERROR](state) { },

  [types.FORM_SETTING_UPDATE_FIELD](state, data) {
    if (!state.formSetting[data.formId]) {
      return
    }
    state.formSetting[data.formId][data.field] = data.value
  },

  [types.FORM_FETCH_OVERVIEW_SUCCESS](state, data) {
    if (!state.forms[data.formId]) {
      state.forms[data.formId] = {}
    }
    state.forms[data.formId].overview = data.data
  },
  [types.FORM_FETCH_OVERVIEW_ERROR](state) { },

  [types.FORM_DUPLICATE_SUCCESS](state, data) {
    let index = objectExists(state.forms, 'id', data.id)
    if (index === false) {
      index = state.forms.length
    }
    state.forms[index] = data
  },
  [types.FORM_DUPLICATE_ERROR](state, data) {
  },

  [types.FORM_ARCHIVE_SUCCESS](state, data) {
    state.formArchived = true
  },
  [types.FORM_ARCHIVE_ERROR](state, data) {
    state.formArchived = false
  },

  [types.FORM_DELETE_LEAD_SUCCESS](state, data) {
    let leads = state.formLeads[data.formId]
    let leadIndex = 0
    for (let lead of leads) {
      if (lead.id === data.leadId) {
        break
      }
      leadIndex++
    }
    leads.splice(leadIndex, 1)
  },

  /* form variants */
  [types.FETCH_FORM_VARIANTS_SUCCESS](state, data) {
    state.formVariants = data || []
  },
  [types.FETCH_FORM_VARIANTS_ERROR](state) {
  },

  [types.DUPLICATE_FORM_VARIANT_SUCCESS](state, data) {
    let index = objectExists(state.formVariants, 'id', data.id)
    if (index === false) {
      index = state.formVariants.length
    }
    state.formVariants[index] = data
  },
  [types.DUPLICATE_FORM_VARIANT_ERROR](state, data) {
  },

  [types.DELETE_FORM_VARIANT_SUCCESS](state, data) {
    let index = objectExists(state.formVariants, 'id', data.id)
    if (index === false) {
      index = state.formVariants.length
    }
    state.formVariants[index] = data
  },
  [types.DELETE_FORM_VARIANT_ERROR](state, data) {
  },

  [types.DELETE_FORM_LEAD_SUCCESS](state, data) {
    state.formLeads = data || []
  },
  [types.DELETE_FORM_LEAD_ERROR](state, data) {
  },

  /* form experiments */
  [types.FORM_EXPERIMENT_CREATE_SUCCESS](state, data) {

  },
  [types.FORM_EXPERIMENT_CREATE_ERROR](context) { },

  [types.FETCH_FORM_EXPERIMENTS_SUCCESS](state, data) {
    state.formExperiments = data || []
  },
  [types.FETCH_FORM_EXPERIMENTS_ERROR](state, data) { },

  [types.FETCH_FORM_EXPERIMENT_SUCCESS](state, data) {
    let index = objectExists(state.formExperiments, 'id', data.id)
    if (index === false) {
      index = state.formExperiments.length
    }
    state.formExperiments[index] = data
  },
  [types.FETCH_FORM_EXPERIMENT_ERROR](state, data) {
  },

  [types.FORM_EXPERIMENT_UPDATE_SUCCESS](state, data) {
  },
  [types.FORM_EXPERIMENT_UPDATE_ERROR](state, data) {
  },

  [types.FORM_EXPERIMENT_START_SUCCESS](state, data) {
    let index = objectExists(state.formExperiments, 'id', data.id)
    if (index === false) {
      index = state.formExperiments.length
    }
    state.formExperiments[index] = data
  },
  [types.FORM_EXPERIMENT_START_ERROR](state, data) {
  },

  [types.FORM_EXPERIMENT_END_SUCCESS](state, data) {
    let index = objectExists(state.formExperiments, 'id', data.id)
    if (index === false) {
      index = state.formExperiments.length
    }
    state.formExperiments[index] = data
  },
  [types.FORM_EXPERIMENT_END_ERROR](state, data) {
  },

  [types.FORM_EXPERIMENT_RESULT_SUCCESS](state, data) {
    let index = objectExists(state.formExperimentResults, 'id', data.id)
    if (index === false) {
      index = state.formExperimentResults.length
    }
    state.formExperimentResults[index] = data
  },
  [types.FORM_EXPERIMENT_RESULT_ERROR](state, data) {

  },

  /* form experiment variants */
  [types.FETCH_FORM_EXPERIMENT_VARIANTS_SUCCESS](state, data) {
    state.formExperimentVariants = data || []
  },
  [types.FETCH_FORM_EXPERIMENT_VARIANTS_ERROR](context, data) {
  },

  /* users */
  [types.FETCH_USERS_SUCCESS](state, data) {
    state.users = data
  },

  [types.UPDATE_USER_LOCAL]: (state, data) => {
    state.user = data
  },

  /* plan */
  [types.CHANGE_USER_PLAN_SUCCESS](state, data) {
    state.user.plan = data.plan
  },
  [types.FETCH_PLAN_SUBSCRIPTION_SUCCESS](state, data) {
    let user = Object.assign({}, state.user)
    user.plan.subscription = data
    localStorage.setItem('user', JSON.stringify(user))
    // Vue.set(state, 'user', user)
    state['user'] = user
  },

  [types.FETCH_PLANS_SUCCESS](state, data) {
    state.plans = data || []
  },
  // mark read lead notifications
  [types.MARK_READ_LEAD_ERROR](state, data) {
  },
  // user settings
  [types.USER_SETTING_UPDATE_FIELD](state, data) {
    Vue.set(state.userSettings = data, data.field, data.value)
  }
}

/* check object exists within array of objects by unique key-value */
function objectExists(items, key, value) {
  for (let i = 0; i < items.length; i++) {
    if (items[i][key] === value) {
      return i
    }
  }
  return false
}

function getQuestionById(state, questionId) {
  for (let step of state.formBuildState.steps) {
    for (let question of step.questions) {
      if (question.id === questionId) {
        return question
      }
    }
  }
}

function getStepById(state, stepId) {
  for (let step of state.formBuildState.steps) {
    if (step.id === stepId) {
      return step
    }
  }
}

function getStepElementById(state, questionId) {
  for (let step of state.formBuildState.steps) {
    for (let element of step.elements) {
      if (element.id === questionId) {
        return element
      }
    }
  }
}
