export const planFeatures = {
  featuresPro: [
    '500 Leads/month',
    '20 Forms',
    '20 Partial Leads/month',
    '12,500 Views /month',
    'Contact State',
    'UTM Paramaters',
    [
      'Trustedform',
      'Multiple Form Endings',
      'Account Manager'
    ]
  ],
  featuresScale: [
    '2,000 Leads/month',
    '100 Forms',
    '1,000 Partial Leads/month',
    '25,000 Views/month',
    'Contact State',
    'UTM Paramaters',
    'Trustedform',
    'Multiple Form Endings',
    ['Account Manager']
  ]
}
export const paddleMonthlyPlans = [
  {
    title: 'Pro',
    text: 'Most Popular',
    price: 49,
    publicId: 'paddle_pro',
    features: planFeatures.featuresPro
  },
  {
    title: 'Scale',
    text: 'Grow Faster',
    price: 99,
    publicId: 'paddle_scale',
    features: planFeatures.featuresScale
  }
]

export const paddleYearlyPlans = [
  {
    title: 'Pro',
    text: 'Most Popular',
    price: 39,
    publicId: 'paddle_pro_annual',
    originalPrice: 49,
    features: planFeatures.featuresPro
  },
  {
    title: 'Scale',
    text: 'Grow Faster',
    price: 79,
    publicId: 'paddle_scale_annual',
    originalPrice: 99,
    features: planFeatures.featuresScale
  }
]
