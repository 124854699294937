<template>
  <div id="logo">
    <img src="./../assets/images/leadgen-logo-white.png" alt="Leadgen">
  </div>
</template>

<style lang="scss">
  #logo img {
    max-width: 300px;
  }
  #logo span {
    font-size: 60px;
    text-shadow: -1px -1px 1px rgba(128,128,128,0.48), 2px 2px 1px rgba(128,128,128,0.55);
  }
  #logo span:nth-of-type(1) {
    color: #70180f;
  }
  #logo span:nth-of-type(2) {
    color: #d2883f;
  }
  #logo span:nth-of-type(3) {
    color: #d6dd26;
  }
  #logo span:nth-of-type(4){
    color: #a2ce65;
  }
  #logo span:nth-of-type(5){
    color: #745048;
  }
  #logo span:nth-of-type(6){
    color: #00a29e;
  }
  #logo span:nth-of-type(7){
    color: #26a8e0;
  }

</style>
