export const translations = {
  en: {
    heading: "Generate up to 5x",
    subheading: "More Leads with Online Forms",
    testimonial:
      "I have replaced all forms across many client websites to LeadGen forms and every single one has seen an increase in enquiries.",
    user: "Carlos Luna, Digital Agency Owner",
    title: "LeadGen App",
    googleBtn: "Sign up with Google",
    emailBtn: "Signup with Email",
    nextBtn: "Next",
    createAccount: "Create Your Account",
    finalStep: "Final Step: Verify Your Email",
    step: "Step",
    alreadyHaveAccount: "Already have an account?",
    declaration:
      "By creating a LeadGen account, you're agreeing to accept the LeadGen",
    declarationTerms: "Terms & Conditions",
    declarationPolicy: "Privacy Policy",
    dueToday: "Due Today",
    noCard: "No credit card required",
    fullAccess: "Full plan access for 14 days",
    upgradeAnytime: "Upgrade to any plan inside the app",
    cancelAnytime: "No Risk - Cancel anytime",
    forms: (count) => `Create up to ${count} form${count === 1 ? "" : "s"}`,
    leads: (count) => `Get up to ${count} lead${count === 1 ? "" : "s"}`,
    pricingInfo: {
      monthly: "(billed monthly)",
      yearly: "(billed yearly)"
    }
  },
  fr: {
    heading: "Générez jusqu'à 5 fois plus",
    subheading: "Plus de prospects avec des formulaires en ligne",
    testimonial:
      "J'ai remplacé tous les formulaires de nombreux sites Web de clients par des formulaires LeadGen et chacun d'entre eux a vu une augmentation des demandes.",
    user: "Carlos Luna, Propriétaire d'une agence digitale",
    title: "Application LeadGen",
    googleBtn: "Inscrivez-vous avec Google",
    emailBtn: "Inscription par e-mail",
    nextBtn: "Suivant",
    createAccount: "Créez votre compte",
    finalStep: "Dernière étape : vérifiez votre e-mail",
    step: "Étape",
    alreadyHaveAccount: "Vous avez déjà un compte ?",
    declaration:
      "En créant un compte LeadGen, vous acceptez d'accepter les conditions d'utilisation",
    declarationTerms: "Conditions générales",
    declarationPolicy: "Politique de confidentialité",
    dueToday: "À payer aujourd'hui",
    noCard: "Aucune carte de crédit requise",
    fullAccess: "Accès complet au plan pendant 14 jours",
    upgradeAnytime: "Passez à n'importe quel plan dans l'application",
    cancelAnytime: "Pas de risque - Annulez à tout moment",
    forms: (count) =>
      `Créez jusqu'à ${count} formulaire${count === 1 ? "" : "s"}`,
    leads: (count) =>
      `Obtenez jusqu'à ${count} prospect${count === 1 ? "" : "s"}`,
    pricingInfo: {
      monthly: "(facturé mensuellement)",
      yearly: "(facturé annuellement)"
    }
  },
  es: {
    heading: "Genera hasta 5 veces más",
    subheading: "Más clientes potenciales con formularios en línea",
    testimonial:
      "He reemplazado todos los formularios en muchos sitios web de clientes con formularios LeadGen y cada uno ha visto un aumento en las consultas.",
    user: "Carlos Luna, Propietario de Agencia Digital",
    title: "Aplicación LeadGen",
    googleBtn: "Regístrate con Google",
    emailBtn: "Registrarse con correo electrónico",
    nextBtn: "Siguiente",
    createAccount: "Crea tu cuenta",
    finalStep: "Último paso: verifica tu correo electrónico",
    step: "Paso",
    alreadyHaveAccount: "¿Ya tienes una cuenta?",
    declaration: "Al crear una cuenta de LeadGen, aceptas aceptar los",
    declarationTerms: "Términos y Condiciones",
    declarationPolicy: "Política de Privacidad",
    dueToday: "A pagar hoy",
    noCard: "No se requiere tarjeta de crédito",
    fullAccess: "Acceso completo al plan durante 14 días",
    upgradeAnytime: "Actualiza a cualquier plan dentro de la aplicación",
    cancelAnytime: "Sin riesgo - Cancela en cualquier momento",
    forms: (count) => `Crea hasta ${count} formulario${count === 1 ? "" : "s"}`,
    leads: (count) =>
      `Obtén hasta ${count} cliente${count === 1 ? "" : "s"} potencial${
        count === 1 ? "" : "es"
      }`,
    pricingInfo: {
      monthly: "(facturado mensualmente)",
      yearly: "(facturado anualmente)"
    }
  },
  de: {
    heading: "Generieren Sie bis zu 5x mehr",
    subheading: "Mehr Leads mit Online-Formularen",
    testimonial:
      "Ich habe alle Formulare auf vielen Kunden-Websites durch LeadGen-Formulare ersetzt, und jede einzelne hat einen Anstieg der Anfragen verzeichnet.",
    user: "Carlos Luna, Inhaber einer Digitalagentur",
    title: "LeadGen App",
    googleBtn: "Mit Google anmelden",
    emailBtn: "Mit E-Mail anmelden",
    nextBtn: "Weiter",
    createAccount: "Erstellen Sie Ihr Konto",
    finalStep: "Letzter Schritt: Bestätigen Sie Ihre E-Mail",
    step: "Schritt",
    alreadyHaveAccount: "Haben Sie schon ein Konto?",
    declaration: "Indem Sie ein LeadGen-Konto erstellen, akzeptieren Sie die",
    declarationTerms: "Allgemeine Geschäftsbedingungen",
    declarationPolicy: "Datenschutzbestimmungen",
    dueToday: "Fällig heute",
    noCard: "Keine Kreditkarte erforderlich",
    fullAccess: "Voller Planzugang für 14 Tage",
    upgradeAnytime: "Wechseln Sie zu einem beliebigen Plan in der App",
    cancelAnytime: "Kein Risiko – jederzeit kündbar",
    forms: (count) =>
      `Erstellen Sie bis zu ${count} Formular${count === 1 ? "" : "e"}`,
    leads: (count) =>
      `Erhalten Sie bis zu ${count} Lead${count === 1 ? "" : "s"}`,
    pricingInfo: {
      monthly: "(monatlich abgerechnet)",
      yearly: "(jährlich abgerechnet)"
    }
  },
  pt: {
    heading: "Gere até 5x mais",
    subheading: "Mais leads com formulários online",
    testimonial:
      "Eu substituí todos os formulários em muitos sites de clientes por formulários LeadGen, e cada um viu um aumento nas consultas.",
    user: "Carlos Luna, Proprietário de Agência Digital",
    title: "Aplicativo LeadGen",
    googleBtn: "Inscreva-se com o Google",
    emailBtn: "Inscreva-se por e-mail",
    nextBtn: "Próximo",
    createAccount: "Crie sua conta",
    finalStep: "Última etapa: verifique seu e-mail",
    step: "Passo",
    alreadyHaveAccount: "Já tem uma conta?",
    declaration: "Ao criar uma conta LeadGen, você concorda em aceitar os",
    declarationTerms: "Termos e Condições",
    declarationPolicy: "Política de Privacidade",
    dueToday: "Vencimento hoje",
    noCard: "Nenhum cartão de crédito necessário",
    fullAccess: "Acesso total ao plano por 14 dias",
    upgradeAnytime: "Faça upgrade para qualquer plano dentro do aplicativo",
    cancelAnytime: "Sem risco - Cancele a qualquer momento",
    forms: (count) => `Crie até ${count} formulário${count === 1 ? "" : "s"}`,
    leads: (count) => `Obtenha até ${count} lead${count === 1 ? "" : "s"}`,
    pricingInfo: {
      monthly: "(cobrado mensalmente)",
      yearly: "(cobrado anualmente)"
    }
  }
}

// Mixin that references the translations constant
export const i18nMixin = {
  methods: {
    getTranslation(lang) {
      console.log("Fetching translation for lang:", lang)
      return translations[lang] || translations.en // Return English as default if lang not found
    }
  }
}
