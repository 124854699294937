import providers from "@/shared/constants/socialauth/providers"

export default {
  data() {
    return {
      socialSignUpOrLogin: {
        isSuccess: false,
        isSocialLogin: false,
        providerGoogle: providers.GOOGLE
      }
    }
  },
  computed: {
    authMixin_token() {
      return this.$store.getters.token
    }
  },
  methods: {
    authMixin_resetSocialSignUpOrLogin() {
      Object.assign(this.socialSignUpOrLogin, {
        isSuccess: false,
        isSocialLogin: false
      })
    },
    setAlert(message, error = false) {
      Object.assign(this.alert, {
        show: true,
        error,
        message
      })
    },
    authMixin_loginOrSignUpWithProvider(provider, action) {
      this.authMixin_resetSocialSignUpOrLogin()
      this.socialSignUpOrLogin.isSocialLogin = action

      const socialPopup = window.open(
        "",
        "New Window",
        `width=600,height=${window.screen.height}`
      )

      if (!socialPopup) {
        this.setAlert(
          "Failed to open authentication window. Please check your pop-up blocker settings."
        )
        return
      }

      this.$store
        .dispatch("socialauth/getProviderRedirectUrl", provider)
        .then((response) => {
          socialPopup.location.href = response.data.redirectUrl

          const checkPopupInterval = setInterval(() => {
            if (socialPopup.closed) {
              clearInterval(checkPopupInterval)
              window.removeEventListener("message", messageHandler)
              if (!this.socialSignUpOrLogin.isSuccess) {
                if (this.loader) {
                this.loader.login = false
                } else {
                  this.flag.googleBtn = false
                }
                this.authMixin_resetSocialSignUpOrLogin();
              }
            }
          }, 500)

          const messageHandler = (event) => {
            if (event.source === socialPopup) {
              window.removeEventListener("message", messageHandler)
              clearTimeout(popupCloseTimeout)
              if (event.data && typeof event.data === "string") {
                localStorage.setItem("social_auth", event.data)
                console.log('-----------', this)
                this.socialSignUpOrLogin.isSuccess = true;
                this.authMixin_handleProviderResponse(provider)
              } else {
                this.setAlert(
                  "Invalid response received from authentication window."
                )
              }
              socialPopup.close()
            }
          }

          window.addEventListener("message", messageHandler)

          const timeoutInSeconds = 30
          const popupCloseTimeout = setTimeout(() => {
            if (!socialPopup.closed) {
              socialPopup.close()
              this.setAlert("Authentication window closed due to timeout.")
            }
            window.removeEventListener("message", messageHandler)
          }, timeoutInSeconds * 1000)
        })
        .catch((err) => {
          this.authMixin_resetSocialSignUpOrLogin()
          this.setAlert(
            err?.response?.data?.meta?.error_message ||
              "Something went wrong! Please try again later.",
            true
          )
          socialPopup.close()
        })
    },
    authMixin_handleProviderResponse(provider) {
      const socialAuthRaw = localStorage.getItem("social_auth")

      if (!socialAuthRaw) {
        this.setAlert("No authentication data received. Please try again.")
        return
      }

      try {
        const socialAuthResponse = JSON.parse(socialAuthRaw)
        const { original } = socialAuthResponse

        if (original?.data && original?.meta?.code === 201) {
          const method = this.socialSignUpOrLogin.isSocialLogin
            ? this.socialAccountLogin
            : this.socialAccountRegister
          method(provider, original.data)
        } else {
          this.setAlert(
            "Unexpected response structure from social authentication."
          )
        }
      } catch (err) {
        this.setAlert("Error processing social authentication response.")
      } finally {
        localStorage.removeItem("social_auth")
      }
    }
  }
}
