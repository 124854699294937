<template>
  <v-container class="billing-view">
    <div v-if="loader.billing">
      <!-- BILLING LOADER-->
      <div class="billing-view__loading" v-if="!loader.error">
        <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
          color="secondary-red"></v-progress-circular>
      </div>
      <!-- BILLING LOAD ERROR-->
      <div class="billing-view__error" v-else>
        <h3>Whoops, looks like something went wrong. Please click the button to try again.</h3>
        <v-btn variant="elevated" color="primary-red" class="text-none font-weight-bold" rounded="md" size="large"
          prepend-icon="mdi-reload" @click="reloadPage()">
          Reload
        </v-btn>
      </div>
    </div>
    <!-- BILLING CONTENT -->
    <div class="content-billing" v-else>
      <!-- page heading -->
      <v-row>
        <v-col cols="12">
          <h5 class="font-weight-black text-black">Billing</h5>
          <p class="text-black">Change your plan, check usage, and download invoices.</p>
        </v-col>
      </v-row>
      <!-- alerts -->
      <v-row>
        <v-col cols="12">
          <v-alert color="blue" density="compact" rounded="md" variant="tonal" closable>
            <v-alert-title class="text-black">
              <v-icon class="text-blue mr-2">mdi-information</v-icon>
              Maxed out your account limits? Time to think about an&nbsp;
              <a class="text-blue font-weight-bold pt-1" @click="$router.push({ name: 'plans.index' })">
                upgrade</a>.
            </v-alert-title>
          </v-alert>
        </v-col>
        <v-col cols="12" v-if="alerts.leadsMsg || alerts.partialLeadsMsg">
          <v-alert color="green" density="compact" rounded="md" variant="tonal">
            <v-alert-title class="text-black">
              <v-icon class="text-black mr-2">mdi-bullhorn</v-icon>
              The count resets&nbsp;
              <span class="alert-span" v-if="alerts.partialLeadsMsg">
                <b>{{ getResetPeriod(featureProperties.NO_OF_PARTIAL_LEADS) }}</b>&nbsp;for partial leads
              </span>
              <span class="alert-span" v-if="alerts.leadsMsg && alerts.partialLeadsMsg">
                ,&nbsp;
              </span>
              <span class="alert-span" v-if="alerts.leadsMsg">
                <b>{{ getResetPeriod(featureProperties.NO_OF_LEADS) }}</b>&nbsp;for leads
              </span>.
            </v-alert-title>
          </v-alert>
        </v-col>
      </v-row>
      <!-- limits & usage -->
      <v-row>
        <!-- forms limit -->
        <v-col cols="12" md="4">
          <v-card variant="outlined" rounded="md" color="purple-accent-3 pa-3">
            <v-card-title class="font-weight-bold text-black text-capitalize">
              Forms
            </v-card-title>
            <v-card-text>
              <v-progress-linear color="purple-accent-3" rounded="sm" bg-opacity="0.3" height="10"
                :model-value="formsCount"></v-progress-linear>
              <div class="text-right pt-5 mb-0 text-grey-darken-3 text-capitalize">
                {{ formsCount }} of {{ user.plan.form_limit }} used
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- leads limit -->
        <v-col cols="12" md="4">
          <v-card variant="outlined" rounded="md" color="pink pa-3">
            <v-card-title class="font-weight-bold text-black text-capitalize">
              Leads
            </v-card-title>
            <v-card-text>
              <v-progress-linear color="pink" rounded="sm" bg-opacity="0.3" height="10"
                :model-value="getPropertyUsage(usageCount.LEADS, featureProperties.LEADS)"></v-progress-linear>
              <div class="text-right pt-5 mb-0 text-grey-darken-3 text-capitalize">
                {{ getPropertyUsage(usageCount.LEADS, featureProperties.NO_OF_LEADS) }} of
                {{ getPropertyData(featureProperties.NO_OF_LEADS).value }} used
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- partial leads limit -->
        <v-col cols="12" md="4">
          <v-card variant="outlined" rounded="md" color="blue pa-3">
            <v-card-title class="font-weight-bold text-black text-capitalize">
              Partial Leads
            </v-card-title>
            <v-card-text>
              <v-progress-linear color="blue" rounded="sm" bg-opacity="0.3" height="10"
                :model-value="getPropertyUsage(usageCount.PARTIAL_LEADS, featureProperties.PARTIAL_LEADS)"></v-progress-linear>
              <div class="text-right pt-5 mb-0 text-grey-darken-3 text-capitalize">
                {{ getPropertyUsage(usageCount.PARTIAL_LEADS, featureProperties.NO_OF_PARTIAL_LEADS) }} of
                {{ getPropertyData(featureProperties.NO_OF_PARTIAL_LEADS).value }} used
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- invoice data table -->
      <v-row>
        <v-col cols="12">
          <!-- tabs panel layout -->
          <v-tabs v-model="tabs.tab" bg-color="white" color="primary-red" class="ml-2">
            <v-tab value="category" class="font-weight-black pl-0">INVOICES</v-tab>
          </v-tabs>
          <!-- invoices -->
          <v-tabs-window v-model="tabs.tab">
            <v-tabs-window-item value="category">
              <p v-if="!hasSubscription" class="py-5 pl-2 text-left">You don't have any subscription.</p>
              <v-data-table v-else class="mt-3 pa-2" rounded="md" :headers="transaction.headers"
                :items="transaction.invoices" :loading="loader.invoice" loading-text="Loading... Please wait"
                no-data-text="No invoices yet" v-model:page="transaction.page"
                v-model:items-per-page="transaction.itemsPerPage">
                <template v-slot:loader>
                  <v-progress-linear color="secondary-red" indeterminate height="2" width="100"></v-progress-linear>
                </template>
                <template v-slot:item.serial="{ item, index }">
                  {{ (transaction.page - 1) * transaction.itemsPerPage + index + 1 }}
                </template>
                <template v-slot:item.status="{ item }">
                  <v-chip :color="item.status === 'completed' ? 'primary-green' : 'secondary-red'" size="small"
                    variant="flat" class="font-weight-bold">
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:item.receipt_url="{ item }">
                  <v-btn class="ma-2" size="small" color="secondary-red" icon="mdi-eye" variant="text"
                    @click="viewInvoice(item.receipt_url)"></v-btn>
                </template>
              </v-data-table>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-col>
      </v-row>
      <!-- snackbar -->
      <v-row>
        <v-col cols="12">
          <v-snackbar rounded="md" height="50" :color="snackbar.error ? 'secondary-red' : 'primary-green'"
            :timeout="3000" v-model="snackbar.show">
            <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.text }} </p>
          </v-snackbar>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import helperMixin from '@/mixins/helper'
import format from '@/mixins/format'
import userMixin from '@/mixins/user'
import planMixin from '@/mixins/plan'
import packageBuilderMixin from '@/mixins/package-builder'
import { mapGetters } from 'vuex'
import { usageCount } from '@/shared/constants/packagebuilder/features'
import { featureProperties } from '../shared/constants/packagebuilder/featureProperties'
import timePeriods from '@/store/time-periods'

export default {
  mixins: [userMixin, planMixin, helperMixin, packageBuilderMixin, format],
  data() {
    return {
      tabs: {
        tab: 'invoice'
      },
      loader: {
        billing: true,
        error: false,
        invoice: true
      },
      snackbar: {
        show: false,
        text: null,
        error: false
      },
      transaction: {
        invoices: [],
        headers: [
          { title: '#', key: 'serial', align: 'start', sortable: false },
          { title: 'Date', key: 'created_at', align: 'start' },
          { title: 'Amount', key: 'amount', align: 'start', sortable: false },
          { title: 'Order ID', key: 'order_id', align: 'start', sortable: false },
          { title: 'Status', key: 'status', align: 'start' },
          { title: 'Invoice', key: 'receipt_url', align: 'start', sortable: false },
        ],
        page: 1,
        itemsPerPage: 10,
      },
      limits: [],
      alerts: {
        partialLeadsMsg: true,
        leadsMsg: true
      }
    }
  },
  mounted() {
    this.packageBuilderMixin_completeLeadCount()
    this.packageBuilderMixin_partialLeadsCount()
    this.packageBuilderMixin_formCount()
    this.listTransaction()
    setTimeout(() => {
      this.getPlanFeatures(this.user.id)
    }, 200)
  },
  computed: {
    featureProperties() {
      return featureProperties
    },
    usageCount() {
      return usageCount
    },
    ...mapGetters([
      'formsCount'
    ])
  },
  methods: {
    listTransaction() {
      this.$store.dispatch('subscription/listTransaction', {
        id: this.user.plan.subscription_id
      }).then((response) => {
        this.transaction.invoices = response.data.data
        this.loader.invoice = false
      }, () => {
        this.loader.invoice = false
      })
    },
    viewInvoice(data) {
      if (data) {
        window.open(data, '_blank')
      } else {
        this.showSnackbar(true, 'OOPS! Unable to fetch invoice.')
      }
    },
    showSnackbar(flag, message) {
      this.snackbar.show = true
      this.snackbar.error = flag
      this.snackbar.text = message
    },
    reloadPage() {
      window.location.reload()
    },
    getPlanFeatures(id) {
      this.planMixin_getPlanFeatures(id)
        .then((response) => {
          const planFeatures = response.plan.plan_features
          const result = planFeatures.flatMap((feature) =>
            feature.feature_properties.map((property) => ({
              title: property.title,
              reset_period: property.reset_period,
              value: property.value
            }))
          )
          this.limits = result
          this.loader.billing = false
        })
        .catch((err) => {
          this.loader.error = true
        })
    },
    getPropertyData(propertyName) {
      const foundProperty = this.limits.find((property) => property.title === propertyName)
      return foundProperty ? foundProperty : 'N/A'
    },
    getResetPeriod(property) {
      const propertyData = this.getPropertyData(property)
      const { alerts } = this
      const alertMap = {
        [featureProperties.NO_OF_LEADS]: 'leadsMsg',
        [featureProperties.NO_OF_PARTIAL_LEADS]: 'partialLeadsMsg'
      }

      if (propertyData.reset_period === timePeriods.NONE || propertyData === 'N/A') {
        const alertKey = alertMap[property]
        if (alertKey) {
          alerts[alertKey] = false
        }
      }
      return this.formatMixin_changeCase(propertyData.reset_period)
    },
    getPropertyUsage(feature, propertyName) {
      const propertyData = this.getPropertyData(propertyName)
      const usageProperty = this.count[feature]

      if (propertyData.reset_period in usageProperty) {
        const usageCount = usageProperty[propertyData.reset_period]
        return usageCount
      }
      return 'N/A'
    }
  }
}
</script>
