<template>
  <v-container style="width: 80%;" class="lead-proof-manage-view">
    <v-row class="align-center justify-space-between mt-n6">
      <v-col cols="12" md="6" class="text-left">
        <router-link :to="{ name: 'leadproofs.index.list' }" class="text-primary-red mb-5 link">
          <v-icon left>mdi-arrow-left</v-icon>
          Lead Proofs
        </router-link>
      </v-col>
    </v-row>

    <div class="loader-container" v-if="isLoading">
      <div class="d-flex justify-center align-center" style="height: 90vh;">
        <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
          color="secondary-red"></v-progress-circular>
      </div>
    </div>

    <div v-else>
      <div class="mb-4 d-flex align-center justify-space-between">
        <div class="mb-n4" v-if="code.length > 0 && showCode">
          Embed below script on your Website/Landing page to see lead proofs.
        </div>
        <div v-else class="flex-grow-1"></div>
        <v-btn variant="flat" type="submit" :loading="submitting" :prepend-icon="isEditView ? 'mdi-update' : 'mdi-plus'"
          class="bg-primary-red font-weight-bold text-none px-4" @click="onFormSubmit">
          {{ isEditView ? 'Update' : 'Create' }} Proof
        </v-btn>
      </div>

      <v-card v-if="code.length > 0 && showCode" color="black" class="my-3 rounded-thin overflow-hidden" elevation="0">
        <v-card-text class="pa-0 position-relative">
          <div class="d-flex align-center">
            <v-sheet class="overflow-x-auto bg-black flex-grow-1">
              <pre
                class="my-0 bg-black py-4"><code class="language-html pl-3 bg-black" v-html="highlightedCode"></code></pre>
            </v-sheet>
            <v-btn icon size="x-small" color="grey-lighten-2" class="mr-3" @click="copyCode" title="Copy">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>

      <v-card rounded="md" elevation="0" class="border-thin border-t-thin rounded-t-sm">
        <v-card-title class="bg-blue-lighten-5 text-primary-red">
          <h6 class="d-flex justify-center mb-0">
            <v-icon color="primary-blue" icon="mdi-format-list-text" class="pt-1"></v-icon>
            <p class="font-weight-bold text-primary-blue mb-0">&nbsp;
              {{ isEditView ? 'Update' : 'Create' }} Lead Proof
            </p>
          </h6>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="onFormSubmit" ref="form">
            <v-row class="px-10 py-5">
              <v-col class="pt-3 pb-0 px-3" cols="12" md="6">
                <v-text-field prepend-inner-icon="mdi mdi-text-short" placeholder="Enter Title" v-model="formData.title"
                  :rules="$valid([{ rule: 'required' }, { rule: 'between', min: '3', max: '50' }])" label="Title"
                  variant="outlined" density="default" required>
                </v-text-field>
              </v-col>
              <v-col class="pt-3 pb-0 px-3" cols="12" md="6">
                <v-text-field prepend-inner-icon="mdi mdi-text-long" placeholder="Enter Description"
                  v-model="formData.description"
                  :rules="$valid([{ rule: 'required' }, { rule: 'between', min: '5', max: '300' }])" label="Description"
                  variant="outlined" required density="default">
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0 px-3">
                <v-autocomplete prepend-inner-icon="mdi mdi-form-select" placeholder="Choose Form"
                  v-model="formData.form" :items="forms" item-title="title" item-value="id" label="Choose Form"
                  variant="outlined" density="default" :rules="$valid([{ rule: 'required' }])"
                  @update:modelValue="fetchFormVariants">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="py-0 px-3">
                <v-autocomplete prepend-inner-icon="mdi mdi-form-dropdown" placeholder="Choose Form Variant"
                  v-model="formData.variant" :items="variants" item-title="title" item-value="id"
                  label="Choose Form Variant" variant="outlined" density="default" :disabled="!formData.form"
                  :rules="$valid([{ rule: 'required' }])" @update:modelValue="fetchFormVariantState">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="py-0 px-3">
                <v-autocomplete prepend-inner-icon="mdi mdi-tooltip-question" placeholder="Choose Variant Question"
                  v-model="formData.question" :items="questions" item-title="title" item-value="id" variant="outlined"
                  density="default" label="Choose Variant Question" :disabled="!formData.variant"
                  :rules="$valid([{ rule: 'required' }])">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="py-0 px-3">
                <v-text-field prepend-inner-icon="mdi mdi-counter" v-model.number="formData.count"
                  label="Count (Number of leads to show.)" variant="outlined" density="default"
                  :rules="$valid([{ rule: 'required' }, { rule: 'number' }])" required>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0 px-3">
                <v-text-field prepend-inner-icon="mdi mdi-timer-sync" v-model.number="formData.delay"
                  label="Delay (Number of seconds to delay for showing the leads.)" variant="outlined" density="default"
                  :rules="$valid([{ rule: 'required' }, { rule: 'number' }])" required>
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="px-10 py-0">
              <v-col cols="12" md="6">
                <div class="d-flex">
                  <v-switch v-model="showFirstPart" inset hide-details color="primary-red"></v-switch>
                  <span class="ml-3 mt-4"><strong>Show First Part Only</strong> (Displays only the first word of a
                    response.)</span>
                </div>
                <div class="d-flex">
                  <v-switch v-model="latest" inset hide-details color="primary-red"></v-switch>
                  <span class="ml-3 mt-4"><strong>Show Latest or Recent Leads</strong> (Disable to load older
                    leads.)</span>
                </div>
              </v-col>

              <v-col cols="12" md="6">
                <div class="d-flex">
                  <v-switch v-model="showCountry" inset hide-details color="primary-red"></v-switch>
                  <span class="ml-3 mt-4"><strong>Show Country</strong> (Displays the country of the lead.)</span>
                </div>
                <div class="d-flex">
                  <v-switch v-model="showTimestamp" inset hide-details color="primary-red"></v-switch>
                  <span class="ml-3 mt-4"><strong>Show Timestamp</strong> (Displays when the lead was
                    received.)</span>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <v-snackbar rounded="md" height="50" :color="snackbar.color" :timeout="snackbar.timeout" v-model="snackbar.show">
      <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.text }} </p>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ref, reactive, computed, onMounted, watch, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import * as actionTypes from '@/store/action-types'
import config from '@/api/config'
import errorTypes from '@/store/error-types'
import Prism from 'prismjs'

export default {
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const formData = reactive({
      title: '',
      description: 'Recently signed up for LeadGen',
      form: null,
      variant: null,
      question: null,
      count: 10,
      delay: 5,
      refId: ''
    })

    const variantStates = ref([])
    const fetchingVariantState = ref(false)
    const submitting = ref(false)
    const showCode = ref(false)
    const form = ref(null)
    const loadingFormVariant = ref(false)
    const isLoading = ref(true)

    const snackbar = reactive({
      show: false,
      text: '',
      color: 'success',
      timeout: 5000
    })

    const showFirstPart = ref(true)
    const showTimestamp = ref(true)
    const showCountry = ref(true)
    const latest = ref(true)

    const isEditView = computed(() => route.name === 'leadproofs.edit')
    const forms = computed(() => store.state.forms || [])
    const variants = computed(() => formData.form ? store.getters.getFormVariants(formData.form).map(v => ({ id: v.id, title: v.title })) : [])

    const showSnackbar = (message, options = {}) => {
      Object.assign(snackbar, {
        text: message,
        color: options.color || 'success',
        timeout: options.timeout || 5000,
        show: true
      })
    }

    const fetchForms = () => {
      store.dispatch(actionTypes.FETCH_FORMS)
        .then(response => store.dispatch(actionTypes.FETCH_FORMS_SUCCESS, response.data.data))
        .catch(() => store.dispatch(actionTypes.FETCH_FORMS_SUCCESS, []))
    }

    const fetchFormVariants = (reset = true) => {
      if (reset) {
        formData.variant = ''
        formData.question = ''
      }

      if (!formData.form) return

      loadingFormVariant.value = true

      store.dispatch(actionTypes.FETCH_FORM_VARIANTS, { formId: formData.form })
        .then(response => {
          store.dispatch(actionTypes.FETCH_FORM_VARIANTS_SUCCESS, response.data.data)
          loadingFormVariant.value = false
        })
        .catch(() => {
          store.dispatch(actionTypes.FETCH_FORM_VARIANTS_ERROR, { formId: formData.form })
          loadingFormVariant.value = false
        })
    }

    const fetchFormVariantState = () => {
      formData.question = ''
      if (!formData.variant || variantStates.value.some(v => v.id === formData.variant)) return

      fetchingVariantState.value = true
      store.dispatch(actionTypes.FORM_FETCH_VARIANT_STATE, { formId: formData.form, variantId: formData.variant })
        .then(response => {
          fetchingVariantState.value = false
          variantStates.value.push(response.data.data)
        })
    }

    const fetchLeadProof = async () => {
      isLoading.value = true
      try {
        const response = await store.dispatch(actionTypes.FETCH_LEAD_PROOF, { id: route.params.id })
        const leadProof = response.data.data

        variantStates.value.push(leadProof.form_variant)

        Object.assign(formData, {
          title: leadProof.title,
          description: leadProof.description,
          form: leadProof.form.id,
          variant: leadProof.form_variant.id,
          question: leadProof.form_question.dbId,
          count: leadProof.count,
          delay: leadProof.delay,
          refId: leadProof.ref_id
        })

        showFirstPart.value = Boolean(leadProof.show_firstpart_only)
        showTimestamp.value = Boolean(leadProof.show_timestamp)
        showCountry.value = Boolean(leadProof.show_country)
        latest.value = Boolean(leadProof.latest)

        await fetchFormVariants(false)
        showCode.value = true
      } catch (error) {
        showSnackbar('Failed to fetch lead proof data. Please try again.', { color: 'secondary-red' })
      } finally {
        isLoading.value = false
      }
    }

    const questions = computed(() => {
      if (!formData.variant) return []

      const variantState = variantStates.value.find(v => v.id === formData.variant)
      if (!variantState) return []

      return variantState.steps.flatMap((step, stepIndex) =>
        step.questions
          .filter(question => [store.getters.questionTypesMap.SHORT_TEXT, store.getters.questionTypesMap.FIRST_NAME].includes(question.type))
          .map((question, questionIndex) => ({
            id: question.dbId,
            title: `${question.title} (Step ${stepIndex + 1} Question ${questionIndex + 1})`
          }))
      )
    })

    const onFormSubmit = async () => {
      const { valid } = await form.value.validate()
      if (!valid) {
        return
      }

      submitting.value = true

      try {
        const data = {
          title: formData.title,
          description: formData.description,
          form_variant_id: formData.variant,
          form_question_id: formData.question,
          show_firstpart_only: showFirstPart.value,
          show_timestamp: showTimestamp.value,
          show_country: showCountry.value,
          latest: latest.value,
          count: formData.count,
          delay: formData.delay
        }

        const action = isEditView.value ? actionTypes.UPDATE_LEAD_PROOF : actionTypes.CREATE_LEAD_PROOF
        const payload = isEditView.value ? { id: parseInt(route.params.id), data } : data

        const response = await store.dispatch(action, payload)
        showSnackbar(`Lead proof ${isEditView.value ? 'updated' : 'created'} successfully.`)

        if (!isEditView.value) {
          router.push({ name: 'leadproofs.edit', params: { id: response.data.data.id } })
        }
      } catch (error) {
        const errorMessage = error.response?.data?.meta?.error_type === errorTypes.INAPPROPRIATE_PLAN
          ? 'Please upgrade your plan to create lead proof.'
          : 'Unable to save. Please try again.'
        showSnackbar(errorMessage, { color: 'secondary-red' })
      } finally {
        submitting.value = false
      }
    }

    const code = computed(() => formData.refId ? `<script src="${config.proofsUrl}/${formData.refId}" async><\/script>` : '')
    const highlightedCode = computed(() => code.value ? Prism.highlight(code.value, Prism.languages.html, 'html') : '')

    const copyCode = () => {
      navigator.clipboard.writeText(code.value)
        .then(() => showSnackbar('Code copied to clipboard!'))
    }

    const highlightCode = async () => {
      await nextTick()
      Prism.highlightAll()
    }

    onMounted(async () => {
      isLoading.value = true
      await fetchForms()
      if (isEditView.value) {
        await fetchLeadProof()
      }
      isLoading.value = false
      await highlightCode()
    })

    watch(route, async () => {
      if (isEditView.value) {
        await fetchLeadProof()
        await highlightCode()
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    })

    watch(showCode, highlightCode)
    watch(code, highlightCode)

    return {
      form,
      loadingFormVariant,
      formData,
      variantStates,
      fetchingVariantState,
      submitting,
      showCode,
      snackbar,
      isEditView,
      forms,
      variants,
      questions,
      fetchFormVariants,
      fetchFormVariantState,
      onFormSubmit,
      showFirstPart,
      showTimestamp,
      showCountry,
      latest,
      code,
      copyCode,
      config,
      isLoading,
      showSnackbar,
      highlightedCode
    }
  }
}
</script>
