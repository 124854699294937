<template>
  <v-card elevation="1">
    <v-card-title @click="toggleCollapse" class="d-flex justify-space-between align-center"
      style="cursor: pointer; background-color: lightgray">
      <slot name="header">
        {{ title }}
      </slot>
      <v-icon>{{ isOpen ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
    </v-card-title>
    <v-expand-transition>
      <div v-show="isOpen">
        <v-card-text>
          <slot name="body">
            Default Body
          </slot>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { TRUE } from 'sass';

export default {
  props: {
    title: {
      type: String,
      default: 'Default Header'
    },
    open: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isOpen: this.open
    };
  },
  watch: {
    open(newValue, oldValue) {
      this.isOpen = newValue
    }
  },
  methods: {
    toggleCollapse() {
      this.isOpen = !this.isOpen;
      this.$emit(this.isOpen ? 'open' : 'close');
    }
  }
};
</script>

<style scoped>
/* Optional: Add your custom styles here */
</style>
