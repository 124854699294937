<template>
  <div id='main-wrapper-form-variant-theme'>
    <div class='main-container' @click.capture='showChromePicker(1)'>
      <ui-tabs type="text" background-color="white" text-color-active="grey" text-color="black"
        v-if="chromePickers.length === chromePickersCount">
        <ui-tab title="Themes">
          <div class='theme-setting-both-sides'>
            <!-- LEFT SIDE START -->
            <div class='left-side'>
              <form-theme-store :formId="parseInt($route.params.id)" :formVariantId="parseInt($route.params.variantId)"
                v-on:addStyle="addStyle" />
            </div>
            <!-- LEFT SIDE END -->

            <!-- RIGHT SIDE START -->
            <div class='right-side'>
              <div class='preview'>Preview</div>
              <div class='right-side-bg'
                :style="'font-family:' + general.font.family + ';background-color:' + uiElements.background.color">

                <div class='form-demo' :style="formDemoStyle" :class='{ formShadow: uiElements.background.formShadow }'>
                  <div class='question' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">Question
                  </div>
                  <div class='question_des' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_description.font.color,
                    fontSize: typography.question_description.font.size + 'px',
                    fontFamily: typography.question_description.font.family,
                    fontWeight: typography.question_description.font.weight,
                    lineHeight: typography.question_description.font.line_height + 'px',
                    textAlign: typography.question_description.text_align
                  }"> This is the question description.
                  </div>
                  <input type="text" class='activeone' value='question answer' :style="{
                    textAlign: typography.input_box.text_align
                  }">
                  <div class='required' :style="{ color: general.colors.warning_color }"> This field is required </div>
                  <div class='question2' :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">
                    Which Platform(s) will the app will be build on ?<span
                      v-if='uiElements.question.asterisk_for_required'> *</span>
                  </div>
                  <ul class="radio-list">
                    <li v-for="(choice, cindex) in choices" :key="cindex">
                      <input name='question1' type="radio">
                      <label :for="'choice' + cindex" class="checkbox-label">{{ choice }}</label>
                    </li>
                  </ul>

                  <div class='gdpr'>
                    <div :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }">
                      GDPR Marketing Permissions
                    </div>
                    <div class='grdp-details' :style="{
                      color: typography.question_description.font.color,
                      fontSize: typography.question_description.font.size + 'px',
                      fontFamily: typography.question_description.font.family,
                      fontWeight: typography.question_description.font.weight,
                      lineHeight: typography.question_description.font.line_height + 'px',
                      textAlign: typography.question_description.text_align
                    }"> We will use the information you provide
                      on this form to be in touch with you and to provide updates and marketing. Please let us know all
                      the ways you like to hear from us.
                    </div>
                    <div class="gdpr-terms">
                      <ui-checkbox-group vertical :options="['Email', 'Direct Post', 'Customized online advertising']"
                        v-model="gdpr"></ui-checkbox-group>
                    </div>
                    <p :style='{ color: theme.general.colors.text_color }'><small>We use <strong>Leadgen</strong> as our
                        marketing automation platform. By submitting this form, you acknowledge that the information you
                        provide will be transferred to <strong>Leadgen</strong>.</small></p>
                  </div>

                  <div class='both-buttons'>
                    <div class="back-button-wrapper">
                      <div :style="'width:' + uiElements.step_navigation.back_button.width + '%;'"
                        v-if="!uiElements.step_navigation.back_button.hide">
                        <ui-button buttonType='button' :style="backButtonStyle"
                          :class='{ shadow: uiElements.step_navigation.back_button.shadow }'>
                          <i class="material-icons">{{ uiElements.step_navigation.back_button.icon }}</i>&nbsp;
                          {{ uiElements.step_navigation.back_button.text }}
                        </ui-button>
                      </div>
                    </div>

                    <div class="continue-button-wrapper" :class='{
                      btnFull: uiElements.step_navigation.back_button.hide,
                      btnHalf: !uiElements.step_navigation.back_button.hide
                    }'>
                      <div :style="'width:' + uiElements.step_navigation.next_button.width + '%'">
                        <ui-button buttonType='button' :style="continueButtonStyle"
                          :class='{ shadow: uiElements.step_navigation.next_button.shadow }'>
                          {{ uiElements.step_navigation.next_button.text }}
                          &nbsp;<i class="material-icons">{{ uiElements.step_navigation.next_button.icon }}</i>
                        </ui-button>
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>
            <!-- RIGHT SIDE END -->
          </div>
        </ui-tab>


        <!-- GENERAL -->
        <ui-tab title="Theme Settings">
          <div class='theme-setting-both-sides'>
            <!-- LEFT SIDE START -->
            <div class='left-side'>
              <h3>Theme Settings</h3>
              <p> Theme settings gives you quick access to commonly used theme default settings. A deeper level of
                customization is available under the <span class='typ_ui'>Typography </span>, <span class='typ_ui'> UI
                  Elements </span> and <span class='typ_ui'> Step Navigation </span>sections.</p>
              <div class='color-palette-fonts'> Color Palette </div>
              <div class='general-style-wrapper'>
                <div class='general-styles-options'>
                  <label> Active Color</label>
                  <div class='color-wrapper'>
                    <div class="color-palettes" :style="{ backgroundColor: general.colors.active_color }"
                      @click='showChromePicker(2)'>
                    </div>
                    <ui-textbox :value="(general.colors.active_color)"
                      @input="updateGeneralSetting($event, 'generalActiveColor')">
                    </ui-textbox>
                  </div>
                  <chrome-picker :value="(general.colors.active_color)"
                    @input="updateGeneralSetting($event, 'generalActiveColor')"
                    :class="{ hide: !chromePickers[2].visible }" class='chrome_picker'> </chrome-picker>
                </div>

                <div class='general-styles-options'>
                  <label> Text Color</label>
                  <div class='color-wrapper'>
                    <div class="color-palettes" :style="{ backgroundColor: general.colors.text_color }"
                      @click='showChromePicker(3)'>
                    </div>
                    <ui-textbox :value="(general.colors.text_color)"
                      @input="updateGeneralSetting($event, 'generalTextColor')">
                    </ui-textbox>
                  </div>
                  <chrome-picker :value="(general.colors.text_color)"
                    @input="updateGeneralSetting($event, 'generalTextColor')"
                    :class="{ hide: !chromePickers[3].visible }" class='chrome_picker'> </chrome-picker>
                </div>

                <div class='general-styles-options'>
                  <label> Warning Color </label>
                  <div class='color-wrapper'>
                    <div class="color-palettes" :style="{ backgroundColor: general.colors.warning_color }"
                      @click='showChromePicker(4)'>
                    </div>
                    <ui-textbox :value="(general.colors.warning_color)"
                      @input="updateGeneralSetting($event, 'generalWarningColor')">
                    </ui-textbox>
                  </div>
                  <chrome-picker :value="(general.colors.warning_color)"
                    @input="updateGeneralSetting($event, 'generalWarningColor')"
                    :class="{ hide: !chromePickers[4].visible }" class='chrome_picker'>
                  </chrome-picker>
                </div>
              </div>

              <div class='family-title'> Global Fonts</div>
              <div class="general-font-family">
                <label for="input-font">Inherit from Website (Direct Embed Only)</label><br>
                <ui-switch :value="general.inherit_toggle" @change="updateGeneralSetting($event, 'inheritFont')"
                  class="input-font">
                </ui-switch>
              </div>
              <div class='general-font-family' v-if="!this.theme.general.inherit_toggle">
                <label for="input-font">Primary Font Family</label><br>
                <ui-select :options="fontFamily" :value="(general.font.family)" :hasSearch=true
                  @input="updateGeneralSetting($event, 'generalFamily')" class="input-font">
                </ui-select>
              </div>

              <div class='style-wrapper'>
                <h3 class='general-form-title'> Form </h3>
                <div class="all-style-options-wrapper form-settings">
                  <div class='all-style-options'>
                    <div class='general-form'>
                      <label for="input-font">Static Height</label><br>
                      <ui-switch :value="!general.dynamic_height"
                        @input="updateGeneralSetting($event, 'generalDynamicHeight')" class="input-font">
                      </ui-switch>
                    </div>
                  </div>
                  <div class='all-style-options'>
                    <div class='general-form'>
                      <label for="input-font">Fade-In Effect</label><br>
                      <ui-switch :value="general.dynamic_fadein"
                        @input="updateGeneralSetting($event, 'generalDynamicFadein')" class="input-font">
                      </ui-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- LEFT SIDE END -->

            <!-- RIGHT SIDE START -->
            <div class='right-side'>
              <div class='preview'>Preview</div>
              <div class='right-side-bg'
                :style="'font-family:' + general.font.family + ';background-color:' + uiElements.background.color">

                <div class='form-demo' :style="formDemoStyle" :class='{ formShadow: uiElements.background.formShadow }'>
                  <div class='question' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">Question
                  </div>
                  <div class='question_des' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_description.font.color,
                    fontSize: typography.question_description.font.size + 'px',
                    fontFamily: typography.question_description.font.family,
                    fontWeight: typography.question_description.font.weight,
                    lineHeight: typography.question_description.font.line_height + 'px',
                    textAlign: typography.question_description.text_align
                  }"> This is the question description.
                  </div>
                  <input type="text" class='activeone' value='question answer' :style="{
                    textAlign: typography.input_box.text_align
                  }">
                  <div class='required' :style="{ color: general.colors.warning_color }"> This field is required </div>
                  <div class='question2' :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">
                    Which Platform(s) will the app will be build on ?<span
                      v-if='uiElements.question.asterisk_for_required'> *</span>
                  </div>
                  <ul class="radio-list">
                    <li v-for="(choice, cindex) in choices" :key="cindex">
                      <input name='question1' type="radio">
                      <label :for="'choice' + cindex" class="checkbox-label">{{ choice }}</label>
                    </li>
                  </ul>

                  <div class='gdpr'>
                    <div :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }">
                      GDPR Marketing Permissions
                    </div>
                    <div class='grdp-details' :style="{
                      color: typography.question_description.font.color,
                      fontSize: typography.question_description.font.size + 'px',
                      fontFamily: typography.question_description.font.family,
                      fontWeight: typography.question_description.font.weight,
                      lineHeight: typography.question_description.font.line_height + 'px',
                      textAlign: typography.question_description.text_align
                    }"> We will use the information you provide
                      on this form to be in touch with you and to provide updates and marketing. Please let us know all
                      the ways you like to hear from us.
                    </div>
                    <div class="gdpr-terms">
                      <ui-checkbox-group vertical :options="['Email', 'Direct Post', 'Customized online advertising']"
                        v-model="gdpr"></ui-checkbox-group>
                    </div>
                    <p :style='{ color: theme.general.colors.text_color }'><small>We use <strong>Leadgen</strong> as our
                        marketing automation platform. By submitting this form, you acknowledge that the information you
                        provide will be transferred to <strong>Leadgen</strong>.</small></p>
                  </div>

                  <div class='both-buttons'>
                    <div class="back-button-wrapper">
                      <div :style="'width:' + uiElements.step_navigation.back_button.width + '%;'"
                        v-if="!uiElements.step_navigation.back_button.hide">
                        <ui-button buttonType='button' :style="backButtonStyle"
                          :class='{ shadow: uiElements.step_navigation.back_button.shadow }'>
                          <i class="material-icons">{{ uiElements.step_navigation.back_button.icon }}</i>
                          {{ uiElements.step_navigation.back_button.text }}
                        </ui-button>
                      </div>
                    </div>

                    <div class="continue-button-wrapper" :class='{
                      btnFull: uiElements.step_navigation.back_button.hide,
                      btnHalf: !uiElements.step_navigation.back_button.hide
                    }'>
                      <div :style="'width:' + uiElements.step_navigation.next_button.width + '%'">
                        <ui-button buttonType='button' :style="continueButtonStyle"
                          :class='{ shadow: uiElements.step_navigation.next_button.shadow }'>
                          {{ uiElements.step_navigation.next_button.text }}
                          <i class="material-icons">{{ uiElements.step_navigation.next_button.icon }}</i>
                        </ui-button>
                      </div>
                    </div>

                  </div>

                </div>

              </div>
            </div>
            <!-- RIGHT SIDE END -->
          </div>
        </ui-tab>

        <!-- Typography -->
        <ui-tab title="Typography" id='t'>
          <div class='theme-setting-both-sides'>
            <div class='left-side'>
              <h3>Typography</h3><br>
              <ui-tabs type="text" style="width:90%" background-color="white" text-color-active="black"
                text-color="black" indicatorColor="primary">
                <ui-tab title="Question title">
                  <div class='style-wrapper'>

                    <div class='all-style-options-wrapper'>
                      <div class='all-style-options'>
                        <label class='headings'> TypeFace</label>
                        <ui-select :options="fontFamily" :hasSearch=true
                          :value="(typography.question_title.font.family)"
                          @input="updateTypographySetting($event, 'titleFamily')" class="input-all input-font-family">
                        </ui-select>
                      </div>

                      <div class='all-style-options'>
                        <label class='headings'> Font Size</label>
                        <ui-select :options="fontSize" :value="(typography.question_title.font.size)"
                          @input="updateTypographySetting($event, 'titleFontSize')" class="input-all">
                        </ui-select>
                      </div>

                      <div class='all-style-options'>
                        <label class='headings'> Weight</label>
                        <ui-select :options="fontWeight" :value="(typography.question_title.font.weight)"
                          @input="updateTypographySetting($event, 'titleFontWeight')" class="input-all">
                        </ui-select>
                      </div>
                    </div>

                    <div class='all-style-options-wrapper'>

                      <div class='all-style-options'>
                        <label class='headings'> Line Height</label>
                        <ui-select :options="lineHeight" :value="(typography.question_title.font.line_height)"
                          @input="updateTypographySetting($event, 'titleLineHeight')" class="input-all"></ui-select>
                      </div>

                      <div class='all-style-options'>

                        <label class='headings'> Color</label>
                        <div class='color-wrapper'>
                          <div class="color-palettes" :style="{ backgroundColor: typography.question_title.font.color }"
                            @click="showChromePicker(5)">
                          </div>
                          <ui-textbox :value="(typography.question_title.font.color)"
                            @input="updateTypographySetting($event, 'titleColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(typography.question_title.font.color)"
                          @input="updateTypographySetting($event, 'titleColor')"
                          :class="{ hide: !chromePickers[5].visible }" class='chrome_picker'>
                        </chrome-picker>
                      </div>
                      <div class='all-style-options'>
                        <label class='headings'>Text Alignment</label> <br />
                        <ui-select :options="buttonAlignment" :value="(typography.question_title.text_align)"
                          @input="updateTypographySetting($event, 'titleAlignment')" class="input-all">
                        </ui-select>
                      </div>
                    </div>
                  </div>
                </ui-tab>

                <ui-tab title="Question description">
                  <div class='style-wrapper'>
                    <div class='all-style-options-wrapper'>
                      <div class='all-style-options'>
                        <label class='headings'> TypeFace</label>
                        <ui-select :options="fontFamily" :hasSearch=true
                          :value="(typography.question_description.font.family)"
                          @input="updateTypographySetting($event, 'descriptionFamily')"
                          class="input-all input-font-family">
                        </ui-select>
                      </div>

                      <div class='all-style-options'>
                        <label class='headings'> Font Size</label>
                        <ui-select :options="fontSize" :value="(typography.question_description.font.size)"
                          @input="updateTypographySetting($event, 'descriptionFontSize')" class="input-all">
                        </ui-select>
                      </div>

                      <div class='all-style-options'>
                        <label class='headings'> Weight</label>
                        <ui-select :options="fontWeight" :value="(typography.question_description.font.weight)"
                          @input="updateTypographySetting($event, 'descriptionWeight')" class="input-all">
                        </ui-select>
                      </div>
                    </div>

                    <div class='all-style-options-wrapper'>
                      <div class='all-style-options'>
                        <label class='headings'> Line Height</label>
                        <ui-select :options="lineHeight" :value="(typography.question_description.font.line_height)"
                          @input="updateTypographySetting($event, 'descriptionLineHeight')"
                          class="input-all"></ui-select>
                      </div>

                      <div class='all-style-options'>
                        <label class='headings'> Color</label>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: typography.question_description.font.color }"
                            @click="showChromePicker(6)">
                          </div>
                          <ui-textbox :value="(typography.question_description.font.color)"
                            @input="updateTypographySetting($event, 'descriptionColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(typography.question_description.font.color)"
                          @input="updateTypographySetting($event, 'descriptionColor')"
                          :class="{ hide: !chromePickers[6].visible }" class='chrome_picker'>
                        </chrome-picker>
                      </div>
                      <div class='all-style-options'>
                        <label class='headings'>Text Alignment</label> <br />
                        <ui-select :options="buttonAlignment" :value="(typography.question_description.text_align)"
                          @input="updateTypographySetting($event, 'descripitionAlignment')" class="input-all">
                        </ui-select>
                      </div>
                    </div>
                  </div>
                </ui-tab>

                <ui-tab title="Input box">
                  <div class='style-wrapper'>

                    <div class='all-style-options-wrapper'>
                      <div class='all-style-options'>
                        <label class='headings'> Family Style</label>
                        <ui-select :options="fontFamily" :hasSearch=true :value="(typography.input_box.font.family)"
                          @input="updateTypographySetting($event, 'inputBoxFamily')"
                          class="input-all input-font-family">
                        </ui-select>
                      </div>

                      <div class='all-style-options '>
                        <label class='headings'> Background Color</label>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: typography.input_box.font.background_color }"
                            @click="showChromePicker(7)">
                          </div>
                          <ui-textbox :value="(typography.input_box.font.background_color)"
                            @input="updateTypographySetting($event, 'inputBoxBackground')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(typography.input_box.font.background_color)"
                          @input="updateTypographySetting($event, 'inputBoxBackground')"
                          :class="{ hide: !chromePickers[7].visible }" class='chrome_picker'
                          id="chrome_picker_text_bg_color">
                        </chrome-picker>
                      </div>

                      <div class='all-style-options '>
                        <label class='headings'> Text Color</label>
                        <div class='color-wrapper'>
                          <div class="color-palettes" :style="{ backgroundColor: typography.input_box.font.color }"
                            @click="showChromePicker(8)">
                          </div>
                          <ui-textbox :value="(typography.input_box.font.color)"
                            @input="updateTypographySetting($event, 'inputBoxColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(typography.input_box.font.color)"
                          @input="updateTypographySetting($event, 'inputBoxColor')"
                          :class="{ hide: !chromePickers[8].visible }" class='chrome_picker'
                          id="chrome_picker_text_bg_color">
                        </chrome-picker>
                      </div>

                    </div>

                    <div class='all-style-options-wrapper'>
                      <div class='all-style-options'>
                        <label class='headings'> Font Size</label>
                        <ui-select :options="fontSize" :value="(typography.input_box.font.font_size)"
                          @input="updateTypographySetting($event, 'inputBoxFont')" class="input-all input-font-family">
                        </ui-select>
                      </div>
                      <div class='all-style-options shadow-radius'>
                        <label class='headings'>Height </label>
                        <ui-textbox :value="(typography.input_box.font.height)"
                          @input="updateTypographySetting($event, 'inputBoxHeight')" type="number" :min="15" :max="48">
                        </ui-textbox>
                      </div>

                      <div class='all-style-options shadow-radius'>
                        <label class='headings'>Spacing </label>
                        <ui-textbox :value="(typography.input_box.font.spacing)"
                          @input="updateTypographySetting($event, 'inputBoxSpacing')" type="number" :min="10" :max="48">
                        </ui-textbox>
                      </div>
                    </div>
                    <div class='all-style-options-wrapper'>
                      <div class="all-style-options">
                        <label class='headings'>Text Alignment</label> <br />
                        <ui-select :options="buttonAlignment" :value="(typography.input_box.text_align)"
                          @input="updateTypographySetting($event, 'inputAlignment')" class="input-all">
                        </ui-select>
                      </div>
                      <div class='all-style-options'>
                        <label class='headings'> Placeholder Text Color</label>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: typography.input_box.placeholder.color }"
                            @click="showChromePicker(71)">
                          </div>
                          <ui-textbox :value="(typography.input_box.placeholder.color)"
                            @input="updateTypographySetting($event, 'inputBoxPlaceholderColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(typography.input_box.placeholder.color)"
                          @input="updateTypographySetting($event, 'inputBoxPlaceholderColor')"
                          :class="{ hide: !chromePickers[71].visible }" class='chrome_picker'
                          id="chrome_picker_text_bg_color">
                        </chrome-picker>
                      </div>
                    </div>
                  </div>
                </ui-tab>

                <ui-tab title="Text Element">
                  <div class='style-wrapper'>

                    <div class='all-style-options-wrapper'>
                      <div class='all-style-options'>
                        <label class='headings'> Family Style</label>
                        <ui-select :options="fontFamily" :hasSearch=true :value="(typography.text_box.family)"
                          @input="updateTypographySetting($event, 'textBoxFamily')" class="input-all input-font-family">
                        </ui-select>
                      </div>
                      <div class='all-style-options '>
                        <label class='headings'> Color</label>
                        <div class='color-wrapper'>
                          <div class="color-palettes" :style="{ backgroundColor: typography.text_box.color }"
                            @click="showChromePicker(9)">
                          </div>
                          <ui-textbox :value="(typography.text_box.color)"
                            @input="updateTypographySetting($event, 'textBoxColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(typography.text_box.color)"
                          @input="updateTypographySetting($event, 'textBoxColor')"
                          :class="{ hide: !chromePickers[9].visible }" class='chrome_picker'
                          id="chrome_picker_text_bg_color">
                        </chrome-picker>
                      </div>
                    </div>

                  </div>
                </ui-tab>

              </ui-tabs>
            </div>

            <div class='right-side'>
              <div class='preview'>Preview</div>
              <div class='right-side-bg'
                :style="'font-family:' + general.font.family + ';background-color:' + uiElements.background.color">
                <div class='form-demo' :style="formDemoStyle" :class='{ formShadow: uiElements.background.formShadow }'>
                  <div :style="{
                    color: typography.text_box.color,
                    fontFamily: typography.text_box.family,
                    paddingBottom: '20px',
                    fontSize: '30px'
                  }">
                    Form Heading Text
                  </div>
                  <div class='question' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">Question
                  </div>
                  <div class='question_des' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_description.font.color,
                    fontSize: typography.question_description.font.size + 'px',
                    fontFamily: typography.question_description.font.family,
                    fontWeight: typography.question_description.font.weight,
                    lineHeight: typography.question_description.font.line_height + 'px',
                    textAlign: typography.question_description.text_align
                  }"> This is the question description.
                  </div>
                  <input type="text" class='activeone' value='question answer' :style="{
                    textAlign: typography.input_box.text_align
                  }">
                  <div class='required' :style="{ color: general.colors.warning_color }"> This field is required </div>
                  <div class='question2' :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">
                    Which Platform(s) will the app will be build on ?<span
                      v-if='uiElements.question.asterisk_for_required'> *</span>
                  </div>
                  <ul class="radio-list">
                    <li v-for="(choice, cindex) in choices" :key="cindex">
                      <input name='question1' type="radio">
                      <label :for="'choice' + cindex" class="checkbox-label">{{ choice }}</label>
                    </li>
                  </ul>

                  <div class='gdpr'>
                    <div :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }"> GDPR Marketing Permissions</div>
                    <div class='grdp-details' :style="{
                      color: typography.question_description.font.color,
                      fontSize: typography.question_description.font.size + 'px',
                      fontFamily: typography.question_description.font.family,
                      fontWeight: typography.question_description.font.weight,
                      lineHeight: typography.question_description.font.line_height + 'px',
                      textAlign: typography.question_description.text_align
                    }"> We will use the information you provide
                      on this form to be in touch with you and to provide updates and marketing. Please let us know all
                      the ways you like to hear from us.
                    </div>
                    <div class="gdpr-terms">
                      <ui-checkbox-group vertical :options="['Email', 'Direct Post', 'Customized online advertising']"
                        v-model="gdpr"></ui-checkbox-group>
                    </div>
                    <p :style='{ color: theme.general.colors.text_color }'><small>We use <strong>Leadgen</strong> as our
                        marketing automation platform. By submitting this form, you acknowledge that the information you
                        provide will be transferred to <strong>Leadgen</strong>.</small></p>

                  </div>
                  <div class='both-buttons'>
                    <div class="back-button-wrapper">
                      <div :style="'width:' + uiElements.step_navigation.back_button.width + '%;'"
                        v-if="!uiElements.step_navigation.back_button.hide">
                        <ui-button buttonType='button' :style="backButtonStyle"
                          :class='{ shadow: uiElements.step_navigation.back_button.shadow }'>
                          <i class="material-icons">{{ uiElements.step_navigation.back_button.icon }}</i>
                          {{ uiElements.step_navigation.back_button.text }}
                        </ui-button>
                      </div>
                    </div>

                    <div class="continue-button-wrapper" :class='{
                      btnFull: uiElements.step_navigation.back_button.hide,
                      btnHalf: !uiElements.step_navigation.back_button.hide
                    }'>
                      <div :style="'width:' + uiElements.step_navigation.next_button.width + '%'">
                        <ui-button buttonType='button' :style="continueButtonStyle"
                          :class='{ shadow: uiElements.step_navigation.next_button.shadow }'>
                          {{ uiElements.step_navigation.next_button.text }}
                          <i class="material-icons">{{ uiElements.step_navigation.next_button.icon }}</i>
                        </ui-button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </ui-tab>

        <!-- UI Elements -->
        <ui-tab title="UI Elements" @select="buttonPreview(0)">
          <div class='theme-setting-both-sides'>
            <div class='left-side '>
              <h3>UI Elements</h3><br>
              <ui-tabs type="text" style="width:90%" background-color="white" text-color-active="black"
                text-color="black" indicatorColor="primary">
                <ui-tab title="Form" @select="buttonPreview(0)">
                  <div class='style-wrapper'>
                    <!-- Background colors        -->
                    <div class='general-style-wrapper'>
                      <div class='general-styles-options background-style-wrapper'>
                        <div class='sub-title'> Canvas Background Color</div>
                        <div class='color-wrapper'>
                          <div class="color-palettes" :style="{ backgroundColor: uiElements.background.color }"
                            @click='showChromePicker(10)'>
                          </div>
                          <ui-textbox :value="(uiElements.background.color)"
                            @input="updateUiElementsSetting($event, 'uiBackground')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.background.color)"
                          @input="updateUiElementsSetting($event, 'uiBackground')"
                          :class="{ hide: !chromePickers[10].visible }" class='chrome_picker'> </chrome-picker>
                      </div>

                      <div class='general-styles-options background-style-wrapper'>
                        <div class='sub-title'> Form Background Color</div>
                        <div class='color-wrapper'>
                          <div class="color-palettes" :style="{ backgroundColor: uiElements.background.formColor }"
                            @click='showChromePicker(11)'>
                          </div>
                          <ui-textbox :value="(uiElements.background.formColor)"
                            @input="updateUiElementsSetting($event, 'uiBackgroundForm')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.background.formColor)"
                          @input="updateUiElementsSetting($event, 'uiBackgroundForm')"
                          :class="{ hide: !chromePickers[11].visible }" class='chrome_picker'> </chrome-picker>
                      </div>
                    </div>

                    <!-- form style options -->
                    <div class='all-style-options-wrapper' style="margin-top:30px">

                      <div class='all-style-options'>
                        <label class='headings'> Border Style </label>
                        <ui-select :options="borderStyle" :value="(uiElements.background.form_border_style)"
                          @input="updateUiElementsSetting($event, 'formBorderStyle')"
                          class="input-all input-font-family">
                        </ui-select>
                      </div>

                      <div class='all-style-options'>
                        <label class='headings'> Border Width </label>
                        <ui-select :options="borderWidth" :value="(uiElements.background.form_border_width)"
                          @input="updateUiElementsSetting($event, 'formBorderWidth')" class="input-all">
                        </ui-select>
                      </div>

                      <div class='all-style-options '>
                        <label class='headings'> Border Color</label>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: uiElements.background.form_border_color }"
                            @click="showChromePicker(12)">
                          </div>
                          <ui-textbox :value="(uiElements.background.form_border_color)"
                            @input="updateUiElementsSetting($event, 'formBorderColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.background.form_border_color)"
                          @input="updateUiElementsSetting($event, 'formBorderColor')"
                          :class="{ hide: !chromePickers[12].visible }" class='chrome_picker'
                          id="chrome_picker_border_color">
                        </chrome-picker>
                      </div>
                    </div>

                    <!-- Hide show form shadow / Progress bar -->
                    <div class='general-style-wrapper'>
                      <div class='style-wrapper '>
                        <div class='sub-title'>Border Radius </div>
                        <ui-textbox :value="(uiElements.background.form_border_radius)"
                          @input="updateUiElementsSetting($event, 'formBorderRadius')" type="number" :min="0"
                          :max="100">
                        </ui-textbox>
                      </div>
                      <div class='style-wrapper'>
                        <div class='sub-title'>Form Shadow </div>
                        <ui-switch :value="(uiElements.background.formShadow)"
                          @change="updateUiElementsSetting($event, 'formShadow')">
                        </ui-switch>
                      </div>

                      <div class='style-wrapper'>
                        <div class='sub-title'>Form Progress Bar </div>
                        <ui-switch :value="(uiElements.step_progress.showProgress)"
                          @change="updateUiElementsSetting($event, 'showProgress')">
                        </ui-switch>
                      </div>
                      <div class='style-wrapper' v-if="uiElements.step_progress.showProgress">
                        <div class='sub-title'>Progress Bar Animation </div>
                        <ui-switch :value="(uiElements.step_progress.showAnimation)"
                          @change="updateUiElementsSetting($event, 'showAnimation')">
                        </ui-switch>
                      </div>
                    </div>

                    <!-- Progress bar colors -->
                    <div class='general-style-wrapper' v-if="uiElements.step_progress.showProgress">
                      <div class='general-styles-options progress-style-wrapper'>
                        <div class='sub-title'> Remaining Progress Bar </div>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: uiElements.step_progress.config.stroke_color }"
                            @click='showChromePicker(13)'>
                          </div>
                          <ui-textbox :value="(uiElements.step_progress.config.stroke_color)"
                            @input="updateUiElementsSetting($event, 'uiStrokeColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.step_progress.config.stroke_color)"
                          @input="updateUiElementsSetting($event, 'uiStrokeColor')"
                          :class="{ hide: !chromePickers[13].visible }" class='chrome_picker'> </chrome-picker>
                      </div>

                      <div class='general-styles-options progress-style-wrapper'>
                        <div class='sub-title'> Lapsed Progress Bar </div>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: uiElements.step_progress.config.fill_color }"
                            @click='showChromePicker(14)'>
                          </div>
                          <ui-textbox :value="(uiElements.step_progress.config.fill_color)"
                            @input="updateUiElementsSetting($event, 'uiFillColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.step_progress.config.fill_color)"
                          @input="updateUiElementsSetting($event, 'uiFillColor')"
                          :class="{ hide: !chromePickers[14].visible }" class='chrome_picker'> </chrome-picker>
                      </div>
                      <div class="general-styles-options progress-style-wrapper">
                        <div class="sub-title">Progress Bar Position</div>
                        <ui-select :options="progressbarPosition" :value="(uiElements.step_progress.progressPosition)"
                          @input="updateUiElementsSetting($event, 'progressPosition')" class="input-all">
                        </ui-select>
                      </div>
                    </div>

                    <!-- Form internal Padding  -->
                    <h5> Form Internal Spacing</h5>
                    <div class='general-style-wrapper'>

                      <div class='style-wrapper '>
                        <div class='sub-title'>Top </div>
                        <ui-textbox :value="(uiElements.background.form_padding_top)"
                          @input="updateUiElementsSetting($event, 'formPaddingTop')" type="number" :min="0" :max="100">
                        </ui-textbox>
                      </div>

                      <div class='style-wrapper '>
                        <div class='sub-title'>Right </div>
                        <ui-textbox :value="(uiElements.background.form_padding_right)"
                          @input="updateUiElementsSetting($event, 'formPaddingRight')" type="number" :min="0"
                          :max="100">
                        </ui-textbox>
                      </div>

                      <div class='style-wrapper '>
                        <div class='sub-title'>Bottom </div>
                        <ui-textbox :value="(uiElements.background.form_padding_bottom)"
                          @input="updateUiElementsSetting($event, 'formPaddingBottom')" type="number" :min="0"
                          :max="100">
                        </ui-textbox>
                      </div>

                      <div class='style-wrapper '>
                        <div class='sub-title'>Left </div>
                        <ui-textbox :value="(uiElements.background.form_padding_left)"
                          @input="updateUiElementsSetting($event, 'formPaddingLeft')" type="number" :min="0" :max="100">
                        </ui-textbox>
                      </div>

                    </div>

                  </div>
                </ui-tab>

                <ui-tab title="questions" @select="buttonPreview(0)">
                  <div class='style-wrapper'>
                    <div class='sub-title'>Required questions are indicated with an asterisk (*) </div>
                    <ui-switch :value="(uiElements.question.asterisk_for_required)"
                      @change="updateUiElementsSetting($event, 'uiQuesAsterisk')">
                    </ui-switch>
                  </div>

                  <div class='style-wrapper' style="padding-top:30px">
                    <div class='sub-title'>Hide Question Labels </div>
                    <ui-switch :value="(uiElements.question.hide_question_labels)"
                      @change="updateUiElementsSetting($event, 'uiQuesLabel')">
                    </ui-switch>
                  </div>
                </ui-tab>

                <ui-tab title="Select / Radio" @select="buttonPreview(0)">
                  <div class='style-wrapper'>
                    <!-- Default apperance -->
                    <div class='all-style-options-wrapper'>
                      <div class='all-style-options'>
                        <label class='headings'> Border Style</label>
                        <ui-select :options="borderStyle"
                          :value="(uiElements.radio_checkbox.default_style.border.style)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxDefaultBorderStyle')"
                          class="input-all input-font-family">
                        </ui-select>
                      </div>
                      <div class='all-style-options'>
                        <label class='headings'>Font Size</label>
                        <ui-textbox :value="(uiElements.radio_checkbox.font.size)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxDefaultFontSize')" type="number" :min="5"
                          :max="200">
                        </ui-textbox>
                      </div>
                      <div class='all-style-options'>
                        <label class='headings'> Border Stroke</label>
                        <ui-select :options="borderWidth"
                          :value="(uiElements.radio_checkbox.default_style.border.width)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxDefaultBorderWidth')" class="input-all">
                        </ui-select>
                      </div>

                      <div class='all-style-options '>
                        <label class='headings'> Border Color</label>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: uiElements.radio_checkbox.default_style.border.color }"
                            @click="showChromePicker(15)">
                          </div>
                          <ui-textbox :value="(uiElements.radio_checkbox.default_style.border.color)"
                            @input="updateUiElementsSetting($event, 'uiCheckboxDefaultBorderColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.radio_checkbox.default_style.border.color)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxDefaultBorderColor')"
                          :class="{ hide: !chromePickers[15].visible }" class='chrome_picker'
                          id="chrome_picker_border_color_default">
                        </chrome-picker>
                      </div>
                    </div>

                    <div class='all-style-options-wrapper select_wrapper' style="">

                      <div class='all-style-options buttons-color '>
                        <label class='headings'>Text Color </label>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: uiElements.radio_checkbox.default_style.color }"
                            @click="showChromePicker(16)">
                          </div>
                          <ui-textbox :value="(uiElements.radio_checkbox.default_style.color)"
                            @input="updateUiElementsSetting($event, 'uiCheckboxDefaultTextColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.radio_checkbox.default_style.color)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxDefaultTextColor')"
                          :class="{ hide: !chromePickers[16].visible }" class='chrome_picker'
                          id="chrome_picker_default_colors">
                        </chrome-picker>
                      </div>

                      <div class='all-style-options buttons-color '>
                        <label class='headings'> Background Color </label>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: uiElements.radio_checkbox.default_style.backgroundcolor }"
                            @click="showChromePicker(17)">
                          </div>
                          <ui-textbox :value="(uiElements.radio_checkbox.default_style.backgroundcolor)"
                            @input="updateUiElementsSetting($event, 'uiCheckboxDefaultBackgroundColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.radio_checkbox.default_style.backgroundcolor)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxDefaultBackgroundColor')"
                          :class="{ hide: !chromePickers[17].visible }" class='chrome_picker'
                          id="chrome_picker_default_colors">
                        </chrome-picker>
                      </div>

                      <div class='all-style-options buttons-color '>
                        <label class='headings'>Dropdown Color </label>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: uiElements.choice.dropdown_skin.default_style.color }"
                            @click="showChromePicker(65)">
                          </div>
                          <ui-textbox :value="(uiElements.choice.dropdown_skin.default_style.color)"
                            @input="updateUiElementsSetting($event, 'uiCheckboxDefaultDropdownColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.choice.dropdown_skin.default_style.color)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxDefaultDropdownColor')"
                          :class="{ hide: !chromePickers[65].visible }" class='chrome_picker'
                          id="chrome_picker_default_colors">
                        </chrome-picker>
                      </div>
                    </div>

                    <!-- Hover apperance -->
                    <div>
                      <h5> Hover Appearance</h5>
                    </div>
                    <div class='all-style-options-wrapper'>
                      <div class='all-style-options'>
                        <label class='headings'> Border Style</label>
                        <ui-select :options="borderStyle" :value="(uiElements.radio_checkbox.hover_style.border.style)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxHoverBorderStyle')"
                          class="input-all input-font-family">
                        </ui-select>
                      </div>

                      <div class='all-style-options'>
                        <label class='headings'> Border Stroke</label>
                        <ui-select :options="borderWidth" :value="(uiElements.radio_checkbox.hover_style.border.width)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxHoverBorderWidth')" class="input-all">
                        </ui-select>
                      </div>

                      <div class='all-style-options '>
                        <label class='headings'> Border Color</label>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: uiElements.radio_checkbox.hover_style.border.color }"
                            @click="showChromePicker(18)">
                          </div>
                          <ui-textbox :value="(uiElements.radio_checkbox.hover_style.border.color)"
                            @input="updateUiElementsSetting($event, 'uiCheckboxHoverBorderColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.radio_checkbox.hover_style.border.color)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxHoverBorderColor')"
                          :class="{ hide: !chromePickers[18].visible }" class='chrome_picker'
                          id="chrome_picker_border_color_hover">
                        </chrome-picker>
                      </div>
                    </div>

                    <div class='all-style-options-wrapper select_wrapper' style="">

                      <div class='all-style-options buttons-color '>
                        <label class='headings'>Text Color </label>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: uiElements.radio_checkbox.hover_style.color }"
                            @click="showChromePicker(19)">
                          </div>
                          <ui-textbox :value="(uiElements.radio_checkbox.hover_style.color)"
                            @input="updateUiElementsSetting($event, 'uiCheckboxHoverTextColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.radio_checkbox.hover_style.color)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxHoverTextColor')"
                          :class="{ hide: !chromePickers[19].visible }" class='chrome_picker'
                          id="chrome_picker_hovers_colors">
                        </chrome-picker>
                      </div>

                      <div class='all-style-options buttons-color '>
                        <label class='headings'> BackgroundColor </label>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: uiElements.radio_checkbox.hover_color }"
                            @click="showChromePicker(20)">
                          </div>
                          <ui-textbox :value="(uiElements.radio_checkbox.hover_color)"
                            @input="updateUiElementsSetting($event, 'uiCheckboxHoverBackgroundColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.radio_checkbox.hover_color)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxHoverBackgroundColor')"
                          :class="{ hide: !chromePickers[20].visible }" class='chrome_picker'
                          id="chrome_picker_hovers_colors">
                        </chrome-picker>
                      </div>
                    </div>

                    <!-- Active apperance -->
                    <div>
                      <h5> Active Appearance</h5>
                    </div>

                    <div class='all-style-options-wrapper' style="margin-top:20px;">
                      <div class='all-style-options'>
                        <label class='headings'> Border Style</label>
                        <ui-select :options="borderStyle" :value="(uiElements.radio_checkbox.active_style.border.style)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxActiveBorderStyle')"
                          class="input-all input-font-family">
                        </ui-select>
                      </div>

                      <div class='all-style-options'>
                        <label class='headings'>Border Stroke</label>
                        <ui-select :options="borderWidth" :value="(uiElements.radio_checkbox.active_style.border.width)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxActiveBorderWidth')" class="input-all">
                        </ui-select>
                      </div>

                      <div class='all-style-options '>
                        <label class='headings'> Border Color</label>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: uiElements.radio_checkbox.active_style.border.color }"
                            @click="showChromePicker(21)">
                          </div>
                          <ui-textbox :value="(uiElements.radio_checkbox.active_style.border.color)"
                            @input="updateUiElementsSetting($event, 'uiCheckboxActiveBorderColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.radio_checkbox.hover_style.border.color)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxActiveBorderColor')"
                          :class="{ hide: !chromePickers[21].visible }" class='chrome_picker'
                          id="chrome_picker_active_border_color">
                        </chrome-picker>
                      </div>
                    </div>

                    <div class='all-style-options-wrapper select_wrapper' style="">
                      <div class='all-style-options buttons-color '>
                        <label class='headings'>Text Color </label>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: uiElements.radio_checkbox.active_style.color }"
                            @click="showChromePicker(22)">
                          </div>
                          <ui-textbox :value="(uiElements.radio_checkbox.active_style.color)"
                            @input="updateUiElementsSetting($event, 'uiCheckboxActiveTextColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.radio_checkbox.active_style.color)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxActiveTextColor')"
                          :class="{ hide: !chromePickers[22].visible }" class='chrome_picker'
                          id="chrome_picker_active_colors">
                        </chrome-picker>
                      </div>

                      <div class='all-style-options buttons-color '>
                        <label class='headings'> BackgroundColor </label>
                        <div class='color-wrapper'>
                          <div class="color-palettes"
                            :style="{ backgroundColor: uiElements.radio_checkbox.checked_color }"
                            @click="showChromePicker(23)">
                          </div>
                          <ui-textbox :value="(uiElements.radio_checkbox.checked_color)"
                            @input="updateUiElementsSetting($event, 'uiCheckboxActiveBackgroundColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(uiElements.radio_checkbox.checked_color)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxActiveBackgroundColor')"
                          :class="{ hide: !chromePickers[23].visible }" class='chrome_picker'
                          id="chrome_picker_active_colors">
                        </chrome-picker>
                      </div>
                    </div>

                    <!-- Other Style Options -->
                    <div>
                      <h5> Other Style Options</h5>
                    </div>
                    <div class='all-style-options-wrapper select_wrapper'>
                      <div class='all-style-options shadow-radius'>
                        <label class='headings'>Border Radius </label>
                        <ui-textbox :value="(uiElements.radio_checkbox.radius)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxRadius')" type="number" :min="5"
                          :max="200">
                        </ui-textbox>
                      </div>
                      <div class='all-style-options shadow-radius'>
                        <label class='headings'>Spaces Between </label>
                        <ui-textbox :value="(uiElements.radio_checkbox.margin)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxMargin')" type="number" :min="5"
                          :max="200">
                        </ui-textbox>
                      </div>
                      <div class="all-style-options">
                        <label class='headings'>Select Button Height </label>
                        <ui-textbox :value="(uiElements.radio_checkbox.font.line_height)"
                          @input="updateUiElementsSetting($event, 'uiCheckboxLineHeight')" type="number" :min="5"
                          :max="200">
                        </ui-textbox>
                      </div>
                    </div>
                  </div>
                </ui-tab>

                <ui-tab title="input box" @select="buttonPreview(0)">
                  <div class='style-wrapper'>

                    <div class='all-style-options-wrapper'>
                      <div class='all-style-options'>
                        <label class='headings'> Border Style</label>
                        <ui-select :options="borderStyle" :value="(typography.input_box.border.style)"
                          @input="updateTypographySetting($event, 'inputBoxBorderStyle')"
                          class="input-all input-font-family">
                        </ui-select>
                      </div>

                      <div class='all-style-options'>
                        <label class='headings'> Border Stroke</label>
                        <ui-select :options="borderWidth" :value="(typography.input_box.border.width)"
                          @input="updateTypographySetting($event, 'inputBoxBorderWidth')" class="input-all">
                        </ui-select>
                      </div>

                      <div class='all-style-options '>
                        <label class='headings'> Border Color</label>
                        <div class='color-wrapper'>
                          <div class="color-palettes" :style="{ backgroundColor: typography.input_box.border.color }"
                            @click="showChromePicker(24)">
                          </div>
                          <ui-textbox :value="(typography.input_box.border.color)"
                            @input="updateTypographySetting($event, 'inputBoxBorderColor')">
                          </ui-textbox>
                        </div>
                        <chrome-picker :value="(typography.input_box.border.color)"
                          @input="updateTypographySetting($event, 'inputBoxBorderColor')"
                          :class="{ hide: !chromePickers[24].visible }" class='chrome_picker'
                          id="chrome_picker_border_color_input">
                        </chrome-picker>
                      </div>
                    </div>

                    <div class='all-style-options-wrapper'>
                      <div class='all-style-options'>
                        <label class='headings'>Border Skin</label>
                        <ui-select :options="['all', 'bottom']" :value="(typography.input_box.border.skin)"
                          @input="updateTypographySetting($event, 'inputBoxBorderSkin')"
                          class="input-all input-font-family">
                        </ui-select>
                      </div>
                      <div class='all-style-options shadow-radius'>
                        <label class='headings'>Corner Radius </label>
                        <ui-textbox :value="(typography.input_box.radius)"
                          @input="updateTypographySetting($event, 'inputBoxRadius')" type="number" :min="0" :max="20">
                        </ui-textbox>
                      </div>
                      <div class='all-style-options shadow-radius'>
                        <label class='headings'>Text Intent </label>
                        <ui-textbox :value="(typography.input_box.font.text_intent)"
                          @input="updateTypographySetting($event, 'inputBoxTextIntent')" type="number" :min="0"
                          :max="200">
                        </ui-textbox>
                      </div>
                    </div>

                  </div>
                </ui-tab>

                <ui-tab title="Choice Image/Icon" @select="buttonPreview(0)">
                  <ui-tabs type="text" background-color="white" text-color-active="black" text-color="black"
                    indicatorColor="primary">
                    <ui-tab title="Default Appearance">

                      <div class='style-wrapper image-icon-style-wraper'>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> Border Style </label>
                            <ui-select :options="borderStyle" :value="(uiElements.choice.image_icon_skin.border.style)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultBorderStyle')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>

                          <div class='all-style-options'>
                            <label class='headings'> Border Stroke </label>
                            <ui-select :options="borderWidth" :value="(uiElements.choice.image_icon_skin.border.width)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultBorderWidth')" class="input-all">
                            </ui-select>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Border Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.border.color }"
                                @click="showChromePicker(25)">
                              </div>
                              <ui-textbox :value="(uiElements.choice.image_icon_skin.border.color)"
                                @input="updateUiElementsSetting($event, 'imageIconDefaultBorderColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.choice.image_icon_skin.border.color)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultBorderColor')"
                              :class="{ hide: !chromePickers[25].visible }" class='chrome_picker'
                              id="chrome_picker_border_color_default">
                            </chrome-picker>
                          </div>
                        </div>

                        <div class='general-style-wrapper'>

                          <div class='style-wrapper '>
                            <div class='sub-title'>Top </div>
                            <ui-textbox :value="(uiElements.choice.image_icon_skin.padding.top)"
                              @input="updateUiElementsSetting($event, 'imageIconPaddingTop')" type="number">
                            </ui-textbox>
                          </div>

                          <div class='style-wrapper '>
                            <div class='sub-title'>Right </div>
                            <ui-textbox :value="(uiElements.choice.image_icon_skin.padding.right)"
                              @input="updateUiElementsSetting($event, 'imageIconPaddingRight')" type="number" :min="0"
                              :max="100">
                            </ui-textbox>
                          </div>

                          <div class='style-wrapper '>
                            <div class='sub-title'>Bottom </div>
                            <ui-textbox :value="(uiElements.choice.image_icon_skin.padding.bottom)"
                              @input="updateUiElementsSetting($event, 'imageIconPaddingBottom')" type="number" :min="0"
                              :max="100">
                            </ui-textbox>
                          </div>

                          <div class='style-wrapper '>
                            <div class='sub-title'>Left </div>
                            <ui-textbox :value="(uiElements.choice.image_icon_skin.padding.left)"
                              @input="updateUiElementsSetting($event, 'imageIconPaddingLeft')" type="number" :min="0"
                              :max="100">
                            </ui-textbox>
                          </div>

                        </div>
                        <div class='image-icon-titles'> Title Style</div>
                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> TypeFace</label>
                            <ui-select :options="fontFamily" :hasSearch=true
                              :value="(uiElements.choice.image_icon_skin.title_font.family)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultTitleFontFamily')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>
                          <div class='all-style-options'>
                            <label class='headings'> Font Size</label> <br />
                            <ui-select :options="fontSize" :value="(uiElements.choice.image_icon_skin.title_font.size)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultTitleFontSize')"
                              class="input-all">
                            </ui-select>
                          </div>
                          <div class='all-style-options'>
                            <label class='headings'> Weight</label> <br />
                            <ui-select :options="fontWeight"
                              :value="(uiElements.choice.image_icon_skin.title_font.weight)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultTitleFontWeight')"
                              class="input-all">
                            </ui-select>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>

                          <div class='all-style-options'>
                            <label class='headings'> Line Height</label>
                            <ui-select :options="lineHeight" :hasSearch=true
                              :value="(uiElements.choice.image_icon_skin.title_font.line_height)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultTitleFontLineHeight')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.title_font.color }"
                                @click="showChromePicker(26)">
                              </div>
                              <ui-textbox :value="(uiElements.choice.image_icon_skin.title_font.color)"
                                @input="updateUiElementsSetting($event, 'imageIconDefaultTitleFontColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.choice.image_icon_skin.title_font.color)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultTitleFontColor')"
                              :class="{ hide: !chromePickers[26].visible }" class='chrome_picker'
                              id="chrome_picker_btn_border_color">
                            </chrome-picker>
                          </div>

                        </div>

                        <div class='image-icon-titles'> Description Style</div>
                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> TypeFace</label>
                            <ui-select :options="fontFamily" :hasSearch=true
                              :value="(uiElements.choice.image_icon_skin.description_font.family)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultDescriptionFontFamily')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>
                          <div class='all-style-options'>
                            <label class='headings'> Font Size</label> <br />
                            <ui-select :options="fontSize"
                              :value="(uiElements.choice.image_icon_skin.description_font.size)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultDescriptionFontSize')"
                              class="input-all">
                            </ui-select>
                          </div>
                          <div class='all-style-options'>
                            <label class='headings'> Weight</label> <br />
                            <ui-select :options="fontWeight"
                              :value="(uiElements.choice.image_icon_skin.description_font.weight)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultDescriptionFontWeight')"
                              class="input-all">
                            </ui-select>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>

                          <div class='all-style-options'>
                            <label class='headings'> Line Height</label>
                            <ui-select :options="lineHeight" :hasSearch=true
                              :value="(uiElements.choice.image_icon_skin.description_font.line_height)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultDescriptionFontLineHeight')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.description_font.color }"
                                @click="showChromePicker(27)">
                              </div>
                              <ui-textbox :value="(uiElements.choice.image_icon_skin.description_font.color)"
                                @input="updateUiElementsSetting($event, 'imageIconDefaultDescriptionFontColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.choice.image_icon_skin.description_font.color)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultDescriptionFontColor')"
                              :class="{ hide: !chromePickers[27].visible }" class='chrome_picker'
                              id="chrome_picker_image_icon_des_color">
                            </chrome-picker>
                          </div>

                        </div>

                        <div class='image-icon-titles'> Other Options</div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options shadow-radius'>
                            <label class='headings'> Box Width </label>
                            <ui-textbox :value="(uiElements.choice.image_icon_skin.width)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultWidth')" type="number">
                            </ui-textbox>
                          </div>

                          <div class='all-style-options shadow-radius'>
                            <label class='headings'>Box Height </label>
                            <ui-textbox :value="(uiElements.choice.image_icon_skin.height)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultHeight')" type="number">
                            </ui-textbox>
                          </div>

                          <div class='all-style-options shadow-radius'>
                            <div class='headings'>Box Shadow </div>
                            <ui-switch :value="(uiElements.choice.image_icon_skin.shadow)"
                              @change="updateUiElementsSetting($event, 'imageIconDefaultShadow')">
                            </ui-switch>
                          </div>

                        </div>

                        <div class='all-style-options-wrapper'>

                          <div class='all-style-options '>
                            <label class='headings'>Icon Size </label>
                            <ui-textbox :value="(uiElements.choice.image_icon_skin.icon_size)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultIconSize')" type="number">
                            </ui-textbox>
                          </div>

                          <div class='all-style-options buttons-color '>
                            <label class='headings'> Icon Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.icon_color }"
                                @click="showChromePicker(28)">
                              </div>
                              <ui-textbox :value="(uiElements.choice.image_icon_skin.icon_color)"
                                @input="updateUiElementsSetting($event, 'imageIconDefaultIconColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.choice.image_icon_skin.icon_color)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultIconColor')"
                              :class="{ hide: !chromePickers[28].visible }" class='chrome_picker'
                              id="chrome_picker_image_icon_color">
                            </chrome-picker>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Image Width </label>
                            <ui-textbox :value="(uiElements.choice.image_icon_skin.image_width)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultImageWidth')" type="number">
                            </ui-textbox>
                          </div>

                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options buttons-color'>
                            <label class='headings'> Background Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.background_color }"
                                @click="showChromePicker(29)">
                              </div>
                              <ui-textbox :value="(uiElements.choice.image_icon_skin.background_color)"
                                @input="updateUiElementsSetting($event, 'imageIconDefaultBackgroundColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.choice.image_icon_skin.background_color)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultBackgroundColor')"
                              :class="{ hide: !chromePickers[29].visible }" class='chrome_picker'
                              id="chrome_picker_image_icon_bg_color">
                            </chrome-picker>
                          </div>
                          <div class='all-style-options buttons-color'>
                            <label class='headings'> Tickbox Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.tickbox.color || general.colors.active_color }"
                                @click="showChromePicker(70)">
                              </div>
                              <ui-textbox
                                :value="(uiElements.choice.image_icon_skin.tickbox.color || general.colors.active_color)"
                                @input="updateUiElementsSetting($event, 'imageIconDefaultTickboxColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker
                              :value="(uiElements.choice.image_icon_skin.tickbox.color || general.colors.active_color)"
                              @input="updateUiElementsSetting($event, 'imageIconDefaultTickboxColor')"
                              :class="{ hide: !chromePickers[70].visible }" class='chrome_picker'
                              id="chrome_picker_image_icon_tickbox_default_color">
                            </chrome-picker>
                          </div>
                          <div class='all-style-options '>
                            <label class='headings'> Border Radius </label>
                            <ui-textbox :value="(uiElements.choice.image_icon_skin.border.radius)"
                              @input="updateUiElementsSetting($event, 'imageIconBorderRadius')" type="number">
                            </ui-textbox>
                          </div>
                        </div>

                      </div>

                    </ui-tab>

                    <ui-tab title="Hover Appearance">
                      <div class='style-wrapper image-icon-hover-style-wraper'>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> Border Style </label>
                            <ui-select :options="borderStyle"
                              :value="(uiElements.choice.image_icon_skin.hover_style.border.style)"
                              @input="updateUiElementsSetting($event, 'imageIconHoverBorderStyle')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>

                          <div class='all-style-options'>
                            <label class='headings'> Border Stroke </label>
                            <ui-select :options="borderWidth"
                              :value="(uiElements.choice.image_icon_skin.hover_style.border.width)"
                              @input="updateUiElementsSetting($event, 'imageIconHoverBorderWidth')" class="input-all">
                            </ui-select>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Border Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.hover_style.border.color }"
                                @click="showChromePicker(30)">
                              </div>
                              <ui-textbox :value="(uiElements.choice.image_icon_skin.hover_style.border.color)"
                                @input="updateUiElementsSetting($event, 'imageIconHoverBorderColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.choice.image_icon_skin.hover_style.border.color)"
                              @input="updateUiElementsSetting($event, 'imageIconHoverBorderColor')"
                              :class="{ hide: !chromePickers[30].visible }" class='chrome_picker'
                              id="chrome_picker_border_color_default">
                            </chrome-picker>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options buttons-color '>
                            <label class='headings'> Box Background Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.hover_style.background_color }"
                                @click="showChromePicker(31)">
                              </div>
                              <ui-textbox :value="(uiElements.choice.image_icon_skin.hover_style.background_color)"
                                @input="updateUiElementsSetting($event, 'imageIconHoverBgColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.choice.image_icon_skin.hover_style.background_color)"
                              @input="updateUiElementsSetting($event, 'imageIconHoverBgColor')"
                              :class="{ hide: !chromePickers[31].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>

                          <div class='all-style-options  buttons-color'>
                            <label class='headings'> Title Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.hover_style.title_font.color }"
                                @click="showChromePicker(32)">
                              </div>
                              <ui-textbox :value="(uiElements.choice.image_icon_skin.hover_style.title_font.color)"
                                @input="updateUiElementsSetting($event, 'imageIconHoverTitleColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.choice.image_icon_skin.hover_style.title_font.color)"
                              @input="updateUiElementsSetting($event, 'imageIconHoverTitleColor')"
                              :class="{ hide: !chromePickers[32].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>

                          </div>

                          <div class='all-style-options  buttons-color'>
                            <label class='headings'> Description Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.hover_style.description_font.color }"
                                @click="showChromePicker(33)">
                              </div>
                              <ui-textbox
                                :value="(uiElements.choice.image_icon_skin.hover_style.description_font.color)"
                                @input="updateUiElementsSetting($event, 'imageIconHoverDesColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker
                              :value="(uiElements.choice.image_icon_skin.hover_style.description_font.color)"
                              @input="updateUiElementsSetting($event, 'imageIconHoverDesColor')"
                              :class="{ hide: !chromePickers[33].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>

                          </div>

                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options buttons-color '>
                            <label class='headings'> Icon Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.hover_style.icon_color }"
                                @click="showChromePicker(34)">
                              </div>
                              <ui-textbox :value="(uiElements.choice.image_icon_skin.hover_style.icon_color)"
                                @input="updateUiElementsSetting($event, 'imageIconHoverIconColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.choice.image_icon_skin.hover_style.icon_color)"
                              @input="updateUiElementsSetting($event, 'imageIconHoverIconColor')"
                              :class="{ hide: !chromePickers[34].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>

                          <div class='all-style-options shadow-radius'>
                            <div class='headings' style="height:45px">Box Shadow </div>
                            <ui-switch :value="(uiElements.choice.image_icon_skin.hover_style.shadow)"
                              @change="updateUiElementsSetting($event, 'imageIconHoverShadow')">
                            </ui-switch>
                          </div>

                        </div>

                      </div>
                    </ui-tab>

                    <ui-tab title="Active Appearance">
                      <div class='style-wrapper image-icon-active-style-wraper'>
                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> Border Style </label>
                            <ui-select :options="borderStyle"
                              :value="(uiElements.choice.image_icon_skin.active_style.border.style)"
                              @input="updateUiElementsSetting($event, 'imageIconActiveBorderStyle')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>

                          <div class='all-style-options'>
                            <label class='headings'> Border Stroke </label>
                            <ui-select :options="borderWidth"
                              :value="(uiElements.choice.image_icon_skin.active_style.border.width)"
                              @input="updateUiElementsSetting($event, 'imageIconActiveBorderWidth')" class="input-all">
                            </ui-select>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Border Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.active_style.border.color }"
                                @click="showChromePicker(35)">
                              </div>
                              <ui-textbox :value="(uiElements.choice.image_icon_skin.active_style.border.color)"
                                @input="updateUiElementsSetting($event, 'imageIconActiveBorderColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.choice.image_icon_skin.active_style.border.color)"
                              @input="updateUiElementsSetting($event, 'imageIconActiveBorderColor')"
                              :class="{ hide: !chromePickers[35].visible }" class='chrome_picker'
                              id="chrome_picker_border_color_default">
                            </chrome-picker>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options buttons-color '>
                            <label class='headings'> Box Background Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.active_style.background_color }"
                                @click="showChromePicker(36)">
                              </div>
                              <ui-textbox :value="(uiElements.choice.image_icon_skin.active_style.background_color)"
                                @input="updateUiElementsSetting($event, 'imageIconActiveBgColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.choice.image_icon_skin.active_style.background_color)"
                              @input="updateUiElementsSetting($event, 'imageIconActiveBgColor')"
                              :class="{ hide: !chromePickers[36].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>

                          <div class='all-style-options  buttons-color'>
                            <label class='headings'> Title Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.active_style.title_font.color }"
                                @click="showChromePicker(37)">
                              </div>
                              <ui-textbox :value="(uiElements.choice.image_icon_skin.active_style.title_font.color)"
                                @input="updateUiElementsSetting($event, 'imageIconActiveTitleColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.choice.image_icon_skin.active_style.title_font.color)"
                              @input="updateUiElementsSetting($event, 'imageIconActiveTitleColor')"
                              :class="{ hide: !chromePickers[37].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>

                          </div>

                          <div class='all-style-options  buttons-color'>
                            <label class='headings'> Description Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.active_style.description_font.color }"
                                @click="showChromePicker(38)">
                              </div>
                              <ui-textbox
                                :value="(uiElements.choice.image_icon_skin.active_style.description_font.color)"
                                @input="updateUiElementsSetting($event, 'imageIconActiveDesColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker
                              :value="(uiElements.choice.image_icon_skin.active_style.description_font.color)"
                              @input="updateUiElementsSetting($event, 'imageIconActiveDesColor')"
                              :class="{ hide: !chromePickers[38].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options buttons-color '>
                            <label class='headings'> Icon Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.choice.image_icon_skin.active_style.icon_color }"
                                @click="showChromePicker(39)">
                              </div>
                              <ui-textbox :value="(uiElements.choice.image_icon_skin.active_style.icon_color)"
                                @input="updateUiElementsSetting($event, 'imageIconActiveIconColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.choice.image_icon_skin.active_style.icon_color)"
                              @input="updateUiElementsSetting($event, 'imageIconActiveIconColor')"
                              :class="{ hide: !chromePickers[39].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>

                          <div class='all-style-options shadow-radius'>
                            <div class='headings'>Box Shadow </div>
                            <ui-switch :value="(uiElements.choice.image_icon_skin.active_style.shadow)"
                              @change="updateUiElementsSetting($event, 'imageIconActiveShadow')">
                            </ui-switch>
                          </div>
                        </div>

                      </div>

                    </ui-tab>

                  </ui-tabs>
                </ui-tab>
                <ui-tab title="Scale" @select="buttonPreview(5)">
                  <ui-tabs type="text" background-color="white" text-color-active="black" text-color="black"
                    indicatorColor="primary">
                    <ui-tab title="Default Apperance">
                      <div class="style-wrapper">
                        <div class="all-style-options-wrapper">
                          <div class="all-style-options">
                            <label class='headings'> Selected Scale</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.scale.config.fill_color }"
                                @click="showChromePicker(72)">
                              </div>
                              <ui-textbox :value="(uiElements.scale.config.fill_color)"
                                @input="updateUiElementsSetting($event, 'scaleUiFillColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.scale.config.fill_color)"
                              @input="updateUiElementsSetting($event, 'scaleUiFillColor')"
                              :class="{ hide: !chromePickers[72].visible }" class='chrome_picker'
                              id="chrome_picker_border_color_input">
                            </chrome-picker>
                          </div>

                          <div class="all-style-options">
                            <label class='headings'> Remaining Scale</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.scale.config.stroke_color }"
                                @click="showChromePicker(73)">
                              </div>
                              <ui-textbox :value="(uiElements.scale.config.stroke_color)"
                                @input="updateUiElementsSetting($event, 'scaleUiStrokeColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.scale.config.stroke_color)"
                              @input="updateUiElementsSetting($event, 'scaleUiStrokeColor')"
                              :class="{ hide: !chromePickers[73].visible }" class='chrome_picker'
                              id="chrome_picker_border_color_input">
                            </chrome-picker>
                          </div>

                          <div class="all-style-options">
                            <label class='headings'> Border Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes" :style="{ backgroundColor: uiElements.scale.border_color }"
                                @click="showChromePicker(74)">
                              </div>
                              <ui-textbox :value="(uiElements.scale.border_color)"
                                @input="updateUiElementsSetting($event, 'scaleBorderColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.scale.border_color)"
                              @input="updateUiElementsSetting($event, 'scaleBorderColor')"
                              :class="{ hide: !chromePickers[74].visible }" class='chrome_picker'
                              id="chrome_picker_border_color_input">
                            </chrome-picker>
                          </div>
                        </div>
                        <div class="all-style-options-wrapper">
                          <div class="all-style-options">
                            <label class='headings'> Selector Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes" :style="{ backgroundColor: uiElements.scale.selector_color }"
                                @click="showChromePicker(75)">
                              </div>
                              <ui-textbox :value="(uiElements.scale.selector_color)"
                                @input="updateUiElementsSetting($event, 'scaleSelectorColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.scale.selector_color)"
                              @input="updateUiElementsSetting($event, 'scaleSelectorColor')"
                              :class="{ hide: !chromePickers[75].visible }" class='chrome_picker'
                              id="chrome_picker_border_color_input">
                            </chrome-picker>
                          </div>
                          <div class="all-style-options">
                            <label class='headings'> Smileys Icon Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes" :style="{ backgroundColor: uiElements.scale.smileys_color }"
                                @click="showChromePicker(76)">
                              </div>
                              <ui-textbox :value="(uiElements.scale.smileys_color)"
                                @input="updateUiElementsSetting($event, 'scaleSmileysColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.scale.smileys_color)"
                              @input="updateUiElementsSetting($event, 'scaleSmileysColor')"
                              :class="{ hide: !chromePickers[76].visible }" class='chrome_picker'
                              id="chrome_picker_border_color_input">
                            </chrome-picker>
                          </div>
                          <div class="all-style-options">
                            <label class='headings'> Smileys Text Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.scale.smileys_text_color }"
                                @click="showChromePicker(77)">
                              </div>
                              <ui-textbox :value="(uiElements.scale.smileys_text_color)"
                                @input="updateUiElementsSetting($event, 'scaleSmileysTextColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.scale.smileys_text_color)"
                              @input="updateUiElementsSetting($event, 'scaleSmileysTextColor')"
                              :class="{ hide: !chromePickers[77].visible }" class='chrome_picker'
                              id="chrome_picker_border_color_input">
                            </chrome-picker>
                          </div>
                        </div>
                      </div>
                    </ui-tab>
                    <ui-tab title="Hover Apperance">
                      <div class="style-wrapper">
                        <div class="all-style-options-wrapper">
                          <div class="all-style-options">
                            <label class='headings'> Smileys Hover Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.scale.hover_style.smileys_hover_color }"
                                @click="showChromePicker(78)">
                              </div>
                              <ui-textbox :value="(uiElements.scale.hover_style.smileys_hover_color)"
                                @input="updateUiElementsSetting($event, 'scaleSmileysHoverColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.scale.hover_style.smileys_hover_color)"
                              @input="updateUiElementsSetting($event, 'scaleSmileysHoverColor')"
                              :class="{ hide: !chromePickers[78].visible }" class='chrome_picker'
                              id="chrome_picker_border_color_input">
                            </chrome-picker>
                          </div>
                        </div>
                      </div>
                    </ui-tab>
                    <ui-tab title="Active Apperance">
                      <div class="style-wrapper">
                        <div class="all-style-options-wrapper">
                          <div class="all-style-options">
                            <label class='headings'> Smileys Active Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.scale.active_style.smileys_active_color }"
                                @click="showChromePicker(79)">
                              </div>
                              <ui-textbox :value="(uiElements.scale.active_style.smileys_active_color)"
                                @input="updateUiElementsSetting($event, 'scaleSmileysActiveColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.scale.active_style.smileys_active_color)"
                              @input="updateUiElementsSetting($event, 'scaleSmileysActiveColor')"
                              :class="{ hide: !chromePickers[79].visible }" class='chrome_picker'
                              id="chrome_picker_border_color_input">
                            </chrome-picker>
                            <label class='headings'> Smileys Text Active Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.scale.active_style.smileys_active_text_color }"
                                @click="showChromePicker(80)">
                              </div>
                              <ui-textbox :value="(uiElements.scale.active_style.smileys_active_text_color)"
                                @input="updateUiElementsSetting($event, 'scaleSmileysActiveTextColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.scale.active_style.smileys_active_text_color)"
                              @input="updateUiElementsSetting($event, 'scaleSmileysActiveTextColor')"
                              :class="{ hide: !chromePickers[80].visible }" class='chrome_picker'
                              id="chrome_picker_border_color_input">
                            </chrome-picker>
                          </div>
                        </div>
                      </div>
                    </ui-tab>
                  </ui-tabs>
                </ui-tab>
              </ui-tabs>
            </div>

            <div class='right-side'>
              <div class='preview'>Preview</div>
              <div v-if="this.btnPreviewFlag == 5" class='right-side-bg'
                :style="'font-family:' + general.font.family + ';background-color:' + uiElements.background.color">
                <div class='form-demo' :style="formDemoStyle" :class='{ formShadow: uiElements.background.formShadow }'>
                  <div class='question2' :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">
                    What is your (approximate) monthly marketing budget?<span
                      v-if='uiElements.question.asterisk_for_required'> *</span>
                  </div>
                  <div class="scale-slider">
                    <div class="counter">
                      <div class="counter-value">${{ slider }}</div>
                    </div>
                    <ui-slider style="margin-bottom: 30px;" class="slider" :min="1000" :max="10000"
                      v-model="slider"></ui-slider>
                  </div>
                  <div class="continue-button-wrapper" :class='{
                    btnFull: uiElements.step_navigation.back_button.hide,
                    btnHalf: !uiElements.step_navigation.back_button.hide
                  }'>
                    <div :style="'width:' + uiElements.step_navigation.next_button.width + '%'">
                      <ui-button buttonType='button' :style="continueButtonStyle"
                        :class='{ shadow: uiElements.step_navigation.next_button.shadow }'>
                        {{ uiElements.step_navigation.next_button.text }}
                        <i class="material-icons">{{ uiElements.step_navigation.next_button.icon }}</i>
                      </ui-button>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="this.btnPreviewFlag == 0" class='right-side-bg'
                :style="'font-family:' + general.font.family + ';background-color:' + uiElements.background.color">
                <div class='form-demo' :style="formDemoStyle" :class='{ formShadow: uiElements.background.formShadow }'>
                  <div class='question' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">Question
                  </div>
                  <div class='question_des' v-if="!uiElements.question.hide_question_labels" :style="{
                    color: typography.question_description.font.color,
                    fontSize: typography.question_description.font.size + 'px',
                    fontFamily: typography.question_description.font.family,
                    fontWeight: typography.question_description.font.weight,
                    lineHeight: typography.question_description.font.line_height + 'px',
                    textAlign: typography.question_description.text_align
                  }"> This is the question description.
                  </div>
                  <input type="text" class='activeone' value='question answer' :style="{
                    textAlign: typography.input_box.text_align
                  }">
                  <div class='required' :style="{ color: general.colors.warning_color }"> This field is required </div>
                  <div class='question2' :style="{
                    color: typography.question_title.font.color,
                    fontSize: typography.question_title.font.size + 'px',
                    fontFamily: typography.question_title.font.family,
                    fontWeight: typography.question_title.font.weight,
                    lineHeight: typography.question_title.font.line_height + 'px',
                    textAlign: typography.question_title.text_align
                  }">
                    Which Platform(s) will the app will be build on ?<span
                      v-if='uiElements.question.asterisk_for_required'> *</span>
                  </div>
                  <ul class="radio-list">
                    <li v-for="(choice, cindex) in choices" :key="cindex">
                      <input name='question1' type="radio">
                      <label :for="'choice' + cindex" class="checkbox-label">{{ choice }}</label>
                    </li>
                  </ul>

                  <div class='gdpr'>
                    <div :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }"> GDPR Marketing Permissions
                    </div>
                    <div class='grdp-details' :style="{
                      color: typography.question_description.font.color,
                      fontSize: typography.question_description.font.size + 'px',
                      fontFamily: typography.question_description.font.family,
                      fontWeight: typography.question_description.font.weight,
                      lineHeight: typography.question_description.font.line_height + 'px',
                      textAlign: typography.question_description.text_align
                    }"> We will use the information you provide
                      on this form to be in touch with you and to provide updates and marketing. Please let us know all
                      the ways you like to hear from us.
                    </div>
                    <div class="gdpr-terms">
                      <ui-checkbox-group vertical :options="['Email', 'Direct Post', 'Customized online advertising']"
                        v-model="gdpr"></ui-checkbox-group>
                    </div>
                    <p :style='{ color: theme.general.colors.text_color }'><small>We use <strong>Leadgen</strong> as our
                        marketing automation platform. By submitting this form, you acknowledge that the information you
                        provide will be transferred to <strong>Leadgen</strong>.</small></p>

                  </div>
                  <div class='both-buttons'>
                    <div class="back-button-wrapper">
                      <div :style="'width:' + uiElements.step_navigation.back_button.width + '%;'"
                        v-if="!uiElements.step_navigation.back_button.hide">
                        <ui-button buttonType='button' :style="backButtonStyle"
                          :class='{ shadow: uiElements.step_navigation.back_button.shadow }'>
                          <i class="material-icons">{{ uiElements.step_navigation.back_button.icon }}</i>
                          {{ uiElements.step_navigation.back_button.text }}
                        </ui-button>
                      </div>
                    </div>

                    <div class="continue-button-wrapper" :class='{
                      btnFull: uiElements.step_navigation.back_button.hide,
                      btnHalf: !uiElements.step_navigation.back_button.hide
                    }'>
                      <div :style="'width:' + uiElements.step_navigation.next_button.width + '%'">
                        <ui-button buttonType='button' :style="continueButtonStyle"
                          :class='{ shadow: uiElements.step_navigation.next_button.shadow }'>
                          {{ uiElements.step_navigation.next_button.text }}
                          <i class="material-icons">{{ uiElements.step_navigation.next_button.icon }}</i>
                        </ui-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </ui-tab>

        <!-- Step Navigation -->
        <ui-tab title="Step Navigation" id="step_navigation">
          <div class='theme-setting-both-sides'>
            <div class='left-side '>
              <h3>Step Navigation</h3>
              <ui-tabs type="text" style="width:90%" background-color="white" text-color-active="black"
                text-color="black" indicatorColor="primary">
                <ui-tab title="Back Button" @select="buttonPreview(0)">
                  <ui-tabs type="text" background-color="white" text-color-active="black" text-color="black"
                    indicatorColor="primary">
                    <ui-tab title="Default Appearance">
                      <div class='style-wrapper'>
                        <div class='buttons-style-wrapper'>
                          <div class='buttons-style-wrapper-options'>
                            <label class='sub-title'> Button Text</label>
                            <ui-textbox :value="(uiElements.step_navigation.back_button.text)"
                              @input="updateUiElementsSetting($event, 'uiStepNavBackText')">
                            </ui-textbox>
                          </div>

                          <div class='buttons-style-wrapper-options'>
                            <label class='sub-title'> Select the Icon </label>
                            <ui-select has-search v-if="renderIcons" :options="allIcons"
                              :value="(uiElements.step_navigation.back_button.icon)"
                              @input="updateUiElementsSetting($event.value, 'uiStepNavBackIconUrl')"
                              placeholder="Select the Icon">
                              <template slot-scope="props" slot="option">
                                <i class="material-icons" v-if="props.option.value">{{ props.option.value }} </i>
                                <label v-else>Select</label>
                              </template>
                            </ui-select>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> TypeFace</label>
                            <ui-select :options="fontFamily" :hasSearch=true
                              :value="(uiElements.step_navigation.back_button.font.family)"
                              @input="updateUiElementsSetting($event, 'uiStepNavBackFamily')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>
                          <div class='all-style-options'>
                            <label class='headings'> Font Size</label> <br />
                            <ui-select :options="fontSize" :value="(uiElements.step_navigation.back_button.font.size)"
                              @input="updateUiElementsSetting($event, 'uiStepNavBackSize')" class="input-all">
                            </ui-select>
                          </div>
                          <div class='all-style-options'>
                            <label class='headings'> Weight</label> <br />
                            <ui-select :options="fontWeight"
                              :value="(uiElements.step_navigation.back_button.font.weight)"
                              @input="updateUiElementsSetting($event, 'uiStepNavBackWeight')" class="input-all">
                            </ui-select>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options '>
                            <label class='headings'>Button Height </label>
                            <ui-textbox :value="(uiElements.step_navigation.back_button.font.height)"
                              @input="updateUiElementsSetting($event, 'uiStepNavBackHeight')" type="number" :min="30"
                              :max="100">
                            </ui-textbox>
                          </div>
                          <div class='all-style-options buttons-color '>
                            <label class='headings'> Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.back_button.font.color }"
                                @click="showChromePicker(66)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.back_button.font.color)"
                                @input="updateUiElementsSetting($event, 'uiStepNavBackColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.back_button.font.color)"
                              @input="updateUiElementsSetting($event, 'uiStepNavBackColor')"
                              :class="{ hide: !chromePickers[66].visible }" class='chrome_picker' id="chrome_picker">
                            </chrome-picker>
                          </div>
                          <div class='all-style-options  buttons-color'>
                            <label class='headings'> Background Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.back_button.backgroundColor }"
                                @click="showChromePicker(40)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.back_button.backgroundColor)"
                                @input="updateUiElementsSetting($event, 'uiStepNavBackBackgroundColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.back_button.backgroundColor)"
                              @input="updateUiElementsSetting($event, 'uiStepNavBackBackgroundColor')"
                              :class="{ hide: !chromePickers[40].visible }" class='chrome_picker' id="chrome_picker">
                            </chrome-picker>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options shadow-radius'>
                            <label class='headings'>Corner Radius </label>
                            <ui-textbox :value="(uiElements.step_navigation.back_button.borderRadius)"
                              @input="updateUiElementsSetting($event, 'uiStepNavBackRadius')" type="number" :min="0"
                              :max="20">
                            </ui-textbox>
                          </div>

                          <div class='all-style-options shadow-radius'>
                            <div class='headings'>Shadow </div>
                            <ui-switch :value="(uiElements.step_navigation.back_button.shadow)"
                              @change="updateUiElementsSetting($event, 'uiStepNavBackShadow')">
                            </ui-switch>
                          </div>

                          <div class='all-style-options shadow-radius'>
                            <div class='headings'>Hide Back Button </div>
                            <ui-switch :value="(uiElements.step_navigation.back_button.hide)"
                              @change="updateUiElementsSetting($event, 'uiStepNavBackHide')">
                            </ui-switch>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options shadow-radius'>
                            <label class='headings'>Button Width In % </label>
                            <ui-textbox :value="(uiElements.step_navigation.back_button.width)"
                              @input="updateUiElementsSetting($event, 'uiStepNavBackWidth')" type="number" :min="30"
                              :max="100">
                            </ui-textbox>
                          </div>
                          <div class='all-style-options'>
                            <label class='headings'>Button Alignment</label> <br />
                            <ui-select :options="buttonAlignment"
                              :value="(uiElements.step_navigation.back_button.alignment)"
                              @input="updateUiElementsSetting($event, 'uiStepNavBackAlignment')" class="input-all">
                            </ui-select>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> Border Style </label>
                            <ui-select :options="borderStyle"
                              :value="(uiElements.step_navigation.back_button.default_style.border.style)"
                              @input="updateUiElementsSetting($event, 'backBorderStyle')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>

                          <div class='all-style-options'>
                            <label class='headings'> Border Stroke </label>
                            <ui-select :options="borderWidth"
                              :value="(uiElements.step_navigation.back_button.default_style.border.width)"
                              @input="updateUiElementsSetting($event, 'backBorderWidth')" class="input-all">
                            </ui-select>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Border Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.back_button.default_style.border.color }"
                                @click="showChromePicker(41)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.back_button.default_style.border.color)"
                                @input="updateUiElementsSetting($event, 'backBorderColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.back_button.default_style.border.color)"
                              @input="updateUiElementsSetting($event, 'backBorderColor')"
                              :class="{ hide: !chromePickers[41].visible }" class='chrome_picker'
                              id="chrome_picker_btn_border_color">
                            </chrome-picker>
                          </div>
                        </div>

                      </div>
                    </ui-tab>

                    <ui-tab title="Hover Appearance">
                      <div class='style-wrapper'>

                        <div class='buttons-style-wrapper all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> Border Style </label>
                            <ui-select :options="borderStyle"
                              :value="(uiElements.step_navigation.back_button.hover_style.border.style)"
                              @input="updateUiElementsSetting($event, 'backBorderStyleHover')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>

                          <div class='all-style-options'>
                            <label class='headings'> Border Stroke </label>
                            <ui-select :options="borderWidth"
                              :value="(uiElements.step_navigation.back_button.hover_style.border.width)"
                              @input="updateUiElementsSetting($event, 'backBorderWidthHover')" class="input-all">
                            </ui-select>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Border Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.back_button.hover_style.border.color }"
                                @click="showChromePicker(66)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.back_button.hover_style.border.color)"
                                @input="updateUiElementsSetting($event, 'backBorderColorHover')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.back_button.hover_style.border.color)"
                              @input="updateUiElementsSetting($event, 'backBorderColorHover')"
                              :class="{ hide: !chromePickers[66].visible }" class='chrome_picker'
                              id="chrome_picker_btn_borderall">
                            </chrome-picker>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options buttons-color '>
                            <label class='headings'> Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.back_button.hover_style.color }"
                                @click="showChromePicker(42)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.back_button.hover_style.color)"
                                @input="updateUiElementsSetting($event, 'uiStepNavBackColorHover')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.back_button.hover_style.color)"
                              @input="updateUiElementsSetting($event, 'uiStepNavBackColorHover')"
                              :class="{ hide: !chromePickers[42].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>
                          <div class='all-style-options  buttons-color'>
                            <label class='headings'> Background Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.back_button.hover_style.backgroundColor }"
                                @click="showChromePicker(43)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.back_button.hover_style.backgroundColor)"
                                @input="updateUiElementsSetting($event, 'uiStepNavBackBgColorHover')">
                              </ui-textbox>
                            </div>
                            <chrome-picker
                              :value="(uiElements.step_navigation.back_button.hover_style.backgroundColor == null ? 'fff' : uiElements.step_navigation.back_button.hover_style.backgroundColor)"
                              @input="updateUiElementsSetting($event, 'uiStepNavBackBgColorHover')"
                              :class="{ hide: !chromePickers[43].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>

                          </div>
                        </div>

                      </div>
                    </ui-tab>

                    <ui-tab title="Active Appearance">
                      <div class='style-wrapper'>

                        <div class='buttons-style-wrapper all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> Border Style </label>
                            <ui-select :options="borderStyle"
                              :value="(uiElements.step_navigation.back_button.active_style.border.style)"
                              @input="updateUiElementsSetting($event, 'backBorderStyleActive')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>

                          <div class='all-style-options'>
                            <label class='headings'> Border Stroke </label>
                            <ui-select :options="borderWidth"
                              :value="(uiElements.step_navigation.back_button.active_style.border.width)"
                              @input="updateUiElementsSetting($event, 'backBorderWidthActive')" class="input-all">
                            </ui-select>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Border Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.back_button.active_style.border.color }"
                                @click="showChromePicker(44)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.back_button.active_style.border.color)"
                                @input="updateUiElementsSetting($event, 'backBorderColorActive')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.back_button.active_style.border.color)"
                              @input="updateUiElementsSetting($event, 'backBorderColorActive')"
                              :class="{ hide: !chromePickers[44].visible }" class='chrome_picker'
                              id="chrome_picker_btn_borderall">
                            </chrome-picker>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options buttons-color '>
                            <label class='headings'> Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.back_button.active_style.color }"
                                @click="showChromePicker(45)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.back_button.active_style.color)"
                                @input="updateUiElementsSetting($event, 'uiStepNavBackColorActive')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.back_button.active_style.color)"
                              @input="updateUiElementsSetting($event, 'uiStepNavBackColorActive')"
                              :class="{ hide: !chromePickers[45].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>
                          <div class='all-style-options  buttons-color'>
                            <label class='headings'> Background Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.back_button.active_style.backgroundColor }"
                                @click="showChromePicker(46)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.back_button.active_style.backgroundColor)"
                                @input="updateUiElementsSetting($event, 'uiStepNavBackBgColorActive')">
                              </ui-textbox>
                            </div>
                            <chrome-picker
                              :value="(uiElements.step_navigation.back_button.active_style.backgroundColor == null ? 'fff' : uiElements.step_navigation.back_button.active_style.backgroundColor)"
                              @input="updateUiElementsSetting($event, 'uiStepNavBackBgColorActive')"
                              :class="{ hide: !chromePickers[46].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>
                        </div>

                      </div>
                    </ui-tab>

                  </ui-tabs>
                </ui-tab>

                <ui-tab title="Continue Button" @select="buttonPreview(0)">
                  <ui-tabs type="text" background-color="white" text-color-active="black" text-color="black"
                    indicatorColor="primary">
                    <ui-tab title="Default Appearance">
                      <div class='style-wrapper'>
                        <div class='buttons-style-wrapper'>
                          <div class='buttons-style-wrapper-options'>
                            <label class='sub-title'> Button Text</label>
                            <ui-textbox :value="(uiElements.step_navigation.next_button.text)"
                              @input="updateUiElementsSetting($event, 'uiStepNavNextText')">
                            </ui-textbox>
                          </div>

                          <div class='buttons-style-wrapper-options'>
                            <label class='sub-title'> Icon Name</label>
                            <ui-select v-if="renderIcons" :options="allIcons"
                              :value="(uiElements.step_navigation.next_button.icon)" :hasSearch=true
                              @input="updateUiElementsSetting($event.value, 'uiStepNavNextIconUrl')"
                              placeholder="Select the Icon">
                              <template slot-scope="props" slot="option">
                                <i class="material-icons" v-if="props.option.value">{{ props.option.value }} </i>
                                <label v-else>Select</label>
                              </template>
                            </ui-select>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> TypeFace</label>
                            <ui-select :options="fontFamily" :hasSearch=true
                              :value="(uiElements.step_navigation.next_button.font.family)"
                              @input="updateUiElementsSetting($event, 'uiStepNavNextFamily')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>
                          <div class='all-style-options'>
                            <label class='headings'> Font Size</label> <br />
                            <ui-select :options="fontSize" :value="(uiElements.step_navigation.next_button.font.size)"
                              @input="updateUiElementsSetting($event, 'uiStepNavNextSize')" class="input-all">
                            </ui-select>
                          </div>
                          <div class='all-style-options'>
                            <label class='headings'> Weight</label> <br />
                            <ui-select :options="fontWeight"
                              :value="(uiElements.step_navigation.next_button.font.weight)"
                              @input="updateUiElementsSetting($event, 'uiStepNavNextWeight')" class="input-all">
                            </ui-select>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options '>
                            <label class='headings'>Button Height </label>
                            <ui-textbox :value="(uiElements.step_navigation.next_button.font.height)"
                              @input="updateUiElementsSetting($event, 'uiStepNavNextHeight')" type="number" :min="30"
                              :max="100">
                            </ui-textbox>
                          </div>

                          <div class='all-style-options buttons-color '>
                            <label class='headings'> Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.next_button.font.color }"
                                @click="showChromePicker(47)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.next_button.font.color)"
                                @input="updateUiElementsSetting($event, 'uiStepNavNextColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.next_button.font.color)"
                              @input="updateUiElementsSetting($event, 'uiStepNavNextColor')"
                              :class="{ hide: !chromePickers[47].visible }" class='chrome_picker' id="chrome_picker">
                            </chrome-picker>
                          </div>
                          <div class='all-style-options  buttons-color'>
                            <label class='headings'> Background Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.next_button.backgroundColor }"
                                @click="showChromePicker(48)">
                              </div>

                              <ui-textbox :value="(uiElements.step_navigation.next_button.backgroundColor)"
                                @input="updateUiElementsSetting($event, 'uiStepNavNextBackgroundColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.next_button.backgroundColor)"
                              @input="updateUiElementsSetting($event, 'uiStepNavNextBackgroundColor')"
                              :class="{ hide: !chromePickers[48].visible }" class='chrome_picker' id="chrome_picker">
                            </chrome-picker>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options shadow-radius'>

                            <label class='headings'>Corner Radius </label>
                            <ui-textbox :value="(uiElements.step_navigation.next_button.borderRadius)"
                              @input="updateUiElementsSetting($event, 'uiStepNavNextRadius')" type="number" :min="0"
                              :max="20">
                            </ui-textbox>

                          </div>

                          <div class='all-style-options shadow-radius'>
                            <div class='headings' style="height:45px">Shadow </div>
                            <ui-switch :value="(uiElements.step_navigation.next_button.shadow)"
                              @change="updateUiElementsSetting($event, 'uiStepNavNextShadow')">
                            </ui-switch>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options shadow-radius'>
                            <label class='headings'>Button Width In % </label>
                            <ui-textbox :value="(uiElements.step_navigation.next_button.width)"
                              @input="updateUiElementsSetting($event, 'uiStepNavNextWidth')" type="number" :min="30"
                              :max="100">
                            </ui-textbox>
                          </div>

                          <div class='all-style-options'>
                            <label class='headings'>Button Alignment</label> <br />
                            <ui-select :options="buttonAlignment"
                              :value="(uiElements.step_navigation.next_button.alignment)"
                              @input="updateUiElementsSetting($event, 'uiStepNavNextAlignment')" class="input-all">
                            </ui-select>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> Border Style </label>
                            <ui-select :options="borderStyle"
                              :value="(uiElements.step_navigation.next_button.default_style.border.style)"
                              @input="updateUiElementsSetting($event, 'nextBorderStyle')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>

                          <div class='all-style-options'>
                            <label class='headings'> Border Stroke </label>
                            <ui-select :options="borderWidth"
                              :value="(uiElements.step_navigation.next_button.default_style.border.width)"
                              @input="updateUiElementsSetting($event, 'nextBorderWidth')" class="input-all">
                            </ui-select>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Border Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.next_button.default_style.border.color }"
                                @click="showChromePicker(49)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.next_button.default_style.border.color)"
                                @input="updateUiElementsSetting($event, 'nextBorderColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.next_button.default_style.border.color)"
                              @input="updateUiElementsSetting($event, 'nextBorderColor')"
                              :class="{ hide: !chromePickers[49].visible }" class='chrome_picker'
                              id="chrome_picker_btn_border_color">
                            </chrome-picker>
                          </div>
                        </div>
                        <div class="image-icon-titles">Auto Navigation</div>
                        <div class='all-style-options-wrapper'>
                          <div class="all-style-options">
                            <label class="headings">Continue Button Visible</label>
                            <ui-switch :value="(!uiElements.step_navigation.next_button.hidden)"
                              @change="updateUiElementsSetting($event, 'uiStepNavNextVisible')">
                            </ui-switch>
                          </div>
                        </div>
                      </div>
                    </ui-tab>

                    <ui-tab title="Hover Appearance">
                      <div class='style-wrapper'>
                        <div class='buttons-style-wrapper all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> Border Style </label>
                            <ui-select :options="borderStyle"
                              :value="(uiElements.step_navigation.next_button.hover_style.border.style)"
                              @input="updateUiElementsSetting($event, 'nextBorderStyleHover')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>

                          <div class='all-style-options'>
                            <label class='headings'> Border Stroke </label>
                            <ui-select :options="borderWidth"
                              :value="(uiElements.step_navigation.next_button.hover_style.border.width)"
                              @input="updateUiElementsSetting($event, 'nextBorderWidthHover')" class="input-all">
                            </ui-select>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Border Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.next_button.hover_style.border.color }"
                                @click="showChromePicker(50)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.next_button.hover_style.border.color)"
                                @input="updateUiElementsSetting($event, 'nextBorderColorHover')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.next_button.hover_style.border.color)"
                              @input="updateUiElementsSetting($event, 'nextBorderColorHover')"
                              :class="{ hide: !chromePickers[50].visible }" class='chrome_picker'
                              id="chrome_picker_btn_borderall">
                            </chrome-picker>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options buttons-color '>
                            <label class='headings'> Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.next_button.hover_style.color }"
                                @click="showChromePicker(51)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.next_button.hover_style.color)"
                                @input="updateUiElementsSetting($event, 'uiStepNavNextColorHover')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.next_button.hover_style.color)"
                              @input="updateUiElementsSetting($event, 'uiStepNavNextColorHover')"
                              :class="{ hide: !chromePickers[51].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>
                          <div class='all-style-options  buttons-color'>
                            <label class='headings'> Background Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.next_button.hover_style.backgroundColor }"
                                @click="showChromePicker(52)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.next_button.hover_style.backgroundColor)"
                                @input="updateUiElementsSetting($event, 'uiStepNavNextBgColorHover')">
                              </ui-textbox>
                            </div>
                            <chrome-picker
                              :value="(uiElements.step_navigation.next_button.hover_style.backgroundColor == null ? 'fff' : uiElements.step_navigation.next_button.hover_style.backgroundColor)"
                              @input="updateUiElementsSetting($event, 'uiStepNavNextBgColorHover')"
                              :class="{ hide: !chromePickers[52].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>
                        </div>

                      </div>
                    </ui-tab>

                    <ui-tab title="Active Appearance">
                      <div class='style-wrapper'>
                        <div class='buttons-style-wrapper all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> Border Style </label>
                            <ui-select :options="borderStyle"
                              :value="(uiElements.step_navigation.next_button.active_style.border.style)"
                              @input="updateUiElementsSetting($event, 'nextBorderStyleActive')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>

                          <div class='all-style-options'>
                            <label class='headings'> Border Stroke </label>
                            <ui-select :options="borderWidth"
                              :value="(uiElements.step_navigation.next_button.active_style.border.width)"
                              @input="updateUiElementsSetting($event, 'nextBorderWidthActive')" class="input-all">
                            </ui-select>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Border Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.next_button.active_style.border.color }"
                                @click="showChromePicker(53)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.next_button.active_style.border.color)"
                                @input="updateUiElementsSetting($event, 'nextBorderColorActive')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.next_button.active_style.border.color)"
                              @input="updateUiElementsSetting($event, 'nextBorderColorActive')"
                              :class="{ hide: !chromePickers[53].visible }" class='chrome_picker'
                              id="chrome_picker_btn_borderall">
                            </chrome-picker>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options buttons-color '>
                            <label class='headings'> Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.next_button.active_style.color }"
                                @click="showChromePicker(54)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.next_button.active_style.color)"
                                @input="updateUiElementsSetting($event, 'uiStepNavNextColorActive')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.next_button.active_style.color)"
                              @input="updateUiElementsSetting($event, 'uiStepNavNextColorActive')"
                              :class="{ hide: !chromePickers[54].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>
                          <div class='all-style-options  buttons-color'>
                            <label class='headings'> Background Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.next_button.active_style.backgroundColor }"
                                @click="showChromePicker(55)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.next_button.active_style.backgroundColor)"
                                @input="updateUiElementsSetting($event, 'uiStepNavNextBgColorActive')">
                              </ui-textbox>
                            </div>
                            <chrome-picker
                              :value="(uiElements.step_navigation.next_button.active_style.backgroundColor == null ? 'fff' : uiElements.step_navigation.next_button.active_style.backgroundColor)"
                              @input="updateUiElementsSetting($event, 'uiStepNavNextBgColorActive')"
                              :class="{ hide: !chromePickers[55].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>
                        </div>

                      </div>
                    </ui-tab>

                  </ui-tabs>
                </ui-tab>

                <ui-tab title="Submit Button" @select="buttonPreview(3)">
                  <ui-tabs type="text" background-color="white" text-color-active="black" text-color="black"
                    indicatorColor="primary">
                    <ui-tab title="Default Appearance">
                      <div class='style-wrapper'>
                        <div class='buttons-style-wrapper'>
                          <div class='buttons-style-wrapper-options'>
                            <label class='sub-title'> Button Text</label>
                            <ui-textbox :value="(uiElements.step_navigation.submit_button.text)"
                              @input="updateUiElementsSetting($event, 'uiStepNavSubmitText')">
                            </ui-textbox>
                          </div>

                          <div class='buttons-style-wrapper-options'>
                            <label class='sub-title'> Icon Name</label>

                            <ui-select v-if="renderIcons" :options="allIcons"
                              :value="(uiElements.step_navigation.submit_button.icon)" :hasSearch=true
                              @input="updateUiElementsSetting($event.value, 'uiStepNavSubmitIconUrl')"
                              placeholder="Select the Icon">
                              <template slot-scope="props" slot="option">
                                <i class="material-icons" v-if="props.option.value">{{ props.option.value }} </i>
                                <label v-else>Select</label>
                              </template>
                            </ui-select>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> TypeFace</label>
                            <ui-select :options="fontFamily" :hasSearch=true
                              :value="(uiElements.step_navigation.submit_button.font.family)"
                              @input="updateUiElementsSetting($event, 'uiStepNavSubmitFamily')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>
                          <div class='all-style-options'>
                            <label class='headings'> Font Size</label> <br />
                            <ui-select :options="fontSize" :value="(uiElements.step_navigation.submit_button.font.size)"
                              @input="updateUiElementsSetting($event, 'uiStepNavSubmitSize')" class="input-all">
                            </ui-select>
                          </div>
                          <div class='all-style-options'>
                            <label class='headings'> Weight</label> <br />
                            <ui-select :options="fontWeight"
                              :value="(uiElements.step_navigation.submit_button.font.weight)"
                              @input="updateUiElementsSetting($event, 'uiStepNavSubmitWeight')" class="input-all">
                            </ui-select>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options '>
                            <label class='headings'>Button Height </label>
                            <ui-textbox :value="(uiElements.step_navigation.submit_button.font.height)"
                              @input="updateUiElementsSetting($event, 'uiStepNavSubmitHeight')" type="number" :min="30"
                              :max="100">
                            </ui-textbox>
                          </div>

                          <div class='all-style-options buttons-color '>
                            <label class='headings'> Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.submit_button.font.color }"
                                @click="showChromePicker(56)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.submit_button.font.color)"
                                @input="updateUiElementsSetting($event, 'uiStepNavSubmitColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.submit_button.font.color)"
                              @input="updateUiElementsSetting($event, 'uiStepNavSubmitColor')"
                              :class="{ hide: !chromePickers[56].visible }" class='chrome_picker' id="chrome_picker">
                            </chrome-picker>
                          </div>
                          <div class='all-style-options  buttons-color'>
                            <label class='headings'> Background Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.submit_button.backgroundColor }"
                                @click="showChromePicker(57)">
                              </div>

                              <ui-textbox :value="(uiElements.step_navigation.submit_button.backgroundColor)"
                                @input="updateUiElementsSetting($event, 'uiStepNavSubmitBackgroundColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.submit_button.backgroundColor)"
                              @input="updateUiElementsSetting($event, 'uiStepNavSubmitBackgroundColor')"
                              :class="{ hide: !chromePickers[57].visible }" class='chrome_picker' id="chrome_picker">
                            </chrome-picker>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options shadow-radius'>
                            <label class='headings'>Corner Radius </label>
                            <ui-textbox :value="(uiElements.step_navigation.submit_button.borderRadius)"
                              @input="updateUiElementsSetting($event, 'uiStepNavSubmitRadius')" type="number" :min="0"
                              :max="20">
                            </ui-textbox>
                          </div>

                          <div class='all-style-options shadow-radius'>
                            <div class='headings' style="height:45px">Shadow </div>
                            <ui-switch :value="(uiElements.step_navigation.submit_button.shadow)"
                              @change="updateUiElementsSetting($event, 'uiStepNavSubmitShadow')">
                            </ui-switch>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options shadow-radius'>
                            <label class='headings'>Button Width In % </label>
                            <ui-textbox :value="(uiElements.step_navigation.submit_button.width)"
                              @input="updateUiElementsSetting($event, 'uiStepNavSubmitWidth')" type="number" :min="30"
                              :max="100">
                            </ui-textbox>
                          </div>

                          <div class='all-style-options'>
                            <label class='headings'>Button Alignment</label> <br />
                            <ui-select :options="buttonAlignment"
                              :value="(uiElements.step_navigation.submit_button.alignment)"
                              @input="updateUiElementsSetting($event, 'uiStepNavSubmitAlignment')" class="input-all">
                            </ui-select>
                          </div>

                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> Border Style </label>
                            <ui-select :options="borderStyle"
                              :value="(uiElements.step_navigation.submit_button.default_style.border.style)"
                              @input="updateUiElementsSetting($event, 'submitBorderStyle')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>

                          <div class='all-style-options'>
                            <label class='headings'> Border Stroke </label>
                            <ui-select :options="borderWidth"
                              :value="(uiElements.step_navigation.submit_button.default_style.border.width)"
                              @input="updateUiElementsSetting($event, 'submitBorderWidth')" class="input-all">
                            </ui-select>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Border Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.submit_button.default_style.border.color }"
                                @click="showChromePicker(58)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.submit_button.default_style.border.color)"
                                @input="updateUiElementsSetting($event, 'submitBorderColor')">
                              </ui-textbox>
                            </div>
                            <chrome-picker
                              :value="(uiElements.step_navigation.submit_button.default_style.border.color)"
                              @input="updateUiElementsSetting($event, 'submitBorderColor')"
                              :class="{ hide: !chromePickers[58].visible }" class='chrome_picker'
                              id="chrome_picker_btn_border_color">
                            </chrome-picker>
                          </div>
                        </div>

                      </div>
                    </ui-tab>

                    <ui-tab title="Hover Appearance">
                      <div class='style-wrapper'>
                        <div class='buttons-style-wrapper all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> Border Style </label>
                            <ui-select :options="borderStyle"
                              :value="(uiElements.step_navigation.submit_button.hover_style.border.style)"
                              @input="updateUiElementsSetting($event, 'submitBorderStyleHover')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>

                          <div class='all-style-options'>
                            <label class='headings'> Border Stroke </label>
                            <ui-select :options="borderWidth"
                              :value="(uiElements.step_navigation.submit_button.hover_style.border.width)"
                              @input="updateUiElementsSetting($event, 'submitBorderWidthHover')" class="input-all">
                            </ui-select>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Border Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.submit_button.hover_style.border.color }"
                                @click="showChromePicker(59)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.submit_button.hover_style.border.color)"
                                @input="updateUiElementsSetting($event, 'submitBorderColorHover')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.submit_button.hover_style.border.color)"
                              @input="updateUiElementsSetting($event, 'submitBorderColorHover')"
                              :class="{ hide: !chromePickers[59].visible }" class='chrome_picker'
                              id="chrome_picker_btn_borderall">
                            </chrome-picker>
                          </div>
                        </div>
                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options buttons-color '>
                            <label class='headings'> Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.submit_button.hover_style.color }"
                                @click="showChromePicker(60)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.submit_button.hover_style.color)"
                                @input="updateUiElementsSetting($event, 'uiStepNavSubmitColorHover')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.submit_button.hover_style.color)"
                              @input="updateUiElementsSetting($event, 'uiStepNavSubmitColorHover')"
                              :class="{ hide: !chromePickers[60].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>
                          <div class='all-style-options  buttons-color'>
                            <label class='headings'> Background Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.submit_button.hover_style.backgroundColor }"
                                @click="showChromePicker(61)">
                              </div>
                              <ui-textbox
                                :value="(uiElements.step_navigation.submit_button.hover_style.backgroundColor)"
                                @input="updateUiElementsSetting($event, 'uiStepNavSubmitBgColorHover')">
                              </ui-textbox>
                            </div>
                            <chrome-picker
                              :value="(uiElements.step_navigation.submit_button.hover_style.backgroundColor == null ? 'fff' : uiElements.step_navigation.submit_button.hover_style.backgroundColor)"
                              @input="updateUiElementsSetting($event, 'uiStepNavSubmitBgColorHover')"
                              :class="{ hide: !chromePickers[61].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>
                        </div>

                      </div>
                    </ui-tab>

                    <ui-tab title="Active Appearance">
                      <div class='style-wrapper'>
                        <div class='buttons-style-wrapper all-style-options-wrapper'>
                          <div class='all-style-options'>
                            <label class='headings'> Border Style </label>
                            <ui-select :options="borderStyle"
                              :value="(uiElements.step_navigation.submit_button.active_style.border.style)"
                              @input="updateUiElementsSetting($event, 'submitBorderStyleActive')"
                              class="input-all input-font-family">
                            </ui-select>
                          </div>

                          <div class='all-style-options'>
                            <label class='headings'> Border Stroke </label>
                            <ui-select :options="borderWidth"
                              :value="(uiElements.step_navigation.submit_button.active_style.border.width)"
                              @input="updateUiElementsSetting($event, 'submitBorderWidthActive')" class="input-all">
                            </ui-select>
                          </div>

                          <div class='all-style-options '>
                            <label class='headings'> Border Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.submit_button.active_style.border.color }"
                                @click="showChromePicker(62)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.submit_button.active_style.border.color)"
                                @input="updateUiElementsSetting($event, 'submitBorderColorActive')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.submit_button.active_style.border.color)"
                              @input="updateUiElementsSetting($event, 'submitBorderColorActive')"
                              :class="{ hide: !chromePickers[62].visible }" class='chrome_picker'
                              id="chrome_picker_btn_borderall">
                            </chrome-picker>
                          </div>
                        </div>

                        <div class='all-style-options-wrapper'>
                          <div class='all-style-options buttons-color '>
                            <label class='headings'> Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.submit_button.active_style.color }"
                                @click="showChromePicker(63)">
                              </div>
                              <ui-textbox :value="(uiElements.step_navigation.submit_button.active_style.color)"
                                @input="updateUiElementsSetting($event, 'uiStepNavSubmitColorActive')">
                              </ui-textbox>
                            </div>
                            <chrome-picker :value="(uiElements.step_navigation.submit_button.active_style.color)"
                              @input="updateUiElementsSetting($event, 'uiStepNavSubmitColorActive')"
                              :class="{ hide: !chromePickers[63].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>
                          <div class='all-style-options  buttons-color'>
                            <label class='headings'> Background Color</label>
                            <div class='color-wrapper'>
                              <div class="color-palettes"
                                :style="{ backgroundColor: uiElements.step_navigation.submit_button.active_style.backgroundColor }"
                                @click="showChromePicker(64)">
                              </div>
                              <ui-textbox
                                :value="(uiElements.step_navigation.submit_button.active_style.backgroundColor)"
                                @input="updateUiElementsSetting($event, 'uiStepNavSubmitBgColorActive')">
                              </ui-textbox>
                            </div>
                            <chrome-picker
                              :value="(uiElements.step_navigation.submit_button.active_style.backgroundColor == null ? 'fff' : uiElements.step_navigation.submit_button.active_style.backgroundColor)"
                              @input="updateUiElementsSetting($event, 'uiStepNavSubmitBgColorActive')"
                              :class="{ hide: !chromePickers[64].visible }" class='chrome_picker'
                              id="chrome_picker_btn_colorall">
                            </chrome-picker>
                          </div>
                        </div>

                      </div>
                    </ui-tab>

                  </ui-tabs>
                </ui-tab>

              </ui-tabs>
            </div>

            <div class='right-side'>
              <div class='preview'>Preview</div>

              <div v-if="this.btnPreviewFlag == 0 || this.btnPreviewFlag == 3" class='right-side-bg'
                :style="'font-family:' + general.font.family + ';background-color:' + uiElements.background.color">

                <div v-if="this.btnPreviewFlag == 0" class='preview-btns'>

                  <div class='form-demo' :style="formDemoStyle"
                    :class='{ formShadow: uiElements.background.formShadow }'>
                    <div class='question' v-if="!uiElements.question.hide_question_labels" :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }">Question
                    </div>
                    <div class='question_des' v-if="!uiElements.question.hide_question_labels" :style="{
                      color: typography.question_description.font.color,
                      fontSize: typography.question_description.font.size + 'px',
                      fontFamily: typography.question_description.font.family,
                      fontWeight: typography.question_description.font.weight,
                      lineHeight: typography.question_description.font.line_height + 'px',
                      textAlign: typography.question_description.text_align
                    }"> This is the question description.
                    </div>
                    <input type="text" class='activeone' value='question answer'
                      :style="{ textAlign: typography.input_box.text_align }">
                    <div class='required' :style="{ color: general.colors.warning_color }"> This field is required
                    </div>
                    <div class='question2' :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }">
                      Which Platform(s) will the app will be build on ?<span
                        v-if='uiElements.question.asterisk_for_required'>
                        *</span>
                    </div>
                    <ul class="radio-list">
                      <li v-for="(choice, cindex) in choices" :key="cindex">
                        <input name='question1' type="radio">
                        <label :for="'choice' + cindex" class="checkbox-label">{{ choice }}</label>
                      </li>
                    </ul>


                    <div class='both-buttons'>
                      <div class="back-button-wrapper">
                        <div :style="'width:' + uiElements.step_navigation.back_button.width + '%;'"
                          v-if="!uiElements.step_navigation.back_button.hide">
                          <ui-button buttonType='button' :style="backButtonStyle"
                            :class='{ shadow: uiElements.step_navigation.back_button.shadow }'>
                            <i class="material-icons">{{ uiElements.step_navigation.back_button.icon }}</i>
                            {{ uiElements.step_navigation.back_button.text }}
                          </ui-button>
                        </div>
                      </div>

                      <div class="continue-button-wrapper">
                        <div :style="'width:' + uiElements.step_navigation.next_button.width + '%'">
                          <ui-button buttonType='button' :style="continueButtonStyle"
                            :class='{ shadow: uiElements.step_navigation.next_button.shadow }'>
                            {{ uiElements.step_navigation.next_button.text }}
                            <i class="material-icons">{{ uiElements.step_navigation.next_button.icon }}</i>
                          </ui-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="this.btnPreviewFlag == 3" class='preview-btns'>
                  <div class='form-demo' :style="formDemoStyle"
                    :class='{ formShadow: uiElements.background.formShadow }'>
                    <div class='question' v-if="!uiElements.question.hide_question_labels" :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }">Question
                    </div>
                    <div class='question_des' v-if="!uiElements.question.hide_question_labels" :style="{
                      color: typography.question_description.font.color,
                      fontSize: typography.question_description.font.size + 'px',
                      fontFamily: typography.question_description.font.family,
                      fontWeight: typography.question_description.font.weight,
                      lineHeight: typography.question_description.font.line_height + 'px',
                      textAlign: typography.question_description.text_align
                    }"> This is the question description.
                    </div>
                    <input type="text" class='activeone' value='question answer'
                      :style="{ textAlign: typography.input_box.text_align }">
                    <div class='required' :style="{ color: general.colors.warning_color }"> This field is required
                    </div>
                    <div class='question2' :style="{
                      color: typography.question_title.font.color,
                      fontSize: typography.question_title.font.size + 'px',
                      fontFamily: typography.question_title.font.family,
                      fontWeight: typography.question_title.font.weight,
                      lineHeight: typography.question_title.font.line_height + 'px',
                      textAlign: typography.question_title.text_align
                    }">
                      Which Platform(s) will the app will be build on ?<span
                        v-if='uiElements.question.asterisk_for_required'>
                        *</span>
                    </div>
                    <ul class="radio-list">
                      <li v-for="(choice, cindex) in choices" :key="cindex">
                        <input name='question1' type="radio">
                        <label :for="'choice' + cindex" class="checkbox-label">{{ choice }}</label>
                      </li>
                    </ul>

                    <div class='both-buttons'>
                      <div class="back-button-wrapper">
                        <div :style="'width:' + uiElements.step_navigation.back_button.width + '%;'"
                          v-if="!uiElements.step_navigation.back_button.hide">
                          <ui-button buttonType='button' :style="backButtonStyle"
                            :class='{ shadow: uiElements.step_navigation.back_button.shadow }'>
                            <i class="material-icons">{{ uiElements.step_navigation.back_button.icon }}</i>
                            {{ uiElements.step_navigation.back_button.text }}
                          </ui-button>
                        </div>
                      </div>

                      <div class="submit-button-wrapper" :class='{
                        btnFull: uiElements.step_navigation.back_button.hide,
                        btnHalf: !uiElements.step_navigation.back_button.hide
                      }'>
                        <div :style="'width:' + uiElements.step_navigation.submit_button.width + '%'">
                          <ui-button buttonType='button' :style="submitButtonStyle"
                            :class='{ shadow: uiElements.step_navigation.submit_button.shadow }'>
                            {{ uiElements.step_navigation.submit_button.text }}
                            <i class="material-icons">{{ uiElements.step_navigation.submit_button.icon }}</i>
                          </ui-button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>

          </div>
        </ui-tab>

      </ui-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as actionTypes from '@/store/action-types'
import { Chrome } from 'vue-color'
import * as deepmerge from 'deepmerge'
import icons from '@/store/icons'
import googleFonts from '@/store/google-fonts'
import userMixin from '@/mixins/user'
import FormThemeStore from '@/components/FormVariantTheme/components/FormThemeStore'

export default {
  props: ['color'],
  mixins: [userMixin],
  data() {
    return {
      slider: 5000,
      formId: '',
      variantId: '',
      fontFamily: ['Lato', 'Open Sans', 'Roboto', 'Raleway', 'PT Serif', 'Ubuntu', 'Montserrat', 'Oswald', 'PT Sans', 'Poppins', 'Muli', 'Oxygen', 'Lobster', 'Teko', 'Jura', 'Karma', 'Nunito Sans', 'Prompt', 'Work Sans', 'Neuton', 'Cabin', 'Arvo', 'Quicksand', 'BioRhyme', 'Trirong', 'Lemonada', 'Bai Jamjuree', 'Mali', 'Krub', 'KoHo', 'Fahkwang', 'Kodchasan', 'Cormorant Infant', 'Infant', 'Maitree', 'Laila', 'Martel Sans', 'Saira', 'Cormorant', 'Changa', 'Zilla Slab', 'Martel'],
      typographyOptions: ['Question title', 'Question description'],
      raised: true,
      fontWeight: ['300', '400', '700'],
      fontSize: ['5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '16', '18', '20', '24', '26', '28', '30', '32', '34', '36', '38', '40', '42'],
      lineHeight: ['9', '10', '11', '12', '13', '14', '16', '18', '20', '24', '36', '48'],
      uiOptions: ['Background', 'Questions', 'Step Navigation'],
      borderStyle: ['None', 'Solid', 'Dotted', 'Dashed', 'Inset', 'Outset'],
      borderWidth: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      progressbarPosition: ['Top', 'Bottom'],
      menuSetTypo: true,
      menuSetUi: true,
      flag: 0,
      choices: ['Android', 'Windows'],
      customStyle: '',
      gdpr: [],
      btnPreviewFlag: 0,
      next: 40,
      back: 30,
      buttonAlignment: ['left', 'right', 'center'],
      renderIcons: false,
      chromePickers: [],
      chromePickersCount: 82,
      colors: {
        hex: '#000000'
      },
      colorValue: '',
      publishingTheme: false,
      displayPicker: false,
      fontsLoaded: []
    }
  },
  components: {
    'chrome-picker': Chrome,
    'form-theme-store': FormThemeStore
  },
  mounted: function () {
    for (let i = 0; i < this.chromePickersCount; i++) {
      this.chromePickers.push({
        id: i,
        visible: false
      })
    }
    this.addStyle()
    this.fontFamily = this.fontFamily.sort()
    setTimeout(() => {
      this.renderIcons = true
    }, 1000)
  },
  methods: {
    buttonPreview: function (x) {
      this.btnPreviewFlag = x
    },
    choiceStyle: function () {
      // scale
      this.customStyle += `#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .scale-slider .slider .ui-slider__track-background {
        height: 8px !important;
        border-radius: 5px !important;
        box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%) !important;
        border: 1px solid ${this.theme.ui_elements.scale.border_color} !important;
        background-color: ${this.theme.ui_elements.scale.config.stroke_color} !important;
      }`
      this.customStyle += `#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .scale-slider .slider .ui-slider__track-fill {
        height: 8px !important;
        border-radius: 5px !important;
        background: ${this.theme.ui_elements.scale.config.fill_color};
        box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
      }`
      this.customStyle += `#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .scale-slider .slider .ui-slider__thumb {
       box-shadow: rgb(0 0 0 / 22%) 0px 0px 4px 0px !important;
       border: 10px solid ${this.theme.ui_elements.scale.selector_color} !important;
       margin-top: 3px !important;
       height: ${this.theme.ui_elements.scale.selector_size}px !important;
       width: ${this.theme.ui_elements.scale.selector_size}px !important;
       background-color: #ffffff;
      }`
      this.customStyle += `#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .scale-slider .slider .ui-slider__thumb::before {
       background-color: #f9f9f900 !important;
      }`
      // check boxes
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .gdpr-terms .ui-checkbox--color-primary.is-checked .ui-checkbox__checkmark::before {' + 'background-color:' + this.theme.ui_elements.radio_checkbox.checked_color + '!important;' + 'border-color:' + this.theme.ui_elements.radio_checkbox.checked_color + '!important}'
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .gdpr-terms .ui-checkbox.is-checked .ui-checkbox__checkmark::after { border-color:' + this.theme.ui_elements.radio_checkbox.active_style.color + '!important}'
      // Font Family
      this.customStyle += '.radio-list li label.checkbox-label{' + 'font-family:' + this.theme.general.font.family + ';color:' + this.theme.general.colors.text_color + '}'
      // Input box
      this.customStyle += `
        #main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .activeone {
          border: 0;
          ${this.theme.typography.input_box.border.skin === 'all' ? 'border' : 'border-bottom'}: ${this.theme.typography.input_box.border.width}px ${this.theme.typography.input_box.border.style} ${this.theme.typography.input_box.border.color};
          box-shadow: none;
          color: ${this.theme.typography.input_box.font.color};
          text-indent: ${this.theme.typography.input_box.font.text_intent}px;
          font-family: ${this.theme.typography.input_box.font.family};
          font-size: ${this.theme.typography.input_box.font.font_size}px;
          background-color: ${this.theme.typography.input_box.font.background_color};
          border-radius: ${this.theme.typography.input_box.radius}px;
          height: ${this.theme.typography.input_box.font.height}px;
        }
        #main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .activeone:hover,
        #main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .activeone:focus {
          border: 0;
          ${this.theme.typography.input_box.border.skin === 'all' ? 'border' : 'border-bottom'}: ${this.theme.typography.input_box.border.width}px ${this.theme.typography.input_box.border.style} ${this.theme.general.colors.active_color};
        }
        `
      // GDPR Options
      this.customStyle += '.right-side .form-demo .ui-checkbox__label-text { font-family:' + this.theme.general.font.family + ';color:' + this.theme.general.colors.text_color + '}'
      // Question option text color
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .radio-list li label.checkbox-label { color:' + this.theme.general.colors.text_color + '}'
      // Margin
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .radio-list li { margin-bottom:' + this.theme.ui_elements.radio_checkbox.margin + 'px; }'
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .question2{ margin-top:' + this.theme.typography.input_box.font.spacing + 'px; }'
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .gdpr{ margin-top:' + this.theme.typography.input_box.font.spacing + 'px; }'
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .radio-list li:last-child { margin-bottom: 0px !important; }'
      // Border radius checkbox/radio
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .radio-list li label.checkbox-label { border-radius:' + this.theme.ui_elements.radio_checkbox.radius + 'px; }'
      // default style checkbox/radio
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .form-demo .radio-list li label.checkbox-label { border:' +
        this.theme.ui_elements.radio_checkbox.default_style.border.width + 'px' + ' ' + this.theme.ui_elements.radio_checkbox.default_style.border.style + this.theme.ui_elements.radio_checkbox.default_style.border.color + ';color:' + this.theme.ui_elements.radio_checkbox.default_style.color + ';background-color:' + this.theme.ui_elements.radio_checkbox.default_style.backgroundcolor + ';font-family:' +
        this.theme.general.font.family + ';font-size:' + this.theme.ui_elements.radio_checkbox.font.size + 'px;' + 'line-height:' + this.theme.ui_elements.radio_checkbox.font.line_height + 'px;' + '}'
      // hover border checkbox/radio
      if (this.theme.ui_elements.radio_checkbox.hover_style.border.width === '0' && this.theme.ui_elements.radio_checkbox.default_style.border.width) {
        this.customStyle += 'input[type="checkbox"]:hover + label,  input[type="radio"]:hover + label { border:' + this.theme.ui_elements.radio_checkbox.default_style.border.width + 'px' + ' ' + this.theme.ui_elements.radio_checkbox.hover_style.border.style + ' ' + 'transparent' + '!important; color:' + this.theme.ui_elements.radio_checkbox.hover_style.color + '!important;background-color:' + this.theme.ui_elements.radio_checkbox.hover_color + '!important;font-family:' + this.theme.general.font.family + '}'
      } else {
        this.customStyle += 'input[type="checkbox"]:hover + label,  input[type="radio"]:hover + label { border:' + this.theme.ui_elements.radio_checkbox.hover_style.border.width + 'px' + ' ' + this.theme.ui_elements.radio_checkbox.hover_style.border.style + ' ' + this.theme.ui_elements.radio_checkbox.hover_style.border.color + '!important; color:' + this.theme.ui_elements.radio_checkbox.hover_style.color + '!important;background-color:' + this.theme.ui_elements.radio_checkbox.hover_color + '!important;font-family:' +
          this.theme.general.font.family + '}'
      }
      // CHECKED check boxes
      this.customStyle += 'input[type="radio"]:checked + label, input[type="checkbox"]:checked + label { border:' + this.theme.ui_elements.radio_checkbox.active_style.border.width + 'px' + ' ' + this.theme.ui_elements.radio_checkbox.active_style.border.style + ' ' + this.theme.ui_elements.radio_checkbox.active_style.border.color + '!important; color:' + this.theme.ui_elements.radio_checkbox.active_style.color + '!important;background-color:' + this.theme.ui_elements.radio_checkbox.checked_color + '!important;font-family:' +
        this.theme.general.font.family + '}'
      let temp = 'center;'
      if (this.theme.ui_elements.step_navigation.back_button.alignment === 'left') {
        temp = 'flex-start;'
      } else if (this.theme.ui_elements.step_navigation.back_button.alignment === 'right') {
        temp = 'flex-end;'
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .back-button-wrapper { justify-content:' + temp + ';width:' + this.theme.ui_elements.step_navigation.back_button.width + '%' + '}'
      temp = 'center;'
      if (this.theme.ui_elements.step_navigation.next_button.alignment === 'left') {
        temp = 'flex-start;'
      } else if (this.theme.ui_elements.step_navigation.next_button.alignment === 'right') {
        temp = 'flex-end;'
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .continue-button-wrapper { justify-content:' + temp + ';width:' + this.theme.ui_elements.step_navigation.next_button.width + '%' + '}'
      temp = 'center;'
      if (this.theme.ui_elements.step_navigation.submit_button.alignment === 'left') {
        temp = 'flex-start;'
      } else if (this.theme.ui_elements.step_navigation.submit_button.alignment === 'right') {
        temp = 'flex-end;'
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .submit-button-wrapper { justify-content:' + temp + ';width:' + this.theme.ui_elements.step_navigation.submit_button.width + '%' + '}'
      if (this.theme.ui_elements.step_navigation.back_button.hide) {
        this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .back-button-wrapper { width:0% }'
        this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .continue-button-wrapper { width:100% }'
        this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .submit-button-wrapper { width:100% }'
      } else {
        this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .back-button-wrapper { width: 48% }'
        this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .continue-button-wrapper { width: 48% }'
        this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .submit-button-wrapper { width: 48% }'
      }
      // back hover style
      let backgroundcolor = this.uiElements.step_navigation.back_button.backgroundColor
      if (this.uiElements.step_navigation.back_button.hover_style.backgroundColor) {
        backgroundcolor = this.uiElements.step_navigation.back_button.hover_style.backgroundColor
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .back-button-wrapper>div>.ui-button:hover{ color:' + this.uiElements.step_navigation.back_button.hover_style.color + '!important; background-color:' + backgroundcolor + '!important;border:' + this.uiElements.step_navigation.back_button.hover_style.border.width + 'px ' + this.uiElements.step_navigation.back_button.hover_style.border.style + ' ' + this.uiElements.step_navigation.back_button.hover_style.border.color + '!important}'
      // back active style
      backgroundcolor = this.uiElements.step_navigation.back_button.backgroundColor
      if (this.uiElements.step_navigation.back_button.active_style.backgroundColor) {
        backgroundcolor = this.uiElements.step_navigation.back_button.active_style.backgroundColor
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .back-button-wrapper>div>.ui-button:active{ color:' + this.uiElements.step_navigation.back_button.active_style.color + '!important; background-color:' + backgroundcolor + '!important; border:' + this.uiElements.step_navigation.back_button.active_style.border.width + 'px ' + this.uiElements.step_navigation.back_button.active_style.border.style + ' ' + this.uiElements.step_navigation.back_button.active_style.border.color + '!important}'
      // next  hover style
      backgroundcolor = this.uiElements.step_navigation.next_button.backgroundColor
      if (this.uiElements.step_navigation.next_button.hover_style.backgroundColor) {
        backgroundcolor = this.uiElements.step_navigation.next_button.hover_style.backgroundColor
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .continue-button-wrapper>div>.ui-button:hover{ color:' + this.uiElements.step_navigation.next_button.hover_style.color + '!important; background-color:' + backgroundcolor + '!important;border:' + this.uiElements.step_navigation.next_button.hover_style.border.width + 'px ' + this.uiElements.step_navigation.next_button.hover_style.border.style + ' ' + this.uiElements.step_navigation.next_button.hover_style.border.color + '!important}'
      // next active style
      backgroundcolor = this.uiElements.step_navigation.next_button.backgroundColor
      if (this.uiElements.step_navigation.next_button.active_style.backgroundColor) {
        backgroundcolor = this.uiElements.step_navigation.next_button.active_style.backgroundColor
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .continue-button-wrapper>div>.ui-button:active{ color:' + this.uiElements.step_navigation.next_button.active_style.color + '!important; background-color:' + backgroundcolor + '!important;border:' + this.uiElements.step_navigation.next_button.active_style.border.width + 'px ' + this.uiElements.step_navigation.next_button.active_style.border.style + ' ' + this.uiElements.step_navigation.next_button.active_style.border.color + '!important}'
      // submit  hover style
      backgroundcolor = this.uiElements.step_navigation.submit_button.backgroundColor
      if (this.uiElements.step_navigation.submit_button.hover_style.backgroundColor) {
        backgroundcolor = this.uiElements.step_navigation.submit_button.hover_style.backgroundColor
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .submit-button-wrapper>div>.ui-button:hover{ color:' + this.uiElements.step_navigation.submit_button.hover_style.color + '!important; background-color:' + backgroundcolor + '!important;border:' + this.uiElements.step_navigation.submit_button.hover_style.border.width + 'px ' + this.uiElements.step_navigation.submit_button.hover_style.border.style + ' ' + this.uiElements.step_navigation.submit_button.hover_style.border.color + '!important}'
      // submit  active style
      backgroundcolor = this.uiElements.step_navigation.submit_button.backgroundColor
      if (this.uiElements.step_navigation.submit_button.active_style.backgroundColor) {
        backgroundcolor = this.uiElements.step_navigation.submit_button.active_style.backgroundColor
      }
      this.customStyle += '#main-wrapper-form-variant-theme .main-container .theme-setting-both-sides .right-side .both-buttons .submit-button-wrapper>div>.ui-button:active{ color:' + this.uiElements.step_navigation.submit_button.active_style.color + '!important; background-color:' + backgroundcolor + '!important;border:' + this.uiElements.step_navigation.submit_button.active_style.border.width + 'px ' + this.uiElements.step_navigation.submit_button.active_style.border.style + ' ' + this.uiElements.step_navigation.submit_button.active_style.border.color + '!important}'
    },
    addStyle: function () {
      this.choiceStyle()
      this.googleFontFamily()
      var x = document.createElement('STYLE')
      x.id = 'formbuilder-designer-styles'
      var t = document.createTextNode(this.customStyle)
      x.appendChild(t)
      if (document.querySelector('#formbuilder-designer-styles')) {
        document.getElementById('formbuilder-designer-styles').remove()
      }
      document.head.appendChild(x)
    },
    googleFontFamily: function () {
      for (const googleFontKey in googleFonts) {
        const googleFontValue = googleFonts[googleFontKey]
        let insert = false

        if (
          googleFontValue.label === this.theme.general.font.family ||
          googleFontValue.label === this.theme.typography.question_description.font.family ||
          googleFontValue.label === this.theme.typography.question_title.font.family ||
          googleFontValue.label === this.theme.ui_elements.step_navigation.back_button.font.family ||
          googleFontValue.label === this.theme.ui_elements.step_navigation.next_button.font.family ||
          googleFontValue.label === this.theme.ui_elements.step_navigation.submit_button.font.family ||
          googleFontValue.label === this.theme.typography.input_box.font.family ||
          googleFontValue.label === this.theme.typography.text_box.family
        ) {
          insert = true
        }

        for (let theme of this.themes) {
          if (
            googleFontValue.label === theme.config.general.font.family ||
            googleFontValue.label === theme.config.ui_elements.step_navigation.back_button.font.family ||
            googleFontValue.label === theme.config.ui_elements.step_navigation.next_button.font.family ||
            googleFontValue.label === theme.config.ui_elements.step_navigation.submit_button.font.family
          ) {
            insert = true
          }
        }

        if (insert && this.fontsLoaded.indexOf(googleFontValue.link) === -1) {
          this.fontsLoaded.push(googleFontValue.link)

          var link = document.createElement('link')
          link.href = googleFontValue.link
          link.rel = 'stylesheet'
          document.head.appendChild(link)
        }
      }
    },
    hideAllChromePickers: function () {
      for (let i = 0; i < this.chromePickersCount; i++) {
        this.chromePickers[i].visible = false
      }
    },
    hexColorCodeInput: function (e) {
      if (e.slice(0, 1) === '#') {
        this.colors = {
          hex: e
        }
      } else if (e.slice(0, 4) === 'rgba') {
        var rgba = e.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
        var hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)
        this.colors = {
          hex: hex,
          a: rgba[3]
        }
      }
    },
    getHexColorValue: function (e) {
      let value = e.hex
      if (e.a < 1) {
        value = `rgba(${e.rgba.r}, ${e.rgba.g}, ${e.rgba.b}, ${e.rgba.a})`
      }
      if (value === undefined) {
        this.hexColorCodeInput(e)
        return e
      }
      return value
    },
    showChromePicker: function (id) {
      if (id >= 2) {
        this.flag = 1
        this.hideAllChromePickers()
        for (let i = 0; i < this.chromePickersCount; i++) {
          if (this.chromePickers[i].id !== id) {
            continue
          }
          this.chromePickers[i].visible = true
        }
        return
      } else if (id === 1 && this.flag === 0) {
        this.hideAllChromePickers()
        this.flag = 0
        return
      }
      this.flag = 0
    },
    updateGeneralSetting: function (e, choice) {
      let general = Object.assign({}, this.general)
      let inheritFont = 'inherit'
      let defaultFont = 'Raleway'
      switch (choice) {
        case 'generalFamily':
          if (this.typography.question_description.font.family === general.font.family) {
            this.updateTypographySetting(e, 'descriptionFamily')
          }
          if (this.typography.question_title.font.family === general.font.family) {
            this.updateTypographySetting(e, 'titleFamily')
          }
          if (this.typography.input_box.font.family === general.font.family) {
            this.updateTypographySetting(e, 'inputBoxFamily')
          }
          if (this.uiElements.step_navigation.next_button.font.family === general.font.family) {
            this.updateUiElementsSetting(e, 'uiStepNavNextFamily')
          }
          if (this.uiElements.step_navigation.back_button.font.family === general.font.family) {
            this.updateUiElementsSetting(e, 'uiStepNavBackFamily')
          }
          if (this.uiElements.step_navigation.submit_button.font.family === general.font.family) {
            this.updateUiElementsSetting(e, 'uiStepNavSubmitFamily')
          }
          if (this.typography.text_box.family === general.font.family) {
            this.updateTypographySetting(e, 'textBoxFamily')
          }
          if (this.uiElements.choice.image_icon_skin.title_font.family === general.font.family) {
            this.updateUiElementsSetting(e, 'imageIconDefaultTitleFontFamily')
          }
          if (this.uiElements.choice.image_icon_skin.description_font.family === general.font.family) {
            this.updateUiElementsSetting(e, 'imageIconDefaultDescriptionFontFamily')
          }
          general.font.family = e
          break
        case 'generalActiveColor':
          if (this.uiElements.step_navigation.next_button.backgroundColor === general.colors.active_color) {
            this.updateUiElementsSetting(e, 'uiStepNavNextBackgroundColor')
          }
          if (this.uiElements.scale.config.fill_color === general.colors.active_color) {
            this.updateUiElementsSetting(e, 'scaleUiFillColor')
          }
          if (this.uiElements.scale.border_color === general.colors.active_color) {
            this.updateUiElementsSetting(e, 'scaleBorderColor')
          }
          if (this.uiElements.step_navigation.submit_button.backgroundColor === general.colors.active_color) {
            this.updateUiElementsSetting(e, 'uiStepNavSubmitBackgroundColor')
          }
          if (this.uiElements.radio_checkbox.checked_color === general.colors.active_color) {
            this.updateUiElementsSetting(e, 'uiCheckboxCheckedColor')
          }
          if (this.typography.input_box.font.color === general.colors.active_color) {
            this.updateTypographySetting(e, 'inputBoxColor')
          }
          if (this.typography.input_box.placeholder.color === general.colors.active_color) {
            this.updateTypographySetting(e, 'inputBoxPlaceholderColor')
          }
          if (this.uiElements.radio_checkbox.hover_style.border.color === general.colors.active_color) {
            this.updateUiElementsSetting(e, 'uiCheckboxHoverBorderColor')
          }
          if (this.uiElements.radio_checkbox.hover_color === general.colors.active_color) {
            this.updateUiElementsSetting(e, 'uiCheckboxHoverBackgroundColor')
          }
          if (this.uiElements.radio_checkbox.active_style.border.color === general.colors.active_color) {
            this.updateUiElementsSetting(e, 'uiCheckboxActiveBorderColor')
          }
          if (this.uiElements.radio_checkbox.checked_color === general.colors.active_color) {
            this.updateUiElementsSetting(e, 'uiCheckboxActiveBackgroundColor')
          }
          general.colors.active_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'generalTextColor':
          if (this.typography.question_title.font.color === general.colors.text_color) {
            this.updateTypographySetting(e, 'titleColor')
          }
          if (this.typography.question_description.font.color === general.colors.text_color) {
            this.updateTypographySetting(e, 'descriptionColor')
          }

          general.colors.text_color = this.getHexColorValue(e)

          this.flag = 1
          break
        case 'generalWarningColor':
          general.colors.warning_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'generalDynamicHeight':
          general.dynamic_height = !e
          this.flag = 1
          break
        case 'generalDynamicFadein':
          general.dynamic_fadein = e
          this.flag = 1
          break
        case 'inheritFont':
          if (!general.inherit_toggle) {
            general.font.family = inheritFont
            this.updateTypographySetting(inheritFont, 'titleFamily')
            this.updateTypographySetting(inheritFont, 'descriptionFamily')
            this.updateTypographySetting(inheritFont, 'inputBoxFamily')
            this.updateUiElementsSetting(inheritFont, 'uiStepNavNextFamily')
            this.updateUiElementsSetting(inheritFont, 'uiStepNavBackFamily')
            this.updateUiElementsSetting(inheritFont, 'uiStepNavSubmitFamily')
            this.updateTypographySetting(inheritFont, 'textBoxFamily')
            this.updateUiElementsSetting(inheritFont, 'imageIconDefaultTitleFontFamily')
            this.updateUiElementsSetting(inheritFont, 'imageIconDefaultDescriptionFontFamily')
          } else {
            general.font.family = defaultFont
            this.updateTypographySetting(defaultFont, 'titleFamily')
            this.updateTypographySetting(defaultFont, 'descriptionFamily')
            this.updateTypographySetting(defaultFont, 'inputBoxFamily')
            this.updateUiElementsSetting(defaultFont, 'uiStepNavNextFamily')
            this.updateUiElementsSetting(defaultFont, 'uiStepNavBackFamily')
            this.updateUiElementsSetting(defaultFont, 'uiStepNavSubmitFamily')
            this.updateTypographySetting(defaultFont, 'textBoxFamily')
            this.updateUiElementsSetting(defaultFont, 'imageIconDefaultTitleFontFamily')
            this.updateUiElementsSetting(defaultFont, 'imageIconDefaultDescriptionFontFamily')
          }
          general.inherit_toggle = e
      }
      this.$store.dispatch(actionTypes.UPDATE_FORM_THEME_GENERAL, general)
      this.addStyle()
    },
    updateTypographySetting: function (e, choice) {
      let typography = Object.assign({}, this.typography)
      switch (choice) {
        case 'titleAlignment':
          typography.question_title.text_align = e
          break
        case 'descripitionAlignment':
          typography.question_description.text_align = e
          break
        case 'inputAlignment':
          typography.input_box.text_align = e
          break
        case 'titleFamily':
          typography.question_title.font.family = e
          break
        case 'titleLineHeight':
          typography.question_title.font.line_height = e
          break
        case 'titleFontSize':
          typography.question_title.font.size = e
          break
        case 'titleFontWeight':
          typography.question_title.font.weight = e
          break
        case 'titleColor':
          if (this.uiElements.scale.selector_color === typography.question_title.font.color) {
            this.updateUiElementsSetting(e, 'scaleSelectorColor')
          }
          typography.question_title.font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'descriptionFamily':
          typography.question_description.font.family = e
          break
        case 'descriptionLineHeight':
          typography.question_description.font.line_height = e
          break
        case 'descriptionFontSize':
          typography.question_description.font.size = e
          break
        case 'descriptionWeight':
          typography.question_description.font.weight = e
          break
        case 'descriptionColor':
          typography.question_description.font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'inputBoxBorderSkin':
          typography.input_box.border.skin = e
          break
        case 'inputBoxBorderStyle':
          typography.input_box.border.style = e
          break
        case 'inputBoxBorderWidth':
          typography.input_box.border.width = e
          break
        case 'inputBoxBorderColor':
          typography.input_box.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'inputBoxHeight':
          typography.input_box.font.height = e
          break
        case 'inputBoxSpacing':
          typography.input_box.font.spacing = e
          break
        case 'inputBoxTextIntent':
          typography.input_box.font.text_intent = e
          break
        case 'inputBoxFamily':
          typography.input_box.font.family = e
          break
        case 'inputBoxBackground':
          typography.input_box.font.background_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'inputBoxColor':
          typography.input_box.font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'inputBoxPlaceholderColor':
          typography.input_box.placeholder.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'inputBoxFont':
          typography.input_box.font.font_size = e
          break
        case 'inputBoxRadius':
          typography.input_box.radius = e
          break
        case 'textBoxColor':
          typography.text_box.color = this.getHexColorValue(e)
          break
        case 'textBoxFamily':
          typography.text_box.family = e
          break
      }
      this.$store.dispatch(actionTypes.UPDATE_FORM_THEME_TYPOGRAPHY, typography)
      this.addStyle()
    },
    updateUiElementsSetting: function (e, choice) {
      let uiElements = JSON.parse(JSON.stringify(this.uiElements))
      switch (choice) {
        case 'uiQuesAsterisk':
          uiElements.question.asterisk_for_required = e
          break
        case 'uiQuesLabel':
          uiElements.question.hide_question_labels = e
          break
        case 'uiBackground':
          uiElements.background.color = this.getHexColorValue(e)

          this.flag = 1
          break
        case 'uiBackgroundForm':
          uiElements.background.formColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'formShadow':
          uiElements.background.formShadow = e
          break
        case 'uiStrokeColor':
          uiElements.step_progress.config.stroke_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiFillColor':
          uiElements.step_progress.config.fill_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'showProgress':
          uiElements.step_progress.showProgress = e
          break
        case 'showAnimation':
          uiElements.step_progress.showAnimation = e
          break
        case 'progressPosition':
          uiElements.step_progress.progressPosition = e
          break
        case 'formBorderWidth':
          uiElements.background.form_border_width = e
          break
        case 'formBorderStyle':
          uiElements.background.form_border_style = e
          break
        case 'formBorderColor':
          uiElements.background.form_border_color = this.getHexColorValue(e)
          break
        case 'formBorderRadius':
          uiElements.background.form_border_radius = e
          break
        // form padding
        case 'formPaddingRight':
          uiElements.background.form_padding_right = e
          break
        case 'formPaddingLeft':
          uiElements.background.form_padding_left = e
          break
        case 'formPaddingBottom':
          uiElements.background.form_padding_bottom = e
          break
        case 'formPaddingTop':
          uiElements.background.form_padding_top = e
          break
        // back button
        case 'uiStepNavBackText':
          uiElements.step_navigation.back_button.text = e
          break
        case 'uiStepNavBackFamily':
          uiElements.step_navigation.back_button.font.family = e
          break
        case 'uiStepNavBackWeight':
          uiElements.step_navigation.back_button.font.weight = e
          break
        case 'uiStepNavBackSize':
          uiElements.step_navigation.back_button.font.size = e
          break
        case 'uiStepNavBackLineHeight':
          uiElements.step_navigation.back_button.font.line_height = e
          break
        case 'uiStepNavBackRounded':
          uiElements.step_navigation.back_button.rounded = e
          break
        case 'uiStepNavBackShadow':
          uiElements.step_navigation.back_button.shadow = e
          break
        case 'uiStepNavBackColor':
          uiElements.step_navigation.back_button.font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavBackBackgroundColor':
          uiElements.step_navigation.back_button.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavBackIconUrl':
          uiElements.step_navigation.back_button.icon = e
          break
        case 'uiStepNavBackWidth':
          uiElements.step_navigation.back_button.width = e
          break
        case 'uiStepNavBackAlignment':
          uiElements.step_navigation.back_button.alignment = e
          break
        case 'uiImage':
          uiElements.background.image = e
          break
        case 'uiStepNavBackRadius':
          uiElements.step_navigation.back_button.borderRadius = e
          break
        case 'uiStepNavBackHide':
          uiElements.step_navigation.back_button.hide = e
          break
        // back default
        case 'backBorderStyle':
          uiElements.step_navigation.back_button.default_style.border.style = e
          break
        case 'backBorderWidth':
          uiElements.step_navigation.back_button.default_style.border.width = e
          break
        case 'backBorderColor':
          uiElements.step_navigation.back_button.default_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavBackHeight':
          uiElements.step_navigation.back_button.font.height = e
          break
        case 'uiStepNavNextVisible':
          uiElements.step_navigation.next_button.hidden = !e
          break
        // back hover
        case 'backBorderStyleHover':
          uiElements.step_navigation.back_button.hover_style.border.style = e
          break
        case 'backBorderWidthHover':
          uiElements.step_navigation.back_button.hover_style.border.width = e
          break
        case 'backBorderColorHover':
          uiElements.step_navigation.back_button.hover_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavBackColorHover':
          uiElements.step_navigation.back_button.hover_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavBackBgColorHover':
          uiElements.step_navigation.back_button.hover_style.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'backBorderStyleActive':
          uiElements.step_navigation.back_button.active_style.border.style = e
          break
        case 'backBorderWidthActive':
          uiElements.step_navigation.back_button.active_style.border.width = e
          break
        case 'backBorderColorActive':
          uiElements.step_navigation.back_button.active_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavBackColorActive':
          uiElements.step_navigation.back_button.active_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavBackBgColorActive':
          uiElements.step_navigation.back_button.active_style.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextText':
          uiElements.step_navigation.next_button.text = e
          break
        case 'uiStepNavNextFamily':
          uiElements.step_navigation.next_button.font.family = e
          break
        case 'uiStepNavNextWeight':
          uiElements.step_navigation.next_button.font.weight = e
          break
        case 'uiStepNavNextSize':
          uiElements.step_navigation.next_button.font.size = e
          break
        case 'uiStepNavNextLineHeight':
          uiElements.step_navigation.next_button.font.line_height = e
          break
        case 'uiStepNavNextRounded':
          uiElements.step_navigation.next_button.rounded = e
          break
        case 'uiStepNavNextShadow':
          uiElements.step_navigation.next_button.shadow = e
          break
        case 'uiStepNavNextColor':
          uiElements.step_navigation.next_button.font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextBackgroundColor':
          uiElements.step_navigation.next_button.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextIconUrl':
          uiElements.step_navigation.next_button.icon = e
          break
        case 'uiStepNavNextIconPosition':
          uiElements.step_navigation.next_button.iconPosition = e
          break
        case 'uiStepNavNextRadius':
          uiElements.step_navigation.next_button.borderRadius = e
          break
        case 'uiStepNavNextWidth':
          uiElements.step_navigation.next_button.width = e
          break
        case 'uiStepNavNextAlignment':
          uiElements.step_navigation.next_button.alignment = e
          break
        // next default
        case 'nextBorderStyle':
          uiElements.step_navigation.next_button.default_style.border.style = e
          break
        case 'nextBorderWidth':
          uiElements.step_navigation.next_button.default_style.border.width = e
          break
        case 'nextBorderColor':
          uiElements.step_navigation.next_button.default_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextHeight':
          uiElements.step_navigation.next_button.font.height = e
          break
        // next hover
        case 'nextBorderStyleHover':
          uiElements.step_navigation.next_button.hover_style.border.style = e
          break
        case 'nextBorderWidthHover':
          uiElements.step_navigation.next_button.hover_style.border.width = e
          break
        case 'nextBorderColorHover':
          uiElements.step_navigation.next_button.hover_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextColorHover':
          uiElements.step_navigation.next_button.hover_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextBgColorHover':
          uiElements.step_navigation.next_button.hover_style.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'nextBorderStyleActive':
          uiElements.step_navigation.next_button.active_style.border.style = e
          break
        case 'nextBorderWidthActive':
          uiElements.step_navigation.next_button.active_style.border.width = e
          break
        case 'nextBorderColorActive':
          uiElements.step_navigation.next_button.active_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextColorActive':
          uiElements.step_navigation.next_button.active_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavNextBgColorActive':
          uiElements.step_navigation.next_button.active_style.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitText':
          uiElements.step_navigation.submit_button.text = e
          break
        case 'uiStepNavSubmitFamily':
          uiElements.step_navigation.submit_button.font.family = e
          break
        case 'uiStepNavSubmitWeight':
          uiElements.step_navigation.submit_button.font.weight = e
          break
        case 'uiStepNavSubmitSize':
          uiElements.step_navigation.submit_button.font.size = e
          break
        case 'uiStepNavSubmitLineHeight':
          uiElements.step_navigation.submit_button.font.line_height = e
          break
        case 'uiStepNavSubmitRounded':
          uiElements.step_navigation.submit_button.rounded = e
          break
        case 'uiStepNavSubmitShadow':
          uiElements.step_navigation.submit_button.shadow = e
          break
        case 'uiStepNavSubmitColor':
          uiElements.step_navigation.submit_button.font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitBackgroundColor':
          uiElements.step_navigation.submit_button.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitIconUrl':
          uiElements.step_navigation.submit_button.icon = e
          break
        case 'uiStepNavSubmitIconPosition':
          uiElements.step_navigation.submit_button.iconPosition = e
          break
        case 'uiStepNavSubmitRadius':
          uiElements.step_navigation.submit_button.borderRadius = e
          break
        case 'uiStepNavSubmitWidth':
          uiElements.step_navigation.submit_button.width = e
          break
        case 'uiStepNavSubmitAlignment':
          uiElements.step_navigation.submit_button.alignment = e
          break
        // submit default
        case 'submitBorderStyle':
          uiElements.step_navigation.submit_button.default_style.border.style = e
          break
        case 'submitBorderWidth':
          uiElements.step_navigation.submit_button.default_style.border.width = e
          break
        case 'submitBorderColor':
          uiElements.step_navigation.submit_button.default_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitHeight':
          uiElements.step_navigation.submit_button.font.height = e
          break
        // submit hover
        case 'submitBorderStyleHover':
          uiElements.step_navigation.submit_button.hover_style.border.style = e
          break
        case 'submitBorderWidthHover':
          uiElements.step_navigation.submit_button.hover_style.border.width = e
          break
        case 'submitBorderColorHover':
          uiElements.step_navigation.submit_button.hover_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitColorHover':
          uiElements.step_navigation.submit_button.hover_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitBgColorHover':
          uiElements.step_navigation.submit_button.hover_style.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'submitBorderStyleActive':
          uiElements.step_navigation.submit_button.active_style.border.style = e
          break
        case 'submitBorderWidthActive':
          uiElements.step_navigation.submit_button.active_style.border.width = e
          break
        case 'submitBorderColorActive':
          uiElements.step_navigation.submit_button.active_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitColorActive':
          uiElements.step_navigation.submit_button.active_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiStepNavSubmitBgColorActive':
          uiElements.step_navigation.submit_button.active_style.backgroundColor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxMargin':
          uiElements.radio_checkbox.margin = e
          break
        case 'uiCheckboxLineHeight':
          uiElements.radio_checkbox.font.line_height = e
          break
        case 'uiCheckboxRadius':
          uiElements.radio_checkbox.radius = e
          break
        case 'uiCheckboxDefaultBorderStyle':
          uiElements.radio_checkbox.default_style.border.style = e
          break
        case 'uiCheckboxDefaultFontSize':
          uiElements.radio_checkbox.font.size = e
          break
        case 'uiCheckboxDefaultBorderColor':
          uiElements.radio_checkbox.default_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxDefaultBorderWidth':
          uiElements.radio_checkbox.default_style.border.width = e
          break
        case 'uiCheckboxDefaultTextColor':
          uiElements.radio_checkbox.default_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxDefaultDropdownColor':
          uiElements.choice.dropdown_skin.default_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxDefaultBackgroundColor':
          uiElements.radio_checkbox.default_style.backgroundcolor = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxHoverBorderStyle':
          uiElements.radio_checkbox.hover_style.border.style = e
          break
        case 'uiCheckboxHoverBorderColor':
          uiElements.radio_checkbox.hover_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxHoverBorderWidth':
          uiElements.radio_checkbox.hover_style.border.width = e
          break
        case 'uiCheckboxHoverTextColor':
          uiElements.radio_checkbox.hover_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxHoverBackgroundColor':
          uiElements.radio_checkbox.hover_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxActiveBorderStyle':
          uiElements.radio_checkbox.active_style.border.style = e
          break
        case 'uiCheckboxActiveBorderColor':
          uiElements.radio_checkbox.active_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxActiveBorderWidth':
          uiElements.radio_checkbox.active_style.border.width = e
          break
        case 'uiCheckboxActiveTextColor':
          uiElements.radio_checkbox.active_style.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'uiCheckboxActiveBackgroundColor':
          uiElements.radio_checkbox.checked_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconDefaultBorderStyle':
          uiElements.choice.image_icon_skin.border.style = e
          break
        case 'imageIconDefaultBorderColor':
          uiElements.choice.image_icon_skin.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconDefaultBorderWidth':
          uiElements.choice.image_icon_skin.border.width = e
          break
        case 'imageIconPaddingTop':
          uiElements.choice.image_icon_skin.padding.top = e
          break
        case 'imageIconPaddingRight':
          uiElements.choice.image_icon_skin.padding.right = e
          break
        case 'imageIconPaddingLeft':
          uiElements.choice.image_icon_skin.padding.left = e
          break
        case 'imageIconPaddingBottom':
          uiElements.choice.image_icon_skin.padding.bottom = e
          break
        case 'imageIconDefaultTitleFontSize':
          uiElements.choice.image_icon_skin.title_font.size = e
          break
        case 'imageIconDefaultTitleFontWeight':
          uiElements.choice.image_icon_skin.title_font.weight = e
          break
        case 'imageIconDefaultTitleFontFamily':
          uiElements.choice.image_icon_skin.title_font.family = e
          break
        case 'imageIconDefaultTitleFontLineHeight':
          uiElements.choice.image_icon_skin.title_font.line_height = e
          break
        case 'imageIconDefaultTitleFontHeight':
          uiElements.choice.image_icon_skin.title_font.height = e
          break
        case 'imageIconDefaultTitleFontColor':
          uiElements.choice.image_icon_skin.title_font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconDefaultDescriptionFontSize':
          uiElements.choice.image_icon_skin.description_font.size = e
          break
        case 'imageIconDefaultDescriptionFontWeight':
          uiElements.choice.image_icon_skin.description_font.weight = e
          break
        case 'imageIconDefaultDescriptionFontFamily':
          uiElements.choice.image_icon_skin.description_font.family = e
          break
        case 'imageIconDefaultDescriptionFontLineHeight':
          uiElements.choice.image_icon_skin.description_font.line_height = e
          break
        case 'imageIconDefaultDescriptionFontHeight':
          uiElements.choice.image_icon_skin.description_font.height = e
          break
        case 'imageIconDefaultDescriptionFontColor':
          uiElements.choice.image_icon_skin.description_font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconDefaultWidth':
          uiElements.choice.image_icon_skin.width = e
          break
        case 'imageIconDefaultHeight':
          uiElements.choice.image_icon_skin.height = e
          break
        case 'imageIconDefaultShadow':
          uiElements.choice.image_icon_skin.shadow = e
          break
        case 'imageIconDefaultBackgroundColor':
          uiElements.choice.image_icon_skin.background_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconDefaultIconSize':
          uiElements.choice.image_icon_skin.icon_size = e
          break
        case 'imageIconDefaultIconColor':
          uiElements.choice.image_icon_skin.icon_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconDefaultImageWidth':
          uiElements.choice.image_icon_skin.image_width = e
          break
        case 'imageIconBorderRadius':
          uiElements.choice.image_icon_skin.border.radius = e
          break
        case 'imageIconDefaultTickboxColor':
          uiElements.choice.image_icon_skin.tickbox.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconHoverBorderStyle':
          uiElements.choice.image_icon_skin.hover_style.border.style = e
          break
        case 'imageIconHoverBorderColor':
          uiElements.choice.image_icon_skin.hover_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconHoverBorderWidth':
          uiElements.choice.image_icon_skin.hover_style.border.width = e
          break
        case 'imageIconHoverBgColor':
          uiElements.choice.image_icon_skin.hover_style.background_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconHoverTitleColor':
          uiElements.choice.image_icon_skin.hover_style.title_font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconHoverDesColor':
          uiElements.choice.image_icon_skin.hover_style.description_font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconHoverIconColor':
          uiElements.choice.image_icon_skin.hover_style.icon_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconHoverShadow':
          uiElements.choice.image_icon_skin.hover_style.shadow = e
          break
        // Image Icon Active style
        case 'imageIconActiveBorderStyle':
          uiElements.choice.image_icon_skin.active_style.border.style = e
          break
        case 'imageIconActiveBorderColor':
          uiElements.choice.image_icon_skin.active_style.border.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconActiveBorderWidth':
          uiElements.choice.image_icon_skin.active_style.border.width = e
          break
        case 'imageIconActiveBgColor':
          uiElements.choice.image_icon_skin.active_style.background_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconActiveTitleColor':
          uiElements.choice.image_icon_skin.active_style.title_font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconActiveDesColor':
          uiElements.choice.image_icon_skin.active_style.description_font.color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconActiveIconColor':
          uiElements.choice.image_icon_skin.active_style.icon_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'imageIconActiveShadow':
          uiElements.choice.image_icon_skin.active_style.shadow = e
          break
        case 'scaleUiFillColor':
          uiElements.scale.config.fill_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleUiStrokeColor':
          uiElements.scale.config.stroke_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleBorderColor':
          uiElements.scale.border_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleSelectorColor':
          uiElements.scale.selector_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleSmileysColor':
          uiElements.scale.smileys_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleSmileysTextColor':
          uiElements.scale.smileys_text_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleSmileysHoverColor':
          uiElements.scale.hover_style.smileys_hover_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleSmileysActiveColor':
          uiElements.scale.active_style.smileys_active_color = this.getHexColorValue(e)
          this.flag = 1
          break
        case 'scaleSmileysActiveTextColor':
          uiElements.scale.active_style.smileys_active_text_color = this.getHexColorValue(e)
          this.flag = 1
          break
      }
      this.$store.dispatch(actionTypes.UPDATE_FORM_THEME_UI_ELEMENTS, uiElements)
      this.addStyle()
    },
    typographySelectedTab: function (value) {
      if (value === 'Question title') {
        document.getElementById('question_title').style.display = 'block'
        document.getElementById('question_description').style.display = 'none'
      } else {
        document.getElementById('question_title').style.display = 'none'
        document.getElementById('question_description').style.display = 'block'
      }
    }
  },
  computed: {
    ...mapGetters([
      'defaultFormVariantTheme'
    ]),
    themes: function () {
      return this.$store.state.formThemeTemplates[0] || []
    },
    theme: function () {
      let themes = []
      if (this.$route.params.id && this.$route.params.variantId) {
        themes = this.$store.state.formVariantThemes
          .filter(t => t.form_variant_id === parseInt(this.$route.params.variantId))
      } else {
        themes = this.$store.state.formVariantThemes.filter(t => t.id === -1)
        if (themes.length === 0) {
          return this.defaultFormVariantTheme
        }
      }
      //return deepmerge(this.defaultFormVariantTheme, themes[0]) || {}
      return { ...this.defaultFormVariantTheme, ...themes[0] }
    },
    typography: function () {
      return this.theme.typography
    },
    general: function () {
      return this.theme.general
    },
    uiElements: function () {
      if (!this.theme.ui_elements.step_navigation.submit_button.icon) {
        this.theme.ui_elements.step_navigation.submit_button.icon = ' '
      }
      if (!this.theme.ui_elements.step_navigation.back_button.icon) {
        this.theme.ui_elements.step_navigation.back_button.icon = ' '
      }
      if (!this.theme.ui_elements.step_navigation.next_button.icon) {
        this.theme.ui_elements.step_navigation.next_button.icon = ' '
      }
      return this.theme.ui_elements
    },
    allIcons: function () {
      let materialIcons = icons.map((icon) => {
        return {
          label: icon,
          value: icon
        }
      })
      materialIcons.unshift({ label: 'Select', value: '' })
      return materialIcons
    },
    backButtonStyle: function () {
      return {
        backgroundColor: this.uiElements.step_navigation.back_button.backgroundColor,
        color: this.uiElements.step_navigation.back_button.font.color,
        fontFamily: this.uiElements.step_navigation.back_button.font.family,
        fontSize: this.uiElements.step_navigation.back_button.font.size + 'px',
        fontWeight: this.uiElements.step_navigation.back_button.font.weight,
        height: this.uiElements.step_navigation.back_button.font.height + 'px',
        width: '100%',
        padding: '0px',
        borderRadius: this.uiElements.step_navigation.back_button.borderRadius + 'px !important',
        border: this.uiElements.step_navigation.back_button.default_style.border.width + 'px' + ' ' + this.uiElements.step_navigation.back_button.default_style.border.style + ' ' + this.uiElements.step_navigation.back_button.default_style.border.color,
        boxSizing: 'border-box'
      }
    },
    continueButtonStyle: function () {
      return {
        color: this.uiElements.step_navigation.next_button.font.color,
        backgroundColor: this.uiElements.step_navigation.next_button.backgroundColor,
        fontFamily: this.uiElements.step_navigation.next_button.font.family,
        fontSize: this.uiElements.step_navigation.next_button.font.size + 'px',
        fontWeight: this.uiElements.step_navigation.next_button.font.weight,
        height: this.uiElements.step_navigation.next_button.font.height + 'px',
        width: '100%',
        padding: '0px',
        borderRadius: this.uiElements.step_navigation.next_button.borderRadius + 'px !important',
        border: this.uiElements.step_navigation.next_button.default_style.border.width + 'px' + ' ' + this.uiElements.step_navigation.next_button.default_style.border.style + ' ' + this.uiElements.step_navigation.next_button.default_style.border.color,
        boxSizing: 'border-box'
      }
    },
    submitButtonStyle: function () {
      return {
        color: this.uiElements.step_navigation.submit_button.font.color,
        backgroundColor: this.uiElements.step_navigation.submit_button.backgroundColor,
        fontFamily: this.uiElements.step_navigation.submit_button.font.family,
        fontSize: this.uiElements.step_navigation.submit_button.font.size + 'px',
        fontWeight: this.uiElements.step_navigation.submit_button.font.weight,
        height: this.uiElements.step_navigation.submit_button.font.height + 'px',
        width: '100%',
        padding: '0px',
        borderRadius: this.uiElements.step_navigation.submit_button.borderRadius + 'px !important',
        border: this.uiElements.step_navigation.submit_button.default_style.border.width + 'px' + ' ' + this.uiElements.step_navigation.submit_button.default_style.border.style + ' ' + this.uiElements.step_navigation.submit_button.default_style.border.color,
        boxSizing: 'border-box'
      }
    },
    formDemoStyle: function () {
      return {
        backgroundColor: this.uiElements.background.formColor,
        border: this.uiElements.background.form_border_width + 'px' + ' ' +
          this.uiElements.background.form_border_style + ' ' +
          this.uiElements.background.form_border_color,
        borderRadius: this.uiElements.background.form_border_radius + 'px',
        padding: this.uiElements.background.form_padding_top + 'px' + ' ' + this.uiElements.background.form_padding_right + 'px' + ' ' + this.uiElements.background.form_padding_bottom + 'px' + ' ' + this.uiElements.background.form_padding_left + 'px'
      }
    }
  }
}
</script>
<style lang="scss">
.counter {
  margin-bottom: 27px;
  display: flex;
  justify-content: center;
  font-size: 21px;
}

.counter-value {
  border: 1px solid grey;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}
</style>
