import * as actionTypes from '../store/action-types'
import { featureSlugs } from '@/shared/constants/packagebuilder/features'
import { featureProperties } from '@/shared/constants/packagebuilder/featureProperties'
import operators from '@/shared/constants/comparisonOperators'

export default {
  data: function () {
    return {
      count: {
        partial: [],
        lead: []
      },
      limit: {
        alert: false,
        message: ''
      },
      page: {
        setting: false
      },
      canUseAnswerBased: null
    }
  },
  methods: {
    packageBuilderMixin_partialLeadsCount: function () {
      this.$store.dispatch(actionTypes.FETCH_PARTIAL_LEADS_COUNT, {
        userId: this.user.id
      })
        .then((response) => {
          this.count.partial = response.data.data
        })
    },
    packageBuilderMixin_completeLeadCount: function () {
      this.$store.dispatch(actionTypes.FETCH_COMPLETE_LEADS_COUNT, {
        userId: this.user.id
      }).then((response) => {
        this.count.lead = response.data.data
      })
    },
    packageBuilderMixin_formCount: function () {
      this.$store.dispatch(actionTypes.FETCH_FORMS_COUNT)
    },

    async packageBuilderMixin_canCreateLeadAndPartialLead() {
      this.limit.message = ''
      this.limit.alert = false
      this.page.setting = false

      const partialAccess = this.userMixin_hasFeatureAccess(featureSlugs.PARTIAL_LEADS)
      const leadAccess = this.userMixin_hasFeatureAccess(featureSlugs.LEADS)

      if (!partialAccess && !leadAccess) {
        this.setLimitMessage("You don't have access to partial leads and leads.")
        return false
      } else if (!partialAccess) {
        this.page.setting = true
        this.setLimitMessage("You don't have access to partial leads.")
      } else if (!leadAccess) {
        this.setLimitMessage("You don't have access to leads.")
      }

      const operator = operators.AND
      const inputArrayPL = partialAccess ? [{
        limitation_title: featureProperties.NO_OF_PARTIAL_LEADS,
        limitation_value: this.count.partial.NONE,
        limitation_valueMonthly: this.count.partial.MONTHLY,
        limitation_valueYearly: this.count.partial.YEARLY,
        limitation_valueAsPerPlan: this.count.partial.AS_PER_PLAN,
        compare: operators.LT
      }] : []

      const inputArrayL = leadAccess ? [{
        limitation_title: featureProperties.NO_OF_LEADS,
        limitation_value: this.count.lead.NONE,
        limitation_valueMonthly: this.count.lead.MONTHLY,
        limitation_valueYearly: this.count.lead.YEARLY,
        limitation_valueAsPerPlan: this.count.lead.AS_PER_PLAN,
        compare: operators.LT
      }] : []

      try {
        let canCreatePL = partialAccess ? await this.userMixin_hasFeaturePropertyAccess(
          featureSlugs.PARTIAL_LEADS,
          operator,
          inputArrayPL
        ) : true

        let canCreateL = leadAccess ? await this.userMixin_hasFeaturePropertyAccess(
          featureSlugs.LEADS,
          operator,
          inputArrayL
        ) : true

        if (!canCreatePL && !canCreateL) {
          this.setLimitMessage('You have crossed the partial leads & leads submission limit.')
        } else if (!canCreatePL) {
          this.page.setting = true
          this.setLimitMessage('You have crossed the partial leads submission limit.')
        } else if (!canCreateL) {
          this.setLimitMessage('You have crossed the leads submission limit.')
        }

        return canCreatePL && canCreateL
      } catch (error) {
        console.log('error:', error)
        this.limit.alert = false
        return false
      }
    },
    packageBuilderMixin_canUseMultipileEndings() {
      return this.userMixin_hasFeatureAccess(featureSlugs.MULTIPILE_ENDINGS)
    },
    getPlanFeatureProperties: function (plan, featureSlug) {
      let planFeatures = plan.plan_features.find(elm => elm.slug === featureSlug)
      if (planFeatures === undefined) {
        return []
      }
      return planFeatures.feature_properties.filter(element => element.feature_id === planFeatures.id)
    },
    packageBuilderMixin_canUseEndingType: function (endingType) {
      if (this.isAdmin || this.isSuperCustomer) {
        return true
      }

      let planFeatureProperties = this.getPlanFeatureProperties(this.plan, featureSlugs.MULTIPILE_ENDINGS)

      // Map ending types to their respective feature properties
      const endingTypeToFeatureProperty = {
        [this.formEndings.ANSWERBASED]: featureProperties.HAS_ANSWERBASED,
        [this.formEndings.VISITORBASED]: featureProperties.HAS_VISITORBASED
        // Add more mappings as needed
      }

      // Get the feature property corresponding to the endingType
      const featureProperty = endingTypeToFeatureProperty[endingType]

      // Check if the feature property exists and get its value
      const hasEndingType = this.getValueByTitle(planFeatureProperties, featureProperty)

      return parseInt(hasEndingType, 10)
    },
    getValueByTitle: function (data, featureProperty) {
      let featurePropertyValue = data.find(obj => obj.title === featureProperty)
      return featurePropertyValue ? featurePropertyValue.value : undefined
    },
    setLimitMessage(message) {
      this.limit.alert = true
      this.limit.message = message
    }
  }
}
