<template>
  <div class="general-widget">
    <div class="general-widget__header">
      <!-- Filters -->
      <div :class="'general-widget__filters' + (showFilters ? '' : ' hide')" v-if="initialized">
        <div class="general-widget__filter form-filter">
          <v-select class="ui-select--bordered" label="Form" placeholder="All" :items="forms"
            v-model="filterParams.forms" :multiple="true" @change="onFormFilterChange"></v-select>
        </div>
        <div class="general-widget__filter period-filter">
          <v-select class="ui-select--bordered" label="Period" item-title="label" return-object :items="periods"
            v-model="filterParams.period.value" @change="(value) => onPeriodFilterChange(value)"></v-select>
          <div class="custom-period-interval">
            <div v-if="filterParams.period.value.value === 'custom'">
              <label>Start Date</label>
              <ui-datepicker class="ui-datepicker--bordered" placeholder="Select a date"
                v-model="filterParams.period.start_date"
                @input="(value) => onPeriodFilterChange(value, true)"></ui-datepicker>
            </div>
            <div v-if="filterParams.period.value.value === 'custom'">
              <label>End Date</label>
              <ui-datepicker :minDate="filterParams.period.start_date" class="ui-datepicker--bordered"
                placeholder="Select a date" v-model="filterParams.period.end_date"
                @input="(value) => onPeriodFilterChange(value, false)"></ui-datepicker>
            </div>
          </div>
        </div>
        <div class="general-widget__filter">
          <v-select class="ui-select--bordered" label="Compare With" :items="comparePeriods" item-title="label"
            return-object v-model="filterParams.compare_period.value" :disabled="comparePeriods.length <= 1"
            @change="(value) => onComparePeriodFilterChange(value)"></v-select>
          <div class="custom-period-interval">
            <div v-if="filterParams.compare_period.value.value === 'custom'">
              <label>Start Date</label>
              <ui-datepicker class="ui-datepicker--bordered" placeholder="Select a date"
                v-model="filterParams.compare_period.start_date"
                @input="(value) => onComparePeriodFilterChange(value, true)"></ui-datepicker>
            </div>
            <div v-if="filterParams.compare_period.value.value === 'custom'">
              <label>End Date</label>
              <ui-datepicker :minDate="filterParams.compare_period.start_date" class="ui-datepicker--bordered"
                placeholder="Select a date" v-model="filterParams.compare_period.end_date"
                @input="(value) => onComparePeriodFilterChange(value, false)"></ui-datepicker>
            </div>
          </div>
        </div>
      </div>
      <div class="general-widget__filter-apply" v-if="filterParamsChanged">
        <ui-button @click="applyFilter" size="small" color="primary" raised>APPLY</ui-button>
      </div>
      <!-- Indicators -->
      <div class="general-widget__indicators">
        <div class="general-widget__indicator">
          <h3 class="general-widget__indicator__title">
            Views In:{{ initialized }}
          </h3>
          <div class="general-widget__indicator__value" v-if="initialized">
            <span class="general-widget__indicator__value__count">{{ viewsResponse.total }}</span>
            <span v-if="viewsResponse.total_percentage_change"
              :class="'general-widget__indicator__value__percentage-change' + (viewsResponse.total_percentage_change >= 0 ? ' up' : ' down')">
              <span class="material-icons">{{ viewsResponse.total_percentage_change >= 0 ? 'arrow_drop_up' :
                'arrow_drop_down' }}</span>{{ abs(viewsResponse.total_percentage_change) }}%
            </span>
          </div>
          <div class="general-widget__indicator__graph" v-if="initialized">
            <canvas style="margin: auto" id="views-indicator-chart" width="165" height="60"></canvas>
          </div>
          <div class="general-widget__indicator__loading" v-if="!initialized">
            <ui-progress-circular color="primary"></ui-progress-circular>
          </div>
        </div>
        <div class="general-widget__indicator">
          <h3 class="general-widget__indicator__title">
            Leads
          </h3>
          <div class="general-widget__indicator__value" v-if="initialized">
            <span class="general-widget__indicator__value__count">{{ leadsResponse.total }}</span>
            <span v-if="leadsResponse.total_percentage_change"
              :class="'general-widget__indicator__value__percentage-change' + (leadsResponse.total_percentage_change >= 0 ? ' up' : ' down')">
              <span class="material-icons">{{ leadsResponse.total_percentage_change >= 0 ? 'arrow_drop_up' :
                'arrow_drop_down' }}</span>{{ abs(leadsResponse.total_percentage_change) }}%
            </span>
          </div>
          <div class="general-widget__indicator__graph" v-if="initialized">
            <canvas style="margin: auto" id="leads-indicator-chart" width="165" height="60"></canvas>
          </div>
          <div class="general-widget__indicator__loading" v-if="!initialized">
            <ui-progress-circular color="primary"></ui-progress-circular>
          </div>
        </div>
        <div v-if="showPartialActiviation" class="general-widget__indicator">
          <h3 class="general-widget__indicator__title">Partial Leads</h3>
          <div class="content">
            <p class="para">Collect responses before form submission</p>
            <router-link :to="{ name: 'partialleads' }">
              <ui-button class="button">Activate</ui-button>
            </router-link>
          </div>
        </div>
        <div v-else class="general-widget__indicator">
          <h3 class="general-widget__indicator__title">
            Partial Leads
          </h3>
          <div class="general-widget__indicator__value" v-if="initialized">
            <span class="general-widget__indicator__value__count">{{ partialLeadsResponse.total }}</span>
            <span v-if="partialLeadsResponse.total_percentage_change"
              :class="'general-widget__indicator__value__percentage-change' + (partialLeadsResponse.total_percentage_change >= 0 ? ' up' : ' down')">
              <span class="material-icons">{{ partialLeadsResponse.total_percentage_change >= 0 ? 'arrow_drop_up' :
                'arrow_drop_down' }}</span>{{ abs(partialLeadsResponse.total_percentage_change) }}%
            </span>
          </div>
          <div class="general-widget__indicator__graph" v-if="initialized">
            <canvas style="margin: auto" id="partial-leads-indicator-chart" width="165" height="60"></canvas>
          </div>
          <div class="general-widget__indicator__loading" v-if="!initialized">
            <ui-progress-circular color="primary"></ui-progress-circular>
          </div>
        </div>
        <div class="general-widget__indicator">
          <h3 class="general-widget__indicator__title">
            Conversion Rate
          </h3>
          <div class="general-widget__indicator__value" v-if="initialized">
            <span class="general-widget__indicator__value__count">{{ crResponse.total }}%</span>
            <span v-if="crResponse.total_percentage_change"
              :class="'general-widget__indicator__value__percentage-change' + (crResponse.total_percentage_change >= 0 ? ' up' : ' down')">
              <span class="material-icons">{{ crResponse.total_percentage_change >= 0 ? 'arrow_drop_up' :
                'arrow_drop_down' }}</span>{{ abs(crResponse.total_percentage_change) }}%
            </span>
          </div>
          <div class="general-widget__indicator__graph" v-if="initialized">
            <canvas style="margin: auto" id="cr-indicator-chart" width="165" height="60"></canvas>
          </div>
          <div class="general-widget__indicator__loading" v-if="!initialized">
            <ui-progress-circular color="primary"></ui-progress-circular>
          </div>
        </div>
        <div class="general-widget__indicator">
          <h3 class="general-widget__indicator__title">
            Completion Time
          </h3>
          <div class="general-widget__indicator__value" v-if="initialized">
            <span class="general-widget__indicator__value__count">{{ toMinutesAndSeconds(ctResponse.total) }}</span>
            <span v-if="ctResponse.total_percentage_change"
              :class="'general-widget__indicator__value__percentage-change' + (ctResponse.total_percentage_change >= 0 ? ' up' : ' down')">
              <span class="material-icons">{{ ctResponse.total_percentage_change >= 0 ? 'arrow_drop_up' :
                'arrow_drop_down' }}</span>{{ abs(ctResponse.total_percentage_change) }}%
            </span>
          </div>
          <div class="general-widget__indicator__graph" v-if="initialized">
          </div>
          <div class="general-widget__indicator__loading" v-if="!initialized">
            <ui-progress-circular color="primary"></ui-progress-circular>
          </div>
        </div>
      </div>
      <!-- controls -->
      <div class="general-widget__controls">
        <i :class="'material-icons' + (showFilters ? '' : ' disabled')" title="Show filter"
          @click="showFilters = !showFilters" v-show="false">filter_list</i>
        <i class="material-icons" title="Refresh" @click="applyFilter">refresh</i>
      </div>
    </div>
    <div class="general-widget__body">
      <general-chart :initialized="initialized" :generalWidget="generalWidget"></general-chart>
    </div>
    <div class="general-widget__footer">
    </div>
  </div>
</template>

<script>
import widgetTypes from '@/store/dashboard-widget-types'
import _ from 'lodash'
import moment from 'moment'
import Chart from 'chart.js'
import GeneralChart from '@/components/dashboard/widgets/general/Chart'
import Vue from 'vue'

export default {
  data: function () {
    return {
      loading: true,
      showFilters: true,
      cacheMinutes: 15 * 60 * 1000,
      filterParamsChanged: false,
      filtersSet: false,
      filterParams: {
        forms: '',
        period: {
          value: { label: 'Today', value: 'today' },
          start_date: new Date(),
          end_date: new Date()
        },
        compare_period: {
          value: { label: '- None -', value: '' },
          start_date: new Date(),
          end_date: new Date()
        }
      }
    }
  },
  components: {
    'general-chart': GeneralChart
  },
  mounted: function () {
    let generalWidgetCached = localStorage.getItem('general_widget')

    if (!generalWidgetCached || typeof generalWidgetCached !== 'object') {
      this.fetchWidgetState()
    } else {
      this.$store.dispatch('dashboard/fetchDashboardWidgetSuccess', generalWidgetCached)
        .then(() => {
          this.loading = false
          this.setFilters()
          setTimeout(() => this.intializeIndicatorCharts(), 100)
        })
    }
  },
  methods: {
    onFormFilterChange: function () {
      if (this.filtersSet) {
        this.filterParamsChanged = true
      }
    },
    onPeriodFilterChange: function (value, periodStart) {
      if (periodStart) {
        if (value > this.filterParams.period.end_date) {
          this.filterParams.period.end_date = value
        }
      }
      if (this.filtersSet) {
        this.filterParamsChanged = true
      }
      this.filterParams.compare_period.value = { label: '- None -', value: '' }
    },
    onComparePeriodFilterChange: function (value, periodStart) {
      if (periodStart) {
        if (value > this.filterParams.compare_period.end_date) {
          this.filterParams.compare_period.end_date = value
        }
      }
      if (this.filtersSet) {
        this.filterParamsChanged = true
      }
    },
    applyFilter: function () {
      let filterParams = {
        forms: {
          ids: []
        },
        period: {}
      }

      // set forms
      if (this.filterParams.forms && this.filterParams.forms.length > 0) {
        filterParams.forms.ids = this.filterParams.forms.map((f) => f.value)
      }

      // set period
      filterParams.period = {
        value: this.filterParams.period.value.value,
        start_date: '',
        end_date: ''
      }

      if (filterParams.period.value === 'custom') {
        filterParams.period.start_date = moment(this.filterParams.period.start_date).format('YYYY-MM-DD')
        filterParams.period.end_date = moment(this.filterParams.period.end_date).format('YYYY-MM-DD')
      }

      // set compare period
      if (this.filterParams.compare_period.value.value) {
        filterParams.compare_period = {
          value: this.filterParams.compare_period.value.value,
          start_date: '',
          end_date: ''
        }

        if (filterParams.compare_period.value === 'custom') {
          filterParams.compare_period.start_date = moment(this.filterParams.compare_period.start_date).format('YYYY-MM-DD')
          filterParams.compare_period.end_date = moment(this.filterParams.compare_period.end_date).format('YYYY-MM-DD')
        }
      }

      this.fetchWidgetState(filterParams)

      this.filterParamsChanged = false
    },
    fetchWidgetState: function (filterParams) {
      let data = {
        widget: {
          type: 'general',
          params: {}
        }
      }

      if (filterParams) {
        data.widget.params.filter_params = filterParams
      }

      this.loading = true
      this.$store.dispatch('dashboard/fetchDashboardWidget', data).then((response) => {
        localStorage.setItem('general_widget', JSON.stringify(response.data.data), this.cacheMinutes)
        this.$store.dispatch('dashboard/fetchDashboardWidgetSuccess', response.data.data || {})
          .then(() => {
            this.loading = false
            this.setFilters()
            setTimeout(() => this.intializeIndicatorCharts(), 100)
          })
      })
    },
    setFilters: function () {
      // set forms filter
      const formIds = this.generalWidget.filter_params.forms.ids
      this.filterParams.forms = this.forms.reduce((carry, form) => {
        if (formIds.indexOf(form.value) >= 0) {
          carry.push({ label: form.label, value: form.value })
        }
        return carry
      }, [])

      // set period filter
      const period = _.find(this.periods, { value: this.generalWidget.filter_params.period.value })
      this.filterParams.period.value = { label: period.label, value: period.value }

      const periodStart = this.generalWidget.filter_params.period.start_date
      if (periodStart) {
        this.filterParams.period.start_date = moment(periodStart).toDate()
      }

      const periodEnd = this.generalWidget.filter_params.period.end_date
      if (periodEnd) {
        this.filterParams.period.end_date = moment(periodEnd).toDate()
      }

      if (!this.generalWidget.filter_params.compare_period) {
        this.filtersSet = true
        return
      }

      // set compare period filter
      const comparePeriod = _.find(this.comparePeriods, {
        value: this.generalWidget.filter_params.compare_period.value
      })
      this.filterParams.compare_period.value = { label: comparePeriod.label, value: comparePeriod.value }

      const comparePeriodStart = this.generalWidget.filter_params.compare_period.start_date
      if (comparePeriodStart) {
        this.filterParams.compare_period.start_date = moment(comparePeriodStart).toDate()
      }

      const comparePeriodEnd = this.generalWidget.filter_params.compare_period.end_date
      if (comparePeriodEnd) {
        this.filterParams.compare_period.end_date = moment(comparePeriodEnd).toDate()
      }

      this.filtersSet = true
    },
    abs: function (value) {
      return Math.abs(value)
    },
    toMinutesAndSeconds: function (value) {
      let valueParts = value.toString().split('.')
      valueParts[0] = parseInt(valueParts[0]) + 'm'
      valueParts[1] = parseFloat('0.' + valueParts[1])
      valueParts[1] = parseFloat(valueParts[1] * 60).toFixed(0) + 's'
      return valueParts[0] + ' ' + valueParts[1]
    },
    intializeIndicatorCharts: function () {
      this.intializeViewsIndicatorChart()
      this.intializeLeadsIndicatorChart()
      this.intializeCRIndicatorChart()
      this.intializePartialLeadsIndicatorChart()
    },
    intializeViewsIndicatorChart: function () {
      let data = this.viewsResponse.value.map((v) => v.visits)
      let labels = this.viewsResponse.value.map((v) => v.visits_created_at)
      const ctx = document.getElementById('views-indicator-chart')
      this.createIndicatorChart(ctx, data, labels)
    },
    intializeLeadsIndicatorChart: function () {
      let data = this.leadsResponse.value.map((v) => v.leads)
      let labels = this.leadsResponse.value.map((v) => v.leads_created_at)
      const ctx = document.getElementById('leads-indicator-chart')
      this.createIndicatorChart(ctx, data, labels)
    },
    intializePartialLeadsIndicatorChart: function () {
      let data = this.partialLeadsResponse.value.map((v) => v.leads)
      let labels = this.partialLeadsResponse.value.map((v) => v.leads_created_at)
      const ctx = document.getElementById('partial-leads-indicator-chart')
      this.createIndicatorChart(ctx, data, labels)
    },
    intializeCRIndicatorChart: function () {
      let data = this.crResponse.value.map((v) => v.conversion_rate)
      let labels = this.crResponse.value.map((v) => v.conversions_rate_created_at)
      const ctx = document.getElementById('cr-indicator-chart')
      this.createIndicatorChart(ctx, data, labels)
    },
    createIndicatorChart: function (ctx, data, labels) {
      if (ctx) {
        return (new Chart(ctx, {
          type: 'line',
          data: {
            datasets: [
              {
                backgroundColor: 'rgba(238, 110, 115, 0.1)',
                strokeColor: 'rgba(238, 110, 115, 1)',
                data: data
              }
            ],
            labels: labels
          },
          options: {
            responsive: false,
            legend: {
              display: false
            },
            elements: {
              line: {
                borderColor: 'rgb(238, 110, 115)',
                borderWidth: 1
              },
              point: {
                radius: 0
              }
            },
            tooltips: {
              enabled: false
            },
            scales: {
              yAxes: [
                {
                  display: false,
                  ticks: {
                    beginAtZero: true
                  }
                }
              ],
              xAxes: [
                {
                  display: false
                }
              ]
            }
          }
        }))
      }
    }
  },
  computed: {
    showPartialActiviation: function () {
      if (!localStorage.getItem('activated')) {
        return true
      } else {
        return false
      }
    },
    initialized: function () {
      return this.generalWidget && !this.loading
    },
    generalWidget: function () {
      return this.$store.getters['dashboard/getWidget'](widgetTypes.GENERAL)
    },
    forms: function () {
      if (!this.generalWidget) {
        return []
      }

      let forms = this.generalWidget.filters.forms.map(form => {
        return { title: form.title, key: form.id }
      })

      return forms
    },
    periods: function () {
      if (!this.generalWidget) {
        return []
      }

      return this.generalWidget.filters.periods
    },
    comparePeriods: function () {
      let period = _.find(this.periods, { value: this.filterParams.period.value.value })

      if (period && !_.find(period.compare, { value: '' })) {
        period.compare.unshift({ label: '- None -', value: '' })
      }

      return period ? period.compare : []
    },
    viewsResponse: function () {
      return this.generalWidget.response.views
    },
    leadsResponse: function () {
      return this.generalWidget.response.leads
    },
    partialLeadsResponse: function () {
      return this.generalWidget.response.partial_leads
    },
    crResponse: function () {
      return this.generalWidget.response.conversion_rate
    },
    ctResponse: function () {
      return this.generalWidget.response.completion_time
    }
  }
}
</script>
