/* import Vue from 'vue'

export default new Vue()
 */
import mitt from 'mitt'

const emitter = mitt()
export default {
  $on: (...args) => emitter.on(...args),
  $once: (...args) => emitter.once(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args)
}
