<template>
  <div class="media-manager">
    <slot :openModal="openModal">
      <v-btn small @click="openModal()"> <v-icon>mdi-image-plus</v-icon> Add Image</v-btn>
    </slot>
    <app-dialog v-model="showDialog" title="Media Library" @close="close">
      <v-tabs v-model="activeTab">
        <v-tab value="uploader">Upload</v-tab>
        <v-tab value="images">Images</v-tab>
      </v-tabs>
      <v-tabs-window v-model="activeTab">
        <v-tabs-window-item value="uploader">
          <div class="media-manager__uploader">
            <media-uploader @uploaded="uploaded"></media-uploader>
          </div>
        </v-tabs-window-item>
        <v-tabs-window-item value="images">
          <media-browser :type="mediaTypes.IMAGE" :key="timestamp" @delete="refreshBrowser"></media-browser>
          <div class="text-right ma-2" :class="{ hide: tab === 'uploader' }">
            <v-btn class="mr-2" :disabled="selected.length === 0" color="primary" @click="choose">Choose</v-btn>
            <v-btn @click="cancel">Cancel</v-btn>
          </div>
        </v-tabs-window-item>
      </v-tabs-window>
    </app-dialog>
  </div>
</template>

<script>
import MediaUploader from '@/components/media/MediaUploader'
import MediaBrowser from '@/components/media/MediaBrowser'
import AppDialog from '../AppDialog.vue'
import EventBus from '@/library/event-bus'

export default {
  props: {
    selectedTab: {
      type: String,
      default: 'images'
    }
  },
  components: {
    MediaUploader,
    MediaBrowser,
    AppDialog
  },
  data: function () {
    return {
      timestamp: null,
      ref: 'mediaManagerModal',
      showDialog: false,
      activeTab: this.selectedTab
    }
  },
  mounted: function () {
    this.updateTimestamp()

    this.$store.dispatch('media/changeTab', {
      tab: this.selectedTab
    }).then(() => {
      //this.$refs.uiTabs.setActiveTab(this.selectedTab + '-tab')
      this.activeTab = this.selectedTab
    })

    if (this.visible) {
      this.openModal()
    }
  },
  methods: {
    openModal: function () {
      //this.$refs[this.ref].open()
      this.showDialog = true
    },
    closeModal: function () {
      //this.$refs[this.ref].close()
      this.showDialog = false
    },
    close: function () {
      EventBus.$off('mm:selected')
      this.$emit('close')
      this.$store.dispatch('media/setVisibility', {
        visible: false
      })
      this.$store.dispatch('media/selectMedia', {
        selected: []
      })
      this.closeModal()
    },
    cancel: function () {
      this.$store.dispatch('media/setVisibility', {
        visible: false
      })
      this.$store.dispatch('media/selectMedia', {
        selected: []
      })
      this.closeModal()
    },
    onTabChange: function (tab) {
      this.$store.dispatch('media/changeTab', {
        tab: tab.id.replace('-tab', '')
      })
    },
    choose: function () {
      EventBus.$emit('mm:selected', this.$store.getters['media/selected'])
      EventBus.$off('mm:selected')
      this.$emit('mm:selected', this.$store.getters['media/selected'])
      this.$store.dispatch('media/setVisibility', {
        visible: false
      }).then(() => {
        this.$store.dispatch('media/selectMedia', {
          selected: []
        })
      })
      this.close()
    },
    uploaded: function (media) {
      if (media.type === this.mediaTypes.IMAGE) {
        this.$store.dispatch('media/resetMedia', {
          mediaType: this.mediaTypes.IMAGE
        }).then(() => {
          this.$store.dispatch('media/selectMedia', {
            selected: [media] || []
          })

          //this.$refs.uiTabs.setActiveTab('images-tab')
          this.activeTab = 'images'
          this.updateTimestamp()
        })
      }
    },
    updateTimestamp: function () {
      this.timestamp = (new Date()).getTime()
    },
    refreshBrowser: function (type) {
      this.$store.dispatch('media/selectMedia', {
        selected: []
      })

      if (type === this.mediaTypes.IMAGE) {
        this.$store.dispatch('media/resetMedia', {
          mediaType: type
        }).then(() => {
          this.updateTimestamp()
        })
      }
    }
  },
  computed: {
    mediaTypes: function () {
      return this.$store.getters['media/types']
    },
    selected: function () {
      return this.$store.getters['media/selected']
    },
    tab: function () {
      return this.$store.getters['media/tab']
    },
    visible: function () {
      return this.$store.getters['media/visible']
    }
  },
  watch: {
    visible: function (to, from) {
      if (to) {
        this.openModal()
      } else {
        this.closeModal()
      }
    }
  }
}
</script>
