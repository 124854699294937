<template>
  <div class="media-uploader" @dragover.prevent @drop="onDrop">
    <div class="media-uploader__error" v-if="uploadErrorMessage.length > 0">
      <v-alert type="error">{{ uploadErrorMessage }}</v-alert>
    </div>
    <div class="media-uploader__input" v-if="!uploading">
      <h3>Drop Image anywhere to upload</h3>
      <p>or</p>
      <v-file-input accept="image/*" name="upload-image" v-model="imageFiles" @update:modelValue="onMediaChange"
        label="Select Image" style="min-width: 20%"></v-file-input>
      <p>Maximum upload image size: {{ maxImageSize }} MB.</p>
    </div>
    <div class="media-uploader__uploading mt-15" v-if="uploading && !uploadErrorMessage">
      <div style="width: 60%">
        <v-progress-linear color="primary" type="determinate" :progress="uploadProgress" v-show="true"
          class="mb-4"></v-progress-linear>
        <span class="media-uploader__uploading-percent">{{ uploadProgress }}%</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      uploading: false,
      uploadProgress: 0,
      uploadErrorMessage: '',
      extensions: ['gif', 'png', 'jpg', 'jpeg'],
      media: {},
      maxImageSize: 4,
      imageFiles: undefined
    }
  },
  mounted: function () {
  },
  methods: {
    onDrop: function (e) {
      e.stopPropagation()
      e.preventDefault()
      this.upload(e.dataTransfer.files[0])
    },
    onMediaChange: function (files) {
      this.upload(this.imageFiles)
    },
    upload: function (file) {
      if (this.uploading || !file) {
        return
      }

      if (!this.validateImage(file)) {
        return
      }

      this.uploading = true
      this.uploadProgress = 0
      this.uploadErrorMessage = ''

      let formData = new FormData()
      formData.append('media_file', file, file.name)
      formData.append('public', 1)

      let progress = (event) => {
        this.uploadProgress = Math.round((event.loaded / event.total) * 100)
      }

      this.$store.dispatch('media/uploadMedia', { formData: formData, progress: progress })
        .then((response) => {
          this.uploading = false
          this.media = response.data.data
          this.$emit('uploaded', this.media)
          this.$store.dispatch('media/uploadMediaSuccess')
        }, (response) => {
          this.uploading = false
          if (response.response.status == 413) {
            this.uploadErrorMessage = `Sorry file size is greater than ${this.maxImageSize}MB`
          } else {
            this.uploadErrorMessage = response?.data?.meta?.error_message
          }
          this.$store.dispatch('media/uploadMediaError')
        })
    },
    validateImage: function (image) {
      if (!this.isImage(image.type)) {
        this.uploadErrorMessage = 'Only Images are allowed.'
        return false
      }
      if (!this.isValidImage(image.name)) {
        this.uploadErrorMessage = 'Sorry, only following ' + this.extensions.join(', ') + ' image types are allowed.'
        return false
      }
      if (this.maxImageSize < this.fileSize(image)) {
        this.uploadErrorMessage = 'Sorry, maximum image size allowed is ' + this.maxImageSize + ' MB.'
        return false
      }
      return true
    },
    isValidImage: function (name) {
      for (let extension of this.extensions) {
        if (name.toLowerCase().search('.' + extension.toLowerCase()) > -1) {
          return true
        }
      }
      return false
    },
    isImage: function (type) {
      return type.search(/^image\//) > -1
    },
    fileSize: function (file) {
      return parseFloat((file.size / (1024 * 1024)).toFixed(2))
    }
  }
}
</script>
