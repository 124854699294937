<template>
  <div id="dashboard">
    <div class="container">
      <div class="dashboards">
        <ui-alert type="success" :dismissible="false" v-if="subscriptionStatus.subscribeSuccess">Congrats!
          You have successfully subscribed to LeadGen {{ plan.title }} Plan.
        </ui-alert>
        <ui-alert type="error" :dismissible="false" v-if="subscriptionStatus.subscribeFailed">
          Something went wrong and we cannot process your request for plan subscription. Try again later.
        </ui-alert>
        <ui-alert type="success" :dismissible="false" v-if="subscriptionStatus.billingUpdateSuccess">
          Your payment details have been updated successfully.
        </ui-alert>
        <ui-alert type="error" :dismissible="false" v-if="subscriptionStatus.billingUpdateFailed">
          Unable to update your payment details. Try again later.
        </ui-alert>
        <general-widget></general-widget>
      </div>
      <trial-ended ref="trialEndedModalComponent"></trial-ended>
      <account-expired ref="accountExpiredModalComponent" :userName="userName"></account-expired>
      <past-due ref="pastDueModalComponent" :pastDueDetails="pastDueDetails"></past-due>
    </div>
  </div>
</template>

<script>
import * as actionTypes from '@/store/action-types.js'
import Vue from 'vue'
import config from '@/api/config'
import userMixin from '@/mixins/user'
import pastDueMixin from '@/mixins/plan'
import environments from '@/shared/constants/environments'
import GeneralWidget from '@/components/dashboard/widgets/general'
import TrialEnded from '@/components/plan/TrialEnded'
import AccountExpired from '@/components/plan/AccountExpired'
import PastDue from '@/components/plan/PastDue'

export default {
  mixins: [userMixin, pastDueMixin],
  components: {
    'general-widget': GeneralWidget,
    'trial-ended': TrialEnded,
    'account-expired': AccountExpired,
    'past-due': PastDue
  },
  name: 'dashboard-view',
  data: function () {
    return {
      subscriptionStatus: {
        interval: null,
        intervalCounter: 0,
        intervalLimit: 10,
        subscribeSuccess: false,
        subscribeFailed: false,
        billingUpdateSuccess: false,
        billingUpdateFailed: false
      },
      planId: null,
      userName: null
    }
  },
  mounted: function () {
    if (!this.isAdmin && !this.isSuperCustomer) {
      if ((this.userMixin_hasActiveFreeTrialPlan || this.userMixin_hasActiveFreePlan)) {
        this.checkUserStatus()
      } else if (this.userMixin_isPastDueUser) {
        this.planMixin_loadUpdateUrl()
        this.$refs.pastDueModalComponent.$children[0].open()
      }
    }
  },
  methods: {
    checkUserStatus: function () {
      this.planId = config.paddle.planId
      window.Paddle.Setup({ vendor: config.paddle.vendorId })
      let firstName = this.user.name.split(' ')
      if ((this.userMixin_hasActiveFreePlan) || (this.userMixin_hasActiveFreeTrialPlan && this.userMixin_planExpired)) {
        this.subscribe('account-expired-right-section')
        this.userName = firstName[0]
        this.$refs.accountExpiredModalComponent.$children[0].open()
      } else if (this.userMixin_hasActiveFreeTrialPlan && (!this.userMixin_inTrial)) {
        this.$refs.trialEndedModalComponent.$children[0].open()
      }
    },
    resetSubscriptionStatus: function () {
      this.subscriptionStatus = {
        interval: null,
        intervalCounter: 0,
        intervalLimit: 10,
        subscribeSuccess: false,
        subscribeFailed: false,
        billingUpdateSuccess: false,
        billingUpdateFailed: false
      }
    },
    subscribe: function (value) {
      this.resetSubscriptionStatus()
      let metdata = {
        user_id: this.user.id
      }
      let checkoutConfig = {
        product: this.planId,
        theme: 'none',
        email: this.user.email,
        passthrough: JSON.stringify(metdata),
        method: 'inline',
        frameTarget: value,
        frameInitialHeight: 416,
        frameStyle: 'width:100%; min-width:312px; background-color:transparent; border:none;',
        marketingConsent: this.user.subscribe_newsletter,
        successCallback: () => {
          if (this.userMixin_planExpired || !this.subscription) {
            this.$refs.accountExpiredModalComponent.$children[0].close()
          } else {
            this.$refs.trialEndedModalComponent.$children[0].close()
          }
          this.pollingSubscriptionStatus(() => {
            this.subscriptionStatus.subscribeSuccess = true
          })
        },
        closeCallback: () => {
          this.resetSubscriptionStatus()
        }
      }

      if (config.paddle.coupon) {
        checkoutConfig.coupon = config.paddle.coupon
      }

      if (
        [environments.LOCAL, environments.DEV].indexOf(config.environment) >= 0
      ) {
        window.Paddle.Environment.set('sandbox')
      }

      window.Paddle.Checkout.open(checkoutConfig)
    },
    updateBillingDetails: function () {
      window.Paddle.Setup({ vendor: config.paddle.vendorId })
      let checkoutConfig = {
        override: this.pastDueDetails.updateBankDetailsUrl,
        successCallback: () => {
          this.pollingSubscriptionStatus(() => {
            this.subscriptionStatus.billingUpdateSuccess = true
            this.$refs.pastDueModalComponent.$children[0].close()
          })
        },
        closeCallback: () => {
          this.resetSubscriptionStatus()
        }
      }
      if (
        [environments.LOCAL, environments.DEV].indexOf(config.environment) >= 0
      ) {
        window.Paddle.Environment.set('sandbox')
      }

      window.Paddle.Checkout.open(checkoutConfig)
    },
    pollingSubscriptionStatus: function (callback = null) {
      this.subscriptionStatus.interval = setInterval(() => {
        if (
          this.subscriptionStatus.intervalCounter >=
          this.subscriptionStatus.intervalLimit
        ) {
          this.subscriptionStatus.subscribeSuccess = false
          this.subscriptionStatus.subscribeFailed = true
          this.subscriptionStatus.billingUpdateSuccess = false
          this.subscriptionStatus.billingUpdateFailed = true
          clearInterval(this.subscriptionStatus.interval)
          return
        }
        this.subscriptionStatus.intervalCounter++
        this.syncLocalUser(() => {
          clearInterval(this.subscriptionStatus.interval)
          if (callback) {
            callback()
          }
        })
      }, 1000)
    },
    syncLocalUser: function (callback) {
      this.$store
        .dispatch(actionTypes.FETCH_USER, {
          userId: this.user.id
        })
        .then((response) => {
          let user = response.data.data
          if (!user.plan || !user.plan.subscription) {
            return
          }
          this.$store.dispatch(actionTypes.UPDATE_USER_LOCAL, user).then(() => {
            localStorage.setItem('user', JSON.stringify(user))
          })
          callback()
        })
    }
  }
}
</script>
