export default {
  paddleScale: 'paddle_scale',
  paddlePro: 'paddle_pro',
  paddleStarter: 'paddle_starter',
  freeTrial: 'free_trial',
  freePlan: 'free',
  paddleScaleAnnual: 'paddle_scale_annual',
  paddleProAnnual: 'paddle_pro_annual',
  proTrial: 'paddle_pro_trial'
}
