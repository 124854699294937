import * as mutationTypes from '@/store/mutation-types'
import * as BlackListIpsApi from '@/api/blacklistips'
import Vue from 'vue'

const state = {
  blacklistIps: []
}

const getters = {
  getBlacklistIps: function (state) {
    return state.blacklistIps
  }
}

const actions = {
  fetchBlacklistIps ({state, commit}, data) {
    commit(mutationTypes.BIP_FETCH_BLACKLIST_IPS)
    return BlackListIpsApi.fetchBlacklistIps()
  },
  fetchBlacklistIpsSuccess ({state, commit}, data) {
    commit(mutationTypes.BIP_FETCH_BLACKLIST_IPS_SUCCESS, data)
  },

  createBlacklistIp ({state, commit}, data) {
    commit(mutationTypes.BIP_CREATE_BLACKLIST_IP)
    return BlackListIpsApi.createBlacklistIp(data)
  },

  updateBlacklistIp ({state, commit}, data) {
    commit(mutationTypes.BIP_UPDATE_BLACKLIST_IP)
    return BlackListIpsApi.updateBlacklistIp(data)
  },
  updateBlacklistIpSuccess ({state, commit}, data) {
    commit(mutationTypes.BIP_UPDATE_BLACKLIST_IP_SUCCESS, data)
  },

  deleteBlacklistIp ({state, commit}, data) {
    commit(mutationTypes.BIP_DELETE_BLACKLIST_IP)
    return BlackListIpsApi.deleteBlacklistIp(data.id)
  }
}

const mutations = {
  [mutationTypes.BIP_FETCH_BLACKLIST_IPS] (state) {
  },
  [mutationTypes.BIP_FETCH_BLACKLIST_IPS_SUCCESS] (state, data) {
    state.blacklistIps = data || []
  },

  [mutationTypes.BIP_CREATE_BLACKLIST_IP] (state) {
  },

  [mutationTypes.BIP_UPDATE_BLACKLIST_IP] (state) {
  },
  [mutationTypes.BIP_UPDATE_BLACKLIST_IP_SUCCESS] (state, data) {
    let index = 0
    for (let blacklistIp of state.blacklistIps) {
      if (blacklistIp.id === data.id) {
        Vue.set(state.blacklistIps, index, data)
      }
      index++
    }
  },

  [mutationTypes.BIP_DELETE_BLACKLIST_IP] (state) {
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
