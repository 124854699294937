<template>
  <v-container style="width:80%" class="form-list-view">
    <h4 class="page-title">Forms</h4>
    <v-row>
      <v-col class="text-end mb-n10">
        <v-btn color="primary-red" class="text-none font-weight-bold mr-3" rounded="md" height="40"
          @click="$router.push({ name: 'forms.create.option' })" prepend-icon="mdi-plus">
          Create Form
        </v-btn>
        <v-btn color="blue" class="text-none font-weight-bold" rounded="md" height="40"
          @click="$router.push({ name: 'form.share' })" prepend-icon="mdi-share-variant" v-if="isAdmin">
          Share
        </v-btn>
      </v-col>
    </v-row>

    <!-- layout for alerts -->
    <v-row no-gutters align="center" class="my-4" v-if="!canCreateForm">
      <v-col cols="12" md class="mb-2">
        <v-alert border variant="tonal" type="warning" rounded="md" density="compact" class="my-4 text-left"
          icon="mdi-alert-circle">
          <span class="text-black mb-0"
            v-if="(userMixin_hasActiveFreeTrialPlan || userMixin_hasActiveFreePlan) && (userMixin_planExpired)">
            Your account has expired. Please <router-link :to="{ name: 'plans.index' }" title="Plans">Click here
            </router-link> to upgrade your account.
          </span>
          <span class="text-black mb-0" v-else-if="userMixin_hasActiveFreeTrialPlan && !userMixin_inTrial">
            Your trial has ended. Please <router-link :to="{ name: 'plans.index' }" title="Plans">Click
              here</router-link>
            to upgrade your account.
          </span>
          <span class="text-black mb-0" v-else-if="userMixin_isPastDueUser">
            The billing on your account failed for this month's payment. Please <router-link
              :to="{ name: 'plans.index' }" title="Update Your Bank Details">Click here</router-link> to reactivate
            your
            account.
          </span>
          <span class="text-black mb-0" v-else-if="userMixin_hasPausedSubscription">
            You do not have any active subscription. Please <router-link :to="{ name: 'plans.index' }"
              title="Update Your Bank Details">Click here</router-link> to reactivate your account.
          </span>
          <span class="text-black mb-0" v-else-if="!isOneToolUser">
            Your limit for creating forms has exceeded. Please <router-link :to="{ name: 'plans.index' }" title="Plans">
              Click here</router-link> to upgrade your account.
          </span>
          <span class="text-black mb-0" v-else>
            Your limit for creating forms has exceeded. Please go to your Onetool account to upgrade your subscription
            to Pro plan.
          </span>
        </v-alert>
      </v-col>
    </v-row>

    <v-row no-gutters align="center" class="my-4" v-if="isFormArchived">
      <v-col cols="12" md class="mb-2">
        <v-alert border variant="tonal" type="success" rounded="md" density="compact" class="my-4 text-left"
          icon="mdi-alert-circle">
          <span class="text-black mb-0">Form is archived successfully!</span>
        </v-alert>
      </v-col>
    </v-row>

    <div>
      <general-list ref="generalList" :fields="fields" :loaded="loaded" :loadedError="loadedError" :listData="listData"
        :listPagination="listPagination" @onListParamsChange="loadFormLists($event)"
        @togglePannel="togglePannel($event)" :canCreateForm="canCreateForm" :bulkDeleteAllowed="bulkDeleteAllowed"
        :bulkDuplicateAllowed="bulkDuplicateAllowed">
        <template v-slot:Form="props">
          <div v-if="isAdmin">
            <router-link class="text-secondary-red" :to="{ name: 'forms.overview', params: { id: props.rowData.id } }">
              <span v-if="props.rowData.template">{{ props.rowData.title }}<span
                  class="template-badge">Template</span></span>
              <span v-else>{{ props.rowData.title }}</span>
            </router-link>
          </div>
          <div v-else>
            <span v-if="props.rowData.blocked">{{ props.rowData.title }}</span>
            <router-link :to="{ name: 'forms.overview', params: { id: props.rowData.id } }" v-else>
              {{ props.rowData.title }}
            </router-link>
          </div>
        </template>
        <template v-slot:ConversionRate="props">
          {{ props.rowData.conversion_rate }}%
        </template>
        <template v-slot:Leads="props">
          <router-link :to="{ name: 'forms.leads', params: { id: props.rowData.id } }" class="secondary-red">
            {{ props.rowData.form_leads_count }}
          </router-link>
        </template>
        <template v-slot:Count="props">
          <span v-if="leadNotificationSetting.enabled && props.rowData.unread_leads > 0">
            <span class="unread-badge">{{ props.rowData.unread_leads > 99 ? '99+' : props.rowData.unread_leads
              }}</span>
          </span>
        </template>
        <template v-slot:CreatedAt="props">
          {{ localeDate(props.rowData.created_at) }}
        </template>
        <template v-slot:UpdatedAt="props">
          {{ localeDate(props.rowData.updated_at) }}
        </template>
        <template v-slot:Action="props">
          <div v-if="isOneToolUser && props.rowData.blocked">
            <a href="https://onetool.co/" target="_blank">
              <ui-button color="orange" raised>Upgrade To Access</ui-button>
            </a>
          </div>
          <router-link :to="{ name: 'plans.index' }"
            title="You need to upgrade to pro plan in order to access this form again."
            v-else-if="props.rowData.blocked">
            <ui-button color="orange" raised>Upgrade To Access</ui-button>
          </router-link>
          <a @click="setDuplicateForm(props.rowData)" title="Clone Form" v-if="!props.rowData.blocked && canCreateForm">
            <v-icon :size="20" color="primary-red" icon="mdi-content-copy"></v-icon>
          </a>
          <a title="View Form" target="_blank" :href="formUrl(props.rowData.key)" v-if="!props.rowData.blocked">
            <v-icon :size="20" color="primary-red" icon="mdi-open-in-new"></v-icon>
          </a>
        </template>
      </general-list>
    </div>

    <!-- Form Duplicate  Modal -->
    <v-dialog v-model="duplicate.isOpenModal" max-width=" fit-content">
      <v-card max-width="600" prepend-icon="mdi-content-copy">
        <template v-slot:title>
          <strong> Are you sure?</strong>
        </template>
        <v-card-text>
          <v-alert v-if="duplicate.message" class="font-weight-bold my-2" border type="warning" variant="outlined"
            prominent>
            {{ duplicate.message }}
          </v-alert>
          <v-alert border type="info" variant="tonal" class="text-black">
            <p class="text-black ma-0">You are about to duplicate the form <strong>{{ duplicate.form.title
                }}</strong>.
              Are you sure you
              want to proceed?</p>
          </v-alert>
        </v-card-text>
        <v-card-actions class="mb-3 mr-3">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="duplicate.isOpenModal = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="light-blue"
            :loading="duplicate.loader" @click="duplicateForm">
            <span>Duplicate</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import GeneralList from '@/components/lists/GeneralList'
import * as actionTypes from '@/store/action-types'
import config from '@/api/config'
import helperMixin from '@/mixins/helper'
import { mapGetters } from 'vuex'
import userMixin from '@/mixins/user'
import { resourceTypes } from '@/shared/constants/generalList/list'

export default ({
  mixins: [helperMixin, userMixin],
  components: {
    'general-list': GeneralList,
  },
  data: function () {
    return {
      loaded: false,
      loadedError: false,
      listData: [],
      listPagination: {},
      totalForms: 0,
      isFormArchived: false,
      checked: false,
      viewingLead: false,
      duplicate: {
        isOpenModal: false,
        message: null,
        loader: false,
        form: null
      },
      fields: [
        {
          name: 'Form',
          sortField: 'title',
          searchField: 'title'
        },
        {
          name: 'ConversionRate'
        },
        {
          name: 'Leads',
          sortField: 'form_leads_count'
        },
        {
          name: 'Count',
          title: '<span id="total-unread-badge"></span> <i id="mini-button-badge"></i>'
        },
        {
          name: 'CreatedAt',
          sortField: 'created_at'
        },
        {
          name: 'UpdatedAt',
          sortField: 'updated_at'
        },
        {
          name: 'Action'
        }
      ]
    }
  },
  mounted: function () {
    this.checkFormIsArchived()
    this.loadFormLists()
    this.loadLeadNotificationSettings()
  },
  methods: {
    togglePannel: function (event) {
      this.checked = event
    },
    loadLeadNotificationSettings: function () {
      this.$store.dispatch('setting/fetchLeadNotificationSetting')
        .then((response) => {
          let responseData = response.data.data
          this.$store.dispatch('setting/fetchLeadNotificationSettingSuccess', response.data.data)
          if (responseData.enabled) {
            setTimeout(() => {
              this.setTotal()
            }, 150)
          }
        })
    },
    loadFormLists: function (params) {
      if (!params) {
        return
      }
      params.resource = resourceTypes.FORMS_LIST
      this.loaded = false
      this.loadedError = false
      this.$store.dispatch('formlists/fetchFormLists', params)
        .then((response) => {
          this.listData = response.data.data
          this.listPagination = response.data.pagination
          this.totalForms = this.listPagination.total
          this.$store.dispatch('formlists/fetchFormListsSuccess', response.data.data)
          this.loaded = true
          this.setTotal()
        }, (err) => {
          this.loaded = true
          this.loadedError = true
        })
    },
    duplicateForm: function () {
      this.duplicate.loader = true
      this.$store.dispatch(actionTypes.FORM_DUPLICATE, {
        id: this.duplicate.form.id
      }).then((response) => {
        this.duplicate.loader = false
        this.$router.push({ name: 'forms.overview', params: { id: response.data.data.id } })
      }, (err) => {
        this.duplicate.loader = false
        this.$store.dispatch(actionTypes.FORM_DUPLICATE_ERROR, null)
        this.duplicate.message = err.response?.data?.meta?.error_message || 'An error occurred while duplicating the forms. Please try again.'
      })
    },
    setDuplicateForm: function (form) {
      this.duplicate.form = form
      this.duplicate.isOpenModal = true
    },
    setTotal: function () {
      let dataValue = this.listData
      let sumUnread = 0
      let jsonObj = []
      for (let i = 0; i < dataValue?.length; i++) {
        sumUnread = sumUnread + dataValue[i].unread_leads
        jsonObj.push(dataValue[i].id)
      }
      let elementTotal = document.getElementById('total-unread-badge')
      let elementButton = document.getElementById('mini-button-badge')
      if (sumUnread > 0) {
        elementTotal?.classList?.add('total-unread-badge')
        elementButton?.classList.add('fa', 'fa-circle', 'mini-button-badge')
        window.$('.total-unread-badge').text(sumUnread)
        let localThis = this
        let formIds = JSON.stringify(Object.assign({}, jsonObj))
        if (elementButton) {
          elementButton.onclick = function () {
            localThis.markViewed(formIds)
          }
        }
      } else {
        window.$('.total-unread-badge').text('')
        elementTotal?.classList?.remove('total-unread-badge')
        elementButton?.classList?.remove('fa', 'fa-circle', 'mini-button-badge')
      }
    },
    markViewed: function (value) {
      this.viewingLead = true
      this.$store.dispatch(actionTypes.MARK_READ_LEAD, {
        formId: value
      }).then((response) => {
        this.viewingLead = false
        this.$refs.generalList.reload()
        this.loadLeadNotificationSettings()
      }, (err) => {
        this.viewingLead = false
        this.$store.dispatch(actionTypes.MARK_READ_LEAD_ERROR, null)
      })
    },
    formUrl: function (key) {
      return config.formsUrl + '/' + key
    },
    checkFormIsArchived() {
      this.isFormArchived = this.$store.state.formArchived
      this.$store.state.formArchived = false
    }
  },
  computed: {
    ...mapGetters([
      'forms',
      'token'
    ]),
    bulkDeleteAllowed: function () {
      if (this.isAdmin || this.isSuperCustomer) {
        return true
      } else if ((this.userMixin_hasActiveFreeTrialPlan) && (!this.userMixin_inTrial || this.userMixin_planExpired)) {
        return false
      } else if (this.userMixin_hasActiveFreePlan) {
        return false
      }
      return true
    },
    bulkDuplicateAllowed: function () {
      if (this.isAdmin || this.isSuperCustomer) {
        return true
      } else if ((this.userMixin_hasActiveFreeTrialPlan) && (!this.userMixin_inTrial || this.userMixin_planExpired)) {
        return false
      } else if (this.userMixin_hasActiveFreePlan) {
        return false
      } else if (this.userMixin_isPastDueUser) {
        return false
      } else if (this.userMixin_hasPausedSubscription && !this.userMixin_hasPausedSubscriptionInGrace) {
        return false
      }
      return true
    },
    canCreateForm: function () {
      if (this.isAdmin || this.isSuperCustomer) {
        return true
      } else if ((this.userMixin_hasActiveFreeTrialPlan) && (!this.userMixin_inTrial || this.userMixin_planExpired)) {
        return false
      } else if (this.userMixin_hasActiveFreePlan) {
        return false
      } else if (this.userMixin_isPastDueUser) {
        return false
      } else if (this.userMixin_hasPausedSubscription && !this.userMixin_hasPausedSubscriptionInGrace) {
        return false
      }
      return this.plan.form_limit > this.totalForms
    },
    leadNotificationSetting: function () {
      return this.$store.getters['setting/leadNotificationSetting']
    },
    formLists: function () {
      return this.$store.getters['formlists/getFormLists']
    }
  }
})
</script>
