<template>
  <div class="past-due-modal-cpt">
    <ui-modal ref="pastDueModal" class="past-due-modal-cpt__modal" size="large" dismiss-on="close-button"
      remove-close-button>
      <div slot="header" class="header-class">
        <div class="header-logo-left">
          <img src="./../../assets/images/views/logo/logo_2022.png" alt="Leadgen" />
        </div>
        <div class="header-text"></div>
        <div class="header-logo-right">
          <img src="./../../assets/images/icons/plan-header-two.svg" alt="Capterra" />
        </div>
      </div>
      <div class="past-due-container">
        <div class="loading-div" v-if="!pastDueDetails.urlLoaded">
          <ui-progress-circular color="primary" :size="60" :stroke="2" v-show="true"></ui-progress-circular>
        </div>
        <div class="alerts-container" v-else-if="pastDueDetails.urlErrorMessage">
          <!-- alert message -->
          <ui-alert type="success" :dismissible="false">{{ pastDueDetails.urlErrorMessage }}</ui-alert>
        </div>
        <div class="past-due-content" v-else>
          <div class="past-due-section">
            <h1>Action Required: Confirm Bank Details</h1>
            <p>
              The billing on your account failed for this month's payment and your account including your forms are
              currently offline.
              This can happen when there is a problem with the bank, insufficient funds, card expiry or other reasons.
              Reactivate your account by adding your payment details here.
            </p>
            <br>
            <div class="button-div" @click="updateBankDetails()">
              <a target="_blank">
                <ui-button size="small" color="red" class="past-due-button">Update Your Bank Details</ui-button>
              </a>
            </div>
            <br>
            <p>
              To regain access to the account, click on the button above and update the bank/card information.
              This will re-activate the account and ensures your forms and lead access will be available again.
            </p>
          </div>
        </div>
      </div>
    </ui-modal>
  </div>
</template>

<script>
export default {
  props: ['pastDueDetails'],
  methods: {
    updateBankDetails: function () {
      this.$parent.updateBillingDetails()
    }
  }
}
</script>
