import * as mutationTypes from '@/store/mutation-types'
import * as FormTemplateBuilderApi from '@/api/form-templates'
import Vue from 'vue'

const state = {
  formTemplates: [],
  formCategories: [],
  formIndustries: []
}

const getters = {
  getFormTemplates: function (state) {
    return state.formTemplates
  },
  getFormTemplateCategories: function (state) {
    return state.formCategories
  },
  getFormTemplateIndustries: function (state) {
    return state.formIndustries
  }

}

const actions = {
  createTemplateBuild ({state, commit}, data) {
    commit(mutationTypes.CREATE_FORM_TEMPLATE_BUILD)
    return FormTemplateBuilderApi.createTemplateBuild(data)
  },

  getFormTemplates ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATE_LISTS)
    return FormTemplateBuilderApi.getFormTemplates()
  },
  getFormTemplatesSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATES_SUCCESS, data)
  },

  getFormTemplate ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATE)
    return FormTemplateBuilderApi.getFormTemplate(data.id)
  },
  getFormTemplateSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATE_SUCCESS, data)
  },

  getFormTemplateCategories ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATE_CATEGORIES)
    return FormTemplateBuilderApi.getFormTemplateCategories()
  },
  getFormTemplateCategoriesSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATE_CATEGORIES_SUCCESS, data)
  },

  getFormTemplateIndustries ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATE_INDUSTRIES)
    return FormTemplateBuilderApi.getFormTemplateIndustries()
  },
  getFormTemplateIndustriesSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATE_INDUSTRIES_SUCCESS, data)
  },

  updateFormTemplate ({state, commit}, data) {
    commit(mutationTypes.UPDATE_FORM_TEMPLATE)
    return FormTemplateBuilderApi.updateFormTemplate(data.id, data.data)
  },

  deleteFormTemplate ({state, commit}, data) {
    commit(mutationTypes.DELETE_FORM_TEMPLATE)
    return FormTemplateBuilderApi.deleteFormTemplate(data.id)
  },
  deleteFormTemplateSuccess ({state, commit}, data) {
    commit(mutationTypes.DELETE_FORM_TEMPLATE_SUCCESS, data)
  },

  getFormTemplateList ({ state, commit }, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATES)
    return FormTemplateBuilderApi.getFormTemplateList(data)
  },
  getFormTemplateListSuccess ({ state, commit }, data) {
    commit(mutationTypes.FETCH_FORM_TEMPLATE_LISTS_SUCCESS, data)
  }
}

const mutations = {
  [mutationTypes.CREATE_FORM_TEMPLATE_BUILD] (state, data) {
    state.formTemplates.push(data)
  },

  [mutationTypes.FETCH_FORM_TEMPLATE] (state) {},
  [mutationTypes.FETCH_FORM_TEMPLATES_SUCCESS] (state, data) {
    state.formTemplates = data || []
  },

  [mutationTypes.FETCH_FORM_TEMPLATE_CATEGORIES] (state) {},
  [mutationTypes.FETCH_FORM_TEMPLATE_CATEGORIES_SUCCESS] (state, data) {
    state.formCategories = data || []
  },

  [mutationTypes.FETCH_FORM_TEMPLATE_INDUSTRIES] (state) {},
  [mutationTypes.FETCH_FORM_TEMPLATE_INDUSTRIES_SUCCESS] (state, data) {
    state.formIndustries = data || []
  },

  [mutationTypes.UPDATE_FORM_TEMPLATE] (state, data) {},

  [mutationTypes.DELETE_FORM_TEMPLATE_SUCCESS] (state, data) {
    let index = 0
    for (let gkey of state.formTemplates) {
      if (gkey.id === data.id) {
        state.formTemplates.splice(index, 1)
        Vue.set(state.formTemplates, state.formTemplates, data)
      }
      index++
    }
  },

  [mutationTypes.FETCH_FORM_TEMPLATES] (state, data) {},
  [mutationTypes.FETCH_FORM_TEMPLATES_SUCCESS] (state, data) {
    state.formTemplateList = data || []
  },

  [mutationTypes.DELETE_FORM_TEMPLATE] (state, data) {
    state.formTemplateList = data || []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
