import Vue from 'vue'
import * as mutationTypes from '@/store/mutation-types'
import * as WhiteLabelApi from '@/api/white-label'
import * as CredentialApi from '@/api/credential'
import * as LeadNotificationApi from '@/api/lead-notification'
import _ from 'lodash'

const state = {
  whiteLabel: {},
  credentials: [],
  leadNotification: {}
}

const getters = {
  whiteLabelSetting: function (state) {
    return state.whiteLabel
  },
  credentials: function (state) {
    return state.credentials
  },
  leadNotificationSetting: function (state) {
    return state.leadNotification
  }
}

const actions = {
  fetchWhiteLabelSetting (context, data) {
    return WhiteLabelApi.fetchWhiteLabel(data)
  },
  fetchWhiteLabelSettingSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_SETTING_WHITE_LABEL_SUCCESS, data)
  },
  updateLocalWhiteLabelSetting ({state, commit}, data) {
    commit(mutationTypes.UPDATE_LOCAL_SETTING_WHITE_LABEL, data)
  },
  updateWhiteLabelSetting (context, data) {
    return WhiteLabelApi.updateWhiteLabel(data)
  },

  fetchCredentials (context, data) {
    return CredentialApi.fetchCredentials(data)
  },
  fetchCredentialsSuccess ({state, commit}, data) {
    commit(mutationTypes.UPDATE_LOCAL_SETTING_CREDENTIALS, data)
  },
  updateLocalCredentialSetting ({state, commit}, data) {
    commit(mutationTypes.UPDATE_LOCAL_SETTING_CREDENTIAL, data)
  },
  updateCredentialsSetting (context, data) {
    return CredentialApi.bulkUpdateCredentials(data)
  },

  fetchLeadNotificationSetting (context, data) {
    return LeadNotificationApi.fetchLeadNotificationSetting(data)
  },
  fetchLeadNotificationSettingSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_SETTING_LEAD_NOTIFICATION_SUCCESS, data)
  },
  updateLocalLeadNotificationSetting ({state, commit}, data) {
    commit(mutationTypes.UPDATE_LOCAL_SETTING_LEAD_NOTIFICATION, data)
  },
  updateLeadNotificationSetting (context, data) {
    return LeadNotificationApi.updateLeadNotificationSetting(data)
  }
}

const mutations = {
  [mutationTypes.FETCH_SETTING_WHITE_LABEL_SUCCESS] (state, data) {
    state.whiteLabel = data
  },
  [mutationTypes.UPDATE_LOCAL_SETTING_WHITE_LABEL] (state, data) {
    state.whiteLabel = data
  },
  [mutationTypes.UPDATE_LOCAL_SETTING_CREDENTIALS] (state, data) {
    state.credentials = data
  },
  [mutationTypes.UPDATE_LOCAL_SETTING_CREDENTIAL] (state, data) {
    const credentialIndex = _.findIndex(state.credentials, {id: data.id})

    Vue.set(state.credentials, credentialIndex, data)
  },
  [mutationTypes.FETCH_SETTING_LEAD_NOTIFICATION_SUCCESS] (state, data) {
    state.leadNotification = data
  },
  [mutationTypes.UPDATE_LOCAL_SETTING_LEAD_NOTIFICATION] (state, data) {
    state.leadNotification = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
