<template>
  <div>
    <slot :openModal="openModal">
      <v-btn small @click="openModal()">Select Icon</v-btn>
    </slot>
    <app-dialog v-model="showIconManager" size="large" class="icon-library-manager" title="Icon Library" @close="close">
      <div class="icon-library-manager__content">
        <div class="icon-library-manager__libraries">
          <div class="icon-library-manager__libraries__cat">
            <div :class="{
              'icon-library-manager__libraries__cat__item': true,
              'icon-library-manager__libraries__cat__item--collapsed': category.collapsed
            }" v-for="category in categories" :key="category.id">
              <div class="icon-library-manager__libraries__cat__item__title" @click="toggleCategory(category)">
                <i class="material-icons">keyboard_arrow_down</i>
              </div>
              <div
                :class="{ 'icon-library-manager__libraries__lib__item': true, 'icon-library-manager__libraries__lib__item--active': l.id === library }"
                v-for="l in categoryLibraries(category.id)" :key="l.id" @click="() => selectLibrary(l.id)">
                <span v-html="l.icon"></span>&nbsp;&nbsp;{{ l.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="icon-library-manager__icons" style="max-height: 600px; overflow:auto">
          <div class="icon-library-manager__icons__search">
            <v-text-field placeholder="Search Icon" v-model="search"></v-text-field>
          </div>
          <div class="icon-library-manager__icons__loading" v-if="fetching">
            <v-progress-circular color="black"></v-progress-circular>
          </div>
          <div v-else-if="search && Object.keys(libraryIcons).length === 0">
            <p class="text-center"><strong>{{ this.search }}:</strong> not found</p>
          </div>
          <div class="icon-library-manager__icons__content" v-else>
            <div
              :class="{ 'icon-library-manager__icon': true, 'icon-library-manager__icon--active': isSelectedIcon(key) }"
              v-for="(libraryIcon, key, index) in libraryIcons" :key="index" @click="selectIcon(key)">
              <div class="icon-library-manager__icon__content">
                <div v-html="libraryIcon['svg']"></div>
                <span>{{ key }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-right ma-4">
        <v-btn :disabled="selected.length === 0" color="primary" @click="choose" class="mr-2">Save</v-btn>
        <v-btn @click="cancel">Cancel</v-btn>
      </div>
    </app-dialog>
  </div>
</template>

<script>
import AppDialog from '../AppDialog.vue'
import EventBus from '@/library/event-bus'

export default {
  components: { AppDialog },
  props: {
    selectedLibrary: {
      type: String,
      default: 'fa5_far'
    }
  },
  data: function () {
    return {
      timestamp: null,
      ref: 'iconLibraryManagerModal',
      showIconManager: false
    }
  },
  mounted: function () {
    this.updateTimestamp()

    if (this.visible) {
      this.openModal()
    }
  },
  methods: {
    fetchIcons: function () {
      this.$store.dispatch('iconlibrary/toggleFetching', { fetching: true })

      if (!this.library) {
        this.$store.dispatch('iconlibrary/toggleFetching', { fetching: false })

        return
      }

      if (this.hasCachedIcons) {
        this.$store.dispatch('iconlibrary/fetchIconsSuccess', {
          library: this.library,
          icons: this.$store.getters['iconlibrary/cachedIcons']
        })

        this.$store.dispatch('iconlibrary/toggleFetching', { fetching: false })

        return
      }

      this.$store.dispatch('iconlibrary/fetchIcons', {
        library: this.library.split('_')[0],
        variation: this.library.split('_')[1]
      })
        .then((response) => {
          this.$store.dispatch('iconlibrary/fetchIconsSuccess', {
            library: this.library,
            icons: response.data.data
          })

          this.$store.dispatch('iconlibrary/toggleFetching', { fetching: false })
        })
    },
    openModal: function () {
      //this.$refs[this.ref].open()
      this.showIconManager = true
      this.fetchIcons()
      setTimeout(() => {
        document.querySelector('.icon-library-manager .v-card-text').scrollTop = 0
      }, 300)
    },
    closeModal: function () {
      //this.$refs[this.ref].close()
      this.showIconManager = false
    },
    close: function () {
      EventBus.$off('ilm:selected')
      this.$emit('close')
      this.$store.dispatch('iconlibrary/setVisibility', {
        visible: false
      })
      this.$store.dispatch('iconlibrary/selectIcon', {
        selected: []
      })
      this.closeModal()
    },
    cancel: function () {
      this.$store.dispatch('iconlibrary/setVisibility', {
        visible: false
      })
      this.$store.dispatch('iconlibrary/selectIcon', {
        selected: []
      })
      this.closeModal()
    },
    choose: function () {
      EventBus.$emit('ilm:selected', this.$store.getters['iconlibrary/selected'])
      EventBus.$off('ilm:selected')
      this.$emit('ilm:selected', this.$store.getters['iconlibrary/selected'])
      this.$store.dispatch('iconlibrary/setVisibility', {
        visible: false
      }).then(() => {
        this.$store.dispatch('iconlibrary/selectIcon', {
          selected: []
        })
      })
      this.close()
    },
    updateTimestamp: function () {
      this.timestamp = (new Date()).getTime()
    },
    selectLibrary: function (library) {
      this.$store.dispatch('iconlibrary/resetIcons').then(() => {
        this.$store.dispatch('iconlibrary/toggleFetching', { fetching: true })

        this.$store.dispatch('iconlibrary/selectLibrary', {
          library
        }).then(() => {
          const delay = this.hasCachedIcons ? 500 : 0

          setTimeout(() => {
            this.fetchIcons()

            this.$store.dispatch('iconlibrary/selectIcon', {
              selected: []
            })
          }, delay)
        })
      })
    },
    selectIcon: function (icon) {
      this.$store.dispatch('iconlibrary/selectIcon', {
        selected: [this.libraryObj.prefix + icon]
      })
    },
    searchIcons: function (icons) {
      if (!this.search) {
        return icons
      }

      return Object.keys(icons).reduce((value, icon) => {
        if (icon.toLowerCase().includes(this.search.toLowerCase())) {
          value[icon] = icons[icon]
        }

        return value
      }, {})
    },
    isSelectedIcon: function (icon) {
      if (this.selected.length === 0) {
        return false
      }

      return this.selected[0] === this.libraryObj.prefix + icon
    },
    categoryLibraries: function (id) {
      return this.$store.getters['iconlibrary/categoryLibraries'](id)
    },
    toggleCategory: function (category) {
      this.$store.dispatch('iconlibrary/toggleCategory', {
        id: category.id,
        collapsed: !category.collapsed
      })
    }
  },
  computed: {
    fetching: function () {
      return this.$store.getters['iconlibrary/fetching']
    },
    hasCachedIcons: function () {
      return this.$store.getters['iconlibrary/hasCachedIcons']
    },
    svgIcons: function () {
      return []
    },
    selected: function () {
      return this.$store.getters['iconlibrary/selected']
    },
    libraryObj: function () {
      return this.$store.getters['iconlibrary/libraryObj']
    },
    library: function () {
      return this.$store.getters['iconlibrary/library']
    },
    visible: function () {
      return this.$store.getters['iconlibrary/visible']
    },
    categories: function () {
      return this.$store.getters['iconlibrary/categories']
    },
    libraries: function () {
      return this.$store.getters['iconlibrary/libraries']
    },
    libraryIcons: function () {
      if (this.search) {
        return this.$store.getters['iconlibrary/searchedIcons']
      }

      return this.$store.getters['iconlibrary/libraryIcons']
    },
    search: {
      get: function () {
        return this.$store.getters['iconlibrary/search']
      },
      set: function (search) {
        this.$store.dispatch('iconlibrary/searchLibrary', {
          search
        })
      }
    }
  },
  watch: {
    visible: function (to, from) {
      if (to) {
        this.fetchIcons()
        this.openModal()
      } else {
        this.closeModal()
      }
    }
  }
}
</script>
