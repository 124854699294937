export default {
  paddleProMonthlyTrial: 'paddle_pro_trial',
  paddleProYearlyTrial: 'paddle_pro_annual_trial',
  paddleScaleMonthlyTrial: 'paddle_scale_trial',
  paddleScaleYearlyTrial: 'paddle_scale_annual_trial',
  paddleEnterpriseMonthlyTrial: 'paddle_enterprise_trial',
  paddleEnterpriseYearlyTrial: 'paddle_enterprise_annual_trial',
  paddleStarterMonthly: 'paddle_starter',
  paddleProMonthly: 'paddle_pro',
  paddleScaleMonthly: 'paddle_scale',
  paddleEnterpriseMonthly: 'paddle_enterprise',
  paddleProYearly: 'paddle_pro_annual',
  paddleScaleYearly: 'paddle_scale_annual',
  paddleEnterpriseYearly: 'paddle_enterprise_annual',
  freeTrial: 'free_trial',
  paddleSinglePlan: 'paddle_single_annual'
}
