import { deleteData, getData, postData, putData } from '../plugins/ApiCalls'

export function fetchContactState(formId, connectionId) {
  return getData('form-contactstate/' + formId + '/connectionId/' + connectionId)
}

export function createContactState(data) {
  return postData('form-contactstate', data)
}

export function updateContactState(data) {
  return putData('form-contactstate/' + data.connectionId, data, { emulateJSON: true })
}

export function deleteContactState(id) {
  return deleteData('form-contactstate/' + id)
}
