<template>
  <div class="form-theme-store-cpt">
    <ui-alert v-if="appliedDefaultTheme" type="info" :removeIcon="true" :dismissible="false">You just activated your new
      theme! please save your changes.</ui-alert>
    <div class="form-theme-store-cpt-titlebar">
      <h3 class="form-theme-store-cpt__title">Themes</h3>
      <div class="form-theme-store-cpt__theme-actions" v-if="selectedTheme">
        <ui-button size="small" color="default" @click="discardTheme" raised>Discard</ui-button>
        <ui-button size="small" color="primary" buttonType="button" @click="() => onThemeAction({ action: 'update' })"
          v-if="this.currentTab === 'myThemes'" raised>Update Theme</ui-button>
        <ui-button size="small" color="primary" buttonType="button" @click="() => onThemeAction({ action: 'update' })"
          v-if="this.currentTab === 'themeStore' && isAdmin" raised>Update Theme</ui-button>
        <ui-button size="small" color="primary" buttonType="button" @click="applyDefaultTheme" raised>Apply
          Theme</ui-button>
        <ui-icon-button has-dropdown icon="more_vert" size="large" color="primary" type="secondary"
          v-if="moreActions.length > 0">
          <ui-menu contain-focus has-icons slot="dropdown" :options="moreActions" @select="onThemeAction"></ui-menu>
        </ui-icon-button>
      </div>
    </div>
    <p>Choose from designs below or use your custom themes</p>
    <div class="form-theme-store-cpt-tabs">
      <ui-tabs :fullwidth="true" @tab-change="onTabChange" ref="uiTabs">
        <!-- THEME STORE TAB -->
        <ui-tab title="Theme Store" id="themeStore">
          <div class="form-theme-store-cpt-tab-content">
            <div class="form-theme-store-cpt__themes">
              <form-theme-store-preview :selectedTheme="selectedTheme" :theme="defaultTheme"
                v-on:selectTheme="selectTheme" v-for="(defaultTheme, index) in defaultThemes" :key="index" />
            </div>
            <!--CONFIRM DIALOGS -->
            <ui-confirm ref="deactivateDefaultTheme" title="Deactivate Default Theme" confirm-button-text="Yes"
              @confirm="deactivateDefaultTheme" v-if="selectedTheme">
              Are you sure you want to deactivate
              <strong>{{ selectedTheme.title }}</strong> default theme?
            </ui-confirm>
          </div>
        </ui-tab>
        <!-- MY|CUSTOM THEMES TAB -->
        <ui-tab title="My Themes" id="myThemes" v-if="customThemes.length > 0">
          <div class="form-theme-store-cpt-tab-content">
            <div class="form-theme-store-cpt__themes">
              <form-theme-store-preview :selectedTheme="selectedTheme" :theme="customTheme"
                v-on:selectTheme="selectTheme" v-for="(customTheme, index) in customThemes" :key="index" />
            </div>
            <!--CONFIRM DIALOGS -->
            <ui-modal ref="publishCustomTheme" title="Publish Custom Theme" v-if="selectedTheme"
              :dismissOn="'close-button'" @close="onModalClose">
              <p>
                Published Custom theme will appear as default theme in customer
                accounts.
              </p>
              <ui-textbox placeholder="Theme Name" v-model="publishThemeTitle" />
              <ui-button buttonType="button" color="primary" @click="publishCustomTheme" size="small"
                :loading="publishingTheme" raised>
                Publish
              </ui-button>
            </ui-modal>
          </div>
        </ui-tab>
        <!-- INACTIVE THEMES TAB -->
        <ui-tab title="Inactive Themes" id="inactiveThemes" v-if="isAdmin && deactivatedDefaultThemes.length > 0">
          <div class="form-theme-store-cpt-tab-content">
            <div class="form-theme-store-cpt__themes">
              <form-theme-store-preview :selectedTheme="selectedTheme" :theme="deactivatedDefaultTheme"
                v-on:selectTheme="selectTheme" v-for="(
                  deactivatedDefaultTheme, index
                ) in deactivatedDefaultThemes" :key="index" />
            </div>
            <!-- CONFIRM DIALOGS -->
            <ui-confirm ref="activateDefaultTheme" title="Activate Theme" confirm-button-text="Yes"
              @confirm="activateDefaultTheme" v-if="selectedTheme">
              Are you sure you want to activate
              <strong>{{ selectedTheme.title }}</strong> theme?
            </ui-confirm>
          </div>
        </ui-tab>
      </ui-tabs>
      <ui-modal ref="updateTheme" title="Update Custom Theme" v-if="selectedTheme" :dismissOn="'close-button'"
        @close="onModalClose">
        <p>
          Are you sure you want to update your custom theme with your current
          form design settings? Your custom theme will permanently take over the
          new settings.
        </p>
        <ui-textbox placeholder="Theme Name" v-model="updateThemeTitle" />
        <ui-button v-if="isAdmin" :icon="this.themeImage ? '' : 'add_photo_alternate'" @click="openPicker"><img
            v-if="this.themeImage" style="width: 50px; padding-right: 4px" :src="this.themeImage" alt="" />
          Select An Image
        </ui-button>
        <ui-button buttonType="button" color="primary" @click="updateTheme" size="small" :loading="updatingTheme"
          raised>
          Update
        </ui-button>
      </ui-modal>
      <ui-confirm ref="deleteTheme" title="Delete Theme" confirm-button-text="Yes" @confirm="deleteTheme"
        v-if="selectedTheme">
        Are you sure you want to delete
        <strong>{{ selectedTheme.title }}</strong> theme?
      </ui-confirm>
    </div>
    <!-- Theme actions start -->
    <div class="form-theme-store-cpt__actions-title">Actions</div>
    <div class="reset-default-theme" @click="showConfirm('defaultTheme')">
      Reset to default theme
    </div>
    <div class="random-theme" @click="openModal('modalCustomTheme')">
      Save as Custom Theme
    </div>
    <ui-confirm ref="defaultTheme" title="Reset Default Theme" confirm-button-text="Yes" @confirm="reset">
      Are you sure you want to Reset to Default Theme?
    </ui-confirm>
    <ui-modal ref="modalCustomTheme" title="Save as Custom Theme" :dismissOn="'close-button'" @close="onModalClose">
      <ui-textbox placeholder="Theme Name" v-model="customeThemeTitle"></ui-textbox>
      <ui-button v-if="isAdmin" :icon="image ? '' : 'add_photo_alternate'" @click="openPicker"><img v-if="image"
          style="width: 50px; padding-right: 4px" :src="image" alt="" />
        Select An Image
      </ui-button>
      <ui-button buttonType="button" color="primary" @click="saveCustomTheme" title="Save Theme" size="small"
        :loading="saving" raised>
        Save Current Theme
      </ui-button>
    </ui-modal>
    <!-- Theme actions end -->
  </div>
</template>

<script>
import * as actionTypes from "@/store/action-types";
import userMixin from "@/mixins/user";
import * as deepmerge from "deepmerge";
import { mapGetters } from "vuex";
import EventBus from "@/library/event-bus";
import FormThemeStorePreview from "@/components/FormVariantTheme/components/FormThemeStorePreview";
import config from "@/api/config";

export default {
  props: {
    formId: {
      type: Number,
    },
    formVariantId: {
      type: Number,
    },
  },
  mixins: [userMixin],
  components: {
    "form-theme-store-preview": FormThemeStorePreview,
  },
  data() {
    return {
      currentTab: "themeStore",

      updateThemeTitle: "",
      publishThemeTitle: "",
      customeThemeTitle: "",
      storeImage: "",
      image: "",

      saving: false,
      appliedDefaultTheme: false,
      selectedTheme: null,
      publishingTheme: false,
      updatingTheme: false,
    };
  },
  methods: {
    openPicker: function () {
      this.$store.dispatch("media/setVisibility", {
        visible: true,
      });
      EventBus.$on("mm:selected", (selected) => {
        this.image = selected[0].url;
        this.storeImage = selected[0].id;
      });
    },
    showConfirm: function (ref) {
      setTimeout(() => this.$refs[ref].open(), 100);
    },
    saveCustomTheme: function () {
      if (!(this.customeThemeTitle === "")) {
        this.saving = true;
        this.$store
          .dispatch(actionTypes.FORMBUILDER_SAVE_FORM_THEME_TEMPLATE, {
            title: this.customeThemeTitle,
            imageId: this.storeImage,
            image: this.image,
            config: {
              general: this.theme.general,
              typography: this.theme.typography,
              ui_elements: this.theme.ui_elements,
              custom_css: this.custom_css,
            },
          })
          .then((response) => {
            this.currentTab = "myThemes";
            this.$refs.uiTabs.setActiveTab(this.currentTab);
            this.selectedTheme = null;
            this.saving = false;
            this.$store
              .dispatch(actionTypes.FORMBUILDER_GET_FORM_THEME_TEMPLATES)
              .then((response) => {
                this.$store.dispatch(
                  actionTypes.FORMBUILDER_GET_FORM_THEME_TEMPLATES_SUCCESS,
                  response.data.data
                );
                this.closeModal("modalCustomTheme");
              });
          });
      }
    },
    openModal(ref) {
      setTimeout(() => this.$refs[ref].open(), 100);
    },
    closeModal(ref) {
      this.$refs[ref].close();
      document.body.classList.remove("ui-modal--is-open");
    },
    publishCustomTheme: function () {
      if (!this.publishThemeTitle) {
        return;
      }

      this.publishingTheme = true;
      this.$store
        .dispatch("formthemetemplate/publishCustomTemplate", {
          id: this.selectedTheme.id,
          config: this.selectedTheme.config,
          title: this.publishThemeTitle,
        })
        .then(
          () => {
            this.selectedTheme = null;
            this.getThemesTemplate();
            this.publishingTheme = false;
            this.publishThemeTitle = "";
            this.closeModal("publishCustomTheme");
          },
          () => {
            this.publishingTheme = false;
          }
        );
    },
    updateTheme: function () {
      if (!this.updateThemeTitle) {
        return;
      }

      this.updatingTheme = true;
      this.$store
        .dispatch("formthemetemplate/updateTemplate", {
          id: this.selectedTheme.id,
          config: {
            general: this.theme.general,
            typography: this.theme.typography,
            ui_elements: this.theme.ui_elements,
          },
          title: this.updateThemeTitle,
          imageId: this.storeImage,
        })
        .then(
          () => {
            this.selectedTheme = null;
            this.getThemesTemplate();
            this.updatingTheme = false;
            this.updateThemeTitle = "";
            this.closeModal("updateTheme");
          },
          () => {
            this.updatingTheme = false;
          }
        );
    },
    selectTheme(theme) {
      this.selectedTheme = theme;

      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    applyDefaultTheme: function () {
      this.$store
        .dispatch(actionTypes.FORMBUILDER_SET_TEMPLATE_THEME, {
          theme: this.selectedTheme,
        })
        .then(() => {
          this.$emit("addStyle");
          this.selectedTheme = null;
          this.appliedDefaultTheme = true;
          setTimeout(() => {
            this.appliedDefaultTheme = false;
          }, 10000);
        });
    },
    discardTheme() {
      this.selectedTheme = null;
    },
    deactivateDefaultTheme: function () {
      this.$store
        .dispatch(
          "formthemetemplate/deactivateDefaultTemplate",
          this.selectedTheme.id
        )
        .then(() => {
          this.selectedTheme = null;
          this.getThemesTemplate();
          this.currentTab = "inactiveThemes";
          this.$refs.uiTabs.setActiveTab(this.currentTab);
        });
    },
    activateDefaultTheme: function () {
      this.$store
        .dispatch(
          "formthemetemplate/activateDefaultTemplate",
          this.selectedTheme
        )
        .then(() => {
          this.selectedTheme = null;
          this.getThemesTemplate();
          this.currentTab = "themeStore";
          this.$refs.uiTabs.setActiveTab(this.currentTab);
        });
    },
    deleteTheme: function () {
      this.$store
        .dispatch("formthemetemplate/deleteTemplate", this.selectedTheme.id)
        .then(() => {
          this.selectedTheme = null;
          this.getThemesTemplate();
          if (this.currentTab === "myThemes" && this.customThemes.length > 0) {
            this.currentTab = "myThemes";
          } else {
            this.currentTab = "themeStore";
          }
          this.$refs.uiTabs.setActiveTab(this.currentTab);
        });
    },
    getThemesTemplate: function () {
      this.$store
        .dispatch(actionTypes.FORMBUILDER_GET_FORM_THEME_TEMPLATES)
        .then((response) => {
          this.$store.dispatch(
            actionTypes.FORMBUILDER_GET_FORM_THEME_TEMPLATES_SUCCESS,
            response.data.data
          );
        });
    },
    reset: function () {
      if (this.$route.params.id && this.$route.params.variantId) {
        this.$store
          .dispatch(actionTypes.FORMBUILDER_SET_DEFAULT_THEME, {
            id: this.theme.id,
            form_variant_id: parseInt(this.$route.params.variantId),
          })
          .then(() => {
            this.$emit("addStyle");
          });
      } else {
        this.$store
          .dispatch(actionTypes.FORMBUILDER_SET_DEFAULT_THEME, { id: -1 })
          .then(() => {
            this.$emit("addStyle");
          });
      }
    },
    onThemeAction(action) {
      switch (action.action) {
        case "deactivate":
          this.showConfirm("deactivateDefaultTheme");
          break;
        case "make_default":
          this.publishThemeTitle = this.selectedTheme.title;
          this.openModal("publishCustomTheme");
          break;
        case "update":
          this.updateThemeTitle = this.selectedTheme.title;
          this.openModal("updateTheme");
          break;
        case "activate":
          this.showConfirm("activateDefaultTheme");
          break;
        case "delete":
          this.showConfirm("deleteTheme");
          break;
      }
    },
    onTabChange(tab) {
      this.selectedTheme = null;
      this.currentTab = tab.id;
    },
    onModalClose() {
      document.body.classList.remove("ui-modal--is-open");
    },
  },
  computed: {
    ...mapGetters(["defaultFormVariantTheme"]),
    themeImage: function () {
      if (!this.selectedTheme.theme_image) {
        return;
      }
      if (this.image) {
        return this.image;
      } else {
        return (
          config.basePath +
          "/media/" +
          this.selectedTheme.theme_image.ref_id +
          "/" +
          this.selectedTheme.theme_image.name
        );
      }
    },
    theme: function () {
      let themes = [];
      if (this.formId && this.formVariantId) {
        themes = this.$store.state.formVariantThemes.filter(
          (t) => t.form_variant_id === parseInt(this.formVariantId)
        );
      } else {
        themes = this.$store.state.formVariantThemes.filter((t) => t.id === -1);
        if (themes.length === 0) {
          return this.defaultFormVariantTheme;
        }
      }

      //return deepmerge(this.defaultFormVariantTheme, themes[0])
      return { ...this.defaultFormVariantTheme, ...themes[0] };
    },
    moreActions() {
      const actions = [];

      if (this.currentTab === "themeStore" && this.isAdmin) {
        actions.push({
          label: "Deactivate",
          action: "deactivate",
        });
      }

      if (this.currentTab === "myThemes") {
        if (this.isAdmin) {
          actions.push({
            label: "Make Default",
            action: "make_default",
          });
        }

        actions.push({
          label: "Delete",
          action: "delete",
        });
      }

      if (this.currentTab === "inactiveThemes" && this.isAdmin) {
        actions.push({
          label: "Activate",
          action: "activate",
        });

        actions.push({
          label: "Delete",
          action: "delete",
        });
      }

      return actions;
    },
    themes: function () {
      return this.$store.state.formThemeTemplates[0] || [];
    },
    customThemes: function () {
      let themes = this.themes.filter((t) => t.type === "CUSTOM");

      return themes;
    },
    defaultThemes: function () {
      let themes = this.themes.filter(
        (t) => t.type === "DEFAULT" && t.published
      );

      return themes;
    },
    deactivatedDefaultThemes: function () {
      let themes = this.themes.filter(
        (t) => t.type === "DEFAULT" && !t.published
      );

      return themes;
    },
  },
};
</script>
<style lang="scss">
.media-manager .ui-modal__mask {
  z-index: 9999;
}
</style>
