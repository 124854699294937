<template>
  <div class="dashboard-notification"
    v-bind:class="{ 'countdown-notification': userMixin_showTwentyFourHourDiscount}"
    v-if="userMixin_showTwentyFourHourDiscount">
    <count-down />
  </div>
  <div class="dashboard-notification"
    v-bind:class="{ 'trial-notification': userMixin_hasActiveFreeTrialPlan && !userMixin_planExpired}"
    v-else-if="!this.isAdmin && !this.isSuperCustomer && userMixin_hasActiveFreeTrialPlan && !userMixin_planExpired">
    <trial-days-left />
  </div>
  <div class="dashboard-notification" v-else>
    <verify-email-notification v-if="showEmailVerification" />
  </div>
</template>

<script>
import userMixin from '@/mixins/user'
import VerifyEmailNotification from '@/components/notification/dashboard/VerifyEmail'
import TrialDaysLeft from '@/components/notification/dashboard/TrialDaysLeft'
import CountDown from '@/components/notification/dashboard/CountDown'

export default {
  mixins: [userMixin],

  components: {
    VerifyEmailNotification,
    TrialDaysLeft,
    CountDown
  },

  computed: {
    showEmailVerification: function () {
      return this.$store.getters['notification/showVerifyEmail'] && !this.user.verified
    }
  }
}
</script>
