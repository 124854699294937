<template>
  <div class="form-template-builder-cpt">
    <div class="container">
      <div class="breadcrums">
        <router-link :to="{ name: 'template-builder.index.list' }" class="text-primary-red mb-5">
          <v-icon left>mdi-arrow-left</v-icon>
          Form Template
        </router-link>
      </div>
      <v-form @submit.prevent="onFormSubmit()" ref="templateBuilder">
        <div class="d-flex justify-space-between mb-4 align-baseline">
          <h5>Template Builder</h5>
          <v-btn rounded="md" type="submit" color="primary-red" class="text-none font-weight-bold"
            :prepend-icon="isEditView ? 'mdi-update' : 'mdi-plus'"
            :text="isEditView ? 'Update Template' : 'Add Template'" :loading="submit.loader"></v-btn>
        </div>
        <div class="template-builder-section content-card">
          <div class="content-card__header d-flex justify-center">
            <h6 class="d-flex justify-left mb-0 text-primary-blue">
              <v-icon icon="mdi-layers-plus" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0">&nbsp;
                {{ isEditView ? "Edit Template" : "Add New Template" }}&nbsp;&nbsp;
              </p>
            </h6>
          </div>
          <div class="d-flex justify-center align-center" style="height: 80vh;" v-if="flags.loader && isEditView">
            <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
              color="secondary-red"></v-progress-circular>
          </div>
          <v-container v-else>
            <v-row>
              <v-col cols="12">
                <v-alert density="compact" border class="mt-3" v-if="submit.action" :type="submit.type">
                  {{ submit.message }}
                </v-alert>
              </v-col>
            </v-row>

            <v-row class="pa-6">
              <v-col cols="12" md="6">
                <!-- Left column -->
                <v-autocomplete rounded="md" prepend-inner-icon="mdi-account" density="comfortable" item-title="email"
                  item-value="id" v-model="choose.user" :items="choose.account" :loading="choose.loadingUsers"
                  label="Search Account By Email" v-model:search="search" @update:search-input="onFromUsersSearch"
                  search :disabled="isEditView" clearable variant="outlined"
                  :rules="$valid([{ rule: 'required', fieldName: 'Choose Account Field' }])"></v-autocomplete>

                <v-card elevation="0" class="mt-4 border-thin">
                  <v-img v-if="image" class="bg-grey-lighten-2" max-height="138" :src="image" cover></v-img>
                  <v-img v-else max-height="138" gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)" cover
                    :src="require('@/assets/images/theme-image.jpg')"></v-img>
                  <v-card-title class="bg-light-blue-lighten-5">
                    <MediaManager @mm:selected="openPicker($event)">
                      <template v-slot:default="{ openModal }">
                        <v-btn block variant="outlined" elevation="0" class="font-weight-bold"
                          prepend-icon="mdi-image-plus" color="secondary-red" text="Add image"
                          @click="openModal()"></v-btn>
                      </template>
                    </MediaManager>
                  </v-card-title>
                </v-card>
                <span v-if="!image" style="color: red;">{{ submit.error }}</span>
                <v-autocomplete class="mt-4" rounded="md" v-model="selectedCategory" :items="formTemplateCategories"
                  item-title="title" return-object density="comfortable" prepend-inner-icon="mdi-shape-plus"
                  variant="outlined" label="Choose categories"
                  :rules="$valid([{ rule: 'required', fieldName: 'Category Field' }])" multiple chips closable-chips
                  clearable></v-autocomplete>

                <v-autocomplete class="mt-4" rounded="md" closable-chips v-model="selectedIndustry"
                  :items="formTemplateIndustries" item-title="title" return-object density="comfortable"
                  prepend-inner-icon="mdi-office-building" variant="outlined" label="Choose Industries"
                  :rules="$valid([{ rule: 'required', fieldName: 'Industry Field' }])" multiple chips
                  clearable></v-autocomplete>

                <v-switch v-if="!isEditView" class="mt-4" v-model="makeDuplicate" density="comfortable"
                  color="primary-red" inset label="Make Duplicate"></v-switch>
              </v-col>

              <v-col cols="12" md="6">
                <!-- Right column -->
                <v-autocomplete rounded="md" :disabled="isEditView" :loading="formsList.loading" v-model="choose.form"
                  item-title="title" item-value="id" :items="choose.forms" density="comfortable"
                  prepend-inner-icon="mdi-form-select" variant="outlined" label="Choose Form"
                  :rules="$valid([{ rule: 'required', fieldName: 'Choose Form Field' }])"></v-autocomplete>

                <v-text-field class="mt-4" rounded="md" clearable @input="errorMessages = []"
                  :error-messages="errorMessages" :disabled="isEditView" v-model="templateId" density="comfortable"
                  prepend-inner-icon="mdi-pound-box" variant="outlined" label="Template ID"
                  :rules="$valid([{ rule: 'required', fieldName: 'Template ID Field' }])"></v-text-field>

                <v-select class="mt-4" rounded="md" :items="selectedCategory" v-model="selectedPrimaryCategory"
                  item-title="title" return-object density="comfortable" prepend-inner-icon="mdi-shape-plus"
                  variant="outlined" label="Choose Primary category"
                  :rules="$valid([{ rule: 'required', fieldName: 'Primary Category Field' }])"></v-select>

                <v-text-field class="mt-4" rounded="md" v-model="title" density="comfortable"
                  prepend-inner-icon="mdi-text-short" variant="outlined" label="New Template Name"
                  :rules="$valid([{ rule: 'required', fieldName: 'Template Title Field' }, { rule: 'between', min: '5', max: '50' }])"></v-text-field>

                <v-textarea class="mt-4" :rules="$valid([{ rule: 'between', min: '3', max: '300' }])" rounded="md"
                  v-model="descriptionText" density="comfortable" auto-grow prepend-inner-icon="mdi-text-long" rows="2"
                  variant="outlined" label="Description"></v-textarea>

              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import * as actionTypes from '@/store/action-types'
import $ from 'jquery'
import errorTypes from '@/store/error-types'
import config from '@/api/config'
import { mapGetters } from 'vuex'
import MediaManager from '@/components/media/MediaManager'


export default {
  components: {
    MediaManager
  },
  data: function () {
    return {
      flags: {
        loader: true
      },
      formsList: {
        loading: false
      },
      errorMessages: [],
      submit: {
        loader: false,
        action: false,
        message: false,
        type: null,
        error: ''
      },
      users: [],
      image: '',
      search: '',
      choose: {
        user: '',
        form: '',
        account: [],
        forms: [],
        loadingUsers: false
      },
      makeDuplicate: false,
      title: '',
      templateId: '',
      selectedCategory: [],
      selectedIndustry: [],
      selectedPrimaryCategory: null,
      descriptionText: '',
      storeImage: '',
      fetchingIndustries: true,
      fetchingCategories: true
    }
  },
  mounted: function () {
    if (this.isEditView) {
      this.fetchFormTemplate()
      this.flags.loader = false
    }
    this.fetchFormTemplateIndustries()
    this.fetchFormTemplateCategories()
  },
  methods: {
    fetchForms: function (search, callback) {
      this.$store.dispatch(actionTypes.FETCH_FORMS, {
        search
      })
        .then((response) => {
          callback(null, response)
        }, (response) => {
          callback(response)
        })
    },
    fetchUsers: function (search, callback) {
      this.$store.dispatch(actionTypes.FETCH_USERS, {
        page: 1,
        search
      })
        .then((response) => {
          callback(null, response)
        }, (response) => {
          callback(response)
        })
    },
    openPicker: function (selected) {
      this.$store.dispatch('media/setVisibility', {
        visible: true
      })
      this.image = selected[0].url
      this.storeImage = selected[0].id
    },
    onFromUsersSearch: function (query) {
      let search = ['email', query]

      this.choose.account = []
      this.choose.loadingUsers = true

      this.fetchUsers(search, (error, response) => {
        this.choose.loadingUsers = false

        if (error) {
          return
        }

        let excludes = []

        if (this.choose.user && this.choose.user.id) {
          excludes.push(this.choose.user.id)
        }

        this.choose.account = this.filterUsers(response.data.data || [], excludes)
      })
      this.onFromUserChange()
    },
    onFromUserChange: function () {
      this.formsList.loading = true
      this.fetchForms(['created_by', this.choose.user], (error, response) => {
        if (error) {
          return
        }
        this.choose.forms = response.data.data || []
        this.formsList.loading = false
      })
    },
    filterUsers: function (users, excludes) {
      if (!users || !excludes) {
        return users
      }

      return users.filter((user) => {
        if (excludes.indexOf(user.id) >= 0) {
          return false
        }

        return true
      })
    },
    autocompleteFix: function () {
      setTimeout(() => {
        $('.ui-select__search-input').attr('autocomplete', 'none')
      }, 500)
    },
    fetchFormTemplateIndustries: function () {
      this.$store.dispatch('formtemplatebuild/getFormTemplateIndustries')
        .then((response) => {
          this.$store.dispatch('formtemplatebuild/getFormTemplateIndustriesSuccess', response.data.data)
          this.fetchingIndustries = false
        }, (response) => {
          this.fetchingIndustries = false
        })
    },
    fetchFormTemplateCategories: function () {
      this.$store.dispatch('formtemplatebuild/getFormTemplateCategories')
        .then((response) => {
          this.$store.dispatch('formtemplatebuild/getFormTemplateCategoriesSuccess', response.data.data)
          this.fetchingCategories = false
        }, (response) => {
          this.fetchingCategories = false
        })
    },
    fetchFormTemplate: function () {
      this.$store.dispatch('formtemplatebuild/getFormTemplate', {
        id: this.$route.params.id
      }).then((response) => {
        let formTemplate = response.data.data
        for (let category of formTemplate.form_categories) {
          for (let categoryName of this.formTemplateCategories) {
            if (category.pivot.form_category_id === categoryName.id) {
              this.selectedCategory.push(categoryName)
              if (category.pivot.is_primary_category === 1) {
                if (category.pivot.form_category_id === categoryName.id) {
                  this.selectedPrimaryCategory = categoryName
                }
              }
            }
          }
        }
        for (let industry of formTemplate.form_industries) {
          for (let indusValue of this.formTemplateIndustries) {
            if (industry.pivot.form_industry_id === indusValue.id) {
              this.selectedIndustry.push(indusValue)
            }
          }
        }
        this.choose.form = formTemplate.template_form.title
        this.choose.user = formTemplate.forms_users.email
        this.descriptionText = formTemplate.description
        this.templateId = formTemplate.template_id
        this.title = formTemplate.title
        this.image = config.basePath + '/media/' + formTemplate.image.ref_id + '/' + formTemplate.image.name
      }).catch(error => {
        this.errored = true
      })
    },
    onFormSubmit: async function () {
      this.submit.message = null
      this.submit.action = false
      const { valid } = await this.$refs.templateBuilder.validate()
      if (!this.image) {
        this.submit.error = 'This image field is required'
        return
      }
      if (!valid) {
        return // Prevent submission if the form is not valid
      }
      this.submit.loader = true
      let formData = {
        form_id: this.choose.form,
        from_user_id: this.choose.user,
        description: this.descriptionText,
        categories: this.selectedCategory,
        industries: this.selectedIndustry,
        template_id: this.templateId,
        title: this.title,
        image: this.storeImage,
        make_duplicate: this.makeDuplicate,
        primary_category: this.selectedPrimaryCategory
      }

      let templateAction = this.isEditView ? 'updateFormTemplate' : 'createTemplateBuild'
      let dispatchData = this.isEditView ? { id: parseInt(this.$route.params.id), data: formData } : formData

      this.$store.dispatch(`formtemplatebuild/${templateAction}`, dispatchData)
        .then((response) => {
          this.submit.loader = false
          this.submit.action = true
          if (!this.isEditView) {
            setTimeout(() => {
              this.$router.push({ name: 'template-builder.index.list' })
            }, 3000)
          }
          let message = `Form Template ${this.isEditView ? 'updated successfully.' : 'added successfully! Redirecting...'}`
          this.showResponseMessage(message, 'success')
        }).catch((error) => {
          this.submit.loader = false
          this.submit.action = true
          this.showResponseMessage(error.response.data.meta.error_message, 'error')
          if (error.response.data.meta.error_type === errorTypes.TEMPLATE_ID_ALREADY_EXIST) {
            this.errorMessages = 'This Template ID is already being used.'
            this.$refs.templateBuilder.validate()
          }
        })
    },
    showResponseMessage: function (message, type) {
      this.submit.message = message
      this.submit.type = type
    }
  },
  computed: {
    ...mapGetters([
      'forms',
      'getFormVariants',
      'token'
    ]),
    isEditView: function () {
      return this.$route.name === 'template-builder.edit'
    },
    formTemplateIndustries: function () {
      return this.$store.getters['formtemplatebuild/getFormTemplateIndustries'] || []
    },
    formTemplateCategories: function () {
      return this.$store.getters['formtemplatebuild/getFormTemplateCategories'] || []
    },
    getSelectedCategory: function () {
      return this.selectedCategory ? this.selectedCategory : null
    }
  },
  watch: {
    search(newQuery) {
      this.onFromUsersSearch(newQuery)
    }
  }
}
</script>
