<template>
  <div class="dashboard-notification__item">
    <div class="countdown-banner">
      <div class="countdown-left">
        <p>Subscribe Now and Save <strong> 30%! </strong> Offer Ends In</p>
      </div>
      <div class="countdown">
        <div class="countdown__block">
          <div class="countdown__digit">{{ countDownTimer.hours }}</div>
          <div class="countdown__text">Hrs</div>
        </div>
        <div class="countdown__block">
          <div class="countdown__digit">{{ countDownTimer.minutes }}</div>
          <div class="countdown__text">Min</div>
        </div>
        <div class="countdown__block">
          <div class="countdown__digit">{{ countDownTimer.seconds }}</div>
          <div class="countdown__text">Sec</div>
        </div>
        <div class="countdown__button">
          <ui-button
            size="small"
            color="default"
            class="past-due-button"
            @click="sendToPlans()"
            >Secure Deal</ui-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script type='text/javascript'>
import userMixin from '@/mixins/user'
import helperMixin from '@/mixins/helper'
import moment from 'moment'

export default {
  mixins: [userMixin, helperMixin],
  data: function () {
    return {
      countDownTimer: {
        hours: null,
        minutes: null,
        seconds: null
      }
    }
  },
  mounted: function () {
    let startDateTime = moment(this.user.created_at).format('YYYY-MM-DDTHH:mm')
    let endDateTime = moment(startDateTime).add(1, 'day').format('YYYY-MM-DDTHH:mm')
    let countDownDate = new Date(endDateTime).getTime()

    this.updateCountDownTimer(countDownDate)
  },
  methods: {
    sendToPlans: function () {
      this.$router.push({ name: 'plans.index' })
    },
    updateCountDownTimer: function (countDownDate) {
      let interval = setInterval(() => {
        let now = new Date().getTime()
        let distance = countDownDate - now

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24))
        this.countDownTimer.seconds = Math.floor((distance % (1000 * 60)) / 1000) // seconds
        this.countDownTimer.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) // minutes
        this.countDownTimer.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + days * 24) // hours

        // If the count down is over
        if (distance < 0) {
          clearInterval(interval)
          this.countDownTimer.seconds = this.countDownTimer.minutes = this.countDownTimer.hours = 0
        }
      }, 1000)
    }
  }
}
</script>
