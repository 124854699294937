import Vue from 'vue'
import { store } from '../store/store'
import * as actionTypes from '@/store/action-types'

import MainApp from '@/MainApp'

import Front from '@/components/containers/Front'
import Dash from '@/components/containers/Dash'
import Auth from '@/components/containers/Auth'

// login related
import Login from '@/views/Login'
import TokenLogin from '@/views/TokenLogin'
import VerifyEmail from '@/views/VerifyEmail'
import VerifyEmailForm from '@/components/authentication/VerifyEmailForm'
import ResetPasswordForm from '@/views/ResetPasswordForm'
import ForgotPassword from '@/components/authentication/ForgotPassword'

// register related
import Register from '@/views/Register'
import RegisterTranslate from '@/views/RegisterTranslate'

// dashboard & onboarding
import Home from '@/views/Home'
import Dashboard from '@/views/Dashboard'
import IndustryOnBoard from '@/components/authentication/IndustryOnboard'

// form related
import FormCreate from '@/views/FormCreate'
import FormVariantCreate from '@/views/FormVariantCreate'
import FormEmbed from '@/components/FormEmbed'
import FormSettings from '@/components/FormSettings'
import FormLeads from '@/components/FormLeads';
import AnswerBased from '@/components/form-builder/Elements/AnswerBased.vue'
import VisitorBased from "@/components/form-builder/Elements/VisitorBased";

import FormsListView from '@/views/FormsListView'
import FormOverview from '@/views/FormOverview'
import FormDetail from '@/views/FormDetail'
import FormTrackingEvents from '@/views/FormDetail/FormTrackingEvents'
import FormVariantEdit from '@/views/FormVariantEdit'
import FormExperimentsOverview from '@/views/FormExperimentsOverview'
import FormExperimentDetail from '@/views/FormExperimentDetail'
import FormShare from '@/views/FormShare'

/** Form integrations */
import ConnectionsList from '@/views/ConnectionsList'
import FormConnectionsTab from '@/views/FormConnectionsTab'
// import FormConnections from '@/views/FormConnections'
import FormConnections from '../components/FormConnections.vue'
import FormCreateConnection from '@/views/FormCreateConnection'
import FormEditConnection from '@/views/FormEditConnection'

/* User Related */
import UserList from '@/views/UserList'
import UserEdit from '@/views/UserEdit'
import UserProfile from '@/views/UserProfile'

// import LandingPageCreate from '@/views/landingpage/LandingPageCreate'
// import LandingPageEdit from '@/views/landingpage/LandingPageEdit'
// import LandingPageList from '@/views/landingpage/LandingPageList'
// import LandingPageView from '@/views/landingpage/LandingPageView'

/* Plans & billing */
import Subscription from '@/views/Subscription'
import Billing from '@/views/Billing'

import chatPage from '@/views/chatPage'
import PageNotFound from '@/views/PageNotFound'
import Help from '@/views/Help'

import Settings from '@/views/Settings'
import PartialLeads from '@/views/PartialLeads'

import BlacklistIps from '@/views/BlacklistIps'
import GoogleRecaptchaKeysList from '@/views/GoogleRecaptchaKeysList'

/* Lead Proof */
import LeadProofManage from '@/components/LeadProofManage'
import LeadProofList from '@/views/LeadProofList'

/* External Checkout */
import ExternalCheckoutManage from '@/components/ExternalCheckoutManage'
import ExternalCheckoutList from '@/views/ExternalCheckoutList'

/* Template */
import FormCreateOption from '@/views/FormCreateOption'
import FormBrowseTemplate from '@/views/FormBrowseTemplate'
import FormTemplateList from '@/views/FormTemplateList'
import FormTemplateBuilder from '@/components/form-template-build/FormTemplateBuilder'

/* User settings */
import UserSettings from '@/views/UserSettings'

/* Package Builder */
import PackageBuilderList from '@/views/PackageBuilderList'
import PackageBuilder from '@/components/PackageBuilder'

// import FormReport from '@/views/FormReport'

/* UTM Params */
import UtmParamsManager from '@/components/UtmParamsManager'
import UtmParams from '@/views/UtmParamsList'


import { createRouter, createWebHistory } from 'vue-router'

const router = new createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "",
      component: MainApp,
      children: [
        // frontend
        {
          path: "/",
          component: Front,
          children: [
            {
              path: "",
              name: "home",
              component: Home,
              meta: { showWhenLoggedIn: true },
            },
          ],
        },
        // login related
        {
          path: "/",
          component: Auth,
          children: [
            {
              path: "login",
              name: "login",
              component: Login,
              meta: {
                showWhenLoggedIn: false,
                title: "Log In – LeadGen App",
                description:
                  "Click here to log in to your LeadGen App account and access your online forms and leads.",
              },
            },
            {
              path: "verify-email-form",
              name: "verifyEmailForm",
              component: VerifyEmailForm,
              meta: { showWhenLoggedIn: false },
            },
            {
              path: "verify-email/:userId/:token",
              name: "verifyEmail",
              component: VerifyEmail,
              meta: { showWhenLoggedIn: true },
            },
            {
              path: "forgot-password-form",
              name: "forgot-password-form",
              component: ForgotPassword,
              meta: {
                showWhenLoggedIn: false,
                title: "Forgot Password – LeadGen App",
              },
            },
            {
              path: "reset-password-form/:userId/:token",
              name: "resetPasswordForm",
              component: ResetPasswordForm,
              meta: { showWhenLoggedIn: true },
            },
            {
              path: "token/login",
              name: "tokenLogin",
              component: TokenLogin,
              meta: {
                showWhenLoggedIn: true,
                title: "Log In – LeadGen App",
                description:
                  "Log In Here To Your LeadGen App Account – The Fastest Way To Create Lead Capture And Multi-step Forms For Marketers.",
              },
            },
          ],
        },
        // register related
        {
          path: "/",
          component: Register,
          meta: {
            requiresAuth: false,
            showWhenLoggedIn: false,
            title: "Create Your Free Account – LeadGen App",
            description:
              "Get Started With LeadGen App To Increase Your Lead Capture Conversions With Better Online Forms. Sign Up Here For A Free Account To Test It Out Yourself.",
          },
          children: [
            {
              path: "register",
              name: "register",
              // beforeEnter: (to, from, next) => {
              //   window.location.href = 'https://leadgenapp.io/pricing/'
              // },
            },
            {
              path: "register-pro-trial-annual",
              name: "registerProAnnualTrial",
            },
            {
              path: "register-scale-trial-annual",
              name: "registerScaleAnnualTrial",
            },
            {
              path: "register-enterprise-trial-annual",
              name: "registerEnterpriseAnnualTrial",
            },
            {
              path: "register-pro-trial",
              name: "registerProMonthlyTrial",
            },
            {
              path: "register-scale-trial",
              name: "registerScaleMonthlyTrial",
            },
            {
              path: "register-enterprise-trial",
              name: "registerEnterpriseMonthlyTrial",
            },
            {
              path: "register-free-trial",
              name: "registerFreeTrial",
              meta: {
                title: "Register to Create Your LeadGen App Account",
                description:
                  "Sign up and create your own LeadGen App account to start creating high-converting forms and capture more leads.",
              },
            },
            {
              path: "register-pro",
              name: "registerProMonthly",
            },
            {
              path: "register-starter",
              name: "registerStarterMonthly",
            },
            {
              path: "register-scale",
              name: "registerScaleMonthly",
            },
            {
              path: "register-enterprise",
              name: "registerEnterpriseMonthly",
            },
            {
              path: "register-pro-annual",
              name: "registerProAnnual",
            },
            {
              path: "register-scale-annual",
              name: "registerScaleAnnual",
            },
            {
              path: "register-enterprise-annual",
              name: "registerEnterpriseAnnual",
            },
            {
              path: "register-single-annual",
              name: "registerSingleAnnual",
            },
          ],
        },
        {
          path: "",
          component: RegisterTranslate,
          children: [
            {
              path: "/enregistrer-essai-gratuit",
              name: "registerFreeTrialFr",
            },
            {
              path: "/enregistrer-pro",
              name: "registerProMonthlyFr",
            },
            {
              path: "/registre-entree",
              name: "registerStarterMonthlyFr",
            },
            {
              path: "/registro-arrancadora",
              name: "registerStarterMonthlyEs",
            },
            {
              path: "/registrieren-starter",
              name: "registerStarterMonthlyDe",
            },
            {
              path: "/registro-iniciante",
              name: "registerStarterMonthlyPt",
            },
            {
              path: "/enregistrer-scale",
              name: "registerScaleMonthlyFr",
            },
            {
              path: "/enregistrer-entreprise",
              name: "registerEnterpriseMonthlyFr",
            },
            {
              path: "/enregistrer-pro-annuel",
              name: "registerProAnnualFr",
            },
            {
              path: "/enregistrer-scale-annuel",
              name: "registerScaleAnnualFr",
            },
            {
              path: "/enregistrer-entreprise-annuel",
              name: "registerEnterpriseAnnualFr",
            },
            {
              path: "/registrar-prueba-gratuita",
              name: "registerFreeTrialEs",
            },
            {
              path: "/registrar-pro",
              name: "registerProMonthlyEs",
            },
            {
              path: "/registrar-escala-mensual",
              name: "registerScaleMonthlyEs",
            },
            {
              path: "/registrar-empresa-mensual",
              name: "registerEnterpriseMonthlyEs",
            },
            {
              path: "/registrar-pro-anual",
              name: "registerProAnnualEs",
            },
            {
              path: "/registrar-escala-anual",
              name: "registerScaleAnnualEs",
            },
            {
              path: "/registrar-empresa-anual",
              name: "registerEnterpriseAnnualEs",
            },
            {
              path: "registrieren-kostenlose-testversion",
              name: "registerFreeTrialDt",
            },
            {
              path: "/registrieren-pro",
              name: "registerProMonthlyDt",
            },
            {
              path: "/registrieren-scale",
              name: "registerMonthlyScaleDt",
            },
            {
              path: "/registrieren-entreprise",
              name: "registerEnterpriseMonthlyDt",
            },
            {
              path: "/registrieren-pro-jahrlich",
              name: "registerProAnnualDt",
            },
            {
              path: "/registrieren-scale-jahrlich",
              name: "registerScaleAnnualDt",
            },
            {
              path: "/registrieren-entreprise-jahrlich",
              name: "registerEnterpriseAnnuaDtl",
            },
            {
              path: "/registro-teste-gratuito",
              name: "registerFreeTrialPt",
            },
            {
              path: "/registro-pro",
              name: "registerProMonthlyPt",
            },
            {
              path: "/registro-escala-mensal",
              name: "registerScaleMonthlyPt",
            },
            {
              path: "/registro-empresa-mensal",
              name: "registerEnterpriseMonthlyPt",
            },
            {
              path: "/registro-pro-anual",
              name: "registerProAnnualPt",
            },
            {
              path: "/registro-escala-anual",
              name: "registerScaleAnnualPt",
            },
            {
              path: "/registro-empresa-anual",
              name: "registerEnterpriseAnnualPt",
            },
          ],
        },
        // onboarding
        {
          path: "onboard",
          name: "industry-onboard",
          component: IndustryOnBoard,
          meta: {
            showWhenLoggedIn: true,
            title: "Welcome – LeadGen App",
            description:
              "Welcome Here To Your LeadGen App Account – The Fastest Way To Create Lead Capture And Multi-step Forms For Marketers.",
          },
        },
        // dashboard
        {
          path: "/dashboard",
          component: Dash,
          meta: { requiresAuth: true },
          children: [
            {
              path: "",
              name: "dashboard",
              component: Dashboard,
            },
            {
              path: "/users",
              name: "users.index",
              component: UserList,
            },
            {
              path: "/users/:id/edit",
              name: "users.edit",
              component: UserEdit,
            },
            {
              path: "/forms",
              name: "forms.index",
              component: FormsListView,
            },
            {
              path: "/forms/share",
              name: "form.share",
              component: FormShare,
            },
            {
              path: "/forms/:id/detail",
              component: FormDetail,
              children: [
                {
                  path: "connections",
                  component: FormConnectionsTab,
                  props: (route) => ({ formId: parseInt(route.params.id) }),
                  children: [
                    {
                      path: "",
                      name: "forms.detail.connectionstab.list",
                      component: FormConnections,
                      props: (route) => ({ formId: parseInt(route.params.id) }),
                    },
                    {
                      path: "create/:connectionType?",
                      name: "forms.detail.connectionstab.create",
                      component: FormCreateConnection,
                      props: (route) => ({ formId: parseInt(route.params.id) }),
                    },
                    {
                      path: "edit/:connectionId/:connectionType",
                      name: "forms.detail.connectionstab.edit",
                      component: FormEditConnection,
                      props: (route) => ({ formId: parseInt(route.params.id) }),
                    },
                  ],
                },
                {
                  path: "",
                  name: "forms.overview",
                  component: FormOverview,
                  props: (route) => ({ formId: parseInt(route.params.id) }),
                },
                {
                  path: "/forms/:id/share",
                  name: "forms.share",
                  component: FormEmbed,
                },
                {
                  path: "/forms/:id/settings",
                  name: "forms.settings",
                  component: FormSettings,
                  props: (route) => ({ formId: parseInt(route.params.id) }),
                },
                {
                  path: "/forms/:id/leads",
                  name: "forms.leads",
                  component: FormLeads,
                  props: (route) => ({ formId: parseInt(route.params.id) }),
                },
                {
                  path: "/forms/:id/tracking-events",
                  name: "forms.trackingEvents",
                  component: FormTrackingEvents,
                  props: (route) => ({ formId: parseInt(route.params.id) }),
                },
                {
                  path: "/forms/:id/experiments",
                  name: "forms.experiments.overview",
                  component: FormExperimentsOverview,
                },
                {
                  path: "/forms/:id/experiments/:experimentId",
                  name: "forms.experiments.detail",
                  component: FormExperimentDetail,
                },
              ],
            },
            {
              path: "/googlerecaptchakeys",
              name: "googlerecaptchakeys",
              component: GoogleRecaptchaKeysList,
            },
            {
              path: "/connections-list",
              name: "connections.list",
              component: ConnectionsList,
            },
            {
              path: "/plans",
              name: "plans.index",
              component: Subscription,
            },
            {
              path: "/billing",
              name: "billing.index",
              component: Billing,
            },
            {
              path: "/lead-proofs-list",
              name: "leadproofs.index.list",
              component: LeadProofList,
            },
            {
              path: "/lead-proofs-create",
              name: "leadproofs.create",
              component: LeadProofManage,
            },
            {
              path: "/lead-proofs/:id/edit",
              name: "leadproofs.edit",
              component: LeadProofManage,
            },
            {
              path: "/external-checkout-list",
              name: "externalcheckout.index.list",
              component: ExternalCheckoutList,
            },
            {
              path: "/external-checkout-create",
              name: "externalcheckout.create",
              component: ExternalCheckoutManage,
            },
            {
              path: "/external-checkout/:id/edit",
              name: "externalcheckout.edit",
              component: ExternalCheckoutManage,
            },
            {
              path: "/help",
              name: "help.index",
              component: Help,
            },
            {
              path: "/partial-leads",
              name: "partialleads",
              component: PartialLeads,
            },
            {
              path: "/user-profile",
              name: "user.profile",
              component: UserProfile,
            },
            {
              path: "/settings",
              name: "settings",
              component: Settings,
            },
            {
              path: "/blacklist-ips",
              name: "blacklist.ips",
              component: BlacklistIps,
            },
            {
              path: "/template-builder-list",
              name: "template-builder.index.list",
              component: FormTemplateList,
            },
            {
              path: "/template-builder-create",
              name: "template-builder.create",
              component: FormTemplateBuilder,
            },
            {
              path: "/template-builder/:id/edit",
              name: "template-builder.edit",
              component: FormTemplateBuilder,
            },
            {
              path: "/user-settings",
              name: "user-settings",
              component: UserSettings,
            },
            {
              path: "/package-builder-list",
              name: "package-builder.index.list",
              component: PackageBuilderList,
            },
            {
              path: "/package-builder-create",
              name: "package-builder.create",
              component: PackageBuilder,
            },
            {
              path: "/package-builder/:id/edit",
              name: "package-builder.edit",
              component: PackageBuilder,
            },
            // {
            //   path: '/user-form-report',
            //   name: 'user-form.report',
            //   component: FormReport
            // },
            {
              path: "/utm-params",
              name: "utmparams.index.list",
              component: UtmParams,
            },
            {
              path: "/utm-params-create",
              name: "utmparams.create",
              component: UtmParamsManager,
            },
            {
              path: "/utm-params/:id/edit",
              name: "utmparams.edit",
              component: UtmParamsManager,
            },
          ],
        },
        // chat
        {
          path: "/chat",
          name: "chat",
          component: chatPage,
        },
        // form/variant create/edit
        {
          path: "/forms/create",
          name: "forms.create",
          component: FormCreate,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/create/option",
          name: "forms.create.option",
          component: FormCreateOption,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/choose/template/:industry?",
          name: "forms.choose.template",
          component: FormBrowseTemplate,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/:id/variants/:variantId/edit/:tab?",
          name: "forms.variants.edit",
          component: FormVariantEdit,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/:id/variants/:variantId/edit/formendings/answerbased/create",
          name: "forms.variants.answerbased.create",
          component: AnswerBased,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/:id/variants/:variantId/edit/formendings/answerbased/:endingId/edit",
          name: "forms.variants.answerbased.edit",
          component: AnswerBased,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/:id/variants/:variantId/edit/formendings/visitorbased/create",
          name: "forms.variants.visitorbased.create",
          component: VisitorBased,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/:id/variants/:variantId/edit/formendings/visitorbased/:endingId/edit",
          name: "forms.variants.visitorbased.edit",
          component: VisitorBased,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/:id/variants/:variantId/edit/formendings",
          name: "forms.variants.edit.conditions",
          component: FormVariantEdit,
          meta: { requiresAuth: true },
        },
        {
          path: "/forms/:id/variants/create",
          name: "forms.variants.create",
          component: FormVariantCreate,
          meta: { requiresAuth: true },
        },
        // landing page
        // {
        //   path: '/landingpages',
        //   name: 'landingpages.index',
        //   component: LandingPageList,
        // },
        // {
        //   path: '/landingpages/create',
        //   component: LandingPageCreate,
        //   name: 'landingpages.create',
        //   meta: { requiresAuth: true }
        // },
        // {
        //   path: '/landingpages/:id/edit',
        //   component: LandingPageEdit,
        //   name: 'landingpages.edit',
        //   meta: { requiresAuth: true }
        // },
        // {
        //   path: '/lp/:id/:title?',
        //   component: LandingPageView,
        //   name: 'landingpages.show',
        //   meta: { showWhenLoggedIn: true }
        // },
      ],
    },
    // 404
    {
      path: "/:catchAll(.*)",
      component: PageNotFound,
    },
  ],
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'LeadGen'
  let meta = document.querySelector('meta[name=description]')

  if (meta && to.meta.description) {
    meta.content = to.meta.description
  }

  setTimeout(() => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      console.log('private route')
      // protected routes
      if (!store?.getters?.isLoggedIn) {
        localStorage.setItem(
          'private_url',
          JSON.stringify({ name: to.name, params: to.params })
        )

        if (store.state.token) {
          // auto logout
          store.dispatch(actionTypes.LOGOUT_USER).then((result) => {
            next({
              path: '/login'
            })
          })
        } else {
          next({
            path: '/login'
          })
        }
      } else {
        next()
      }
    } else {
      // public routes
      if (!store?.getters?.isLoggedIn) {
        next()
      } else {
        if (to.matched.some((record) => record.meta.showWhenLoggedIn)) {
          next()
        } else {
          next({
            path: '/dashboard'
          })
        }
      }
    }
  }, 0)
})

export default router
