<template>
  <v-container class="form-leads-view" v-if="flag.pageLoader">
    <!-- FORM SETTINGS LOADER-->
    <div class="form-settings-view__loading" v-if="!flag.pageError">
      <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
        color="secondary-red"></v-progress-circular>
    </div>
    <!-- FORM SETTINGS LOAD ERROR-->
    <div class="form-settings-view__error" v-else>
      <h3>Whoops, looks like something went wrong. Please click the button to try again.</h3>
      <v-btn variant="elevated" color="primary-red" class="text-none font-weight-bold" rounded="md" size="large"
        prepend-icon="mdi-reload" @click="reloadPage()">
        Reload
      </v-btn>
    </div>
  </v-container>
  <v-container class="form-leads-view" v-else>

    <!-- layout for download & filtering -->
    <v-row>
      <v-col cols="12" md="4">
        <v-select label="Filter by lead type" variant="outlined" density="comfortable" hide-details chips closable-chips
          multiple :items="leadTypes" item-title="label" item-value="value" v-model="leadsGroup">
        </v-select>
      </v-col>
      <v-col cols="12" md="4">
        <v-select class="ml-1" label="Filter by variants" placeholder="Search variant or choose below"
          variant="outlined" density="comfortable" hide-details chips :items="variants" item-value="id"
          item-title="title" v-model="selectedVariant" @update:modelValue="filterVariants">
        </v-select>
      </v-col>
      <v-col cols="12" md="3" v-if="experiments.length === 0"></v-col>
      <v-col cols="12" md="3" v-if="experiments.length > 0">
        <v-select class="ml-1" label="Filter by experiment" placeholder="Search or choose below" variant="outlined"
          density="comfortable" hide-details chips :items="experiments" item-value="id" item-title="title"
          v-model="selectedExperiment" @update:modelValue="filterExperiments">
        </v-select>
      </v-col>
      <v-col cols="12" md="1" class="d-flex justify-end align-end">
        <v-speed-dial location="bottom right" transition="slide-x-reverse-transition">
          <template v-slot:activator="{ props: activatorProps }">
            <v-btn color="tertiary-blue" v-bind="activatorProps" icon="mdi-cloud-download" size="small"
              title="Download Leads" class="mb-1" height="45" width="45" />
          </template>
          <v-btn key="1" icon="mdi-code-json" color="light-blue" size="small" title="Download JSON"
            :loading="flag.loadingJson" @click="exportLeads('json')" />
          <v-btn key="2" icon="mdi-file-delimited" color="blue" size="small" title="Download CSV"
            :loading="flag.loadingCsv" @click="exportLeads('csv')" />
        </v-speed-dial>
      </v-col>
    </v-row>

    <!-- layout for banner  -->
    <v-row class="py-1" v-if="selectedVariant && selectedVariant.deleted_at">
      <v-banner lines="one" stacked color="warning" class="alert-banner border-0" icon="mdi-alert-decagram"
        rounded="md">
        <template v-slot:text>
          <span class="text-black">This list is from an archived form variant which has been deleted. Do
            you also want to delete this list?</span>
        </template>
        <template v-slot:actions>
          <v-btn rounded="md" variant="elevated" class="font-weight-bold text-none mb-1" color="primary-red"
            prepend-icon="mdi-delete-forever" @click="flag.isTrashDeleteModal = true">
            <span>Delete List</span>
          </v-btn>
        </template>
      </v-banner>
    </v-row>

    <!-- layout for leads/partial data-table -->
    <v-row no-gutters align="center" class="mt-10 bg-grey-lighten-5">
      <v-col cols="12">
        <!-- Data Table for Leads & Partial Leads-->
        <v-data-table :headers="tableHeaders" :items="leads" :items-per-page="paginate.items"
          @update:page="paginate.current = $event" :page="paginate.current" item-key="id" :loading="flag.tableLoader"
          show-expand single-expand :expanded.sync="expanded" loading-text="Loading... Please wait"
          :no-data-text="leadsGroup.length === 0 ? 'Please select the lead type filter above to see leads' : 'This form has not generated any leads yet'"
          class="border-thin rounded">

          <template width="100%" v-slot:loader>
            <v-progress-linear color="secondary-red" indeterminate height="2"></v-progress-linear>
          </template>

          <!-- S.No -->
          <template v-slot:item.id="{ item, index }">
            {{ calculateIndex(index) }}
          </template>

          <!-- Submitted -->
          <template v-slot:item.submitted="{ item }">
            {{ numberOfDays(item.created_at) }}
          </template>

          <!-- Exact Date (created_at) -->
          <template v-slot:item.created_at="{ item }">
            {{ formattedDate(item.created_at) }}
          </template>

          <!-- Lead Type -->
          <template v-slot:item.leadType="{ item }">
            <span class="lg-badge" :class="item.is_partial ? 'bg-light-blue' : 'bg-tertiary-blue'">
              {{ item.is_partial ? 'Partial' : 'Lead' }}
            </span>
          </template>

          <!-- Source URL -->
          <template v-slot:item.form_visit.source_url="{ item }">
            <a class="text-secondary-red" target="_blank" :href="item.form_visit.source_url">{{
              item.form_visit.source_url
              }}</a>
          </template>

          <!-- Expanded Slot to show questions and responses -->
          <template v-slot:expanded-row="{ columns, item }">
            <td :colspan="columns.length">
              <v-row v-if="item">
                <v-col cols="12">
                  <v-card variant="outlined" class="border-thin bg-grey-lighten-5">
                    <v-card-title class="bg-blue-lighten-5 text-primary-blue">
                      <h6 class="d-flex justify-center mb-0">
                        <v-icon icon="mdi-list-box" class="pt-1"></v-icon>
                        <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Lead Details</p>
                      </h6>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text>
                      <!-- question & their responses -->
                      <v-row no-gutters>
                        <v-col cols="12" sm="3">
                          <span class="text-tertiary-blue font-weight-bold text-uppercase">
                            Form Question Responses:
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="4" v-for="question in questions" :key="question.id">
                          <span class="text-black font-weight-bold">
                            {{ question.config.title }}
                          </span>
                          <div class="text-grey-darken-2" v-if="
                            question.config.type === questionTypesMap.PARAGRAPH_TEXT"
                            v-html="nl2Br(questionResponse(question, item))"></div>
                          <div class="text-grey-darken-2" v-else-if="question.config.type === questionTypesMap.ADDRESS">
                            <div v-for="(addressFieldValue, addressFieldKey) in questionResponse(question, item)"
                              :key="addressFieldKey">
                              <b v-if="addressFieldValue" class="text-grey-darken-4">
                                {{ addressFieldKey }}:</b> {{ addressFieldValue }}
                            </div>
                          </div>
                          <div class="text-grey-darken-2" v-else>
                            {{ questionResponse(question, item) }}
                          </div>
                        </v-col>
                      </v-row>

                      <v-divider class="my-2"></v-divider>

                      <!-- hidden fields responses -->
                      <v-row no-gutters>
                        <v-col cols="12" sm="3">
                          <span class="text-tertiary-blue font-weight-bold text-uppercase">
                            Form Hidden Fields:
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="4" v-for="hiddenField in hiddenFields" :key="hiddenField.id">
                          <span class="text-black font-weight-bold">
                            <b>Hidden Field: </b>{{ hiddenField.name }}
                          </span>
                          <div class="text-grey-darken-2" v-html="hiddenFieldResponse(hiddenField, item)">
                          </div>
                        </v-col>
                      </v-row>

                      <v-divider class="my-2"></v-divider>

                      <!-- utm responses -->
                      <v-row no-gutters>
                        <v-col cols="12" sm="3">
                          <span class="text-tertiary-blue font-weight-bold text-uppercase">
                            UTM Parameters:
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="4" v-for="(utm, key) in item.form_utm_params" :key="key">
                          <span class="text-black font-weight-bold">
                            {{ formatTitle(key) }}
                          </span>
                          <div class="text-grey-darken-2 text-start">
                            {{ utm }}
                          </div>
                        </v-col>
                      </v-row>

                      <v-divider class="my-2"></v-divider>

                      <!-- other responses -->
                      <v-row no-gutters>
                        <v-col cols="12" sm="3">
                          <span class="text-tertiary-blue font-weight-bold text-uppercase">
                            Other Details:
                          </span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="4">
                          <span class="text-black font-weight-bold">
                            Device
                          </span>
                          <div class="text-grey-darken-2">
                            {{ item.form_visit.device_type }}
                          </div>
                        </v-col>
                        <v-col cols="12" md="4">
                          <span class="text-black font-weight-bold">
                            Browser
                          </span>
                          <div>
                            {{ item.form_visit.browser }}
                          </div>
                        </v-col>
                        <v-col cols="12" md="4">
                          <span class="text-black font-weight-bold">
                            IP Address
                          </span>
                          <div class="text-grey-darken-2">
                            {{ item.form_visit.ip }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="4">
                          <span class="text-black font-weight-bold">
                            Submitted
                          </span>
                          <div class="text-grey-darken-2">
                            {{ numberOfDays(item.created_at) }} at {{ localeTime(item.created_at) }}
                          </div>
                        </v-col>
                        <v-col cols="12" md="4">
                          <span class="text-black font-weight-bold">
                            Certificate URL
                          </span>
                          <div>
                            <a class="text-secondary-red" target="_blank" :href="item.contactstate_cert_url">{{
                              item.contactstate_cert_url
                              }}</a>
                          </div>
                        </v-col>
                        <v-col cols="12" md="4">
                          <span class="text-black font-weight-bold">
                            Calculator Result
                          </span>
                          <div class="text-grey-darken-2">
                            {{ !item.calculator_total & item.calculator_total !== 0 ? '' : item.calculator_total }}
                          </div>
                        </v-col>
                        <v-col cols="12" md="4">
                          <span class="text-black font-weight-bold">
                           UUID
                          </span>
                          <div class="text-grey-darken-2">
                            {{ item.reference_no}}
                          </div>
                        </v-col>
                      </v-row>

                      <v-divider class="my-2"></v-divider>

                      <!-- danger zone -->
                      <v-row no-gutters>
                        <v-col cols="12" sm="3">
                          <span class="text-tertiary-blue font-weight-bold text-uppercase">
                            Danger Zone:
                          </span>
                        </v-col>
                        <v-col cols="12" sm="2"></v-col>
                        <v-col cols="12" sm="3">
                          <v-btn class="text-none font-weight-bold" color="primary-red" variant="flat"
                            prepend-icon="mdi-delete" @click="openLeadDeleteModal(item.id)">
                            <span>Delete</span>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="12">
                  <p>Error: Unable to load lead details.</p>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- Delete Lead Modal -->
    <v-dialog v-model="flag.isLeadDeleteModal" max-width="600">
      <v-card max-width="600" prepend-icon="mdi-trash-can-outline">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-card-text>
          <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">You are about to delete this lead data. Are you sure you
              want to proceed?</p>
          </v-alert>
        </v-card-text>
        <v-card-actions class="mb-3 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="flag.isLeadDeleteModal = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="primary-red"
            @click="deleteLead(this.currentLeadId)" :loading="flag.loadingDelete">
            <span>Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Trashed data Modal -->
    <v-dialog v-model="flag.isTrashDeleteModal" max-width="600">
      <v-card max-width="600" prepend-icon="mdi-trash-can-outline">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-card-text>
          <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">By deleting you will lose all the data in this list. Are you sure you
              want to proceed?</p>
          </v-alert>
        </v-card-text>
        <v-card-actions class="mb-3 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Keep Data"
            @click="flag.isTrashDeleteModal = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="primary-red"
            @click="deleteTrashed(selectedVariant.id)" :loading="flag.loadingDelete">
            <span>Delete Data</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- layout for snackbar -->
    <v-snackbar v-model="snackbar.show" rounded="md" height="50" :color="snackbar.color" :timeout="3000">
      <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.message }} </p>
    </v-snackbar>

  </v-container>
</template>

<script>
import * as actionTypes from '../store/action-types'
import { mapGetters } from 'vuex'
import variantTypes from '@/store/variant-types'
import ApiConfig from '@/api/config.js' /* api configuration */
import moment from 'moment'
import { leadConsentTypes } from '../shared/constants/leadConsentTypes'
import { otherChoice } from '../shared/constants/formQuestionSkins'
import helper from '@/mixins/helper'
import userMixin from '@/mixins/user'
import packageBuilderMixin from '@/mixins/package-builder'

export default {
  mixins: [helper, userMixin, packageBuilderMixin],
  data() {
    return {
      routeName: 'forms.leads',
      leadsGroup: ['partial', 'leads'],
      formId: null,
      expanded: [],
      idVariant: null,
      selectedVariant: '',
      selectedExperiment: null,
      currentLeadId: null,
      paginate: {
        current: 1,
        items: 10,
        total: 0,
      },
      flag: {
        pageLoader: true,
        pageError: false,
        tableLoader: true,
        isLeadDeleteModal: false,
        isTrashDeleteModal: false,
        leadsLoaded: false,
        loadingCsv: false,
        loadingJson: false,
        loadingDelete: false
      },
      snackbar: {
        show: false,
        message: null,
        color: null
      },
      tableHeaders: [
        { title: 'S.No', value: 'id', sortable: false },
        { title: 'Submitted', value: 'submitted', sortable: false, width: '13%' },
        { title: 'Date', value: 'created_at', sortable: true, width: '15%' },
        { title: 'Lead Type', value: 'leadType', sortable: false },
        { title: 'IP Address', value: 'form_visit.ip', sortable: false, width: '17%' },
        { title: 'Source URL', value: 'form_visit.source_url', sortable: false, width: '25%' },
      ]
    }
  },
  mounted() {
    this.routeName = this.$route.name
    this.formId = this.$route.params.id
    this.fetchAllVariants()
    this.fetchFormExperiments()

    setTimeout(() => {
      this.packageBuilderMixin_canCreateLeadAndPartialLead()
    }, 2000)
  },
  methods: {
    reloadPage: function () {
      window.location.reload()
    },
    calculateIndex(index) {
      return (this.paginate.current - 1) * this.paginate.items + index + 1
    },
    fetchLeads() {
      this.$store.dispatch(actionTypes.FORM_FETCH_LEADS, {
        formId: this.formId,
        idVariant: this.selectedVariant ? this.selectedVariant.id : null,
        idExperiment: this.selectedExperiment ? this.selectedExperiment.id : null,
        page: this.paginate.current,
        perPage: this.paginate.items
      }).then((response) => {
        // Set total items for pagination
        this.paginate.total = response.data.pagination.total
        // Set current leads
        this.$store.dispatch(actionTypes.FORM_FETCH_LEADS_SUCCESS, {
          leads: response.data.data,
          formId: this.formId
        })
        setTimeout(() => {
          this.markLeadViewed()
        }, 50)

        this.flag.leadsLoaded = true
        this.flag.pageLoader = false
        this.flag.tableLoader = false
      }).catch((err) => {
        this.$store.dispatch(actionTypes.FORM_FETCH_LEADS_ERROR)
        this.flag.leadsLoaded = false
        this.flag.pageError = true
        this.flag.tableLoader = false
      })
    },
    fetchTrashedVariants(trashed = 1) {
      this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS, {
        formId: this.formId,
        params: { trashed: trashed }
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS_SUCCESS, response?.data?.data)
        this.fetchLeads()
      }).catch((err) => {
        let text = err.response?.data?.meta?.error_message || 'An error occurred while fetching form variants. Please try again.'
        this.showSnackbar(text, true)
      })
    },
    fetchAllVariants() {
      this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS, {
        formId: this.formId,
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS_SUCCESS, response?.data?.data)
        if (!this.selectedVariant) {
          this.selectChampionVariant(response?.data?.data)
        }

        this.fetchTrashedVariants()
      }).catch((err) => {
        let text = err.response?.data?.meta?.error_message || 'An error occurred while fetching form variants. Please try again.'
        this.showSnackbar(text, true)
      })
    },
    selectChampionVariant(variants) {
      const champion = variants.find(variant => variant.form_variant_type.type === variantTypes.CHAMPION)
      this.selectedVariant = champion || variants[0]
    },
    fetchFormExperiments() {
      this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENTS, {
        formId: this.formId
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENTS_SUCCESS, response.data.data)
      }).catch((err) => {
        let text = err.response?.data?.meta?.error_message || 'An error occurred while fetching form experiments. Please try again.'
        this.showSnackbar(text, true)
      })
    },
    filterVariants(value) {
      if (!value) {
        return
      }
      this.flag.tableLoader = true
      this.selectedVariant = this.variants.find(variant => variant.id === value)

      this.fetchLeads()
    },
    filterExperiments(value) {
      if (!value) {
        return
      }
      this.flag.tableLoader = true
      this.selectedExperiment = this.experiments.find(experiment => experiment.id === value)
      this.fetchLeads()
    },
    questionResponse: function (question, lead) {
      let response = ''
      for (let questionResponse of lead.question_responses) {
        if (question.id !== questionResponse.form_question_id) {
          continue
        }
        response = questionResponse.response
        if (question.config.type === this.questionTypesMap.DATE) {
          response = response ? (new Date(response)).toDateString() : ''
        } else if (question.config.type === this.questionTypesMap.SINGLE_CHOICE) {
          if (typeof response === 'object') {
            response = (response.type === otherChoice.OTHER) ? response.value : response.label
          }
        } else if (question.config.type === this.questionTypesMap.MULTIPLE_CHOICE) {
          if (Array.isArray(response)) {
            response = response.map(choice => {
              return (choice.type === otherChoice.OTHER) ? choice.value : choice.label
            }).join(', ')
          }
        } else if (question.config.type === this.questionTypesMap.GDPR) {
          if (Array.isArray(response) && typeof response[0] === 'string') {
            response = response.map((c, index) => 'option ' + (index + 1)).join(', ')
          } else if (Array.isArray(response) && typeof response[0] === 'object') {
            response = response.map((c, index) => 'option ' + (c.id)).join(', ')
          }
        }
      }
      return response
    },
    hiddenFieldResponse: function (hiddenField, lead) {
      const response = _.find(
        lead.hidden_field_responses,
        { form_hidden_field_id: hiddenField.id }
      )
      if (response && response.response && response.response.includes('cert.trustedform.com')) {
        return '<a target="_blank" class="text-secondary-red" href="' + response.response + '">' + response.response + '</a>'
      }
      return response ? response.response : ''
    },
    nl2Br: function (text) {
      return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
    },
    numberOfDays: function (previousDate) {
      return moment
        .utc(previousDate, 'YYYY-MM-DD HH:mm:ss')
        .local()
        .from(moment.now())
    },
    dateSubmitted: function (date) {
      let d = new Date(date)
      return d.toDateString()
    },
    formattedDate: function (date) {
      let d = new Date(date)
      return d.toDateString()
    },
    formatTitle: function (key) {
      return key.split('_').map((word, index) =>
        index === 0 ? word.toUpperCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      ).join(' ')
    },
    openLeadDeleteModal(leadId) {
      this.currentLeadId = leadId
      this.flag.isLeadDeleteModal = true
    },
    markLeadViewed() {
      this.$store.dispatch(actionTypes.MARK_READ_LEAD, {
        formVariantId: this.selectedVariant.id
      }).then((response) => {
      }, (err) => {
        this.$store.dispatch(actionTypes.MARK_READ_LEAD_ERROR, null)
      })
    },
    deleteLead: function (leadId) {
      this.flag.loadingDelete = true
      this.$store.dispatch(actionTypes.FORM_DELETE_LEAD, {
        leadId: leadId
      }).then((response) => {
        this.$store.dispatch(actionTypes.FORM_DELETE_LEAD_SUCCESS, {
          leadId: leadId,
          formId: this.formId
        })

        this.flag.loadingDelete = false
        this.flag.isLeadDeleteModal = false
        this.showSnackbar('Lead deleted successfully!', false)
        setTimeout(() => {
          this.packageBuilderMixin_canCreateLeadAndPartialLead()
        }, 2000)
      }, (err) => {
        this.flag.loadingDelete = false
        this.flag.isLeadDeleteModal = false
        let text = err.response?.data?.meta?.error_message || 'An error occurred while deleting this lead. Please try again.'
        this.showSnackbar(text, true)
      })
    },
    deleteTrashed(variantId) {
      this.flag.loadingDelete = true
      this.$store.dispatch(actionTypes.DELETE_FORM_LEAD, {
        formId: this.formId,
        variantId: variantId
      }).then((response) => {
        this.flag.loadingDelete = false
        this.flag.isTrashDeleteModal = false
        // Clear the selected variant and show success message
        this.selectedVariant = null

        this.showSnackbar('List deleted successfully!', false)

        this.flag.tableLoader = true
        this.fetchAllVariants()

        setTimeout(() => {
          this.packageBuilderMixin_canCreateLeadAndPartialLead()
        }, 2000)
        this.$store.dispatch(actionTypes.DELETE_FORM_LEAD_SUCCESS, response.data.data)
      }, (err) => {
        this.flag.loadingDelete = false
        this.flag.isTrashDeleteModal = false
        let text = err.response?.data?.meta?.error_message || 'An error occurred while deleting this list. Please try again.'
        this.showSnackbar(text, true)
        this.$store.dispatch(actionTypes.DELETE_FORM_LEAD_ERROR, null)
      })
    },
    showSnackbar(message, isError) {
      this.snackbar = {
        show: true,
        message: message,
        color: isError ? 'secondary-red' : 'primary-green'
      }
    },

    exportLeads(format) {
      let formId = parseInt(this.formId)
      let variantId = parseInt(this.selectedVariant.id)

      if (isNaN(formId) || isNaN(variantId)) {
        return
      }
      this.flag.loadingCsv = format === 'csv'
      this.flag.loadingJson = format === 'json'

      let dl = ApiConfig.basePath + `/forms/${formId}/variants/${variantId}/leads/export/${format}/`

      if (this.leadsGroup.length === 1) {
        if (this.leadsGroup[0] === leadConsentTypes.PARTIAL) {
          dl += 'partial'
        } else if (this.leadsGroup[0] === leadConsentTypes.LEADS) {
          dl += 'leads'
        }
      } else {
        dl += 'partial/leads'
      }

      dl += '?token=' + this.token
      let win = window.open(dl, '_blank')
      win.focus()
      this.flag.loadingCsv = false
      this.flag.loadingJson = false
    }
  },
  computed: {
    ...mapGetters([
      'formLeads',
      'questionTypesMap',
      'getFormVariants',
      'formExperiments',
      'token'
    ]),
    leadTypes() {
      return [
        { label: 'Partial Leads', value: 'partial' },
        { label: 'Leads', value: 'leads' }
      ]
    },
    questions: function () {
      let questions = []
      for (let lead of this.leads) {
        for (let questionResponse of lead.question_responses) {
          if (!_.find(questions, { id: questionResponse.form_question_id })) {
            questions.push(questionResponse.form_question)
          }
        }
      }
      return questions
    },
    leadConsentTypes: function () {
      return leadConsentTypes
    },
    hiddenFields: function () {
      let hiddenFields = []
      for (let lead of this.leads) {
        for (let hiddenFieldResponse of lead.hidden_field_responses) {
          if (!_.find(hiddenFields, { id: hiddenFieldResponse.form_hidden_field_id })) {
            hiddenFields.push(hiddenFieldResponse.form_hidden_field)
          }
        }
      }
      return hiddenFields
    },
    leads: function () {
      if (this.leadsGroup.length === 0) {
        return []
      }
      let leads = this.formLeads[this.formId] || []
      if (this.leadsGroup.length === 1) {
        if (this.leadsGroup[0] === leadConsentTypes.PARTIAL) {
          leads = leads.filter(lead => lead.is_partial)
        } else if (this.leadsGroup[0] === leadConsentTypes.LEADS) {
          leads = leads.filter(lead => !lead.is_partial)
        }
      }
      return leads
    },
    variants() {
      return this.$store.getters.getFormVariants(parseInt(this.formId)) || []
    },
    experiments() {
      return this.$store.getters.formExperiments(parseInt(this.formId)) || []
    }
  }
}
</script>
