<template>
  <v-container fluid class="w-100 h-100 pa-0 mb-0">
    <v-row class="register-view pa-0 ma-0">
      <!-- Left Part with Gradient Background -->
      <v-col cols="12" md="5" sm="12" xs="12" class="gradient-background">
        <div class="mx-auto d-flex flex-wrap flex-column justify-center pa-3 pa-sm-4 pa-md-5">
          <!-- Left Part Headings-->
          <div class="left-heading">
            <v-img :src="require('@/assets/images/icons/logo-register.svg')" :width="150">
            </v-img>
            <h2 class="mt-10 font-weight-bold text-white">Generate up to 5x</h2>
            <h3 class="mt-1 mb-2 text-white">More Leads with Online Forms</h3>
          </div>
          <br>
          <!-- Left Part Plan details-->
          <v-card rounded="md" color="#1d49c5" class="pa-3 pa-sm-4 pa-md-5" elevation="0">
            <v-card-text>
              <v-row class="text-center" v-if="screen.loader">
                <v-col cols="12">
                  <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
                    color="white"></v-progress-circular>
                </v-col>
              </v-row>
              <v-row v-else>
                <!-- plan title & price -->
                <v-col cols="9">
                  <span class="plan-title">{{ registerPlanDetails.title }}</span>
                </v-col>
                <v-col cols="3">
                  <span class="plan-title">{{ registerPlanDetails.price }}</span>
                </v-col>
                <!-- plan details -->
                <v-col cols="12">
                  <ul class="plan-features">
                    <li v-if="registerPlanDetails.isTrialPlan"><v-icon icon="mdi-circle-small"></v-icon>No credit card
                      required</li>
                    <li v-if="registerPlanDetails.isTrialPlan"><v-icon icon="mdi-circle-small"></v-icon>Full plan access
                      for 14 days</li>
                    <li><v-icon icon="mdi-circle-small"></v-icon>{{ registerPlanDetails.forms }}</li>
                    <li v-if="!registerPlanDetails.isFreeTrial"><v-icon icon="mdi-circle-small"></v-icon>{{
                      registerPlanDetails.leads }}</li>
                    <li><v-icon icon="mdi-circle-small"></v-icon>Upgrade to any plan inside the app</li>
                    <li v-if="!registerPlanDetails.isFreeTrial"><v-icon icon="mdi-circle-small"></v-icon>No Risk -
                      Cancel
                      anytime</li>
                  </ul>
                </v-col>
              </v-row>
              <!-- plan due & price -->
              <v-row v-if="!registerPlanDetails.isFreeTrial">
                <v-col cols="9">
                  <span class="plan-title">Due Today:</span>
                </v-col>
                <v-col cols="3">
                  <span class="plan-title">{{ registerPlanDetails.dueToday }}</span> <br>
                  <span class="pricing-info">{{ registerPlanDetails.pricingInfo }}</span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <br>
          <!-- Left Part Testimonials-->
          <v-timeline>
            <v-timeline-item size="small">
              <template v-slot:icon>
                <v-avatar :image="require('@/assets/images/views/register/testimonial.png')" :size="70"
                  variant="elevated"></v-avatar>
              </template>
              <v-card variant="flat" class="pa-2">
                <v-card-text class="pa-0">
                  <p class="">I have replaced all forms across many client websites to LeadGen
                    forms and
                    every single
                    one has seen an increase in enquiries.</p>
                  <span class="font-weight-bold text-uppercase">Carlos Luna, Digital Agency
                    Owner</span>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </div>
      </v-col>
      <!-- Right Part with Form -->
      <v-col cols="12" md="7" sm="12" xs="12"
        class="form-background d-flex flex-wrap flex-column align-center justify-center">
        <v-card class="register-card" rounded="sm" elevation="3" max-width="530" width="100%">
          <v-form ref="createAccountForm" @submit.prevent="validateForm">
            <v-stepper alt-labels v-model="screen.step">
              <!-- Customizable Stepper Header -->
              <v-stepper-header v-if="!registerPlanDetails.isFreeTrial">
                <v-stepper-item title="Step 1" :value="1" :complete="screen.step > 1" color="green"
                  :class="{ 'font-weight-bold': true, 'text-green': screen.step >= 1, 'text-grey-darken-1': screen.step < 1 }"
                  class="opacity-100"></v-stepper-item>
                <v-divider :thickness="3" :color="screen.step > 1 ? 'green' : 'grey-lighten-2'"
                  opacity="0.8"></v-divider>
                <v-stepper-item title="Step 2" :value="2" :complete="screen.step > 2"
                  :color="screen.step > 1 ? 'green' : 'grey-lighten-2'"
                  :class="{ 'font-weight-bold': true, 'text-green': screen.step >= 2, 'text-grey-darken-1': screen.step < 2 }"
                  class="opacity-100"></v-stepper-item>
                <v-divider :thickness="3" :color="screen.step > 2 ? 'green' : 'grey-lighten-2'"
                  opacity="0.8"></v-divider>
                <v-stepper-item title="Step 3" :value="3" :complete="screen.step > 3"
                  :color="screen.step > 2 ? 'green' : 'grey-lighten-2'"
                  :class="{ 'font-weight-bold': true, 'text-green': screen.step >= 3, 'text-grey-darken-1': screen.step < 3 }"
                  class="opacity-100"></v-stepper-item>
              </v-stepper-header>

              <!-- logo & title by default on all steps-->
              <v-row class="mt-10 mt-sm-5 px-3 px-sm-4 px-md-5">
                <v-col cols="12" class="text-center d-flex flex-column align-center" v-if="!screen.emailVerify">
                  <v-img :width="50" aspect-ratio="1" cover
                    :src="require('@/assets/images/leadgen-leaf-logo.png')"></v-img>
                  <h6 class="font-weight-bold my-2">Create Your Account</h6>
                </v-col>
                <v-col cols="12" class="text-center d-flex flex-column align-center" v-else>
                  <v-img :width="50" aspect-ratio="1" cover
                    :src="require('@/assets/images/views/register/envelop.png')"></v-img>
                  <h6 class="font-weight-bold my-2">Final Step: Verify Your Email
                  </h6>
                </v-col>
              </v-row>

              <!-- alert-->
              <v-row class="mt-10 mt-sm-5 px-10" v-if="flag.error">
                <v-col cols="12" class="d-flex flex-column align-center">
                  <v-alert border type="error" variant="tonal" rounded="md" density="compact" class="text-left w-100"
                    icon="mdi-alert-circle">
                    <span class="text-black mb-0">{{ flag.msg }}</span>
                  </v-alert>
                </v-col>
              </v-row>

              <!-- Stepper Content -->
              <!-- Step 1 Content -->
              <div v-if="screen.step === 1">
                <v-row class="mt-10 mt-sm-5 px-10" v-if="!screen.emailVerify">

                  <!-- choose email or google -->
                  <v-col cols="12" class="text-center d-flex flex-column align-center"
                    v-if="screen.step === 1 && !screen.emailRegister">
                    <!-- Google Button -->
                    <v-btn block color="#4285F4" height="45" class="google-btn" rounded="md" :loading="flag.googleBtn"
                      @click="loginWithGoogle">
                      <v-avatar size=" 43" color="white" class="google-icon-wrapper pa-2">
                        <v-img :width="100" aspect-ratio="1/1" contain
                          :src="require('@/assets/images/views/login/google-logo.png')"></v-img>
                      </v-avatar>
                      <span class="font-weight-bold text-none mb-0">Signup with Google</span>
                    </v-btn>
                    <!-- Email Button -->
                    <v-btn block color="grey-lighten-2" height="45" class="mt-5 email-btn font-weight-bold text-none"
                      rounded="md" variant="flat" :disabled="flag.googleBtn" :loading="flag.emailBtn"
                      @click="screen.emailRegister = true">
                      Signup with Email
                    </v-btn>
                  </v-col>

                  <!-- register form -->
                  <v-col cols="12" v-if="screen.step === 1 && screen.emailRegister">
                    <v-text-field prepend-inner-icon="mdi-account-box-plus-outline" variant="outlined"
                      density="comfortable" rounded="md" label="Enter your name" v-model="emailSignUp.name" :rules="$valid([
                        { rule: 'required', msg: 'Please enter your name' },
                        { rule: 'alphaSpaces' },
                        { rule: 'between', min: 3, max: 50 }
                      ])" clearable></v-text-field>

                    <v-text-field prepend-inner-icon="mdi-email-plus-outline" variant="outlined" density="comfortable"
                      rounded="md" label="Enter your email address" v-model="emailSignUp.email" :rules="$valid([
                        { rule: 'required', msg: 'Please enter your email address' },
                        { rule: 'email' }
                      ])" clearable></v-text-field>

                    <v-text-field variant="outlined" color="secondary-red" density="comfortable" rounded="md"
                      label="Set your password" prepend-inner-icon="mdi-lock-plus-outline"
                      :append-inner-icon="flag.password ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="flag.password ? 'text' : 'password'" @click:append-inner="flag.password = !flag.password"
                      v-model="emailSignUp.password" :rules="$valid([
                        { rule: 'required', msg: 'Please set your password' },
                        { rule: 'password' },
                        { rule: 'between', min: '8', max: '150' }
                      ])" clearable></v-text-field>

                    <v-btn color="primary-red" rounded="md" height="45" class="text-none font-weight-bold w-100"
                      @click="validateForm" :loading="flag.emailBtn">Next</v-btn>
                  </v-col>

                  <!-- declaration -->
                  <v-col cols="12" class="my-5">
                    <p class="text-grey-darken-1 text-center declaration text-caption text-sm-body-2">
                      By creating a LeadGen account, you're agreeing to accept the LeadGen
                      <a class="text-grey-darken-4" href="https://leadgenapp.io/terms-and-conditions/"
                        target="_blank">Terms&nbsp;&amp;&nbsp;Conditions </a>and
                      <a class="text-grey-darken-4" href="https://leadgenapp.io/privacy-policy/"
                        target="_blank">Privacy Policy</a>
                    </p>
                  </v-col>
                </v-row>
                <!-- div to be shown when email verification is enabled in case of free trial -->
                <v-row class="ma-5 px-5" v-else>
                  <p class="text-center">We sent an email to <strong class="text-primary-red">{{ screen.email
                      }}</strong>. Check your inbox to verify your
                    account.</p>
                </v-row>
              </div>

              <!-- Step 2 Content -->
              <!-- form step two - payment div if paid plan -->
              <div v-if="(screen.step === 2) || (screen.step === 3)">
                <div class="payment-div px-10 py-5"></div>
              </div>

              <!-- Step 4 Content -->
              <!-- loader -->
              <div v-if="screen.step === 4">
                <v-row class="text-center pa-10 ma-10">
                  <v-col cols="12">
                    <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
                      color="secondary-red"></v-progress-circular>
                  </v-col>
                </v-row>
              </div>

            </v-stepper>
            <!-- google recaptcha -->
            <div id="grecaptcha-register-form"></div>
          </v-form>
        </v-card>
        <a @click="sendToLogin()"
          class="my-5 text-decoration-none text-primary-red have-account text-body-2 text-sm-body-1">Already have an
          Account?</a>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as actionTypes from '../store/action-types'
import { mapGetters } from 'vuex'
import errorTypes from '@/store/error-types'
import config from '@/api/config'
import authMixin from '@/mixins/auth'
import planMixin from '@/mixins/plan'

export default {
  mixins: [authMixin, planMixin],
  data() {
    return {
      emailSignUp: {
        name: '',
        email: '',
        password: '',
        params: {},
        agreeTerms: true,
        agreeNewsletter: true,
        isEmailVerificationEnabled: false
      },
      screen: {
        step: 1,
        email: null,
        loader: true,
        emailRegister: false,
        emailVerify: false
      },
      flag: {
        password: false,
        emailBtn: false,
        googleBtn: false,
        chooseBtn: false,
        error: false,
        msg: null
      },
      recaptcha: {
        widgetId: null,
        response: null
      },
      registerPlanData: null,
      incompleteSignUp: null,
      registerPlanDetails: {}
    }
  },
  mounted: function () {
    this.initializeComponent()
  },
  computed: {
    ...mapGetters(['planTypes']),
    errorTypes: function () {
      return errorTypes
    },
    registerPlanDetails() {
      if (!this.registerPlanData) {
        return {
          title: 'LeadGen App',
          price: '$0.00 USD',
          dueToday: '$0.00 USD',
          pricingInfo: 'loading...',
          forms: '0',
          leads: '0',
          isFreeTrial: true,
          isTrialPlan: true
        }
      }

      const planTitle = this.registerPlanData.isMonthlyBilled
        ? this.registerPlanData.title
        : `${this.registerPlanData.title} Annual`

      let price = this.registerPlanData.price || '0.00'
      let dueToday = '0.00'

      if (!this.registerPlanData.isFreeTrial && !this.registerPlanData.isTrialPlan) {
        dueToday = this.registerPlanData.isMonthlyBilled || this.registerPlanData.isSinglePlan
          ? price
          : this.calculateYearlyPrice(price)
      }

      return {
        title: `LeadGen App ${planTitle}`,
        price: `$${price}${this.registerPlanData.isTrialPlan ? '' : ' USD/mo.'}`,
        dueToday: `$${dueToday} (USD)`,
        pricingInfo: this.registerPlanData.isMonthlyBilled ? '(billed monthly)' : '(billed yearly)',
        forms: `Create up to ${this.registerPlanData.forms} form${this.registerPlanData.forms === 1 ? '' : 's'}`,
        leads: `Get up to ${this.registerPlanData.leads} lead${this.registerPlanData.leads === 1 ? '' : 's'}`,
        isFreeTrial: this.registerPlanData.isFreeTrial,
        isTrialPlan: this.registerPlanData.isTrialPlan
      }
    }
  },
  methods: {
    resetRecaptcha: function () {
      this.recaptcha = {
        widgetId: null,
        response: null
      }
    },
    resetFlag: function () {
      this.flag = {
        password: false,
        emailBtn: false,
        googleBtn: false,
        chooseBtn: false,
        error: false,
        msg: null
      }
    },
    sendToLogin: function () {
      this.$router.push({ name: 'login' })
      setTimeout(() => { this.$router.go() }, 10)
    },
    initializeComponent() {
      this.registerPlanData = this.planMixin_getDetails(this.$route.fullPath)
      console.log('registerPlanData-------------', this.registerPlanData)

      this.incompleteSignUp = this.captureSignUpData()
      if (this.incompleteSignUp) {
        console.log('----Sign up is incomplete--------', this.incompleteSignUp)
        this.planMixin_subscribeUser(this.subscriptionStatus.planId, this.incompleteSignUp.user)
      }

      this.setupPaddle()
      this.captureParams()
      this.screen.loader = false
    },
    captureSignUpData() {
      const storedData = sessionStorage.getItem('incompleteSignUp')
      if (storedData) {
        try {
          const parsedData = JSON.parse(storedData)
          sessionStorage.removeItem('incompleteSignUp')
          return parsedData
        } catch (error) {
          console.error('Error parsing incompleteSignUp data:', error)
          return null
        }
      }
      return null
    },
    setupPaddle() {
      // Ensure Paddle is set up with the vendor ID from your configuration
      window.Paddle.Setup({
        vendor: config.paddle.vendorId
      })

      // Save the context of `this` for use inside the event listener
      const localThis = this

      // Add an event listener to handle messages from the Paddle checkout process
      window.addEventListener('message', function (event) {
        // Verify that the message is coming from a trusted Paddle domain
        if (event.origin === 'https://sandbox-buy.paddle.com' || event.origin === 'https://checkout.paddle.com') {
          console.log('Received message from Paddle:', event.data)

          // Handle the 'Checkout.Location.Submit' event by moving to the next step
          if (event.data.event_name === 'Checkout.Location.Submit') {
            console.log('Paddle switched to next page asking for details')
            localThis.screen.step++
          }

          // Handle the 'Checkout.Complete' event when the checkout process is finished
          if (event.data.event_name === 'Checkout.Complete') {
            console.log('Paddle is complete')
            localThis.screen.step++
          }
        }
      })
    },
    captureParams() {
      const params = new URLSearchParams(window.location.search)
      this.emailSignUp.params = Array.from(params.keys()).reduce(
        (key, val) => ({ ...key, [val]: params.get(val) }),
        {}
      )
    },
    calculateYearlyPrice: function (price) {
      let calculatedPrice = price * 12
      return Math.round(calculatedPrice)
    },
    executeRecaptcha: function () {
      if (!config.registration.recaptchaEnabled) {
        return
      }

      this.flag.emailBtn = true
      if (this.recaptcha.widgetId === null) {
        this.recaptcha.widgetId = window.grecaptcha.render('grecaptcha-register-form', {
          'sitekey': config.recaptchaSiteKey,
          'callback': (token) => {
            if (token.length > 0) {
              this.recaptcha.response = token
              this.registerAccount()
            }
          },
          'expired-callback': this.resetRecaptchaResponse,
          'size': 'invisible',
          'error-callback': this.resetRecaptchaResponse
        })
        window.grecaptcha.execute(this.recaptcha.widgetId)
      } else {
        this.flag.emailBtn = false
        window.grecaptcha.reset(this.recaptcha.widgetId)
        window.grecaptcha.execute(this.recaptcha.widgetId)
      }
    },
    resetRecaptchaResponse: function () {
      this.recaptcha.response = null

      if (this.recaptcha.widgetId != null) {
        window.grecaptcha.reset(this.recaptcha.widgetId)
      }
      this.recaptcha.widgetId = null
    },
    validateForm: async function () {
      this.resetFlag()
      const { valid } = await this.$refs.createAccountForm.validate()
      if (!valid) {
        return
      }
      this.executeRecaptcha()
    },
    registerAccount: async function () {
      this.flag.emailBtn = true
      this.$store.dispatch(actionTypes.REGISTER_USER, {
        'name': this.emailSignUp.name,
        'email': this.emailSignUp.email,
        'password': this.emailSignUp.password,
        'agree_terms': this.emailSignUp.agreeTerms,
        'subscribe_newsletter': this.emailSignUp.agreeNewsletter,
        'signup_params': this.emailSignUp.params,
        'planId': this.subscriptionStatus.planId,
        'isFreeTrial': this.registerPlanData.isFreeTrial,
        'grecaptcha_token': this.recaptcha.response
      }).then((response) => {
        console.log('response-------', response)
        this.$store.dispatch(actionTypes.REGISTER_SUCCESS)
        this.fetchEmailVerificationSetting(response?.data?.data)
      }).catch((err) => {
        console.log('err-------', err)
        this.$store.dispatch(actionTypes.REGISTER_FAILED)
        this.resetRecaptcha()
        this.resetRecaptchaResponse
        this.flag.emailBtn = false
        this.flag.error = true
        let message = err?.response?.data?.meta?.error_message ?? 'Unable to create account. Please try again later.'
        this.flag.msg = message
      })
    },
    fetchEmailVerificationSetting: function (data) {
      let localThis = this
      this.$store.dispatch('user-settings/fetchUserSettings', {
      }).then((response) => {
        let userSetting = response?.data?.data
        this.$store.dispatch('user-settings/fetchUserSettingsSuccess', {
          userSetting: userSetting
        })
        this.emailSignUp.isEmailVerificationEnabled = userSetting[0].email_verification_enabled
        if (this.registerPlanData.isFreeTrial && this.emailSignUp.isEmailVerificationEnabled) {
          setTimeout(function () {
            localThis.screen.emailVerify = true
            localThis.screen.email = localThis.emailSignUp.email
          }, 50)
        } else if (this.registerPlanData.isFreeTrial && !this.emailSignUp.isEmailVerificationEnabled) {
          setTimeout(function () {
            localThis.$router.push({ 'name': 'tokenLogin', query: { 'token': data.token } })
          }, 3000)
        } else {
          this.flag.emailBtn = false
          console.log('ELSE--------- paid plan register', data)
          this.planMixin_subscribeUser(this.subscriptionStatus.planId, data)
        }
      })
    },
    loginWithGoogle() {
      this.flag.googleBtn = true
      this.flag.error = false
      this.authMixin_loginOrSignUpWithProvider(this.socialSignUpOrLogin.providerGoogle, false)
    },
    socialAccountRegister(provider, data) {
      let socialData = data
      socialData.planId = this.subscriptionStatus.planId
      socialData.isFreeTrial = this.registerPlanData.isFreeTrial
      this.$store.dispatch('socialauth/socialSignUp', {
        provider: provider,
        data: socialData
      })
        .then(response => {
          console.log('IF social--------------------', response)
          let socialThis = this
          if (this.registerPlanData.isFreeTrial) {
            setTimeout(function () {
              socialThis.$router.push({ 'name': 'tokenLogin', query: { 'token': response?.data?.data?.token } })
            }, 50)
          } else {
            console.log('ELSE social--------------------')
            this.planMixin_subscribeUser(this.subscriptionStatus.planId, response?.data?.data)
          }
        })
        .catch(err => {
          console.log('CATCH social--------------------', err)
          this.authMixin_resetSocialSignUpOrLogin()
          this.flag.googleBtn = false
          this.flag = {
            error: true,
            msg: err.response.data?.meta?.error_message || 'Something went wrong during social sign up. Please try again later.'
          }
        })
    }
  }
}
</script>
