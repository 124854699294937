<template>
  <div class="form-question-jump">
    <div class="logic-jump__jumps">
      <div :class="{ 'logic-jump__jump': true, 'multi-select': isMultiSelect }" v-for="(jump, jumpIndex) in jumps"
        :key="jumpIndex">
        <div class="logic-jump__jump__body">
          <div class="logic-jump__jump__step">
            <div class="pa-4">
              <v-select label="Jump To" placeholder="Select Step" variant="outlined" density="compact" :items="stepsMap"
                item-title="label" :value="getStepByLabelAndValue(jump.step)" v-model="jump[jumpIndex]"
                @update:modelValue="updateJump($event, jumpIndex, jump)"
                :name="`question-single-multi-${question.id}-jump-to-${(jumpIndex + 1)}`"
                :rules="$valid([{ rule: 'required', fieldName: 'Jump To' }])">
              </v-select>
            </div>
          </div>
          <div class="logic-jump__jump__conditions">
            <div class="logic-jump__jump__condition" v-for="(condition, conditionIndex) in jump.conditions"
              :key="conditionIndex">
              <div>
                <v-select label="If Choice Selected Is" placeholder="Select Choice" :items="choicesMap"
                  item-title="label" variant="outlined" density="compact" class="mr-2"
                  v-model="jump.conditions[conditionIndex].choice" return-object
                  @update:modelValue="updateJumpCondition($event, 'choice', jumpIndex, conditionIndex, condition)"
                  :name="`question-single-multi-${question.id}-jump-${(jumpIndex + 1)}-condition-${(conditionIndex + 1)}`"
                  :rules="$valid([{ rule: 'required', fieldName: 'Jump Choice' }])">
                </v-select>
                <v-select placeholder="Operation" :items="logicOperators" item-title="label" variant="outlined"
                  density="compact" class="mr-2" v-model="jump.conditions[conditionIndex].operator" return-object
                  :class="{ 'vh': !isMultiSelect || jump.conditions.length - 1 === conditionIndex }"
                  @update:modelValue="updateJumpCondition($event, 'operator', jumpIndex, conditionIndex, condition)">
                </v-select>
                <v-btn @click="deleteJumpCondition(jumpIndex, conditionIndex)" title="Delete jump condition"
                  :class="{ 'delete-jump-condition': true, 'vh': jump.conditions.length <= 1 }" size="small" color="red"
                  icon="mdi-minus-circle" raised variant="text"></v-btn>
              </div>
              <!-- <span
                v-if="errors.has(`${formScope}.question-single-multi-${question.id}-jump-${(jumpIndex + 1)}-condition-${(conditionIndex + 1)}`)"
                class="error-text">
                {{ errors.first(`${formScope}.question-single-multi-${question.id}-jump-${(jumpIndex +
                  1)}-condition-${(conditionIndex + 1)}`) }}
              </span> -->
            </div>
          </div>
          <v-btn color="red" size="small" variant="text" icon="mdi-delete" title="Delete jump"
            @click="deleteJump(jumpIndex)"></v-btn>
        </div>
        <div v-if="isMultiSelect" class="logic-jump__jump__condition-add-wrap">
          <v-btn rounded="md" color="grey-lighten-3" prepend-icon="mdi-plus" class=""
            @click="addJumpCondition(jumpIndex)">
            condition</v-btn>
        </div>
      </div>
    </div>
    <div class="logic-jump_footer">
      <v-btn rounded="md" color="grey-lighten-3" prepend-icon="mdi-plus" class="text-none" @click="addJump()">
        Logic Jump</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as actionTypes from '../store/action-types'

export default {
  props: {
    questionId: {
      required: true,
      type: Number
    },
    questionType: {
      required: true,
      type: String
    }
  },
  data: function () {
    return {
      formScope: 'form_builder',
      logicOperators: [
        {
          label: 'AND',
          value: 'AND'
        },
        {
          label: 'OR',
          value: 'OR'
        }
      ]
    }
  },
  methods: {
    addJump: function () {
      let jump = {
        step: '',
        conditions: [{ choice: '' }]
      }
      if (this.questionType === this.questionTypesMap.MULTIPLE_CHOICE) {
        jump.conditions[0].operator = 'AND'
      }
      this.$store.dispatch(actionTypes.FORMBUILDER_ADD_QJUMP, {
        questionId: this.questionId,
        jump: jump
      })
    },
    addJumpCondition: function (index) {
      this.$store.dispatch(actionTypes.FORMBUILDER_ADD_QJUMP_CONDITION, {
        questionId: this.questionId,
        jumpIndex: index,
        condition: {
          choice: '',
          operator: 'AND'
        }
      })
    },
    getStepIndex: function (stepId) {
      let sIndex = 0
      for (let step of this.steps) {
        if (step.id === stepId) {
          return sIndex
        }
        sIndex++
      }
    },
    updateJumpCondition: function (e, field, jumpIndex, conditionIndex, condition) {
      if (!e) {
        return
      }
      switch (field) {
        case 'choice':
          condition['choice'] = e.value
          break
        case 'operator':
          condition['operator'] = e.value
          break
      }
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QJUMP_CONDITION, {
        questionId: this.questionId,
        jumpIndex: jumpIndex,
        conditionIndex: conditionIndex,
        condition: condition
      })
    },
    deleteJumpCondition: function (jumpIndex, conditionIndex) {
      this.$store.dispatch(actionTypes.FORMBUILDER_DELETE_QJUMP_CONDITION, {
        questionId: this.questionId,
        jumpIndex: jumpIndex,
        conditionIndex: conditionIndex
      })
    },
    updateJump: function (e, jumpIndex, jump) {
      if (!e) {
        return
      }
      jump.step = e.value
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QJUMP, {
        questionId: this.questionId,
        jumpIndex: jumpIndex,
        jump: jump
      })
    },
    deleteJump: function (jumpIndex) {
      this.$store.dispatch(actionTypes.FORMBUILDER_DELETE_QJUMP, {
        questionId: this.questionId,
        jumpIndex: jumpIndex
      })
    },
    getStepByLabelAndValue: function (step) {
      if (step === -1) {
        return { 'label': 'End', value: -1 }
      }
      if (step) {
        return { 'label': 'Step ' + step, value: step }
      }
      return ''
    },
    questionChoiceById: function (id) {
      for (let choice of this.choices) {
        if (choice.id === id) {
          return choice
        }
      }
      return ''
    }
  },
  computed: {
    ...mapGetters(['questionTypesMap']),
    isMultiSelect: function () {
      return this.questionTypesMap.MULTIPLE_CHOICE === this.questionType
    },
    steps: function () {
      return this.$store.state.formBuildState.steps || []
    },
    question: function () {
      return this.$store.getters.getQuestionbyId(this.questionId)
    },
    jumps: function () {
      return this.question.jumps || []
    },
    choices: function () {
      return this.question.choices || []
    },
    stepsMap: function () {
      let stepsMap = this.steps
        .filter(step => step.id !== this.question.stepId)
        .map((step, index) => {
          return {
            label: 'Step ' + Number(this.getStepIndex(step.id) + 1).toString(),
            value: this.getStepIndex(step.id) + 1
          }
        })
      stepsMap.push({ label: 'End', value: -1 })
      return stepsMap
    },
    choicesMap: function () {
      return this.choices
        .filter(c => c.label && c.label.trim().length > 0)
        .map((choice) => {
          return {
            label: choice.label,
            value: choice.id
          }
        })
    }
  }
}
</script>
