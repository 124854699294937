<template>
  <v-container class="partial-leads-view" style="width: 80%;">
    <v-form @submit.prevent="updateSettings()">
      <v-row>
        <v-col cols="12">
          <h4 class="page-title">Partial Leads</h4>
          <p class="text-sm text-grey-darken-4">Partial lead tracking helps you get insight into form responses from users who
            started filling your forms and left without submitting them. Configure the global partial lead collection
            settings for all your forms here. Changing and saving these settings will override the individual partial
            lead settings of each form.</p>
          <v-btn class="text-none font-weight-bold float-right" prepend-icon="mdi-content-save-cog" type="submit"
            color="primary-red" rounded="md" :loading='partialLeads.updating' variant="flat"
            text='Save Settings'></v-btn>
        </v-col>
      </v-row>
      <v-card rounded="md" elevation="0" class="border-thin border-t-thin rounded-t-sm mt-3">
        <v-card-title class="bg-blue-lighten-5 text-primary-blue">
          <h6 class="d-flex justify-left mb-0">
            <v-icon icon="mdi-account-supervisor-circle-outline" class="pt-1"></v-icon>
            <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Partial Leads</p>
          </h6>
        </v-card-title>
        <v-card-text class="partial-leads-content d-flex pa-4">
          <div style="width: 35%">
            <v-switch v-model="enabled" :label="`Partial Lead Tracking ${enabled ? 'Enabled' : 'Disabled'}`"
              color="primary-red" inset density="compact" hide-details @change="enableSettings($event, 'enable')">
            </v-switch>
            <p class="partial-info" v-if="enabled">
              Only available in multi-step forms<br />(Forms with at least 2 form
              steps)
            </p>
            <a class="text-primary-red link" target="_blank" v-if="enabled"
              href="https://leadgenapp.io/help/partial-lead-submissions/">How Partial Lead Tracking Works<v-icon
                color="primary-red" :size="19" icon="mdi-open-in-new"></v-icon>
            </a>
          </div>
          <div style="width: 80%" v-if="enabled">
            <label class="opt-in d-flex justify-center font-weight-bold text-medium mb-3">Choose Opt-in Method</label>
            <v-radio-group class="partial-consent-wrapper" inline v-model="consentType">
              <v-row>
                <v-col cols="6" class="d-flex align-stretch">
                  <div :class="this.consentType === leadConsentTypes.INFORMED ? 'active' : ''"
                    class="content-card rounded-lg cursor-pointer d-flex flex-column">
                    <div class="content-card__body flex-grow-1 d-flex flex-column">
                      <div class="label-text-overview flex-grow-1 d-flex flex-column justify-space-between">
                        <v-radio label="Informed Consent" value="informed" color="red"
                          @input="enableSettings($event, 'informed')" class="font-weight-bold"></v-radio>
                        <p>
                          Once visitor clicks on the next button to reach form step 2, all provided responses will be
                          stored. Notice about storing data will be shown at the end of the form step 1 and consent is
                          obtained by navigation button click to form step 2.
                        </p>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="6" class="d-flex align-stretch">
                  <div :class="this.consentType === leadConsentTypes.EXPRESSED ? 'active' : ''"
                    class="content-card rounded-lg cursor-pointer d-flex flex-column">
                    <div class="content-card__body flex-grow-1 d-flex flex-column">
                      <div class="label-text-overview flex-grow-1 d-flex flex-column justify-space-between">
                        <v-radio value="expressed" color="red" label="Expressed Consent"
                          @input="enableSettings($event, 'expressed')" class="font-weight-bold"></v-radio>
                        <p>
                          Consent is obtained from active opt-in when form visitor ticks a checkbox, shown along with
                          information text about data storing. The checkbox is shown at the bottom of form step 1 and
                          needs to be checked by the form visitor to progress through the form (Opt-in is mandatory).
                        </p>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-radio-group>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
    <v-alert type="info" variant="tonal" rounded="md" density="compact" class="mb-1 mt-5 text-grey-darken-2">
      <p class="text-black mb-0"> <strong>Note:</strong> Partial lead tracking can be changed
        globally and individually per form. You can individually edit the partial lead tracking configuration per
        form, by going to Forms, Opening your Form, Settings, Partial Leads section. Individual form changes on
        partial lead tracking will be overridden by these global settings the moment you save the settings on this
        page. If changes are made on the form-level which are not overridden and saved via global settings on this
        page. If changes are made on the form-level which are not overridden and saved via global settings on this
        page, your form may or may not have partial lead tracking enabled regardless of the settings you see on this
        page. By opening form settings pages individually you will see if the form has the partial lead tracking
        enabled.
      </p>
    </v-alert>

    <!-- Snackbar -->
    <v-snackbar rounded="md" height="50" v-model="snackbar.show" :color="snackbar.color" top
      :timeout="snackbar.duration">
      <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.message }} </p>
    </v-snackbar>
  </v-container>
</template>

<script>
import userMixin from '@/mixins/user'
import { leadConsentTypes } from '../shared/constants/leadConsentTypes'
export default {
  mixins: [userMixin],
  data: function () {
    return {
      snackbar: {
        show: false,
        message: '',
        color: '',
        duration: 2000
      },
      localStorageValue: false,
      enabled: false,
      consentType: leadConsentTypes.INFORMED,
      partialLeads: {
        updating: false,
        updateSuccess: false
      },
      partialLeadSettings: {
        user_id: null,
        enabled: false,
        consent_type: leadConsentTypes.INFORMED
      }
    }
  },
  mounted: function () {
    this.fetchGlobalPartialSettings()
  },
  methods: {
    enableSettings: function (value, field) {
      if (this.enabled) {
        this.localStorageValue = true
        localStorage.setItem('activated', this.localStorageValue)
      }
      this.partialLeadSettings.user_id = this.user.id
      this.partialLeadSettings.enabled = this.enabled
      this.partialLeadSettings.consentType = field === leadConsentTypes.INFORMED ? value : field === leadConsentTypes.EXPRESSED ? value : this.consentType
    },
    enablePartialSettings: function () {
      this.enableSettings()
      this.partialLeads.updating = true
      this.partialLeads.updateSuccess = true
      this.$store.dispatch('partialLeads/saveSettings', this.partialLeadSettings)
        .then(() => {
          this.partialLeads.updating = false
          this.partialLeads.updateSuccess = true
          this.showSnackbar('Settings Updated', 'success')
        }, () => {
          this.partialLeads.updating = false
          this.partialLeads.updateSuccess = false
          this.showSnackbar('Unable to save settings', 'error')
        })
    },
    updateSettings: function () {
      this.enablePartialSettings()
    },
    fetchGlobalPartialSettings: function () {
      this.$store.dispatch('partialLeads/fetchGlobalPartialSettings', {
        user_id: this.user.id
      }).then((response) => {
        let setting = response.data.data
        this.$store.dispatch('partialLeads/fetchGlobalPartialSettingsSuccess', {
          user_id: this.user.id,
          setting: setting
        })
        this.enabled = setting.enabled === 1 ? true : false
        this.consentType = setting.consent_type
      })
    },
    showSnackbar: function (message, type) {
      this.snackbar.message = message
      this.snackbar.color = type === 'success' ? 'primary-green' : 'secondary-red'
      this.snackbar.show = true
    }
  },
  computed: {
    leadConsentTypes: function () {
      return leadConsentTypes
    }
  }
}
</script>
