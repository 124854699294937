<template>
  <div class="form-endings-list-view">
    <div class="general-list-wrapper mb-7">
      <div class="d-flex justify-end ga-3 mb-n9">
        <v-btn v-if="!checked && checkIfUniqueVisitorConditions" @click="openPriorityModal()"
          prepend-icon="mdi-table-of-contents" class="priority-button font-weight-bold text-none bg-light-blue-accent-3"
          :disabled="!packageBuilderMixin_canUseEndingType(formEndings.VISITORBASED)">
          Set Priority
        </v-btn>
        <v-btn class="font-weight-bold text-none" rounded="md" v-if="!checked" color="primary-red"
          @click="openConditionModal" prepend-icon="mdi-plus">
          Add Condition
        </v-btn>
      </div>
      <general-list ref="generalList" :fields="fields" :loadedError="list.loadError" :loaded="list.loaded"
        :listData="listData" :defaultThankyouId="defaultThankyouId" :listPagination="listPagination"
        @onListParamsChange="loadFormEndings($event)" @togglePannel="togglePannel($event)"
        :bulkDeleteAllowed="bulkDeleteAllowed" :bulkDuplicateAllowed="bulkDuplicateAllowed">

        <!-- Endings Title -->
        <template v-slot:Title="props">
          <span v-if="props.rowData.type === formEndings.DEFAULT">
            {{ props.rowData.endingTitle || 'Default Thank You Page' }}
          </span>
          <span v-else>
            {{ props.rowData.title }}
          </span>
        </template>

        <!-- Endings Type -->
        <template v-slot:Type="props">
          <span class="lg-badge pt-1 ml-4 bg-tertiary-blue" v-if="props.rowData.type === formEndings.ANSWERBASED">
            Answer Based
          </span>
          <span class="lg-badge pt-1 ml-4 bg-light-blue" v-if="props.rowData.type === formEndings.VISITORBASED">
            Visitor Based
          </span>
          <span class="lg-badge pt-1 ml-4 bg-primary-red" v-if="props.rowData.type === formEndings.DEFAULT">
            Default
          </span>
        </template>

        <!-- Condition wrapper -->
        <template v-slot:Condition="props">
          <div :class="!props.rowData.ending_enabled ? 'condition-disabled' : 'conditon-enabled'"
            v-if="props.rowData.type !== formEndings.DEFAULT" class="condition-wrapper pa-4 rounded">
            <div v-for="condition in props.rowData.conditions" :key="condition.id">
              <div v-if="condition.form_ending_id === props.rowData.id">
                <div class="condition mb-4">
                  <span v-if="props.rowData.type === formEndings.ANSWERBASED">
                    <span class="text-uppercase font-weight-bold text-blue-lighten-1">IF &nbsp;</span>
                    <span class="missing-feild font-weight-bold text-secondary-red blink-soft"
                      v-if="checkIfQuestionIsDeleted(condition.question_id)">"MISSING FIELD"</span>
                    <span v-else>{{ truncatedText(checkIfQuestionTitleUpdated(condition.question_id, condition.field))
                      }}
                    </span>
                    <span class="text-uppercase font-weight-bold text-blue-lighten-1">&nbsp;{{ condition.operator
                      }}</span>&nbsp; <span v-if="shouldDisplayCondition(condition)">"{{
                        truncatedText(condition.value)
                      }}"</span>
                  </span>
                  <span v-if="props.rowData.type === formEndings.VISITORBASED">
                    <span class="text-uppercase font-weight-bold text-blue-lighten-1">IF &nbsp;</span>
                    <span>{{ parseTriggerSelected(condition.field) }}</span> <span
                      class="text-uppercase font-weight-bold text-blue-lighten-1">&nbsp;{{
                        condition.operator }}</span>&nbsp;
                    <span v-if="shouldDisplayCondition(condition)">"{{ truncatedText(condition.value) }}"</span> <br>
                  </span>
                </div>
                <div class="condition-action">
                  <span v-if="props.rowData.submit_action === formSubmitActionArr[1].value">
                    <span class="text-uppercase font-weight-bold text-blue-lighten-1">DISPLAY MESSAGE:</span>
                    {{ truncatedText(removeTags(props.rowData.thankyou_message)) }}
                  </span>
                  <span v-else-if="props.rowData.submit_action === formSubmitActionArr[0].value">
                    <span class="text-uppercase font-weight-bold text-blue-lighten-1">REDIRECT TO:</span> {{
                      props.rowData.thankyou_url }}
                  </span>
                </div>
                <div
                  v-if="checkIfQuestionIsDeleted(condition.question_id) && props.rowData.type === formEndings.ANSWERBASED"
                  class="text-secondary-red mt-4"><b>ERROR:</b> One or more fields have been deleted which are
                  required
                  by
                  this condition.</div>
              </div>
            </div>
          </div>
        </template>

        <!-- Condition status -->
        <template v-slot:Status="props">
          <div>
            <span v-if="showLoader && props.rowData.id === enableConditionData.id">
              <i class="fas fa-spin fa-spinner"></i>
            </span>
            <span v-else>
              <v-switch inset hide-details color="primary-red"
                :disabled="!packageBuilderMixin_canUseEndingType(props.rowData.type)"
                v-if="props.rowData.type !== formEndings.DEFAULT"
                :title="props.rowData.ending_enabled ? 'Disable Condition' : 'Enable Condition'"
                v-model="props.rowData.ending_enabled" @change="enableFormEnding($event, props.rowData)"></v-switch>
              <span class="condition-status text-grey-darken-1" v-if="props.rowData.type !== formEndings.DEFAULT">{{
                props.rowData.ending_enabled ? 'Enabled' :
                  'Disabled' }}</span>
            </span>
          </div>
        </template>

        <!-- Action -->
        <template v-slot:Action="props">
          <div>
            <div v-if="props.rowData.type === formEndings.DEFAULT">
              <a @click="editDefaultThankyouPage(props.rowData)" title="Edit">
                <v-icon icon="mdi-square-edit-outline" title="Edit" color="primary-red"></v-icon>
              </a>
            </div>
            <div v-else>
              <div v-if="!packageBuilderMixin_canUseEndingType(props.rowData.type)">
                <v-btn class="font-weight-bold text-none" color="black" @click="$router.push({ name: 'plans.index' })"
                  prepend-icon="mdi-lock">Upgrade to
                  Access</v-btn>
              </div>
              <div v-else>
                <router-link
                  :to="{ name: `forms.variants.${checkEndingType(props.rowData.type)}.edit`, params: { endingId: props.rowData.id } }">
                  <v-icon icon="mdi-square-edit-outline" title="Edit" color="primary-red"></v-icon>
                </router-link>
                <v-icon icon="mdi-delete-outline" title="Delete" color="primary-red"
                  @click="openDeleteOrDuplicateModal(props.rowData.id, 'delete')"></v-icon>
                <v-icon icon="mdi-content-copy" title="Duplicate" color="primary-red"
                  @click="openDeleteOrDuplicateModal(props.rowData.id, 'duplicate')"></v-icon>
              </div>
            </div>
          </div>
        </template>
      </general-list>
    </div>

    <!-- Default Thank you page Modal -->
    <v-dialog class="default-thankyou-modal" persistent v-model="modals.editThankyouPageModal" max-width="800">
      <v-card max-width="800">
        <v-toolbar color="white">
          <v-toolbar-title class="font-weight-bold"> <v-icon icon="mdi-cog-transfer"></v-icon> Submit
            Actions</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon="mdi-close" @click="modals.editThankyouPageModal = false"></v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <v-form @submit.prevent="() => false" ref="thankyouMessageForm">
            <div class="thankyou-message-section content-card">
              <div class="section-content content-card__body" v-if="defaultThankyouPage.submitAction">
                <v-text-field density="comfortable" v-model="defaultThankyouPage.endingTitle" disabled
                  variant="outlined" label="Thankyou Page Title"></v-text-field>
                <v-select density="comfortable" variant="outlined" label="Choose Submit Action"
                  v-model="defaultThankyouPage.submitAction" :items="formSubmitActionArr" item-title="label"
                  item-value="value" return-object></v-select>
                <div v-if="defaultThankyouPage.submitAction.value === formSubmitActionArr[0].value">
                  <div class="formfield">
                    <v-text-field density="comfortable" variant="outlined" v-model="defaultThankyouPage.thankyouUrl"
                      label="Destination URL"
                      :rules="$valid([{ rule: 'required', fieldName: 'Destination URL' }, { rule: 'url', fieldName: 'Destination URL' }])"></v-text-field>
                    <v-switch inset color="secondary-red" label="Post form data to URL" hide-details
                      v-model="defaultThankyouPage.postDataToUrl"></v-switch>
                    <v-switch inset color="secondary-red" label="Append data to URL" hide-details
                      v-model="defaultThankyouPage.appendDataToUrl">Append data to URL</v-switch>
                  </div>
                </div>
                <div v-else-if="defaultThankyouPage.submitAction.value === formSubmitActionArr[1].value">
                  <div class="formfield">
                    <quill-editor v-model:content="defaultThankyouPage.thankyouMessage" :options="editorOption"
                      name="thankyou-message" data-vv-as="Thankyou Message" data-vv-value-path="value"
                      contentType="html" :data-vv-scope="formThankyouPageScope" v-validate="'required'">
                    </quill-editor>
                    <br>
                    <v-switch inset color="secondary-red" hide-details
                      label="Trim trailing zeros from calculator result"
                      v-model="defaultThankyouPage.trimTrailingZeros"></v-switch>
                  </div>
                </div>
              </div>
            </div>
            <v-alert border type="error" closable v-if="flags.errorMsg.length > 0">
              {{ flags.errorMsg }}
            </v-alert>
          </v-form>
        </v-card-text>
        <v-card-actions class="mb-3 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="modals.editThankyouPageModal = false">
          </v-btn>
          <v-btn rounded="md" variant="elevated" class="font-weight-bold text-none" color="primary-red"
            :loading="flags.processing" @click="saveThankyouPage">
            <span>Save</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Condition Modal -->
    <v-dialog class="addConditionModal" persistent v-model="modals.addConditionModal" max-width="1000px">
      <v-card max-width="1000">
        <v-toolbar color="white">
          <v-toolbar-title class="font-weight-bold">Choose Form Ending Type</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon="mdi-close" @click="modals.addConditionModal = false"></v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <section class="ending-types" v-if="this.packageBuilderMixin_canUseMultipileEndings()">
            <div v-if="this.packageBuilderMixin_canUseEndingType(this.formEndings.ANSWERBASED)"
              @click="redirectToEnding('answerbased')" class="card text-center rounded ma-3 pa-8 realative">
              <div class="card-content">
                <img src="./../assets/images/icons/answer-based.png" style="width:87px;" alt="">
                <h1 class="font-weight-bold">Answer Based</h1>
                <p>Guide your users to different final pages based on what they answered most and show them customized
                  content. Easily segment your audience by different personas.</p>
              </div>
            </div>
            <div v-else class="card text-center rounded ma-3 pa-8 realative transition no-hover">
              <div class="card-content">
                <img src="./../assets/images/icons/answer-based.png" style="width:87px;" alt="">
                <h1 class="font-weight-bold">Answer Based</h1>
                <p>Guide your users to different final pages based on what they answered most and show them customized
                  content. Easily segment your audience by different personas.</p>
              </div>
              <div class="card-footer">
                <v-btn class="bg-black text-none text-white font-weight-bold"
                  @click="$router.push({ name: 'plans.index' })" prepend-icon="mdi-lock">Upgrade
                  to
                  Access</v-btn>
              </div>
            </div>
            <div v-if="this.packageBuilderMixin_canUseEndingType(this.formEndings.VISITORBASED)"
              class="card text-center rounded ma-3 pa-8 realative transition" @click="redirectToEnding('visitorbased')">
              <div class="card-content">
                <img src="./../assets/images/icons/visitor-based.png" style="width:100px;" alt="">
                <h1 class="font-weight-bold">Visitor Based</h1>
                <p>Route users to distinct final pages based on their location and other criteria. Effortlessly
                  segment
                  your audience according to various personas.</p>
              </div>
            </div>
            <div v-else class="card no-hover">
              <div class="card-content">
                <img src="./../assets/images/icons/visitor-based.png" style="width:100px;" alt="">
                <h1 class="font-weight-bold">Visitor Based</h1>
                <p>Route users to distinct final pages based on their location and other criteria. Effortlessly
                  segment
                  your audience according to various personas.</p>
              </div>
              <div class="card-footer">
                <v-btn class="bg-black text-none text-white font-weight-bold"
                  @click="$router.push({ name: 'plans.index' })" prepend-icon="mdi-lock">Upgrade
                  to
                  Access</v-btn>
              </div>
            </div>
            <div class="card text-center rounded ma-3 pa-8 realative transition cursor-not-allowed no-hover">
              <div class="card-content">
                <div class="card-tag absolute font-weight-bold text-white pa-1 pr-2 pl-2 bg-primary-red">COMING SOON
                </div>
                <img src="./../assets/images/icons/score-based.png" style="width:100px;" alt="">
                <h1 class="font-weight-bold">Score Based</h1>
                <p>Personalize the user experience by directing them to different final pages based on their
                  calculator
                  result. Tailor the post-experience journey to align with their score.</p>
              </div>
            </div>
          </section>
          <section class="ending-types" v-else>
            <div class="card text-center rounded ma-3 pa-8 realative transition no-hover">
              <div class="card-content">
                <img src="./../assets/images/icons/answer-based.png" style="width:87px;" alt="">
                <h1 class="font-weight-bold">Answer Based</h1>
                <p>Guide your users to different final pages based on what they answered most and show them customized
                  content. Easily segment your audience by different personas.</p>
              </div>
              <div class="card-footer">
                <v-btn class="bg-black text-none text-white font-weight-bold"
                  @click="$router.push({ name: 'plans.index' })" prepend-icon="mdi-lock">Upgrade
                  to
                  Access</v-btn>
              </div>
            </div>
            <div class="card text-center rounded ma-3 pa-8 realative transition no-hover">
              <div class="card-content">
                <img src="./../assets/images/icons/visitor-based.png" style="width:100px;" alt="">
                <h1 class="font-weight-bold">Visitor Based</h1>
                <p>Route users to distinct final pages based on their location and other criteria. Effortlessly
                  segment
                  your audience according to various personas.</p>
              </div>
              <div class="card-footer">
                <v-btn class="bg-black text-none text-white font-weight-bold"
                  @click="$router.push({ name: 'plans.index' })" prepend-icon="mdi-lock">Upgrade
                  to
                  Access</v-btn>
              </div>
            </div>
            <div class="card text-center rounded ma-3 pa-8 realative transition cursor-not-allowed no-hover">
              <div class="card-content">
                <div class="card-tag absolute font-weight-bold text-white pa-1 pr-2 pl-2 bg-primary-red">COMING SOON
                </div>
                <img src="./../assets/images/icons/score-based.png" style="width:100px;" alt="">
                <h1 class="font-weight-bold">Score Based</h1>
                <p>Personalize the user experience by directing them to different final pages based on their
                  calculator
                  result. Tailor the post-experience journey to align with their score.</p>
              </div>
            </div>
          </section>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Delete & Duplicate Modal -->
    <v-dialog v-model="modals.openModal" max-width="600px">
      <v-card max-width="600"
        :prepend-icon="currentModalType === 'delete' ? 'mdi-trash-can-outline' : 'mdi-content-copy'">
        <template v-slot:title>
          <b>Are you sure?</b>
        </template>
        <v-card-text>
          <v-alert v-if="flags.errorMsg.length > 0" border type="warning" variant="tonal" density="compact">
            <p class="text-black ma-0">{{ flags.errorMsg }}</p>
          </v-alert>
          <v-spacer class="my-4"></v-spacer>
          <v-alert border :type="currentModalType === 'delete' ? 'error' : 'info'" variant="tonal" density="compact"
            icon="mdi-information">
            <p class="text-black ma-0" v-if="currentModalType === 'delete'">Proceed with caution. This action is
              permanent.</p>
            <p class="text-black ma-0" v-else>This action will duplicate the selected form ending.</p>
          </v-alert>
        </v-card-text>
        <v-card-actions class="mb-3 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="modals.openModal = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="font-weight-bold text-none"
            :color="currentModalType === 'delete' ? 'primary-red' : 'light-blue'" :loading="flags.processing"
            @click="currentModalType === 'delete' ? deleteFormEnding() : duplicateFormEnding()">
            <span>{{ currentModalType === 'delete' ? 'Delete' : 'Duplicate' }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Ending exists Model -->
    <v-dialog persistent v-model="modals.openEndingExistsModal" max-width="600px">
      <v-card max-width="600" prepend-icon="mdi-information">
        <template v-slot:title>
          <b>Are you sure?</b>
        </template>
        <v-card-text>
          <v-alert v-if="flags.errorMsg.length > 0" border type="warning" variant="tonal" density="compact">
            <p class="text-black ma-0">{{ flags.errorMsg }}</p>
          </v-alert>
          <v-spacer class="my-4"></v-spacer>
          <v-alert border type="warning" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">
              Only one type of <strong>form ending</strong> can
              be enabled at a time on this form. If you
              proceed to {{ this.showModalType ===
                'addCondition' ? 'add' : 'enable' }} this ending, all <strong>{{ this.selectedType ===
                formEndings.ANSWERBASED.toLowerCase() ? 'visitor' : 'answer' }} based endings</strong>
              will be disabled.
            </p>
          </v-alert>
        </v-card-text>
        <v-card-actions class="mb-3 mr-4">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="closeEndingExistsModal">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="primary-red"
            :loading="flags.processing" @click="proceedToSelectedEnding()">
            <span>Proceed</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Set priority modal -->
    <v-dialog persistent v-model="modals.openSetPriorityModal" max-width="600px">
      <v-card max-width="600">
        <v-toolbar color="white">
          <v-toolbar-title class="font-weight-bold"> <v-icon class="mr-2" icon="mdi-priority-high"></v-icon>Set Priority
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon="mdi-close" @click="modals.openSetPriorityModal = false"></v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-alert v-if="flags.errorMsg.length > 0" border type="warning" variant="tonal" density="compact">
            <p class="text-black ma-0">{{ flags.errorMsg }}</p>
          </v-alert>
          <v-spacer class="my-4"></v-spacer>
          <v-alert v-if="flags.saved" border type="warning" variant="tonal" density="compact">
            <p class="text-black ma-0">Change updated successfully.</p>
          </v-alert>
          <v-spacer class="my-4"></v-spacer>
          <v-alert border type="info" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">
              Please arrange the below
              <strong>visitor based conditions</strong> in order of
              priority by dragging them up or down.
              Conditions at the top will be executed first and so on.
            </p>
          </v-alert>
          <v-spacer class="my-4"></v-spacer>
          <draggable tag="ul" :list="visitorPriority" class="list-group mt-4" handle=".handle" @end="onDragEnd"
            itemKey="name">
            <template v-slot:item="{ element }">
              <li class="list-group-item d-flex align-baseline ga-3">
                <i style="cursor:all-scroll;" class="fa fa-align-justify handle"></i>
                <span class="drag-text font-weight-bold rounded border pa-2 w-100 mb-3 bg-grey-lighten-4">{{
                  element.title }}</span>
              </li>
            </template>
          </draggable>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import GeneralList from '@/components/lists/GeneralList'
import { resourceTypes } from '@/shared/constants/generalList/list'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import _ from 'lodash'
import { editorMixin } from '@/mixins/form-endings'
import { formEndingTypes } from '@/shared/constants/formEndingTypes'
import userMixin from '@/mixins/user'
import draggable from 'vuedraggable'
import packageBuilderMixin from '@/mixins/package-builder'

export default {
  mixins: [editorMixin, userMixin, packageBuilderMixin],
  components: {
    'general-list': GeneralList,
    'quill-editor': QuillEditor,
    'draggable': draggable
  },
  data: function () {
    return {
      modals: {
        addConditionModal: false,
        editThankyouPageModal: false,
        openEndingExistsModal: false,
        openSetPriorityModal: false,
        openModal: false
      },
      currentModalType: '',
      visitorPriority: [],
      checkIfUniqueVisitorConditions: false,
      list: {
        loaded: false,
        loadError: false
      },
      checked: false,
      listData: [],
      defaultThankyouId: null,
      conditionsData: null,
      syncUserPlan: null,
      listPagination: {},
      fields: [
        {
          name: 'Title',
          sortField: 'title',
          searchField: 'title'
        },
        {
          name: 'Type'
        },
        {
          name: 'Condition'
        },
        {
          name: 'Status'
        },
        {
          name: 'Action'
        }
      ],
      selectedEnding: null,
      selectedType: null,
      showModalType: null,
      showLoader: false,
      enableConditionData: {
        value: '',
        id: null
      },
      defaultThankyouPage: {
        id: null,
        type: formEndingTypes.DEFAULT,
        postDataToUrl: false,
        appendDataToUrl: false,
        thankyouMessage: '',
        enableThankyouUrl: false,
        thankyouUrl: '',
        submitAction: '',
        trimTrailingZeros: false
      },
      flags: {
        saved: false,
        errorMsg: '',
        deleted: false,
        duplicated: false,
        processing: false
      }
    }
  },
  mounted: function () {
    this.defaultThankyouPage.submitAction = this.formSubmitActionArr[0]
    this.endingsMixin_getFormQuestions()
    this.loadFormEndings()
    this.syncUserPlan = this.syncUser
  },
  methods: {
    closeEndingExistsModal: function () {
      this.modals.openEndingExistsModal = false
      this.selectedType = null
      this.$refs.generalList.reload()
    },
    onDragEnd: function () {
      let updatedPriority = this.visitorPriority.map((item, index) => ({ ...item, id: index + 1 }))
      this.$store.dispatch('formendings/setVisitorPriority', { order: updatedPriority, formId: this.$route.params.id })
        .then(() => {
          this.flags.saved = true
          setTimeout(() => {
            this.flags.saved = false
          }, 2000)
        }, (error) => {
          this.flags.saved = false
          this.flags.errorMsg = error.msg ? error.msg : 'Something went wrong! please try again'
        })
    },
    showSnackbar: function (type, message) {
      let snackbarRef = this.$refs[type]
      snackbarRef.createSnackbar({
        message: message,
        duration: 100 * 50
      })
    },
    togglePannel: function (event) {
      this.checked = event
    },
    redirectToEnding: function (type) {
      if (!this.$route.params.id || !this.$route.params.variantId) {
        return
      }
      if (this.checkIfEndingExists(type)) {
        this.showModalType = 'addCondition'
        this.modals.openEndingExistsModal = true
        this.selectedType = type
      } else {
        const routePath = `/forms/${this.$route.params.id}/variants/${this.$route.params.variantId}/edit/formendings/${type}/create`
        window.location.href = routePath
      }
    },
    checkIfEndingExists: function (typeParam) {
      const endings = this.listData.filter(item => item.type !== this.formEndings.DEFAULT)
      for (let item of endings) {
        if (item.type !== typeParam.toUpperCase() && item.ending_enabled === true) {
          return true
        }
      }
      return false
    },
    proceedToSelectedEnding: function () {
      if (this.showModalType === 'addCondition') {
        const routePath = `/forms/${this.$route.params.id}/variants/${this.$route.params.variantId}/edit/formendings/${this.selectedType}/create`
        window.location.href = routePath
      } else if (this.showModalType === 'enableCondition') {
        this.$store.dispatch('formendings/enableFormEnding', { id: this.enableConditionData.id, userId: this.user.id, enabled: this.enableConditionData.value })
          .then(() => {
            this.$refs.generalList.reload()
          }, (error) => {
            this.flags.errorMsg = error.msg ? error.msg : 'Something went wrong! please try again'
          })
        this.modals.openEndingExistsModal = false
      }
    },
    loadFormEndings: function (params) {
      if (!params) {
        return
      }
      params.resource = resourceTypes.FORM_ENDINGS
      this.list.loaded = false
      this.list.loadError = false
      this.$store.dispatch('formendings/getFormEndings', params)
        .then((response) => {
          this.$store.dispatch('formendings/getFormEndingsSuccess', response.data.data)
          let data = response.data.data.items
          this.listData = data.map(item => ({
            ...item, // Copy all other properties
            ending_enabled: item.ending_enabled === 1 // Convert to boolean
          }));
          this.defaultThankyouId = response.data.data.defaultThankYouMessageId
          this.listPagination = response.data.pagination
          this.updateVisitorPriority()
          this.list.loaded = true
        }, (response) => {
          this.list.loadError = true
        })
    },
    openDeleteOrDuplicateModal: function (id, type) {
      this.currentModalType = type
      this.selectedEnding = id
      this.modals.openModal = true
    },
    openPriorityModal: function () {
      this.modals.openSetPriorityModal = true
    },
    saveThankyouPage: async function () {
      this.flags.processing = true
      this.flags.errorMsg = ''
      const { valid } = await this.$refs.thankyouMessageForm.validate()
      if (!valid) {
        this.flags.processing = false
        return
      }
      const dispatchData = { userId: this.user.id, data: this.defaultThankyouPage }
      this.$store.dispatch('formendings/saveDefaultThankyouPage', dispatchData)
        .then(() => {
          this.flags.saved = true
          this.flags.processing = false
          this.$refs.generalList.reload()
          this.modals.editThankyouPageModal = false
        }, (error) => {
          this.flags.processing = false
          this.flags.errorMsg = error.msg ? error.msg : 'Something went wrong! please try again'
        })
    },
    editDefaultThankyouPage: function (thankyouData) {
      Object.assign(this.defaultThankyouPage, {
        id: thankyouData.id,
        appendDataToUrl: thankyouData.append_data_to_url === 1,
        postDataToUrl: thankyouData.post_data_to_url === 1,
        thankyouMessage: thankyouData.thankyou_message,
        thankyouUrl: thankyouData.thankyou_url,
        enableThankyouUrl: thankyouData.enable_thankyou_url,
        submitAction: _.find(this.formSubmitActionArr, { value: thankyouData.submit_action }),
        endingTitle: thankyouData.title || 'Default Thank You Page',
        trimTrailingZeros: thankyouData.trim_trailing_zeros === 1
      });

      this.modals.editThankyouPageModal = true;
    },
    deleteFormEnding: function () {
      this.flags.deleted = false
      this.flags.processing = true
      this.$store.dispatch('formendings/deleteFormEnding', { id: this.selectedEnding, userId: this.user.id })
        .then(() => {
          this.flags.deleted = true
          this.flags.processing = false
          this.$refs.generalList.reload()
          this.modals.openModal = false
        }, (error) => {
          this.flags.processing = false
          this.flags.errorMsg = error.msg ? error.msg : 'Something went wrong! please try again'
        })
    },
    duplicateFormEnding: function () {
      this.flags.duplicated = false
      this.flags.processing = true
      this.$store.dispatch('formendings/duplicateFormEnding', { id: this.selectedEnding, userId: this.user.id })
        .then(() => {
          this.flags.duplicated = true
          this.flags.processing = false
          this.$refs.generalList.reload()
          this.modals.openModal = false
        }, (error) => {
          this.flags.processing = false
          this.flags.errorMsg = error.msg ? error.msg : 'Something went wrong! please try again'
        })
    },
    enableFormEnding: function (value, data) {
      if (this.checkIfEndingExists(data.type)) {
        this.showModalType = 'enableCondition'
        this.enableConditionData.value = data.ending_enabled
        this.enableConditionData.id = data.id
        this.selectedType = data.type.toLowerCase()
        this.modals.openEndingExistsModal = true
      } else {
        this.showLoader = true
        this.enableConditionData.id = data.id
        this.$store.dispatch('formendings/enableFormEnding', { id: data.id, userId: this.user.id, enabled: data.ending_enabled })
          .then(() => {
            this.$refs.generalList.reload()
            this.showLoader = false
          }, (error) => {
            this.showLoader = false
            this.flags.errorMsg = error.msg ? error.msg : 'Something went wrong! please try again'
          })
      }
    },
    openConditionModal: function () {
      this.modals.addConditionModal = true
    },
    removeTags: function (input) {
      if (typeof input === 'string') {
        return input.replace(/<\/?[^>]+(>|$)/g, '')
      } else {
        return input
      }
    },
    truncatedText: function (text) {
      if (text.length <= 20) {
        return text
      } else {
        return text.substring(0, 30) + '...'
      }
    },
    shouldDisplayCondition: function (condition) {
      const excludeOperators = [this.endingConditions.ISEMPTY, this.endingConditions.ISFILLED, this.endingConditions.ISCHECKED, this.endingConditions.ISNOTCHECKED]

      return !(
        excludeOperators.includes(condition.operator) ||
        excludeOperators.includes('Is ' + condition.operator)
      )
    },
    checkIfQuestionIsDeleted: function (id) {
      return !this.formQuestions.some(item => item.id === id)
    },
    checkIfQuestionTitleUpdated: function (id, oldText) {
      const item = this.formQuestions.find(item => item.id === id)
      if (oldText !== item.title) {
        return item.title
      }
      return oldText
    },
    parseTriggerSelected: function (value) {
      try {
        const field = JSON.parse(value)
        return field.title
      } catch (error) {
        return null
      }
    },
    checkEndingType: function (type) {
      if (type === this.formEndings.ANSWERBASED) {
        return 'answerbased'
      } else if (type === this.formEndings.VISITORBASED) {
        return 'visitorbased'
      }
    },
    updateVisitorPriority: function () {
      const visitorConditions = this.listData
        .filter(item => item.type === this.formEndings.VISITORBASED && item.ending_enabled === true)
        .map(item => {
          // Extract the name and id from the conditions
          const condition = item.conditions[0]
          const fieldData = JSON.parse(condition.field)
          return { name: fieldData.type, id: condition.priority, title: fieldData.title }
        })
      const uniqueVisitorConditions = Array.from(new Set(visitorConditions.map(JSON.stringify))).map(JSON.parse)
      this.checkIfUniqueVisitorConditions = uniqueVisitorConditions.length > 1
      if (this.selectedType === 'answerbased') {
        this.checkIfUniqueVisitorConditions = false
      }
      const sortedVisitorConditions = uniqueVisitorConditions.sort((a, b) => a.id - b.id)
      // Update the visitorPriority array
      this.visitorPriority = sortedVisitorConditions
    }
  },
  computed: {
    bulkDeleteAllowed: function () {
      return true
    },
    bulkDuplicateAllowed: function () {
      return true
    }
  }
}
</script>
