import { deleteData, getData, postData, putData } from '../plugins/ApiCalls'

export function fetchFormWebhooks(formId) {
  return getData('forms/' + formId + '/webhooks')
}

export function fetchFormWebhook(formId, webhookId) {
  return getData('forms/' + formId + '/webhooks/' + webhookId)
}

export function createFormWebhook(formId, webhook) {
  return postData('forms/' + formId + '/webhooks', webhook, { emulateJSON: true })
}

export function updateFormWebhook(formId, webhookId, webhook) {
  return putData('forms/' + formId + '/webhooks/' + webhookId, webhook, { emulateJSON: true })
}

export function deleteFormWebhook(formId, id) {
  return deleteData('forms/' + formId + '/webhooks/' + id)
}
