<template>
  <div id="form-overview">
    <!--champion -->
    <div class="form-champion">
      <div class="d-flex justify-space-between align-center flex-grow-1">
        <div class="d-flex align-center">
          <!-- <v-icon color="secondary-red" :size="68" icon="mdi-account-supervisor-circle"></v-icon> -->
          <v-avatar color="red" size="55">
            <v-icon color="white" size="30">mdi-account-multiple</v-icon>
          </v-avatar>
          <div class="d-flex ml-2 flex-column">
            <span style="font-size: 28px;" class="font-weight-bold">{{ form.visitors_count }}</span>
            <span class="text-grey font-weight-bold">Visitors</span>
          </div>
        </div>
        <div class="d-flex align-center">
          <!-- <v-icon color="secondary-red" :size="68" icon="mdi-format-list-bulleted"></v-icon> -->
          <v-avatar color="red" size="55">
            <v-icon color="white" size="30">mdi-format-list-bulleted</v-icon>
          </v-avatar>
          <div class="d-flex ml-2 flex-column">
            <span style="font-size: 28px;" class="font-weight-bold">{{ form.conversions }}</span>
            <span class="text-grey font-weight-bold">Conversions</span>
          </div>
        </div>
        <div class="d-flex align-center">
          <!-- <v-icon color="secondary-red" :size="68" icon="mdi-chart-timeline-variant"></v-icon> -->
          <v-avatar color="red" size="55">
            <v-icon color="white" size="30">mdi-chart-timeline-variant</v-icon>
          </v-avatar>
          <div class="d-flex ml-2 flex-column">
            <span style="font-size: 28px;" class="font-weight-bold"> {{ conversionRate(form.conversions,
              form.visitors_count) }}%</span>
            <span class="text-grey font-weight-bold">Conversion Rate</span>
          </div>
        </div>
        <div>
          <small class="update-date">Last form change: &nbsp; <br> <span><b>{{ localeDateTime(champion.updated_at)
                }}</b></span></small>
          <div>
            <v-btn density="comfortable" variant="outlined" class="text-none font-weight-bold mt-2" color="primary-red"
              prepend-icon="mdi-refresh" @click="modals.resetConfirmDialog = true">Reset Form Statistics</v-btn>
            <v-dialog v-model="modals.resetConfirmDialog" width="600px">
              <v-card>
                <template v-slot:title> <strong>Are you sure?</strong> </template>
                <v-card-text>
                  <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
                    <p class="text-black ma-0">This function will reset your leads, conversion statistics and Form
                      experiments data to
                      0. Would
                      you like
                      to proceed?</p>
                  </v-alert>
                </v-card-text>
                <v-card-actions class="mb-2">
                  <v-btn class="text-none" variant="tonal" @click="modals.resetConfirmDialog = false">Cancel</v-btn>
                  <v-btn variant="flat" color="primary-red" class="mr-2 text-none font-weight-bold"
                    @click="onConfirmReset">Reset</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
    </div>
    <!-- New Variant -->
    <div class="content-card">
      <header style="background-color: #edf4f9;"
        class="d-flex justify-space-between  align-center pr-3 pl-3 py-2 rounded-t">
        <span style="font-size: 14px;" v-if="formUrl"><strong class="text-primary-red">Published: </strong> <a
            class="text-primary-red text-decoration-underline" target="_blank" :href="formUrl">{{ formUrl
            }} <v-icon :size="18" icon="mdi-open-in-new"></v-icon> </a> </span>
        <div>
          <router-link :to="{ name: 'forms.variants.create', params: { id: $route.params.id || 0 } }">
            <v-btn text="Add Variant" prepend-icon="mdi-plus" color="light-blue"
              class="text-none font-weight-bold"></v-btn>
          </router-link>
        </div>
      </header>
      <table class="content-card__body">
        <!-- Champion -->
        <thead>
          <tr>
            <th class="text-left pl-6" colspan="5">Champion Variant</th>
            <th class="text-center">Visitors</th>
            <th class="text-center">Conversions</th>
            <th class="text-center">Conversion Rate</th>
            <th class="text-center">Partial Entries</th>
            <th class="text-center">Edit</th>
            <th>Preview </th>
            <th>More </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="5" class="form-name">
              <a @click="goToVariant(champion.id)"> {{ champion.title }}</a>
            </td>
            <td class='text-center visitors_count'>{{ champion.visitors_count }}</td>
            <td class='text-center font-weight-medium'>{{ champion.conversions }}</td>
            <td class='text-center conversion-champion-rate'>
              {{ conversionRate(champion.conversions, champion.visitors_count) }}%
            </td>
            <td class='text-center font-weight-medium'>{{ champion.partials_count }}</td>
            <td class='text-center'>
              <a @click="goToVariant(champion.id)">
                <v-icon icon="mdi-square-edit-outline"> </v-icon>
              </a>
            </td>
            <td class='text-center'>
              <a :href="previewLink(champion)" target="_blank">
                <v-icon icon="mdi-eye">
                </v-icon>
              </a>
            </td>
            <td class='text-center'>
              <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn icon="mdi-dots-vertical" variant="text" v-bind="props"></v-btn>
                </template>
                <v-list>
                  <template v-for="(item, i) in [{ label: 'Duplicate', action: 'duplicate', variant: champion }]"
                    :key="i">
                    <v-list-item @click="onChampionMoreAction(item)">
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
        <!-- challenger -->
        <thead v-if="challengers.length > 0">
          <tr>
            <th class="name" colspan="12">Challenger Variants</th>
          </tr>
        </thead>
        <!-- totals -->
        <tbody class="totals" v-if="challengers.length > 0">
          <tr v-for="challenger in challengers" :key="challenger.id">
            <td colspan="5" class="form-name">
              <a @click="goToVariant(challenger.id)"> {{ challenger.title }}</a>
            </td>
            <td class='text-center visitors_count'>{{ challenger.visitors_count }}</td>
            <td class='text-center font-weight-medium'>{{ challenger.conversions }}</td>
            <td class='text-center conversion-variant-rate '>
              {{ conversionRate(challenger.conversions, challenger.visitors_count) }}%
            </td>
            <td class='text-center font-weight-medium'>{{ challenger.partials_count }}</td>
            <td class='text-center'>
              <a @click="goToVariant(challenger.id)"> <v-icon icon="mdi-square-edit-outline"> </v-icon>
              </a>
            </td>
            <td class='text-center'>
              <a target="_blank" :href="previewLink(challenger)"><v-icon icon="mdi-eye"></v-icon>
              </a>
            </td>
            <!-- More Dropdown Trigger -->
            <td class='text-center'>
              <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn icon="mdi-dots-vertical" variant="text" v-bind="props"></v-btn>
                </template>
                <v-list>
                  <template
                    v-for="(item, i) in [{ label: 'Promote As Champion', action: 'make_champion', variant: challenger }, { label: 'Duplicate', action: 'duplicate', variant: challenger }]"
                    :key="i">
                    <v-list-item @click="onChallengerMoreAction(item)">
                      <v-list-item-title>{{ item.label }}</v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-list-item
                    @click="onChallengerMoreAction({ label: 'Delete', action: 'delete', variant: challenger })">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
          <tr>
            <td colspan="5" class="blank">Overall:</td>
            <td class='text-center font-weight-medium'>{{ form.visitors_count }}</td>
            <td class='text-center font-weight-medium'>{{ form.conversions }}</td>
            <td class='text-center font-weight-medium conversion-variant-rate'>{{ conversionRate(form.conversions,
              form.visitors_count) }}%</td>
            <td colspan="3"> </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-12">
      <p>Form Traffic Mode</p>
    </div>
    <section class='other-features-wrapper'>
      <v-radio-group inline v-model="isCurrentModeAbTest">
        <div class="content-card" @click="stopExperiments">
          <div class="content-card__body" :class="this.runningExperiments.length === 0 ? 'active' : ''">
            <div class="label-text-overview">
              <v-radio color="primary-red" :true-value="false" :false-value="true">
                <template v-slot:label><strong class="text-black">Standard</strong></template>
              </v-radio>
              <p>Send all your traffic to one form variant only</p>
            </div>
          </div>
        </div>

        <div class="content-card">
          <div class="content-card__body" :class="this.runningExperiments.length > 0 ? 'active' : ''">
            <div class="label-text-overview" @click="goToView('experiments')">
              <v-radio color="primary-red" :true-value="true" :false-value="false">
                <template v-slot:label><strong class="text-black">A/B Test</strong></template>
              </v-radio>
              <p>Automated traffic split to compare performance of 2 form variants. </p>
            </div>
          </div>
        </div>
      </v-radio-group>
    </section>

    <!-- Make Champion Modal -->
    <v-dialog v-model="modals.promoteToChampionModal" width="550px">
      <v-card>
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-card-text>
          <v-alert border type="tertiary-blue" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">You are about to promote this variant as a Champion?</p>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn class="text-none font-weight-bold" variant="tonal"
            @click="modals.promoteToChampionModal = false">Cancel</v-btn>
          <v-btn variant="flat" :loading="championVariant.promoting" class="mr-4 text-none font-weight-bold"
            color="tertiary-blue" @click="promoteToChampion">Promote</v-btn>
        </v-card-actions>
        <p class="error-text text-center">{{ championVariant.errorMessage }}</p>
      </v-card>
    </v-dialog>

    <!-- Variant Duplicate  Modal -->
    <v-dialog v-model="duplicate.modal" width="550px">
      <v-card>
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-card-text>
          <v-alert border type="info" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">You are about to duplicate the <strong>{{ duplicate.variant ?
                duplicate.variant.title
                : '' }}.</strong> Are you sure you want to proceed? </p>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn class="font-weight-bold text-none" buttonType="button" @click="duplicate.modal = false"
            variant="tonal">Cancel</v-btn>
          <v-btn variant="flat" :loading="duplicate.duplicating" color="light-blue"
            class="mr-4 text-none font-weight-bold" @click="duplicatedFormVariant">Duplicate</v-btn>
        </v-card-actions>
        <p class="error-text text-center">{{ duplicate.errorMessage }}</p>
      </v-card>
    </v-dialog>

    <!-- Variant Delete  Modal -->
    <v-dialog v-model="this.delete.modal" width="550px" title="Warning">
      <v-card>
        <template v-slot:title>
          <strong><v-icon :size="23" class="mr-1" icon="mdi-trash-can-outline"></v-icon>Are you sure?</strong>
        </template>
        <v-card-text>
          <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">Proceed with caution. Deleting variant will also delete its leads.</p>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn class="font-weight-bold text-none" @click="this.delete.modal = false" variant="tonal">Cancel</v-btn>
          <v-btn variant="flat" :loading="this.delete.deleting" color="primary-red" @click="deletedFormVariant"
            class="mr-4 font-weight-bold text-none">Delete</v-btn>
        </v-card-actions>
        <p class="error-text text-center">{{ this.delete.errorMessage }}</p>
      </v-card>
    </v-dialog>

    <!-- Enable Standard Modal -->
    <v-dialog v-model="modals.standardModeModal" width="550px">
      <v-card>
        <template v-slot:title><strong>Enable Standard Mode</strong></template>
        <v-card-text>
          <v-alert border type="warning" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">You form is currently running as an active A/B test. When switching to standard
              traffic mode, all traffic is
              directed to the champion form variant. All other variants will become inactive.</p>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn class="font-weight-bold text-none" variant="tonal"
            @click="modals.standardModeModal = false">Cancel</v-btn>
          <v-btn variant="flat" color="primary-red" class="mr-2 text-none font-weight-bold"
            @click="enableStandardMode(runningExperiments)">Enable
            Standard
            Mode</v-btn>
        </v-card-actions>
        <p class="error-text text-center">{{ stopErrorMessage }}</p>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as actionTypes from '@/store/action-types'
import variantTypes from '@/store/variant-types'
import helperMixin from '@/mixins/helper'
import authMixin from '@/mixins/auth'
import config from '@/api/config'
import formBuilderTabs from '@/shared/constants/formBuilderTabs.js'
import ErrorTypes from '@/store/error-types'

export default {
  mixins: [helperMixin, authMixin],
  data: function () {
    return {
      resetting: false,
      championVariant: {
        newChampionId: null,
        promoting: false,
        errorMessage: '',
      },
      duplicate: {
        variant: null,
        duplicating: false,
        errorMessage: '',
        modal: false
      },
      delete: {
        variant: null,
        deleting: false,
        errorMessage: '',
        modal: false,
      },
      modals: {
        promoteToChampionModal: false,
        standardModeModal: false,
        resetConfirmDialog: false,
      },
      formExp: false,
      loadingExperiments: true,
      abTest: '',
      standard: '',
      stopErrorMessage: '',
      endingExperiment: false,
    }
  },
  mounted: function () {
    this.fetchForm()
    this.fetchFormVariants()
    this.fetchFormExperiments()
  },
  methods:
  {
    onConfirmReset: function () {
      this.resetting = true
      this.$store.dispatch('formResetStatus/resetFormStatus', {
        id: this.formId
      }).then((response) => {
        this.$store.dispatch('formResetStatus/resetFormStatusSuccess')
        this.fetchForm()
        this.fetchFormVariants()
        this.fetchFormExperiments()
        this.resetting = false
        this.modals.resetConfirmDialog = false
      }, (err) => {
        this.$store.dispatch('formResetStatus/resetFormStatusError')
      })
    },
    stopExperiments: function () {
      if (this.runningExperiments.length > 0) {
        this.modals.standardModeModal = true
      }
    },
    enableStandardMode: function (experiments) {
      this.endingExperiment = true
      this.stopErrorMessage = ''
      this.$store.dispatch(actionTypes.FORM_EXPERIMENT_END, {
        formId: this.formId,
        experimentId: experiments[0]?.id
      }).then((response) => {
        this.endingExperiment = false
        this.modals.standardModeModal = false
        this.$store.dispatch(actionTypes.FORM_EXPERIMENT_END_SUCCESS, response.data.data)
          .then(() => {
            // this.$router.push({name: 'forms.experiments.detail', params: {id: this.formId, experimentId: experiments[0].id}})
          })
      }, (response) => {
        this.endingExperiment = false
        this.$store.dispatch(actionTypes.FORM_EXPERIMENT_END_ERROR)
        if (response.response.data.meta.error_type === ErrorTypes.EXPERIMENT_NOT_STARTED) {
          this.stopErrorMessage = 'Experiment is not started yet.'
        } else if (response.response.data.meta.error_type === ErrorTypes.EXPERIMENT_ALREADY_ENDED) {
          this.stopErrorMessage = 'Experiment already ended'
        } else {
          this.stopErrorMessage = 'Unable to end experiment, please try again.'
        }
      })
    },

    goToView: function (view) {
      if (view === 'overview') {
        this.$router.push({ name: 'forms.overview', params: { id: this.formId } })
      } else if (view === 'share') {
        this.$router.push({ name: 'forms.share', params: { id: this.formId } })
      } else if (view === 'responses') {
        this.$router.push({ name: 'forms.responses', params: { id: this.formId } })
      } else if (view === 'experiments') {
        this.$router.push({ name: 'forms.experiments.overview', params: { id: this.formId } })
      } else if (view === 'webhooks') {
        this.$router.push({ name: 'forms.webhooks.index' })
      } else {
        this.$router.push({ name: 'forms.settings', params: { id: this.formId } })
      }
    },
    conversionRate: function (conversions, visitors) {
      if (!visitors || !conversions) {
        return 0
      }
      return ((conversions / visitors) * 100).toFixed(2)
    },
    goToVariant: function (id) {
      if (id) {
        this.$router.push({ name: 'forms.variants.edit', params: { id: this.$route.params.id, variantId: id, tab: formBuilderTabs.BUILD } })
      }
    },
    fetchForm: function () {
      this.$store.dispatch(actionTypes.FETCH_FORM, {
        id: this.$route.params.id
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_SUCCESS, response.data.data)
      }, (err) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_ERROR, {
          formId: this.$route.params.id
        })
      })
    },
    fetchFormVariants: function () {
      this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS, {
        formId: this.$route.params.id
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS_SUCCESS, response.data.data)
      }, (err) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_VARIANTS_ERROR, {
          formId: this.$route.params.id
        })
      })
    },
    duplicatedFormVariant: function () {
      if (!this.duplicate.variant) {
        return
      }
      this.duplicate.duplicating = true
      this.duplicate.errorMessage = ''
      this.$store.dispatch(actionTypes.DUPLICATE_FORM_VARIANT, {
        formId: this.$route.params.id,
        variantId: this.duplicate.variant.id
      }).then((response) => {
        this.duplicate.duplicating = false
        this.$store.dispatch(actionTypes.DUPLICATE_FORM_VARIANT_SUCCESS, response.data.data)
        this.duplicate.modal = false
      }, (err) => {
        this.duplicate.duplicating = false
        this.$store.dispatch(actionTypes.DUPLICATE_FORM_VARIANT_ERROR, null)
        this.duplicate.errorMessage = 'Unable to duplicate please try again.'
      })
    },
    deletedFormVariant: function () {
      if (!this.delete.variant) {
        return
      }
      this.delete.deleting = true
      this.delete.errorMessage = ''
      this.$store.dispatch(actionTypes.DELETE_FORM_VARIANT, {
        formId: this.$route.params.id,
        variantId: this.delete.variant.id
      }).then((response) => {
        this.delete.deleting = false
        this.$store.dispatch(actionTypes.DELETE_FORM_VARIANT_SUCCESS, response.data.data)
        this.delete.modal = false
        this.fetchFormVariants()
      }, (err) => {
        this.delete.deleting = false
        this.$store.dispatch(actionTypes.DELETE_FORM_VARIANT_ERROR, null)
        this.delete.errorMessage = err?.data?.meta?.error_message || 'Unable to delete please try again.'
      })
    },
    fetchFormExperiments: function () {
      this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENTS, {
        formId: this.$route.params.id
      }).then((response) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENTS_SUCCESS, response.data.data)
        this.loadingFormExperiment = false
      }, (err) => {
        this.$store.dispatch(actionTypes.FETCH_FORM_EXPERIMENTS_ERROR, {
          formId: this.$route.params.id
        })
        this.loadingExperiments = false
      })
    },
    setNewChampion: function (id) {
      this.championVariant.newChampionId = id
      this.modals.promoteToChampionModal = true
    },
    promoteToChampion: function () {
      if (!this.championVariant.newChampionId) {
        return
      }
      this.championVariant.promoting = true
      this.championVariant.errorMessage = ''
      this.$store.dispatch(actionTypes.PROMOTE_FORM_VARIANT, {
        formId: this.formId,
        variantId: this.championVariant.newChampionId
      })
        .then((response) => {
          this.championVariant.promoting = false
          this.fetchFormVariants()
          this.promoteToChampion = false
        }, (err) => {
          this.championVariant.promoting = false
          this.championVariant.errorMessage = response?.data?.meta?.error_message || 'You must stop the running experiment.'
        })
    },
    setDuplicateVariant: function (variant) {
      this.duplicate.variant = variant
      this.duplicate.modal = true
    },
    setDeleteVariant: function (variant) {
      this.delete.variant = variant
      this.delete.modal = true
    },
    onChampionMoreAction: function (action) {
      if (action.action === 'duplicate') {
        this.setDuplicateVariant(action.variant)
      }
      if (action.action === 'delete') {
        this.setDeleteVariant(action.variant)
      }
    },
    onChallengerMoreAction: function (action) {
      if (action.action === 'duplicate') {
        this.setDuplicateVariant(action.variant)
      } else if (action.action === 'make_champion') {
        this.setNewChampion(action.variant.id)
      } else if (action.action === 'delete') {
        this.setDeleteVariant(action.variant)
      }
    },
    previewLink: function (variant) {
      if (!variant) {
        return ''
      }
      const token = this.authMixin_token
      return `${config.formsUrl}/preview/forms/${this.formId}/variants/${variant.id}?token=${token}`
    }
  },
  computed: {
    formId: function () {
      return parseInt(this.$route.params.id)
    },
    formKey: function () {
      return this.form.key
    },
    formUrl: function () {
      if (!this.formKey) {
        return ''
      }
      return config.formsUrl + '/' + this.formKey
    },
    form: function () {
      return this.$store.getters.getForm(this.formId) || {}
    },
    variants: function () {
      return this.$store.getters.getFormVariants(this.formId)
    },
    champion: function () {
      for (let v of this.variants) {
        if (v.form_variant_type.type === variantTypes.CHAMPION) {
          return v
        }
      }
      return {}
    },
    isCurrentModeAbTest() {
      return this.runningExperiments.length > 0
    },
    challengers: function () {
      let challengers = []
      for (let v of this.variants) {
        if (v.form_variant_type.type === variantTypes.CHAMPION) {
          continue
        }
        challengers.push(v)
      }
      return challengers
    },
    runningExperiments: function () {
      return this.$store.getters.runningExperiments(this.formId) || []
    }
  }
}
</script>
