<template>
  <div class="account-expired-modal-cpt">
    <ui-modal
      ref="accountExpiredModal"
      class="account-expired-modal-cpt__modal"
      size="large"
      dismiss-on="close-button"
      remove-close-button
    >
      <div slot="header" class="header-class">
        <div class="header-logo-left">
          <img
            src="./../../assets/images/icons/plan-header-one.svg"
            alt="Leadgen"
          />
        </div>
        <div class="header-text"></div>
        <div class="header-logo-right">
          <img
            src="./../../assets/images/icons/plan-header-two.svg"
            alt="Capterra"
          />
        </div>
      </div>
      <div class="account-expired-container">
        <div class="account-expired-content">
          <div class="account-expired-left-section">
            <ui-button
              size="small"
              color="red"
              disabled
              class="account-expired-button"
              >your account has expired</ui-button
            >
            <h1>Welcome Back {{ userName }}</h1>
            <p>
              Your account is currently inactive. To access your forms and grow
              your business with new lead generation campaigns, subscribe to
              LeadGen Pro and you're ready to go.
            </p>
            <br />
            <div class="account-expired-plan-detail">
              <div class="plan-type">
                <h3 class="plan-title">Pro Plan</h3>
                <p class="plan-price">$49/month</p>
              </div>
              <div class="plan-detail-list">
                <ul>
                  <li>
                    <span><i class="fas fa-check text-success"></i></span
                    >Unlimited leads
                  </li>
                  <li>
                    <span><i class="fas fa-check text-success"></i></span>20
                    Forms
                  </li>
                  <li>
                    <span><i class="fas fa-check text-success"></i></span>Remove
                    LeadGen branding
                  </li>
                  <li>
                    <span><i class="fas fa-check text-success"></i></span>Cancel
                    anytime
                  </li>
                </ul>
              </div>
            </div>
            <br />
            <div class="left-logos">
              <img
                src="./../../assets/images/icons/plan-footer.svg"
                alt="Support"
              />
            </div>
          </div>
          <div class="account-expired-right-section"></div>
        </div>
      </div>
    </ui-modal>
  </div>
</template>

<script>
export default {
  props: ['userName']
}
</script>
