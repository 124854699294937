<template>
  <v-container class="external-checkout-form pt-1" style="width: 80%">
    <v-row class="align-center justify-space-between mt-n6">
      <v-col cols="12" md="6" class="text-left">
        <router-link :to="{ name: 'externalcheckout.index.list' }" class="text-primary-red mb-5 link">
          <v-icon left>mdi-arrow-left</v-icon>
          External Checkout
        </router-link>
      </v-col>
    </v-row>

    <div v-if="isLoading" class="loader-container">
      <div class="d-flex justify-center align-center" style="height: 90vh;">
        <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
          color="secondary-red"></v-progress-circular>
      </div>
    </div>

    <div v-else>
      <div class="mb-4 d-flex align-center justify-space-between">
        <div class="mb-n4" v-if="code && showCode">
          Embed below script on your Website/Landing page to use External Checkout.
        </div>
        <div v-else class="flex-grow-1"></div>
        <v-btn variant="flat" type="submit" :loading="submitting" :prepend-icon="isEditView ? 'mdi-update' : 'mdi-plus'"
          class="bg-primary-red font-weight-bold text-none px-4" @click="onFormSubmit">
          {{ isEditView ? 'Update' : 'Create' }} Checkout
        </v-btn>
      </div>

      <v-card v-if="code.length > 0 && showCode" color="grey-darken-4"
        class="overflow-hidden my-3 rounded-thin overflow-hidden" rounded="md">
        <v-card-text class="position-relative">
          <v-btn icon="mdi-content-copy" title="Copy External Checkout" size="small" color="grey"
            class="position-absolute top-0 right-0 my-3 mx-2" @click="copyCode"></v-btn>
          <v-sheet color="transparent" class="overflow-x-auto px-2">
            <pre><code v-html="highlightedCode" class="text-wrap text-pre-wrap"></code></pre>
          </v-sheet>
        </v-card-text>
      </v-card>

      <v-card rounded="md" elevation="0" class="border-thin border-t-thin rounded-t-sm">
        <v-card-title class="bg-blue-lighten-5 text-primary-blue">
          <h6 class="d-flex justify-center mb-0">
            <v-icon icon="mdi-open-in-app" class="mt-1"></v-icon>
            <p class="font-weight-bold text-primary-blue mb-0">&nbsp;
              {{ isEditView ? 'Update' : 'Create' }} External Checkout
            </p>
          </h6>
        </v-card-title>
        <v-card-text class="px-10">
          <v-form ref="formRef" @submit.prevent="onFormSubmit">
            <v-row class="px-2 py-5">
              <v-col cols="12" md="6" class="pb-0 pt-2">
                <v-text-field v-model="formData.title" label="Title (for admin)" prepend-inner-icon="mdi-text-short"
                  variant="outlined" density="default" rounded="md"
                  :rules="$valid([{ rule: 'required', fieldName: 'Title' }, { rule: 'between', min: '5', max: '50' }])"
                  required placeholder="Enter Title"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pb-0 pt-2">
                <v-text-field v-model="formData.description" label="Description (for admin)"
                  :rules="$valid([{ rule: 'between', min: '3', max: '300' }])" placeholder="Enter Description"
                  prepend-inner-icon="mdi-text-long" variant="outlined" density="default" rounded="md"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field v-model="formData.form_heading" label="Form Heading" placeholder="Enter Form Heading"
                  prepend-inner-icon="mdi-format-header-equal" variant="outlined" density="default" rounded="md"
                  :rules="$valid([{ rule: 'required' }, { rule: 'between', min: '5', max: '50' }])"
                  required></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-autocomplete v-model="formData.plan_id"
                  :items="typeof plans === 'string' ? JSON.parse(plans) : plans" item-title="title" item-value="id"
                  label="Select Plan" placeholder="Type to search plans" prepend-inner-icon="mdi-currency-usd"
                  variant="outlined" density="default" clearable :rules="$valid([{ rule: 'required' }])"
                  required></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" class="py-0" v-if="!formData.login">
                <v-text-field v-model="formData.redirect_url" label="Redirect Url (must start with http:// or https://)"
                  placeholder="Enter Redirect Url " prepend-inner-icon="mdi-link" variant="outlined" density="default"
                  rounded="md" :rules="$valid([
                    { rule: 'required' },
                    { rule: 'url', params: { fieldName: 'Redirect URL' } }
                  ])" required></v-text-field>
              </v-col>
            </v-row>

            <v-row class="px-2 py-0 my-0">
              <v-col cols="12" md="6">
                <v-switch inset v-model="formData.login" label="Login user after sign-up"
                  color="primary-red"></v-switch>
              </v-col>
              <v-col cols="12" md="6">
                <v-switch inset v-model="formData.enable" label="Enable" color="primary-red"></v-switch>
              </v-col>
            </v-row>

            <v-alert type="info" variant="tonal" rounded="md" density="compact" class="mb-4">
              <p class="mb-0 text-black text-subtitle-1">
                <strong>Note:</strong> Field names must be correct. Use <strong>"name"</strong> for name,
                <strong>"email"</strong> for email, and <strong>"password"</strong> for password. These parameters must
                be set up for each checkout.
              </p>
            </v-alert>

            <v-btn @click="addField" variant="flat" prepend-icon="mdi-plus"
              class="bg-primary-green font-weight-bold text-none mb-3 px-4">Add
              Field</v-btn>

            <v-row v-for="(field, index) in fields" :key="index" class="px-2 py-1 mt-1">
              <v-col cols="12" md="4">
                <v-text-field v-model="field.label" label="Label" variant="outlined" density="comfortable"
                  :rules="$valid([{ rule: 'required' }, { rule: 'alphaSpaces' }, { rule: 'between', min: '3', max: '30' }])"
                  required></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field v-model="field.name" label="Field Name" variant="outlined" density="comfortable"
                  :rules="$valid([{ rule: 'required' }, { rule: 'alpha' }, { rule: 'between', min: '3', max: '10' }])"
                  required></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-autocomplete v-model="field.type" :items="options" item-title="text" item-value="value"
                  label="Input Type" variant="outlined" density="comfortable" :rules="$valid([{ rule: 'required' }])"
                  required></v-autocomplete>
              </v-col>
              <v-col cols="12" md="1">
                <v-btn variant="text" color="primary-red" icon @click="removeField(field)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </v-container>

  <v-snackbar rounded="md" height="50" :color="snackbar.color" :timeout="snackbar.timeout" v-model="snackbar.visible">
    <p class="text-center font-weight-bold mb-0 text-white py-0"> {{ snackbar.message }} </p>
  </v-snackbar>
</template>

<script setup>
import { ref, computed, watch, onMounted, nextTick } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import config from '@/api/config'
import Prism from 'prismjs'

import * as actionTypes from '@/store/action-types'

const formRef = ref(null)
const formData = ref({
  title: '',
  description: '',
  form_heading: '',
  plan_id: null,
  redirect_url: '',
  login: false,
  enable: false,
  refId: '',
  label: '',
  name: '',
  type: ''
})
const options = ['text', 'email', 'password']
const submitting = ref(false)
const showCode = ref(false)
const isLoading = ref(true)
const fields = ref([])
const exists = ref(false)
const store = useStore()
const router = useRouter()
const route = useRoute()
const plans = computed(() => store.state.plans)

const isEditView = computed(() => route.name === 'externalcheckout.edit')
const code = computed(() => {
  if (!formData.value.refId) {
    return ''
  }
  return `<script src="${config.scriptsUrl}/external-checkout/${formData.value.refId}" async></scri` + `pt>` + `<div id="external-checkout-registration-${formData.value.refId}" class="external-checkout-registration"></div>`
})

const highlightedCode = computed(() => {
  return Prism.highlight(code.value, Prism.languages.html, 'html')
})


const fetchPlans = async () => {
  try {
    const response = await store.dispatch(actionTypes.FETCH_PLANS)
    await store.dispatch(actionTypes.FETCH_PLANS_SUCCESS, response.data.data)
    return response
  } catch (error) {
    await store.dispatch(actionTypes.FETCH_PLANS_SUCCESS, [])
    return []
  }
}

const fetchExternalCheckout = async () => {
  isLoading.value = true
  try {
    const id = route.params.id
    if (!id) {
      return
    }
    const response = await store.dispatch('externalcheckout/getExternalCheckout', { id })
    const externalCheckout = response.data.data
    formData.value = {
      ...formData.value,
      title: externalCheckout.title,
      description: externalCheckout.description,
      form_heading: externalCheckout.form_heading,
      plan_id: externalCheckout.plan_id,
      redirect_url: externalCheckout.redirect_url,
      enable: Boolean(externalCheckout.enable),
      refId: externalCheckout.ref_id,
      login: Boolean(externalCheckout.login)
    }
    fields.value = JSON.parse(externalCheckout.fields)
    showCode.value = true
  } catch (error) {
    showSnackbar('Failed to fetch external checkout data. Please try again.', { color: 'secondary-red' })
  } finally {
    isLoading.value = false
  }
}

const validateForm = async () => {
  if (formRef.value) {
    const { valid, errors } = await formRef.value.validate()
    return { valid, errors }
  }
  return { valid: false, errors: ['Form reference not found'] }
}

const onFormSubmit = async () => {
  try {
    submitting.value = true

    const { valid } = await validateForm()
    if (!valid) {
      return
    }

    const data = {
      ...formData.value,
      plan_id: typeof formData.value.plan_id === 'object' ? formData.value.plan_id.id : formData.value.plan_id,
      fields: JSON.stringify(fields.value),
      enable: formData.value.enable ? 1 : 0
    }

    if (!data.login) {
      if (!data.redirect_url) {
        showSnackbar('Redirect URL is required when login is disabled.', { color: 'secondary-red' })
        return
      }
      if (!/^https?:\/\//i.test(data.redirect_url)) {
        data.redirect_url = `https://${data.redirect_url}`
      }
    } else {
      data.redirect_url = null
    }

    let response
    if (!isEditView.value) {
      response = await store.dispatch('externalcheckout/createExternalCheckout', data)
      if (response && response.data && response.data.data && response.data.data.id) {
        await router.push({ name: 'externalcheckout.edit', params: { id: response.data.data.id } })
        await nextTick()
        await fetchExternalCheckout()
      } else {
        throw new Error('Invalid response data')
      }
    } else {
      response = await store.dispatch('externalcheckout/updateExternalCheckout', { id: parseInt(route.params.id), data })
      await fetchExternalCheckout()
    }

    if (!response || !response.data || !response.data.data) {
      throw new Error('Invalid response from server')
    }

    formData.value = { ...formData.value, ...response.data.data }
    showSnackbar(isEditView.value ? 'External checkout updated successfully.' : 'External checkout created successfully.', { color: 'primary-green' })

    if (!isEditView.value && response.data.data.id) {
      router.push({ name: 'externalcheckout.edit', params: { id: response.data.data.id } })
    }

    formData.value.refId = response.data.data.ref_id
    showCode.value = true

    await fetchExternalCheckout()
  } catch (error) {
    if (error.response?.data?.meta?.error_type === 'INAPPROPRIATE_PLAN') {
      showSnackbar('Please upgrade your plan to create external checkouts.', { color: 'secondary-red' })
    } else {
      const errorMessage = error.response?.data?.meta?.error_message || error.message || 'An unknown error occurred'
      showSnackbar(`Failed to save external checkout: ${errorMessage}`, { color: 'secondary-red' })
    }
  } finally {
    submitting.value = false
  }
}
const snackbar = ref({
  visible: false,
  message: '',
  color: 'primary-green',
  timeout: 5000
})

const showSnackbar = (message, options = {}) => {
  snackbar.value = {
    visible: true,
    message,
    color: options.color || 'primary-green',
    timeout: options.timeout || 5000
  }
}
const copyCode = () => {
  navigator.clipboard.writeText(code.value).then(() => {
    showSnackbar('Code copied to clipboard.')
  })
}

const checkIfExists = (fieldId) => {
  exists.value = fields.value.some((field) => field.id === fieldId)
}

const addField = () => {
  let newId = fields.value.length + 1
  checkIfExists(newId)

  if (!exists.value) {
    fields.value.push({
      id: newId,
      label: '',
      name: '',
      type: null
    })
  } else {
    newId = fields.value.length + 2
    checkIfExists(newId)
    if (!exists.value) {
      fields.value.push({
        id: newId,
        label: '',
        name: '',
        type: null
      })
    } else {
      fields.value.push({
        id: fields.value.length + 3,
        label: '',
        name: '',
        type: null
      })
    }
  }
}

const removeField = (field) => {
  const index = fields.value.indexOf(field)
  fields.value.splice(index, 1)
}

onMounted(async () => {
  isLoading.value = true
  await fetchPlans()
  if (isEditView.value) {
    await fetchExternalCheckout()
  } else {
    isLoading.value = false
  }
  await highlightedCode
})

watch(
  () => formData.value.plan_id,
  (newValue) => {
    if (newValue === null || newValue === undefined) {
      formData.value.plan_id = ''
    }
  }
)

watch(
  () => formData.value.refId,
  (newValue) => {
    if (newValue) {
      showCode.value = true
      nextTick(() => {
        Prism.highlightAll()
      })
    }
  }
)

onMounted(async () => {
  isLoading.value = true
  await fetchPlans()
  if (isEditView.value) {
    await fetchExternalCheckout()
  } else {
    isLoading.value = false
  }
})
</script>
