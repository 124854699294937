<template>
  <v-container style="width: 80%;" class="recaptcha-view">
    <v-row>
      <v-col cols="12 pa-0">
        <h4 class="page-title">reCAPTCHA</h4>
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between pa-0 mb-n4">
        <v-text-field max-width="500px" v-model="search" density="compact" label="Search reCAPTCHA" clearable
          prepend-inner-icon="mdi-magnify" variant="outlined" rounded></v-text-field>
        <v-btn prepend-icon="mdi-plus" color="primary-red text-none font-weight-black" rounded="md"
          class="mb-7 font-weight-bold" @click="openCreateDialog">Add reCAPTCHA</v-btn>
      </v-col>
      <v-data-table :headers="headers" :items="filteredRecaptchaKeys" :items-per-page="itemsPerPage"
        @update:page="currentPage = $event" :page="currentPage" :loading="fetching" class="border-thin rounded">
        <template v-slot:loader>
          <v-progress-linear color="secondary-red" indeterminate height="2"></v-progress-linear>
        </template>
        <template v-slot:item.index="{ index }">
          {{ calculateIndex(index) }}
        </template>
        <template v-slot:item.title="{ item }">
          <a class="list-item-action" @click="editRecaptcha(item)">{{ item.title }}</a>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small @click="openDeleteRecaptchaModal(item)" color="primary-red" :disabled="item.forms_count !== 0">
            mdi-delete
          </v-icon>
          <v-tooltip location="bottom" v-if="item.forms_count !== 0">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props" small color="black">mdi-information</v-icon>
            </template>
            <span>You can't delete reCAPTCHA unless you remove it from every form it's used in.</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-row>

    <!-- Modals -->
    <v-dialog v-model="showRecaptchaModal" max-width="600" rounded="sm">
      <v-card prepend-icon="mdi-cached" class="form-title">
        <template v-slot:title>
          <strong>{{ isEditing ? 'Update ' : 'Add ' }} Google reCAPTCHA</strong>
        </template>
        <v-divider></v-divider>
        <v-card-text>
          <v-alert v-if="saveError" type="error" dismissible rounded="sm" density="compact" class="mb-4">
            {{ saveErrorMessage }}
          </v-alert>
          <google-recaptcha-key :edit-action="isEditing" :recaptcha-data="recaptchaForm" @save="saveRecaptcha"
            @recaptcha-complete="onRecaptchaComplete" @save-error="onSaveError" @closeDialog="handleDialogClose" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeleteModal" max-width="600px">
      <v-card max-width="600" prepend-icon="mdi-trash-can-outline">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-card-text>
          <v-alert v-if="deleteError" border type="warning" variant="tonal" density="compact">
            <p class="text-black ma-0">Unable to delete this reCAPTCHA. Please try again.</p>
          </v-alert>
          <v-spacer class="my-4"></v-spacer>
          <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">You are about to delete this reCAPTCHA. Are you sure you
              want to proceed?</p>
          </v-alert>
        </v-card-text>
        <v-card-actions class="mb-3 mr-3">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="showDeleteModal = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="primary-red" :loading="deleting"
            @click="deleteRecaptcha">
            <span>Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { ref, onMounted, computed, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import GoogleRecaptchaKey from '@/components/GoogleRecaptchaKey.vue'

export default {
  components: {
    GoogleRecaptchaKey
  },
  setup() {
    const store = useStore()
    const search = ref('')
    const showRecaptchaModal = ref(false)
    const showDeleteModal = ref(false)
    const isEditing = ref(false)
    const recaptchaForm = ref({})
    const selectedRecaptcha = ref({})
    const fetching = ref(true)
    const deleting = ref(false)
    const deleteError = ref(false)
    const saveError = ref(false)
    const saveErrorMessage = ref('')
    const currentPage = ref(1)
    const itemsPerPage = ref(10)
    const totalItems = ref(0)

    const headers = [
      { title: '#', key: 'index', width: '10%' },
      { title: 'Title', key: 'title', width: '10%' },
      { title: 'Site Key', key: 'site_key', width: '15%' },
      { title: 'Secret Key', key: 'secret_key', width: '15%' },
      { title: 'Used in Forms', key: 'forms_count', align: 'center', width: '15%' },
      { title: 'Actions', key: 'actions', sortable: false, width: '5%' }
    ]

    const calculateIndex = (index) => {
      return (currentPage.value - 1) * itemsPerPage.value + index + 1
    }

    const fetchGoogleRecaptchaKeys = async () => {
      fetching.value = true
      try {
        const response = await store.dispatch('googlerecaptcha/fetchRecaptchaKeys')
        const data = response.data && response.data.data ? response.data.data : response
        await store.dispatch('googlerecaptcha/fetchRecaptchaKeysSuccess', data)
      } catch (error) {
      } finally {
        fetching.value = false
      }
    }

    const filteredRecaptchaKeys = computed(() => {
      let keys = store.getters['googlerecaptcha/getGoogleRecaptchaKeys'] || []

      if (!Array.isArray(keys)) {
        keys = []
      }

      if (search.value) {
        keys = keys.filter((item) =>
          item.title.toLowerCase().includes(search.value.toLowerCase())
        )
      }

      return keys.map((key) => ({
        ...key
      })).sort((a, b) => b.id - a.id)
    })

    const onRecaptchaComplete = (success) => {
      if (success) {
        showRecaptchaModal.value = false
        fetchGoogleRecaptchaKeys()
        saveError.value = false
        saveErrorMessage.value = ''
      }
    }

    onMounted(fetchGoogleRecaptchaKeys)

    const openCreateDialog = () => {
      isEditing.value = false
      recaptchaForm.value = {}
      showRecaptchaModal.value = true
    }

    const editRecaptcha = (item) => {
      isEditing.value = true
      recaptchaForm.value = { ...item }
      nextTick(() => {
        showRecaptchaModal.value = true
      })
    }
    const onSaveError = (error) => {
      saveError.value = true
      saveErrorMessage.value = error.message || 'An error occurred while saving'
    }

    const handleDialogClose = () => {
      showRecaptchaModal.value = false
    }
    const openDeleteRecaptchaModal = (item) => {
      if (!item) {
        return
      }
      if (item.forms_count === 0) {
        selectedRecaptcha.value = { ...item }
        showDeleteModal.value = true
      } else {
      }
    }

    const deleteRecaptcha = async () => {
      const recaptcha = selectedRecaptcha.value

      if (!recaptcha || !recaptcha.id) {
        deleteError.value = true
        return
      }

      deleting.value = true
      deleteError.value = false

      try {
        await store.dispatch('googlerecaptcha/deleteRecaptchaKey', recaptcha)
        showDeleteModal.value = false
        await fetchGoogleRecaptchaKeys()
      } catch (error) {
        deleteError.value = true
      } finally {
        deleting.value = false
      }
      watch(() => isEditing.value, (newValue) => {
        if (!newValue) {
          recaptchaForm.value = {}
        }
      })
    }

    return {
      search,
      headers,
      showRecaptchaModal,
      showDeleteModal,
      isEditing,
      recaptchaForm,
      selectedRecaptcha,
      fetching,
      deleting,
      deleteError,
      saveError,
      saveErrorMessage,
      currentPage,
      itemsPerPage,
      totalItems,
      filteredRecaptchaKeys,
      calculateIndex,
      openCreateDialog,
      editRecaptcha,
      openDeleteRecaptchaModal,
      deleteRecaptcha,
      onRecaptchaComplete,
      onSaveError,
      handleDialogClose
    }
  }
}
</script>
