import * as mutationTypes from '@/store/mutation-types'
import * as GoogleRecaptchaApi from '@/api/googlerecaptcha'
import Vue from 'vue'

const state = {
  googleRecaptchaKeys: []
}

const getters = {
  getGoogleRecaptchaKeys: function (state) {
    return state.googleRecaptchaKeys
  }
}

const actions = {
  fetchRecaptchaKeys ({state, commit}, data) {
    commit(mutationTypes.FETCH_GOOGLE_RECAPTCHA_KEYS)
    return GoogleRecaptchaApi.fetchRecaptchaKeys()
  },
  fetchRecaptchaKeysSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_GOOGLE_RECAPTCHA_KEYS_SUCCESS, data)
  },

  createRecaptchaKey ({state, commit}, data) {
    commit(mutationTypes.CREATE_GOOGLE_RECAPTCHA_KEY)
    return GoogleRecaptchaApi.createRecaptchaKey(data)
  },
  createRecaptchaKeySuccess ({state, commit}, data) {
    commit(mutationTypes.CREATE_GOOGLE_RECAPTCHA_KEY_SUCCESS, data)
  },

  updateRecaptchaKey ({state, commit}, data) {
    commit(mutationTypes.UPDATE_GOOGLE_RECAPTCHA_KEY)
    return GoogleRecaptchaApi.updateRecaptchaKey(data)
  },
  updateRecaptchaKeySuccess ({state, commit}, data) {
    commit(mutationTypes.UPDATE_GOOGLE_RECAPTCHA_KEY_SUCCESS, data)
  },

  deleteRecaptchaKey ({state, commit}, data) {
    commit(mutationTypes.DELETE_GOOGLE_RECAPTCHA_KEY)
    return GoogleRecaptchaApi.deleteRecaptchaKey(data.id)
  },
  deleteRecaptchaKeySuccess ({state, commit}, data) {
    commit(mutationTypes.DELETE_GOOGLE_RECAPTCHA_KEY_SUCCESS, data)
  }
}

const mutations = {
  [mutationTypes.FETCH_GOOGLE_RECAPTCHA_KEYS] (state) {
  },
  [mutationTypes.FETCH_GOOGLE_RECAPTCHA_KEYS_SUCCESS] (state, data) {
    state.googleRecaptchaKeys = data || []
  },

  [mutationTypes.CREATE_GOOGLE_RECAPTCHA_KEY] (state) {
  },
  [mutationTypes.CREATE_GOOGLE_RECAPTCHA_KEY_SUCCESS] (state, data) {
    Vue.set(state.googleRecaptchaKeys, state.googleRecaptchaKeys.length, data)
  },

  [mutationTypes.UPDATE_GOOGLE_RECAPTCHA_KEY] (state) {
  },
  [mutationTypes.UPDATE_GOOGLE_RECAPTCHA_KEY_SUCCESS] (state, data) {
    let index = 0
    for (let gkey of state.googleRecaptchaKeys) {
      if (gkey.id === data.id) {
        Vue.set(state.googleRecaptchaKeys, index, data)
      }
      index++
    }
  },

  [mutationTypes.DELETE_GOOGLE_RECAPTCHA_KEY] (state) {
  },
  [mutationTypes.DELETE_GOOGLE_RECAPTCHA_KEY_SUCCESS] (state, data) {
    let index = 0
    for (let gkey of state.googleRecaptchaKeys) {
      if (gkey.id === data.id) {
        state.googleRecaptchaKeys.splice(index, 1)
        Vue.set(state.googleRecaptchaKeys, state.googleRecaptchaKeys, data)
      }
      index++
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
