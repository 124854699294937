<template>
  <div class="subscription-view__main">
    <div class="container">
      <div class="subscription-view__main__alerts">
        <div class="alerts-container">
          <div class="success-alert-messages">
            <!-- subscription alert message -->
            <ui-alert type="success" :dismissible="false" v-if="subscriptionStatus.subscribeSuccess">Congrats!
              You have successfully subscribed to LeadGen {{ plan.title.replace('Trial', '') }} Plan.</ui-alert>
            <!-- subscription upgrade alert message -->
            <ui-alert type="success" :dismissible="false" v-if="subscriptionStatus.upgradeSuccess">Congrats!
              You have successfully upgraded to LeadGen {{ plan.title }} Plan.</ui-alert>
            <!-- cancel subscription alert message -->
            <ui-alert type="success" :dismissible="false" v-if="subscriptionStatus.cancelSuccess">
              You have successfully cancelled your subscription plan.</ui-alert>
            <!-- downgrade plan alert message -->
            <ui-alert type="success" :dismissible="false" v-if="subscriptionStatus.downgradeSuccess">
              You have successfully downgraded your subscription plan.
              Re-subscribe any time or choose another Plan.
            </ui-alert>
            <!-- reschedule payment plan alert message -->
            <ui-alert type="success" :dismissible="false" v-if="subscriptionStatus.reschedulePaymentSuccess">
              Congrats! You have successfully subscribed to LeadGen {{ plan.title.replace('Trial', '') }} Plan
            </ui-alert>
            <!-- pause plan alert message -->
            <ui-alert type="success" :dismissible="false" v-if="subscriptionStatus.pauseSuccess">
              You have successfully cancelled your subscription plan. Resume any time.
            </ui-alert>
            <!-- resume plan alert message -->
            <ui-alert type="success" :dismissible="false" v-if="subscriptionStatus.resumeSuccess">
              You have successfully resumed your subscription plan.
            </ui-alert>
            <!-- upgrade message -->
            <ui-alert type="info" :dismissible="false" v-if="loading">
              Please wait, we are processing your request.
            </ui-alert>
            <ui-alert type="error" :dismissible="false" v-else-if="subscriptionStatus.upgradeFailed">
              Unable to upgrade, Try again.
            </ui-alert>
          </div>
          <div class="error-alert-messages">
            <!-- subscription alert message -->
            <ui-alert type="error" :dismissible="false" v-if="subscriptionStatus.subscribeFailed">
              Something went wrong and we cannot process your request for plan subscription. Try again later.</ui-alert>
            <!-- subscription upgrade alert message -->
            <ui-alert type="error" :dismissible="false" v-if="subscriptionStatus.upgradeFailed">
              Something went wrong and we cannot process your request for plan upgrade. Try again later.</ui-alert>
            <!-- cancel subscription alert message -->
            <ui-alert type="error" :dismissible="false" v-if="subscriptionStatus.cancelFailed">
              Something went wrong and we cannot process your request for plan cancellation. Try again later.</ui-alert>
            <!-- downgrade plan alert message -->
            <ui-alert type="error" :dismissible="false" v-if="subscriptionStatus.downgradeFailed">
              Something went wrong and we cannot process your request for plan downgrade. Try again later.</ui-alert>
            <!-- reschedule payment plan alert message -->
            <ui-alert type="error" :dismissible="false" v-if="subscriptionStatus.reschedulePaymentFailed">
              Something went wrong and we cannot fetch your subscription details. Try again later.
            </ui-alert>
            <!-- pause plan alert message -->
            <ui-alert type="error" :dismissible="false" v-if="subscriptionStatus.pauseFailed">
              Something went wrong and we cannot process your request for cancellation. Try again later.</ui-alert>
            <!-- resume plan alert message -->
            <ui-alert type="error" :dismissible="false" v-if="subscriptionStatus.resumeFailed">
              Something went wrong and we cannot process your request for plan resume. Try again later.</ui-alert>
          </div>
          <div class="info-alert-messages">
            <!-- cancelled subscription info message -->
            <ui-alert type="info" :dismissible="false"
              v-if="hasCancelledSubscription || userMixin_hasPausedSubscriptionInGrace">
              Your subscription is currently in grace period. Your grace period will expire on
              <strong>{{ hasCancelledSubscription ? localeDate(subscription.ends_at) :
                localeDate(subscription.paused_from) }}</strong>.
              <p><strong>Note:</strong> After the grace period all your forms will be disabled
                automatically.
              </p>
            </ui-alert>
            <!-- trial days left message -->
            <p v-if="userMixin_hasActiveFreeTrialPlan && !userMixin_planExpired"><strong>{{
              (userMixin_trialDaysLeft === 1) ? userMixin_trialDaysLeft + ' Day' :
                userMixin_trialDaysLeft
                + ' Days'
                }} Left</strong> on Trial. Upgrade Now.</p>
            <!-- account expired alert message -->
            <ui-alert type="warning" :dismissible="false"
              v-else-if="userMixin_hasActiveFreeTrialPlan && userMixin_planExpired">
              Your account has expired. Upgrade for the full experience!
            </ui-alert>
          </div>
        </div>
      </div>
      <div class="subscription-view__main__switch">
        <h5 class="plan-text">Plans</h5>
        <div class="plan-switch">
          <strong>Monthly </strong>
          <ui-switch color="primary" v-model="yearPlan"></ui-switch>
          <strong> Yearly</strong><label>(Save 20%)</label>
        </div>
      </div>
      <div class="subscription-view__main__plans">
        <!-- Yearly Plans -->
        <div class="plan-view"
          :style="{ 'max-width': plan.public_id === paddlePublicIds.singlePlan ? 'none' : '950px' }" v-if="yearPlan">
          <div class="plan" v-for="(yearPlan, index) in filteredYearlyPlans" :key="index"
            :class="{ 'scale-plan': yearPlan.publicId === paddlePublicIds.scaleYearly }">
            <div class="current-plan" v-if="(yearPlan.publicId === paddlePublicIds.proYearly)">
              <span v-if="(plan.public_id === paddlePublicIds.proYearly)">Your Current Plan</span>
              <img v-if="(plan.public_id === paddlePublicIds.proYearly)" src="./../assets/images/icons/arrow-down.svg"
                alt="LeadGen">
            </div>
            <div class="current-plan" v-if="(yearPlan.publicId === paddlePublicIds.scaleYearly)">
              <span v-if="(plan.public_id === paddlePublicIds.scaleYearly)">Your Current Plan</span>
              <img v-if="(plan.public_id === paddlePublicIds.scaleYearly)" src="./../assets/images/icons/arrow-down.svg"
                alt="LeadGen">
            </div>
            <div class="current-plan" v-if="(yearPlan.publicId === paddlePublicIds.enterpriseYearly)">
              <span v-if="(plan.public_id === paddlePublicIds.enterpriseYearly)">Your Current Plan</span>
              <img v-if="(plan.public_id === paddlePublicIds.enterpriseYearly)"
                src="./../assets/images/icons/arrow-down.svg" alt="LeadGen">
            </div>
            <div class="current-plan" v-if="(yearPlan.publicId === paddlePublicIds.singlePlan)">
              <span v-if="(plan.public_id === paddlePublicIds.singlePlan)">Your Current Plan</span>
              <img v-if="(plan.public_id === paddlePublicIds.singlePlan)" src="./../assets/images/icons/arrow-down.svg"
                alt="LeadGen">
            </div>
            <span class="most__popular" v-if="(yearPlan.publicId === paddlePublicIds.scaleYearly)">MOST POPULAR</span>
            <div :class="{ 'scale-plan-header': yearPlan.publicId === paddlePublicIds.scaleYearly }"
              class="plan__header">
              <!-- Handle Title on Pro Annual Plan -->
              <div class="pro_div" v-if="(yearPlan.publicId === paddlePublicIds.proYearly)">
                {{ (hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING
                  && plan.public_id === paddlePublicIds.proYearlyTrial
                  ? yearPlan.title + ' Trial' : yearPlan.title)
                }}
              </div>
              <!-- Handle Title on Scale Annual Plan -->
              <div class="scale_div" v-if="(yearPlan.publicId === paddlePublicIds.scaleYearly)">
                {{ (hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING
                  && plan.public_id === paddlePublicIds.scaleYearlyTrial
                  ? yearPlan.title + ' Trial' : yearPlan.title)
                }}
              </div>
              <!-- Handle Title on Enterprise Annual Plan -->
              <div class="enterprise_div" v-if="(yearPlan.publicId === paddlePublicIds.enterpriseYearly)">
                {{ (hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING
                  && plan.public_id === paddlePublicIds.enterpriseYearlyTrial
                  ? yearPlan.title + ' Trial' : yearPlan.title)
                }}
              </div>
              <!-- Handle Title on Single Annual Plan -->
              <div v-if="yearPlan.publicId === paddlePublicIds.singlePlan" class="single_div">
                {{ yearPlan.title }}
              </div>
            </div>
            <div v-if="(!userMixin_showTwentyFourHourDiscount)" class="price-cut">
              ${{ yearPlan.originalPrice }}/month
            </div>
            <div class="price-cut" v-else>${{ yearPlan.price }}/month</div>
            <!-- Show only for 24 hours discount -->
            <div v-if="(userMixin_showTwentyFourHourDiscount)">
              <!-- <div class="plan__price__box_discount">
                <span class="plan__currency">$</span>
                <span class="plan__price">{{ yearPlan.price }}</span>
                <span class="plan__period">/month</span>
              </div> -->
              <div class="plan__price__box_discount active">
                <span class="plan__currency">$</span>
                <span class="plan__price">{{ calculatePercent(yearPlan.price) }}</span>
                <span class="plan__period">/month</span>
                <span class="plan__savings">save 30%</span>
              </div>
            </div>
            <!-- Show Normally -->
            <div class="plan__price__box" v-else>
              <span class="plan__currency">$</span>
              <span class="plan__price">{{ yearPlan.price }}</span>
              <span class="plan__period"
                v-text="`${yearPlan.publicId === paddlePublicIds.singlePlan ? '/year' : '/month'}`"></span>
              <span v-if="yearPlan.publicId !== paddlePublicIds.singlePlan" class="plan__savings">Save
                ${{ calculateSavings(yearPlan.price, yearPlan.originalPrice) }}</span>
            </div>
            <h3>Billed Yearly</h3>
            <div class="plan__footer__btn" v-if="!hasCancelledSubscription">
              <!-- Handle Footer Button on Pro Plan -->
              <div class="pro_div" v-if="(yearPlan.publicId === paddlePublicIds.proYearly)">
                <!-- Upgrade to Pro Annual when no subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                  @click="() => subscribePlan(annualProId)" :loading="subscriptionStatus.planId === annualProId">Upgrade
                </ui-button>
                <!-- Upgrade to Pro Annual when on Pro/Scale/Enterprise Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised
                v-else-if="((plan.public_id === paddlePublicIds.proMonthly) ||
                (plan.public_id === paddlePublicIds.scaleMonthly) ||
                (plan.public_id === paddlePublicIds.enterpriseMonthly) ||
                (plan.public_id === paddlePublicIds.starterMonthly)) &&
                (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(annualProId, yearPlan)">Upgrade</ui-button>
                <!-- Upgrade to Pro Annual when on Pro/Scale/Enterprise Monthly Trial subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-else-if="((plan.public_id === paddlePublicIds.proMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.scaleMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.singlePlan)) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(annualProId, yearPlan)">Upgrade</ui-button>
                <!-- Show Subscribe Now when on Pro Annual Trial subscription -->
                <!-- Show trial message when on trial -->
                <div class="left-align" v-else-if="(plan.public_id === paddlePublicIds.proYearlyTrial) &&
                  hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING">
                  <ui-button class="subscribe__plan__btn" iconPosition="right"
                    @click="() => subscribeWithinTrial(trialAnnualProId)"
                    :loading="subscriptionStatus.planId === trialAnnualProId">Subscribe Now</ui-button>
                  <p>
                    <i><small>You're currently in trial period.</small></i>
                  </p>
                </div>
                <!-- Show Subscribe when on Pro/Scale/Enterprise Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.proMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.scaleMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial))"
                  @click="() => upgradeWithinTrial(annualProId)" :loading="subscriptionStatus.planId === annualProId">
                  Subscribe
                </ui-button>
                <!-- Show Your Plan when on Pro Annual subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled v-if="(plan.public_id === paddlePublicIds.proYearly) &&
                  subscription.status === paddleSubscriptionStatusTypes.ACTIVE">Your Plan</ui-button>
                <!-- Show Your Plan when on Pro Annual Trial subscription after trial ends -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled v-else-if="(plan.public_id === paddlePublicIds.proYearlyTrial) &&
                  subscription.status === paddleSubscriptionStatusTypes.ACTIVE">Your Plan</ui-button>
                <!-- Show reactivate account for past due users-->
                <a v-if="((plan.public_id === paddlePublicIds.proYearly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAST_DUE))"
                  @click="() => updateBillingDetails()">
                  <ui-button size="large" class="upgrade__plan__btn">Reactivate Account</ui-button>
                </a>
                <a v-else-if="((plan.public_id === paddlePublicIds.proYearlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAST_DUE))"
                  @click="() => updateBillingDetails()">
                  <ui-button size="large" class="upgrade__plan__btn">Reactivate Account</ui-button>
                </a>
                <!-- Resume to Pro Yearly when on Pro Yearly subscription Paused -->
                <ui-button class="resume__plan__btn" iconPosition="right" v-if="(plan.public_id === paddlePublicIds.proYearly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAUSED)"
                  @click="() => resumeSubscription(annualProId)"
                  :loading="subscriptionStatus.planId === annualProId">Reactivate Plan
                </ui-button>
                <!-- Resume to Pro Yearly when on Pro Yearly Trial subscription Paused -->
                <ui-button class="resume__plan__btn" iconPosition="right" v-else-if="(plan.public_id === paddlePublicIds.proYearlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAUSED)"
                  @click="() => resumeSubscription(trialAnnualProId)"
                  :loading="subscriptionStatus.planId === trialAnnualProId">Reactivate Plan
                </ui-button>
                <br>
                <!---Cancel/Pause-->
                <!-- Show Pause when on Pro Yearly subscription -->
                <a class="cancel-link" v-if="((plan.public_id === paddlePublicIds.proYearly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE))"
                  @click="handlePause(yearPlan.forms)">Cancel subscription</a>
                <!-- Show Pause when on Pro Yearly Trial subscription -->
                <a class="cancel-link" v-else-if="((plan.public_id === paddlePublicIds.proYearlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE))"
                  @click="handlePause(yearPlan.forms)">Cancel subscription</a>
                <!-- Show Cancel when on Pro Yearly Trial subscription -->
                <a class="cancel-link" v-else-if="((plan.public_id === paddlePublicIds.proYearlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.TRIALING))"
                  @click="handleCancel(yearPlan.forms)">Cancel subscription</a>
              </div>
              <!-- Handle Footer Button on Scale Plan -->
              <div class="scale_div" v-if="(yearPlan.publicId === paddlePublicIds.scaleYearly)">
                <!-- Upgrade to Scale Annual when no subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                  @click="() => subscribePlan(annualScaleId)" :loading="subscriptionStatus.planId === annualScaleId">
                  Upgrade</ui-button>
                <!-- Upgrade to Scale Annual when on Pro/Scale/Enterprise Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised
                v-else-if="((plan.public_id === paddlePublicIds.proMonthly) ||
                (plan.public_id === paddlePublicIds.scaleMonthly) ||
                (plan.public_id === paddlePublicIds.enterpriseMonthly) ||
                (plan.public_id === paddlePublicIds.singlePlan) ||
                (plan.public_id === paddlePublicIds.starterMonthly)) &&
                (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(annualScaleId, yearPlan)">Upgrade</ui-button>
                <!-- Upgrade to Scale Annual when on Pro/Scale/Enterprise Monthly Trial subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-else-if="((plan.public_id === paddlePublicIds.proMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.scaleMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial)) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(annualScaleId, yearPlan)">Upgrade</ui-button>
                <!-- Show Subscribe Now when on Scale Annual Trial subscription -->
                <!-- Show trial message when on trial -->
                <div class="left-align" v-else-if="(plan.public_id === paddlePublicIds.scaleYearlyTrial) &&
                  hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING">
                  <ui-button class="subscribe__plan__btn" iconPosition="right"
                    @click="() => subscribeWithinTrial(trialAnnualScaleId)"
                    :loading="subscriptionStatus.planId === trialAnnualScaleId">Subscribe Now</ui-button>
                  <p>
                    <i><small>You're currently in trial period.</small></i>
                  </p>
                </div>
                <!-- Show Subscribe when on Pro/Scale/Enterprise Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.proMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.scaleMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial))"
                  @click="() => upgradeWithinTrial(annualScaleId)"
                  :loading="subscriptionStatus.planId === annualScaleId">
                  Subscribe
                </ui-button>
                <!-- Show Your Plan when on Scale Annual subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled v-if="(plan.public_id === paddlePublicIds.scaleYearly) &&
                  subscription.status === paddleSubscriptionStatusTypes.ACTIVE">Your Plan</ui-button>
                <!-- Show Your Plan when on Scale Annual Trial subscription after trial ends -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled v-else-if="(plan.public_id === paddlePublicIds.scaleYearlyTrial) &&
                  subscription.status === paddleSubscriptionStatusTypes.ACTIVE">Your Plan</ui-button>
                <!-- Show reactivate account for past due users-->
                <a v-if="((plan.public_id === paddlePublicIds.scaleYearly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAST_DUE))"
                  @click="() => updateBillingDetails()">
                  <ui-button size="large" class="upgrade__plan__btn">Reactivate Account</ui-button>
                </a>
                <a v-else-if="((plan.public_id === paddlePublicIds.scaleYearlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAST_DUE))"
                  @click="() => updateBillingDetails()">
                  <ui-button size="large" class="upgrade__plan__btn">Reactivate Account</ui-button>
                </a>
                <!-- Resume to Scale Yearly when on Scale Yearly subscription Paused -->
                <ui-button class="resume__plan__btn" iconPosition="right" v-if="(plan.public_id === paddlePublicIds.scaleYearly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAUSED)"
                  @click="() => resumeSubscription(annualScaleId)"
                  :loading="subscriptionStatus.planId === annualScaleId">Reactivate Plan
                </ui-button>
                <!-- Resume to Scale Yearly when on Scale Yearly Trial subscription Paused -->
                <ui-button class="resume__plan__btn" iconPosition="right" v-else-if="(plan.public_id === paddlePublicIds.scaleYearlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAUSED)"
                  @click="() => resumeSubscription(trialAnnualScaleId)"
                  :loading="subscriptionStatus.planId === trialAnnualScaleId">Reactivate Plan
                </ui-button>
                <br>
                <!---Cancel/Pause-->
                <!-- Show Pause when on Scale Yearly subscription -->
                <a class="cancel-link" v-if="((plan.public_id === paddlePublicIds.scaleYearly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE))"
                  @click="handlePause(yearPlan.forms)">Cancel subscription</a>
                <!-- Show Pause when on Scale Yearly Trial subscription -->
                <a class="cancel-link" v-else-if="((plan.public_id === paddlePublicIds.scaleYearlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE))"
                  @click="handlePause(yearPlan.forms)">Cancel subscription</a>
                <!-- Show Cancel when on Scale Yearly Trial subscription -->
                <a class="cancel-link" v-else-if="((plan.public_id === paddlePublicIds.scaleYearlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.TRIALING))"
                  @click="handleCancel(yearPlan.forms)">Cancel subscription</a>
              </div>
              <!-- Handle Footer Button on Enterprise Plan -->
              <div class="enterprise_div" v-if="(yearPlan.publicId === paddlePublicIds.enterpriseYearly)">
                <!-- Upgrade to Enterprise Annual when no subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                  @click="() => subscribePlan(annualEnterpriseId)"
                  :loading="subscriptionStatus.planId === annualEnterpriseId">Upgrade
                </ui-button>
                <!-- Upgrade to Enterprise Annual when on Pro/Scale/Enterprise Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised
                v-else-if="((plan.public_id === paddlePublicIds.proMonthly) ||
                (plan.public_id === paddlePublicIds.scaleMonthly) ||
                (plan.public_id === paddlePublicIds.enterpriseMonthly) ||
                (plan.public_id === paddlePublicIds.singlePlan) ||
                (plan.public_id === paddlePublicIds.starterMonthly)) &&
                (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(annualEnterpriseId, yearPlan)">Upgrade</ui-button>
                <!-- Upgrade to Enterprise Annual when on Pro/Scale/Enterprise Monthly Trial subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-else-if="((plan.public_id === paddlePublicIds.proMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.scaleMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial)) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(annualEnterpriseId, yearPlan)">Upgrade</ui-button>
                <!-- Show Your Plan when on Enterprise Annual Trial subscription -->
                <!-- Show trial message when on trial -->
                <div class="left-align" v-else-if="(plan.public_id === paddlePublicIds.enterpriseYearlyTrial) &&
                  hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING">
                  <ui-button class="subscribe__plan__btn" iconPosition="right"
                    @click="() => subscribeWithinTrial(trialAnnualEnterpriseId)"
                    :loading="subscriptionStatus.planId === trialAnnualEnterpriseId">Subscribe Now</ui-button>
                  <p>
                    <i><small>You're currently in trial period.</small></i>
                  </p>
                </div>
                <!-- Show Subscribe when on Pro/Scale/Enterprise Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.proMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.scaleMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial))"
                  @click="() => upgradeWithinTrial(annualEnterpriseId)"
                  :loading="subscriptionStatus.planId === annualEnterpriseId">
                  Subscribe
                </ui-button>
                <!-- Show Your Plan when on Enterprise Annual subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled v-if="(plan.public_id === paddlePublicIds.enterpriseYearly) &&
                  subscription.status === paddleSubscriptionStatusTypes.ACTIVE">Your Plan</ui-button>
                <!-- Show Your Plan when on Enterprise Annual Trial subscription after trial ends -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled v-else-if="(plan.public_id === paddlePublicIds.enterpriseYearlyTrial) &&
                  subscription.status === paddleSubscriptionStatusTypes.ACTIVE">Your Plan</ui-button>
                <!-- Show reactivate account for past due users-->
                <a v-if="((plan.public_id === paddlePublicIds.enterpriseYearly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAST_DUE))"
                  @click="() => updateBillingDetails()">
                  <ui-button size="large" class="upgrade__plan__btn">Reactivate Account</ui-button>
                </a>
                <a v-else-if="((plan.public_id === paddlePublicIds.enterpriseYearlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAST_DUE))"
                  @click="() => updateBillingDetails()">
                  <ui-button size="large" class="upgrade__plan__btn">Reactivate Account</ui-button>
                </a>
                <!-- Resume to Enterprise Yearly when on Enterprise Yearly subscription Paused -->
                <ui-button class="resume__plan__btn" iconPosition="right" v-if="(plan.public_id === paddlePublicIds.enterpriseYearly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAUSED)"
                  @click="() => resumeSubscription(annualEnterpriseId)"
                  :loading="subscriptionStatus.planId === annualEnterpriseId">Reactivate Plan
                </ui-button>
                <!-- Resume to Enterprise Yearly when on Enterprise Yearly Trial subscription Paused -->
                <ui-button class="resume__plan__btn" iconPosition="right" v-else-if="(plan.public_id === paddlePublicIds.enterpriseYearlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAUSED)"
                  @click="() => resumeSubscription(trialAnnualEnterpriseId)"
                  :loading="subscriptionStatus.planId === trialAnnualEnterpriseId">Reactivate Plan
                </ui-button>
                <br>
                <!---Cancel/Pause-->
                <!-- Show Pause when on Enterprise Yearly subscription -->
                <a class="cancel-link" v-if="((plan.public_id === paddlePublicIds.enterpriseYearly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE))"
                  @click="handlePause(yearPlan.forms)">Cancel subscription</a>
                <!-- Show Pause when on Enterprise Yearly Trial subscription -->
                <a class="cancel-link" v-else-if="((plan.public_id === paddlePublicIds.enterpriseYearlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE))"
                  @click="handlePause(yearPlan.forms)">Cancel subscription</a>
                <!-- Show Cancel when on Enterprise Yearly Trial subscription -->
                <a class="cancel-link" v-else-if="((plan.public_id === paddlePublicIds.enterpriseYearlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.TRIALING))"
                  @click="handleCancel(yearPlan.forms)">Cancel subscription</a>
              </div>
              <!-- Handle Footer Button on Single Plan -->
              <div class="single_div" v-if="(yearPlan.publicId === paddlePublicIds.singlePlan)">
                <ui-button class="your__plan__btn" iconPosition="right" disabled v-if="(plan.public_id === paddlePublicIds.singlePlan) &&
                  subscription.status === paddleSubscriptionStatusTypes.ACTIVE">Your Plan
                </ui-button>
              </div>
              <!-- Resume to single Yearly when on single Yearly subscription Paused -->
              <div class="single_div" v-if="(yearPlan.publicId === paddlePublicIds.singlePlan)">
                <ui-button class="resume__plan__btn" iconPosition="right" style="padding: 10px 30px"
                  v-if="(subscription.status === paddleSubscriptionStatusTypes.PAUSED)"
                  @click="() => resumeSubscription(singlePlanId)" :loading="subscriptionStatus.planId === singlePlanId">
                  Reactivate Plan
                </ui-button>
              </div>
              <!-- Show Pause when on Single Yearly subscription -->
              <div class="single_div" v-if="(yearPlan.publicId === paddlePublicIds.singlePlan)">
                <a class="cancel-link" v-if="((plan.public_id === paddlePublicIds.singlePlan) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE))"
                  @click="handlePause(yearPlan.forms)">Cancel subscription</a>
              </div>
              <!-- Show reactivate account for past due users-->
              <div class="single_div" v-if="(yearPlan.publicId === paddlePublicIds.singlePlan)">
                <a v-if="((subscription.status === paddleSubscriptionStatusTypes.PAST_DUE))"
                  @click="() => updateBillingDetails()">
                  <ui-button size="large" class="upgrade__plan__btn" style="padding: 10px 30px">Reactivate
                    Account</ui-button>
                </a>
              </div>
            </div>
            <div class="plan__header__btn" v-if="!hasCancelledSubscription">
              <!-- Handle Header Button on Pro Plan -->
              <div class="pro_div" v-if="(yearPlan.publicId === paddlePublicIds.proYearly)">
                <!-- Upgrade to Pro Annual when no subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                  @click="() => subscribePlan(annualProId)" :loading="subscriptionStatus.planId === annualProId">Upgrade
                </ui-button>
                <!-- Upgrade to Pro Annual when on Pro/Scale/Enterprise Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-else-if="((subscription.status === paddleSubscriptionStatusTypes.ACTIVE) &&
                  ((plan.public_id === paddlePublicIds.proMonthly) ||
                    (plan.public_id === paddlePublicIds.scaleMonthly) ||
                    (plan.public_id === paddlePublicIds.enterpriseMonthly)))"
                  @click="() => handleUpgrade(annualProId, yearPlan)">Upgrade</ui-button>
                <!-- Upgrade to Pro Annual when on Pro/Scale/Enterprise Monthly Trial subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-else-if="((subscription.status === paddleSubscriptionStatusTypes.ACTIVE) &&
                  ((plan.public_id === paddlePublicIds.proMonthlyTrial) ||
                    (plan.public_id === paddlePublicIds.scaleMonthlyTrial) ||
                    (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial) ||
                    (plan.public_id === paddlePublicIds.singlePlan)))"
                  @click="() => handleUpgrade(annualProId, yearPlan)">Upgrade</ui-button>
                <!-- Show Subscribe when on Pro/Scale/Enterprise Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.proMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.scaleMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial))"
                  @click="() => upgradeWithinTrial(annualProId)" :loading="subscriptionStatus.planId === annualProId">
                  Subscribe
                </ui-button>
                <!-- Show Your Plan when on Pro Annual subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled
                  v-if="(plan.public_id === paddlePublicIds.proYearly)">Your Plan</ui-button>
                <!-- Show Your Plan when on Pro Annual Trial subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled
                  v-else-if="(plan.public_id === paddlePublicIds.proYearlyTrial)">Your Plan</ui-button>
              </div>
              <!-- Handle Header Button on Scale Plan -->
              <div class="scale_div" v-if="(yearPlan.publicId === paddlePublicIds.scaleYearly)">
                <!-- Upgrade to Scale Annual when no subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                  @click="() => subscribePlan(annualScaleId)" :loading="subscriptionStatus.planId === annualScaleId">
                  Upgrade</ui-button>
                <!-- Upgrade to Scale Annual when on Pro/Scale/Enterprise Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-else-if="((subscription.status === paddleSubscriptionStatusTypes.ACTIVE) &&
                  ((plan.public_id === paddlePublicIds.proMonthly) ||
                    (plan.public_id === paddlePublicIds.scaleMonthly) ||
                    (plan.public_id === paddlePublicIds.enterpriseMonthly ||
                      (plan.public_id === paddlePublicIds.singlePlan))))"
                  @click="() => handleUpgrade(annualScaleId, yearPlan)">Upgrade</ui-button>
                <!-- Upgrade to Scale Annual when on Pro/Scale/Enterprise Monthly Trial subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-else-if="((subscription.status === paddleSubscriptionStatusTypes.ACTIVE) &&
                  ((plan.public_id === paddlePublicIds.proMonthlyTrial) ||
                    (plan.public_id === paddlePublicIds.scaleMonthlyTrial) ||
                    (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial)))" yearPlan
                  @click="() => handleUpgrade(annualScaleId, yearPlan)">Upgrade</ui-button>
                <!-- Show Subscribe when on Pro/Scale/Enterprise Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.proMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.scaleMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial))"
                  @click="() => upgradeWithinTrial(annualScaleId)"
                  :loading="subscriptionStatus.planId === annualScaleId">
                  Subscribe
                </ui-button>
                <!-- Show Your Plan when on Scale Annual subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled
                  v-if="(plan.public_id === paddlePublicIds.scaleYearly)">Your Plan</ui-button>
                <!-- Show Your Plan when on Scale Annual Trial subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled
                  v-else-if="(plan.public_id === paddlePublicIds.scaleYearlyTrial)">Your Plan</ui-button>
              </div>
              <!-- Handle Header Button on Enterprise Plan -->
              <div class="enterprise_div" v-if="(yearPlan.publicId === paddlePublicIds.enterpriseYearly)">
                <!-- Upgrade to Enterprise Annual when no subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                  @click="() => subscribePlan(annualEnterpriseId)"
                  :loading="subscriptionStatus.planId === annualEnterpriseId">Upgrade
                </ui-button>
                <!-- Upgrade to Enterprise Annual when on Pro/Scale/Enterprise Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-else-if="((subscription.status === paddleSubscriptionStatusTypes.ACTIVE) &&
                  ((plan.public_id === paddlePublicIds.proMonthly) ||
                    (plan.public_id === paddlePublicIds.scaleMonthly) ||
                    (plan.public_id === paddlePublicIds.enterpriseMonthly) ||
                    (plan.public_id === paddlePublicIds.singlePlan)))"
                  @click="() => handleUpgrade(annualEnterpriseId, yearPlan)">Upgrade</ui-button>
                <!-- Upgrade to Enterprise Annual when on Pro/Scale/Enterprise Monthly Trial subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-else-if="((subscription.status === paddleSubscriptionStatusTypes.ACTIVE) &&
                  ((plan.public_id === paddlePublicIds.proMonthlyTrial) ||
                    (plan.public_id === paddlePublicIds.scaleMonthlyTrial) ||
                    (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial)))"
                  @click="() => handleUpgrade(annualEnterpriseId, yearPlan)">Upgrade</ui-button>
                <!-- Show Subscribe when on Pro/Scale/Enterprise Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.proMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.scaleMonthlyTrial) ||
                  (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial))"
                  @click="() => upgradeWithinTrial(annualEnterpriseId)"
                  :loading="subscriptionStatus.planId === annualEnterpriseId">
                  Subscribe
                </ui-button>
                <!-- Show Your Plan when on Enterprise Annual subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled
                  v-if="(plan.public_id === paddlePublicIds.enterpriseYearly)">Your Plan</ui-button>
                <!-- Show Your Plan when on Enterprise Annual Trial subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled
                  v-else-if="(plan.public_id === paddlePublicIds.enterpriseYearlyTrial)">Your Plan</ui-button>
              </div>
              <!-- Handle Header Button on Single Plan -->
              <div class="single_div" v-if="(yearPlan.publicId === paddlePublicIds.singlePlan)">
                <ui-button class="your__plan__btn" iconPosition="right" disabled v-if="(plan.public_id === paddlePublicIds.singlePlan) &&
                  subscription.status === paddleSubscriptionStatusTypes.ACTIVE">Your Plan</ui-button>
              </div>
              <!-- Show only for 24 hours discount -->
              <div class="hour-discount" v-if="(userMixin_showTwentyFourHourDiscount)">
                <span>30% Off</span>
              </div>
            </div>
            <div class="plan__features">
              <!-- <h5>{{ yearPlan.text }}</h5> -->
              <ul>
                <li class="plan__feature" v-for="(feature, index) in yearPlan.features" :key="index">
                  <div v-if="Array.isArray(feature)">
                    <ul>
                      <li v-for="nestedFeature in feature" :key="nestedFeature">
                        <div class="excluded-features"><span class="material-icons">close</span>{{ nestedFeature }}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="included-features" v-else><span class="material-icons">check</span>{{ feature }}</div>
                </li>
              </ul>
            </div>

          </div>
        </div>
        <!-- Monthly Plans -->
        <div class="plan-view" v-else>
          <div class="plan" v-for="(monthPlan, index) in paddlePlans.monthly" :key="index" :class="{ 'scale-plan': monthPlan.publicId === paddlePublicIds.scaleMonthly }">
            <div class="current-plan" v-if="(monthPlan.publicId === paddlePublicIds.starterMonthly)">
               <span v-if="(plan.public_id === paddlePublicIds.starterMonthly)">Your Current Plan</span>
               <img v-if="(plan.public_id === paddlePublicIds.starterMonthly)" src="./../assets/images/icons/arrow-down.svg" alt="LeadGen">
            </div>
            <div class="current-plan" v-if="(monthPlan.publicId === paddlePublicIds.proMonthly)">
              <span v-if="(plan.public_id === paddlePublicIds.proMonthly)">Your Current Plan</span>
              <img v-if="(plan.public_id === paddlePublicIds.proMonthly)" src="./../assets/images/icons/arrow-down.svg"
                alt="LeadGen">
            </div>
            <div class="current-plan" v-if="(monthPlan.publicId === paddlePublicIds.scaleMonthly)">
              <span v-if="(plan.public_id === paddlePublicIds.scaleMonthly)">Your Current Plan</span>
              <img v-if="(plan.public_id === paddlePublicIds.scaleMonthly)"
                src="./../assets/images/icons/arrow-down.svg" alt="LeadGen">
            </div>
            <div class="current-plan" v-if="(monthPlan.publicId === paddlePublicIds.enterpriseMonthly)">
              <span v-if="(plan.public_id === paddlePublicIds.enterpriseMonthly)">Your Current Plan</span>
              <img v-if="(plan.public_id === paddlePublicIds.enterpriseMonthly)"
                src="./../assets/images/icons/arrow-down.svg" alt="LeadGen">
            </div>

            <span class="most__popular" v-if="(monthPlan.publicId === paddlePublicIds.scaleMonthly)">MOST POPULAR</span>
            <div :class="{ 'scale-plan-header': monthPlan.publicId === paddlePublicIds.scaleMonthly }" class="plan__header">
              <div class="starter_div" v-if="(monthPlan.publicId === paddlePublicIds.starterMonthly)">
                {{ monthPlan.title }}
                <!-- {{ (hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING
                && plan.public_id === paddlePublicIds.proMonthlyTrial
                ? monthPlan.title + ' Trial' : monthPlan.title)
                }} -->
              </div>
              <!-- Handle Title on Pro Plan -->
              <div class="pro_div" v-if="(monthPlan.publicId === paddlePublicIds.proMonthly)">
                {{ (hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING
                  && plan.public_id === paddlePublicIds.proMonthlyTrial
                  ? monthPlan.title + ' Trial' : monthPlan.title)
                }}
              </div>
              <!-- Handle Title on Scale Plan -->
              <div class="scale_div" v-if="(monthPlan.publicId === paddlePublicIds.scaleMonthly)">
                {{ (hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING
                  && plan.public_id === paddlePublicIds.scaleMonthlyTrial
                  ? monthPlan.title + ' Trial' : monthPlan.title)
                }}
              </div>
              <!-- Handle Title on Enterprise Plan -->
              <div class="enterprise_div" v-if="(monthPlan.publicId === paddlePublicIds.enterpriseMonthly)">
                {{ (hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING
                  && plan.public_id === paddlePublicIds.enterpriseMonthlyTrial
                  ? monthPlan.title + ' Trial' : monthPlan.title)
                }}
              </div>
            </div>
            <div v-if="(userMixin_showTwentyFourHourDiscount) && monthPlan.publicId !== paddlePublicIds.starterMonthly" class="price-cut">${{ monthPlan.price }}/month</div>
            <!-- Show only for 24 hours discount -->
            <div v-if="(userMixin_showTwentyFourHourDiscount) && monthPlan.publicId !== paddlePublicIds.starterMonthly">
              <!-- <div class="plan__price__box_discount">
                <span class="plan__currency">$</span>
                <span class="plan__price">{{ monthPlan.price }}</span>
                <span class="plan__period">/month</span>
              </div> -->
              <div class="plan__price__box_discount active">
                <span class="plan__currency">$</span>
                <span class="plan__price">{{ calculatePercent(monthPlan.price) }}</span>
                <span class="plan__period">/month</span>
                <span class="plan__savings">save 30%</span>
              </div>
            </div>
            <!-- Show Normally -->
            <div class="plan__price__box" v-else>
              <span class="plan__currency">$</span>
              <span class="plan__price">{{ monthPlan.price }}</span>
              <span class="plan__period">/month</span>
            </div>
            <h3>Billed Monthly</h3>
            <div class="plan__footer__btn" v-if="!hasCancelledSubscription">
              <div class="starter_div" v-if="(monthPlan.publicId === paddlePublicIds.starterMonthly)">
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                  @click="() => subscribePlan(monthlyStarterId)" :loading="subscriptionStatus.planId === monthlyStarterId">
                  Upgrade
                </ui-button>

                <!-- Show Subscribe when on Pro Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right"
                  v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.proMonthlyTrial))"
                  @click="() => downgradeWithinTrial('100', monthlyStarterId)" :loading="subscriptionStatus.planId === monthlyStarterId">
                  Subscribe
                </ui-button>
                <!-- Show Subscribe when on Scale Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right"
                  v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.scaleMonthlyTrial))"
                  @click="() => downgradeWithinTrial('100', monthlyStarterId)" :loading="subscriptionStatus.planId === monthlyStarterId">
                  Subscribe
                </ui-button>
                <!-- Show Subscribe when on Enterprise Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right"
                  v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial))"
                  @click="() => downgradeWithinTrial('300', monthlyStarterId)" :loading="subscriptionStatus.planId === monthlyStarterId">
                  Subscribe
                </ui-button>
                <!-- Downgrade to starter Monthly when on pro Monthly subscription -->
                <ui-button class="downgrade__plan__btn" iconPosition="right"
                  v-if="(plan.public_id === paddlePublicIds.proMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="handleDowngrade('20', monthlyStarterId)">Downgrade
                </ui-button>
                <!-- Downgrade to starter Monthly when on Scale Monthly subscription -->
                <ui-button class="downgrade__plan__btn" iconPosition="right"
                  v-if="(plan.public_id === paddlePublicIds.scaleMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="handleDowngrade('100', monthlyStarterId)">Downgrade
                </ui-button>
                <!-- Downgrade to starter Monthly when on Enterprise Monthly subscription -->
                <ui-button class="downgrade__plan__btn" iconPosition="right"
                  v-if="(plan.public_id === paddlePublicIds.enterpriseMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="handleDowngrade('300', monthlyStarterId)">Downgrade
                </ui-button>
                <!-- Resume to Starter Monthly when on Starter Monthly subscription Paused -->
                <ui-button class="resume__plan__btn" iconPosition="right"
                  v-if="(plan.public_id === paddlePublicIds.starterMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAUSED)"
                  @click="() => resumeSubscription(monthlyStarterId)"
                  :loading="subscriptionStatus.planId === monthlyStarterId">Reactivate Plan
                </ui-button>
                <a class="cancel-link" v-if="((plan.public_id === paddlePublicIds.starterMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE))"
                  @click="handlePause(monthPlan.forms)">Cancel subscription</a>
              </div>
              <!-- Handle Footer Button on Pro Plan -->
              <div class="pro_div" v-if="(monthPlan.publicId === paddlePublicIds.proMonthly)">
                <!-- Upgrade to Pro Monthly when no subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                  @click="() => subscribePlan(monthlyProId)" :loading="subscriptionStatus.planId === monthlyProId">
                  Upgrade
                </ui-button>
                <!-- Upgrade to Pro Monthly when on Starter Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised
                  v-else-if="(plan.public_id === paddlePublicIds.starterMonthly) ||
                (plan.public_id === paddlePublicIds.singlePlan) &&
                (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(monthlyProId, paddlePublicIds.proMonthly)"
                  >Upgrade
                </ui-button>
                <!-- Upgrade to Pro Annual when on Pro Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" size="large" v-else-if="(plan.public_id === paddlePublicIds.proMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(annualProId, filteredYearlyPlans)">Get Pro Yearly</ui-button>
                <!-- Upgrade to Pro Annual when on Pro Monthly Trial subscription after trial ends-->
                <ui-button class="upgrade__plan__btn" iconPosition="right" size="large" v-else-if="(plan.public_id === paddlePublicIds.proMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(annualProId, filteredYearlyPlans)">Get Pro Yearly</ui-button>
                  <!-- Show Upgrade when on Single Plan subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right"
                v-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.ACTIVE) &&
                (plan.public_id === paddlePublicIds.singlePlan))"
                  @click="() =>subscribePlan(monthlyProId)" :loading="subscriptionStatus.planId === monthlyProId">
                  Upgrade
                </ui-button>

                <!-- Show Subscribe Now when on Pro Monthly Trial subscription -->
                <!-- Show trial message when on trial -->
                <div class="left-align" v-else-if="(plan.public_id === paddlePublicIds.proMonthlyTrial) &&
                  hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING">
                  <ui-button class="subscribe__plan__btn" iconPosition="right"
                    @click="() => subscribeWithinTrial(trialMonthlyProId)"
                    :loading="subscriptionStatus.planId === trialMonthlyProId">Subscribe Now</ui-button>
                  <p>
                    <i><small>You're currently in trial period.</small></i>
                  </p>
                </div>
                <!-- Show Subscribe when on Scale Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.scaleMonthlyTrial))"
                  @click="() => downgradeWithinTrial('100', monthlyProId)"
                  :loading="subscriptionStatus.planId === monthlyProId">
                  Subscribe
                </ui-button>
                <!-- Show Subscribe when on Enterprise Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial))"
                  @click="() => downgradeWithinTrial('300', monthlyProId)"
                  :loading="subscriptionStatus.planId === monthlyProId">
                  Subscribe
                </ui-button>
                <!-- Downgrade to Pro Monthly when on Scale Monthly subscription -->
                <ui-button class="downgrade__plan__btn" iconPosition="right" v-if="(plan.public_id === paddlePublicIds.scaleMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="handleDowngrade('100', monthlyProId)">Downgrade
                </ui-button>
                <!-- Downgrade to Pro Monthly when on Enterprise Monthly subscription -->
                <ui-button class="downgrade__plan__btn" iconPosition="right" v-else-if="(plan.public_id === paddlePublicIds.enterpriseMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="handleDowngrade('300', monthlyProId)">Downgrade
                </ui-button>
                <!-- Downgrade to Pro Monthly when on Scale Monthly Trial subscription after trial ends-->
                <ui-button class="downgrade__plan__btn" iconPosition="right" v-if="(plan.public_id === paddlePublicIds.scaleMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="handleDowngrade('100', monthlyProId)">Downgrade
                </ui-button>
                <!-- Downgrade to Pro Monthly when on Enterprise Monthly Trial subscription after trial ends-->
                <ui-button class="downgrade__plan__btn" iconPosition="right" v-else-if="(plan.public_id === paddlePublicIds.enterpriseMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="handleDowngrade('300', monthlyProId)">Downgrade
                </ui-button>
                <!-- Show reactivate account for past due users-->
                <a v-if="((plan.public_id === paddlePublicIds.proMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAST_DUE))"
                  @click="() => updateBillingDetails()">
                  <ui-button size="large" class="upgrade__plan__btn">Reactivate Account</ui-button>
                </a>
                <a v-else-if="((plan.public_id === paddlePublicIds.proMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAST_DUE))"
                  @click="() => updateBillingDetails()">
                  <ui-button size="large" class="upgrade__plan__btn">Reactivate Account</ui-button>
                </a>
                <!-- Resume to Pro Monthly when on Pro Monthly subscription Paused -->
                <ui-button class="resume__plan__btn" iconPosition="right" v-if="(plan.public_id === paddlePublicIds.proMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAUSED)"
                  @click="() => resumeSubscription(monthlyProId)"
                  :loading="subscriptionStatus.planId === monthlyProId">Reactivate Plan
                </ui-button>
                <!-- Resume to Pro Monthly when on Pro Monthly Trial subscription Paused -->
                <ui-button class="resume__plan__btn" iconPosition="right" v-else-if="(plan.public_id === paddlePublicIds.proMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAUSED)"
                  @click="() => resumeSubscription(trialMonthlyProId)"
                  :loading="subscriptionStatus.planId === trialMonthlyProId">Reactivate Plan
                </ui-button>
                <br>
                <!---Cancel/Pause-->
                <!-- Show Pause when on Pro Monthly subscription -->
                <a class="cancel-link" v-if="((plan.public_id === paddlePublicIds.proMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE))"
                  @click="handlePause(monthPlan.forms)">Cancel subscription</a>
                <!-- Show Pause when on Pro Monthly Trial subscription -->
                <a class="cancel-link" v-else-if="((plan.public_id === paddlePublicIds.proMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE))"
                  @click="handlePause(monthPlan.forms)">Cancel subscription</a>
                <!-- Show Cancel when on Pro Monthly Trial subscription -->
                <a class="cancel-link" v-else-if="((plan.public_id === paddlePublicIds.proMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.TRIALING))"
                  @click="handleCancel(monthPlan.forms)">Cancel subscription</a>
              </div>
              <!-- Handle Footer Button on Scale Plan -->
              <div class="scale_div" v-if="(monthPlan.publicId === paddlePublicIds.scaleMonthly)">
                <!-- Upgrade to Scale Monthly when no subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                  @click="() => subscribePlan(monthlyScaleId)" :loading="subscriptionStatus.planId === monthlyScaleId">
                  Upgrade
                </ui-button>
              <!-- Upgrade to Scale Monthly when on Starter Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised
                  v-else-if="(plan.public_id === paddlePublicIds.starterMonthly) ||
                (plan.public_id === paddlePublicIds.singlePlan) &&
                (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(monthlyScaleId, paddlePublicIds.scaleMonthly)"
                  >Upgrade
                </ui-button>
                <!-- Upgrade to Scale Monthly when on Pro Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised
                  v-else-if="(plan.public_id === paddlePublicIds.proMonthly) ||
                (plan.public_id === paddlePublicIds.singlePlan) &&
                (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(monthlyScaleId, paddlePublicIds.scaleMonthly)"
                  >Upgrade
                </ui-button>
                <!-- Upgrade to Scale Monthly when on Pro Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-else-if="(plan.public_id === paddlePublicIds.proMonthly) ||
                  (plan.public_id === paddlePublicIds.singlePlan) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(monthlyScaleId, paddlePublicIds.scaleMonthly)">Upgrade
                </ui-button>
                <!-- Upgrade to Scale Monthly when on Pro Monthly Trial subscription after trial ends -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-else-if="(plan.public_id === paddlePublicIds.proMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => updateSubscriptionPlan(monthlyScaleId)"
                  :loading="subscriptionStatus.planId === monthlyScaleId">Upgrade
                </ui-button>
                <!-- Upgrade to Scale Annual when on Scale Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" size="large" v-else-if="(plan.public_id === paddlePublicIds.scaleMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(annualScaleId, filteredYearlyPlans)">Get Scale Yearly</ui-button>
                <!-- Upgrade to Scale Annual when on Scale Monthly Trial subscription after trial ends-->
                <ui-button class="upgrade__plan__btn" iconPosition="right" size="large" v-else-if="(plan.public_id === paddlePublicIds.scaleMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(annualScaleId, filteredYearlyPlans)">Get Scale Yearly</ui-button>
                <!-- Show Subscribe Now when on Scale Monthly Trial subscription -->
                <!-- Show trial message when on trial -->
                <div class="left-align" v-else-if="(plan.public_id === paddlePublicIds.scaleMonthlyTrial) &&
                  hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING">
                  <ui-button class="subscribe__plan__btn" iconPosition="right"
                    @click="() => subscribeWithinTrial(trialMonthlyScaleId)"
                    :loading="subscriptionStatus.planId === trialMonthlyScaleId">Subscribe Now</ui-button>
                  <p>
                    <i><small>You're currently in trial period.</small></i>
                  </p>
                </div>
                <!-- Show Subscribe when on Pro Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.proMonthlyTrial))"
                  @click="() => upgradeWithinTrial(monthlyScaleId)"
                  :loading="subscriptionStatus.planId === monthlyScaleId">
                  Subscribe
                </ui-button>
                <!-- Show Subscribe when on Enterprise Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial))"
                  @click="() => downgradeWithinTrial('300', monthlyScaleId)"
                  :loading="subscriptionStatus.planId === monthlyScaleId">
                  Subscribe
                </ui-button>
                <!-- Downgrade to Scale Monthly when on Enterprise Monthly subscription -->
                <ui-button class="downgrade__plan__btn" iconPosition="right" v-if="(plan.public_id === paddlePublicIds.enterpriseMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="handleDowngrade('300', monthlyScaleId)">Downgrade
                </ui-button>
                <!-- Downgrade to Scale Monthly when on Enterprise Monthly Trial subscription after trial ends-->
                <ui-button class="downgrade__plan__btn" iconPosition="right" v-else-if="(plan.public_id === paddlePublicIds.enterpriseMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="handleDowngrade('300', monthlyScaleId)">Downgrade
                </ui-button>
                <!-- Show reactivate account for past due users-->
                <a v-if="((plan.public_id === paddlePublicIds.scaleMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAST_DUE))"
                  @click="() => updateBillingDetails()">
                  <ui-button size="large" class="upgrade__plan__btn">Reactivate Account</ui-button>
                </a>
                <a v-else-if="((plan.public_id === paddlePublicIds.scaleMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAST_DUE))"
                  @click="() => updateBillingDetails()">
                  <ui-button size="large" class="upgrade__plan__btn">Reactivate Account</ui-button>
                </a>
                <!-- Resume to Scale Monthly when on Scale Monthly subscription Paused -->
                <ui-button class="resume__plan__btn" iconPosition="right" v-if="(plan.public_id === paddlePublicIds.scaleMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAUSED)"
                  @click="() => resumeSubscription(monthlyScaleId)"
                  :loading="subscriptionStatus.planId === monthlyScaleId">Reactivate Plan
                </ui-button>
                <!-- Resume to Scale Monthly when on Scale Monthly Trial subscription Paused -->
                <ui-button class="resume__plan__btn" iconPosition="right" v-else-if="(plan.public_id === paddlePublicIds.scaleMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAUSED)"
                  @click="() => resumeSubscription(trialMonthlyScaleId)"
                  :loading="subscriptionStatus.planId === trialMonthlyScaleId">Reactivate Plan
                </ui-button>
                <br>
                <!---Cancel/Pause-->
                <!-- Show Pause when on Scale Monthly subscription -->
                <a class="cancel-link" v-if="((plan.public_id === paddlePublicIds.scaleMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE))"
                  @click="handlePause(monthPlan.forms)">Cancel subscription</a>
                <!-- Show Pause when on Scale Monthly Trial subscription -->
                <a class="cancel-link" v-else-if="((plan.public_id === paddlePublicIds.scaleMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE))"
                  @click="handlePause(monthPlan.forms)">Cancel subscription</a>
                <!-- Show Cancel when on Scale Monthly Trial subscription -->
                <a class="cancel-link" v-else-if="((plan.public_id === paddlePublicIds.scaleMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.TRIALING))"
                  @click="handleCancel(monthPlan.forms)">Cancel subscription</a>
              </div>
              <!-- Handle Footer Button on Enterprise Plan -->
              <div class="enterprise_div" v-if="(monthPlan.publicId === paddlePublicIds.enterpriseMonthly)">
                <!-- Upgrade to Enterprise Annual when no subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                  @click="() => subscribePlan(monthlyEnterpriseId)"
                  :loading="subscriptionStatus.planId === monthlyEnterpriseId">Upgrade
                </ui-button>
              <!-- Upgrade to Enterprise Monthly when on Starter Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised
                v-if="(plan.public_id === paddlePublicIds.starterMonthly) ||
                (plan.public_id === paddlePublicIds.singlePlan) &&
                (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(monthlyEnterpriseId, paddlePublicIds.enterpriseMonthly)"
                  >Upgrade
                </ui-button>
                <!-- Upgrade to Enterprise Monthly when on Pro Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised
                v-if="(plan.public_id === paddlePublicIds.proMonthly) ||
                (plan.public_id === paddlePublicIds.singlePlan) &&
                (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(monthlyEnterpriseId, paddlePublicIds.enterpriseMonthly)"
                  >Upgrade
                </ui-button>
                <!-- Upgrade to Enterprise Monthly when on Pro Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="(plan.public_id === paddlePublicIds.proMonthly) ||
                  (plan.public_id === paddlePublicIds.singlePlan) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(monthlyEnterpriseId, paddlePublicIds.enterpriseMonthly)">Upgrade
                </ui-button>
                <!-- Upgrade to Enterprise Monthly when on Pro Monthly Trial subscription after trial ends -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="(plan.public_id === paddlePublicIds.proMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => updateSubscriptionPlan(monthlyEnterpriseId)"
                  :loading="subscriptionStatus.planId === monthlyEnterpriseId">Upgrade
                </ui-button>
                <!-- Upgrade to Enterprise Monthly when on Scale Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="(plan.public_id === paddlePublicIds.scaleMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(monthlyEnterpriseId, paddlePublicIds.enterpriseMonthly)">Upgrade
                </ui-button>
                <!-- Upgrade to Enterprise Monthly when on Scale Monthly Trial subscription after trial ends -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="(plan.public_id === paddlePublicIds.scaleMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => updateSubscriptionPlan(monthlyEnterpriseId)"
                  :loading="subscriptionStatus.planId === monthlyEnterpriseId">Upgrade
                </ui-button>
                <!-- Upgrade to Enterprise Annual when on Enterprise Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" size="large" v-else-if="(plan.public_id === paddlePublicIds.enterpriseMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(annualEnterpriseId, filteredYearlyPlans)">Get Enterprise
                  Yearly</ui-button>
                <!-- Upgrade to Enterprise Annual when on Enterprise Monthly Trial subscription after trial ends-->
                <ui-button class="upgrade__plan__btn" iconPosition="right" size="large" v-else-if="(plan.public_id === paddlePublicIds.enterpriseMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(annualEnterpriseId, filteredYearlyPlans)">Get Enterprise
                  Yearly</ui-button>
                <!-- Show Subscribe Now when on Enterprise Monthly Trial subscription -->
                <!-- Show trial message when on trial -->
                <div class="left-align" v-else-if="(plan.public_id === paddlePublicIds.enterpriseMonthlyTrial) &&
                  hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING">
                  <ui-button class="subscribe__plan__btn" iconPosition="right"
                    @click="() => subscribeWithinTrial(trialMonthlyEnterpriseId)"
                    :loading="subscriptionStatus.planId === trialMonthlyEnterpriseId">Subscribe Now</ui-button>
                  <p>
                    <i><small>You're currently in trial period.</small></i>
                  </p>
                </div>
                <!-- Show Subscribe when on Pro/Scale Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right"
                  v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                    (plan.public_id === paddlePublicIds.proMonthlyTrial) || (plan.public_id === paddlePublicIds.scaleMonthlyTrial))"
                  @click="() => upgradeWithinTrial(monthlyEnterpriseId)"
                  :loading="subscriptionStatus.planId === monthlyEnterpriseId">
                  Subscribe
                </ui-button>
                <!-- Show reactivate account for past due users-->
                <a v-if="((plan.public_id === paddlePublicIds.enterpriseMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAST_DUE))"
                  @click="() => updateBillingDetails()">
                  <ui-button size="large" class="upgrade__plan__btn">Reactivate Account</ui-button>
                </a>
                <a v-else-if="((plan.public_id === paddlePublicIds.enterpriseMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAST_DUE))"
                  @click="() => updateBillingDetails()">
                  <ui-button size="large" class="upgrade__plan__btn">Reactivate Account</ui-button>
                </a>
                <!-- Resume to Enterprise Monthly when on Enterprise Monthly subscription Paused -->
                <ui-button class="resume__plan__btn" iconPosition="right" v-if="(plan.public_id === paddlePublicIds.enterpriseMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAUSED)"
                  @click="() => resumeSubscription(monthlyEnterpriseId)"
                  :loading="subscriptionStatus.planId === monthlyEnterpriseId">Reactivate Plan
                </ui-button>
                <!-- Resume to Enterprise Monthly when on Enterprise Monthly Trial subscription Paused -->
                <ui-button class="resume__plan__btn" iconPosition="right" v-else-if="(plan.public_id === paddlePublicIds.enterpriseMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.PAUSED)"
                  @click="() => resumeSubscription(trialMonthlyEnterpriseId)"
                  :loading="subscriptionStatus.planId === trialMonthlyEnterpriseId">Reactivate Plan
                </ui-button>
                <br>
                <!---Cancel/Pause-->
                <!-- Show Pause when on Enterprise Monthly subscription -->
                <a class="cancel-link" v-if="((plan.public_id === paddlePublicIds.enterpriseMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE))"
                  @click="handlePause(monthPlan.forms)">Cancel subscription</a>
                <!-- Show Pause when on Enterprise Monthly Trial subscription -->
                <a class="cancel-link" v-else-if="((plan.public_id === paddlePublicIds.enterpriseMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE))"
                  @click="handlePause(monthPlan.forms)">Cancel subscription</a>
                <!-- Show Cancel when on Enterprise Monthly Trial subscription -->
                <a class="cancel-link" v-else-if="((plan.public_id === paddlePublicIds.enterpriseMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.TRIALING))"
                  @click="handleCancel(monthPlan.forms)">Cancel subscription</a>
              </div>
            </div>
            <div class="plan__header__btn" v-if="!hasCancelledSubscription">
              <!-- Handle Header Button on Pro Plan -->
              <div class="pro_div" v-if="(monthPlan.publicId === paddlePublicIds.proMonthly)">
                <!-- Upgrade to Pro Monthly when no subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                  @click="() => subscribePlan(monthlyProId)" :loading="subscriptionStatus.planId === monthlyProId">
                  Upgrade
                </ui-button>
                <!-- Show Subscribe when on Scale Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.scaleMonthlyTrial))"
                  @click="() => downgradeWithinTrial('100', monthlyProId)">
                  Subscribe
                </ui-button>
                <!-- Show Upgrade when on Single Plan subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.ACTIVE) &&
                  (plan.public_id === paddlePublicIds.singlePlan))" @click="() => subscribePlan(monthlyProId)"
                  :loading="subscriptionStatus.planId === monthlyProId">
                  Upgrade
                </ui-button>
                <!-- Show Subscribe when on Enterprise Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial))"
                  @click="() => downgradeWithinTrial('300', monthlyProId)"
                  :loading="subscriptionStatus.planId === monthlyProId">
                  Subscribe
                </ui-button>
                <!-- Show Your Plan when on Pro Monthly subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled
                  v-if="(plan.public_id === paddlePublicIds.proMonthly)">Your Plan</ui-button>
                <!-- Show Your Plan when on Pro Monthly Trial subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled
                  v-else-if="(plan.public_id === paddlePublicIds.proMonthlyTrial)">Your Plan</ui-button>
              </div>
              <!-- Handle Header Button on Scale Plan -->
              <div class="scale_div" v-if="(monthPlan.publicId === paddlePublicIds.scaleMonthly)">
                <!-- Upgrade to Scale Monthly when no subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                  @click="() => subscribePlan(monthlyScaleId)" :loading="subscriptionStatus.planId === monthlyScaleId">
                  Upgrade
                </ui-button>
                <!-- Upgrade to Scale Monthly when on Pro Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="(plan.public_id === paddlePublicIds.proMonthly) ||
                  (plan.public_id === paddlePublicIds.singlePlan) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(monthlyScaleId, paddlePublicIds.scaleMonthly)">Upgrade
                </ui-button>
                <!-- Upgrade to Scale Monthly when on Pro Monthly Trial subscription after trial ends -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="(plan.public_id === paddlePublicIds.proMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => updateSubscriptionPlan(monthlyScaleId)"
                  :loading="subscriptionStatus.planId === monthlyScaleId">Upgrade
                </ui-button>
                <!-- Show Subscribe when on Pro Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.proMonthlyTrial))"
                  @click="() => upgradeWithinTrial(monthlyScaleId)"
                  :loading="subscriptionStatus.planId === monthlyScaleId">
                  Subscribe
                </ui-button>
                <!-- Show Subscribe when on Enterprise Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right" v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                  (plan.public_id === paddlePublicIds.enterpriseMonthlyTrial))"
                  @click="() => downgradeWithinTrial('300', monthlyScaleId)"
                  :loading="subscriptionStatus.planId === monthlyScaleId">
                  Subscribe
                </ui-button>
                <!-- Show Your Plan when on Scale Monthly subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled
                  v-if="(plan.public_id === paddlePublicIds.scaleMonthly)">Your Plan</ui-button>
                <!-- Show Your Plan when on Scale Monthly Trial subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled
                  v-else-if="(plan.public_id === paddlePublicIds.scaleMonthlyTrial)">Your Plan</ui-button>
              </div>
              <!-- Handle Header Button on Enterprise Plan -->
              <div class="enterprise_div" v-if="(monthPlan.publicId === paddlePublicIds.enterpriseMonthly)">
                <!-- Upgrade to Enterprise Annual when no subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                  @click="() => subscribePlan(monthlyEnterpriseId)"
                  :loading="subscriptionStatus.planId === monthlyEnterpriseId">Upgrade
                </ui-button>
                <!-- Upgrade to Enterprise Monthly when on Pro Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="(plan.public_id === paddlePublicIds.proMonthly) ||
                  (plan.public_id === paddlePublicIds.singlePlan) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(monthlyEnterpriseId, paddlePublicIds.enterpriseMonthly)">Upgrade
                </ui-button>
                <!-- Upgrade to Enterprise Monthly when on Pro Monthly Trial subscription after trial ends -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="(plan.public_id === paddlePublicIds.proMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => updateSubscriptionPlan(monthlyEnterpriseId)"
                  :loading="subscriptionStatus.planId === monthlyEnterpriseId">Upgrade
                </ui-button>
                <!-- Upgrade to Enterprise Monthly when on Scale Monthly subscription -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="(plan.public_id === paddlePublicIds.scaleMonthly) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => handleUpgrade(monthlyEnterpriseId, paddlePublicIds.enterpriseMonthly)">Upgrade
                </ui-button>
                <!-- Upgrade to Scale Monthly when on Scale Monthly Trial subscription after trial ends -->
                <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="(plan.public_id === paddlePublicIds.scaleMonthlyTrial) &&
                  (subscription.status === paddleSubscriptionStatusTypes.ACTIVE)"
                  @click="() => updateSubscriptionPlan(monthlyEnterpriseId)"
                  :loading="subscriptionStatus.planId === monthlyEnterpriseId">Upgrade
                </ui-button>
                <!-- Show Subscribe when on Pro/Scale Monthly Trial subscription -->
                <ui-button class="subscribe__plan__btn" iconPosition="right"
                  v-else-if="((hasSubscription && subscription.status === paddleSubscriptionStatusTypes.TRIALING) &&
                    (plan.public_id === paddlePublicIds.proMonthlyTrial) || (plan.public_id === paddlePublicIds.scaleMonthlyTrial))"
                  @click="() => upgradeWithinTrial(monthlyEnterpriseId)"
                  :loading="subscriptionStatus.planId === monthlyEnterpriseId">
                  Subscribe
                </ui-button>
                <!-- Show Your Plan when on Enterprise Annual subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled
                  v-if="(plan.public_id === paddlePublicIds.enterpriseMonthly)">Your Plan</ui-button>
                <!-- Show Your Plan when on Enterprise Annual Trial subscription -->
                <ui-button class="your__plan__btn" iconPosition="right" disabled
                  v-else-if="(plan.public_id === paddlePublicIds.enterpriseMonthlyTrial)">Your Plan</ui-button>
              </div>
              <!-- Show only for 24 hours discount -->
              <div class="hour-discount" v-if="(userMixin_showTwentyFourHourDiscount)">
                <span>30% Off</span>
              </div>
            </div>
            <div class="plan__features">
              <!-- <h5>{{ monthPlan.text }}</h5> -->
              <ul>
                <li class="plan__feature" v-for="(feature, index) in monthPlan.features" :key="index">
                  <div v-if="Array.isArray(feature)">
                    <ul>
                      <li v-for="nestedFeature in feature" :key="nestedFeature">
                        <div><span class="material-icons">close</span>{{ nestedFeature }}</div>
                      </li>
                    </ul>
                  </div>
                  <div class="included-features" v-else><span class="material-icons">check</span>{{ feature }}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="subscription-view__main__other">
        <h3>See All Features:</h3>
        <div class="all__features">
          <table>
           <thead>
            <tr>
              <th>Plan</th>
              <th>Starter</th>
              <th>Pro</th>
              <th>Scale</th>
              <th>Enterprise</th>
            </tr>
           </thead>
           <tbody>
            <tr v-for="(feature, index) in allPlanFeatures" :key="index">
              <td>{{feature.Plan}}</td>
              <td :class="{ 'green_check': feature.Starter === '✓', 'grey_cross': feature.Starter === '✗' }">{{ feature.Starter }}</td>
              <td :class="{ 'green_check': feature.Pro === '✓', 'grey_cross': feature.Pro === '✗' }">{{ feature.Pro }}</td>
              <td :class="{ 'green_check': feature.Scale === '✓', 'grey_cross': feature.Scale === '✗' }">{{ feature.Scale }}</td>
              <td :class="{ 'green_check': feature.Enterprise === '✓', 'grey_cross': feature.Enterprise === '✗' }">{{ feature.Enterprise }}</td>
            </tr>
           </tbody>
          </table>
        </div>
        <br>
        <br>
        <h3>Enterprise</h3>
        <div class="row__two">
          <div class="list-row">
            <h5>Custom solutions available for enterprises</h5>
            <li><span class="material-icons">check</span> Access to more than 300 forms</li>
            <li><span class="material-icons">check</span> Dedicated support manager</li>
          </div>
          <div class="button-row">
            <a href="https://leadgenapp.io/book-onboarding-call/" target="_blank" class="ui-button--white-color">Book a
              Call</a>
          </div>
        </div>
        <div class="row__three">
          <h6>Get detailed insight into plans and features on our
            <a href="https://leadgenapp.io/pricing/">pricing page</a>
          </h6>
          <br>
          <p>1) Tax deductible: Depending on each country/states tax laws</p>
          <br>
        </div>
      </div>
      <div class="subscription-view__main__modals">
        <div v-if="planAction === 'update'">
          <ui-modal class="plan-action-modal" ref="planActionModal" :dismissible="true"
            @close="closeModal('planActionModal')" dismiss-on="close-button subscriptionStatus.upgradeSuccess"
            size="auto">
            <div slot="header"></div>
            <p>
            <h3>Do you want to upgrade your plan to {{ currentPlanDetails.title }} {{ yearlyUpgrade ? 'Annual' :
              'Monthly' }}?</h3>
            </p>
            <ul>
              <li>
                <span class="material-icons">check</span>Capture up to {{ currentPlanDetails.features[0] }}
              </li>
              <li>
                <span class="material-icons">check</span>Access to up to {{ currentPlanDetails.forms }} forms
              </li>
              <li>
                <span class="material-icons">check</span>Efficiently capture up to {{ currentPlanDetails.features[2] }}
              </li>
              <li>
                <span class="material-icons">check</span>All features
              </li>
              <li>
                <span class="material-icons">check</span>No Risk - Cancel anytime
              </li>
            </ul>
            <h3 v-show="yearlyUpgrade"> Due Today ${{ currentPlanDetails.price * 12 }}. USD </h3>
            <p><strong> ${{ currentPlanDetails.price }} / mo. {{ yearlyUpgrade ? 'Billed Annually' : 'Billed Monthly' }}
              </strong> </p>
            <div slot="footer">
              <ui-button class="no-action-button" color="primary" type="primary" raised
                @click="updateSubscriptionPlan(subscriptionStatus.planId)" :disabled="loading"
                style="padding: 15px 35px !important; margin-left: 300px !important">
                <span v-if="loading">
                  <ui-progress-circular color="white"></ui-progress-circular>
                </span>
                <span v-else>
                  Confirm
                </span>
              </ui-button>
            </div>
            <ui-alert type="info" :dismissible="false" v-if="loading">
              Please wait, we are processing your request.
            </ui-alert>
            <ui-alert type="error" :dismissible="false" v-else-if="subscriptionStatus.upgradeFailed">
              Unable to upgrade, Try again.
            </ui-alert>
          </ui-modal>
        </div>
        <div v-else-if="planAction === 'downgrade' || planAction === 'cancel' || planAction === 'pause'">
          <ui-modal class="plan-action-modal" ref="planActionModal" :dismissible="true" dismiss-on="close-button"
            @close="closeModal('planActionModal')" size="auto">
            <div slot="header"></div>
            <h3>Do you want to {{ planAction === 'pause' ? 'cancel' : planAction }} your plan?</h3>
            <p>When you {{ planAction === 'pause' ? 'cancel' : planAction }} you will lose the following</p>
            <ul>
              <li>
                <span class="material-icons">clear</span>Access to up to {{ planActionForms }} forms
              </li>
              <li>
                <span class="material-icons">clear</span>Your embedded forms will stop loading on your pages
              </li>
              <li>
                <span class="material-icons">clear</span>Access to your leads and response data
              </li>
              <li>
                <span class="material-icons">clear</span>Full flexibility of your form design
              </li>
            </ul>
            <p>Do you really want to {{ planAction === 'pause' ? 'cancel' : planAction }}?</p>
            <div slot="footer">
              <ui-button class="no-action-button" color="primary" type="primary" raised
                @click="$router.push({ name: 'forms.index' })">
                Keep my forms and Leads
              </ui-button>
              <ui-button v-if="rescheduleAction" class="action-button" :loading="subscriptionStatus.syncingButton"
                @click="reschedulePayment(subscriptionStatus.planId)"> downgrade
              </ui-button>
              <ui-button v-else class="action-button" :loading="subscriptionStatus.syncingButton" @click="planAction === 'pause' ? pauseSubscription() :
                planAction === 'cancel' ? cancelSubscription() :
                  updateSubscriptionPlan(subscriptionStatus.planId)">
                {{ planAction === 'pause' ? 'cancel subscription' :
                  planAction === 'cancel' ? 'cancel subscription' :
                    'downgrade plan' }}
              </ui-button>
            </div>
          </ui-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as actionTypes from '@/store/action-types.js'
import Vue from 'vue'
import config from '@/api/config'
import environments from '@/shared/constants/environments'
import userMixin from '@/mixins/user'
import helperMixin from '@/mixins/helper'
import pastDueMixin from '@/mixins/plan'
import dashboardMixin from '@/mixins/dashboard'
import planPublicIds from '@/shared/constants/plan/paddle/publicIds'
import paddleAction from '@/shared/constants/plan/paddle/action'
import { paddleMonthlyPlans, paddleYearlyPlans, allPlanFeatures } from '@/shared/constants/plan/paddle/plans'
import $ from 'jquery'

export default {
  mixins: [userMixin, helperMixin, pastDueMixin, dashboardMixin],
  data: function () {
    return {
      yearPlan: false,
      paddlePlans: {
        yearly: paddleYearlyPlans,
        monthly: paddleMonthlyPlans
      },
      allPlanFeatures: allPlanFeatures,
      paddlePublicIds: {
        proMonthlyTrial: planPublicIds.paddleProMonthlyTrial,
        proYearlyTrial: planPublicIds.paddleProYearlyTrial,
        scaleMonthlyTrial: planPublicIds.paddleScaleMonthlyTrial,
        scaleYearlyTrial: planPublicIds.paddleScaleYearlyTrial,
        enterpriseMonthlyTrial: planPublicIds.paddleEnterpriseMonthlyTrial,
        enterpriseYearlyTrial: planPublicIds.paddleEnterpriseYearlyTrial,
        starterMonthly: planPublicIds.paddleStarterMonthly,
        proMonthly: planPublicIds.paddleProMonthly,
        scaleMonthly: planPublicIds.paddleScaleMonthly,
        enterpriseMonthly: planPublicIds.paddleEnterpriseMonthly,
        proYearly: planPublicIds.paddleProYearly,
        scaleYearly: planPublicIds.paddleScaleYearly,
        enterpriseYearly: planPublicIds.paddleEnterpriseYearly,
        singlePlan: planPublicIds.paddleSinglePlan
      },
      subscriptionStatus: {
        interval: null,
        intervalCounter: 0,
        intervalLimit: 10,
        subscribeSuccess: false,
        subscribeFailed: false,
        upgradeSuccess: false,
        upgradeFailed: false,
        cancelSuccess: false,
        pauseSuccess: false,
        pauseFailed: false,
        resumeSuccess: false,
        resumeFailed: false,
        cancelFailed: false,
        downgradeSuccess: false,
        downgradeFailed: false,
        syncingButton: false,
        planId: null,
        billingUpdateSuccess: false,
        billingUpdateFailed: false,
        reschedulePaymentSuccess: false,
        reschedulePaymentFailed: false
      },
      couponDetails: {
        addDiscountCoupon: false,
        loaded: false,
        coupon: null
      },
      loading: false,
      currentPlanDetails: null,
      monthlyStarterId: null,
      monthlyProId: null,
      monthlyScaleId: null,
      monthlyEnterpriseId: null,
      annualProId: null,
      annualScaleId: null,
      annualEnterpriseId: null,
      trialMonthlyProId: null,
      trialMonthlyScaleId: null,
      trialMonthlyEnterpriseId: null,
      trialAnnualProId: null,
      trialAnnualScaleId: null,
      trialAnnualEnterpriseId: null,
      planAction: null,
      planActionForms: null,
      singlePlanId: null,
      rescheduleAction: null,
      yearlyUpgrade: false,
      monthlyUpgrade: true
    }
  },
  mounted: function () {
    this.monthlyProId = config.paddle.planId
    this.monthlyScaleId = config.paddle.scaleId
    this.monthlyEnterpriseId = config.paddle.enterpriseId
    this.annualProId = config.paddle.annualProId
    this.annualScaleId = config.paddle.annualScaleId
    this.annualEnterpriseId = config.paddle.annualEnterpriseId
    this.trialMonthlyProId = config.paddle.trialPlanId
    this.trialMonthlyScaleId = config.paddle.trialScaleId
    this.trialMonthlyEnterpriseId = config.paddle.trialEnterpriseId
    this.trialAnnualProId = config.paddle.trialAnnualProId
    this.trialAnnualScaleId = config.paddle.trialAnnualScaleId
    this.trialAnnualEnterpriseId = config.paddle.trialAnnualEnterpriseId
    this.singlePlanId = config.paddle.singlePlanId
    this.monthlyStarterId = config.paddle.starterPlanId

    window.Paddle.Setup({ vendor: config.paddle.vendorId })
    if (this.userMixin_isPastDueUser) {
      this.planMixin_loadUpdateUrl()
    }

    if (this.userMixin_showTwentyFourHourDiscount) {
      this.loadDiscountCoupon()
    }
  },
  methods: {
    calculateSavings: function (a, b) {
      return (b - a) * 12
    },
    resetSubscriptionStatus: function () {
      this.subscriptionStatus = {
        interval: null,
        intervalCounter: 0,
        intervalLimit: 10,
        subscribeSuccess: false,
        subscribeFailed: false,
        upgradeSuccess: false,
        upgradeFailed: false,
        cancelSuccess: false,
        cancelFailed: false,
        pauseSuccess: false,
        pauseFailed: false,
        resumeSuccess: false,
        resumeFailed: false,
        downgradeSuccess: false,
        downgradeFailed: false,
        syncingButton: false,
        planId: null,
        billingUpdateSuccess: false,
        billingUpdateFailed: false,
        reschedulePaymentSuccess: false,
        reschedulePaymentFailed: false
      }
    },
    subscribePlan: function (planId) {
      this.resetSubscriptionStatus()
      let metdata = {
        user_id: this.user.id
      }
      this.subscriptionStatus.planId = planId
      this.planAction = 'subscribe'
      let checkoutConfig = {
        product: planId,
        theme: 'none',
        email: this.user.email,
        passthrough: JSON.stringify(metdata),
        marketingConsent: this.user.subscribe_newsletter,
        successCallback: () => {
          this.pollingSubscriptionStatus(() => {
            this.subscriptionStatus.subscribeSuccess = true
            this.adjustPlanLayout()
          })
        },
        closeCallback: () => {
          this.resetSubscriptionStatus()
        }
      }

      if (config.paddle.coupon) {
        checkoutConfig.coupon = config.paddle.coupon
      } else if (this.couponDetails.addDiscountCoupon) {
        checkoutConfig.coupon = this.couponDetails.coupon
      }

      if ([environments.LOCAL, environments.DEV].indexOf(config.environment) >= 0) {
        window.Paddle.Environment.set('sandbox')
      }

      window.Paddle.Checkout.open(checkoutConfig)
    },
    handleUpgrade: function (planId, planDetails) {
      this.planAction = 'update'
      let plan = ''
      if (!this.yearPlan && !(planDetails === this.filteredYearlyPlans)) {
        for (let i = 0; i < this.monthlyPlans.length; i++) {
          if (planDetails === this.monthlyPlans[i].publicId) {
            plan = this.monthlyPlans[i]
            break
          }
        }
        this.monthlyUpgrade = true
      } else if (planDetails === this.filteredYearlyPlans) {
        for (let i = 0; i < planDetails.length; i++) {
          if (planDetails[i].title === this.plan.title) {
            plan = planDetails[i]
          }
        }
        this.yearlyUpgrade = true
      } else {
        this.yearlyUpgrade = true
        plan = planDetails
      }
      this.currentPlanDetails = plan
      this.subscriptionStatus.planId = planId
      this.$nextTick(() => {
        this.openModal('planActionModal')
      })
    },
    updateSubscriptionPlan: function (updatedPanId) {
      if (!this.subscription) {
        return
      }
      this.loading = true
      this.resetSubscriptionStatus()
      if (this.planAction === 'downgrade') {
        this.subscriptionStatus.syncingButton = true
      } else {
        this.planAction = 'update'
      }
      this.subscriptionStatus.planId = updatedPanId
      this.$store.dispatch('subscription/updateSubscription', {
        id: this.user.plan.subscription.id,
        params: { planId: updatedPanId }
      }).then(() => {
        this.pollingSubscriptionStatus(() => {
          if (this.planAction === 'downgrade') {
            this.subscriptionStatus.downgradeSuccess = true
          } else {
            this.subscriptionStatus.upgradeSuccess = true
          }
          this.planAction = null
          this.rescheduleAction = null
          this.loading = false
        })
      }, () => {
        if (this.planAction === 'downgrade') {
          this.subscriptionStatus.downgradeFailed = true
        } else {
          this.subscriptionStatus.upgradeFailed = true
        }
        this.loading = false
      })
      this.closeModal('planActionModal')
    },
    handleCancel: function (formValue) {
      this.planAction = 'cancel'
      this.planActionForms = formValue
      this.$nextTick(() => {
        this.openModal('planActionModal')
      })
    },
    cancelSubscription: function () {
      if (!this.subscription) {
        return
      }
      this.planAction = 'cancel'
      this.resetSubscriptionStatus()
      this.subscriptionStatus.syncingButton = true
      this.$store.dispatch('subscription/cancelSubscription', {
        id: this.user.plan.subscription.id
      }).then(() => {
        this.pollingSubscriptionStatus(() => {
          this.subscriptionStatus.cancelSuccess = true
        })
      }, () => {
        this.subscriptionStatus.cancelFailed = true
        this.subscriptionStatus.syncingButton = false
      })
    },
    handleDowngrade: function (formValue, planId) {
      this.planAction = 'downgrade'
      this.planActionForms = formValue
      this.subscriptionStatus.planId = planId
      this.$nextTick(() => {
        this.openModal('planActionModal')
      })
    },
    handlePause: function (formValue, planId) {
      this.planAction = 'pause'
      this.planActionForms = formValue
      this.subscriptionStatus.planId = planId
      this.$nextTick(() => {
        this.openModal('planActionModal')
      })
    },
    pauseSubscription: function () {
      if (!this.subscription) {
        return
      }
      this.resetSubscriptionStatus()
      this.subscriptionStatus.syncingButton = true
      this.$store.dispatch('subscription/pauseSubscription', {
        id: this.user.plan.subscription.id
      }).then(() => {
        this.pollingSubscriptionStatus(() => {
          this.subscriptionStatus.pauseSuccess = true
          this.planAction = null
        })
      }, () => {
        this.subscriptionStatus.pauseFailed = true
      })
    },
    resumeSubscription: function (resumePlanId) {
      if (!this.subscription) {
        return
      }
      this.resetSubscriptionStatus()
      this.subscriptionStatus.planId = resumePlanId
      this.planAction = 'resume'
      this.$store.dispatch('subscription/resumeSubscription', {
        id: this.user.plan.subscription.id
      }).then(() => {
        this.pollingSubscriptionStatus(() => {
          this.subscriptionStatus.resumeSuccess = true
          this.planAction = null
        })
      }, () => {
        this.subscriptionStatus.resumeFailed = true
      })
    },
    updateBillingDetails: function () {
      window.Paddle.Setup({ vendor: config.paddle.vendorId })
      this.planAction = 'billing'
      let checkoutConfig = {
        override: this.pastDueDetails.updateBankDetailsUrl,
        successCallback: () => {
          this.pollingSubscriptionStatus(() => {
            this.subscriptionStatus.billingUpdateSuccess = true
          })
        },
        closeCallback: () => {
          this.resetSubscriptionStatus()
        }
      }
      if (
        [environments.LOCAL, environments.DEV].indexOf(config.environment) >= 0
      ) {
        window.Paddle.Environment.set('sandbox')
      }

      window.Paddle.Checkout.open(checkoutConfig)
    },
    subscribeWithinTrial: function (planId) {
      if (!this.subscription) {
        return
      }
      this.rescheduleAction = paddleAction.subscribeWithinTrial
      this.reschedulePayment(planId)
    },
    upgradeWithinTrial: function (planId) {
      if (!this.subscription) {
        return
      }
      this.rescheduleAction = paddleAction.upgradeWithinTrial
      this.reschedulePayment(planId)
    },
    downgradeWithinTrial: function (formValue, planId) {
      if (!this.subscription) {
        return
      }
      this.rescheduleAction = paddleAction.downgradeWithinTrial
      this.planActionForms = formValue
      this.subscriptionStatus.planId = planId
      this.openModal('planActionModal')
    },
    reschedulePayment: function (planId) {
      this.resetSubscriptionStatus()
      this.subscriptionStatus.planId = planId
      this.subscriptionStatus.syncingButton = true
      this.planAction = 'reschedule'
      this.$store.dispatch('subscription/listPayment', {
        id: this.user.plan.subscription_id
      }).then(() => {
        this.pollingSubscriptionStatus(() => {
          this.resetSubscriptionStatus()
          if (this.rescheduleAction === paddleAction.subscribeWithinTrial) {
            this.subscriptionStatus.reschedulePaymentSuccess = true
            this.rescheduleAction = null
          } else if (this.rescheduleAction === paddleAction.upgradeWithinTrial) {
            this.rescheduleAction = null
            this.planAction = 'update'
            this.updateSubscriptionPlan(planId)
          } else if (this.rescheduleAction === paddleAction.downgradeWithinTrial) {
            this.rescheduleAction = null
            this.planAction = 'downgrade'
            this.updateSubscriptionPlan(planId)
          }
        })
      }, () => {
        this.resetSubscriptionStatus()
        this.planAction = null
        this.rescheduleAction = null
        this.subscriptionStatus.reschedulePaymentFailed = true
      })
    },
    loadDiscountCoupon: function () {
      this.$store.dispatch('subscription/getDiscountCoupon').then((response) => {
        this.couponDetails.loaded = true
        this.couponDetails.addDiscountCoupon = true
        this.couponDetails.coupon = response.data.data.coupon
      }, () => {
        this.couponDetails.loaded = false
        this.couponDetails.addDiscountCoupon = false
        this.couponDetails.coupon = null
      })
    },
    calculatePercent: function (data) {
      let calculatedPercent = data - (data * 0.30)
      return Math.round(calculatedPercent)
    },
    pollingSubscriptionStatus: function (callback = null) {
      this.subscriptionStatus.interval = setInterval(() => {
        if (this.subscriptionStatus.intervalCounter >= this.subscriptionStatus.intervalLimit) {
          this.subscriptionStatus.subscribeSuccess = false
          this.subscriptionStatus.subscribeFailed = true
          this.subscriptionStatus.billingUpdateSuccess = false
          this.subscriptionStatus.billingUpdateFailed = true
          this.planAction = null
          this.rescheduleAction = null
          this.subscriptionStatus.planId = null
          clearInterval(this.subscriptionStatus.interval)
          this.scrollToTop()
          return
        }
        this.subscriptionStatus.intervalCounter++
        this.syncLocalUser(() => {
          clearInterval(this.subscriptionStatus.interval)
          if (callback) {
            callback()
          }
        })
      }, 8000)
    },
    syncLocalUser: function (callback) {
      this.$nextTick(() => {
        this.closeModal('planActionModal')
      })
      this.$store.dispatch(actionTypes.FETCH_USER, {
        userId: this.user.id
      })
        .then((response) => {
          let user = response.data.data
          if (!user.plan || !user.plan.subscription) {
            return
          }
          const successAction = () => {
            this.$store.dispatch(actionTypes.UPDATE_USER_LOCAL, user)
              .then(() => {
                localStorage.setItem('user', JSON.stringify(user))
              })
            this.subscriptionStatus.syncingButton = false
            this.scrollToTop()
            callback()
          }

          if (this.planAction === 'subscribe' && user.plan && user.plan.subscription) {
            successAction()
          }

          if ((this.planAction === 'update' || this.planAction === 'downgrade') &&
            (user.plan.subscription.paddle_plan === this.subscriptionStatus.planId.toString())) {
            successAction()
          }

          if (this.planAction === 'cancel' && user.plan.subscription.status === 'deleted') {
            successAction()
          }

          if (this.planAction === 'reschedule' && user.plan.subscription.status === 'active') {
            successAction()
          }

          if (this.planAction === 'pause' && user.plan.subscription.status === 'paused') {
            successAction()
          }

          if (this.planAction === 'resume' && user.plan.subscription.status === 'active' &&
            (user.plan.subscription.paddle_plan === this.subscriptionStatus.planId.toString())) {
            successAction()
          }

          if (this.planAction === 'billing') {
            successAction()
          }
        })
    },
    openModal: function (ref) {
      this.$refs[ref].open()
    },
    closeModal: function (ref) {
      this.scrollToTop()
      this.$refs[ref].close()
    },
    scrollToTop: function () {
      $('html, body').animate({ scrollTop: 0 }, 'slow')
    }
  },
  computed: {
    filteredYearlyPlans() {
      if (this.plan.public_id !== this.paddlePublicIds.singlePlan) {
        return this.paddlePlans.yearly.slice(0, 3)
      }
      return this.paddlePlans.yearly
    },
    monthlyPlans() {
      return this.paddlePlans.monthly
    }
  }
}
</script>
