<template>
  <div class="d-flex justify-center align-center" style="height: 80vh;" v-if="!userFormLoaded">
    <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
      color="secondary-red"></v-progress-circular>
  </div>
  <div class="d-flex justify-center align-center flex-column mt-10" style="height: 80vh;"
    v-else-if="userFormLoadedError">
    <h4>
      Whoops, looks like something went wrong. Please click reload to try again.
    </h4>
    <v-btn prepend-icon="mdi-refresh-circle" size="large" color="primary-red"
      class="font-weight-bold text-none">Reload</v-btn>
  </div>
  <div v-else>
    <v-container class="user-edit-component__content mx-auto">
      <!-- Tracking info -->
      <v-expansion-panels elevation="0" class="pa-5" v-model="panel.trackingInfo" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-folder-information" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Tracking info</p>
            </h6>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="border-thin border-t-0">
            <v-table>
              <thead class="bg-white">
                <tr>
                  <th class="font-weight-bold">Field</th>
                  <th class="font-weight-bold">Values</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Country</td>
                  <td>{{ user.country }}</td>
                </tr>
                <tr>
                  <td>IP address</td>
                  <td>{{ user.ip }}</td>
                </tr>
                <tr>
                  <td>City</td>
                  <td>{{ user.city }}</td>
                </tr>
                <tr>
                  <td>Source</td>
                  <td>{{ user.source }}</td>
                </tr>
                <tr v-for="(value, prop) in user.signup_params" :key="value.id">
                  <td>{{ prop }}</td>
                  <td>{{ value }}</td>
                </tr>
              </tbody>
            </v-table>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- onboarding details -->
      <v-expansion-panels elevation="0" class="pa-5" v-model="panel.onboardingDetails" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-card-account-details" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Onboarding Details</p>
            </h6>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="border-thin border-t-0">
            <v-table>
              <thead class="bg-white">
                <tr>
                  <th class="font-weight-bold">Industry</th>
                  <th class="font-weight-bold">Role</th>
                  <th class="font-weight-bold">Goal</th>
                  <th class="font-weight-bold">First Heard From</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{
                      user.onboardingData
                        ? user.onboardingData.industry.replace(/_/g, " ")
                        : "N/A"
                    }}
                  </td>
                  <td>
                    {{
                      user.onboardingData
                        ? user.onboardingData.role.replace(/_/g, " ")
                        : "N/A"
                    }}
                  </td>
                  <td>
                    {{
                      user.onboardingData
                        ? user.onboardingData.goal.replace(/_/g, " ")
                        : "N/A"
                    }}
                  </td>
                  <td>
                    {{
                      user.onboardingData && user.onboardingData.first_heard
                        ? user.onboardingData.first_heard
                        : "N/A"
                    }}
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Basic details section -->
      <v-expansion-panels elevation="0" class="pa-5" v-model="panel.basicDetails" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-smart-card" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Basic Details</p>
            </h6>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="border-thin border-t-0">
            <v-row class="mt-5 mb-3 pl-7 pr-7">
              <v-col cols="12">
                <v-form @submit.prevent="onBasicFormSubmit" ref="basicDetailsForm">
                  <v-text-field label="Account" prepend-inner-icon="mdi-account" variant="outlined" v-model="user.name"
                    required :rules="$valid([{ rule: 'required', fieldName: 'Name' }])"></v-text-field>
                  <br>
                  <v-text-field label="Email Address" prepend-inner-icon="mdi-email-outline" variant="outlined"
                    :rules="$valid([{ rule: 'required', fieldName: 'Email' }, { rule: 'email' }])" v-model="user.email"
                    required></v-text-field>

                  <v-switch inset persistent-hint density="comfortable" v-model="user.twoFactor" color="primary-red"
                    label="Enable two factor Authentication" hide-details>
                  </v-switch>
                  <div class="text-center">
                    <v-btn type="submit" :loading="basicForm.saving" class="mt-4 font-weight-bold text-none"
                      color="primary-red" prepend-icon="mdi-content-save-cog">Save Changes
                    </v-btn>
                  </div>
                  <div v-if="basicForm.submitted">
                    <v-alert v-if="basicForm.saveErrorMsg.length === 0" closable density="compact" type="success"
                      theme="dark" border class="mt-3">
                      Basic Details updated successfully.
                    </v-alert>
                    <v-alert v-else closable density="compact" type="error" border class="mt-3">
                      {{ basicForm.saveErrorMsg }}
                    </v-alert>
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- change password -->
      <v-expansion-panels elevation="0" class="pa-5" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-lock-reset" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Change Password</p>
            </h6>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="border-thin border-t-0">
            <v-row class="mt-5 mb-3 pl-7 pr-7">
              <v-col cols="12">
                <v-form @submit.prevent="onPasswordFormSubmit" ref="changePasswordForm">
                  <v-text-field v-model="user.password" :type="visible ? 'text' : 'password'"
                    :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" @click:append-inner="visible = !visible"
                    prepend-inner-icon="mdi-lock-outline" label="Set New Password" variant="outlined" :rules="$valid([
                      { rule: 'required', fieldName: 'Password' },
                      { rule: 'password' },
                      { rule: 'between', min: '8', max: '150' }
                    ])">
                  </v-text-field>
                  <br>
                  <v-text-field v-model="user.confirm_password" :type="confirmVisible ? 'text' : 'password'"
                    :append-inner-icon="confirmVisible ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append-inner="confirmVisible = !confirmVisible" prepend-inner-icon="mdi-lock-outline"
                    variant="outlined" :rules="$valid([
                      { rule: 'required', fieldName: 'Confirm Password' },
                      { rule: 'password' },
                      { rule: 'confirm', field: user.password },
                      { rule: 'between', min: '8', max: '150' }
                    ])" label="Confirm Password">
                  </v-text-field>
                  <div class="text-center">
                    <v-btn type="submit" :loading="passwordForm.saving" class="font-weight-bold mt-3 text-none"
                      color="primary-red" @click="submit" prepend-icon="mdi-content-save-cog">Save Changes</v-btn>
                  </div>
                  <div class="form-field" v-if="passwordForm.submitted">
                    <v-alert class="mt-3" v-if="passwordForm.saveErrorMsg.length === 0" closable border
                      density="compact" type="success">Password saved successfully</v-alert>
                    <v-alert class="mt-3" v-else closable border density="compact" type="error">{{
                      passwordForm.saveErrorMsg }}</v-alert>
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Change Role -->
      <v-expansion-panels elevation="0" class="pa-5" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-table-account" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Role</p>
            </h6>
            <v-row no-gutters>
              <v-col class="d-flex justify-start" cols="4">
                <span class="lg-badge bg-primary-green ml-4">{{ user.role }}</span>
              </v-col>
            </v-row>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="border-thin border-t-0">
            <v-row class="mt-5 mb-3 pl-7 pr-7">
              <v-col cols="12">
                <v-form @submit.prevent="onRoleFormSubmit" ref="changeRole">
                  <v-select label="Role" :items="roles" item-title="role" v-model="user.role"
                    variant="outlined"></v-select>
                  <div class="text-center">
                    <v-btn type="submit" class="font-weight-bold text-none" :loading="roleForm.saving"
                      color="primary-red" @click="submit" prepend-icon="mdi-content-save-cog">Save Changes</v-btn>
                  </div>
                  <div class="form-field" v-if="roleForm.submitted">
                    <v-alert v-if="roleForm.saveErrorMsg.length === 0" class="mt-3" closable border density="compact"
                      type="success">Role saved successfully</v-alert>
                    <v-alert v-else class="mt-3" closable border density="compact" type="error">{{
                      roleForm.saveErrorMsg
                      }}</v-alert>
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Activate Deactivate account -->
      <v-expansion-panels elevation="0" class="pa-5" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-account-cog" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Activate/Deactivate</p>
            </h6>
            <span v-if="user.active" class="lg-badge ml-4 mr-6 bg-primary-green">Active</span>
            <span v-else class="lg-badge ml-4 mr-6 bg-secondary-red">Suspended</span>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="border-thin border-t-0">
            <v-row class="mt-5 mb-3 pl-7 pr-7">
              <v-col cols="12">
                <v-form @submit.prevent="onActivationFormSubmit" ref="accountActivation">
                  <div class="toggle-wrapper bg-grey-lighten-2 pl-2 rounded-pill">
                    <v-switch inset inline persistent-hint density="comfortable" v-model="user.active"
                      color="primary-red" :label="user.active ? 'Active' : 'Suspended'" hide-details>
                    </v-switch>
                  </div>

                  <div class="text-center">
                    <v-btn type="submit" :loading="activationForm.saving" class="font-weight-bold mt-5 text-none"
                      color="primary-red" @click="submit" prepend-icon="mdi-content-save-cog">Save Changes</v-btn>
                  </div>
                  <div class="form-field" v-if="activationForm.submitted">
                    <v-alert v-if="activationForm.saveErrorMsg.length === 0" class="mt-3" closable border
                      density="compact" type="success">User account {{ user.active ? "Activated" : "Deactivated" }}
                      successfully</v-alert>
                    <v-alert v-else class="mt-3" closable border density="compact" type="error">{{
                      activationForm.saveErrorMsg }}</v-alert>
                  </div>
                </v-form>
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Plan Details -->
      <v-expansion-panels elevation="0" class="pa-5" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-currency-usd" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Plan</p>
            </h6>
          </v-expansion-panel-title>

          <v-expansion-panel-text class="border-thin border-t-0">
            <v-form>
              <div class="form-field">
                <h6 class="lg-uppercase lg-flex lg-align-items-center">
                  <span>Current Plan </span><span class="lg-badge bg-primary-green ml-4">{{ user.plan }}</span>
                </h6>
              </div>
              <div class="form-field">
                <h6 class="lg-uppercase lg-flex lg-align-items-center">
                  <span>Subscription</span><span class="lg-badge bg-primary-green ml-4">{{
                    isSubscriptionCancelled ? "cancelled" : "active"
                    }}</span>
                </h6>
              </div>
            </v-form>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- Delete Account -->
      <v-expansion-panels elevation="0" class="pa-5" ripple>
        <v-expansion-panel>
          <v-expansion-panel-title color="blue-lighten-5">
            <h6 class="d-flex justify-left mb-0">
              <v-icon icon="mdi-delete-alert" class="pt-1"></v-icon>
              <p class="font-weight-bold text-primary-blue mb-0">&nbsp;Delete Account</p>
            </h6>
          </v-expansion-panel-title>
          <v-expansion-panel-text class="border-thin border-t-0">
            <v-alert variant="tonal" v-if="requestAccountAction.toBeDeleted" icon="mdi-alert-circle" border
              class="mt-2 pa-4" density="compact" type="error">
              <span class="text-black">This account will be deleted after 24 hours. If you wish to stop the deletion
                process, please click the button below.</span>
            </v-alert>
            <v-alert v-else type="error" variant="tonal" border icon="mdi-alert-circle" class="mt-2 pa-4"
              density="compact">
              <p class="text-black"> <strong>Delete Account</strong> <br> This action will permanently delete the user
                account, along with all associated
                data, from the
                LeadGen system. Once deleted, there will be no way to restore the user account. If you wish to cancel
                this request,
                you can do so now or choose to cancel within 24 hours.</p>
            </v-alert>
            <v-alert type="error" text="Stripe subscription needs a
              manually action for cancellation through Stripe Dashboard. Go To Stripe Dashboard > Find customer with
              email
              <b>{{ user.email }}</b> which has LeadGen subscription then cancel that subscription."
              v-if="user.plan.stripe_plan_id">
            </v-alert>
            <v-alert v-if="requestAccountAction.deleteMessage.length > 0" :text="requestAccountAction.deleteMessage"
              type="error">
            </v-alert>
            <!-- dialog change Delete Account -->
            <v-dialog rounded="sm" max-width="600">
              <template v-slot:activator="{ props: activatorProps }">
                <v-btn :loading="requestAccountAction.loader"
                  @click="requestAccountAction.toBeDeleted ? cancelUserDelete() : ''"
                  v-bind="!requestAccountAction.toBeDeleted ? activatorProps : ''"
                  :color="requestAccountAction.toBeDeleted ? 'green' : 'primary-red'"
                  :text="requestAccountAction.toBeDeleted ? 'Cancel Deletion' : 'Delete Account'" variant="flat"
                  class="mt-4 font-weight-bold text-none"></v-btn>
              </template>
              <template v-slot:default="{ isActive }">

                <v-card prepend-icon="mdi-update">
                  <template v-slot:title>
                    <strong>Are you sure?</strong>
                  </template>
                  <v-divider></v-divider>
                  <v-card-text class="mb-0">
                    <v-row class="mt-2">
                      <v-col cols="12">
                        <v-alert border type="error" variant="tonal" rounded="md" density="compact" class="mb-6"
                          icon="mdi-information">
                          <p class="text-black mb-0">
                            This action permanently deletes the account and all associated data from the LeadGen system.
                            Once deleted, there will be no way to restore the user account.
                          </p>
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="mb-2 mr-4">
                    <v-btn class="text-none font-weight-bold" color="grey-darken-3" variant="tonal" rounded="md"
                      @click="isActive.value = false">
                      Cancel
                    </v-btn>

                    <v-btn color="primary-red" class="font-weight-bold text-none" variant="flat" text="Delete"
                      @click="requestUserDelete(isActive)"></v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </div>
</template>

<script>
import * as actionTypes from '@/store/action-types'
import { mapGetters } from 'vuex'

export default {
  props: {
    userId: {
      required: true
    }
  },
  data: function () {
    return {
      panel: {
        trackingInfo: 0,
        onboardingDetails: 0,
        basicDetails: 0,
        changePassword: 0
      },
      visible: false,
      confirmVisible: false,
      user: {
        name: '',
        email: '',
        role: '',
        active: 0,
        plan: '',
        password: '',
        confirm_password: '',
        ip: '',
        country: '',
        city: '',
        source: '',
        signup_params: {},
        subscriptions: [],
        twoFactor: 1,
        onboardingData: []
      },
      basicForm: {
        scope: 'basicForm',
        saving: false,
        submitted: false,
        saveErrorMsg: ''
      },
      passwordForm: {
        scope: 'passwordForm',
        saving: false,
        submitted: false,
        saveErrorMsg: ''
      },
      roleForm: {
        scope: 'roleForm',
        saving: false,
        submitted: false,
        saveErrorMsg: ''
      },
      activationForm: {
        scope: 'activationForm',
        saving: false,
        submitted: false,
        saveErrorMsg: ''
      },
      planForm: {
        scope: 'planForm',
        saving: false,
        submitted: false,
        saveErrorMsg: ''
      },
      userFormLoaded: false,
      userFormLoadedError: false,
      requestAccountAction: {
        loader: false,
        toBeDeleted: false,
        deleteAt: '',
        deleteMessage: ''
      }
    }
  },
  mounted: function () {
    this.userFormLoaded = false
    this.userFormLoadedError = false
    this.$store.dispatch(actionTypes.FETCH_USER, {
      userId: this.userId
    })
      .then((response) => {
        let user = response.data.data
        this.user.name = user.name
        this.user.email = user.email
        if (user.roles.length > 0) {
          this.user.role = user.roles[0].name
        }
        this.user.onboardingData = user.onboarding_data
        this.user.active = user.active == 1 ? true : false
        this.user.plan = user.plan.title
        this.user.subscriptions = user.subscriptions
        this.userFormLoaded = true
        this.user.ip = user.ip
        this.user.country = user.country
        this.user.city = user.city
        this.user.source = user.source
        this.user.signup_params = this.formatSignUpParams(JSON.parse(user.signup_params))
        this.user.twoFactor = user.twoFactor.enabled === 1 ? true : false
        this.requestAccountAction.toBeDeleted = user.is_inactive_user && user.is_inactive_user.user_id === user.id
        this.requestAccountAction.deleteAt = user.is_inactive_user ? user.is_inactive_user.delete_at : ''
      }, () => {
        this.userFormLoaded = true
        this.userFormLoadedError = true
      })
  },
  methods: {
    formatSignUpParams: function (signUpParams) {
      for (let propName in signUpParams) {
        if (!signUpParams[propName]) {
          delete signUpParams[propName]
        }
      }
      return signUpParams
    },
    reloadPage: function () {
      window.location.reload()
    },
    onBasicFormSubmit: async function () {
      const { valid } = await this.$refs.basicDetailsForm.validate()
      if (valid) {
        this.basicForm.saving = true
        this.basicForm.submitted = false
        this.basicForm.saveErrorMsg = ''
        this.$store.dispatch(actionTypes.UPDATE_USER_BASICDETAILS, {
          userId: this.userId,
          payload: {
            name: this.user.name,
            email: this.user.email,
            twoFactor: this.user.twoFactor ? 1 : 0
          }
        })
          .then((response) => {
            this.basicForm.saving = false
            this.basicForm.submitted = true
          }, (error) => {
            this.basicForm.saving = false
            this.basicForm.submitted = true
            this.basicForm.saveErrorMsg = error.response.data.meta.error_message
          })
      }
    },
    onPasswordFormSubmit: async function () {
      const { valid } = await this.$refs.changePasswordForm.validate()
      if (valid) {
        this.passwordForm.saving = true
        this.passwordForm.submitted = false
        this.passwordForm.saveErrorMsg = ''
        this.$store.dispatch(actionTypes.UPDATE_USER_PASSWORD, {
          userId: this.userId,
          payload: {
            password: this.user.password
          }
        })
          .then((response) => {
            this.passwordForm.saving = false
            this.passwordForm.submitted = true
          }, () => {
            this.passwordForm.saving = false
            this.passwordForm.submitted = true
            this.passwordForm.saveErrorMsg = 'Unable to update please try again!'
          })
      }
    },
    onRoleFormSubmit: async function () {
      const { valid } = await this.$refs.changeRole.validate()
      if (valid) {
        this.roleForm.saving = true
        this.roleForm.submitted = false
        this.roleForm.saveErrorMsg = ''
        this.$store.dispatch(actionTypes.UPDATE_USER_ROLE, {
          userId: this.userId,
          payload: {
            role: this.user.role
          }
        })
          .then((response) => {
            this.roleForm.saving = false
            this.roleForm.submitted = true
            if (this.user.role === this.rolesMap.ADMIN) {
              this.$router.push({ name: 'users.index' })
            } else {
              this.reloadPage()
            }
          }, () => {
            this.roleForm.saving = false
            this.roleForm.submitted = true
            this.roleForm.saveErrorMsg = 'Unable to update please try again!'
          })
      }
    },
    onActivationFormSubmit: async function () {
      const { valid } = await this.$refs.accountActivation.validate()
      if (valid) {
        this.activationForm.saving = true
        this.activationForm.submitted = false
        this.activationForm.saveErrorMsg = ''
        this.$store.dispatch(actionTypes.UPDATE_USER_ACTIVATION, {
          userId: this.userId,
          payload: {
            active: this.user.active ? 1 : 0
          }
        })
          .then((response) => {
            this.activationForm.saving = false
            this.activationForm.submitted = true
          }, () => {
            this.activationForm.saving = false
            this.activationForm.submitted = true
            this.activationForm.saveErrorMsg = 'Unable to update please try again!'
          })
      }
    },
    onPlanFormSubmit: function () {
      this.$validator.validateAll(this.planForm.scope).then((result) => {
        if (result) {
          this.planForm.saving = true
          this.planForm.submitted = false
          this.planForm.saveErrorMsg = ''
          this.$store.dispatch(actionTypes.CANCEL_USER_SUBSCRIPTION, {
            userId: this.userId
          })
            .then((response) => {
              this.planForm.saving = false
              this.planForm.submitted = true
              this.reloadPage()
            }, () => {
              this.planForm.saving = false
              this.planForm.submitted = true
              this.planForm.saveErrorMsg = 'Unable to update please try again!'
            })
        }
      })
    },
    requestUserDelete: function (isActive) {
      isActive.value = false
      this.requestAccountAction.toBeDeleted = false
      this.requestAccountAction.loader = true
      this.$store.dispatch('user/requestUserDelete', {
        userId: this.userId
      }).then(() => {
        this.requestAccountAction.toBeDeleted = true
      }).catch(err => {
        this.requestAccountAction.toBeDeleted = false
        this.requestAccountAction.deleteMessage = err.response && err.response.data && err.response.data.meta.error_message
          ? err.response.data.meta.error_message : 'Unable to request user deletion'
      })
      this.requestAccountAction.loader = false
    },
    cancelUserDelete: function () {
      this.requestAccountAction.toBeDeleted = true
      this.requestAccountAction.loader = true
      this.$store.dispatch('user/cancelUserDelete', {
        userId: this.userId
      }).then(() => {
        this.requestAccountAction.toBeDeleted = false
      }).catch(err => {
        this.requestAccountAction.deleteMessage = err.response && err.response.data && err.response.data.meta.error_message
          ? err.response.data.meta.error_message : 'Unable to cancel user deletion'
      })
      this.requestAccountAction.loader = false
    }
  },
  computed: {
    ...mapGetters(['rolesMap', 'planTypes']),
    roles: function () {
      return Object.keys(this.rolesMap) || []
    },
    canCancelSubscription: function () {
      for (let s of this.user.subscriptions || []) {
        if (s.ends_at === null) {
          return true
        }
        const today = new Date()
        const endsAt = new Date(s.ends_at)
        if (today < endsAt) {
          return false
        }
        return false
      }
    },
    isSubscriptionCancelled: function () {
      for (let s of this.user.subscriptions || []) {
        const today = new Date()
        const endsAt = new Date(s.ends_at)
        if (today < endsAt) {
          return true
        }
      }
      return false
    }
  }
}
</script>
