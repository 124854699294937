import * as mutationTypes from '@/store/mutation-types'
import * as FormConnectionApi from '@/api/formconnection'
// import Vue from 'vue'

const state = {
  formConnections: []
}

const getters = {
  getFormConnections: function (state) {
    return state.formConnections
  }
}

const actions = {
  fetchFormConnection ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_CONNECTION)
    return FormConnectionApi.fetchFormConnection(data)
  },
  fetchFormConnectionSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_CONNECTION_SUCCESS, data)
  },
  fetchFormConnections ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_CONNECTIONS)
    return FormConnectionApi.fetchFormConnections()
  },
  fetchFormConnectionsSuccess ({state, commit}, data) {
    commit(mutationTypes.FETCH_FORM_CONNECTIONS_SUCCESS, data)
  }
}

const mutations = {
  [mutationTypes.FETCH_FORM_CONNECTION] (state, data) {},
  [mutationTypes.FETCH_FORM_CONNECTION_SUCCESS] (state, data) {},
  [mutationTypes.FETCH_FORM_CONNECTIONS] (state, data) {},
  [mutationTypes.FETCH_FORM_CONNECTIONS_SUCCESS] (state, data) {
    state.formConnections = data || []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
