<template>
  <div class="trial-ended-modal-cpt">
    <ui-modal ref="trialEndedModal" class="trial-ended-modal-cpt__modal" size="large" dismiss-on="close-button"
      remove-close-button>
      <div slot="header" class="header-class">
        <div class="header-logo-left">
          <img src="./../../assets/images/icons/plan-header-one.svg" alt="Leadgen" />
        </div>
        <div class="header-text">
          <p><span>0 of 14 days </span> left on your trial</p>
        </div>
        <div class="header-logo-right">
          <img src="./../../assets/images/icons/plan-header-two.svg" alt="Capterra" />
        </div>
      </div>
      <div class="trial-ended-container">
        <div class="trial-content">
          <div class="trial-left-section">
            <ui-button size="small" color="red" disabled class="trial-ended-button">your trial has ended
            </ui-button>
            <h1>One Step to Complete</h1>
            <p>
              Your LeadGen App trial has ended. To access your account, fill out
              the form to upgrade and you're ready to go. Scale up your lead
              generation today!
            </p>
            <br /><br />
            <div class="trial-plan-detail">
              <div class="plan-type">
                <h3 class="plan-title">Pro Plan</h3>
                <p class="plan-price">$49/month</p>
              </div>
              <div class="plan-detail-list">
                <ul>
                  <li>
                    <span><i class="fas fa-check text-success"></i></span>500 leads/month
                  </li>
                  <li>
                    <span><i class="fas fa-check text-success"></i></span>20
                    Forms
                  </li>
                  <li>
                    <span><i class="fas fa-check text-success"></i></span>Remove
                    LeadGen branding
                  </li>
                  <li>
                    <span><i class="fas fa-check text-success"></i></span>Cancel
                    anytime
                  </li>
                </ul>
              </div>
            </div>
            <br /><br />
            <div class="left-logos">
              <img src="./../../assets/images/icons/plan-footer.svg" alt="Support" />
            </div>
          </div>
          <div class="trial-right-section">
            <div class="subscription-view__main__switch">
              <h5 class="plan-text">Plans</h5>
              <div class="plan-switch">
                <strong>Monthly</strong>
                <ui-switch color="primary" v-model="yearPlan">
                </ui-switch>
                <strong>Yearly</strong>
                <label>(Save 20%)</label>
              </div>
            </div>
            <div class="subscription-view__main__plans">
              <!-- Yearly Plans -->
              <div class="plan-view" v-if="yearPlan">
                <div class="plan" v-for="(yearPlan, index) in yearlyPlans" :key="index">
                  <div class="plan__header">{{ yearPlan.title }}</div>
                  <div class="plan__header__btn">
                    <div class="pro_div" v-if="(yearPlan.publicId === publicIdProYearly)">
                      <!-- Upgrade to Pro Annual when no subscription -->
                      <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                        @click="() => subscribePlan(annualProId)"
                        :loading="subscriptionStatus.planId === annualProId">Upgrade</ui-button>
                    </div>
                    <div class="scale_div" v-if="(yearPlan.publicId === publicIdScaleYearly)">
                      <!-- Upgrade to Scale Annual when no subscription -->
                      <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                        @click="() => subscribePlan(annualScaleId)"
                        :loading="subscriptionStatus.planId === annualScaleId">Upgrade
                      </ui-button>
                    </div>
                  </div>
                  <div class="price-cut">${{ yearPlan.originalPrice }}/month</div>
                  <div class="plan__price__box">
                    <span class="plan__currency">$</span>
                    <span class="plan__price">{{ yearPlan.price }}</span>
                    <span class="plan__period">/month</span>
                  </div>
                  <h3>Billed Yearly</h3>
                  <div class="plan__footer__btn">
                    <div class="pro_div" v-if="(yearPlan.publicId === publicIdProYearly)">
                      <!-- Upgrade to Pro Annual when no subscription -->
                      <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                        @click="() => subscribePlan(annualProId)"
                        :loading="subscriptionStatus.planId === annualProId">Upgrade</ui-button>
                    </div>
                    <div class="scale_div" v-if="(yearPlan.publicId === publicIdScaleYearly)">
                      <!-- Upgrade to Scale Annual when no subscription -->
                      <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                        @click="() => subscribePlan(annualScaleId)"
                        :loading="subscriptionStatus.planId === annualScaleId">Upgrade
                      </ui-button>
                    </div>
                  </div>
                  <div class="plan__features">
                    <!-- <h5>{{ yearPlan.text }}</h5> -->
                    <ul>
                      <li class="plan__feature" v-for="(feature, index) in yearPlan.features" :key="index">
                        <div v-if="Array.isArray(feature)">
                          <ul>
                            <li v-for="nestedFeature in feature" :key="nestedFeature">
                              <div class="excluded-features"><span class="material-icons">close</span>{{ nestedFeature
                                }}</div>
                            </li>
                          </ul>
                        </div>
                        <div class="included-features" v-else><span class="material-icons">check</span>{{ feature }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- Monthly Plans -->
              <div class="plan-view" v-else>
                <div class="plan" v-for="(monthPlan, index) in monthlyPlans" :key="index">
                  <div class="plan__header">{{ monthPlan.title }}</div>
                  <div class="plan__header__btn">
                    <div class="pro_div" v-if="(monthPlan.publicId === publicIdPro)">
                      <!-- Upgrade to Pro when no subscription -->
                      <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                        @click="() => subscribePlan(monthlyProId)"
                        :loading="subscriptionStatus.planId === monthlyProId">Upgrade
                      </ui-button>
                    </div>
                    <div class="scale_div" v-if="(monthPlan.publicId === publicIdScale)">
                      <!-- Upgrade to Pro when no subscription -->
                      <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                        @click="() => subscribePlan(monthlyScaleId)"
                        :loading="subscriptionStatus.planId === monthlyScaleId">Upgrade
                      </ui-button>
                    </div>
                  </div>
                  <div class="plan__price__box">
                    <span class="plan__currency">$</span>
                    <span class="plan__price">{{ monthPlan.price }}</span>
                    <span class="plan__period">/month</span>
                  </div>
                  <h3>Billed Monthly</h3>
                  <div class="plan__footer__btn">
                    <div class="pro_div" v-if="(monthPlan.publicId === publicIdPro)">
                      <!-- Upgrade to Pro when no subscription -->
                      <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                        @click="() => subscribePlan(monthlyProId)"
                        :loading="subscriptionStatus.planId === monthlyProId">Upgrade
                      </ui-button>
                    </div>
                    <div class="scale_div" v-if="(monthPlan.publicId === publicIdScale)">
                      <!-- Upgrade to Scale when no subscription -->
                      <ui-button class="upgrade__plan__btn" iconPosition="right" raised v-if="!hasSubscription"
                        @click="() => subscribePlan(monthlyScaleId)"
                        :loading="subscriptionStatus.planId === monthlyScaleId">Upgrade
                      </ui-button>
                    </div>
                  </div>
                  <!-- <div class="plan__features">
                                        <h5>{{ monthPlan.text }}</h5>
                                        <div class="plan__feature" v-for="(feature, index) in monthPlan.features"
                                            :key="index">
                                            <div>
                                                <span class="material-icons">check</span>{{ feature }}
                                            </div>
                                        </div>
                                    </div> -->
                  <div class="plan__features">
                    <!-- <h5>{{ monthPlan.text }}</h5> -->
                    <ul>
                      <li class="plan__feature" v-for="(feature, index) in monthPlan.features" :key="index">
                        <div v-if="Array.isArray(feature)">
                          <ul>
                            <li v-for="nestedFeature in feature" :key="nestedFeature">
                              <div><span class="material-icons">close</span>{{ nestedFeature }}</div>
                            </li>
                          </ul>
                        </div>
                        <div class="included-features" v-else><span class="material-icons">check</span>{{ feature }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ui-modal>
  </div>
</template>

<script>
import * as actionTypes from '@/store/action-types.js'
import Vue from 'vue'
import config from '@/api/config'
import userMixin from '@/mixins/user'
import helperMixin from '@/mixins/helper'
import environments from '@/shared/constants/environments'
import planPublicIds from '@/shared/constants/planPublicIds'
import { paddleMonthlyPlans, paddleYearlyPlans } from '@/shared/constants/paddlePlans'

export default {
  mixins: [userMixin, helperMixin],
  data: function () {
    return {
      yearPlan: false,
      publicIdPro: planPublicIds.paddlePro,
      publicIdScale: planPublicIds.paddleScale,
      publicIdProYearly: planPublicIds.paddleProAnnual,
      publicIdScaleYearly: planPublicIds.paddleScaleAnnual,
      monthlyPlans: paddleMonthlyPlans,
      yearlyPlans: paddleYearlyPlans,
      subscriptionStatus: {
        interval: null,
        intervalCounter: 0,
        intervalLimit: 10,
        subscribeSuccess: false,
        subscribeFailed: false,
        planId: null
      },
      monthlyProId: null,
      monthlyScaleId: null,
      annualProId: null,
      annualScaleId: null
    }
  },
  mounted: function () {
    this.monthlyProId = config.paddle.planId
    this.monthlyScaleId = config.paddle.scaleId
    this.annualProId = config.paddle.annualProId
    this.annualScaleId = config.paddle.annualScaleId
    window.Paddle.Setup({ vendor: config.paddle.vendorId })
  },
  methods: {
    resetSubscriptionStatus: function () {
      this.subscriptionStatus = {
        interval: null,
        intervalCounter: 0,
        intervalLimit: 10,
        subscribeSuccess: false,
        subscribeFailed: false,
        planId: null
      }
    },
    subscribePlan: function (planId) {
      this.resetSubscriptionStatus()
      let metdata = {
        user_id: this.user.id
      }
      this.subscriptionStatus.planId = planId
      let checkoutConfig = {
        product: planId,
        theme: 'none',
        email: this.user.email,
        passthrough: JSON.stringify(metdata),
        marketingConsent: this.user.subscribe_newsletter,
        successCallback: () => {
          this.pollingSubscriptionStatus(() => {
            this.subscriptionStatus.subscribeSuccess = true
          })
        },
        closeCallback: () => {
          this.resetSubscriptionStatus()
        }
      }

      if (config.paddle.coupon) {
        checkoutConfig.coupon = config.paddle.coupon
      }

      if ([environments.LOCAL, environments.DEV].indexOf(config.environment) >= 0) {
        window.Paddle.Environment.set('sandbox')
      }

      window.Paddle.Checkout.open(checkoutConfig)
    },
    pollingSubscriptionStatus: function (callback = null) {
      this.subscriptionStatus.interval = setInterval(() => {
        if (this.subscriptionStatus.intervalCounter >= this.subscriptionStatus.intervalLimit) {
          this.subscriptionStatus.subscribeSuccess = false
          this.subscriptionStatus.subscribeFailed = true
          this.$parent.subscriptionStatus.subscribeFailed = true
          clearInterval(this.subscriptionStatus.interval)
          return
        }
        this.subscriptionStatus.intervalCounter++
        this.syncLocalUser(() => {
          clearInterval(this.subscriptionStatus.interval)
          if (callback) {
            callback()
          }
        })
      }, 1000)
    },
    syncLocalUser: function (callback) {
      this.$parent.subscriptionStatus.subscribeSuccess = true
      this.$store.dispatch(actionTypes.FETCH_USER, {
        userId: this.user.id
      })
        .then((response) => {
          let user = response.data.data
          if (!user.plan || !user.plan.subscription) {
            return
          }
          this.$store.dispatch(actionTypes.UPDATE_USER_LOCAL, user)
            .then(() => {
              localStorage.setItem('user', JSON.stringify(user))
            })
          this.$refs.trialEndedModal.close()
          callback()
        })
    }
  }
}
</script>
