<template>
  <div :class="{ question: true }" v-bind:id="'question' + questionId" v-bind:data-id="questionId">

    <div class="question-content hoverable">
      <div class="row">
        <div class="col m8 s12 offset-m1">
          <div class="actions" @click="toggle">
            <v-icon v-if="open">mdi-chevron-down</v-icon>
            <v-icon v-else>mdi-chevron-right</v-icon>
          </div>
          <div class="question-panel">
            {{ 'Question ' + (index) + ': ' + (questionTitle || '') }}
          </div>
        </div>
      </div>
      <div :class="{ 'question-setting': true, 'hide': !open }">
        <div class="row">
          <div class="col m10 s12 offset-m1">
            <div class="form-group">
              <div class="question__phishing" v-if="hasPhishingContent">
                <v-alert type="error" variant="tonal" rounded="md" density="compact" icon="mdi-information">
                  <p class="text-black mb-0"> <strong>Warning:</strong> Please note that for security reasons LeadGen
                    Forms should not be used to
                    capture password details of users. We do not tolerate password phishing and will close down accounts
                    that engage in this behavior.
                  </p>
                </v-alert>
              </div>
            </div>

            <!-- QUESTION TYPE FIELD | COMMON FIELD -->
            <v-row>
              <v-col cols="8">
                <v-autocomplete density="comfortable" variant="outlined" label="Question Type"
                  placeholder="Select Question Type" :items="qt" item-title="label" return-object
                  @update:modelValue="updateQuestionType($event)" v-model="questionTypeObj"
                  :rules="$valid([{ rule: 'required' }])"></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-select :disabled="!hasSkin" density="comfortable" variant="outlined" label="Skin"
                  placeholder="Choose skin" :items="questionSkinOptions" item-title="label" return-object
                  v-model="questionSkin"></v-select>
              </v-col>
            </v-row>

            <div v-if="questionType">

              <!-- QUESTION TITLE FIELD & REQUIRED SWITCH & HIDE TITLE SWITCH | COMMON FIELD -->
              <v-row class="mt-n6">
                <v-col cols="8">
                  <v-text-field density="comfortable" variant="outlined" label="Title" placeholder="Ask Your Question"
                    v-model="questionTitle" class="form-control" :name="`question-title-${question.id}`"
                    :rules="$valid([{ rule: 'required' }])"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <div class="q-title-actions d-flex align-center rounded">
                    <v-switch class="pl-4" density="compact" v-model="questionHideTitle" hide-details label="Hide Title"
                      color="secondary-red"></v-switch>
                    <v-divider v-if="hasRequiredField" vertical class="mr-3 ml-3"></v-divider>
                    <v-spacer class="pr-2" v-else></v-spacer>
                    <v-switch class="pr-4" density="compact" v-if="hasRequiredField" v-model="required" hide-details
                      label="Required" color="secondary-red"></v-switch>
                  </div>
                </v-col>
              </v-row>


              <!-- DATE TYPE QUESTION | PLACEHOLDER -->
              <div class="mt-n5" v-if="hasSkin && questionType === questionTypesMap.DATE && hasPlaceholder">
                <!-- 3 input boxes & dropdown-->
                <v-row
                  v-if="questionSkin.id === dateSkinIds.THREE_INPUT_BOXES || questionSkin.id === dateSkinIds.DROPDOWN">
                  <v-col cols="4">
                    <v-text-field density="comfortable" variant="outlined" label="Placeholder Day"
                      :placeholder="placeholderDayText" v-model="placeholderDay"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field density="comfortable" variant="outlined" label="Placeholder Month"
                      :placeholder="placeholderMonthText" v-model="placeholderMonth"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field density="comfortable" variant="outlined" label="Placeholder Year"
                      :placeholder="placeholderYearText" v-model="placeholderYear"></v-text-field>
                  </v-col>
                </v-row>
                <!-- 1 input box -->
                <v-row v-if="questionSkin.id === dateSkinIds.ONE_INPUT_BOX">
                  <v-col cols="4">
                    <v-text-field density="comfortable" variant="outlined" label="Placeholder Text"
                      placeholder="DD / MM / YYYY" v-model="placeholderOneInputText" disabled></v-text-field>
                  </v-col>
                </v-row>
              </div>

              <!-- SINGLE SELECT MULTI SELECT QUESTION SKIN LAYOUT FIELD  && SKIN COLUMN FIELD | COMMON FIELD -->
              <v-row class="mt-n6"
                v-if="questionType === questionTypesMap.MULTIPLE_CHOICE || questionType === questionTypesMap.SINGLE_CHOICE">
                <v-col cols="4" v-if="hasSkinLayout">
                  <v-select density="comfortable" variant="outlined" label="Layout" placeholder="Choose skin layout"
                    :items="questionSkinLayouts" v-model="questionSkin['layout']" item-title="label"></v-select>
                </v-col>
                <v-col cols="4" v-if="hasSkinColumn">
                  <v-select density="comfortable" variant="outlined" label="Columns" placeholder="Choose skin layout"
                    :items="questionSkinColumns" v-model="questionSkin['column']" item-title="label"></v-select>
                </v-col>
              </v-row>


              <!-------------------------------------------
              | SINGLE/MULTI QUESTION TYPE FIELDS SECTION  |
              --------------------------------------------->
              <!-- SINGLE/MULTI QUESTION CHOICE VALUES & PRESELECT SWITCH FIELD -->
              <v-row
                v-if="questionType === questionTypesMap.MULTIPLE_CHOICE || questionType === questionTypesMap.SINGLE_CHOICE">
                <v-col cols="4">
                  <v-switch density="compact" v-model="enableChoicesValues" hide-details label="Show/Hide Choice Values"
                    color="secondary-red"></v-switch>
                </v-col>
                <v-col cols="4">
                  <v-switch density="compact" label="Enable Pre-Selected Choice" v-model="enablePreSelectChoices"
                    hide-details color="secondary-red"></v-switch>
                </v-col>
              </v-row>

              <!-- SINGLE/MULTI QUESTION CHOICES FIELD -->
              <div v-if="hasChoices" class="form-group choices">
                <label for="question-label">List Options</label>
                <v-spacer class="my-4"></v-spacer>
                <div :id="`dragChoiceField${question.id}`">
                  <div class="row choice" v-for="(choice, index) in choices" :key="choice.id" :data-id="choice.id">
                    <!-- LABEL CHOICE -->
                    <div class="choice__label">
                      <v-text-field active density="comfortable" variant="outlined" :label="'Choice ' + (index + 1)"
                        :placeholder="'Choice ' + (index + 1)"
                        @update:modelValue="choiceUpdated($event, choice, 'label')" :value="choice.label"
                        :name="`question-single-multi-${question.id}-choice-${(index + 1)}`" :rules="$valid([
                          { rule: 'required', msg: 'Choice field is required' },
                          {
                            rule: 'expression',
                            value: choice.label && !isDuplicateChoice(choice, question),
                            msg: 'Duplicate choice is not allowed'
                          }
                          ,
                          {
                            rule: 'maxPreSelection',
                            value: enableMinMaxChoices && isExceedingMinChoices(choices, minChoices),
                            msg: ''
                          }
                        ])">
                      </v-text-field>
                    </div>
                    <!-- DESCRIPTION CHOICE -->
                    <div class="choice__description"
                      v-if="hasSkin && (questionSkin.id === 'image' || questionSkin.id === 'icon')">
                      <v-text-field density="comfortable" variant="outlined" :label="'Description ' + (index + 1)"
                        :placeholder="'Choice Description ' + (index + 1)"
                        @update:modelValue="choiceUpdated($event, choice, 'description')" :value="choice.description"
                        :name="`question-single-multi-${question.id}-choice-des-${(index + 1)}`">
                      </v-text-field>
                    </div>
                    <!-- IMAGE CHOICE -->
                    <div class="choice__image" v-if="hasSkin && questionSkin.id === 'image'">
                      <div class="choice__image__uploader" v-if="choice.image">
                        <MediaManager @mm:selected="choiceImageUpdate($event, choice)">
                          <template v-slot:default="{ openModal }">
                            <v-btn small @click="openModal()">
                              <template v-slot:prepend>
                                <img :src="choice.image" :alt="choice.label" class="choice-img" width="26px"
                                  height="26px" />Add Image
                              </template>
                            </v-btn>
                          </template>
                        </MediaManager>
                      </div>
                      <div class="choice__image__uploader" v-else>
                        <!-- <v-btn small @click="choiceImageUpdate(choice)"><v-icon>mdi-image-plus</v-icon>Add Image</v-btn> -->
                        <MediaManager @mm:selected="choiceImageUpdate($event, choice)"></MediaManager>
                      </div>
                    </div>
                    <!-- ICON CHOICE -->
                    <div class="choice__icon" v-if="hasSkin && questionSkin.id === 'icon'">
                      <div class="choice__icon__selected" v-if="choice.icon">
                        <IconLibraryManager @ilm:selected="choiceIconUpdate($event, choice)">
                          <template v-slot:default="{ openModal }">
                            <v-btn small @click="openModal()" class="ml-3">
                              <template v-slot:prepend>
                                <v-icon size="x-large">{{ `fa:${parseIcon(choice.icon).class}` }}</v-icon>
                              </template>
                              <label>Select Icon</label>
                            </v-btn>
                          </template>
                        </IconLibraryManager>
                      </div>
                      <div class="choice__icon__select" v-else>
                        <IconLibraryManager @ilm:selected="choiceIconUpdate($event, choice)"></IconLibraryManager>
                      </div>
                    </div>

                    <!-- CHOICE VALUE FOR QUIZ -->
                    <div class="choice__value" v-if="enableChoicesValues">
                      <v-text-field density="comfortable" variant="outlined" :label="'Choice Value ' + (index + 1)"
                        :placeholder="'Value ' + (index + 1)" @update:modelValue="choiceValueUpdated($event, index)"
                        :name="`question-single-multi-${question.id}-choice-value-${(index + 1)}`"
                        :rules="$valid([{ rule: 'required' }, { rule: 'number', msg: 'Choice value must be numeric.' }])">
                      </v-text-field>
                    </div>

                    <!-- Pre select choice  -->
                    <div v-if="enablePreSelectChoices && choice.type !== otherChoice.OTHER" class="pt-1 pl-2 mb-10">
                      <v-checkbox-btn hide-details color="secondary-red"
                        @update:modelValue="choiceUpdated($event, choice, 'selected')" :trueValue="true"
                        v-model="choice.selected"></v-checkbox-btn>
                    </div>

                    <!-- CHOICE DELETE ACTION -->
                    <div class="choice__delete">
                      <v-icon color="secondary-red" @click="setDeleteChoice(index)"
                        v-if="choices.length > 1 && choice.type !== otherChoice.OTHER" title="Delete Choice"
                        class="mb-12" size="small">mdi-delete</v-icon>

                      <v-icon color="grey-darken-4" v-if="choice.type !== otherChoice.OTHER" title="Drag Choice"
                        class="mb-12 glyphicon-move" size="x-small">mdi-cursor-move</v-icon>
                    </div>
                  </div>
                </div>
                <span class="error-text" v-if="enablePreSelectChoices && isExceedingMinChoices(choices, minChoices)">
                  Pre selected choices are greater than min choices. </span>

                <!-- ADD NEW CHOICE ACTION -->
                <div class="row add-choice-wrap">
                  <br />
                  <v-btn rounded="md" color="light-blue" variant="flat" prepend-icon="mdi-plus" class="text-none mr-2"
                    @click="addChoice()"> Add
                    Choice</v-btn>
                  <v-btn rounded="md" color="tertiary-blue" variant="flat" prepend-icon="mdi-plus-box-multiple"
                    class="text-none ml-1" @click="openModal('bulkUploadOptionsModal')">Bulk Upload
                    Choices
                  </v-btn>
                </div>
              </div>

              <!---------------------------------------
              | ADDRESS QUESTION TYPE FIELDS SECTION  |
              ----------------------------------------->
              <div class="form-group address_fields" v-if="questionType === questionTypesMap.ADDRESS">
                <div class="address_fields__body">
                  <v-row v-if="hasSkin && questionSkin.id === addressSkinIds.GOOGLE_AUTOCOMPLETE">
                    <v-col cols="4">
                      <v-select variant="outlined" density="comfortable" label="API Key Source"
                        :items="addressQuestionApiKeySourcesOptions" item-title="label"
                        @update:modelValue="updateAddressAutocompleteApiKeySource($event)"></v-select>
                    </v-col>
                    <v-col cols="4"
                      v-if="addressAutocompleteApiKeySourceValue && addressAutocompleteApiKeySourceValue.value === addressQuestionApiKeySources.GLOBAL_API_KEY">
                      <v-select variant="outlined" density="comfortable" label="Choose Google API Key"
                        :items="addressAutocompleteApiKeys" :item-title="label"
                        @update:modelValue="updateAddressAutocompleteApiKey($event, addressQuestionApiKeySources.GLOBAL_API_KEY)"></v-select>
                    </v-col>
                    <v-col cols="4" v-else>
                      <v-text-field variant="outlined" density="comfortable" label="Enter Your Google API Key"
                        :value="addressAutocompleteApiKeyValue"
                        @update:modelValue="updateAddressAutocompleteApiKey($event, addressQuestionApiKeySources.CUSTOM)"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-select variant="outlined" density="comfortable" label="Auto Complete Mode"
                        :items="addressAutocompleteModesOptions" item-title="label"
                        @update:modelValue="updateAddressAutocompleteMode($event)"></v-select>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="addressAutoCompleteMode && (addressAutoCompleteMode.id === addressAutocompleteModeIds.SEARCH)">
                    <v-col cols="4">
                      <v-switch color="secondary-red" label="Make Fields Editable" hide-details
                        v-model="addressAutoCompleteFieldsEdit">
                      </v-switch>
                    </v-col>
                  </v-row>

                  <div class="address_fields__body__default-skin"
                    v-if="(hasSkin && questionSkin.id === addressSkinIds.GOOGLE_AUTOCOMPLETE ? addressAutoCompleteFieldsEdit || (addressAutoCompleteMode && (addressAutoCompleteMode.id === addressAutocompleteModeIds.MANUAL)) : true)">
                    <br>
                    <label for="question-label">Address Field Options</label>
                    <br><br>
                    <div class="row address_field">
                      <div class="address_field__item">
                        <label for="question-label">Required</label>
                      </div>
                      <div class="address_field__item address_label">
                        <label for="question-label">Address Field</label>
                      </div>
                      <div class="address_field__item">
                        <label for="question-label">Placeholder</label>
                      </div>
                    </div>
                    <div :id="`dragAddressField${question.id}`">
                      <div class="row address_field" v-for="addressField in orderedAddressField(addressFields || [])"
                        :key="addressField.id" :data-id="addressField.id">
                        <br>
                        <div class="address_field__item">
                          <v-checkbox
                            @update:modelValue="updateAddressFieldItem($event, addressField, 'required')"></v-checkbox>
                        </div>
                        <div class="address_field__item">
                          <v-text-field variant="outlined" :value="addressField.label_value"
                            :placeholder="addressField.placeholder"
                            @update:modelValue="updateAddressFieldItem($event, addressField, 'label')"></v-text-field>
                        </div>
                        <div class="address_field__item">
                          <v-text-field variant="outlined" :value="addressField.placeholder_value"
                            :placeholder="addressField.placeholder"
                            @update:modelValue="updateAddressFieldItem($event, addressField, 'placeholder')"></v-text-field>
                        </div>
                        <div class="address_field__order" v-if="addressFields.length > 1">
                          <img src="./../assets/images/icons/drag.svg" class="drag-btn glyphicon-move" height="16px"
                            width="16px" alt="Leadgen">
                        </div>
                      </div>
                    </div>
                    <div class="row add-choice-wrap" v-if="addressFields.length <= 7">
                      <br />
                      <v-btn rounded="md" color="light-blue" variant="flat" prepend-icon="mdi-plus" class="text-none"
                        @click="questionShowFields = true">
                        Add Field</v-btn>
                    </div>
                    <br>
                    <div v-if="questionShowFields">
                      <v-select multiple chips density="comfortable" variant="outlined" placeholder="Select fields"
                        label="Enable/Disable Address Fields" :items="addressFieldsArray" return-object
                        item-title="label" v-model="addressFields" color="secondary-red"></v-select>
                    </div>
                  </div>
                </div>
              </div>

              <!---------------------------------------
              | GDPR QUESTION TYPE FIELDS SECTION      |
              ----------------------------------------->
              <div class="form-group" v-if="questionType === questionTypesMap.GDPR">
                <div v-if="question.options && question.options.choices">
                  <v-expansion-panels v-model="gdprPanel" elevation="0" class="py-2 px-0" ripple
                    v-for="(choice, index) in question.options.choices" :key="index">
                    <v-expansion-panel>
                      <v-expansion-panel-title :min-height="0" color="blue-lighten-5">
                        <h6 class="d-flex justify-left mb-0">
                          {{ 'Option ' + (index + 1) }}
                        </h6>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text class="border-thin border-t-0">
                        <div>
                          <quill-editor v-model:content="question.options.choices[index].label" contentType="html"
                            placeholder="Inset text here..." :options="gdprEditorOption"
                            @update:modelValue="updateGDPRChoice($event, choice, 'label')" />

                          <div class="d-flex justify-space-between gc-16">
                            <v-checkbox v-model="question.options.choices[index].required"
                              title="Make this option required" label="Required"
                              @update:modelValue="updateGDPRChoice($event, choice, 'required')" color="secondary-red"
                              hide-details></v-checkbox>

                            <v-icon v-if="question.options.choices.length > 1" class="mt-4" color="secondary-red"
                              title="Delete this option" @click="deleteGDPRChoice(choice.id)">mdi-delete</v-icon>
                          </div>
                        </div>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-btn rounded="md" variant="flat" color="light-blue" prepend-icon="mdi-plus" class="text-none"
                    @click="addGDPRChoice()">
                    Add Option</v-btn>
                </div>
              </div>

              <!------------------------------
              | LOGIC JUMP FIELDS SECTION        |
              ------------------------------->
              <div class="form-group logic-jump"
                v-if="hasChoices && questionTypesMap.GDPR !== questionType && steps.length > 1">
                <form-question-jump :question-id="questionId" :question-type="questionType"></form-question-jump>
              </div>

              <!-- SINGLE/MULTI QUESTION CHOICES RANDOM ORDER SWITCH FIELD & OTHER OPTION FIELD-->
              <v-row
                v-if="questionType === questionTypesMap.MULTIPLE_CHOICE || questionType === questionTypesMap.SINGLE_CHOICE">
                <v-col cols="4">
                  <v-switch v-model="randomChoiceOrder" label="Random Choice Order" hide-details
                    color="secondary-red"></v-switch>
                </v-col>
                <v-col cols="4">
                  <v-switch v-model="otherOption" @change="toggleOtherOption()" label="Add other option" hide-details
                    color="secondary-red"></v-switch>
                </v-col>
              </v-row>

              <!------------------------------
              | RANGE TYPE QUESTION       |
              ------------------------------->
              <div v-if="questionType === questionTypesMap.RANGE">
                <!-- slider & range skin -->
                <v-row
                  v-if="questionSkin.id === rangeSkinIds.SLIDER_SCALE || questionSkin.id === rangeSkinIds.RANGE_SCALE">
                  <v-col cols="4">
                    <v-text-field density="comfortable" variant="outlined" label="Min Value" active name="minScale"
                      :value="question.rangeFields.minScaleValue" class="form-control"
                      @update:modelValue="updateRangeField($event, 'minScaleValue')"
                      :placeholder="question.rangeFields.maxScaleValue ? (`0 to ${question.rangeFields.maxScaleValue}`) : '0 to 9999999'"
                      :maxlength="12" enforce-maxlength
                      :rules="$valid([{ rule: 'required' }, { rule: 'number' }, { rule: 'maxLength', max: '12' }])"></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field density="comfortable" variant="outlined" label="Max Value" active name="maxScale"
                      :value="question.rangeFields.maxScaleValue"
                      @update:modelValue="updateRangeField($event, 'maxScaleValue')" class="form-control"
                      :placeholder="question.rangeFields.minScaleValue ? (`${question.rangeFields.minScaleValue} to 9999999`) : '1 to 9999999'"
                      :maxlength="12" enforce-maxlength
                      :rules="$valid([{ rule: 'required' }, { rule: 'number' }, { rule: 'maxLength', max: '12' }])"></v-text-field>
                  </v-col>
                </v-row>
                <!-- likert scale -->
                <v-row v-if="questionSkin.id === rangeSkinIds.LIKERT_SCALE">
                  <v-col cols="4">
                    <v-text-field density="comfortable" variant="outlined" active label="Max Value"
                      :value="question.rangeFields.maxScaleLimit"
                      @update:modelValue="updateRangeField($event, 'maxScaleLimit')" name="maxScaleLimit"
                      :rules="$valid([{ rule: 'required' }, { rule: 'number' }, { rule: 'range', min: '3', max: '10' }])">
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>


              <!-- QUESTION ADVANCED SETTINGS | COMMON FIELD -->
              <div>
                <div class="advance-setting mb-6" @click="isOpenA = !isOpenA">
                  Advanced Settings
                  <div class="actions">
                    <v-icon v-if="isOpenA">mdi-chevron-down</v-icon>
                    <v-icon v-else>mdi-chevron-right</v-icon>
                  </div>
                </div>
                <div v-show="isOpenA" class="list-reset w-48 p-4 bg-grey-light">

                  <!-----------------------------------
                  | DATE QUESTION TYPE FIELD SECTION | DATE RANGE - PICKER
                  ------------------------------------>
                  <v-row v-if="questionType === questionTypesMap.DATE && questionSkin.id === dateSkinIds.DATE_PICKER">
                    <!-- Col for toggle -->
                    <v-col cols="3">
                      <div class="q-title-actions d-flex align-center rounded">
                        <v-switch class="pl-5" density="compact" v-model="enableMinMax" label="Date Range" hide-details
                          color="secondary-red"></v-switch>
                        <!-- <v-divider vertical class=''></v-divider>
                        <v-switch class="" density="compact" v-model="autoIncrement" label="Current Date"
                          hide-details color="secondary-red"></v-switch> -->
                      </div>
                    </v-col>
                    <!-- Col for inputs -->
                    <v-col cols="3" v-if="question.enableMinMax">
                      <fieldset style="border: 1px solid #a9a9a9;" class="py-0 mt-n2 rounded">
                        <legend class="px-1" style="font-size: 12px; color:grey;">Start Date</legend>
                        <v-switch class="pl-5" density="compact" v-model="autoIncrement" label="Current Date"
                          hide-details color="secondary-red"></v-switch>
                      </fieldset>
                      <!-- <div class="q-title-actions d-flex align-center rounded">
                        <v-switch class="pl-5" density="compact" v-model="autoIncrement" label="Current Date"
                        hide-details color="secondary-red"></v-switch>
                      </div> -->
                    </v-col>
                    <v-col cols="3" v-if="question.enableMinMax && !question.autoIncrement">
                      <v-date-input v-model="minDate" @update:modelValue="updateMinDate($event)" label="Start Date"
                        :placeholder="'Set Start Date'" density="comfortable" variant="outlined" prepend-icon=""
                        prepend-inner-icon="$calendar">
                      </v-date-input>
                    </v-col>

                    <v-col cols="3" v-show="question.enableMinMax">
                      <v-date-input v-model="maxDate" @update:modelValue="updateMaxDate($event)" label="End Date"
                        :placeholder="'Set End Date'" density="comfortable" variant="outlined" prepend-icon=""
                        prepend-inner-icon="$calendar"></v-date-input>
                    </v-col>
                  </v-row>

                  <!-----------------------------------
                  | PHONE NUMBER QUESTION TYPE FIELD SECTION | DEFAULT COUNTRY CODE
                  ------------------------------------>
                  <v-row class="align-center" v-if="questionType === questionTypesMap.PHONE_NUMBER">
                    <!-- Col for toggle -->
                    <v-col cols="4">
                      <v-switch v-model="enableDefaultCode" label="Default country code" hide-details
                        color="secondary-red"></v-switch>
                    </v-col>
                    <!-- Col for inputs -->
                    <v-col cols="4" v-if="question.enableDefaultCode">
                      <input class="default-code-input" type="tel" :id="`question-default-code-${question.id}`"
                        :name="`question-default-code-${question.id}`" :value="defaultCountryCode"
                        @countrychange="updateDefaultCountryCode()">
                    </v-col>
                  </v-row>

                  <!-----------------------------------
                  |RANGE QUESTION TYPE FIELD SECTION | TOGGLES
                  ------------------------------------>

                  <!-- toggle & input for step count & unit values -->
                  <!-- Row for toggles -->
                  <v-row
                    v-if="questionType === questionTypesMap.RANGE && (questionSkin.id === rangeSkinIds.SLIDER_SCALE || questionSkin.id === rangeSkinIds.RANGE_SCALE)">
                    <v-col cols="4">
                      <v-switch v-model="enableUnitValues" label="Enable/Disable Unit Values" hide-details
                        color="secondary-red"></v-switch>
                    </v-col>
                    <v-col cols="4">
                      <v-switch v-model="enableStepCount" label="Step Count" hide-details
                        color="secondary-red"></v-switch>
                    </v-col>
                  </v-row>
                  <!-- Row for inputs -->
                  <v-row class="mb-n8"
                    v-if="questionType === questionTypesMap.RANGE && (questionSkin.id === rangeSkinIds.SLIDER_SCALE || questionSkin.id === rangeSkinIds.RANGE_SCALE)">
                    <v-col cols="4" v-if="enableUnitValues">
                      <v-text-field density="comfortable" variant="outlined" label="Unit" class="form-control"
                        type="text" :value="question.rangeFields.unit" placeholder="$, €, kg"
                        @update:modelValue="updateRangeField($event, 'unit')"></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="enableUnitValues">
                      <v-select density="comfortable" variant="outlined" label="Unit Alignment" :items="unitAlignments"
                        item-title="label" @update:modelValue="updateRangeField($event, 'unitAlignment')"
                        class="form-control"></v-select>
                    </v-col>
                    <v-col cols="4" v-if="enableStepCount">
                      <v-text-field density="comfortable" active variant="outlined" label="Step Count"
                        class="form-control" :value="question.rangeFields.stepCount" placeholder="1"
                        @update:modelValue="updateRangeField($event, 'stepCount')"></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- toggle & input for orientation scale -->
                  <!-- Row for toggle & inputs -->
                  <v-row class="mb-n10"
                    v-if="questionType === questionTypesMap.RANGE && (questionSkin.id === rangeSkinIds.LIKERT_SCALE)">
                    <v-col cols="4">
                      <v-switch v-model="showHideOrientationScale" label="Show/Hide Orientation Scale" hide-details
                        color="secondary-red"></v-switch>
                    </v-col>
                    <v-col cols="8" class="d-flex" v-if="question.showHideOrientationScale">
                      <v-col cols="6">
                        <v-text-field active density="comfortable" variant="outlined" label="Lower End Scale Text"
                          class="form-control" :value="question.rangeFields.lowerEndScaleText"
                          @update:modelValue="updateRangeField($event, 'lowerEndScaleText')"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field active density="comfortable" variant="outlined" label="Higher End Scale Text"
                          class="form-control" :value="question.rangeFields.higherEndScaleText"
                          @update:modelValue="updateRangeField($event, 'higherEndScaleText')"></v-text-field>
                      </v-col>
                    </v-col>
                  </v-row>

                  <!-- toggle & input for orientation scale -->
                  <!-- Row for toggle -->
                  <v-row
                    v-if="questionType === questionTypesMap.RANGE && (questionSkin.id === rangeSkinIds.LIKERT_SMILEYS_SCALE)">
                    <v-col cols="4">
                      <v-switch v-model="enableCustomText" label="Set Custom Text for Smileys" hide-details
                        color="secondary-red"></v-switch>
                    </v-col>
                  </v-row>
                  <!-- Row for inputs -->
                  <v-row class="mb-n8"
                    v-if="question.enableCustomText && questionType === questionTypesMap.RANGE && (questionSkin.id === rangeSkinIds.LIKERT_SMILEYS_SCALE)">
                    <v-col cols="4">
                      <v-text-field density="comfortable" active variant="outlined"
                        :label="question.rangeFields.veryUnsatisfied" class="form-control" name="veryUnsatisfied"
                        prepend-inner-icon="mdi-emoticon-dead-outline" :value="question.rangeFields.veryUnsatisfied"
                        @update:modelValue="updateRangeField($event, 'veryUnsatisfied')"
                        :rules="$valid([{ rule: 'required' }])"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field density="comfortable" active variant="outlined"
                        :label="question.rangeFields.unsatisfied" class="form-control" name="unsatisfied"
                        prepend-inner-icon="mdi-emoticon-sad-outline" :value="question.rangeFields.unsatisfied"
                        @update:modelValue="updateRangeField($event, 'unsatisfied')"
                        :rules="$valid([{ rule: 'required' }])"></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field density="comfortable" active variant="outlined"
                        :label="question.rangeFields.neutral" class="form-control" name="neutral"
                        prepend-inner-icon="mdi-emoticon-neutral-outline" :value="question.rangeFields.neutral"
                        @update:modelValue="updateRangeField($event, 'neutral')"
                        :rules="$valid([{ rule: 'required' }])"></v-text-field>
                    </v-col>
                    <v-col class="mt-n6 mb-n3" cols="4">
                      <v-text-field density="comfortable" active variant="outlined"
                        :label="question.rangeFields.satisfied" class="form-control" name="satisfied"
                        prepend-inner-icon="mdi-emoticon-happy-outline" :value="question.rangeFields.satisfied"
                        @update:modelValue="updateRangeField($event, 'satisfied')"
                        :rules="$valid([{ rule: 'required' }])"></v-text-field>
                    </v-col>
                    <v-col class="mt-n6 mb-n3" cols="4">
                      <v-text-field density="comfortable" active variant="outlined"
                        :label="question.rangeFields.verySatisfied" class="form-control" name="verySatisfied"
                        prepend-inner-icon="mdi-emoticon-excited-outline" :value="question.rangeFields.verySatisfied"
                        @update:modelValue="updateRangeField($event, 'verySatisfied')"
                        :rules="$valid([{ rule: 'required' }])"></v-text-field>
                    </v-col>
                  </v-row>

                  <!-----------------------------------
                  |NUMBER QUESTION TYPE FIELD SECTION | MIN MAX
                  ------------------------------------>
                  <v-row class="mb-n8" v-if="questionType === questionTypesMap.NUMBER">
                    <!-- Col for toggle -->
                    <v-col cols="4">
                      <v-switch v-model="enableMinMaxLimit" label="Set Min/Max Number" hide-details
                        color="secondary-red"></v-switch>
                    </v-col>
                    <!-- Col for inputs -->
                    <v-col cols="4" v-if="question.enableMinMaxLimit">
                      <v-text-field density="comfortable" variant="outlined" label="Min Number" :value="minNumber"
                        class="form-control" @update:modelValue="updateMinNumber"
                        :placeholder="this.maxNumber ? (`0 to ${this.maxNumber}`) : '0 to 9999999'" name="minNumber"
                        :maxlength="12" enforce-maxlength
                        :rules="$valid([{ rule: 'required' }, { rule: 'number' }, { rule: 'maxLength', max: '12' }])"></v-text-field>
                    </v-col>
                    <v-col cols="4" v-if="question.enableMinMaxLimit">
                      <v-text-field density="comfortable" variant="outlined" label="Max Number" :value="maxNumber"
                        @update:modelValue="updateMaxNumber" class="form-control"
                        :placeholder="this.minNumber ? (`${this.minNumber} to 9999999`) : '1 to 9999999'"
                        name="maxNumber" :maxlength="12" enforce-maxlength
                        :rules="$valid([{ rule: 'required' }, { rule: 'number' }, { rule: 'maxLength', max: '12' }])"></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- QUESTION MULTIPLE CHOICE MIN/MAX -->
                  <v-row v-if="hasRequiredField && questionType === questionTypesMap.MULTIPLE_CHOICE">
                    <v-col cols="4">
                      <v-switch v-if="required" v-model="enableMinMaxChoices" label="Min/Max Choice Selections"
                        hide-details color="secondary-red"></v-switch></v-col>
                    <v-col cols="4">
                      <v-select hide-details v-show="enableMinMaxChoices && required" density="comfortable"
                        variant="outlined" label="Min Choice" :items="minMaxChoiceOptions" v-model="question.minChoices"
                        @update:modelValue="updateMinChoices($event)"></v-select></v-col>
                    <v-col cols="4">
                      <v-select hide-details v-show="enableMinMaxChoices && required" density="comfortable"
                        variant="outlined" label="Max Choice" :items="minMaxChoiceOptions" item-title="label"
                        v-model="question.maxChoices" @update:modelValue="updateMaxChoices($event)"></v-select>
                    </v-col>
                  </v-row>

                  <!-- QUESTION DESCRIPTION FIELD | COMMON FIELD -->
                  <v-row>
                    <v-col cols="12">
                      <v-textarea density="comfortable" variant="outlined" label="Description" id="question-description"
                        class="form-control" placeholder="Explain Your Question" rows="3" no-resize hide-details
                        v-model="questionDescription"></v-textarea>
                    </v-col>
                  </v-row>

                  <!-- QUESTION PLACEHOLDER & FIELD NAME  & SINGLE/MULTI QUESTION CHOICE ALIGNMENT FIELD | COMMON FIELD -->
                  <v-row>
                    <!-- QUESTION PLACEHOLDER FIELD | COMMON FIELD -->
                    <v-col cols="6" v-if="hasPlaceholder && questionType !== questionTypesMap.DATE">
                      <v-text-field density="comfortable" variant="outlined" label="Placeholder Text"
                        placeholder="Set placeholder text" id="question-placeholder" class="form-control" type="text"
                        v-bind:placeholder="placeholderText" v-model="placeholder"></v-text-field>
                    </v-col>
                    <!-- QUESTION FIELD NAME FIELD | COMMON FIELD -->
                    <v-col cols="6">
                      <v-text-field density="comfortable" variant="outlined" label="Field Name (used for
                        webhooks)" placeholder="Enter form field name" v-model="questionFieldName"
                        :name="`question-name-${question.id}`" disabled></v-text-field>
                      <!-- <span class="error-text"
                      v-if="errors.firstByRule(`${formScope}.question-name-${question.id}`, 'excluded', formScope)">
                      Duplicate field names are not allowed.
                    </span>
                    <span v-else-if="errors.has(`${formScope}.question-name-${question.id}`)" class="error-text">
                      {{ errors.first(`${formScope}.question-name-${question.id}`) }}
                    </span> -->
                    </v-col>
                    <!-- SINGLE/MULTI QUESTION CHOICE ALIGNMENT FIELD -->
                    <v-col cols="6" v-if="hasChoices && hasSkin && questionSkin.id !== 'dropdown'">
                      <v-select density="comfortable" variant="outlined" label="Alignment" :items="choiceAlignments"
                        item-title="label" v-model="questionSkin['alignment']"></v-select>
                    </v-col>
                  </v-row>

                  <!-- EMAIL QUESTION REPLY TO and ALLOW/RESTRICT EMAILS -->
                  <div v-if="questionType === questionTypesMap.EMAIL_ADDRESS">
                    <!-- Row for toggle -->
                    <div class="form-group d-block mb-4">
                      <v-switch v-model="question.replyTo" @update:modelValue="updateEmailAddressReplyTo"
                        label="Use this email address as Reply-To address in email notifications" hide-details
                        color="secondary-red"></v-switch>
                      <v-switch v-model="question.restrictEmail" @update:modelValue="updateRestrictEmailAddress"
                        label="Define allowed/restricted email format responses" hide-details
                        color="secondary-red"></v-switch>
                    </div>

                    <!-- Row for inputs -->
                    <div class="form-group d-block mb-4" v-if="question.restrictEmail">
                      <div v-for="(field, index) in restrictEmailFields" :key="field.id" :data-id="field.id"
                        class="d-flex align-items-center ma-0 pa-0 mb-n5">
                        <v-text-field density="comfortable" variant="outlined" label="Domain"
                          @update:model-value="restrictEmailFieldValueUpdated($event, field, 'email')"
                          placeholder="Type email format e.g gmail.com" :value="field.email" prepend-inner-icon="mdi-at"
                          class="flex-grow-1 mr-2"
                          :name="`restrict-email-field-${(index + 1)}-questionId-${question.id}`" :rules="$valid([
                            { rule: 'required' },
                            { rule: 'regex', value: '^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\\.[a-zA-Z]{2,})+$', msg: 'Incorrect domain format' },
                            {
                              rule: 'expression',
                              value: field.email && !isSameDomain(field, question),
                              msg: 'Duplicate domain is not allowed'
                            }])"></v-text-field>

                        <div class="d-flex gc-10">
                          <v-radio-group v-model="field.allow" inline dense>
                            <v-radio color="secondary-red" hide-details :value="1" label="Allow"></v-radio>
                            <v-radio color="secondary-red" hide-details :value="0" label="Deny"></v-radio>
                          </v-radio-group>

                          <v-icon v-if="restrictEmailFields.length > 1" color="secondary-red"
                            @click="deleteRestrictEmailField(index)" class="mt-2">
                            mdi-delete
                          </v-icon>
                        </div>
                      </div>

                      <v-btn rounded="md" color="secondary-red" variant="text" prepend-icon="mdi-plus"
                        class="text-none mt-0" @click="addField">
                        Add new format
                      </v-btn>
                    </div>
                  </div>


                  <div class="form-group question-required" v-if="questionType === questionTypesMap.MULTIPLE_CHOICE">
                    <span v-if="parseInt(minChoices) > parseInt(maxChoices)" class="red-text err-msg">Max Choices
                      should not be less than Min Choices</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <!-- Delete choice modal-->
    <v-dialog v-model="showDeleteQuestionChoiceModal" ref="deleteQuestionChoiceModal"
      @close="closeModal('deleteQuestionChoiceModal')" rounded="sm" max-width="500">
      <v-card class="pa-4 bg-white overflow-visible position-relative border-t-xl border-error border-opacity-100">
        <!-- Icon section (overlapping avatar) -->
        <div class=" text-center p-4 rounded inline-flex mt-n14 mb-2">
          <v-avatar size="70" color="secondary-red">
            <v-icon size="36" color="white">mdi-delete</v-icon>
          </v-avatar>
        </div>
        <!-- Delete Text -->
        <v-card-text class="text-center">
          <h3 class="font-weight-bold">Delete Choice</h3>
          <v-alert v-if="inLogicJumps" border type="warning" variant="tonal" rounded="md" density="compact" class="mb-6"
            icon="mdi-information">
            <p class="text-black text-left mb-0">This choice is currently used in Logic Jump. You have to delete any
              reference to this choice from Logic Jump before deleting it.</p>
          </v-alert>
          <v-alert v-else border type="error" variant="tonal" rounded="md" density="compact" class="mb-6"
            icon="mdi-information">
            <p class="text-black text-left mb-0">You are about to delete this choice. Are you sure?</p>
          </v-alert>
        </v-card-text>
        <!-- Buttons -->
        <v-card-actions class="d-flex justify-center pt-0">
          <v-btn rounded="md" color="grey-lighten-3" class="font-weight-bold text-capitalize w-25 mr-1" variant="flat"
            @click="closeModal('deleteQuestionChoiceModal')">
            Cancel
          </v-btn>
          <v-btn rounded="md" color="primary-red" class="font-weight-bold text-capitalize w-25 ml-1" variant="flat"
            @click="deleteChoice()">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Bulk upload choices modal -->
    <v-dialog v-model="showBulkUploadOptionsModal" ref="bulkUploadOptionsModal"
      @close="closeModal('bulkUploadOptionsModal')" rounded="sm" max-width="500">
      <v-card class="pa-4 bg-white overflow-visible position-relative border-t-xl border-error border-opacity-100">
        <!-- Icon section (overlapping avatar) -->
        <div class="text-center p-4 rounded inline-flex mt-n14 mb-2">
          <v-avatar size="70" color="secondary-red">
            <v-icon size="36" color="white">mdi-upload-circle</v-icon>
          </v-avatar>
        </div>
        <!-- Title and content -->
        <v-card-title class="text-center pt-8">
          <h5 class="font-weight-bold">How Would You Like To Upload Choices?</h5>
        </v-card-title>
        <!-- Buttons -->
        <v-card-actions class="d-flex justify-center pt-1">
          <v-btn rounded="md" color="grey-lighten-3" class="font-weight-bold text-capitalize w-33 mr-1" variant="flat"
            @click="showBulkUploadModal('typedChoicesModal')">
            Type Choices
          </v-btn>
          <v-btn rounded="md" color="primary-red" class="font-weight-bold text-capitalize w-33 ml-1" variant="flat"
            @click="showBulkUploadModal('uploadCsvModal')">
            Upload CSV File
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Type Choices modal -->
    <v-dialog v-model="showTypedChoicesModal" ref="typedChoicesModal" @close="closeModal('typedChoicesModal')"
      rounded="sm" max-width="620">
      <v-card class="pa-4 bg-white overflow-visible position-relative border-t-xl border-error border-opacity-100">
        <!-- Icon section (overlapping avatar) -->
        <div class="text-center p-4 rounded inline-flex mt-n14 mb-2">
          <v-avatar size="70" color="secondary-red">
            <v-icon size="36" color="white">mdi-keyboard</v-icon>
          </v-avatar>
        </div>
        <v-card-text class="text-center">
          <h5 class="font-weight-bold">Type Your Choices Here</h5>
          <p class="text-grey-darken-3 mb-0">Write or paste your choices below. Each choice must be on a separate
            line.
          </p>
          <v-textarea density="comfortable" variant="outlined" name="typed-choices" rounded="md" rows="11" no-resize
            counter :placeholder="'Choice 1\nChoice 2\nChoice 3'"
            :rules="$valid([{ rule: 'required' }, { rule: 'maxLength', max: '400' }])" v-model="typedChoicesData">
          </v-textarea>
        </v-card-text>
        <!-- Buttons -->
        <v-card-actions class="d-flex justify-center pt-1">
          <v-btn rounded="md" color="grey-lighten-3" class="font-weight-bold text-capitalize w-25 mr-1" variant="flat"
            @click="closeModal('typedChoicesModal')">
            discard
          </v-btn>
          <v-btn rounded="md" color="primary-red" class="font-weight-bold text-capitalize w-25 ml-1" variant="flat"
            @click="uploadTypedChoices()">
            add choices
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Upload csv modal -->
    <v-dialog v-model="showUploadCsvModal" id="uploadCsvModal" ref="uploadCsvModal"
      @close="closeModal('uploadCsvModal')" rounded="sm" max-width="620">
      <v-card class="pa-4 bg-white overflow-visible position-relative border-t-xl border-error border-opacity-100">
        <!-- Icon section (overlapping avatar) -->
        <div class="text-center p-4 rounded inline-flex mt-n14 mb-2">
          <v-avatar size="70" color="secondary-red">
            <v-icon size="36" color="white">mdi-cloud-upload</v-icon>
          </v-avatar>
        </div>
        <v-card-text class="text-center">
          <h5 class="font-weight-bold">Upload Your csv File</h5>
          <p class="text-grey-darken-3 mb-0">Add choices in column A of a csv file.</p>
          <p class="text-grey-darken-3 mt-0 pt-0">Optional: Add numeric choice values in column B next to the
            choices</p>
          <v-row justify="center" v-if="loadCsvReading">
            <v-col cols="12">
              <div class="loading-div">
                <v-progress-circular model-value="50" :size="60" :width="4" indeterminate
                  color="secondary-red"></v-progress-circular>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" v-else-if="loadCsvError">
            <v-col cols="12">
              <v-alert border type="error" variant="tonal" rounded="md" density="compact" class="mb-6"
                icon="mdi-information">
                <p class="text-black mb-0">{{ csvReadError }}</p>
              </v-alert>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-2" v-else>
            <v-col cols="12">
              <h6 class="font-weight-bold">Drop anywhere to upload</h6>
              <span>OR</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as actionTypes from '../store/action-types'
import { mapGetters } from 'vuex'
import FormQuestionJump from '@/components/FormQuestionJump'
import * as util from '@/library/util'
import _ from 'lodash'
import EventBus from '@/library/event-bus'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import IconMixin from '@/mixins/icon'
import intlTelInput from 'intl-tel-input'
import { addressSkins, addressSkinIds, addressAutocompleteModes, addressAutocompleteModeIds, dateSkins, dateSkinIds, rangeSkins, rangeSkinIds, otherChoice } from '@/shared/constants/formQuestionSkins'
import { credentialTypes } from '../shared/constants/credentials'
import phishingWords from '../shared/constants/form/formPhishingWords'
import Sortable from 'sortablejs/modular/sortable.complete.esm.js'
import config from '@/api/config'
import MediaManager from './media/MediaManager.vue'
import IconLibraryManager from './icon-library/IconLibraryManager.vue'
import AppDialog from './AppDialog.vue'
import AppCollapsible from './AppCollapsible.vue'
import { VDateInput } from 'vuetify/labs/VDateInput'
import { extend } from '../plugins/validation/manager'

extend('expression', {
  getMessage: function (params) {
    return params.msg || `${params.fieldName || "This field"} is invalid`
  },
  validate: function (value, params) {
    return params ? params.value === true : true
  }
})

extend('maxPreSelection', {
  getMessage: function (params) {
    return params.msg || `${params.fieldName || "This selection"} is invalid`
  },
  validate: function (value, params) {
    return params ? params.value !== true : true
  }
})

export default {
  mixins: [IconMixin],
  props: {
    stepId: Number,
    questionId: Number,
    index: Number
  },

  components: {
    'form-question-jump': FormQuestionJump,
    'quill-editor': QuillEditor,
    MediaManager,
    IconLibraryManager,
    AppDialog,
    AppCollapsible,
    VDateInput
  },

  data() {
    return {
      gdprPanel: 0,
      slider4: 0,
      formScope: 'form_builder',
      unitAlignments: ['left', 'right'],
      selectedAlignment: 'left',
      deleteRestrictEmailFieldIndex: -1,
      dateDropdownSkin: '',
      open: true,
      showChoices: false,
      showModal: false,
      hasValidJump: false,
      deleteChoiceIndex: -1,
      newQuestionType: null,
      toggleMinMaxVisibility: false,
      selectedAddressFields: '',
      isOpenA: false,
      questionShowFields: false,
      gdprEditorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
            [{ 'color': [] }]
          ]
        }
      },
      gdprOptionsState: {},
      iti: {},
      addressFieldApiKeySource: null,
      sortables: {},
      typedChoicesFormScope: 'typed-choices-form',
      typedChoicesData: null,
      uploadCsvFormSocpe: 'upload-csv-form',
      loadCsvReading: false,
      loadCsvError: false,
      csvReadError: '',
      csvData: null,
      csvUploadError: false,
      csvChoiceIndex: null,
      checkStatus: false,
      showOther: '',
      showTypedChoicesModal: false,
      showBulkUploadOptionsModal: false,
      showUploadCsvModal: false,
      showDeleteQuestionChoiceModal: false,
      csvFiles: []
    }
  },

  mounted() {
    if (this.questionType === this.questionTypesMap.MULTIPLE_CHOICE || this.questionType === this.questionTypesMap.SINGLE_CHOICE) {
      setTimeout(() => {
        let dragChoice = document.getElementById('dragChoiceField' + this.question.id)

        this.initSortable(dragChoice, this.question)
      }, 500)
    }

    if (this.questionType === this.questionTypesMap.ADDRESS) {
      setTimeout(() => {
        let dragAddress = document.getElementById('dragAddressField' + this.question.id)
        this.initSortable(dragAddress, this.question)
      }, 500)
    }

    if (this.questionType === this.questionTypesMap.GDPR) {
      let index = 0

      for (let choice of this.question.options.choices) {
        this.gdprOptionsState[choice.id] = index === 0

        index++
      }
    }
  },

  methods: {
    initSortable: function (el, question, stepId) {
      this.sortables[el] = Sortable.create(el, {
        handle: '.glyphicon-move',
        animation: 150,
        onEnd: () => {
          if (
            this.question.id === question.id &&
            (
              question.type === this.questionTypesMap.SINGLE_CHOICE ||
              question.type === this.questionTypesMap.MULTIPLE_CHOICE
            )
          ) {
            this.$store.dispatch(actionTypes.FORMBUILDER_SORT_QCHOICE, {
              stepId: this.stepId,
              questionId: this.questionId,
              state: this.sortables[el].toArray()
            })
          }

          if (
            this.question.id === question.id &&
            question.type === this.questionTypesMap.ADDRESS
          ) {
            this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QADDRESS_FIELDS, {
              stepId: this.stepId,
              questionId: this.questionId,
              fields: this.addressFieldsObj,
              sorted: this.sortables[el].toArray()
            })
          }
        }
      })
    },

    toggle: function () {
      this.open = !this.open
    },

    toggleOtherOption: function () {
      const otherIndex = this.choices.findIndex(choice => choice.type === this.otherChoice.OTHER)
      if (otherIndex === -1 && this.otherOption) {
        const newChoice = {
          id: this.choices.length + 1,
          label: 'Other Option',
          order: this.choices.length + 1,
          type: otherChoice.OTHER,
          value: ''
        }
        this.choices.push(newChoice)
        this.choicesValues.push('0')
      } else if (otherIndex !== -1) {
        this.removeOtherChoice(otherIndex)
      }
    },

    removeOtherChoice: function (index) {
      this.$store.dispatch(actionTypes.FORMBUILDER_DELETE_QCHOICE, {
        stepId: this.stepId,
        questionId: this.questionId,
        index: this.deleteChoiceIndex
      }).then(() => {
        this.$store.dispatch(actionTypes.FORMBUILDER_DELETE_QCHOICE_VALUE, {
          stepId: this.stepId,
          questionId: this.questionId,
          index: index
        }).then(() => {
          this.adjustChoiceLimits()
          this.choicesValues.splice(index, 1)
          this.choices.splice(index, 1)
          this.deleteChoiceIndex = -1
        })
      })
    },

    adjustChoiceLimits: function () {
      if (this.question.choices.length < this.minChoices || this.question.choices.length > this.maxChoices) {
        this.updateMinChoices(1)
        this.updateMaxChoices(1)
      }
    },

    updateQuestionType: function (question) {
      if (!question) {
        return
      }
      for (let t of this.qt) {
        if (t.type === this.questionType && t.question === this.questionTitle) {
          this.questionTitle = question.question
          break
        }
      }
      this.questionType = question.type
    },

    findType: function (type) {
      for (let t of this.qt) {
        if (t.type === type) {
          return t
        }
      }
      return null
    },

    deleteQuestion: function () {
      this.$store.dispatch(actionTypes.FORMBUILDER_DELETE_Q, {
        'stepId': this.stepId,
        'questionId': this.questionId
      }).then((result) => {
        this.showModal = false
      })
    },

    hasDuplicates: function (array) {
      return (new Set(array)).size !== array.length
    },

    validateJump: function (valid) {
      this.hasValidJump = valid
    },

    openModal: function (name) {
      switch (name) {
        case 'typedChoicesModal': this.showTypedChoicesModal = true; break;
        case 'bulkUploadOptionsModal': this.showBulkUploadOptionsModal = true; break;
        case 'uploadCsvModal': this.showUploadCsvModal = true; break;
        case 'deleteQuestionChoiceModal': this.showDeleteQuestionChoiceModal = true; break;
      }
      /* this.$refs[ref].open() */
    },

    closeModal: function (name) {
      switch (name) {
        case 'typedChoicesModal': this.showTypedChoicesModal = false; break;
        case 'bulkUploadOptionsModal': this.showBulkUploadOptionsModal = false; break;
        case 'uploadCsvModal': this.showUploadCsvModal = false; break;
        case 'deleteQuestionChoiceModal': this.showDeleteQuestionChoiceModal = false; break;
      }
      /* this.$refs[ref].close() */
    },

    updateQuestionSkin: function (event, field) {
      this.questionSkin = Object.assign({}, this.questionSkin, { [field]: event.value })
    },

    // DATE
    updateMinDate: function (value) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QMINDATE, {
        'minDate': value instanceof Date ? value.toISOString() : null,
        'stepId': this.stepId,
        'questionId': this.questionId
      })
    },

    updateMaxDate: function (value) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QMAXDATE, {
        'maxDate': value instanceof Date ? value.toISOString() : null,
        'stepId': this.stepId,
        'questionId': this.questionId
      })
    },
    // NUMBER
    updateMinNumber: function (e) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QMINNUMBER, {
        'minNumber': !this.maxNumber ? parseInt(e) : parseInt(e) > this.maxNumber ? this.maxNumber : e,
        'stepId': this.stepId,
        'questionId': this.questionId
      })
    },
    updateMaxNumber: function (e) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QMAXNUMBER, {
        'maxNumber': !this.minNumber ? parseInt(e) : this.minNumber > parseInt(e) ? this.minNumber : e,
        'stepId': this.stepId,
        'questionId': this.questionId
      })
    },
    // BULK UPLOAD MODAL
    showBulkUploadModal: function (modal) {
      this.closeModal('bulkUploadOptionsModal')
      //this.$validator.pause()
      this.$nextTick(() => {
        /* this.$validator.errors.clear()
        this.$validator.fields.items.forEach((field) => field.reset())
        this.$validator.fields.items.forEach((field) =>
          this.errors.remove(field)
        )
        this.$validator.resume() */
      })
      // Filter out the choices of type 'other' before mapping labels
      let choicesLabel = this.choices.filter(item => item.type !== this.otherChoice.OTHER).map(item => item['label'])
      let formattedChoices = choicesLabel.join('\n')
      this.typedChoicesData = formattedChoices
      this.openModal(modal)
    },
    deleteExistingChoices: function () {
      this.choices.forEach((element, index) => {
        if (element.type !== this.otherChoice.OTHER) {
          this.$store.dispatch(actionTypes.FORMBUILDER_DELETE_QCHOICE, {
            'stepId': this.stepId,
            'questionId': this.questionId,
            'index': index,
            'bulkDelete': true
          }).then(() => {
            this.$store.dispatch(actionTypes.FORMBUILDER_DELETE_QCHOICE_VALUE, {
              'stepId': this.stepId,
              'questionId': this.questionId,
              'index': index,
              'bulkDelete': true
            })
          })
        }
      })
    },
    uploadTypedChoices: function () {
      this.deleteExistingChoices()
      setTimeout(() => {
        let typedText = this.typedChoicesData.match(/[^\r\n]+/g)
        typedText.forEach(element => {
          this.bulkUploadChoices(element)
        })
        this.closeModal('typedChoicesModal')
        this.toggleOtherOption()
      }, 50)
      /* this.$validator.validateAll(this.typedChoicesFormScope)
        .then((result) => {
          if (result) {
            this.deleteExistingChoices()
            setTimeout(() => {
              let typedText = this.typedChoicesData.match(/[^\r\n]+/g)
              typedText.forEach(element => {
                this.bulkUploadChoices(element)
              })
              this.closeModal('typedChoicesModal')
              this.toggleOtherOption()
            }, 50)
          }
        }) */
    },
    downloadCsvSample: function () {
      window.location.href = config.sampleCsvUrl
    },
    uploadCsvFile: function (event) {
      this.csvUploadError = false
      this.csvReadError = ''
      console.log(`files: ${JSON.stringify(this.csvFiles[0])}`);
      this.readCsvFile(this.csvFiles[0])
      /* this.$validator.validateAll(this.uploadCsvFormSocpe)
        .then((result) => {
          if (result) {
            this.readCsvFile(event.target.files[0])
          }
        }) */
    },
    dragCsvFile: function (event) {
      event.preventDefault()
      this.csvUploadError = false
      this.csvReadError = ''

      //this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach((field) => field.reset())
        this.$validator.fields.items.forEach((field) =>
          this.errors.remove(field)
        )
        this.$validator.resume()
      })

      if (event.dataTransfer.files[0]['type'] !== 'text/csv') {
        this.csvUploadError = true
        this.csvReadError = 'The CSV File field must be a valid file.'
        return
      }
      let fileSize = Math.round((event.dataTransfer.files[0]['size'] / 1024))
      if (fileSize > 4096) {
        this.csvUploadError = true
        this.csvReadError = 'The CSV File must be up to 4 MB'
        return
      }
      this.readCsvFile(event.dataTransfer.files[0])
    },
    readCsvFile: function (inputCsvFile) {
      this.csvUploadError = false
      this.loadCsvReading = true
      let localThis = this
      // read csv file
      const reader = new FileReader()
      reader.readAsText(inputCsvFile)
      reader.onload = () => {
        const readText = reader.result
        localThis.formatCsvData(readText)
      }
      reader.onerror = () => {
        localThis.loadCsvError = true
        localThis.csvReadError = reader.error
      }
    },
    formatCsvData: function (data) {
      let newCsvData = data.split(/\r\n|\n/)
      let csvHeader = newCsvData[0].split(',')
      let csvRows = []

      for (let i = 0; i < newCsvData.length; i++) {
        let newData = newCsvData[i].split(',')
        if (newData.length === csvHeader.length) {
          let tempData = []
          for (let j = 0; j < 2; j++) {
            tempData.push(newData[j])
          }
          csvRows.push(tempData)
        }
      }

      if (csvRows.length > 100) {
        this.loadCsvReading = false
        this.csvUploadError = true
        this.csvReadError = 'The CSV File should not have more than 100 records.'
        return
      }

      this.deleteExistingChoices()
      setTimeout(() => {
        this.addCsvDataChoices(csvRows)
      }, 50)
    },
    addCsvDataChoices: function (csvRows) {
      // set choice index
      this.csvChoiceIndex = this.choices.length
      let choiceLabel, choiceValue
      csvRows.forEach(element => {
        choiceLabel = element[0]
        choiceValue = element[1]
        if (choiceLabel) {
          this.bulkUploadChoices(choiceLabel)
          if (choiceValue) {
            let updatedChoiceValue = parseInt(choiceValue) ? parseInt(choiceValue) : 0
            this.bulkUploadChoiceValues(updatedChoiceValue, this.csvChoiceIndex)
            this.csvChoiceIndex++
          }
        }
      })

      let localThis = this
      setTimeout(() => {
        localThis.loadCsvReading = false
        localThis.closeModal('uploadCsvModal')
        localThis.toggleOtherOption()
      }, 1000)
    },
    bulkUploadChoices: function (label) {
      this.$store.dispatch(actionTypes.FORMBUILDER_ADD_QCHOICE_VALUE, {
        'stepId': this.stepId,
        'questionId': this.questionId,
        'choiceLabel': label
      }).then(() => {
        this.$store.dispatch(actionTypes.FORMBUILDER_ADD_QCHOICE, {
          'stepId': this.stepId,
          'questionId': this.questionId,
          'choiceLabel': label
        })
      })
    },
    bulkUploadChoiceValues: function (value, index) {
      this.enableChoicesValues = true
      this.choiceValueUpdated(value, index)
    },

    // MULTI SELECT / SINGLE SELECT
    updateMinChoices: function (value) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QMINCHOICES, {
        'minChoices': value,
        'stepId': this.stepId,
        'questionId': this.questionId
      })
    },

    updateMaxChoices: function (value) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QMAXCHOICES, {
        'maxChoices': value,
        'stepId': this.stepId,
        'questionId': this.questionId
      })
    },

    choiceUpdated: function (value, choice, field) {
      if (this.questionType === this.questionTypesMap.SINGLE_CHOICE && this.enablePreSelectChoices) {
        for (let ch of this.choices) {
          if (ch.selected && ch.selected === value) {
            ch.selected = false
          }
        }
      }
      if (this.hasChoices) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QCHOICE, {
          stepId: this.stepId,
          questionId: this.questionId,
          choice: Object.assign({}, choice, { [field]: value })
        }).then(() => {
          /* this.$validator.validate(`${this.formScope}.*`) */
        })
      }
    },

    choiceValueUpdated: function (value, index) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QCHOICE_VALUE, {
        'stepId': this.stepId,
        'questionId': this.questionId,
        'index': index,
        'value': value
      })
    },

    choiceImageUpdate: function (selected, choice) {
      this.$store.dispatch('media/setVisibility', {
        visible: true
      })
      this.choiceUpdated(selected[0].url, choice, 'image')
      /* EventBus.$on('mm:selected', (selected) => {
        this.choiceUpdated(selected[0].url, choice, 'image')
      }) */
    },

    choiceIconUpdate: function (selected, choice) {
      this.$store.dispatch('iconlibrary/setVisibility', {
        visible: true
      })
      console.log(`icon got: `, selected[0]);
      this.choiceUpdated(selected[0], choice, 'icon')
      /* EventBus.$on('ilm:selected', (selected) => {
        this.choiceUpdated(selected[0], choice, 'icon')
      }) */
    },

    choiceImageRemove: function (choice) {
      this.choiceUpdated('', choice, 'image')
    },

    choiceIconRemove: function (choice) {
      this.choiceUpdated('', choice, 'icon')
    },

    addChoice: function () {
      let otherOptionId = ''
      for (let choices of this.question.choices) {
        if (choices.type === this.otherChoice.OTHER) {
          otherOptionId = choices.order
        }
      }
      this.$store.dispatch(actionTypes.FORMBUILDER_ADD_QCHOICE_VALUE, {
        'stepId': this.stepId,
        'questionId': this.questionId
      }).then(() => {
        this.$store.dispatch(actionTypes.FORMBUILDER_ADD_QCHOICE, {
          'stepId': this.stepId,
          'questionId': this.questionId,
          'otherOption': otherOptionId
        })
      })
    },

    setDeleteChoice: function (choiceIndex) {
      this.deleteChoiceIndex = choiceIndex
      this.openModal('deleteQuestionChoiceModal')
    },

    deleteChoice: function () {
      this.$store.dispatch(actionTypes.FORMBUILDER_DELETE_QCHOICE, {
        'stepId': this.stepId,
        'questionId': this.questionId,
        'index': this.deleteChoiceIndex
      }).then(() => {
        this.$store.dispatch(actionTypes.FORMBUILDER_DELETE_QCHOICE_VALUE, {
          'stepId': this.stepId,
          'questionId': this.questionId,
          'index': this.deleteChoiceIndex
        }).then(() => {
          if (
            this.question.choices.length < this.minChoices ||
            this.question.choices.length < this.maxChoices
          ) {
            this.updateMinChoices(1)
            this.updateMaxChoices(1)
          }
          this.deleteChoiceIndex = -1
        })
      })
      this.closeModal('deleteQuestionChoiceModal')
    },
    isSameDomain: function (data, question) {
      let count = 0
      for (let field of this.restrictEmailFields) {
        if (field.email === data.email) {
          count++
        } else if (field.email && data.email && data.email.trim() === field.email.trim()) {
          count++
        }
      }
      if (count >= 2) {
        return true
      }
      return false
    },
    isDuplicateChoice: function (choice) {
      let count = 0
      for (let c of this.choices) {
        if (c.label === choice.label) {
          count++
        } else if (c.label && choice.label && choice.label.trim() === c.label.trim()) {
          count++
        }
      }
      if (count >= 2) {
        return true
      }
      return false
    },
    isExceedingMinChoices: function (choicesData, minChoices) {
      const selectedCount = choicesData.filter(choice => choice.selected).length
      // console.log('Selected choices count:', selectedCount)
      // console.log('Minimum required choices:', minChoices)
      return selectedCount > minChoices
    },

    singleSelectSkinLayouts: function (skin) {
      if (skin.id === 'image') {
        return this.imageSkinLayouts
      } else if (skin.id === 'icon') {
        return this.iconSkinLayouts
      }

      return []
    },

    multiSelectSkinLayouts: function (skin) {
      if (skin.id === 'image') {
        return this.imageSkinLayouts
      } else if (skin.id === 'icon') {
        return this.iconSkinLayouts
      }

      return []
    },

    singleSelectSkinColumns: function (skin) {
      let columns = [
        { label: 'One', value: '1' },
        { label: 'Two', value: '2' },
        { label: 'Three', value: '3' },
        { label: 'Four', value: '4' }
      ]

      if (skin.id === 'image') {
        return columns
      } else if (skin.id === 'icon') {
        return columns
      }
      return []
    },

    multiSelectSkinColumns: function (skin) {
      let columns = [
        { label: 'One', value: '1' },
        { label: 'Two', value: '2' },
        { label: 'Three', value: '3' },
        { label: 'Four', value: '4' }
      ]

      if (skin.id === 'image') {
        return columns
      } else if (skin.id === 'icon') {
        return columns
      }

      return []
    },

    // ADDRESS QUESTION
    updateAddressFields: function (fields) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QADDRESS_FIELDS, {
        stepId: this.stepId,
        questionId: this.questionId,
        fields: fields
      })
    },
    updateRangeField: function (value, key) {
      let newFields = this.rangeFieldsObj
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QRANGE_RANGEFIELD, {
        stepId: this.stepId,
        questionId: this.questionId,
        rangeField: newFields[key] = value
      }).then(() => {
        /* this.$validator.validate(`${this.formScope}.*`) */
      })
    },
    updateAddressFieldItem: function (value, field, key) {
      let addressFields = this.addressFieldsObj

      if (key === 'label' || key === 'placeholder') {
        field[key + '_value'] = value
        addressFields[field.id][key + '_value'] = value
      } else {
        field[key] = value
        addressFields[field.id][key] = value
      }
      this.updateAddressFields(addressFields)
    },

    selectedAddressFieldsChanged: function (fields) {

    },

    updateAddressFieldsName: function () {
      if (!this.questionFieldName || !this.questionFieldName.trim()) {
        return
      }
      let addressFields = this.addressFieldsObj
      for (let addressFieldKey in addressFields) {
        let fieldName = this.questionFieldName + '_' + addressFields[addressFieldKey].field_name_suffix
        addressFields[addressFieldKey].field_name = fieldName
      }
      this.updateAddressFields(addressFields)
    },

    clearDuplicateAddressFields: function (fields) {
      let newFields = []
      let fieldIds = []
      for (let field of fields) {
        if (fieldIds.indexOf(field.id) !== -1) {
          continue
        }
        fieldIds.push(field.id)
        newFields.push(field)
      }
      return newFields
    },

    orderedAddressField: function (fields) {
      if (fields.length > 0 && !fields[0].order) {
        return fields
      }
      return _.orderBy(fields, ['order'], ['asc'])
    },

    changeAddressFieldOrder: function (field, up) {
      let orderedAddressField = this.orderedAddressField(this.addressFields)
      if (up && field.order <= orderedAddressField[0].order) {
        return
      }
      if (!up && field.order >= orderedAddressField[orderedAddressField.length - 1].order) {
        return
      }

      let order = field.order + (up ? -1 : 1)
      for (let addressFieldKey in this.addressFieldsObj) {
        let addressField = this.addressFieldsObj[addressFieldKey]
        if (addressField.order === order) {
          this.updateAddressFieldItem(field.order, addressField, 'order')
          this.updateAddressFieldItem(order, field, 'order')
          break
        }
      }
    },

    updateAddressAutocompleteApiKey: function (apikey, apikeySource) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY, {
        stepId: this.stepId,
        questionId: this.questionId,
        apikey: apikeySource === this.addressQuestionApiKeySources.GLOBAL_API_KEY ? apikey.id : apikey,
        apikeySource
      })
    },

    updateAddressAutocompleteApiKeySource: function (apikeySource) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY_SOURCE, {
        stepId: this.stepId,
        questionId: this.questionId,
        apikeySource: apikeySource.value
      })
    },

    updateAddressAutocompleteMode: function (mode) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_MODE, {
        stepId: this.stepId,
        questionId: this.questionId,
        autocompleteMode: mode
      })
    },

    // GDPR QUESTION
    deleteGDPRChoice: function (id) {
      let choices = _.cloneDeep(this.question.options.choices)

      choices = choices.filter((choice) => {
        return choice.id !== id
      })

      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_GDPR_CHOICES, {
        'choices': choices,
        'stepId': this.stepId,
        'questionId': this.questionId
      })
    },

    addGDPRChoice: function () {
      let choices = _.cloneDeep(this.question.options.choices)

      let id = -1

      for (let choice of choices) {
        if (id < choice.id) {
          id = choice.id
        }
      }

      id += 1

      choices.push({
        id: id,
        label: '',
        required: true
      })

      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_GDPR_CHOICES, {
        'choices': choices,
        'stepId': this.stepId,
        'questionId': this.questionId
      }).then(() => {
        this.gdprOptionsState[id] = false

        this.openGdprOption(id)
      })
    },

    updateGDPRChoice: function (value, choice, field) {
      let choices = _.cloneDeep(this.question.options.choices)
      let newChoice = _.find(choices, { id: choice.id })

      if (!newChoice) {
        return
      }

      newChoice[field] = value

      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_GDPR_CHOICES, {
        'choices': choices,
        'stepId': this.stepId,
        'questionId': this.questionId
      })
    },

    isDuplicateGDPROption: function (choice) {
      let count = 0
      for (let c of this.question.options.choices) {
        if (c.label === choice.label) {
          count++
        }
      }
      if (count >= 2) {
        return true
      }
      return false
    },
    openGdprOption: function (id) {
      let state = _.cloneDeep(this.gdprOptionsState)

      for (let choiceId in state) {
        state[choiceId] = false
      }

      state[id] = true

      this.gdprOptionsState = state
    },
    closeGdprOption: function (id) {
      let state = _.cloneDeep(this.gdprOptionsState)

      state[id] = false

      this.gdprOptionsState = state
    },

    // EMAIL ADDRESS QUESTION
    updateEmailAddressReplyTo: function (value) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QEMAIL_REPLYTO, {
        'replyTo': value,
        'stepId': this.stepId,
        'questionId': this.questionId
      })
    },
    updateRestrictEmailAddress: function (value) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAIL, {
        'restrictEmail': value,
        'stepId': this.stepId,
        'questionId': this.questionId
      })
    },
    restrictEmailFieldValueUpdated: function (value, field, item) {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELD, {
        stepId: this.stepId,
        questionId: this.questionId,
        restrictEmailField: Object.assign({}, field, { [item]: item === 'email' ? value.toLowerCase() : value })
      }).then(() => {
        /* this.$validator.validate(`${this.formScope}.*`) */
      })
    },
    addField: function () {
      this.$store.dispatch(actionTypes.FORMBUILDER_ADD_QEMAIL_RESTRICTEMAILFIELD, {
        stepId: this.stepId,
        questionId: this.questionId
      })
    },
    deleteRestrictEmailField: function (index) {
      this.deleteRestrictEmailFieldIndex = index
      this.$store.dispatch(actionTypes.FORMBUILDER_DELETE_QEMAIL_RESTRICTEMAILFIELD, {
        stepId: this.stepId,
        questionId: this.questionId,
        index: this.deleteRestrictEmailFieldIndex
      })
      this.deleteRestrictEmailFieldIndex = -1
    },

    // PHONE NUMBER QUESTION
    updateDefaultCountryCode: function () {
      this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QPHONE_COUNTRY_CODE_VALUE, {
        'defaultCountryCode': this.enableDefaultCode ? this.iti.getSelectedCountryData().iso2 : null,
        'stepId': this.stepId,
        'questionId': this.questionId
      })
    },
    initialiseIntlInput: function () {
      setTimeout(() => {
        let inputId = '#question-default-code-' + this.question.id
        let countryCode = document.querySelector(inputId)
        this.iti = intlTelInput(countryCode, {
          separateDialCode: true,
          utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.6/js/utils.js',
          initialCountry: this.selectedCountry() || 'auto',
          geoIpLookup: function (success, failure) {
            window.$.get('https://ipapi.co/jsonp/', function () { }, 'jsonp').always(function (resp) {
              let countryValue = (resp && resp.country) ? resp.country : 'us'
              success(countryValue)
            })
          }
        })
      }, 10)
    },
    selectedCountry: function () {
      if (this.enableDefaultCode && this.defaultCountryCode) {
        return this.defaultCountryCode
      }
      return null
    }
  },

  computed: {
    ...mapGetters([
      'questionTypesMap',
      'hasFirstNameTypeQuestion',
      'hasLastNameTypeQuestion',
      'hasGdprTypeQuestion'
    ]),

    // Shared
    questionType: {
      get() {
        return this.question.type
      },
      set(type) {
        let isNewQuestion = !this.questionType

        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QTYPE, {
          'type': type,
          'stepId': this.stepId,
          'questionId': this.questionId
        }).then(() => {
          /* this.$validator.validate(`${this.formScope}.*`) */
        })

        var t = this.findType(type)

        // SINGLE|MULTIPLE update choices
        if (
          this.questionType === this.questionTypesMap.MULTIPLE_CHOICE ||
          this.questionType === this.questionTypesMap.SINGLE_CHOICE
        ) {
          if (t.options.choices) {
            if (isNewQuestion || !this.question.choices) {
              let choices = t.options.choices.slice()
              let choicesValues = t.options.choicesValues.slice()

              this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QCHOICES, {
                'choices': choices,
                'stepId': this.stepId,
                'questionId': this.questionId
              }).then(() => {
                /* this.$validator.validate(`${this.formScope}.*`) */
              })

              this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QCHOICES_VALUES, {
                'choicesValues': choicesValues,
                'stepId': this.stepId,
                'questionId': this.questionId
              })

              if (t.type === this.questionTypesMap.MULTIPLE_CHOICE) {
                this.updateMinChoices(1)
                this.updateMaxChoices(1)
              }
            }

            if (t.type === this.questionTypesMap.MULTIPLE_CHOICE) {
              this.questionSkin = this.multiSelectSkins[0]
            }

            if (t.type === this.questionTypesMap.SINGLE_CHOICE) {
              this.questionSkin = this.singleSelectSkins[0]
            }

            this.$store.dispatch(actionTypes.FORMBUILDER_DELETE_QJUMPS, {
              questionId: this.questionId
            })
          }

          setTimeout(() => {
            let dragChoice = document.getElementById('dragChoiceField' + this.question.id)

            this.initSortable(dragChoice, this.question)
          }, 500)
        }

        // COMMON update title
        if (isNewQuestion) {
          this.questionTitle = this.questionTypesMap.GDPR === this.questionType ? t.title : t.question
        }

        // COMMON update description
        if (isNewQuestion) {
          this.questionDescription = t.description
        }

        // COMMON update required option
        if (isNewQuestion) {
          if (this.questionType !== this.questionTypesMap.ADDRESS) {
            this.required = t.options.require
          }
          if (this.questionType === this.questionTypesMap.RANGE) {
            this.showHideOrientationScale = t.options.showHideOrientationScale
          }
        }
        if (this.questionType === this.questionTypesMap.ADDRESS) {
          this.required = t.required
        }

        // ADDRESS
        if (this.questionType === this.questionTypesMap.ADDRESS) {
          if (!this.addressFieldsArray.length) {
            this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QADDRESS_FIELDS, {
              stepId: this.stepId,
              questionId: this.questionId,
              fields: _.cloneDeep(t.fields)
            }).then(() => {
              this.selectedAddressFields = this.addressFieldsArray.filter((f) => f.enabled)
              /* this.$validator.validate(`${this.formScope}.*`) */
            })
          }

          if (isNewQuestion) {
            this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_MODE, {
              stepId: this.stepId,
              questionId: this.questionId,
              autocompleteMode: _.find(addressAutocompleteModes, { id: addressAutocompleteModeIds.SEARCH })
            })
          }

          this.selectedAddressFields = this.addressFieldsArray.filter((f) => f.enabled)

          setTimeout(() => {
            let dragAddress = document.getElementById('dragAddressField' + this.question.id)
            this.initSortable(dragAddress, this.question)
          }, 500)
        }

        if (this.questionType === this.questionTypesMap.ADDRESS) {
          this.questionSkin = this.addressSkins[0]
        }

        // Date
        if (this.questionType === this.questionTypesMap.DATE) {
          this.questionSkin = this.dateSkins[0]
        }

        // Range
        if (this.questionType === this.questionTypesMap.RANGE) {
          this.questionSkin = this.rangeSkins[0]
          this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QRANGE_RANGEFIELDS, {
            'rangeFields': _.cloneDeep(t.options.rangeFields),
            'stepId': this.stepId,
            'questionId': this.questionId
          }).then(() => {
            /* this.$validator.validate(`${this.formScope}.*`) */
          })
        }

        // GDPR
        if (this.questionType === this.questionTypesMap.GDPR) {
          this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_GDPR_CHOICES, {
            'choices': t.options.choices || [],
            'stepId': this.stepId,
            'questionId': this.questionId
          }).then(() => {
            /* this.$validator.validate(`${this.formScope}.*`) */
            let index = 0

            for (let choice of this.question.options.choices) {
              this.gdprOptionsState[choice.id] = index === 0

              index++
            }
          })
        }

        // EMAIL ADDRESS
        if (t.type === this.questionTypesMap.EMAIL_ADDRESS) {
          let restrictEmailFields = t.options.restrictEmailFields.slice()
          this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QEMAIL_REPLYTO, {
            'replyTo': t.options.replyTo,
            'stepId': this.stepId,
            'questionId': this.questionId
          }).then(() => {
            /* this.$validator.validate(`${this.formScope}.*`) */
          })
          this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAIL, {
            'restrictEmail': t.options.restrictEmail,
            'stepId': this.stepId,
            'questionId': this.questionId
          }).then(() => {
            /* this.$validator.validate(`${this.formScope}.*`) */
          })

          this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELDS, {
            'restrictEmailFields': restrictEmailFields,
            'stepId': this.stepId,
            'questionId': this.questionId
          }).then(() => {
            /* this.$validator.validate(`${this.formScope}.*`) */
          })
        }
      }
    },
    validateMinMaxValue: function () {
      if (!this.rangeFieldsObj.maxScaleValue) {
        return 'required'
      }
      let value = `max_value: ${this.rangeFieldsObj.maxScaleValue}`
      return value
    },

    questionTitle: {
      get() {
        return this.question.title
      },
      set(title) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QTITLE, {
          'title': title,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },

    questionHideTitle: {
      get() {
        return this.question.hide_title
      },
      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QTITLE_HIDE, {
          'hide_title': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },

    questionDescription: {
      get() {
        return this.question.description || this.questionTypeObj.description
      },
      set(description) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QDESCRIPTION, {
          'description': description,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },

    questionFieldName: {
      get() {
        return this.question.field_name || this.questionTypeObj.field_name + this.question.id
      },
      set(fieldName) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QFIELDNAME, {
          'fieldName': fieldName ? fieldName.trim() : fieldName,
          'stepId': this.stepId,
          'questionId': this.questionId
        }).then(() => {
          if (this.questionType === this.questionTypesMap.ADDRESS) {
            this.updateAddressFieldsName()
          }
        })
      }
    },

    questionSkin: {
      get: function () {
        if (
          this.questionType === this.questionTypesMap.SINGLE_CHOICE ||
          this.questionType === this.questionTypesMap.MULTIPLE_CHOICE
        ) {
          return this.question.skin || this.choiceQuestionSkins[0]
        } else if (this.questionType === this.questionTypesMap.ADDRESS) {
          return this.question.skin || this.addressSkins[0]
        } else if (this.questionType === this.questionTypesMap.DATE) {
          return this.question.skin || this.dateSkins[0]
        } else if (this.questionType === this.questionTypesMap.RANGE) {
          return this.question.skin || this.rangeSkins[0]
        }

        return null
      },
      set: function (value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QSKIN, {
          'skin': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },

    questionSkinOptions: function () {
      if (
        this.questionType === this.questionTypesMap.SINGLE_CHOICE ||
        this.questionType === this.questionTypesMap.MULTIPLE_CHOICE
      ) {
        return this.choiceQuestionSkins
      } else if (this.questionType === this.questionTypesMap.ADDRESS) {
        return this.addressSkins
      } else if (this.questionType === this.questionTypesMap.DATE) {
        return this.dateSkins
      } else if (this.questionType === this.questionTypesMap.RANGE) {
        return this.rangeSkins
      }

      return []
    },

    questionSkinLayout: {
      get: function () {
        if (!this.questionSkin || this.questionSkinLayouts.length === 0) {
          return ''
        }

        let questionSkinLayout = this.questionSkinLayouts
          .filter(layout => layout.value === this.questionSkin.layout)
          .pop()

        return questionSkinLayout || this.questionSkinLayouts[0]
      }
    },

    questionSkinColumn: {
      get: function () {
        if (this.questionSkinColumns.length === 0) {
          return ''
        }

        let questionSkinColumn = this.questionSkinColumns
          .filter(column => column.value === this.questionSkin.column)
          .pop()

        return questionSkinColumn || this.questionSkinColumns[0]
      }
    },

    questionSkinLayouts: function () {
      if (!this.hasSkin) {
        return []
      }

      if (this.questionType === this.questionTypesMap.SINGLE_CHOICE) {
        return this.singleSelectSkinLayouts(this.questionSkin)
      }

      if (this.questionType === this.questionTypesMap.MULTIPLE_CHOICE) {
        return this.multiSelectSkinLayouts(this.questionSkin)
      }

      return []
    },

    questionSkinColumns: function () {
      if (!this.hasSkin) {
        return []
      }

      if (this.questionType === this.questionTypesMap.SINGLE_CHOICE) {
        return this.singleSelectSkinColumns(this.questionSkin)
      }

      if (this.questionType === this.questionTypesMap.MULTIPLE_CHOICE) {
        return this.multiSelectSkinColumns(this.questionSkin)
      }

      return []
    },

    hasSkin: function () {
      return !!this.question.skin && this.questionSkinOptions.length > 0
    },

    hasSkinLayout: function () {
      return this.hasSkin && this.questionSkinLayouts.length > 0
    },

    hasSkinColumn: function () {
      return this.hasSkin && this.questionSkinColumns.length > 0
    },

    placeholder: {
      get() {
        if (this.hasPlaceholder) {
          return this.question.placeholder
        }
      },
      set(placeholder) {
        if (this.hasPlaceholder) {
          this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QPLACEHOLDER, {
            'placeholder': placeholder,
            'stepId': this.stepId,
            'questionId': this.questionId
          })
        }
      }
    },
    placeholderDay: {
      get() {
        if (this.hasPlaceholder) {
          return this.question.placeholderDay || this.placeholderDayText
        }
      },
      set(placeholderDay) {
        if (this.hasPlaceholder) {
          this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QDATE_PLACEHOLDERDAY, {
            'placeholderDay': placeholderDay,
            'stepId': this.stepId,
            'questionId': this.questionId
          })
        }
      }
    },
    placeholderMonth: {
      get() {
        if (this.hasPlaceholder) {
          return this.question.placeholderMonth || this.placeholderMonthText
        }
      },
      set(placeholderMonth) {
        if (this.hasPlaceholder) {
          this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QDATE_PLACEHOLDERMONTH, {
            'placeholderMonth': placeholderMonth,
            'stepId': this.stepId,
            'questionId': this.questionId
          })
        }
      }
    },
    placeholderYear: {
      get() {
        if (this.hasPlaceholder) {
          return this.question.placeholderYear || this.placeholderYearText
        }
      },
      set(placeholderYear) {
        if (this.hasPlaceholder) {
          this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QDATE_PLACEHOLDERYEAR, {
            'placeholderYear': placeholderYear,
            'stepId': this.stepId,
            'questionId': this.questionId
          })
        }
      }
    },

    required: {
      get() {
        return this.question.required
      },

      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QREQUIRED, {
          'required': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },

    hasRequiredField: function () {
      if (this.questionType === this.questionTypesMap.GDPR) {
        return false
      }

      if (
        this.questionType === this.questionTypesMap.ADDRESS &&
        (
          (
            this.questionSkin.id === this.addressSkinIds.GOOGLE_AUTOCOMPLETE &&
            this.question.autocompleteMode === this.addressAutocompleteModeIds.SEARCH &&
            this.question.autocompleteFieldsEdit
          ) ||
          (
            this.questionSkin.id === this.addressSkinIds.DEFAULT
          ) ||
          (
            this.questionSkin.id === this.addressSkinIds.GOOGLE_AUTOCOMPLETE &&
            this.question.autocompleteMode === this.addressAutocompleteModeIds.MANUAL
          )
        )
      ) {
        return false
      }
      if (this.questionType === this.questionTypesMap.RANGE && (this.questionSkin.id === rangeSkinIds.SLIDER_SCALE || this.questionSkin.id === rangeSkinIds.RANGE_SCALE)) {
        return false
      }

      return true
    },

    question: function () {
      return this.$store.getters.getQuestion(this.stepId, this.questionId)
    },

    qt: function () {
      return this.$store.state.formQuestionTypes.filter(question => {
        if (this.questionType === this.questionTypesMap.FIRST_NAME) {
          return true
        } else if (
          question.type === this.questionTypesMap.FIRST_NAME &&
          this.hasFirstNameTypeQuestion
        ) {
          return false
        }

        if (this.questionType === this.questionTypesMap.LAST_NAME) {
          return true
        } else if (
          question.type === this.questionTypesMap.LAST_NAME &&
          this.hasLastNameTypeQuestion
        ) {
          return false
        }

        if (this.questionType === this.questionTypesMap.GDPR) {
          return true
        } else if (
          question.type === this.questionTypesMap.GDPR &&
          this.hasGdprTypeQuestion
        ) {
          return false
        }

        return true
      })
    },

    questionTypeObj: function () {
      if (!this.questionType) {
        return ''
      }
      for (let q of this.qt) {
        if (q.type === this.questionType) {
          return q
        }
      }
    },

    title: function () {
      if (this.questionTypeObj) {
        return this.questionTypeObj.question || ''
      }
      return ''
    },

    hasPlaceholder: function () {
      if (!this.questionTypeObj) {
        return false
      }
      if (this.questionType === this.questionTypesMap.ADDRESS) {
        return false
      }
      if (this.questionType === this.questionTypesMap.RANGE) {
        return false
      }
      if (this.questionType === this.questionTypesMap.SINGLE_CHOICE || this.questionType === this.questionTypesMap.MULTIPLE_CHOICE) {
        if (this.questionSkin.id !== this.singleSelectSkins[2].id) {
          return false
        }
        return true
      }
      return this.questionTypeObj.options.placeholder
    },

    placeholderText: function () {
      if (this.hasPlaceholder) {
        return this.questionTypeObj.options.placeholderText
      }
      return ''
    },
    placeholderDayText: function () {
      if (!this.hasPlaceholder) {
        return
      }
      if (!this.questionTypeObj.options.placeholderDayText && this.questionSkin.id === this.dateSkinIds.THREE_INPUT_BOXES) {
        return 'DD'
      }
      if (!this.questionTypeObj.options.placeholderDayText && this.questionSkin.id === this.dateSkinIds.DROPDOWN) {
        return 'Day'
      }
      return this.questionTypeObj.options.placeholderDayText
    },
    placeholderMonthText: function () {
      if (!this.hasPlaceholder) {
        return
      }
      if (!this.questionTypeObj.options.placeholderMonthText && this.questionSkin.id === this.dateSkinIds.THREE_INPUT_BOXES) {
        return 'MM'
      }
      if (!this.questionTypeObj.options.placeholderMonthText && this.questionSkin.id === this.dateSkinIds.DROPDOWN) {
        return 'Month'
      }
      return this.questionTypeObj.options.placeholderMonthText
    },
    placeholderYearText: function () {
      if (!this.hasPlaceholder) {
        return
      }
      if (!this.questionTypeObj.options.placeholderYearText && this.questionSkin.id === this.dateSkinIds.THREE_INPUT_BOXES) {
        return 'YYYY'
      }
      if (!this.questionTypeObj.options.placeholderYearText && this.questionSkin.id === this.dateSkinIds.DROPDOWN) {
        return 'Year'
      }
      return this.questionTypeObj.options.placeholderYearText
    },
    placeholderOneInputText: function () {
      if (!this.hasPlaceholder) {
        return
      }
      return 'DD/MM/YYYY'
    },
    fieldNameText: function () {
      return this.question.type
    },

    step: function () {
      return this.$store.getters.getStep(this.stepId)
    },

    steps: function () {
      return this.$store.state.formBuildState.steps || []
    },

    inLogicJumps: function () {
      if (this.deleteChoiceIndex === -1 || !this.question.choices[this.deleteChoiceIndex]) {
        return false
      }
      let jumps = this.question.jumps
      if (jumps && jumps.length > 0) {
        for (let jump of jumps) {
          for (let condition of jump.conditions) {
            if (condition.choice === this.question.choices[this.deleteChoiceIndex].id) {
              return true
            }
          }
        }
      }
      return false
    },

    hiddenFields: function () {
      return this.$store.getters.formHiddenFields(parseInt(this.$route.params.id), parseInt(this.$route.params.variantId)) || []
    },

    validateForm: function () {
      return this.$store.getters.validateForm
    },

    calculator: function () {
      return this.$store.state.formBuildState.calculator
    },

    hasDuplicateFieldName: function () {
      return this.fieldNames
        .filter(v => v === this.questionFieldName)
        .length > 1
    },

    fieldNames: function () {
      return this.$store.getters.getFormBuilderFieldNames
    },

    // DATE QUESTION
    enableMinMax: {
      get() {
        return this.question.enableMinMax
      },

      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QMINMAX, {
          'enableMinMax': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },

    minDate: function () {
      if (this.question.minDate) {
        return new Date(this.question.minDate)
      }
      return null
    },

    maxDate: function () {
      if (this.question.maxDate) {
        return new Date(this.question.maxDate)
      }
      return null
    },

    // enable auto increment
    autoIncrement: {
      get() {
        return this.question.autoIncrement
      },
      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QMINMAX_AUTOINCEREMENT, {
          'autoIncrement': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },

    // MULTI SELECT / SINGLE SELECT QUESTION
    enableMinMaxChoices: {
      get() {
        return this.question.enableMinMaxChoices
      },

      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QMINMAXCHOICES, {
          'enableMinMaxChoices': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },

    enableChoicesValues: {
      get() {
        return this.question.enableChoicesValues
      },

      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QENABLE_CHOICES_VALUES, {
          'enableChoicesValues': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },
    enablePreSelectChoices: {
      get() {
        return this.question.enablePreSelectChoices
      },

      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QENABLE_PRE_SELECT_CHOICES, {
          'enablePreSelectChoices': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },

    randomChoiceOrder: {
      get() {
        return this.question.randomChoiceOrder
      },

      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QENABLE_RANDOM_CHOICE_ORDER, {
          'randomChoiceOrder': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },

    otherOption: {
      get() {
        if (this.question.otherOption) {
          this.showOther = true
        }
        return this.question.otherOption
      },

      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QENABLE_OTHER_CHOICE, {
          'otherOption': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },

    minMaxChoiceOptions: function () {
      let x = this.$store.getters.getQuestion(this.stepId, this.questionId)
      if (x.choices.length) {
        let opt = []
        for (let i = 0; i < x.choices.length; i++) {
          opt[i] = i + 1
        }
        let strOpt = opt.map(function (e) { return e.toString() })
        return strOpt
      }
    },

    hasChoices: function () {
      if (!this.questionTypeObj) {
        return false
      }

      if (this.questionType === this.questionTypesMap.ADDRESS) {
        return false
      }

      if (this.questionType === this.questionTypesMap.GDPR) {
        return false
      }

      if (this.questionTypeObj.options.choices) {
        return true
      }
      return false
    },
    rangeFieldsObj: function () {
      return this.question.rangeFields || {}
    },
    choices: function () {
      return this.$store.getters.getQuestion(this.stepId, this.questionId).choices || []
    },
    restrictEmailFields: function () {
      return this.$store.getters.getQuestion(this.stepId, this.questionId).restrictEmailFields || []
    },

    choicesValues: function () {
      return this.$store.getters.getQuestion(this.stepId, this.questionId).choicesValues || []
    },

    minChoices: function () {
      if (this.question.minChoices) {
        return this.question.minChoices.toString()
      }
      return this.required ? '1' : '0'
    },

    maxChoices: function () {
      if (this.question.maxChoices) {
        return this.question.maxChoices.toString()
      }
      return this.required ? '1' : '0'
    },
    minNumber: function () {
      if (this.question.minNumber || this.question.minNumber === 0) {
        return this.question.minNumber
      }
      return null
    },
    maxNumber: function () {
      if (this.question.maxNumber) {
        return this.question.maxNumber
      }
      return null
    },
    singleSelectSkins: function () {
      return util.singleSelectSkins()
    },

    multiSelectSkins: function () {
      return util.multiSelectSkins()
    },

    addressSkins: function () {
      return addressSkins
    },

    addressSkinIds: function () {
      return addressSkinIds
    },
    dateSkins: function () {
      return dateSkins
    },
    otherChoice: function () {
      return otherChoice
    },

    dateSkinIds: function () {
      return dateSkinIds
    },
    rangeSkins: function () {
      return rangeSkins
    },

    rangeSkinIds: function () {
      return rangeSkinIds
    },

    choiceQuestionSkins: function () {
      if (this.questionType === this.questionTypesMap.MULTIPLE_CHOICE) {
        return this.multiSelectSkins
      }
      return this.singleSelectSkins
    },

    choiceAlignments: function () {
      return util.alignments()
    },

    imageSkinLayouts: function () {
      return [
        {
          value: 'boxed_content',
          label: 'Boxed Content'
        },
        {
          value: 'inner_content',
          label: 'Inner Content'
        },
        {
          value: 'outer_content',
          label: 'Outer Content'
        }
      ]
    },

    iconSkinLayouts: function () {
      return [
        {
          value: 'boxed_content',
          label: 'Boxed Content'
        },
        {
          value: 'outer_content',
          label: 'Outer Content'
        }
      ]
    },

    // ADDRESS QUESTION
    addressFields: {
      get: function () {
        if (this.questionType !== this.questionTypesMap.ADDRESS) {
          return []
        }
        return this.addressFieldsArray.filter(f => f.enabled)
      },
      set: function (fields) {
        if (!fields || Object.keys(fields).length === 0 || this.addressFieldsArray.length === 0) {
          return
        }

        for (let addressFieldKey in this.addressFieldsObj) {
          if (_.find(fields, { id: this.addressFieldsObj[addressFieldKey].id })) {
            this.addressFieldsObj[addressFieldKey].enabled = true
          } else {
            this.addressFieldsObj[addressFieldKey].enabled = false
          }
        }

        this.updateAddressFields(this.addressFieldsObj)
      }
    },

    addressFieldsObj: function () {
      return this.question.fields || {}
    },

    addressFieldsArray: function () {
      let fieldsArray = []
      for (let key in this.addressFieldsObj) {
        fieldsArray.push(this.addressFieldsObj[key])
      }
      return this.clearDuplicateAddressFields(fieldsArray)
    },
    addressQuestionApiKeySourcesOptions: function () {
      return [
        { label: 'Custom', value: this.addressQuestionApiKeySources.CUSTOM },
        { label: 'Global API Key', value: this.addressQuestionApiKeySources.GLOBAL_API_KEY }
      ]
    },

    addressQuestionApiKeySources: function () {
      return {
        CUSTOM: 'custom',
        GLOBAL_API_KEY: 'globalApiKey'
      }
    },

    addressAutocompleteApiKeySourceValue: function () {
      if (this.questionType !== this.questionTypesMap.ADDRESS) {
        return ''
      }

      return _.find(
        this.addressQuestionApiKeySourcesOptions,
        {
          value: this.question.autocompleteApiKeySource
        }
      ) || ''
    },

    addressAutocompleteApiKeyValue: function () {
      if (this.questionType !== this.questionTypesMap.ADDRESS) {
        return ''
      }

      if (
        this.addressAutocompleteApiKeySourceValue &&
        this.addressAutocompleteApiKeySourceValue.value === this.addressQuestionApiKeySources.CUSTOM
      ) {
        return this.question.autocompleteApiKey || ''
      }

      return _.find(
        this.credentials,
        {
          id: parseInt(this.question.autocompleteApiKey, 10)
        }
      ) || ''
    },

    addressAutocompleteApiKeys: function () {
      return this.credentials
        .filter((c) => {
          return c.type === credentialTypes.GOOGLE_API_KEY
        })
    },

    addressAutocompleteModesOptions: function () {
      return addressAutocompleteModes || []
    },

    addressAutocompleteModeIds: function () {
      return addressAutocompleteModeIds
    },

    addressAutoCompleteFieldsEdit: {
      get: function () {
        return this.question.autocompleteFieldsEdit
      },
      set: function (value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_FIELDS_EDIT, {
          stepId: this.stepId,
          questionId: this.questionId,
          autocompleteFieldsEdit: value
        })
        setTimeout(() => {
          let dragAddress = document.getElementById('dragAddressField' + this.question.id)
          this.initSortable(dragAddress, this.question)
        }, 500)
      }
    },

    addressAutoCompleteMode: {
      get: function () {
        const mode = _.find(
          addressAutocompleteModes,
          { id: this.question.autocompleteMode || this.addressAutocompleteModeIds.SEARCH }
        )

        return mode
      },
      set: function (value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_MODE, {
          stepId: this.stepId,
          questionId: this.questionId,
          autocompleteMode: value
        })
      }
    },

    // GDPR QUESTION
    isGDPRRequired: function () {
      if (this.questionTypesMap.GDPR !== this.questionType) {
        return false
      }

      let isRequired = false
      for (let choice of this.question.options.choices) {
        isRequired = isRequired || choice.required
      }
      return isRequired
    },
    // NUMBER QUESTION
    enableMinMaxLimit: {
      get() {
        return this.question.enableMinMaxLimit
      },
      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QNUMBER_QMINMAXLIMIT, {
          'enableMinMaxLimit': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },
    // Range QUESTION
    enableCustomText: {
      get() {
        return this.question.enableCustomText
      },
      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QRANGE_CUSTOMTEXT, {
          'enableCustomText': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },
    showHideOrientationScale: {
      get() {
        return this.question.showHideOrientationScale
      },
      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QRANGE_SHORIENTATIONSCALE, {
          'showHideOrientationScale': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },
    enableUnitValues: {
      get() {
        return this.question.enableUnitValues
      },
      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QRANGE_UNITVALUES, {
          'enableUnitValues': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },
    enableStepCount: {
      get() {
        return this.question.enableStepCount
      },
      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QRANGE_STEPCOUNT, {
          'enableStepCount': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
      }
    },
    // PHONE NUMBER QUESTION
    enableDefaultCode: {
      get() {
        return this.question.enableDefaultCode
      },
      set(value) {
        this.$store.dispatch(actionTypes.FORMBUILDER_UPDATE_QPHONE_DEFAULT_COUNTRY_CODE, {
          'enableDefaultCode': value,
          'stepId': this.stepId,
          'questionId': this.questionId
        })
        if (this.enableDefaultCode) {
          this.initialiseIntlInput()
        }
      }
    },
    defaultCountryCode: function () {
      if (this.question.enableDefaultCode) {
        console.log('Question', this.question)
        return this.question.defaultCountryCode
      }
      return null
    },
    // Settings
    credentials: function () {
      return this.$store.getters['setting/credentials']
    },
    hasPhishingContent: function () {
      const delimeters = [/ /, /\(/, /\)/, /\*/, /"/, /'/, /\[/, /\]/, '-', '_']

      for (let word of phishingWords) {
        if (!this.question.title && !this.question.description) {
          continue
        }

        if (this.question.title && this.question.title.toLowerCase().indexOf(word.toLowerCase()) >= 0) {
          return true
        }

        if (this.question.description && this.question.description.toLowerCase().indexOf(word.toLowerCase()) >= 0) {
          return true
        }

        if (this.question.placeholder && this.question.placeholder.toLowerCase().indexOf(word.toLowerCase()) >= 0) {
          return true
        }

        if (this.question.choices) {
          for (let choice of this.question.choices) {
            if (typeof choice === 'object') {
              if (choice.label.toLowerCase().indexOf(word.toLowerCase()) >= 0) {
                return true
              }
            } else {
              if (choice.toLowerCase().indexOf(word.toLowerCase()) >= 0) {
                return true
              }
            }
          }
        }

        for (let delimeter of delimeters) {
          let replacedTitle = this.question.title ? this.question.title.replace(new RegExp(delimeter, 'g'), '') : ''
          if (replacedTitle.toLowerCase().indexOf(word.toLowerCase()) >= 0) {
            return true
          }

          let replacedDescription = this.question.description ? this.question.description.replace(new RegExp(delimeter, 'g'), '') : ''
          if (replacedDescription.toLowerCase().indexOf(word.toLowerCase()) >= 0) {
            return true
          }

          let replacedPlaceholder = this.question.placeholder ? this.question.placeholder.replace(new RegExp(delimeter, 'g'), '') : ''
          if (replacedPlaceholder.toLowerCase().indexOf(word.toLowerCase()) >= 0) {
            return true
          }

          if (this.question.choices) {
            for (let choice of this.question.choices) {
              if (typeof choice === 'object') {
                let replacedChoice = choice && choice.label ? choice.label.replace(new RegExp(delimeter, 'g'), '') : ''

                if (replacedChoice.toLowerCase().indexOf(word.toLowerCase()) >= 0) {
                  return true
                }
              } else {
                let replacedChoice = choice ? choice.replace(new RegExp(delimeter, 'g'), '') : ''

                if (replacedChoice.toLowerCase().indexOf(word.toLowerCase()) >= 0) {
                  return true
                }
              }
            }
          }
        }
      }

      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~intl-tel-input/build/css/intlTelInput.min.css';

.default-code-input {
  // visibility: hidden;
}

.intl-tel-input {
  padding: 5px;
}

.question .choices .choice__icon__selected {
  padding: 0px;
}

.question .choices .choice__icon__selected {
  padding: 0px;
}
</style>
