<template>
  <v-container style="width:80%">
    <h4 class="page-title">External Checkout</h4>
    <v-row>
      <v-col class="text-end mb-n10">
        <v-btn color="primary-red font-weight-bold text-none" @click="$router.push({ name: 'externalcheckout.create' })"
          prepend-icon="mdi-plus">
          External Checkout
        </v-btn>
      </v-col>
    </v-row>
    <div>
      <general-list :fields="fields" :loadedError="loadedError" :loaded="loaded" :listData="listData"
        :listPagination="listPagination" @onListParamsChange="loadExternalCheckout($event)" ref="generalList">
        <template v-slot:['#']="props">
          {{ (props.rowIndex + 1) + (currenPage - 1) * perPage }}
        </template>
        <template v-slot:Title="props">
          <router-link :to="{ name: 'externalcheckout.edit', params: { id: props.rowData.id } }" class="secondary-red">
            {{ props.rowData.title }}
          </router-link>
        </template>
        <template v-slot:Description="props">
          {{ props.rowData.description }}
        </template>
        <template v-slot:Action="props">
          <i @click="openDeleteModal(props.rowData.id)"
            class="material-icons text-primary-red cursor-pointer">delete</i>
        </template>
      </general-list>
    </div>

    <!-- delete  External Checkout modal -->
    <v-dialog v-model="isModalOpen" max-width="600px">
      <v-card max-width="600" prepend-icon="mdi-trash-can-outline">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-card-text>
          <v-alert v-if="isError" border type="warning" variant="tonal" density="compact">
            <p class="text-black ma-0">Unable to delete this checkout. Please try again.</p>
          </v-alert>
          <v-spacer class="my-4"></v-spacer>
          <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">You are about to delete this checkout. Are you sure you
              want to proceed?</p>
          </v-alert>
        </v-card-text>
        <v-card-actions class="mb-3 mr-3">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="isModalOpen = false">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="primary-red" :loading="isLoading"
            @click="deleteExternalCheckout">
            <span>Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import GeneralList from '@/components/lists/GeneralList'
import { mapGetters } from 'vuex'
import config from '@/api/config'

export default {
  data: function () {
    return {
      loaded: false,
      loadedError: false,
      listData: [],
      listPagination: {},
      fields: [
        {
          name: 'Title',
          sortField: 'title',
          searchField: 'title'
        },
        {
          name: 'Description'
        },
        {
          name: 'Action'
        }
      ],
      currenPage: 1,
      perPage: 15,
      isModalOpen: false,
      isLoading: false,
      isError: false,
      selectedExternalCheckout: -1
    }
  },
  components: {
    'general-list': GeneralList
  },
  mounted: function () {
    this.loadExternalCheckout()
  },
  methods: {
    loadExternalCheckout: function (params) {
      if (!params) {
        return
      }
      params.resource = 'external_checkout'
      this.loaded = false
      this.loadedError = false
      this.$store.dispatch('externalcheckoutlists/getExternalCheckoutList', params)
        .then((response) => {
          this.$store.dispatch('externalcheckoutlists/getExternalCheckoutListSuccess', response.data.data)
          this.listData = response.data.data
          this.listPagination = response.data.pagination
          this.loaded = true
          this.loadedError = false
        }, (response) => {
          this.loaded = true
          this.loadedError = true
        })
    },
    openDeleteModal: function (id) {
      this.selectedExternalCheckout = id
      this.isError = false
      this.isModalOpen = true
    },
    deleteExternalCheckout: function () {
      this.isLoading = true
      this.isError = false
      this.$store.dispatch('externalcheckoutlists/deleteExternalCheckout', {
        id: this.selectedExternalCheckout
      }).then((response) => {
        this.isLoading = false
        this.isModalOpen = false
        this.$refs.generalList.reload()
        this.$store.dispatch('externalcheckoutlists/deleteExternalCheckoutSuccess', {
          id: this.selectedExternalCheckout
        })
      }, (response) => {
        this.isLoading = false
        this.isError = true
      })
    },
  },
  computed: {
    ...mapGetters([
      'token'
    ]),
    apiUrl: function () {
      return config.basePath + '/external-checkout'
    },
    externalCheckouts: function () {
      return this.$store.getters['externalcheckout/getExternalCheckouts'] || []
    }
  }
}
</script>
