import Vue from 'vue'
import * as mutationTypes from '@/store/mutation-types'
import * as MediaApi from '@/api/media'
import mediaTypes from '@/store/media-types'
import _ from 'lodash'

const state = {
  visible: false,
  tab: 'images',
  loading: {
    images: false
  },
  selected: [],
  images: {
    data: [],
    pagination: {}
  },
  videos: []
}

const getters = {
  selected: function (state) {
    return state.selected
  },
  types: function () {
    return mediaTypes
  },
  images: function (state) {
    return state.images
  },
  loading: function (state) {
    return state.loading
  },
  tab: function (state) {
    return state.tab
  },
  visible: function (state) {
    return state.visible
  }
}

const actions = {
  setVisibility({ commit }, data) {
    commit(mutationTypes.MM_SET_VISIBILITY, data)
  },

  changeTab({ commit }, data) {
    commit(mutationTypes.MM_CHANGE_TAB, data)
  },

  fetchMedia({ commit }, data) {
    if (data.mediaType === mediaTypes.IMAGE) {
      commit(mutationTypes.MM_FETCH_IMAGES)
      return MediaApi.fetchMedia(mediaTypes.IMAGE, data)
    }
  },

  fetchMediaSuccess({ commit }, data) {
    if (data.mediaType === mediaTypes.IMAGE) {
      commit(mutationTypes.MM_FETCH_IMAGES_SUCCESS, data.images)
    }
  },

  fetchMediaError({ commit }, data) {
    if (data.mediaType === mediaTypes.IMAGE) {
      commit(mutationTypes.MM_FETCH_IMAGES_ERROR)
    }
  },

  loadingMedia({ commit }, data) {
    if (data.mediaType === mediaTypes.IMAGE) {
      commit(mutationTypes.MM_LOADING_IMAGES, data)
    }
  },

  resetMedia({ commit }, data) {
    if (data.mediaType === mediaTypes.IMAGE) {
      commit(mutationTypes.MM_RESET_IMAGES)
    }
  },

  selectMedia({ commit }, data) {
    commit(mutationTypes.MM_SELECT_MEDIA, data)
  },

  deleteMedia({ commit }, data) {
    return MediaApi.deleteMedia(data.id)
  },

  uploadMedia({ state, commit }, data) {
    commit(mutationTypes.MM_UPLOAD_MEDIA)
    return MediaApi.uploadMedia(data.formData, data.progress)
  },

  uploadMediaSuccess({ state, commit }) {
    commit(mutationTypes.MM_UPLOAD_MEDIA_SUCCESS)
  },

  uploadMediaError({ state, commit }) {
    commit(mutationTypes.MM_UPLOAD_MEDIA_ERROR)
  }
}

const mutations = {
  [mutationTypes.MM_SET_VISIBILITY](state, data) {
    state.visible = data.visible
    console.log(`${state.visible}: ${JSON.stringify(data)}`);
  },

  [mutationTypes.MM_CHANGE_TAB](state, data) {
    state.tab = data.tab
  },

  [mutationTypes.MM_FETCH_IMAGES](state) {
    state.fetchingImages = true
  },

  [mutationTypes.MM_FETCH_IMAGES_SUCCESS](state, data) {
    state.fetchingImages = false
    let images = data.images || []
    let pagination = data.pagination || {}

    if (data.pagination.current_page > 1) {
      images = [...state.images.data, ...images]
      images = _.uniqBy(images, 'id')
    }

    state.images.data = images
    state.images.pagination = pagination
  },

  [mutationTypes.MM_FETCH_IMAGES_ERROR](state) {
    state.images = {
      data: [],
      pagination: {}
    }
    state.fetchingImages = false
  },

  [mutationTypes.MM_RESET_IMAGES](state, data) {
    state.images = {
      data: [],
      pagination: {}
    }
  },

  [mutationTypes.MM_LOADING_IMAGES](state, data) {
    state.loading.images = data.loading
  },

  [mutationTypes.MM_SELECT_MEDIA](state, data) {
    state.selected = data.selected
  },

  [mutationTypes.MM_UPLOAD_MEDIA](state) {
  },

  [mutationTypes.MM_UPLOAD_MEDIA_SUCCESS](state) {
  },

  [mutationTypes.MM_UPLOAD_MEDIA_ERROR](state) {
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
