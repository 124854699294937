<template>
  <v-row class="fill-height login-view" align="center" justify="center" v-if="screen.login">
    <v-col cols="12">
      <div class="create-account-wrapper">
        <a class="text-primary-red create-account" @click="this.$router.push({ name: 'registerFreeTrial' })">Create New
          Account
          Here</a>
      </div>
      <v-card flat class="mx-auto my-5" max-width="450">
        <v-card-title class="text-center d-flex flex-column align-center">
          <v-img :width="80" aspect-ratio="16/9" cover :src="require('@/assets/images/leadgen-leaf-logo.png')"></v-img>
          <h4 class="font-weight-bold my-5">Login</h4>
        </v-card-title>
        <v-card-text>
          <!-- Social Signup -->
          <v-row dense>
            <v-col cols="12">
              <!-- Google Button -->
              <v-btn block color="#4285F4" height="45" class="google-btn" @click="loginWithGoogle"
                :loading="loader.login">
                <v-avatar size="43" color="white" class="google-icon-wrapper pa-2">
                  <v-img :width="100" aspect-ratio="1/1" contain
                    :src="require('@/assets/images/views/login/google-logo.png')"></v-img>
                </v-avatar>
                <span class="font-weight-bold text-none mb-0">Login with Google</span>
              </v-btn>
              <v-divider class="my-10 font-weight-bold" thickness="2" color="blue-grey">
                <span class="font-weight-bold text-blue-grey">OR</span>
              </v-divider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-alert v-if="alert.show" border :type="alert.error ? 'error' : 'warning'" variant="tonal" rounded="md"
                density="compact" class="text-left mb-8" icon="mdi-alert-circle">
                <span class="alert mb-0">{{ alert.message }}</span>
                <span v-if="alert.flag === errorTypes.UNVERIFIED_ACCOUNT" class="alert">&nbsp;or
                  <a @click="$router.push({ name: 'verifyEmailForm' })" class="alert-link text-primary-red">Resend
                    Verification Mail</a>
                </span>
                <span v-if="alert.flag === errorTypes.SIGNUP_INCOMPLETE" class="alert">
                  <a @click="sendToRegisterStep()"
                    class="alert-link text-primary-red">&nbsp;app.leadgenapp.io/register</a>
                </span>
              </v-alert>

              <v-spacer class="my-2"></v-spacer>

              <v-form ref="loginForm">
                <v-text-field v-model="emailLogIn.email" label="Enter your email address"
                  placeholder="Enter your email address" prepend-inner-icon="mdi-email-outline" :rules="$valid([
                    { rule: 'required', msg: 'Please enter your email address' },
                    { rule: 'email' }
                  ])" variant="outlined" density="comfortable" rounded="md"></v-text-field>

                <v-spacer class="my-1"></v-spacer>

                <v-text-field v-model="emailLogIn.password" label="Enter your password"
                  placeholder="Enter your current password" prepend-inner-icon="mdi-lock-outline"
                  :append-inner-icon="emailLogIn.togglePassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append-inner="emailLogIn.togglePassword = !emailLogIn.togglePassword"
                  :type="emailLogIn.togglePassword ? 'text' : 'password'" :rules="$valid([
                    { rule: 'required', msg: 'Please enter your password' }
                  ])" variant="outlined" density="comfortable" rounded="md"></v-text-field>

                <v-row>
                  <v-col cols="6">
                    <v-checkbox v-model="emailLogIn.remember" label="Remember me" hide-details
                      class="text-blue-grey-darken-1 checkbox" density="comfortable" color="primary-green"
                      base-color="blue-grey-darken-1"></v-checkbox>
                  </v-col>
                  <v-col cols="6" class="text-right">
                    <v-btn color="primary-red" rounded="md" height="42" width="500"
                      class="text-none font-weight-bold mb-0 login-btn" append-icon="mdi-login" @click="loginAccount"
                      :loading="loader.login">Login</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col cols="12" class="text-center mt-4">
              <a @click="$router.push({ name: 'forgot-password-form' })"
                class="text-decoration-none text-primary-red forgot-password">Forgot Password</a>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row class="fill-height two-factor-view" align="center" justify="center" v-else>
    <TwoFactor :twoFactorData="twoFactorData" :loginSuccess="loginSuccess" />
  </v-row>
</template>

<script>
import jwtDecode from 'jwt-decode'
import * as actionTypes from '@/store/action-types.js'
import errorTypes from '@/store/error-types'
import authMixin from '@/mixins/auth'
import planMixin from '@/mixins/plan'
import TwoFactor from '../components/authentication/TwoFactor.vue'

export default {
  mixins: [authMixin, planMixin],
  components: {
    TwoFactor
  },
  data() {
    return {
      screen: {
        login: true,
        twoFactor: false,
        registerRoute: null,
        loginData: null
      },
      emailLogIn: {
        email: '',
        password: '',
        togglePassword: false,
        remember: true
      },
      twoFactorData: {
        email: null,
        password: null
      },
      alert: {
        show: false,
        error: true,
        message: null,
        flag: null
      },
      loader: {
        login: false
      }
    }
  },
  computed: {
    errorTypes() {
      return errorTypes
    }
  },
  methods: {
    loginAccount() {
      this.loader.login = true
      this.alert.show = false

      // Validate the login form
      this.$refs.loginForm.validate().then(({ valid }) => {
        if (!valid) {
          this.loader.login = false
          return
        }

        // Dispatch the login action
        this.$store.dispatch(actionTypes.LOGIN_USER, {
          email: this.emailLogIn.email,
          password: this.emailLogIn.password
        })
          .then(response => {
            console.log('login SUCCESS', response)

            // Ensure response contains the necessary data
            this.screen.loginData = response?.data?.data
            this.loginSuccess(this.screen.loginData) // Call success handler
            this.loader.login = false // Stop loader on success
          })
          .catch(err => {
            console.log('login ERROR', err)

            // Handle error response safely
            const responseData = err?.response?.data
            const meta = responseData?.meta || {}

            // Set loginData for failure case if exists
            this.screen.loginData = responseData?.data
            this.$store.dispatch(actionTypes.LOGIN_FAILED)
            this.loader.login = false

            // Handle incomplete two-factor authentication
            if (meta.error_type === errorTypes.INCOMPLETE_TWO_FACTOR) {
              this.twoFactorData = {
                email: this.emailLogIn.email,
                password: this.emailLogIn.password
              }
              this.screen.login = false
              this.screen.twoFactor = true
            } else {
              // Show error alert
              this.alert = {
                show: true,
                error: true,
                message: meta.error_message || 'Something went wrong! Please try again later.',
                flag: meta.error_type || null
              }
            }
          })
      })
    },
    loginWithGoogle() {
      this.loader.login = true
      this.alert.show = false
      this.authMixin_loginOrSignUpWithProvider(this.socialSignUpOrLogin.providerGoogle, true)
    },
    socialAccountLogin(provider, data) {
      this.$store.dispatch('socialauth/socialLogIn', {
        provider: provider,
        data: data })
        .then(response => {
          console.log('socialAccountLogin Response***************', response)
          this.screen.loginData = response?.data?.data
          this.loginSuccess(this.screen.loginData)
        })
        .catch(err => {
          console.log('socialAccountLogin Error ***************', err)
          this.authMixin_resetSocialSignUpOrLogin()
          this.screen.loginData = err?.response?.data?.data
          this.loader.login = false
          this.alert = {
            show: true,
            error: true,
            message: err.response.data?.meta?.error_message || 'Something went wrong during social login. Please try again later.',
            flag: err?.response?.data?.meta?.error_type
          }
        })
    },
    loginSuccess(data) {
      this.loader.login = false
      localStorage.setItem('token', data.token)
      localStorage.setItem('decodedToken', jwtDecode(data.token))
      localStorage.setItem('user', JSON.stringify(data.user))
      this.fetchPlans(data.user)

      this.$store.dispatch(actionTypes.LOGIN_SUCCESS, {
        token: data.token,
        user: data.user,
        decodedToken: jwtDecode(data.token)
      })

      const { user } = data
      const privateUrl = JSON.parse(localStorage.getItem('private_url'))

      if (user.show_onboarding) {
        localStorage.removeItem('private_url')
        this.$router.push({ name: 'industry-onboard' })
      } else if (privateUrl) {
        localStorage.removeItem('private_url')
        this.$router.push(privateUrl)
      } else {
        this.authMixin_resetSocialSignUpOrLogin()
        this.$router.push({ name: 'dashboard' })
      }
    },
    fetchPlans(user) {
      this.$store.dispatch(actionTypes.FETCH_PLANS).then((response) => {
        const plans = response.data.data || []
        localStorage.setItem('plans', JSON.stringify(plans))
        this.$store.dispatch(actionTypes.FETCH_PLANS_SUCCESS, plans)
      })
    },
    sendToRegisterStep() {
      const { registerPageRoute } = this.getRegisterPage(this.screen.loginData)
      console.log('Data being sent:', this.screen.loginData)
      const stringifiedData = JSON.stringify(this.screen.loginData)
      console.log('Stringified data:', stringifiedData)

      // Store the data in sessionStorage
      sessionStorage.setItem('incompleteSignUp', stringifiedData)

      this.$router.push({ name: registerPageRoute }).then(() => {
        console.log('Navigation successful')
      }).catch(error => {
        console.error('Navigation error:', error)
      })
    },
    getRegisterPage(data) {
      console.log('data-----', data)
      const { signUpPlan } = data
      return signUpPlan
        ? this.planMixin_getDetails(signUpPlan.public_id)
        : this.planMixin_getDefaultRoute()
    }
  }
}
</script>
