<template>
  <div class="main-front-container">
    <div class="front-header" v-if="!isLeadgenFormView()">
      <router-link id="logo" to="/">
        <img src="./../../assets/images/Logo_with_gradient.svg" alt="Leadgen">
      </router-link>
    </div>
    <div class="front-content">
      <router-view></router-view>
    </div>
    <div class="front-footer">
      <!-- <p>
        <a href="http://www.apexure.com/" title="Apexure" target="_blank">Powered by Apexure</a>
      </p> -->
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo'
export default {
  components: {
    'logo': Logo
  },
  methods: {
    getCurrentYear: function () {
      let date = new Date()
      return date.getFullYear()
    },

    isLeadgenFormView: function () {
      return this.$route.name === 'leadgenform.shared' || this.$route.name === 'leadgenform'
    }
  }
}
</script>
