import ExperimentState from '@/store/experiment-state'
import store from '@/store/store'
import * as actionTypes from '@/store/action-types'
import roles from '@/store/roles'
import oneToolPlanTypes from '@/store/onetool-plan-types'
import _ from 'lodash'
import signupSources from '@/store/signup-sources'

export default {
  // App
  isTokenExpired: state => {
    if (!state.decodedToken) {
      return true
    }
    return Math.floor(Date.now() / 1000) >= state.decodedToken.exp
  },
  isLoggedIn: (state, getters) => {
    return state.token && !getters.isTokenExpired
  },
  user: (state, getters) => {
    if (state.user) {
      let subscription = state.user.plan.subscription
      if (subscription) {
        let d1 = new Date(subscription.current_period_end * 1000)
        let d2 = new Date()
        if (d2 >= d1) {
          state.user.plan = this.freePlan
          store.dispatch(actionTypes.CHANGE_USER_PLAN_SUCCESS, { plan: getters.freePlan })
        }
      }
    }
    return state.user
  },
  userPlan: (state, getters) => {
    return getters.user ? getters.user.plan : null
  },
  userPlanSubscription: (state, getters) => {
    if (getters.user && getters.userPlan) {
      return getters.userPlan.subscription
    }
    return null
  },
  token: (state) => {
    return state.token
  },
  isAdmin: (state) => {
    if (!state.user) {
      return false
    }
    return (state.user.roles || []).filter((role) => {
      return role.name === roles.ADMIN
    }).length > 0
  },
  isSuperCustomer: (state) => {
    if (!state.user) {
      return false
    }
    return state.user.roles.filter((role) => {
      return role.name === roles.SUPER_CUSTOMER
    }).length > 0
  },
  isCustomer: (state) => {
    if (!state.user) {
      return false
    }
    return state.user.roles.filter((role) => {
      return role.name === roles.CUSTOMER
    }).length > 0
  },
  hasProPlan: (state) => {
    if (!state.user) {
      return 'free'
    }
    return state.user.plan.public_id === 'pro'
  },
  hasOneToolProPlan: (state) => {
    if (!state.user) {
      return 'free'
    }
    return state.user.plan.public_id === oneToolPlanTypes.PRO_LITE
  },
  isOneToolUser: (state) => {
    if (!state.user) {
      return signupSources.WEBAPP
    }

    return state.user.source === signupSources.ONETOOL
  },
  /* Entity */
  getEntity: (state) => (id, type) => {
    return _.find(state[type], { id: id })
  },
  /* Form builder */
  formBuilderState: (state) => {
    return state.formBuilderState
  },
  currentFormId: (state) => {
    return state.formBuildState.form_id
  },
  currentFormKey: (state) => {
    return state.formBuildState.key
  },
  validateForm: (state) => {
    return state.formBuildState.validate
  },
  getStep: (state, getters) => (id) => {
    for (let step of state.formBuildState.steps) {
      if (step.id === id) {
        return step
      }
    }
    return null
  },
  getSteps: (state, getters) => () => {
    return state.formBuildState.steps
  },
  getQuestion: (state, getters) => (stepId, questionId) => {
    let step = getters.getStep(stepId)
    for (let question of step.questions) {
      if (question.id === questionId) {
        return question
      }
    }
    return null
  },
  getQuestionTypes: (state, getters) => {
    return state.formBuildState.qt || {}
  },
  getQuestionbyId: (state, getters) => (questionId) => {
    let steps = state.formBuildState.steps
    for (let step of steps) {
      for (let question of step.questions) {
        if (question?.id === questionId) {
          return question
        }
      }
    }
  },
  getStepIndex: (state) => (stepId) => {
    let sIndex = 0
    for (let step of state.formBuildState.steps) {
      if (step.id === stepId) {
        return sIndex
      }
      sIndex++
    }
  },
  hasFirstNameTypeQuestion: (state) => {
    for (let step of state.formBuildState.steps) {
      for (let question of step.questions) {
        if (question.type === state.questionTypesMap.FIRST_NAME) {
          return true
        }
      }
    }
    return false
  },
  hasLastNameTypeQuestion: (state) => {
    for (let step of state.formBuildState.steps) {
      for (let question of step.questions) {
        if (question.type === state.questionTypesMap.LAST_NAME) {
          return true
        }
      }
    }
    return false
  },
  hasGdprTypeQuestion: (state) => {
    for (let step of state.formBuildState.steps) {
      for (let question of step.questions) {
        if (question.type === state.questionTypesMap.GDPR) {
          return true
        }
      }
    }
    return false
  },
  getFormBuilderFieldNames: (state) => {
    let fieldNames = []

    if (!state.formBuildState.steps) {
      return fieldNames
    }

    for (let step of state.formBuildState.steps) {
      if (!step.questions) {
        continue
      }

      for (let question of step.questions) {
        if (question.field_name) {
          fieldNames.push(question.field_name)
        }
      }
    }

    if (state.formBuildState.calculator.fieldName) {
      fieldNames.push(state.formBuildState.calculator.fieldName)
    }

    return fieldNames
  },

  /* form */
  forms: (state, getters) => {
    return state.forms
  },

  getForm: (state, getters) => (formId) => {
    for (let form of state.forms) {
      if (form.id === formId) {
        return form
      }
    }
    return {}
  },
  formPreview: (state) => {
    return state.formPreview
  },
  formLeads: (state) => {
    return state.formLeads
  },
  defaultFormVariantTheme: (state) => {
    // if (state.formVariantThemes.length && state.formVariantThemes[0].id >= 1 && state.formBuildState.id != null) {
    //   return JSON.parse(JSON.stringify(state.formVariantThemes[0]))
    // } else {
    return JSON.parse(JSON.stringify(state.defaultFormVariantTheme))
    // }
  },
  formSetting: (state) => {
    return state.formSetting
  },
  leadsCount: (state) => {
    return state.leadsCount
  },
  formsCount: (state) => {
    return state.formsCount
  },
  averageConversionCount: (state) => {
    return state.averageConversionCount
  },
  questionTypesMap: (state) => {
    return state.questionTypesMap
  },
  operators: (state) => {
    return state.operators
  },
  formStepElementTypes: (state) => {
    return state.formStepElementTypes
  },
  /* form variants */
  getFormVariants: (state, getters) => (formId) => {
    let variants = []
    for (let variant of state.formVariants) {
      if (variant.form_id === formId) {
        variants.push(variant)
      }
    }
    return variants
  },

  getFormVariant: (state, getters) => (variantId) => {
    for (let variant of state.formVariants) {
      if (variant.id === variantId) {
        return variant
      }
    }
    return {}
  },

  /* form experiments */
  formExperiments: (state, getters) => (formId) => {
    let experiments = []
    for (let experiment of state.formExperiments) {
      if (experiment.form_id === formId) {
        experiments.push(experiment)
      }
    }
    return experiments
  },

  formExperiment: (state, getters) => (experimentId) => {
    let experiments = state.formExperiments
    for (let experiment of experiments) {
      if (experiment.id === experimentId) {
        return experiment
      }
    }
    return null
  },

  formExperimentState: (state, getters) => (experimentId) => {
    let experiment = getters.formExperiment(experimentId) || {}
    if (experiment.started_at && experiment.ended_at) {
      return ExperimentState.ENDED
    } else if (experiment.started_at && !experiment.ended_at) {
      return ExperimentState.RUNNING
    }
    return ExperimentState.DRAFT
  },

  runningExperiments: (state, getters) => (formId) => {
    let experiments = []
    for (let experiment of state.formExperiments) {
      if (experiment.form_id !== formId) {
        continue
      }
      if (experiment.started_at && !experiment.ended_at) {
        experiments.push(experiment)
      }
    }
    return experiments
  },

  draftExperiments: (state, getters) => (formId) => {
    let experiments = []
    for (let experiment of state.formExperiments) {
      if (experiment.form_id !== formId) {
        continue
      }
      if (!experiment.started_at && !experiment.ended_at) {
        experiments.push(experiment)
      }
    }
    return experiments
  },

  endedExperiments: (state, getters) => (formId) => {
    let experiments = []
    for (let experiment of state.formExperiments) {
      if (experiment.form_id !== formId) {
        continue
      }
      if (experiment.started_at && experiment.ended_at) {
        experiments.push(experiment)
      }
    }
    return experiments
  },

  formExperimentResults: (state, getters) => {
    return state.formExperimentResults || []
  },

  getFormExperimentResult: (state, getters) => (experimentId) => {
    for (let experimentResult of getters.formExperimentResults) {
      if (experimentResult.id === experimentId) {
        return experimentResult
      }
    }
    return {}
  },

  // plan
  planTypes: (state) => {
    return state.planTypes
  },
  freePlan: (state) => {
    for (let plan of state.plans) {
      if (plan.public_id === state.planTypes.FREE) {
        return plan
      }
    }
    return null
  },
  proPlan: (state) => {
    for (let plan of state.plans) {
      if (plan.public_id === state.planTypes.PRO) {
        return plan
      }
    }
    return null
  },

  /* form hidden fields */
  formHiddenFields: (state) => (formId, variantId) => {
    let hiddenFields = []
    for (let hf of state.formHiddenFields) {
      if (hf.form_id === formId && hf.form_variant_id === variantId) {
        hiddenFields.push(hf)
      }
    }
    return hiddenFields
  },

  /* user settings */
  userSetting: (state) => {
    return state.userSettings
  },
  // extra
  formArchived: (state) => state.formArchived,
  mimeTypes: (state) => state.mimeTypes,
  httpVerbs: (state) => state.httpVerbs,
  themeDefaults: (state) => state.defaultFormVariantTheme,
  rolesMap: (state) => state.rolesMap
}
