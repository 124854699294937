<template>
  <v-container style="width: 80%;">
    <h4 class="page-title">LeadProof</h4>
    <v-row v-if="!showLimitExceededAlert">
      <v-col class="text-end mb-n10">
        <v-btn color="primary-red font-weight-bold text-none" @click="$router.push({ name: 'leadproofs.create' })"
          prepend-icon="mdi-plus">
          Create LeadProof
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="showLimitExceededAlert">
      <v-col>
        <v-alert type="warning" :dismissible="false">
          Your limit for creating Lead Proofs has exceeded. Please
          <router-link :to="{ name: 'plans.index' }" title="Plans">Click here</router-link> to upgrade your account.
        </v-alert>
      </v-col>
    </v-row>
    <div class="row">
      <general-list :fields="fields" :loadedError="list.loadError" :loaded="list.loading" ref="generalList"
        :listData="listData" :listPagination="listPagination" @onListParamsChange="loadLeadProof($event)">
        <template v-slot:['#']="props">
          {{ (props.rowIndex + 1) + (currenPage - 1) * perPage }}
        </template>
        <template v-slot:Title="props">
          <router-link :to="{ name: 'leadproofs.edit', params: { id: props.rowData.id } }" class="secondary-red">
            {{ props.rowData.title }}
          </router-link>
        </template>
        <template v-slot:Form="props">
          {{ props.rowData.form.title }}
        </template>
        <template v-slot:Variant="props">
          {{ props.rowData.form_variant.title }}
        </template>
        <template v-slot:Question="props">
          {{ props.rowData.form_question.config.title }}
        </template>
        <template v-slot:Action="props">
          <i @click="openDeleteLeadProofModal(props.rowData.id)"
            class="material-icons text-primary-red cursor-pointer">delete</i>
        </template>
      </general-list>
    </div>
    <!-- delete lead proof modal -->
    <v-dialog v-model="deleteDialog" max-width="600px">
      <v-card max-width="600" prepend-icon="mdi-trash-can-outline">
        <template v-slot:title>
          <strong>Are you sure?</strong>
        </template>
        <v-card-text>
          <v-alert v-if="list.deleteError" border type="warning" variant="tonal" density="compact">
            <p class="text-black ma-0">Unable to delete this lead proof. Please try again.</p>
          </v-alert>
          <v-spacer class="my-4"></v-spacer>
          <v-alert border type="error" variant="tonal" density="compact" icon="mdi-information">
            <p class="text-black ma-0">You are about to delete this proof. Are you sure you
              want to proceed?</p>
          </v-alert>
        </v-card-text>
        <v-card-actions class="mb-3 mr-3">
          <v-btn rounded="md" variant="flat" class="text-none font-weight-bold" color="grey-lighten-3" text="Cancel"
            @click="closeDeleteModal">
          </v-btn>
          <v-btn rounded="md" variant="flat" class="font-weight-bold text-none" color="primary-red"
            :loading="list.deleting" @click="deleteLeadProof">
            <span>Delete</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import GeneralList from '@/components/lists/GeneralList'
import { mapGetters } from 'vuex'
import config from '@/api/config'
import userMixin from '@/mixins/user'
import { featureSlugs } from '@/shared/constants/packagebuilder/features'
import { featureProperties } from '@/shared/constants/packagebuilder/featureProperties'
import operators from '@/shared/constants/comparisonOperators'
import { resourceTypes } from '@/shared/constants/generalList/list'


export default {
  mixins: [userMixin],
  data() {
    return {
      deleteDialog: false,
      list: {
        loading: false,
        loadError: false,
        deleting: false,
        deleteError: false
      },
      listCount: [],
      listData: [],
      listPagination: {},
      fields: [
        {
          name: 'Title',
          sortField: 'title',
          searchField: 'title'
        },
        {
          name: 'Form'
        },
        {
          name: 'Variant'
        },
        {
          name: 'Question'
        },
        {
          name: 'Action'
        }
      ],
      currentPage: 1,
      perPage: 15,
      selectedLeadProof: -1,
      featureSlug: null,
      showLimitExceededAlert: false
    }
  },
  components: {
    'general-list': GeneralList
  },
  mounted: function () {
    this.featureSlug = featureSlugs.LEAD_PROOFS
    this.loadLeadProof()
    setTimeout(() => {
      this.canCreateLeadProof()
    }, 1000)
  },
  methods: {
    loadLeadProof: function (params) {
      if (!params) {
        return
      }
      params.resource = resourceTypes.LEAD_PROOF
      this.list.loading = false
      this.list.loadError = false
      this.$store.dispatch('leadprooflists/getLeadProofList', params)
        .then((response) => {
          this.$store.dispatch('leadprooflists/getLeadProofListSuccess', response.data.data)
          this.listData = response.data.data
          this.listPagination = response.data.pagination
          this.list.loading = true
          this.list.loadError = false
          this.leadProofsCount()
        }, (response) => {
          this.list.loading = true
          this.list.loadError = true
        })
    },
    openDeleteLeadProofModal(id) {
      this.selectedLeadProof = id
      this.deleteDialog = true
    },
    closeDeleteModal() {
      this.deleteDialog = false
    },
    deleteLeadProof() {
      this.list.deleting = true
      this.list.deleteError = false
      this.$store.dispatch('leadprooflists/deleteLeadProof', {
        id: this.selectedLeadProof
      }).then((response) => {
        this.list.deleting = false
        this.closeDeleteModal()
        this.$store.dispatch('leadprooflists/deleteLeadProofSuccess', {
          id: this.selectedLeadProof
        })
        this.$refs.generalList.reload()
      }, (response) => {
        this.list.deleting = false
        this.list.deleteError = true
      })
    },
    leadProofsCount: function () {
      this.$store.dispatch('leadprooflists/fetchLeadProofCount')
        .then((response) => {
          this.listCount = response.data.data
          setTimeout(() => {
            this.canCreateLeadProof()
          }, 1000)
        }, () => { })
    },
    openModal: function (ref) {
      this.$refs[ref].open()
    },
    closeModal: function (ref) {
      this.$refs[ref].close()
    },
    async canCreateLeadProof() {
      if (!this.userMixin_hasFeatureAccess(this.featureSlug)) {
        return false
      }

      let operator = operators.AND
      let inputArray = []

      inputArray.push({
        'limitation_title': featureProperties.NO_OF_LEAD_PROOFS,
        'limitation_value': this.listCount.NONE, // over all count
        'limitation_valueMonthly': this.listCount.MONTHLY, // monthly count
        'limitation_valueYearly': this.listCount.YEARLY, // yearly count
        'limitation_valueAsPerPlan': this.listCount.AS_PER_PLAN, // as per plan
        'compare': operators.LT
      })

      let canCreate = await this.userMixin_hasFeaturePropertyAccess(this.featureSlug, operator, inputArray)
      this.showLimitExceededAlert = !canCreate
    }
  },
  computed: {
    ...mapGetters([
      'token'
    ]),
    apiUrl: function () {
      return config.basePath + '/lead-proofs'
    }
  }
}
</script>
