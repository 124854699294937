import * as mTypes from '@/store/mutation-types'
import * as types from '@/store/action-types'

import * as Auth from '@/api/auth'
import * as FormApi from '@/api/form'
import * as LeadApi from '@/api/lead'
import * as UserApi from '@/api/user'
import * as FormExperimentApi from '@/api/form-experiment'
import * as FormVariantApi from '@/api/form-variant'
import * as FormExperimentVariantApi from '@/api/form-experiment-variant'
import * as FormVisitApi from '@/api/form-visit'
import * as PlanApi from '@/api/plan'
import * as StripeApi from '@/api/stripe'
import * as FormHiddenFieldsApi from '@/api/form-hidden-fields'
import * as FormVariantTemplate from '@/api/form-theme-template'
import * as LeadProofApi from '@/api/lead-proofs'
import * as UtmParamsApi from '@/api/utm-params'

import Vue from 'vue'

export default {
  [types.INIT_APP]: (context, data) => {
    context.commit(mTypes.INIT_APP, data)
  },

  [types.INSERT_ENTITY]: (context, data) => {
    context.commit(mTypes.INSERT_ENTITY, data)
  },

  [types.UPDATE_ENTITY]: (context, data) => {
    context.commit(mTypes.UPDATE_ENTITY, data)
  },

  [types.FORMBUILDER_RESET]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_RESET, data)
  },

  [types.FETCH_FORMS_BY_USER]: (context, data) => {
    context.commit(mTypes.FETCH_FORMS_BY_USER)
    return FormApi.getByUser(data.currentPage)
  },

  [types.FETCH_FORM_BY_USER]: (context, data) => {
    return FormApi.getFormByUser(data.id)
  },

  [types.FORMBUILDER_CREATE_FORM]: (context) => {
    context.commit(mTypes.FORMBUILDER_CREATE_FORM)
    return FormApi.create(context.state.formBuildState)
  },

  [types.FORMBUILDER_CREATE_FORM_VARIANT]: (context, data) => {
    return FormVariantApi.createVariant(
      data.formId,
      context.state.formBuildState
    )
  },

  [types.FORMBUILDER_CREATE_FORM_VARIANT_SUCCESS]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_CREATE_FORM_VARIANT_SUCCESS)
  },

  [types.FORMBUILDER_CREATE_FORM_VARIANT_ERROR]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_CREATE_FORM_VARIANT_ERROR)
  },

  [types.FORMBUILDER_UPDATE_FORM]: (context) => {
    return FormVariantApi.updateVariantState(
      context.state.formBuildState.form_id,
      context.state.formBuildState.id,
      context.state.formBuildState
    )
  },

  [types.FORMBUILDER_UPDATE_ID]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_ID, data)
  },

  [types.FORMBUILDER_ADD_STEP]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_ADD_STEP, data)
  },

  [types.FORMBUILDER_DELETE_STEP]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_DELETE_STEP, data)
  },

  [types.FORMBUILDER_ADD_QUESTION]: (context, data) => {
    // data.step = context.getters.getStep(data.question.stepId)
    // data.step.questions.push(data.question)
    context.commit(mTypes.FORMBUILDER_ADD_QUESTION, data)
  },
  [types.FORMBUILDER_DUPLICATE_QUESTION]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_DUPLICATE_QUESTION, data)
  },
  [types.FORMBUILDER_UPDATE_FORMTITLE]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_FORMTITLE, data)
  },
  [types.FORMBUILDER_UPDATE_CHOICE_FORMULA]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_CHOICE_FORMULA, data)
  },
  [types.FORMBUILDER_UPDATE_STEPQ]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_STEPQ, data)
  },

  [types.FORMBUILDER_UPDATE_STEPS]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_STEPS, data)
  },

  [types.FORMBUILDER_UPDATE_STEP_JUMP]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_STEP_JUMP, data)
  },

  [types.FORMBUILDER_UPDATE_STEP_AN]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_STEP_AN, data)
  },

  [types.FORMBUILDER_ADD_QT]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_ADD_QT, data)
  },

  [types.FORMBUILDER_UPDATE_QTYPE]: (context, data) => {
    var question = context
      .getters
      .getQuestion(data.stepId, data.questionId)
    question.type = data.type
    context.commit(mTypes.FORMBUILDER_UPDATE_QTYPE, data)
  },

  [types.FORMBUILDER_UPDATE_QTITLE]: (context, data) => {
    context
      .getters
      .getQuestion(data.stepId, data.questionId).title = data.title
    context.commit(mTypes.FORMBUILDER_UPDATE_QTITLE, data)
  },

  [types.FORMBUILDER_UPDATE_QTITLE_HIDE]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QTITLE_HIDE, data)
  },

  [types.FORMBUILDER_UPDATE_QDESCRIPTION]: (context, data) => {
    context
      .getters
      .getQuestion(data.stepId, data.questionId).description = data.description
    context.commit(mTypes.FORMBUILDER_UPDATE_QDESCRIPTION, data)
  },

  [types.FORMBUILDER_UPDATE_QFIELDNAME]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QFIELDNAME, data)
  },

  [types.FORMBUILDER_UPDATE_QPLACEHOLDER]: (context, data) => {
    context
      .getters
      .getQuestion(data.stepId, data.questionId).placeholder = data.placeholder
    context.commit(mTypes.FORMBUILDER_UPDATE_QPLACEHOLDER, data)
  },
  [types.FORMBUILDER_UPDATE_QDATE_PLACEHOLDERDAY]: (context, data) => {
    let question = context
      .getters
      .getQuestion(data.stepId, data.questionId)
    question['placeholderDay'] = data.placeholderDay
    context.commit(mTypes.FORMBUILDER_UPDATE_QDATE_PLACEHOLDERDAY, data)
  },
  [types.FORMBUILDER_UPDATE_QDATE_PLACEHOLDERMONTH]: (context, data) => {
    let question = context
      .getters
      .getQuestion(data.stepId, data.questionId)
    question['placeholderMonth'] = data.placeholderMonth
    context.commit(mTypes.FORMBUILDER_UPDATE_QDATE_PLACEHOLDERMONTH, data)
  },
  [types.FORMBUILDER_UPDATE_QDATE_PLACEHOLDERYEAR]: (context, data) => {
    let question = context
      .getters
      .getQuestion(data.stepId, data.questionId)
    question['placeholderYear'] = data.placeholderYear
    context.commit(mTypes.FORMBUILDER_UPDATE_QDATE_PLACEHOLDERYEAR, data)
  },

  [types.FORMBUILDER_UPDATE_QMINDATE]: (context, data) => {
    let question = context
      .getters
      .getQuestion(data.stepId, data.questionId)
    question['minDate'] = data.minDate
    context.commit(mTypes.FORMBUILDER_UPDATE_QMINDATE, data)
  },

  [types.FORMBUILDER_UPDATE_QMAXDATE]: (context, data) => {
    let question = context
      .getters
      .getQuestion(data.stepId, data.questionId)
    question['maxDate'] = data.maxDate
    context.commit(mTypes.FORMBUILDER_UPDATE_QMAXDATE, data)
  },
  [types.FORMBUILDER_UPDATE_QMINNUMBER]: (context, data) => {
    let question = context
      .getters
      .getQuestion(data.stepId, data.questionId)
    question['minNumber'] = data.minNumber
    context.commit(mTypes.FORMBUILDER_UPDATE_QMINNUMBER, data)
  },
  [types.FORMBUILDER_UPDATE_QMAXNUMBER]: (context, data) => {
    let question = context
      .getters
      .getQuestion(data.stepId, data.questionId)
    question['maxNumber'] = data.maxNumber
    context.commit(mTypes.FORMBUILDER_UPDATE_QMAXNUMBER, data)
  },

  [types.FORMBUILDER_UPDATE_QMINCHOICES]: (context, data) => {
    let question = context
      .getters
      .getQuestion(data.stepId, data.questionId)
    question['minChoices'] = data.minChoices
    context.commit(mTypes.FORMBUILDER_UPDATE_QMINCHOICES, data)
  },

  [types.FORMBUILDER_UPDATE_QMAXCHOICES]: (context, data) => {
    let question = context
      .getters
      .getQuestion(data.stepId, data.questionId)
    question['maxChoices'] = data.maxChoices
    context.commit(mTypes.FORMBUILDER_UPDATE_QMAXCHOICES, data)
  },

  [types.FORMBUILDER_UPDATE_QREQUIRED]: (context, data) => {
    context
      .getters
      .getQuestion(data.stepId, data.questionId).required = data.required
    context.commit(mTypes.FORMBUILDER_UPDATE_QREQUIRED, data)
  },
  [types.FORMBUILDER_UPDATE_QMINMAX]: (context, data) => {
    let question = context
      .getters.getQuestion(data.stepId, data.questionId)
    question.enableMinMax = data.enableMinMax
    context.commit(mTypes.FORMBUILDER_UPDATE_QMINMAX, data)
  },
  [types.FORMBUILDER_UPDATE_QMINMAX_AUTOINCEREMENT]: (context, data) => {
    let question = context
      .getters.getQuestion(data.stepId, data.questionId)
    question.autoIncrement = data.autoIncrement
    context.commit(mTypes.FORMBUILDER_UPDATE_QMINMAX_AUTOINCEREMENT, data)
  },
  [types.FORMBUILDER_UPDATE_QMINMAXCHOICES]: (context, data) => {
    let question = context
      .getters.getQuestion(data.stepId, data.questionId)
    question.enableMinMaxChoices = data.enableMinMaxChoices
    context.commit(mTypes.FORMBUILDER_UPDATE_QMINMAXCHOICES, data)
  },
  [types.FORMBUILDER_UPDATE_QENABLE_CHOICES_VALUES]: (context, data) => {
    let question = context
      .getters.getQuestion(data.stepId, data.questionId)
    question.enableChoicesValues = data.enableChoicesValues
    context.commit(mTypes.FORMBUILDER_UPDATE_QENABLE_CHOICES_VALUES, data)
  },
  [types.FORMBUILDER_UPDATE_QENABLE_PRE_SELECT_CHOICES]: (context, data) => {
    let question = context
      .getters.getQuestion(data.stepId, data.questionId)
    question.enablePreSelectChoices = data.enablePreSelectChoices
    context.commit(mTypes.FORMBUILDER_UPDATE_QENABLE_PRE_SELECT_CHOICES, data)
  },
  [types.FORMBUILDER_UPDATE_QENABLE_RANDOM_CHOICE_ORDER]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QENABLE_RANDOM_CHOICE_ORDER, data)
  },

  [types.FORMBUILDER_UPDATE_QENABLE_OTHER_CHOICE]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QENABLE_OTHER_CHOICE, data)
  },

  [types.FORMBUILDER_UPDATE_QNUMBER]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QNUMBER, data)
  },
  [types.FORMBUILDER_UPDATE_QSKIN]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QSKIN, data)
  },

  [types.FORMBUILDER_UPDATE_QCHOICES]: (context, data) => {
    context
      .getters
      .getQuestion(data.stepId, data.questionId).choices = data.choices
    context.commit(mTypes.FORMBUILDER_UPDATE_QCHOICES, data)
  },

  [types.FORMBUILDER_UPDATE_GDPR_CHOICES]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_GDPR_CHOICES, data)
  },

  [types.FORMBUILDER_UPDATE_QCHOICES_VALUES]: (context, data) => {
    context
      .getters
      .getQuestion(data.stepId, data.questionId).choicesValues = data.choicesValues
    context.commit(mTypes.FORMBUILDER_UPDATE_QCHOICES_VALUES, data)
  },

  [types.FORMBUILDER_UPDATE_QCHOICE]: (context, data) => {
    // let question = context
    //   .getters
    //   .getQuestion(data.stepId, data.questionId)
    // let choices = question.choices.slice()
    // let oldValue = choices[data.index]
    // choices[data.index] = data.value
    // context.getters.getQuestion(data.stepId, data.questionId).choices = choices
    // // update jumps choice
    // if (question.jumps) {
    //   for (let jump of question.jumps) {
    //     if (jump.conditions) {
    //       for (let condition of jump.conditions) {
    //         if (condition.choice && condition.choice === oldValue) {
    //           condition.choice = data.value
    //         }
    //       }
    //     }
    //   }
    // }
    context.commit(mTypes.FORMBUILDER_UPDATE_QCHOICE, data)
  },

  [types.FORMBUILDER_UPDATE_QCHOICE_VALUE]: (context, data) => {
    // let choicesValues = context
    //   .getters
    //   .getQuestion(data.stepId, data.questionId)
    //   .choicesValues.slice()
    // choicesValues[data.index] = data.value
    // context.getters.getQuestion(data.stepId, data.questionId).choicesValues = choicesValues
    context.commit(mTypes.FORMBUILDER_UPDATE_QCHOICE_VALUE, data)
  },

  [types.FORMBUILDER_DELETE_QCHOICE]: (context, data) => {
    let selectChoices = context.getters.getQuestion(data.stepId, data.questionId).choices
    selectChoices.splice(data.index, data.bulkDelete ? selectChoices.length : 1)
    context.commit(mTypes.FORMBUILDER_DELETE_QCHOICE, data)
  },

  [types.FORMBUILDER_DELETE_QCHOICE_VALUE]: (context, data) => {
    let selectChoiceValues = context.getters.getQuestion(data.stepId, data.questionId).choicesValues
    selectChoiceValues.splice(data.index, data.bulkDelete ? selectChoiceValues.length : 1)
    context.commit(mTypes.FORMBUILDER_DELETE_QCHOICE_VALUE, data)
  },

  [types.FORMBUILDER_ADD_QCHOICE]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_ADD_QCHOICE, data)
  },

  [types.FORMBUILDER_SORT_QCHOICE]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_SORT_QCHOICE, data)
  },

  [types.FORMBUILDER_ADD_QCHOICE_VALUE]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_ADD_QCHOICE_VALUE, data)
  },

  [types.FORMBUILDER_DELETE_Q]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_DELETE_Q, data)
  },

  [types.FORMBUILDER_VALIDATE]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_VALIDATE, data)
  },

  [types.FORMBUILDER_UPDATE_GDPR]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_GDPR, data)
  },

  [types.FORMBUILDER_REMOVE_GDPR]: (context) => {
    context.commit(mTypes.FORMBUILDER_REMOVE_GDPR)
  },

  [types.FORMBUILDER_UPDATE_CALCULATOR]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_CALCULATOR, data)
  },

  [types.FORMBUILDER_ADD_QJUMP]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_ADD_QJUMP, data)
  },

  [types.FORMBUILDER_ADD_QJUMP_CONDITION]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_ADD_QJUMP_CONDITION, data)
  },

  [types.FORMBUILDER_UPDATE_QJUMP_CONDITION]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QJUMP_CONDITION, data)
  },

  [types.FORMBUILDER_UPDATE_QJUMP]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QJUMP, data)
  },

  [types.FORMBUILDER_DELETE_QJUMP]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_DELETE_QJUMP, data)
  },

  [types.FORMBUILDER_DELETE_QJUMP_CONDITION]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_DELETE_QJUMP_CONDITION, data)
  },

  [types.FORMBUILDER_DELETE_QJUMPS]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_DELETE_QJUMPS, data)
  },

  [types.FORMBUILDER_UPDATE_QADDRESS_FIELDS]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QADDRESS_FIELDS, data)
  },

  [types.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY, data)
  },

  [types.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY_SOURCE]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_APIKEY_SOURCE, data)
  },

  [types.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_FIELDS_EDIT]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_FIELDS_EDIT, data)
  },

  [types.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_MODE]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QADDRESS_AUTOCOMPLETE_MODE, data)
  },

  [types.FORMBUILDER_UPDATE_QEMAIL_REPLYTO]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QEMAIL_REPLYTO, data)
  },
  [types.FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAIL]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAIL, data)
  },
  [types.FORMBUILDER_ADD_QEMAIL_RESTRICTEMAILFIELD]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_ADD_QEMAIL_RESTRICTEMAILFIELD, data)
  },
  [types.FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELDS]: (context, data) => {
    context
      .getters
      .getQuestion(data.stepId, data.questionId).restrictEmailFields = data.restrictEmailFields
    context.commit(mTypes.FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELDS, data)
  },
  [types.FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELD]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QEMAIL_RESTRICTEMAILFIELD, data)
  },
  [types.FORMBUILDER_DELETE_QEMAIL_RESTRICTEMAILFIELD]: (context, data) => {
    context
      .getters
      .getQuestion(data.stepId, data.questionId).restrictEmailFields.splice(data.index, 1)
    context.commit(mTypes.FORMBUILDER_DELETE_QEMAIL_RESTRICTEMAILFIELD, data)
  },

  // theme variant
  [types.FORMBUILDER_SET_DEFAULT_THEME]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_SET_DEFAULT_THEME, data)
  },
  [types.FORMBUILDER_SET_THEME_SETTING_FIRST_TIME]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_SET_THEME_SETTING_FIRST_TIME, data)
  },
  [types.UPDATE_FORM_THEME_TYPOGRAPHY]: (context, data) => {
    context.commit(mTypes.UPDATE_FORM_THEME_TYPOGRAPHY, data)
  },
  [types.UPDATE_FORM_THEME_GENERAL]: (context, data) => {
    context.commit(mTypes.UPDATE_FORM_THEME_GENERAL, data)
  },
  [types.UPDATE_FORM_THEME_UI_ELEMENTS]: (context, data) => {
    context.commit(mTypes.UPDATE_FORM_THEME_UI_ELEMENTS, data)
  },
  [types.FORMBUILDER_GET_THEME_SETTING]: (context, data) => {
    return FormVariantApi.getThemeSetting(data.formId, data.variantId)
  },
  [types.FORMBUILDER_SAVE_THEME_SETTING]: (context, data) => {
    return FormVariantApi.saveThemeSetting(data.formId, data.variantId, context.state.formVariantThemes[0])
  },
  [types.FORMBUILDER_UPDATE_THEME_SETTING]: (context, data) => {
    return FormVariantApi.updateThemeSetting(data.formId, data.variantId, context.state.formVariantThemes[0])
  },
  // Custome default theme
  [types.FORMBUILDER_SAVE_FORM_THEME_TEMPLATE]: (context, data) => {
    return FormVariantTemplate.saveFormTemplateTheme(data)
  },
  [types.FORMBUILDER_GET_FORM_THEME_TEMPLATES]: (context, data) => {
    return FormVariantTemplate.getFormTemplateThemes()
  },
  [types.FORMBUILDER_SET_TEMPLATE_THEME]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_SET_TEMPLATE_THEME, data)
  },
  // element
  [types.FORMBUILDER_ADD_ELEMENT]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_ADD_ELEMENT, data)
  },
  [types.FORMBUILDER_UPDATE_ENUMBER]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_ENUMBER, data)
  },
  [types.FORMBUILDER_DELETE_E]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_DELETE_E, data)
  },

  [types.FORMBUILDER_UPDATE_TB_ELEMENT_CONTENT]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_TB_ELEMENT_CONTENT, data)
  },

  /* users */
  [types.FETCH_USERS]: (context, data) => {
    return UserApi.fetchUsers(data)
  },
  [types.FETCH_USERS_SUCCESS]: (context, data) => {
    context.commit(mTypes.FETCH_USERS_SUCCESS, data)
  },

  [types.FETCH_USER]: (context, data) => {
    return UserApi.fetchUser(data.userId)
  },

  [types.UPDATE_USER_BASICDETAILS]: (context, data) => {
    return UserApi.updateBasicDetails(data.userId, data.payload)
  },

  [types.UPDATE_USER_PASSWORD]: (context, data) => {
    return UserApi.updatePassword(data.userId, data.payload)
  },

  [types.UPDATE_USER_ROLE]: (context, data) => {
    return UserApi.updateRole(data.userId, data.payload)
  },

  [types.UPDATE_USER_ACTIVATION]: (context, data) => {
    return UserApi.updateActivation(data.userId, data.payload)
  },

  [types.CANCEL_USER_SUBSCRIPTION]: (context, data) => {
    return UserApi.cancelSubscription(data.userId)
  },

  [types.UPDATE_USER_LOCAL]: (context, data) => {
    context.commit(mTypes.UPDATE_USER_LOCAL, data)
  },

  /* register and login */
  [types.REGISTER_USER]: (context, data) => {
    context.commit(mTypes.REGISTER_USER, data)
    return Auth.register(data)
  },
  [types.REGISTER_SUCCESS]: (context) => {
    context.commit(mTypes.REGISTER_SUCCESS)
  },
  [types.REGISTER_FAILED]: (context) => {
    context.commit(mTypes.REGISTER_FAILED)
  },

  [types.LOGIN_USER]: (context, data) => {
    context.commit(mTypes.LOGIN_USER, data)
    return Auth.login(data)
  },
  [types.LOGIN_SUCCESS]: (context, data) => {
    context.commit(mTypes.LOGIN_SUCCESS, data)
  },
  [types.LOGIN_FAILED]: (context) => {
    context.commit(mTypes.LOGIN_FAILED)
  },
  [types.LOGOUT_USER]: (context, data) => {
    Auth.logout()
    context.commit(mTypes.LOGOUT_USER, data)
  },

  /* forms */
  [types.FETCH_FORM_BY_KEY]: (context, data) => {
    return FormApi.getFormByKey(data.key, data.variantId, data.visitorId)
  },

  [types.FETCH_FORM]: (context, data) => {
    return FormApi.getForm(data.id)
  },
  [types.FETCH_FORM_SUCCESS]: (context, data) => {
    context.commit(mTypes.FETCH_FORM_SUCCESS, data)
  },
  [types.FETCH_FORM_ERROR]: (context, data) => {
    context.commit(mTypes.FETCH_FORM_ERROR, data)
  },

  [types.UPDATE_FORM]: (context, data) => {
    return FormApi.updateForm(data.id, data)
  },

  [types.FETCH_FORMS]: (context, data) => {
    if (data && (data.page || data.search)) {
      return FormApi.getForms(data)
    }
    return FormApi.getForms()
  },
  [types.FETCH_FORMS_SUCCESS]: (context, data) => {
    context.commit(mTypes.FETCH_FORMS_SUCCESS, data)
  },
  [types.FETCH_FORMS_ERROR]: (context, data) => {
    context.commit(mTypes.FETCH_FORMS_ERROR, data)
  },

  [types.FORM_UPDATE_IFRAME_HEIGHT]: (context, data) => {
    context.commit(mTypes.FORM_UPDATE_IFRAME_HEIGHT, data)
  },

  [types.FORM_UPDATE_IFRAME_HEIGHT]: (context, data) => {
    context.commit(mTypes.FORM_UPDATE_IFRAME_HEIGHT, data)
  },

  [types.FORM_UPDATE_IFRAME_DIMENSIONS]: (context, payload) => {
    context.commit(mTypes.UPDATE_IFRAME_DIMENSIONS, payload)
  },
  [types.FORM_FULL_WIDTH_FORM]: (context, payload) => {
    context.commit(mTypes.FORM_FULL_WIDTH_FORM, payload)
  },

  [types.FORM_UPDATE_IFRAME_WIDTH]: (context, data) => {
    context.commit(mTypes.FORM_UPDATE_IFRAME_WIDTH, data)
  },

  [types.FORM_UPDATE_IFRAME_FORM_SHADOW]: (context, data) => {
    context.commit(mTypes.FORM_UPDATE_IFRAME_FORM_SHADOW, data)
  },

  [types.FORM_SAVE_LEAD]: (context, data) => {
    return FormApi.saveLead(data)
  },

  [types.FORM_DELETE_LEAD]: (context, data) => {
    return FormApi.deleteLead(data)
  },

  [types.FORM_DELETE_LEAD_SUCCESS]: (context, data) => {
    context.commit(mTypes.FORM_DELETE_LEAD_SUCCESS, data)
  },

  [types.FORM_FETCH_LEADS]: (context, data) => {
    return FormApi.getLeads(data.formId, data.idVariant, data.idExperiment, data.page) // Leads or Responses
  },
  [types.FETCH_PARTIAL_LEADS_COUNT]: (context, data) => {
    return FormApi.getPartialLeadsCount(data.userId) // Partial leads
  },
  [types.FETCH_COMPLETE_LEADS_COUNT]: (context, data) => {
    return FormApi.getCompleteLeadsCount(data.userId) // Complete leads
  },
  [types.FORM_FETCH_LEADS_SUCCESS]: (context, data) => {
    context.commit(mTypes.FORM_FETCH_LEADS_SUCCESS, data)
  },

  [types.FORMBUILDER_GET_THEME_SETTING_SUCCESS]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_GET_THEME_SETTING_SUCCESS, data)
  },
  [types.FORMBUILDER_GET_FORM_THEME_TEMPLATES_SUCCESS]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_GET_FORM_THEME_TEMPLATES_SUCCESS, data)
  },
  [types.FORM_FETCH_LEADS_ERROR]: (context) => {
    context.commit(mTypes.FORM_FETCH_LEADS_ERROR)
  },

  [types.FETCH_LEADS_COUNT]: (context) => {
    LeadApi.leadsCount().then((response) => {
      context.commit(mTypes.FETCH_LEADS_COUNT, {
        count: response.data.data.count
      })
    }, (response) => {
      context.commit(mTypes.FETCH_LEADS_COUNT, {
        count: -1
      })
    })
  },

  /* form hidden fields */
  [types.RESET_FORM_HIDDEN_FIELDS]: (context, data) => {
    return context.commit(mTypes.RESET_FORM_HIDDEN_FIELDS, [])
  },

  [types.FETCH_FORM_HIDDEN_FIELDS]: (context, data) => {
    return FormHiddenFieldsApi.fetchHiddenFields(data.formId, data.variantId)
  },

  [types.FETCH_FORM_HIDDEN_FIELDS_SUCCESS]: (context, data) => {
    context.commit(mTypes.FETCH_FORM_HIDDEN_FIELDS_SUCCESS, data)
  },

  [types.UPDATE_FORM_HIDDEN_FIELDS_SUCCESS]: (context, data) => {
    context.commit(mTypes.UPDATE_FORM_HIDDEN_FIELDS_SUCCESS, data)
  },

  [types.REMOVE_FORM_HIDDEN_FIELD]: (context, data) => {
    context.commit(mTypes.REMOVE_FORM_HIDDEN_FIELD, data)
  },

  [types.UPDATE_FORM_HIDDEN_FIELD_ATTRIBUTE]: (context, data) => {
    context.commit(mTypes.UPDATE_FORM_HIDDEN_FIELD_ATTRIBUTE, data)
  },

  [types.SAVE_FORM_HIDDEN_FIELDS]: (context, data) => {
    return FormHiddenFieldsApi.saveHiddenFields(data.formId, data.variantId, data.hiddenFields)
  },

  [types.UPDATE_FORM_FOOTER_TEXT]: (context, data) => {
    return FormApi.updateFooterText(data.formId, data.footerData)
  },

  [types.FETCH_FORMS_COUNT]: (context) => {
    FormApi.formsCount().then((response) => {
      context.commit(mTypes.FETCH_FORMS_COUNT, {
        count: response.data.data.count
      })
    }, (response) => {
      context.commit(mTypes.FETCH_FORMS_COUNT, {
        count: -1
      })
    })
  },

  [types.FETCH_AVG_CONV_RATE_COUNT]: (context) => {
    LeadApi.averageConversionRate().then((response) => {
      context.commit(mTypes.FETCH_AVG_CONV_RATE_COUNT, {
        rate: response.data.data.rate.toFixed(2)
      })
    }, (response) => {
      context.commit(mTypes.FETCH_AVG_CONV_RATE_COUNT, {
        count: -1
      })
    })
  },

  [types.FORM_FETCH_SETTING]: (context, data) => {
    return FormApi.getSetting(data.formId)
  },

  [types.FORM_FETCH_SETTING_SUCCESS]: (context, data) => {
    context.commit(mTypes.FORM_FETCH_SETTING_SUCCESS, data)
  },

  [types.FORM_FETCH_SETTING_ERROR]: (context) => {
    context.commit(mTypes.FORM_FETCH_SETTING_ERROR)
  },

  [types.FORM_SETTING_UPDATE_FIELD]: (context, data) => {
    context.commit(mTypes.FORM_SETTING_UPDATE_FIELD, data)
  },

  [types.FORM_SAVE_SETTING]: (context, data) => {
    return FormApi.saveSetting(data.formId, context.state.formSetting[data.formId])
  },

  [types.VERIFY_USER_ACCOUNT]: (context, data) => {
    return UserApi.verifyUserAccount(data.userId, data.token)
  },

  [types.SEND_ACCOUNT_VERIFICATION]: (context, data) => {
    return UserApi.sendAccountVerification(data.email)
  },

  [types.SEND_PASSWORD_RESET_LINK]: (context, data) => {
    return UserApi.sendPasswordResetLink(data)
  },

  [types.RESET_PASSWORD]: (context, data) => {
    return UserApi.resetPassword(data)
  },

  [types.FORM_FETCH_OVERVIEW]: (context, data) => {
    return FormApi.getOverview(data.id)
  },
  [types.FORM_FETCH_OVERVIEW_SUCCESS]: (context, data) => {
    context.commit(mTypes.FORM_FETCH_OVERVIEW_SUCCESS, data)
  },
  [types.FORM_FETCH_OVERVIEW_ERROR]: (context, data) => {
    context.commit(mTypes.FORM_FETCH_OVERVIEW_ERROR, data)
  },

  [types.FORM_DUPLICATE]: (context, data) => {
    return FormApi.duplicate(data.id)
  },
  [types.FORM_DUPLICATE_SUCCESS]: (context, data) => {
    context.commit(mTypes.FORM_DUPLICATE_SUCCESS, data)
  },
  [types.FORM_DUPLICATE_ERROR]: (context, data) => {
    context.commit(mTypes.FORM_DUPLICATE_ERROR, data)
  },

  [types.FORM_ARCHIVE]: (context, data) => {
    return FormApi.archive(data.id)
  },
  [types.FORM_ARCHIVE_SUCCESS]: (context, data) => {
    context.commit(mTypes.FORM_ARCHIVE_SUCCESS, data)
  },
  [types.FORM_ARCHIVE_ERROR]: (context, data) => {
    context.commit(mTypes.FORM_ARCHIVE_ERROR, data)
  },
  [types.FORM_SHARE]: (context, data) => {
    return FormApi.share(data)
  },

  /* form visits */
  [types.FORM_CREATE_VISIT_BY_KEY]: (context, data) => {
    return FormVisitApi.createVisitByKey(data.key, data.data)
  },

  /* form variants */
  [types.FETCH_FORM_VARIANTS]: (context, data) => {
    return FormVariantApi.getFormVariants(data.formId, data.params)
  },
  [types.FETCH_FORM_VARIANTS_SUCCESS]: (context, data) => {
    context.commit(mTypes.FETCH_FORM_VARIANTS_SUCCESS, data)
  },
  [types.FETCH_FORM_VARIANTS_ERROR]: (context, data) => {
    context.commit(mTypes.FETCH_FORM_VARIANTS_ERROR, data)
  },

  [types.FORM_FETCH_VARIANT_STATE]: (context, data) => {
    return FormVariantApi.getVariantState(data.formId, data.variantId)
  },

  [types.DUPLICATE_FORM_VARIANT]: (context, data) => {
    return FormVariantApi.duplicateFormVariant(data.formId, data.variantId)
  },
  [types.DUPLICATE_FORM_VARIANT_SUCCESS]: (context, data) => {
    context.commit(mTypes.DUPLICATE_FORM_VARIANT_SUCCESS, data)
  },
  [types.DUPLICATE_FORM_VARIANT_ERROR]: (context, data) => {
    context.commit(mTypes.DUPLICATE_FORM_VARIANT_ERROR, data)
  },

  [types.DELETE_FORM_VARIANT]: (context, data) => {
    return FormVariantApi.deleteFormVariant(data.formId, data.variantId)
  },
  [types.DELETE_FORM_VARIANT_SUCCESS]: (context, data) => {
    context.commit(mTypes.DELETE_FORM_VARIANT_SUCCESS, data)
  },
  [types.DELETE_FORM_VARIANT_ERROR]: (context, data) => {
    context.commit(mTypes.DELETE_FORM_VARIANT_ERROR, data)
  },

  [types.DELETE_FORM_LEAD]: (context, data) => {
    return LeadApi.bulkDelete(data.formId, data.variantId)
  },
  [types.DELETE_FORM_LEAD_SUCCESS]: (context, data) => {
    context.commit(mTypes.DELETE_FORM_LEAD_SUCCESS, data)
  },
  [types.DELETE_FORM_LEAD_ERROR]: (context, data) => {
    context.commit(mTypes.DELETE_FORM_LEAD_ERROR, data)
  },

  [types.PROMOTE_FORM_VARIANT]: (context, data) => {
    return FormVariantApi.promoteFormVariant(data.formId, data.variantId)
  },

  [types.UPDATE_FORM_VARIANT_SETTING]: (context, data) => {
    return FormVariantApi.updateVariantSetting(data.formId, data.variantId, data.data)
  },

  /* form experiments */
  [types.FORM_EXPERIMENT_CREATE]: (context, data) => {
    return FormExperimentApi.createExperiment(data.formId, data.data)
  },
  [types.FORM_EXPERIMENT_CREATE_SUCCESS]: (context, data) => {
    context.commit(mTypes.FORM_EXPERIMENT_CREATE_SUCCESS, data)
  },
  [types.FORM_EXPERIMENT_CREATE_ERROR]: (context, data) => {
    context.commit(mTypes.FORM_EXPERIMENT_CREATE_ERROR, data)
  },

  [types.FETCH_FORM_EXPERIMENTS]: (context, data) => {
    return FormExperimentApi.getFormExperiments(data.formId)
  },
  [types.FETCH_FORM_EXPERIMENTS_SUCCESS]: (context, data) => {
    context.commit(mTypes.FETCH_FORM_EXPERIMENTS_SUCCESS, data)
  },
  [types.FETCH_FORM_EXPERIMENTS_ERROR]: (context, data) => {
    context.commit(mTypes.FETCH_FORM_EXPERIMENTS_ERROR, data)
  },

  [types.FETCH_FORM_EXPERIMENT]: (context, data) => {
    return FormExperimentApi.getFormExperiment(data.formId, data.experimentId)
  },
  [types.FETCH_FORM_EXPERIMENT_SUCCESS]: (context, data) => {
    context.commit(mTypes.FETCH_FORM_EXPERIMENT_SUCCESS, data)
  },
  [types.FETCH_FORM_EXPERIMENT_ERROR]: (context, data) => {
    context.commit(mTypes.FETCH_FORM_EXPERIMENT_ERROR, data)
  },

  [types.FORM_EXPERIMENT_UPDATE]: (context, data) => {
    return FormExperimentApi.updateExperiment(data.formId, data.experimentId, data.data)
  },
  [types.FORM_EXPERIMENT_UPDATE_SUCCESS]: (context, data) => {
    context.commit(mTypes.FORM_EXPERIMENT_UPDATE_SUCCESS, data)
  },
  [types.FORM_EXPERIMENT_UPDATE_ERROR]: (context, data) => {
    context.commit(mTypes.FORM_EXPERIMENT_UPDATE_ERROR, data)
  },

  [types.FORM_EXPERIMENT_START]: (context, data) => {
    return FormExperimentApi.startExperiment(data.formId, data.experimentId)
  },
  [types.FORM_EXPERIMENT_START_SUCCESS]: (context, data) => {
    context.commit(mTypes.FORM_EXPERIMENT_START_SUCCESS, data)
  },
  [types.FORM_EXPERIMENT_START_ERROR]: (context, data) => {
    context.commit(mTypes.FORM_EXPERIMENT_START_ERROR, data)
  },

  [types.FORM_EXPERIMENT_END]: (context, data) => {
    return FormExperimentApi.endExperiment(data.formId, data.experimentId)
  },
  [types.FORM_EXPERIMENT_END_SUCCESS]: (context, data) => {
    context.commit(mTypes.FORM_EXPERIMENT_END_SUCCESS, data)
  },
  [types.FORM_EXPERIMENT_END_ERROR]: (context, data) => {
    context.commit(mTypes.FORM_EXPERIMENT_END_ERROR, data)
  },

  [types.FORM_EXPERIMENT_RESULT]: (context, data) => {
    return FormExperimentApi.result(data.formId, data.experimentId)
  },
  [types.FORM_EXPERIMENT_RESULT_SUCCESS]: (context, data) => {
    context.commit(mTypes.FORM_EXPERIMENT_RESULT_SUCCESS, data)
  },
  [types.FORM_EXPERIMENT_RESULT_ERROR]: (context, data) => {
    context.commit(mTypes.FORM_EXPERIMENT_RESULT_ERROR, data)
  },

  /* form experiment variants */
  [types.FETCH_FORM_EXPERIMENT_VARIANTS]: (context, data) => {
    return FormExperimentVariantApi.getFormExperimentVariants(data.formId, data.experimentId)
  },
  [types.FETCH_FORM_EXPERIMENT_VARIANTS_SUCCESS]: (context, data) => {
    context.commit(mTypes.FETCH_FORM_EXPERIMENT_VARIANTS_SUCCESS, data)
  },
  [types.FETCH_FORM_EXPERIMENT_VARIANTS_ERROR]: (context, data) => {
    context.commit(mTypes.FETCH_FORM_EXPERIMENT_VARIANTS_ERROR, data)
  },

  /* plan */
  [types.CHANGE_USER_PLAN]: (context, data) => {
    return PlanApi.changePlan(data)
  },
  [types.CHANGE_USER_PLAN_SUCCESS]: (context, data) => {
    context.commit(mTypes.CHANGE_USER_PLAN_SUCCESS, data)
  },

  [types.CANCEL_USER_PLAN]: (context) => {
    return PlanApi.cancelPlan()
  },

  [types.RESUME_USER_PLAN]: (context) => {
    return PlanApi.resumePlan()
  },

  [types.FETCH_PLANS]: (context) => {
    return PlanApi.getPlans()
  },
  [types.FETCH_PLANS_SUCCESS]: (context, data) => {
    context.commit(mTypes.FETCH_PLANS_SUCCESS, data)
  },

  [types.FETCH_PLAN_SUBSCRIPTION]: (context, data) => {
    return PlanApi.planSubscription(data.planId)
  },
  [types.FETCH_PLAN_SUBSCRIPTION_SUCCESS]: (context, data) => {
    context.commit(mTypes.FETCH_PLAN_SUBSCRIPTION_SUCCESS, data)
  },

  /* Stripe */
  [types.FETCH_STRIPE_SETUP_INTENT]: (context, data) => {
    return StripeApi.getSetupIntent(data)
  },
  [types.FETCH_STRIPE_COUPON_CODE]: (context, data) => {
    return StripeApi.getCouponCode(data)
  },

  /* lead proofs */
  [types.CREATE_LEAD_PROOF]: (context, data) => {
    return LeadProofApi.createProof(data)
  },
  [types.UPDATE_LEAD_PROOF]: (context, data) => {
    return LeadProofApi.updateProof(data.id, data.data)
  },
  [types.FETCH_LEAD_PROOF]: (context, data) => {
    return LeadProofApi.getProof(data.id)
  },
  [types.FETCH_LEAD_PROOFS]: (context, data) => {
    return LeadProofApi.getProofs()
  },
  [types.DELETE_LEAD_PROOF]: (context, data) => {
    return LeadProofApi.deleteLeadProof(data.id)
  },

  /* phone number default country code */
  [types.FORMBUILDER_UPDATE_QPHONE_DEFAULT_COUNTRY_CODE]: (context, data) => {
    let question = context
      .getters.getQuestion(data.stepId, data.questionId)
    question.enableDefaultCode = data.enableDefaultCode
    context.commit(mTypes.FORMBUILDER_UPDATE_QPHONE_DEFAULT_COUNTRY_CODE, data)
  },
  [types.FORMBUILDER_UPDATE_QNUMBER_QMINMAXLIMIT]: (context, data) => {
    let question = context
      .getters.getQuestion(data.stepId, data.questionId)
    question.enableMinMaxLimit = data.enableMinMaxLimit
    context.commit(mTypes.FORMBUILDER_UPDATE_QNUMBER_QMINMAXLIMIT, data)
  },
  [types.FORMBUILDER_UPDATE_QRANGE_UNITVALUES]: (context, data) => {
    let question = context
      .getters.getQuestion(data.stepId, data.questionId)
    question.enableUnitValues = data.enableUnitValues
    context.commit(mTypes.FORMBUILDER_UPDATE_QRANGE_UNITVALUES, data)
  },
  [types.FORMBUILDER_UPDATE_QRANGE_STEPCOUNT]: (context, data) => {
    let question = context
      .getters.getQuestion(data.stepId, data.questionId)
    question.enableStepCount = data.enableStepCount
    context.commit(mTypes.FORMBUILDER_UPDATE_QRANGE_STEPCOUNT, data)
  },
  [types.FORMBUILDER_UPDATE_QRANGE_CUSTOMTEXT]: (context, data) => {
    let question = context
      .getters.getQuestion(data.stepId, data.questionId)
    question.enableCustomText = data.enableCustomText
    context.commit(mTypes.FORMBUILDER_UPDATE_QRANGE_CUSTOMTEXT, data)
  },
  [types.FORMBUILDER_UPDATE_QRANGE_SHORIENTATIONSCALE]: (context, data) => {
    let question = context
      .getters.getQuestion(data.stepId, data.questionId)
    question.showHideOrientationScale = data.showHideOrientationScale
    context.commit(mTypes.FORMBUILDER_UPDATE_QRANGE_SHORIENTATIONSCALE, data)
  },
  [types.FORMBUILDER_UPDATE_QRANGE_RANGEFIELDS]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QRANGE_RANGEFIELDS, data)
  },
  [types.FORMBUILDER_UPDATE_QRANGE_RANGEFIELD]: (context, data) => {
    context.commit(mTypes.FORMBUILDER_UPDATE_QRANGE_RANGEFIELD, data)
  },

  [types.FORMBUILDER_UPDATE_QPHONE_COUNTRY_CODE_VALUE]: (context, data) => {
    let question = context
      .getters
      .getQuestion(data.stepId, data.questionId)
    question.defaultCountryCode = data.defaultCountryCode
    context.commit(mTypes.FORMBUILDER_UPDATE_QPHONE_COUNTRY_CODE_VALUE, data)
  },
  /* mark read lead notifications */
  [types.MARK_READ_LEAD]: (context, data) => {
    return LeadApi.markRead(data)
  },
  [types.MARK_READ_LEAD_ERROR]: (context, data) => {
    context.commit(mTypes.MARK_READ_LEAD_ERROR, data)
  },
  [types.USER_SETTING_UPDATE_FIELD]: (context, data) => {
    context.commit(mTypes.USER_SETTING_UPDATE_FIELD, data)
  },
  /* utm  */
  [types.FETCH_UTM_PARAM]: (context, data) => {
    return UtmParamsApi.getScript(data.id)
  }
}
