import * as actionTypes from '@/store/action-types.js'

import {
  paddleMonthlyPlans,
  paddleYearlyPlans,
  paddleMonthlyTrialPlans,
  paddleYearlyTrialPlans,
  freeTrialPlans
} from '@/shared/constants/plan/paddle/plans'
import planPublicIds from '@/shared/constants/plan/paddle/publicIds'
import planActions from '@/shared/constants/plan/paddle/action'
import {
  registerRoutePath,
  registerRoutesName,
} from '@/shared/constants/plan/paddle/route'
import config from '@/api/config'
import environments from '@/shared/constants/environments'

export default {
  data: function () {
    return {
      pastDueDetails: {
        urlLoaded: false,
        updateBankDetailsUrl: null,
        urlErrorMessage: null
      },
      subscriptionStatus: {
        interval: null,
        intervalCounter: 0,
        intervalLimit: 10,
        subscribeSuccess: false,
        subscribeFailed: false,
        upgradeSuccess: false,
        upgradeFailed: false,
        cancelSuccess: false,
        pauseSuccess: false,
        pauseFailed: false,
        resumeSuccess: false,
        resumeFailed: false,
        cancelFailed: false,
        downgradeSuccess: false,
        downgradeFailed: false,
        syncingButton: false,
        planId: null,
        billingUpdateSuccess: false,
        billingUpdateFailed: false,
        reschedulePaymentSuccess: false,
        reschedulePaymentFailed: false,
        planAction: null
      },
      mixin_user: {
        id: null,
        token: null
      }
    }
  },
  methods: {
    planMixin_loadUpdateUrl: function () {
      this.$store
        .dispatch('subscription/getPastDueSubscription', {
          id: this.user.plan.subscription_id,
        })
        .then(
          (response) => {
            this.pastDueDetails.urlLoaded = true
            this.pastDueDetails.urlErrorMessage = null
            this.pastDueDetails.updateBankDetailsUrl =
              response.data.data.updateUrl
          },
          (error) => {
            this.pastDueDetails.urlLoaded = false
            this.pastDueDetails.updateBankDetailsUrl = null
            this.pastDueDetails.urlErrorMessage = error.body.meta.error_message
          }
        )
    },
    planMixin_getPlanFeatures: function (userId) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(actionTypes.FETCH_USER, {
            userId: userId
          })
          .then((response) => {
            resolve(response.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    planMixin_getDetails: function (identifier) {
      let planDetails = {}
      planDetails.isMonthlyBilled = false
      planDetails.isTrialPlan = false
      planDetails.isFreeTrial = false
      planDetails.isSinglePlan = false
      planDetails.registerPageRoute = null

      switch (identifier) {
        case registerRoutePath.registerProMonthlyTrial:
        case planPublicIds.paddleProMonthlyTrial:
          this.subscriptionStatus.planId = config.paddle.trialPlanId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleProMonthlyTrial,
            paddleMonthlyTrialPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.isTrialPlan = true
          planDetails.registerPageRoute =
            registerRoutesName.registerProMonthlyTrial
          break
        case registerRoutePath.registerProAnnualTrial:
        case planPublicIds.paddleProYearlyTrial:
          this.subscriptionStatus.planId = config.paddle.trialAnnualProId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleProYearlyTrial,
            paddleYearlyTrialPlans
          )
          planDetails.isTrialPlan = true
          planDetails.registerPageRoute =
            registerRoutesName.registerProAnnualTrial
          break
        case registerRoutePath.registerScaleMonthlyTrial:
        case planPublicIds.paddleScaleMonthlyTrial:
          this.subscriptionStatus.planId = config.paddle.trialScaleId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleScaleMonthlyTrial,
            paddleMonthlyTrialPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.isTrialPlan = true
          planDetails.registerPageRoute =
            registerRoutesName.registerScaleMonthlyTrial
          break
        case registerRoutePath.registerScaleAnnualTrial:
        case planPublicIds.paddleScaleYearlyTrial:
          this.subscriptionStatus.planId = config.paddle.trialAnnualScaleId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleScaleYearlyTrial,
            paddleYearlyTrialPlans
          )
          planDetails.isTrialPlan = true
          planDetails.registerPageRoute =
            registerRoutesName.registerScaleAnnualTrial
          break
        case registerRoutePath.registerEnterpriseMonthlyTrial:
        case planPublicIds.paddleEnterpriseMonthlyTrial:
          this.subscriptionStatus.planId = config.paddle.trialEnterpriseId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleEnterpriseMonthlyTrial,
            paddleMonthlyTrialPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.isTrialPlan = true
          planDetails.registerPageRoute =
            registerRoutesName.registerEnterpriseMonthlyTrial
          break
        case registerRoutePath.registerEnterpriseAnnualTrial:
        case planPublicIds.paddleEnterpriseYearlyTrial:
          this.subscriptionStatus.planId =
            config.paddle.trialAnnualEnterpriseId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleEnterpriseYearlyTrial,
            paddleYearlyTrialPlans
          )
          planDetails.isTrialPlan = true
          planDetails.registerPageRoute =
            registerRoutesName.registerEnterpriseAnnualTrial
          break
        case registerRoutePath.registerProMonthly:
        case planPublicIds.paddleProMonthly:
          this.subscriptionStatus.planId = config.paddle.planId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleProMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute = registerRoutesName.registerProMonthly
          break
        case registerRoutePath.registerStarterMonthly:
        case planPublicIds.paddleStarterMonthly:
          this.subscriptionStatus.planId = config.paddle.starterPlanId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleStarterMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute =
            registerRoutesName.registerStarterMonthly
          break
        case registerRoutePath.registerStarterMonthlyFr:
        case registerRoutePath.registerStarterMonthlyDe:
        case registerRoutePath.registerStarterMonthlyEs:
        case registerRoutePath.registerStarterMonthlyPt:
          this.subscriptionStatus.planId = config.paddle.starterPlanId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleStarterMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute =
            registerRoutesName.registerStarterMonthly
          break
        case registerRoutePath.registerProMonthlyFr:
        case registerRoutePath.registerProMonthlyDt:
        case registerRoutePath.registerProMonthlyEs:
        case registerRoutePath.registerProMonthlyPt:
          this.subscriptionStatus.planId = config.paddle.planId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleProMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute = registerRoutesName.registerProMonthly
          break
        case registerRoutePath.registerProAnnual:
        case planPublicIds.paddleProYearly:
          this.subscriptionStatus.planId = config.paddle.annualProId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleProYearly,
            paddleYearlyPlans
          )
          planDetails.registerPageRoute = registerRoutesName.registerProAnnual
          break
        case registerRoutePath.registerProAnnualFr:
        case registerRoutePath.registerProAnnualDt:
        case registerRoutePath.registerProAnnualEs:
        case registerRoutePath.registerProAnnualPt:
          this.subscriptionStatus.planId = config.paddle.annualProId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleProYearly,
            paddleYearlyPlans
          )
          planDetails.registerPageRoute =
            registerRoutesName.registerProAnnualFr
          break
        case registerRoutePath.registerScaleMonthly:
        case planPublicIds.paddleScaleMonthly:
          this.subscriptionStatus.planId = config.paddle.scaleId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleScaleMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute =
            registerRoutesName.registerScaleMonthly
          break
        case registerRoutePath.registerScaleMonthlyFr:
        case registerRoutePath.registerScaleMonthlyDt:
        case registerRoutePath.registerScaleMonthlyEs:
        case registerRoutePath.registerScaleMonthlyPt:
          this.subscriptionStatus.planId = config.paddle.scaleId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleScaleMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute =
            registerRoutesName.registerScaleMonthly
          break
        case registerRoutePath.registerScaleAnnual:
        case planPublicIds.paddleScaleYearly:
          this.subscriptionStatus.planId = config.paddle.annualScaleId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleScaleYearly,
            paddleYearlyPlans
          )
          planDetails.registerPageRoute =
            registerRoutesName.registerScaleAnnual
          break
        case registerRoutePath.registerScaleAnnualFr:
        case registerRoutePath.registerScaleAnnualDt:
        case registerRoutePath.registerScaleAnnualEs:
        case registerRoutePath.registerScaleAnnualPt:
          this.subscriptionStatus.planId = config.paddle.annualScaleId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleScaleYearly,
            paddleYearlyPlans
          )
          planDetails.registerPageRoute =
            registerRoutesName.registerScaleAnnual
          break
        case registerRoutePath.registerEnterpriseMonthly:
        case planPublicIds.paddleEnterpriseMonthly:
          this.subscriptionStatus.planId = config.paddle.enterpriseId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleEnterpriseMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute =
            registerRoutesName.registerEnterpriseMonthly
          break
        case registerRoutePath.registerEnterpriseMonthlyFr:
        case registerRoutePath.registerEnterpriseMonthlyDt:
        case registerRoutePath.registerEnterpriseMonthlyEs:
        case registerRoutePath.registerEnterpriseMonthlyPt:
          this.subscriptionStatus.planId = config.paddle.enterpriseId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleEnterpriseMonthly,
            paddleMonthlyPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.registerPageRoute =
            registerRoutesName.registerEnterpriseMonthly
          break
        case registerRoutePath.registerEnterpriseAnnual:
        case planPublicIds.paddleEnterpriseYearly:
          this.subscriptionStatus.planId = config.paddle.annualEnterpriseId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleEnterpriseYearly,
            paddleYearlyPlans
          )
          planDetails.registerPageRoute =
            registerRoutesName.registerEnterpriseAnnual
          break
        case registerRoutePath.registerEnterpriseAnnualFr:
        case registerRoutePath.registerEnterpriseAnnualDt:
        case registerRoutePath.registerEnterpriseAnnualEs:
        case registerRoutePath.registerEnterpriseAnnualPt:
          this.subscriptionStatus.planId = config.paddle.annualEnterpriseId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleEnterpriseYearly,
            paddleYearlyPlans
          )
          planDetails.registerPageRoute =
            registerRoutesName.registerEnterpriseAnnual
          break
        case registerRoutePath.registerSingleAnnual:
        case planPublicIds.paddleSinglePlan:
          this.subscriptionStatus.planId = config.paddle.singlePlanId
          planDetails = this.planMixin_filterPlans(
            planPublicIds.paddleSinglePlan,
            paddleYearlyPlans
          )
          planDetails.isSinglePlan = true
          break
        case registerRoutePath.registerFreeTrial:
        case registerRoutePath.registerFreeTrialFr:
        case registerRoutePath.registerFreeTrialDt:
        case registerRoutePath.registerFreeTrialPt:
        case registerRoutePath.registerFreeTrialEs:
          planDetails = this.planMixin_filterPlans(
            planPublicIds.freeTrial,
            freeTrialPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.isTrialPlan = true
          planDetails.isFreeTrial = true
          break
        default:
          planDetails = this.planMixin_filterPlans(
            planPublicIds.freeTrial,
            freeTrialPlans
          )
          planDetails.isMonthlyBilled = true
          planDetails.isTrialPlan = true
          planDetails.isFreeTrial = true
          break
      }
      return planDetails
    },
    planMixin_getDefaultRoute: function () {
      let planDetails = {}
      this.subscriptionStatus.planId = config.paddle.trialPlanId
      planDetails = this.planMixin_filterPlans(
        planPublicIds.paddleProMonthlyTrial,
        paddleMonthlyTrialPlans
      )
      planDetails.isMonthlyBilled = true
      planDetails.isTrialPlan = true
      planDetails.registerPageRoute =
        registerRoutesName.registerProMonthlyTrial
      return planDetails
    },
    planMixin_filterPlans: function (planPublicId, planType) {
      const planDetails = planType.find((object) => {
        return object.publicId === planPublicId
      })
      return planDetails
    },
    planMixin_resetSubscriptionStatus: function () {
      this.subscriptionStatus = {
        interval: null,
        intervalCounter: 0,
        intervalLimit: 10,
        subscribeSuccess: false,
        subscribeFailed: false,
        upgradeSuccess: false,
        upgradeFailed: false,
        cancelSuccess: false,
        pauseSuccess: false,
        pauseFailed: false,
        resumeSuccess: false,
        resumeFailed: false,
        cancelFailed: false,
        downgradeSuccess: false,
        downgradeFailed: false,
        syncingButton: false,
        planId: null,
        billingUpdateSuccess: false,
        billingUpdateFailed: false,
        reschedulePaymentSuccess: false,
        reschedulePaymentFailed: false,
        planAction: null
      }
    },
    planMixin_subscribeUser: function (planId, userData) {
      console.log('planMixin_subscribeUser-----planId-------', planId)
      console.log('planMixin_subscribeUser---userData---------', userData)
      this.planMixin_resetSubscriptionStatus()
      this.screen.step++

      let metadata = {
        user_id: userData.id
      }
      this.mixin_user.id = userData.id
      this.mixin_user.token = userData.token
      this.subscriptionStatus.planAction = planActions.subscribe

      let checkoutConfig = {
        product: planId,
        theme: 'none',
        email: userData.email,
        passthrough: JSON.stringify(metadata),
        method: 'inline',
        frameTarget: 'payment-div',
        frameInitialHeight: 416,
        frameStyle: 'width:100%; min-width:312px; background-color:transparent; border:none;',
        marketingConsent: userData.subscribe_newsletter,
        successCallback: () => {
          this.flag.loading = true
          this.planMixin_pollingSubscriptionStatus(() => {
            this.subscriptionStatus.subscribeSuccess = true
            let otherThis = this
            if (userData.token) {
              setTimeout(function () {
                otherThis.flag.loading = false
                otherThis.$router.push({
                  name: 'tokenLogin',
                  query: { token: userData.token }
                })
              }, 3000)
            } else {
              setTimeout(function () {
                otherThis.flag.loading = false
                otherThis.$router.push({ name: 'login' })
              }, 3000)
            }
          })
        },
        closeCallback: () => {
          this.planMixin_resetSubscriptionStatus()
        }
      }

      if (config.paddle.coupon) {
        checkoutConfig.coupon = config.paddle.coupon
      }

      if (
        [environments.LOCAL, environments.DEV].indexOf(config.environment) >= 0
      ) {
        window.Paddle.Environment.set('sandbox')
      }

      // Ensure that Paddle is loaded and the DOM is ready
      if (window.Paddle) {
        this.$nextTick(() => {
          window.Paddle.Checkout.open(checkoutConfig)
        })
      } else {
        console.error('Paddle is not loaded')
      }
    },
    planMixin_pauseSubscription: function () {},
    planMixin_resumeSubscription: function () {},
    planMixin_subscribeWithinTrial: function () {},
    planMixin_upgradeWithinTrial: function () {},
    planMixin_downgradeWithinTrial: function () {},
    planMixin_addSubscription: function () {},
    planMixin_updateSubscription: function () {},
    planMixin_cancelSubscription: function () {},
    planMixin_pollingSubscriptionStatus: function (callback = null) {
      this.subscriptionStatus.interval = setInterval(() => {
        if (
          this.subscriptionStatus.intervalCounter >=
          this.subscriptionStatus.intervalLimit
        ) {
          this.subscriptionStatus.subscribeSuccess = false
          this.subscriptionStatus.subscribeFailed = true
          this.subscriptionStatus.billingUpdateSuccess = false
          this.subscriptionStatus.billingUpdateFailed = true
          this.subscriptionStatus.planAction = null
          this.subscriptionStatus.planId = null
          clearInterval(this.subscriptionStatus.interval)
          return
        }
        this.subscriptionStatus.intervalCounter++
        this.planMixin_syncLocalUser(() => {
          clearInterval(this.subscriptionStatus.interval)
          if (callback) {
            callback()
          }
        })
      }, 4000)
    },
    planMixin_syncLocalUser: function (callback) {
      if (this.mixin_user.token) {
        localStorage.setItem('token', this.mixin_user.token) // add token for authentication
      }
      this.$store
        .dispatch(actionTypes.FETCH_USER, {
          userId: this.mixin_user.id ? this.mixin_user.id : this.user.id,
        })
        .then((response) => {
          if (this.mixin_user.token) {
            localStorage.removeItem('token', this.mixin_user.token) // add token for authentication
          }
          let user = response.data.data
          if (!user.plan || !user.plan.subscription) {
            return
          }
          const successAction = () => {
            this.$store
              .dispatch(actionTypes.UPDATE_USER_LOCAL, user)
              .then(() => {
                localStorage.setItem('user', JSON.stringify(user))
              })
            this.subscriptionStatus.syncingButton = false
            callback()
          }

          if (
            this.subscriptionStatus.planAction === planActions.subscribe &&
            user.plan &&
            user.plan.subscription
          ) {
            successAction()
          }
        })
    }
  }
}
