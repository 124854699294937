<template>
  <v-row align="center" justify="center" class="my-16 two-factor-view">
    <v-col cols="12" sm="10" md="8" lg="6" xl="4">
      <v-card class="text-center pa-6 my-2" flat>
        <v-card-title class="text-center d-flex flex-column align-center">
          <v-img :width="80" aspect-ratio="1" cover :src="require('@/assets/images/leadgen-leaf-logo.png')"></v-img>
          <br>
        </v-card-title>
        <v-card-text>
          <h4 class="text-h4 font-weight-bold mb-4">Two Factor Authentication</h4>
          <div class="text-subtitle-1	font-weight-regular">
            We sent a verification code to <span class="text-secondary-red">{{ twoFactorData.email }}</span>
            <br>
            Please check your email and paste the code below.
          </div>
          <!-- Error / Warning alert -->
          <v-row justify="center" class="my-2">
            <v-col cols="12" v-if="alertTwoFa.show">
              <v-alert border type="error" variant="tonal" rounded="md" density="compact" class="text-left"
                icon="mdi-alert-circle">
                <span class="alert mb-0"> {{ alertTwoFa.message }}</span>
              </v-alert>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-sheet color="surface">
              <v-otp-input variant="outlined" base-color="secondary-red" type="number" length="6"
                v-model="twoFactorAuth.code" @finish="twoFactorAuth.error = false"></v-otp-input>
              <p class="text-error" v-if="twoFactorAuth.error">This field is required</p>
            </v-sheet>
          </v-row>
          <v-row justify="center">
            <v-checkbox label="Trust this device for 60 days?" hide-details class="text-grey-darken-4"
              density="comfortable" color="primary-green" base-color="grey-darken-4"
              v-model="twoFactorAuth.isTrustedDevice"></v-checkbox>
          </v-row>
          <v-spacer class="my-5"></v-spacer>
          <v-row justify="center">
            <v-btn class="text-none font-weight-bold two-fa-btn" color="primary-red" height="42" text="Verify"
              width="30%" rounded="md" @click="verifyTwoFactor" :loading="twoFactorAuth.verifying"></v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { LOGIN_USER } from '@/store/action-types.js'

export default {
  props: ['twoFactorData'],
  data() {
    return {
      twoFactorAuth: {
        code: '',
        isTrustedDevice: false,
        verifying: false,
        error: false
      },
      alertTwoFa: {
        show: false,
        message: null
      }
    }
  },
  methods: {
    verifyTwoFactor() {
      if (this.twoFactorAuth.code.length !== 6) {
        this.twoFactorAuth.error = true
        return
      }

      this.twoFactorAuth.verifying = true
      this.twoFactorAuth.error = false

      this.$store.dispatch(LOGIN_USER, {
        email: this.twoFactorData.email,
        password: this.twoFactorData.password,
        authenticateCode: this.twoFactorAuth.code,
        isTrustedDevice: this.twoFactorAuth.isTrustedDevice
      })
        .then(response => {
          this.$parent.$parent.loginSuccess(response.data.data)
        })
        .catch(err => {
          this.alertTwoFa.show = true
          this.alertTwoFa.message = err.response?.data?.meta?.error_message || 'Something went wrong! Please try again later.'
        })
        .finally(() => {
          this.twoFactorAuth.verifying = false
        })
    }
  }
}
</script>
