import { createApp } from 'vue'
import App from './App.vue' /* root component */
import router from './router' /* application router */
import { store } from './store/store' /* app store */
import jquery from 'jquery'
import KeenUI from 'keen-ui/src' /* UI components */
import ValidatorHandler from './plugins/validation/handler.js' /* custom validation */
import vuetify from './plugins/vuetify/index.js' /* vuetify */

const app = createApp(App)

if (typeof ResizeObserver !== 'undefined') {
  const ro = new ResizeObserver(() => {})
  ro.observe(document.body)
}

const globalProperties = app.config.globalProperties

app.use(KeenUI)
app.use(vuetify)
app.use(ValidatorHandler)
app.use(store)
app.use(router)

window.$ = jquery

app.mount('#app')

export default globalProperties
