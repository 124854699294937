<template>
  <div class="center-align home-container">
      <div class="content">
       <h1>...capture your lead insights.</h1>
        <div v-if="!isLoggedIn">
          <router-link to="/register"><ui-button type="primary" buttonType="button" color="green" size="large" raised>Create Leadgen Account</ui-button></router-link>
          <router-link to="/login"><ui-button type="primary" buttonType="button" color="primary" size="large">Sign In</ui-button></router-link>
        </div>
        <div v-else>
          <router-link to="/dashboard"><ui-button type="primary" buttonType="button" color="primary" icon="arrow_forward" iconPosition="right" size="large" raised> Dashboard </ui-button></router-link>
        </div>
      </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo'
import { mapGetters } from 'vuex'
export default {
  components: {
    'logo': Logo
  },

  computed: {
    ...mapGetters([
      'isLoggedIn'
    ])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

</style>
